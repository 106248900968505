<div class="container-xl mx-0 mx-md-2">

  <div class="row" style="margin-right: 0;">
    <div class="container">
      <div class="header">
        <ul class="breadcrumb">
          <li><a routerLinkActive="active">Recherche</a></li>
          <li>{{ keyWordBrute }} </li>
        </ul>
      </div>
    </div>
  </div>

  <div class="row" *ngIf="allResult && allResult.matches">
    <div class="col-6 col-sm-4 col-md-3 col-lg-2 px-xs-1 mb-4"
      *ngFor="let data of allResult.matches | paginate: { itemsPerPage: ITEM_PER_PAGE, currentPage: p ,totalItems: nbrProd};">
      <div class="containt">
        <app-produit-input [data]="data"></app-produit-input>
        <!--<div [routerLink]="['/detail-produit/' + data.slug]" style="cursor: pointer;">
          <ng-container>
            <a>
              <img width="auto" height="auto" *ngIf="data.image" [src]="data.image" alt="" class="img-product" style="width: 255px;object-fit: cover;">
              <img width="auto" height="auto" *ngIf="!data.image" src="assets/static/list2.png" alt="" class="img-product">
              <div class="top-text" *ngIf="data.titre">
                <h3 style="word-wrap: break-word;">{{data.titre}}</h3>
              </div>
            </a>
          </ng-container>
          <div class="text-center">
            <h4 style="word-wrap: break-word;">{{ data.model | uppercase }}</h4>
            <h5 *ngIf="data.prix_promotion && data.prix_promotion > 0">{{ data.prix_promotion | number : '1.2-2' }} €
            </h5>
            <h5 *ngIf="!data.prix_promotion || data.prix_promotion === 0">{{ data.prix | number : '1.2-2' }} €</h5>
          </div>
        </div>-->
      </div>

    </div>

    <div class="col-12 text-center mt-4" *ngIf="!allResult.matches.length">
      <img width="auto" height="auto" src="assets/images/logo/refus@2x.png" alt="" style="width: 50px;">
      <p>Aucun produit n'a été trouvé</p>
    </div>
  </div>
  <pagination-controls class="modal-pagination text-center" (pageChange)="changePage($event)" directionLinks="true"
        responsive="true" autoHide="true" previousLabel="PRÉCÉDENT" nextLabel="SUIVANT">
  </pagination-controls>

  <div class="row mt-5" *ngIf="allResult && allResult.suggestions">
    <div class="col-12" *ngIf="allResult.suggestions.caracteristiques.length || allResult.suggestions.categorie.length
      || allResult.suggestions.couleur.length || allResult.suggestions.description.length || allResult.suggestions.model.length
      || allResult.suggestions.sous_univers.length || allResult.suggestions.univers.length">
      Recherche associé
    </div>

    <div class="col-12" *ngIf="allResult.suggestions.caracteristiques.length">
      <p class="mb-0">caracteristiques:
        <a [routerLink]="['/recherche']" [queryParams]="{mot: item}"
          *ngFor="let item of allResult.suggestions.caracteristiques"> {{ item }} </a>
      </p>
    </div>
    <div class="col-12" *ngIf="allResult.suggestions.categorie.length">
      <p class="mb-0">categorie:
        <a [routerLink]="['/recherche']" [queryParams]="{mot: item}"
          *ngFor="let item of allResult.suggestions.categorie"> {{ item }} </a>
      </p>
    </div>
    <div class="col-12" *ngIf="allResult.suggestions.couleur.length">
      <p class="mb-0">couleur:
        <a [routerLink]="['/recherche']" [queryParams]="{mot: item}" *ngFor="let item of allResult.suggestions.couleur">
          {{ item }} </a>
      </p>
    </div>
    <div class="col-12" *ngIf="allResult.suggestions.description.length">
      <p class="mb-0">description:
        <a [routerLink]="['/recherche']" [queryParams]="{mot: item}"
          *ngFor="let item of allResult.suggestions.description"> {{ item }} </a>
      </p>
    </div>
    <div class="col-12" *ngIf="allResult.suggestions.model.length">
      <p class="mb-0">modèle:
        <a [routerLink]="['/recherche']" [queryParams]="{mot: item}" *ngFor="let item of allResult.suggestions.model">
          {{ item }} </a>
      </p>
    </div>
    <div class="col-12" *ngIf="allResult.suggestions.sous_univers.length">
      <p class="mb-0">sous_univers:
        <a [routerLink]="['/recherche']" [queryParams]="{mot: item}"
          *ngFor="let item of allResult.suggestions.sous_univers"> {{ item }} </a>
      </p>
    </div>
    <div class="col-12" *ngIf="allResult.suggestions.univers.length">
      <p class="mb-0">univers:
        <a [routerLink]="['/recherche']" [queryParams]="{mot: item}" *ngFor="let item of allResult.univers"> {{ item }}
        </a>
      </p>
    </div>
  </div>
</div>
