<div class="pt-4 main-ads" style="background-color: #FFFFFF">
  <p class="mb-0 head-section" style="margin: 2% 0;">ILS NOUS ONT FAIT CONFIANCE, <span class="break-on-mobile head-section">ET ILS ONT EU RAISON</span></p>
  <!-- EVALUATION -->
  <div class="pt-4">
    <div class="row">
      <div class="col-md-12 col-lg-3 my-auto pb-4 text-center">
        <a class="w-100" href="https://fr.trustpilot.com/evaluate/hadeen-place.fr" target="_blank" rel="noopener"
            style="width: 100% !important;">
          <img src="assets/images/trustpilot.png" class="trustpilot_img" alt="Trustpilot" style="width: 15rem;">
        </a>
        <!--<div>
          Basé sur <a class="number_avis" href="https://fr.trustpilot.com/review/hadeen-place.fr" target="_blank" rel="noopener">1850 avis</a>
        </div>-->
        <!--<div class="trustpilot-widget w-100" data-locale="fr-FR" data-template-id="56278e9abfbbba0bdcd568bc"
          data-businessunit-id="6152d7a2916a86001d1a9a41" data-style-height="52px" data-style-width="100%">
          <a class="w-100" href="https://fr.trustpilot.com/review/hadeen-place.fr" target="_blank" rel="noopener"
            style="width: 100% !important;">Trustpilot</a>
        </div>-->
      </div>
      <div class="col-md-12 col-lg-8">
        <div id="carouselAvis" class="carousel slide w-100" data-ride="carousel" data-interval="false">
          <div class="carousel-inner w-100">
            <div class="carousel-item w-100" *ngFor="let itemParent of list; let ind = index"
              [ngClass]="{'active': ind === 0}">
              <div class="row">
                <div class="col-12 col-md-3 mb-0" *ngFor="let itemFils of itemParent.fils; let i = index">
                  <div class="box sb3">
                    <div style="display: flex;" class="mb-2">
                      <div class="testimonials__header--stars">
                          <div class="star-icon"
                          [ngStyle]="{'background-color': itemFils.note >= 1 ? getColor(itemFils.note) : '#dcdce6'}"></div>
                          <div class="star-icon"
                          [ngStyle]="{'background-color': itemFils.note >= 2 ? getColor(itemFils.note) : '#dcdce6'}"></div>
                          <div class="star-icon"
                          [ngStyle]="{'background-color': itemFils.note >= 3 ? getColor(itemFils.note) : '#dcdce6'}"></div>
                          <div class="star-icon"
                          [ngStyle]="{'background-color': itemFils.note >= 4 ? getColor(itemFils.note) : '#dcdce6'}"></div>
                          <!-- <div class="star-icon"
                          [ngStyle]="{'background-color': itemFils.note >= 5 ? getColor(itemFils.note) : '#dcdce6'}"></div> -->
                      </div>
                    </div>                    
                    <!--<p class="m-0" style="font-size: 10px; color: #848484;">{{itemFils.date | date:'d/M/yyyy'}} &nbsp;</p>-->
                    <p class="mx-0 mt-0 mb-1 title-ads" *ngIf="itemFils.avis">{{itemFils?.avis}}</p>
                    <p class="testimonial__content m-0" [ngClass]="{'h-100': expandedIndex.includes(i)}"
                      style="color: #707070; font-size: 14px; font-weight: 400;">
                      <span class="testimonial__content--quote" *ngIf="!itemFils.readmore">
                        {{(itemFils.avis.length>100)? (itemFils.avis |
                        slice:0:100)+'...': (itemFils.avis)}}
                        <a class="testimonial__content--more" *ngIf="itemFils.avis.length>100"
                          (click)="readMore(itemFils, i)" style="color:#70707080;cursor:pointer;">voir plus</a>
                      </span>
                      <span class="testimonial__content--quote" *ngIf="itemFils.readmore">
                        {{itemFils.avis}}
                        <a class="testimonial__content--more" *ngIf="itemFils.avis.length>100"
                          (click)="readMore(itemFils, i)" style="color:#70707080;cursor:pointer;">voir moins</a>
                      </span>
                    </p>
                    <p class="mx-0 mb-0 mt-0" style="font-size: 13px;color: #ada7a7;font-weight: 600;">{{itemFils.author}}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="carousel-controls w-100">
            <a class="carousel-control-prev" style="left: -25px!important;" href="#carouselAvis" role="button" data-slide="prev">
              <!-- <span class="carousel-control-prev-icon" aria-hidden="true"></span> -->
              <i class="fa fa-chevron-left" style="color: #666363;font-size: 23px;"></i>
              <span class="sr-only">Précédent</span>
            </a>
            <a class="carousel-control-next" style="right: -25px!important;" href="#carouselAvis" role="button" data-slide="next">
              <!-- <span class="carousel-control-next-icon" aria-hidden="true"></span> -->
              <i class="fa fa-chevron-right" style="color: #666363;font-size: 23px;"></i>
              <span class="sr-only">Suivant</span>
            </a>
          </div>

          <div class="col-12 mt-2" *ngIf="data && data.length > 1">
            <div class="row m-auto" style="width: fit-content;">
              <a class="carousel-control-prev" href="#carouselAvis" role="button" data-slide="prev"
                style="width: fit-content; position: initial;">
                <a uk-slidenav-previous style="color: #fff;"></a>
              </a>
              <ol class="carousel-indicators m-0" style="position: initial; width: fit-content;">
                <li attr.data-target="#carouselAvis" *ngFor="let itemParent of data; let ind = index"
                  [ngClass]="{'active': ind === 0}"
                  style="width: 10px; height: 10px; border-radius: 50%; background-color: #fff;"
                  attr.data-slide-to="{{ ind }}"></li>
              </ol>
              <a class="carousel-control-next" href="#carouselAvis" role="button" data-slide="next"
                style="width: fit-content; position: initial;">
                <a uk-slidenav-next style="color: #fff;"></a>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

</div>
