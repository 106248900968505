
import { ProductService } from 'src/app/services/product.service';
import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { WebSocketAPI } from './WebSocketAPI';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { StorageService } from './storage.service';
import { HttpService } from './http.service';

@Injectable({
  providedIn: 'root'
})
export class ModalOpenService {

  webSocketAPI: WebSocketAPI;
  modalRef2: BsModalRef;

  // message servive
  url = 'messages/discussions';
  discussions = [];
  discussion = {
    messages: [{ createdAt: new Date(), files: [] }],
    receiver: { id: 0, pseudo: '', imageProfil: '' },
    objet: '',
  };
  nbTotal = 0;
  dataSource;
  unread = 0;


  constructor(
    private toast: ToastrService, private modalService: BsModalService,
    private productService: ProductService, private storageService: StorageService,
    private http: HttpService) {
    this.webSocketAPI = new WebSocketAPI();

    // message service
    const user = this.storageService.getItem('User');
    if (user) {
      this.updateDiscussions();
    }
  }



  async testConnectionSOcket() {
    this.webSocketAPI.init();
    this.webSocketAPI.connectTo();
    await this.webSocketAPI.registerCallback(() => {
      // const user = JSON.parse(localStorage.getItem('User'));
      const userString = this.storageService.getItem('User');
      if (userString) {
        const user = JSON.parse(userString);
        setTimeout(() => {
          this.initWs(user);
        }, 500);
      }
    });
  }

  testDisconectionSOcket() {
    this.webSocketAPI._disconnect();
  }

  initWs(user) {
    this.productService.getEnvies();
    this.webSocketAPI.stompClient.subscribe('/topic/HADEEN.wishlist.*', (data) => {
      // this.props.setStatus(true)
      this.productService.getEnvies();
    });
    // this.webSocketAPI.stompClient.subscribe(`/topic/HADEEN.message.recu.${ user.id.userId }`, (data) => {
    this.webSocketAPI.stompClient.subscribe(`/topic/HADEEN.message.recu.${user.id.userId}`, (data) => {
      const body = JSON.parse(data.body);
      const newMessage =  {
        createdAt: body.sender.createdAt,
        file: null,
        id: 0,
        is_sent: false,
        lu: false,
        message: body.message,
        published_at: {},
        type: null,
        files : []
      };
      this.unread += 1;

      this.insertNewMessage(body.object, newMessage);

      if (this.discussion.objet === body.object) {
        this.discussion.messages.push(newMessage);
      }
      this.toast.success('Nouveau message', '', {
        timeOut: 3000
      });
    });
    this.webSocketAPI.stompClient.subscribe('/user/topic/errors', (data) => {
      this.toast.error('Non envoyé', '', {
        timeOut: 3000
      });
    });
  }

  sendMessage(message, template?) {
    // const messages = {
    //   receiver: 191,
    //   objet: 'objet test',
    //   message: 'ceci est un message test',
    // };

    // const user = localStorage.getItem('User');
    const user = this.storageService.getItem('User');
    if (user) {
      this.webSocketAPI.send('/app/message', message);
      this.toast.success('Message envoyé', '', {
        timeOut: 3000
      });
    } else {
      this.loginModal(template);
    }
  }

  setLu(row) {
    const lien = '/app/message.discussion.lu.' + row.id;
    // /app/message.discussion.lu.[discussionId]
    this.webSocketAPI.send(lien, row);
  }

  likeProd(path, data) {
    this.webSocketAPI.send(path, data);
    setTimeout(() => {
      this.productService.getEnvies();
    }, 300);
  }

  loginModal(template) {
    this.modalRef2 = this.modalService.show(
      template,
      Object.assign({}, { class: 'login' }),
      // Object.assign({ backdrop: 'static' }),
    );
  }



  // message service
  collectMessages(data) {
    let unreadTotal = 0;
    data.forEach((element, index) => {
      const link = this.url + '/' + element.id;
      this.http.get(link).subscribe(discussion => {
        discussion.data.discussion.messages.sort((b, a) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime());
        Object.assign(element, { messages: discussion.data.discussion.messages });
        element['lastMessageDate'] = element.messages[element.messages.length - 1].createdAt;
        if (element.lu === 0) {
          unreadTotal += element.messages.filter(m => m.lu === false && m.is_sent === false).length;
        }
        if (index === data.length - 1) {
          data.sort((a, b) => new Date(b.lastMessageDate).getTime() - new Date(a.lastMessageDate).getTime());
          this.discussions = data;
          this.unread = unreadTotal;
          if (this.dataSource !== undefined) {
            this.dataSource.data = data;
          }
        }
      });
    });
    // this.discussions = data;
    // if (this.dataSource !== undefined) {
    //   this.dataSource.data = data;
    // }
  }

  updateDiscussions() {
    this.http.get(this.url).subscribe(data => {
      this.nbTotal = data.data.nb_total;
      // this.unread = data.data.discussions.filter(d => d.lu === 0).length;
      this.collectMessages(data.data.discussions);
    });
  }

  insertNewMessage(objet, message) {

    const index = this.discussions.findIndex(d => d.objet === objet);
    if (index < 0) {

      this.updateDiscussions();
    } else {
      // this.unread ++;
      this.nbTotal += 1;
      this.discussions[index].messages.push(message);
      this.dataSource.data = this.discussions;
    }
  }
}
