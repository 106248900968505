<div class="container prd-list">
  <div class="row">
    <div id="carouselOffreDuMoment{{idSlide}}" class="carousel slide w-100" data-ride="carousel" data-interval="false">
      <div class="carousel-inner w-100">
        <ng-container *ngIf="screenWidth >= 768 && data.max!=undefined;else moyen">
          <div class="carousel-item w-100" *ngFor="let itemParent of data.max; let ind = index" [ngClass]="{'active': ind === 0}">            
            <div class="row">
              <div class="col-6 pd-x" [ngStyle]="{'padding-right.px': ind!=4 ? 6 : 15, 'padding-left.px': ind!=0 ? 6 : 15}" [ngClass]="itemParent.fils.length > 4 ? 'col-md' : 'col-md-3 width_custom'" *ngFor="let itemFils of itemParent.fils; let ind = index">
                <app-produit-input [data]="itemFils" [loginTemplate]="login"></app-produit-input>
              </div>
            </div>
          </div>
        </ng-container>
        <ng-template #moyen>
          <ng-container *ngIf="screenWidth < 768 && screenWidth >= 576; else mobileView">
            <div class="carousel-item w-100" *ngFor="let itemParent of data?.moyen; let ind = index" [ngClass]="{'active': ind === 0}">
              <div class="row">
                <div class="col-3" [ngStyle]="{'padding-right.px': ind!=3 ? 6 : 15, 'padding-left.px': ind!=0 ? 6 : 15}" [ngClass]="itemParent.fils.length > 4 ? 'col-md' : 'col-md-3 width_custom'" *ngFor="let itemFils of itemParent.fils; let ind = index">
                  <app-produit-input [data]="itemFils" [loginTemplate]="login"></app-produit-input>
                </div>
              </div>
            </div>
          </ng-container>
        </ng-template>
        <div class="carousel-inner w-100">
          <ng-template #mobileView>
            <div class="carousel-item w-100 active">
              <div class="row h-custom" style="margin-left: 12px;flex-wrap: nowrap;touch-action: manipulation;overflow-x: scroll;">
                <div class="col-5 px-1" *ngFor="let itemFils of data.min; let ind = index">
                  <app-produit-input [data]="itemFils" [loginTemplate]="login"></app-produit-input>
                </div>
              </div>
            </div>
          </ng-template>     
        </div>
    </div>
    <div class="col-12" style="margin-top: 20px;margin-bottom: 7px;" *ngIf="data && data.min.length > 1 && screenWidth >= 576">        
            <div class="row m-auto" style="width: fit-content;">
              <a class="carousel-control-prev" href="#carouselOffreDuMoment{{idSlide}}" role="button" data-slide="prev" style="width: fit-content; position: initial;">
                <a uk-slidenav-previous style="color: #848484;"></a>
              </a>
            <ol class="carousel-indicators m-0" style="position: initial; width: fit-content;">
              <li attr.data-target="#carouselOffreDuMoment{{idSlide}}" *ngFor="let itemParent of nbrSlide; let ind = index" [ngClass]="{'active': ind === 0}" style="width: 10px; height: 10px; border-radius: 50%; background-color: #000000;" attr.data-slide-to="{{ ind }}"></li>
            </ol>
            <a class="carousel-control-next" href="#carouselOffreDuMoment{{idSlide}}" role="button" data-slide="next" style="width: fit-content; position: initial;">
              <a uk-slidenav-next style="color: #848484;"></a>
            </a>
          </div>        
    </div>
  </div>
  <!-- <button *ngIf="type != 'offres'" [routerLink]="['/all-product']" [queryParams]="{type : type === 'venteflash' ? type : null}" style="font-family: 'Poppins-SemiBold';">JE DÉCOUVRE</button> -->
</div>
