<mat-accordion class="expandable-content">
  <mat-expansion-panel class="expandable-content__panel mb-3" (opened)="panelOpenState = true" (closed)="panelOpenState = false">
      <mat-expansion-panel-header class="expandable-content__title">
          <mat-panel-title style="font-size: 16px; font-weight: 700; color: #2E2E2E; margin-top: 5px;">
              {{ item.Title }}
          </mat-panel-title>
      </mat-expansion-panel-header>
      <div class="expandable-content__content" [innerHTML]="item.ExpandableContent"></div>
      <div>fdsfsdf</div>
  </mat-expansion-panel>
</mat-accordion>