<div class="row">
  <div class="col-12 mb-4">
    <h1 class="text-center">MON COMPTE</h1>
  </div>

  <div class="col-12 col-md-4 mb-4">
    <div style="border: 1px solid #d9d9d9; cursor: pointer; height: 100%" [routerLink]="['mon-profil']">
      <div class="row pt-4 pb-2 pl-2 pr-2">
        <div class="col-12 text-center mb-1"><img width="auto" height="auto" style="height: 25px;"
            src="../../../assets/images/logo/profil.png" alt=""></div>
        <div class="col-12">
          <h2 class="text-center" style="color: #1A1A1A; font-size: 20px; font-family: 'SFCompactDisplay-Medium';">Mon
            profil
          </h2>
        </div>
        <div class="col-12">
          <p class="text-center" style="color: #1a1a1a; font-size: 16px; font-family: 'SFCompactDisplay-Regular';">
            Consulter mes informations personnelles
          </p>
        </div>
      </div>
    </div>
  </div>

  <div class="col-12 col-md-4 mb-4">
    <div style="border: 1px solid #d9d9d9; cursor: pointer; height: 100%" [routerLink]="['favoris']">
      <div class="row pt-4 pb-2 pl-2 pr-2">
        <div class="col-12 text-center mb-1"><img width="auto" height="auto" style="height: 25px;"
            src="../../../assets/images/logo/love_grey.png" alt=""></div>
        <div class="col-12">
          <h2 class="text-center" style="color: #1A1A1A; font-size: 20px; font-family: 'SFCompactDisplay-Medium';">
            Favoris <span *ngIf="productService?.mesEnvies?.length "></span></h2>
        </div>
        <div class="col-12">
          <p class="text-center" style="color: #1a1a1a; font-size: 16px; font-family: 'SFCompactDisplay-Regular';">
            Consulter et commander vos articles préférés</p>
        </div>
      </div>
    </div>
  </div>

  <div class="col-12 col-md-4 mb-4">
    <div style="border: 1px solid #d9d9d9; cursor: pointer;" (click)="goTo('mes-achats')">
      <div class="row pt-4 pb-2 pl-2 pr-2">
        <div class="col-12 text-center mb-1"><img width="auto" height="auto" style="height: 25px;"
            src="../../../assets/images/logo/listesAchats.png" alt=""></div>
        <div class="col-12">
          <h2 class="text-center" style="color: #1A1A1A; font-size: 20px; font-family: 'SFCompactDisplay-Medium';">
            Mes listes d’achats
            <span *ngIf="productService.listeAchat && productService.listeAchat.length">({{ productService.listeAchat.length}})</span>
          </h2>
        </div>
        <div class="col-12">
          <p class="text-center" style="color: #1a1a1a; font-size: 16px; font-family: 'SFCompactDisplay-Regular';">
            Créer, consulter, modifier, chercher et partager
          </p>
        </div>
      </div>
    </div>

    <div *ngIf="showMenuListeAchat">
      <div class="d-block d-md-none mt-2">
        <mat-expansion-panel [expanded]="true" style="box-shadow: 3px 3px 5px 6px #ccc;background: white;">
          <div class="d-flex justify-content-between pt-3" [routerLink]="['/compte/mes-achats']">
            <p style="cursor: pointer;">Créer une nouvelle liste</p>
            <span><i class="fa fa-chevron-right"></i></span>
          </div>
          <div class="d-flex justify-content-between pt-2" [routerLink]="['/compte/mes-achats/consulter-achat']">
            <p style="cursor: pointer;">Consulter une liste</p>
            <span><i class="fa fa-chevron-right"></i></span>
          </div>
          <ng-container *ngFor="let item of productService.listeAchat">
            <div class="d-flex justify-content-between pt-2">
              <p style="cursor: pointer;" [routerLink]="['/compte/mes-achats/'+item.id]" [state]="true">
                {{ item.event | titlecase }}: {{ item.title }}
              </p>
              <span><i class="fa fa-chevron-right"></i></span>
            </div>
          </ng-container>
        </mat-expansion-panel>
      </div>
    </div>
  </div>

  <div class="col-12 col-md-4 mb-4">
    <div style="border: 1px solid #d9d9d9; cursor: pointer; height: 100%" [routerLink]="['mes-messages']">
      <div class="row pt-4 pb-2 pl-2 pr-2">
        <div class="col-12 text-center mb-1"><img width="auto" height="auto" style="height: 20px;"
            src="../../../assets/images/logo/messages.png" alt=""></div>
        <div class="col-12">
          <h2 class="text-center" style="color: #1A1A1A; font-size: 20px; font-family: 'SFCompactDisplay-Medium';">Mes
            messages <span *ngIf="chatService.unread>0">({{chatService.unread}})</span> </h2>
        </div>
        <div class="col-12">
          <p class="text-center" style="color: #1a1a1a; font-size: 16px; font-family: 'SFCompactDisplay-Regular';">
            Consulter, envoyer et répondre
          </p>
        </div>
      </div>
    </div>
  </div>

  <div class="col-12 col-md-4 mb-4">
    <div style="border: 1px solid #d9d9d9; cursor: pointer;" (click)="goTo('mes-commandes')">
      <div class="row pt-4 pb-2 pl-2 pr-2">
        <div class="col-12 text-center mb-1"><img width="auto" height="auto" style="height: 25px;"
            src="../../../assets/images/logo/commandes.png" alt=""></div>
        <div class="col-12">
          <h2 class="text-center" style="color: #1A1A1A; font-size: 20px; font-family: 'SFCompactDisplay-Medium';">Mes
            commandes
            <span *ngIf="productService.allCommande && productService.allCommande.length">({{productService.allCommande.length}})</span>
          </h2>
        </div>
        <div class="col-12">
          <p class="text-center" style="color: #1a1a1a; font-size: 16px; font-family: 'SFCompactDisplay-Regular';">
            Suivre le status de vos achats et ventes</p>
        </div>
      </div>
    </div>

    <div *ngIf="showMenuMesCommandes" [@fadeInOut]>
      <div class="d-block d-md-none mt-2">
        <mat-expansion-panel [expanded]="true" style="box-shadow: 3px 3px 5px 6px #ccc;background: white;">
          <div class="d-flex justify-content-between pt-3" [routerLink]="['/compte/mes-commandes']">
            <p style="cursor: pointer;">Mes achats ({{ productService.commandeAcheteur.length }})</p>
            <span><i class="fa fa-chevron-right"></i></span>
          </div>
          <div class="d-flex justify-content-between pt-2" *ngIf="user.etapeVendeur === 3"
            [routerLink]="['/compte/mes-commandes/ventes']">
            <p style="cursor: pointer;">Mes ventes ({{ productService.commandSeller.length }})</p>
            <span><i class="fa fa-chevron-right"></i></span>
          </div>
        </mat-expansion-panel>
      </div>
    </div>
  </div>

  <div class="col-12 col-md-4 mb-4" *ngIf="commonService.user.etapeVendeur >= 3">
    <div style="border: 1px solid #d9d9d9; cursor: pointer; height: 100%" (click)="goTo2('ma-boutique')">
      <div class="row pt-4 pb-2 pl-2 pr-2">
        <div class="col-12 text-center mb-1"><img width="auto" height="auto" style="height: 25px;"
            src="../../../assets/images/logo/boutique.png" alt=""></div>
        <div class="col-12">
          <h2 class="text-center" style="color: #1A1A1A; font-size: 20px; font-family: 'SFCompactDisplay-Medium';">Ma
            boutique
          </h2>
        </div>
        <div class="col-12">
          <p class="text-center" style="color: #1a1a1a; font-size: 16px; font-family: 'SFCompactDisplay-Regular';">
            Gérer vos articles et votre profil
          </p>
        </div>
      </div>
    </div>
  </div>

  <div class="col-12 col-md-4 mb-4"
    *ngIf="commonService.user.etapeVendeur >= 3">
    <div style="border: 1px solid #d9d9d9; cursor: pointer; height: 100%" [routerLink]="['/compte/frais']">
      <div class="row pt-4 pb-2 pl-2 pr-2">
        <div class="col-12 text-center mb-1"><img width="auto" height="auto" style="height: 25px;"
            src="../../../assets/images/logo/settings.png" alt=""></div>
        <div class="col-12">
          <h2 class="text-center" style="color: #1A1A1A; font-size: 20px; font-family: 'SFCompactDisplay-Medium';">
            Paramétrer frais de livraison</h2>
        </div>
        <div class="col-12">
          <p class="text-center" style="color: #1a1a1a; font-size: 16px; font-family: 'SFCompactDisplay-Regular';">
            Définir votre politique de transport
          </p>
        </div>
      </div>
    </div>
  </div>

  <div class="col-12 col-md-4 mb-4"
    *ngIf="commonService.user.etapeVendeur >= 3">
    <div style="border: 1px solid #d9d9d9; cursor: pointer; height: 100%" [routerLink]="['/compte/mon-abonnement']">
      <div class="row pt-4 pb-2 pl-2 pr-2">
        <div class="col-12 text-center mb-1"><img width="auto" height="auto" style="height: 28px;"
            src="../../../assets/images/logo/handshake.svg" alt=""></div>
        <div class="col-12">
          <h2 class="text-center" style="color: #1A1A1A; font-size: 20px; font-family: 'SFCompactDisplay-Medium';">
            Mes abonnements</h2>
        </div>
        <div class="col-12">
          <p class="text-center" style="color: #1a1a1a; font-size: 16px; font-family: 'SFCompactDisplay-Regular';">
            Découvrir, souscrire et changer De forfait pour augmenter vos ventes
          </p>
        </div>
      </div>
    </div>
  </div>

  <div class="col-12 col-md-4 mb-4" *ngIf="commonService.user.etapeVendeur >= 3">
    <div style="border: 1px solid #d9d9d9; cursor: pointer; height: 100%" (click)="goTo2('promouvoir-ma-boutique')">
      <div class="row pt-4 pb-2 pl-2 pr-2">
        <div class="col-12 text-center mb-1"><img width="auto" height="auto" style="height: 25px;"
            src="../../../assets/images/logo/promotion.png" alt=""></div>
        <div class="col-12">
          <h2 class="text-center" style="color: #1A1A1A; font-size: 20px; font-family: 'SFCompactDisplay-Medium';">
            Promouvoir ma boutique/mes produits
          </h2>
        </div>
        <div class="col-12">
          <p class="text-center" style="color: #1a1a1a; font-size: 16px; font-family: 'SFCompactDisplay-Regular';">
            Booster vos ventes
          </p>
        </div>
      </div>
    </div>
  </div>

  <div class="col-12 col-md-4 mb-4" *ngIf="commonService.user.etapeVendeur >= 3">
    <div style="border: 1px solid #d9d9d9; cursor: pointer; height: 100%;" [routerLink]="['/ajout-prod']">
      <div class="row pt-4 pb-2 pl-2 pr-2">
        <div class="col-12 text-center mb-1"><img width="auto" height="auto" style="height: 25px;"
            src="../../../assets/images/logo/boutique.png" alt=""></div>
        <div class="col-12">
          <h2 class="text-center" style="color: #1A1A1A; font-size: 20px; font-family: 'SFCompactDisplay-Medium';">
            Ajouter un article</h2>
        </div>
        <div class="col-12">
          <p class="text-center" style="color: #1a1a1a; font-size: 16px; font-family: 'SFCompactDisplay-Regular';">
            Mettre un produit en vente
          </p>
        </div>
      </div>
    </div>
  </div>

  <div class="col-12 col-md-4 mb-4">
    <div style="border: 1px solid #d9d9d9; cursor: pointer; height: 100%;" (click)="supprimerCompte()">
      <div class="row pt-4 pb-2 pl-2 pr-2">
        <div class="col-12 text-center mb-1"><img width="auto" height="auto" style="height: 25px;"
            src="../../../assets/images/arrow-right.png" alt=""></div>
        <div class="col-12">
          <h2 class="text-center" style="color: #1A1A1A; font-size: 20px; font-family: 'SFCompactDisplay-Medium';">
            Supprimer mon compte</h2>
        </div>
        <div class="col-12">
          <p class="text-center" style="color: #1a1a1a; font-size: 16px; font-family: 'SFCompactDisplay-Regular';">
            Supprimer mon compte
          </p>
        </div>
      </div>
    </div>
  </div>

  <div class="col-12 col-md-4 mb-4" *ngIf="!commonService.user.etapeVendeur || commonService.user.etapeVendeur < 3">
    <div style="border: 1px solid #d9d9d9; cursor: pointer; height: 100%" [routerLink]="['/devenir-vendeur']">
      <div class="row pt-4 pb-2 pl-2 pr-2">
        <div class="col-12 text-center mb-1"><img width="auto" height="auto" style="height: 25px;"
            src="../../../assets/images/logo/boutique.png" alt=""></div>
        <div class="col-12">
          <h2 class="text-center" style="color: #1A1A1A; font-size: 20px; font-family: 'SFCompactDisplay-Medium';">
            Devenir vendeur</h2>
        </div>
        <div class="col-12">
          <p class="text-center" style="color: #1a1a1a; font-size: 16px; font-family: 'SFCompactDisplay-Regular';">
            Consulter, modifier, commander, partager mes produits favoris…</p>
        </div>
      </div>
    </div>
  </div>

  <div class="col-12 col-md-4 mb-4">
    <div style="border: 1px solid #d9d9d9; cursor: pointer; background-color: #f9f9f9; height: 100%">
      <div class="row pt-4 pb-2 pl-2 pr-2">
        <div class="col-12">
          <h2 class="text-center" style="color: #1A1A1A; font-size: 20px; font-family: 'SFCompactDisplay-Medium';">
            Besoin d’aide?</h2>
        </div>
        <div class="col-12">
          <p class="text-center" style="color: #1a1a1a; font-size: 16px; font-family: 'SFCompactDisplay-Regular';">Vous
            avez une question ou une demande particulière ? Consultez notre
            <a [routerLink]="['/centre-d-aide']" routerLinkActive="router-link-active"
              style="text-decoration: underline; color: #000000;">FAQ</a>, ou contactez notre
            <a style="text-decoration: underline; color: #000000;" (click)="openModalContact()">Support client</a>
          </p>
        </div>
      </div>
    </div>
  </div>

  <div class="col-12 col-md-4 mb-4">
    <div style="border: 1px solid #d9d9d9; cursor: pointer; background-color: #f9f9f9; height: 100%">
      <div class="row pt-4 pb-2 pl-2 pr-2">
        <div class="col-12">
          <h2 class="text-center" style="color: #1A1A1A; font-size: 20px; font-family: 'SFCompactDisplay-Medium';">A
            propos d'Hadéen-place</h2>
        </div>
        <div class="col-12">
          <p class="text-center" style="color: #1a1a1a; font-size: 16px; font-family: 'SFCompactDisplay-Regular';">
            Vous souhaitez mieux nous connaître ? Découvrez notre rubrique
            <a href="" style="text-decoration: underline; color: #000000;">Qui sommes-nous ?</a> et suivez nous sur les
            réseaux
          </p>
        </div>
      </div>
    </div>
  </div>

  <div class="col-12 col-md-4 mb-4">
    <div class="h-100" style="border: 1px solid #d9d9d9; cursor: pointer; background-color: #f9f9f9;"
      [routerLink]="['/conditions-de-ventes']">
      <div class="row pt-4 pb-2 pl-2 pr-2">
        <div class="col-12">
          <h2 class="text-center" style="color: #1A1A1A; font-size: 20px; font-family: 'SFCompactDisplay-Medium';">Nos
            CGU
          </h2>
        </div>
        <div class="col-12">
          <p class="text-center" style="color: #1a1a1a; font-size: 16px; font-family: 'SFCompactDisplay-Regular';">
            Quelques liens utiles :
            <a [routerLink]="['/conditions-de-ventes']"
              style="text-decoration: underline; color: #000000;">Livraison</a>,
            <a [routerLink]="['/conditions-de-ventes']"
              style="text-decoration: underline; color: #000000;">Paiement</a>,
            <a [routerLink]="['/conditions-de-ventes']" style="text-decoration: underline; color: #000000;">conditions
              de retour</a>
          </p>
        </div>
      </div>
    </div>
  </div>
</div>


<!-- modal pour contacter le moderateur -->
<div class="modal fade" id="modalContactModerateur2" tabindex="-1" role="dialog" aria-labelledby="modalLabel"
  aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content" style="border-radius: 8px;">
      <div class="modal-header pb-0">
        <div class="col-12">
          <h6>À : Hadéen-place</h6>
        </div>
      </div>
      <div class="modal-body">
        <div class="col-sm-12">
          <textarea class="form-control col-sm-12 area" rows="5" placeholder="Ecrire le message ici..."
            [(ngModel)]="messageText"></textarea>
          <button class="h-btn-grey-green float-right mt-2 px-3" data-dismiss="modal" mat-flat-button
            (click)="sendMessage()">Envoyer</button>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- end modal pour contacter le moderateur -->
