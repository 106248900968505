import { Directive, HostListener } from '@angular/core';

@Directive({
  selector: '[appOnlyDigit]'
})
export class OnlyDigitDirective {

  constructor() { }

  @HostListener('input', ['$event'])
    onKeyDown(event: KeyboardEvent) {
      const input = event.target as HTMLInputElement;
      let trimmed = input.value.replace(/\s+/g, '');
      const point = [...trimmed].filter(l => l === '.').length;
      const rule = new RegExp('[0-9]+(\.[0-9]{2})?');
      if (rule.test(trimmed) && point < 2) {
        const nb = parseFloat(trimmed);
        const tmp = trimmed.split('.');
        if (nb > 100 || (tmp.length > 1 && tmp[1].length > 0 && parseFloat(tmp[1]) > 99)) {
          trimmed = trimmed.substr(0, trimmed.length - 1);
        }
      } else {
        trimmed = trimmed.substr(0, trimmed.length - 1);
      }
      input.value = trimmed;
  }
}
