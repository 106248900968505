<div class="row">
  <div class="col-12 col-md-12">
    <div class="row">
      <div class="col-12">
        <div class="header">
          <ul class="breadcrumb mb-0">
            <li [routerLink]="['/compte']"><a>Compte</a></li>
            <li class="active"><a>Boutique</a></li>
          </ul>
        </div>
      </div>
      <div class="col-12 mb-4">
        <h1 class="text-center">MA BOUTIQUE</h1>
      </div>
      <div class="col-12 mb-4">
        <div style="padding: 5px; border: 1px solid #D6D6D6; border-bottom: none; cursor: pointer;">
          <div class="col-12 col-md-10 offset-md-1">
            <div class="row mt-3 mb-3">
              <div class="col text-right pr-0" (click)="myList = false">
                <button style="padding: 7px 12px;"
                  [ngClass]="myList ? 'h-btn-green-bordered': 'h-btn-black-bordered'">MON PROFIL VENDEUR</button>
              </div>
              <div class="col pl-0" (click)="myList = true" style="font-size: 16px">
                <button style="padding: 7px 12px;"
                  [ngClass]="myList ? 'h-btn-black-bordered': 'h-btn-green-bordered'">MES PRODUITS(<span
                    *ngIf="myProducts">{{ myProducts.nb_total }}</span>)</button>
              </div>
            </div>
          </div>
        </div>
        <div class="mb-3 pt-3" style="padding: 5px; border: 1px solid #D6D6D6;">
          <div class="row pl-3 pr-3" *ngIf="myList">
            <h6 *ngIf="isExistProdOnline" class="text-u-italic mx-auto" [routerLink]="['/detail-vendeur/'+idVendeur]">
              Consulter ma boutique en tant qu’acheteur</h6>
            <div class="col-12 p-1">
              <button class="h-btn-black-bordered" style="padding: 7px 12px;" (click)="sendListArchive()">
                {{ currentStatut === "ARCHIVE" ? 'Désarchiver' : 'Archiver'}}
              </button>
            </div>
            <div class="row mx-0" *ngIf="myProducts">
              <div class="col-6 col-md-4 p-1 itemProduct"
                *ngFor="let itemProduct of myProducts.produits | paginate: { itemsPerPage: 9, currentPage: page ,totalItems: myProducts.nb_total}"
                style="margin-bottom: 25px;">
                <mat-checkbox *ngIf="itemProduct.isArchive !== null" [(ngModel)]="itemProduct.isArchive"
                  (ngModelChange)="archiveProduct(itemProduct)" style="z-index: 2;"></mat-checkbox>
                <img width="auto" height="auto" src="../../../../assets/static/boost.png" alt=""
                  style="cursor: pointer; position: absolute; right: 10px; top: 10px; width: 15px; z-index: 2;">
                <div style="position: relative;">
                  <div *ngIf="itemProduct.statut === 'ARCHIVE'"
                    style="background-color: #DBFBE4; padding: 5px; position: absolute; font-weight: 600; width: 100%; bottom: 0; text-align: center;">
                    ARCHIVÉ</div>
                  <img width="auto" height="auto" class="produit" [src]="itemProduct.image" alt=""
                    [routerLink]="['/detail-produit/' + itemProduct.slug]">
                </div>
                <!--id.produitId]-->
                <p class="mb-0 text-center" style="font-size: 12px; word-wrap: break-word;">{{ itemProduct.model |
                  uppercase
                  }}
                </p>
                <p class="mb-0 text-center" style="font-size: 12px; overflow-wrap: anywhere;">
                  <span *ngIf="(itemProduct.prix_promotion && itemProduct.prix_promotion > 0) && !itemProduct.isEdit">
                    <span style="text-decoration: line-through;">{{ itemProduct.prix | number : '1.2-2' }} €</span> {{
                    itemProduct.prix_promotion | number : '1.2-2' }} €
                  </span>
                  <span
                    *ngIf="(!itemProduct.prix_promotion || itemProduct.prix_promotion === 0) && !itemProduct.isEdit">
                    {{ itemProduct.prix | number : '1.2-2' }} €
                  </span>
                  <input type="text" name="edit prix" [(ngModel)]="itemProduct.prix" *ngIf="itemProduct.isEdit"
                    style="width: 45px; text-align: center; font-size: 12px; "> <span
                    *ngIf="itemProduct.isEdit && itemProduct.prix_promotion">/</span>
                  <input type="text" name="edit prix" [(ngModel)]="itemProduct.prix_promotion"
                    *ngIf="itemProduct.isEdit && itemProduct.prix_promotion"
                    style="width: 50px; text-align: center; font-size: 12px;">
                  <mat-icon aria-hidden="false" aria-label="Example home icon" style="font-size: 14px; cursor: pointer;"
                    *ngIf="!itemProduct.isEdit" (click)="itemProduct.isEdit = true">edit</mat-icon>
                  <mat-icon aria-hidden="false" aria-label="Example home icon" style="font-size: 14px; cursor: pointer;"
                    *ngIf="itemProduct.isEdit && (itemProduct.prix != itemProduct.prixOrig || itemProduct.prix_promotion != itemProduct.prix_promotionOrig)"
                    (click)="editPrix(itemProduct)">done</mat-icon>
                  <mat-icon aria-hidden="false" aria-label="Example home icon" style="font-size: 14px; cursor: pointer;"
                    *ngIf="itemProduct.isEdit" (click)="editPrix(itemProduct, true)">close</mat-icon>
                </p>
                <button *ngIf="itemProduct.statut !== 'EN_ATTENTE_VALIDATION' && itemProduct.promotion_end_at"
                  class="h-btn w-100 mb-2 p-1"
                  style="font-size: 12px; color: #345B2B; font-family: 'SFCompactDisplay-Medium'; border: #ffffff !important;">Article
                  en avant jusqu’à : {{ itemProduct.promotion_end_at | date:'dd/MM/yyyy'}}</button>

                <button *ngIf="itemProduct.statut === 'EN_ATTENTE_VALIDATION'" class="h-btn w-100 mb-2 p-1"
                  style="font-size: 12px; color: #345B2B; font-family: 'SFCompactDisplay-Medium'; border: #ffffff !important;">En
                  attente de validation</button>

                <button *ngIf="itemProduct.statut !== 'EN_ATTENTE_VALIDATION' && !itemProduct.promotion_end_at"
                  (click)="boostProduct(itemProduct.id)" class="h-btn w-100 mb-2 p-1" type="button" data-toggle="modal"
                  data-target="#staticBackdrop">Booster</button>

                <button class="h-btn w-100 mb-2 p-1" *ngIf="itemProduct.statut !== 'EN_ATTENTE_VALIDATION'"
                  [routerLink]="['/edit-prod/' + itemProduct.id.produitId]">Modifier</button>
              </div>
            </div>
          </div>
          <ng-container *ngIf="myList">

            <div class="row">
              <div class="col-12">
                <!-- maxSize="5"  -->
                <pagination-controls class="modal-pagination text-center m-auto" (pageChange)="changePage($event)"
                  directionLinks="true" responsive="true" autoHide="true" previousLabel="PRÉCÉDENT" nextLabel="SUIVANT">
                </pagination-controls>
              </div>
            </div>

          </ng-container>
          <div class="col-12 col-md-10 offset-md-1" *ngIf="!myList">

            <div class="row mb-2">
              <div class="col-12 col-md-6">
                <button class="w-100 sfCompactDisplayMedium" mat-flat-button
                  [routerLink]="['/compte/promouvoir-ma-boutique']" routerLinkActive="router-link-active"
                  style="border-radius: 0px; background-color: #345B2B; color: #ffffff;">
                  METTRE EN AVANT LA BOUTIQUE
                </button>
              </div>
              <div class="col-12 col-md-6">
                <button class="w-100 sfCompactDisplayMedium" mat-flat-button
                  style="border-radius: 0px; background-color: black; color: white;" (click)="changeStatutBoutique()">
                  <i
                    [ngClass]="{'fa fa-pause': statutBoutique === 'EN_LIGNE', 'fas fa-play' : statutBoutique === 'EN_CONGE'}"></i>
                  {{ statutBoutique === 'EN_LIGNE' ? 'METTRE EN PAUSE LA BOUTIQUE' : 'METTRE EN LIGNE LA BOUTIQUE' }}
                </button>
              </div>
            </div>

            <div class="row mb-0 mb-md-2">
              <div class="col-12">
                <div class="d-flex align-items-center my-3 sf-regular">
                  <img width="auto" height="auto" [src]="avatar" alt="" class="avatar img-fluid"
                    style="border-radius: 50%; width: 87px; height: 87px;">
                  <div class="pl-3 col-md-10 col-8">
                    <input type="file" hidden accept="image/*" (change)="detectFile($event)" #file>
                    <button (click)="file.click()" mat-flat-button
                      style="border-radius: 0px; background-color: #345B2B; color: #ffffff;">
                      Modifier logo
                    </button>
                    <br>
                    <small class="text-muted">(Le fichier doit être en jpg, png, svg, 300ko max.)</small>

                  </div>
                </div>
              </div>

              <div>
                <mat-form-field class="col-12 hadeenInput" appearance="fill" [floatLabel]="'auto'">
                  <mat-label>Nom de la Société</mat-label>
                  <input disabled matInput disabled placeholder="Nom de la Société" [ngModel]="nom" required>
                </mat-form-field>

                <mat-form-field class="col-6 hadeenInput" appearance="fill" [floatLabel]="'auto'">
                  <mat-label>Siret </mat-label>
                  <input disabled matInput disabled placeholder="Siret" [ngModel]="siret" required>
                </mat-form-field>

                <mat-form-field class="col-6 hadeenInput" appearance="fill" [floatLabel]="'auto'">
                  <mat-label>TVA</mat-label>
                  <input disabled matInput disabled placeholder="TVA" [ngModel]="tva" required>
                </mat-form-field>


                <mat-form-field class="col-6 hadeenInput" appearance="fill" [floatLabel]="'auto'">
                  <mat-label>Adresse de la société </mat-label>
                  <input disabled matInput disabled placeholder="Adresse de la société" [ngModel]="adresse" required>
                </mat-form-field>

                <mat-form-field class="col-6 hadeenInput" appearance="fill" [floatLabel]="'auto'">
                  <mat-label>Mail de la société</mat-label>
                  <input disabled matInput disabled placeholder="Mail de la société" [ngModel]="mail" required>
                </mat-form-field>

                <mat-form-field class="col-12 hadeenInput" appearance="fill" [floatLabel]="'auto'">
                  <mat-label>Description de la société</mat-label>
                  <textarea matInput disabled placeholder="Description de la société" [ngModel]="description"
                    required></textarea>
                </mat-form-field>

                <mat-form-field class="col-12 hadeenInput" appearance="fill" [floatLabel]="'auto'">
                  <mat-label>Les conditions de retour des produits</mat-label>
                  <textarea matInput disabled placeholder="Les conditions de retour des produits" [ngModel]="conditions"
                    required></textarea>
                </mat-form-field>
              </div>
            </div>

            <div class="row mb-3">
              <button class="h-btn-grey-green m-auto pl-3 pr-3" mat-flat-button>Besoin d’aide? Nous contacter</button>
            </div>

          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- Modal -->
<div class="modal fade" id="staticBackdrop" data-backdrop="static" data-keyboard="false" tabindex="-1"
  aria-labelledby="staticBackdropLabel" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="staticBackdropLabel">Mettre en avant un produit</h5>
        <button type="button" class="close mr-0" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body" *ngIf="dataForm">
        <app-step3 [isBoost]="true" [formData]="dataForm" (closeStep)="closeModal()" [isExist]="true"></app-step3>
      </div>
    </div>
  </div>
</div>
