import { HttpClient } from '@angular/common/http';
import { Component, OnInit, Input  } from '@angular/core';
import { environment } from 'src/environments/environment';
declare var $;

@Component({
  selector: 'app-hero',
  templateUrl: './hero.component.html',
  styleUrls: ['./hero.component.scss']
})
export class HeroComponent implements OnInit {

  @Input() item: any;
  @Input() indBuilder: any;
  isMobile = false;

  constructor(private httpClient: HttpClient) {
    if (window.innerWidth > 350) {
      this.isMobile = true;
    }
  }

  ngOnInit() {
    if (this.item.Link && this.item.Link.InternalURL && this.item.Link.InternalURL.parent_page) {
      // tslint:disable-next-line: max-line-length
      this.httpClient.get(`${environment.strapi_url}pages/${this.item.Link.InternalURL.parent_page}`).subscribe((dataCurrentPage: any) => {
        this.item.Link.InternalURL.parent_page = dataCurrentPage;
      });
    }

    if (this.item.Link && this.item.Link.InternalURL && this.item.Link.InternalURL.parent_parent_page) {
      // tslint:disable-next-line: max-line-length
      this.httpClient.get(`${environment.strapi_url}pages/${this.item.Link.InternalURL.parent_parent_page}`).subscribe((dataCurrentPage: any) => {
        this.item.Link.InternalURL.parent_parent_page = dataCurrentPage;
      });
    }
  }

  setDataLayer() {
    const objet = {
      'event': 'cta_banner_clicked',
      'promotion_id': this.item.Title,
      'creative_name': this.item.Image.name,
      'promotion_name': this.item.Link.Title
    };
    window['dataLayer'] = window['dataLayer'] || [];
    window['dataLayer'].push(objet);
  }

  redirectButton(idElement) {
    document.getElementById(idElement).scrollIntoView();
    // $('#testimonial')[0].scrollIntoView();
  }

  generateUrl(page) {
    let url = '';
    if (page.parent_parent_page) {
      url += page.parent_parent_page.Slug + '/';
    }
    if (page.parent_page) {
      url += page.parent_page.Slug + '/';
    }
    return url += page.Slug;
  }
}
