import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-optin',
  templateUrl: './optin.component.html',
  styleUrls: ['./optin.component.scss']
})
export class OptinComponent implements OnInit {

  @Input() dataItem: any;
  headersValue;
  mailSubscribe;
  message = '';
  acceptNewsLetter = false;

  constructor(
    private httpClient: HttpClient, private toastrService: ToastrService,
    private activatedRoute: ActivatedRoute) {
    this.headersValue = new HttpHeaders();
    this.headersValue = this.headersValue.set('DatabaseName', 'CITI_Neige_Aventure_test');
    // this.translate.setDefaultLang(this.activatedRoute.snapshot.paramMap.get('langue'));
  }

  ngOnInit() {
    // this.translate.get('mail_added').subscribe((text: string) => {
    //   this.message = text;
    // });
  }

  async sendData() {

    if (this.acceptNewsLetter) {
      if (this.mailSubscribe) {
        // this.headersValue.set('Authorization', `${environment.userTech.token_type} ${environment.userTech.access_token}`);

        // const langue = localStorage.getItem('langueName') + '-ch';
        // const body = new URLSearchParams();
        // body.set('email', this.mailSubscribe);
        // body.set('agencyId', '317');
        // body.set('language', langue + '-ch');
        // body.set('sendEmailForNewslettersInscription', 'true');
        // body.set('fromEmail', 'event@onthemountain.ch');
        // // const body = new FormData();
        // // body.set('email', this.mailSubscribe);

        // // tslint:disable-next-line: max-line-length
        // await this.httpClient.post(`${environment.ws_city}Contact/create?agencyId=317&email=${this.mailSubscribe}&language=${langue}&sendEmailForNewslettersInscription=true&fromEmail=event@onthemountain.ch`, body).subscribe((response: any) => {
        //   this.toastrService.success(this.message, '', {
        //     timeOut: 3000
        //   });
        // });
      }
    } else{
      this.toastrService.error('Veuillez accepter la politique de confidentialité des données');
    }

  }
}
