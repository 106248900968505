import { CommonService } from 'src/app/services/common.service';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ChatService } from 'src/app/services/chat.service';
import { ToastrService } from 'ngx-toastr';
import { HttpService } from 'src/app/services/http.service';

declare var $;
@Component({
  selector: 'app-profil',
  templateUrl: './profil.component.html',
  styleUrls: ['./profil.component.scss']
})
export class ProfilComponent implements OnInit {

  userForm: FormGroup;
  passwordForm: FormGroup;
  user;

  messageText = '';

  selectedValue = false;
  hideActuel = true;
  hideNew = true;

  constructor(
    private formBuilder: FormBuilder, private commonService: CommonService,
    public chatService: ChatService, private toastr: ToastrService, private httpService: HttpService) { }

  ngOnInit(): void {
    this.user = this.commonService.user;
    this.userForm = this.formBuilder.group({
      genre: [1],
      nom: [{value: '', disabled: true}, Validators.required],
      prenom: [{value: '', disabled: true}, Validators.required],
      mail: [{value: '', disabled: true}, Validators.required],
      ddn: [{value: ''}],
      mdpActuel: [{value: ''}, Validators.required],
      mdpNouveau: [{value: ''}, Validators.required],
      mdpConfirmation: [{value: ''}],
    }, {validator: this.checkPasswords });

    this.userForm.controls['nom'].setValue(this.user.nom);
    this.userForm.controls['prenom'].setValue(this.user.prenom);
    this.userForm.controls['mail'].setValue(this.user.email);
    if (this.user.sexe === 'HOMME') {
      this.userForm.controls['genre'].setValue(1);
    } else {
      this.userForm.controls['genre'].setValue(2);
    }
    this.userForm.controls['mdpActuel'].setValue('');
    this.userForm.controls['mdpNouveau'].setValue('');
    this.userForm.controls['mdpConfirmation'].setValue('');
  }

  get f() {
    return this.userForm.controls;
  }

  openModalContact() {
    $('#modalContactModerateur').modal('toggle');
  }

  sendMessage() {
    if (this.messageText.length > 0) {
      const idModerateur = this.user.id.plateforme.moderateur_id || 14;
      const messageToModerateur = {
        receiver: idModerateur,
        objet: 'Hadeen',
        message: this.messageText
      };
      this.chatService.sendMessage(messageToModerateur);
    } else {
      this.toastr.error('Veuillez entrer un message');
    }
  }

  onSubmit() {
    const userValue = this.userForm.value;
    if (!this.checkPasswords(this.userForm) && userValue.mdpNouveau && userValue.mdpActuel) {
      const body = new URLSearchParams();
      body.set('new_password', userValue.mdpNouveau);
      body.set('old_password', userValue.mdpActuel);
      this.httpService.put(`users/password/renew?${body.toString()}`, body).subscribe(
        (resp: any) => {
          this.toastr.success('Modification réussie', '', {
            timeOut: 1000
          });
        },
        (err: any) => {
          if (typeof(err.data) === 'string') {
            this.toastr.error(err.data, '', {
              timeOut: 1000
            });
          } else {
            this.toastr.error('erreur', '', {
              timeOut: 1000
            });
          }
        }
      );
    } else {
      this.toastr.error('Merci de verifié votre mot de passe', '', {
        timeOut: 1000
      });
    }
  }

  checkPasswords(group: FormGroup) {
    const pass = group.get('mdpNouveau').value;
    const confirmPass = group.get('mdpConfirmation').value;

    return pass === confirmPass ? null : { notSame: true };
  }
}
