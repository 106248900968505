import { ProductService } from 'src/app/services/product.service';
import { CommonService } from 'src/app/services/common.service';
import { Component, Inject, OnInit, ViewChild } from '@angular/core';

import {BreakpointObserver, Breakpoints} from '@angular/cdk/layout';
import { Router } from '@angular/router';

import {style, state, animate, transition, trigger} from '@angular/animations';
import { HttpService } from 'src/app/services/http.service';
import { StorageService } from 'src/app/services/storage.service';
import { ChatService } from 'src/app/services/chat.service';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';

declare var $;

@Component({
  selector: 'app-moncompte',
  templateUrl: './moncompte.component.html',
  styleUrls: ['./moncompte.component.scss'],
  animations: [
    trigger('fadeInOut', [
      transition(':enter', [
        style({opacity: 0}),
        animate(500, style({opacity: 1}))
      ]),
      transition(':leave', [
        animate(500, style({opacity: 0}))
      ])
    ])
  ]
})

export class MoncompteComponent implements OnInit {

  showMenuListeAchat = false;
  showMenuMesCommandes = false;
  breakpointObserver;
  user;

  page;
  panelOpenState = false;

  selectedValue;
  headerComponent;
  achatsListe;
  current = null;

  constructor(
    public commonService: CommonService, public productService: ProductService, private httpService: HttpService,
    breakpointObserver: BreakpointObserver, private router: Router, public chatService: ChatService,
    private storageService: StorageService, private dialog: MatDialog) {
      this.breakpointObserver = breakpointObserver;
  }

  ngOnInit(): void {
    this.commonService.getUser();
    this.commonService.getVendeur();
    // this.user = JSON.parse(this.storageService.getItem('User'));
    this.user = this.commonService.user;
    this.productService.getListeAchat();
    this.productService.getEnvies();

    this.productService.getCommandeAcheteur();
  }

  goTo(link) {
    const isSmallScreen = this.breakpointObserver.isMatched('(max-width: 599px)');
    if (isSmallScreen) {
      if (link === 'mes-achats') {
        this.showMenuListeAchat = !this.showMenuListeAchat;
      } else {
        this.showMenuMesCommandes = !this.showMenuMesCommandes;
      }
    } else {
      this.showMenuListeAchat = false;
      this.router.navigate(['/compte/' + link]);
    }
  }

  goTo2(link) {
    if (this.user.etapeVendeur === 3 && this.user.role === 'VENDEUR_VALIDEE') {
      this.router.navigate([`${link}`]);
    } else {
      this.httpService.get(`users/acheteur/byId`).subscribe(
        resp => {
          this.storageService.setItem('User', JSON.stringify(resp.data.user));
          setTimeout(() => {
            this.router.navigate([`${link}`]);
          }, 200);
        },
        err => {
          this.openModal();
        }
      );
    }
  }

  openModal() {
    $('#staticBackdropInvalidAccount').modal('show');
  }

  closeModal(stateModal) {
    $('#staticBackdropInvalidAccount').modal('hide');
    if (stateModal) {
      this.router.navigate(['/' + stateModal]);
    }
  }
}

