<div class="frais-dialog" style="color: #FFF;">
    <div class="text-center" *ngIf="dataSucc.isSuccess === true">
        <p>Vos frais de livraison ont été paramétrés avec succès!</p>
        <p>Vous pouvez les modifier à tout moment dans cet espace!</p>
    </div>
    <div mat-dialog-actions style="text-align: end;" *ngIf="dataSucc.isSuccess === true">
        <!-- <button mat-button [mat-dialog-close]="data.animal" cdkFocusInitial>Ok</button> -->
        <button class="h-btn-black px-3 py-1 m-auto" style="border-radius: 5px;" (click)="onNoClick()">FERMER</button>
    </div>

    <div class="text-center" *ngIf="!dataSucc.isSuccess">
        <p>Vous avez paramétré les frais de livraison
            <span *ngIf="dataSucc.maxQuantite > 1">entre 1 et {{ dataSucc.maxQuantite }} articles.</span>
            <span *ngIf="dataSucc.maxQuantite <= 1">pour 1 article.</span>
        </p>
        <p>Pour les paniers avec plus de {{ dataSucc.maxQuantite + 1 }} articles de votre boutique les frais de livraison seront gratuits.</p>
    </div>
    <div mat-dialog-actions class="row m-0" *ngIf="!dataSucc.isSuccess">
        <button class="h-btn-black px-3 py-1 mr-auto" style="border-radius: 5px;" (click)="onNoClick()">ANNULER</button>
        <button class="h-btn-black px-3 py-1" style="border-radius: 5px;" [mat-dialog-close]="true">VALIDER</button>
    </div>
</div>