import { isPlatformBrowser } from '@angular/common';
import { Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';

@Component({
  selector: 'app-centre-d-aide',
  templateUrl: './centre-d-aide.component.html',
  styleUrls: ['./centre-d-aide.component.scss']
})
export class CentreDAideComponent implements OnInit {

  dataVendeur = {
    univers: [
      {
        title: 'Vendre',
        icon: 'fab fa-sellcast',
        contents: [
          { question: 'Comment devenir vendeur?', answer: 'Vous devez tout d’abord créer un compte , puis dans « mon compte » cliquer sur « devenir vendeur ». Vous devez vous munir des documents suivants : kbis, pièce d’identité du gérant, IBAN et remplir les informations demandées. Une fois votre demande effectuée, vous recevrez un validation sous 24 heures ou une demande supplémentaire de documents nécessaires a la constitution de votre compte vendeur. Seules les entreprises localisée en France sont autorisées à créer un compte vendeur sur la Market Place Haden-Place' },
          { question: 'Comment mettre en vente des articles?  ', answer: 'Si vous n’avez pas encore de compte vendeur vous devez en créer un afin de pouvoir mettre en vente vos articles. Si vous avez déjà un compte vendeur, il vous suffit d’aller dans « mon compte » et cliquer sur « ajouter des produits ». Pensez à mettre de belles photos de vos articles afin de vendre plus rapidement.' },
          { question: 'Combien coûte la mise en vente ? ', answer: 'La mise en vente d’un article est gratuite ! Si vous ne mettez pas en avant ou ne boostez pas vos articles ou eboutique, la publication ne vous coûtera rien. C’est seulement si vous vendez un article, que 12% du montant total  vous sera déduit lors du paiement de la commande. Ces 12% sont les frais de commissions d’Haden-Place qui permettent la maintenance de nos services ainsi que les frais du prestataire de service du paiement sécurisé. ' },
          { question: 'Que puis-je vendre? ', answer: 'Tous les articles sont autorisés tant ils respectent la loi applicable et qui correspondent au moins à un impact listé. Il est interdit de vendre tout objet ne respectant pas la loi, contrefait, échantillons ou testeurs, médicaments sur ordonnance, drogue, tabac et tout ce qui en dérive, tout objet à connotation raciste, haineux  ou violent, articles d’occasions, tout article comprenant de la fourrure (sauf laine et mohair) ' },
          { question: 'Mon article ne correspond à aucun impact', answer: 'Si votre article ne correspond a aucun impact mais que selon vous il correspond à d’autres impact qui ne sont pas listés, vous pouvez nous contacter via le formulaire de contact dans « mon compte » puis « nous contacter »,  afin de soumettre votre demande. Si aucun impact ne correspond à votre article c’est qu’il ne peut pas être vendu sur notre plateforme de vente' },
          { question: 'Je ne retrouve pas mon article dans le listing ou dans ma e-boutique', answer: 'C’est que votre article est en attente de validation. Cela peut prendre jusque’a 24 heures. N’hésitez pas à nous contacter via le formulaire de contact dans « mon compte » puis « nous contacter » si le délais est dépassé.' },
          { question: 'Comment supprimer un article? ', answer: 'Par définition, l’article ne peut être qu’archivé car en cas de litige avec un acheteur nous avons besoin de retrouver le descriptif et les photos de l’article acheté. Si vous désirez ne plus vendre cet article, il vous suffit de modifier le nombre de quantité en stock afin de le mettre en rupture de stock. Si vous souhaitez l’archivez vous pouvez nous contacter via le formulaire de contact dans « mon compte » puis « nous contacter » afin d’en faire la demande.' },          
          { question: 'Mes articles ne se vendent pas assez vite', answer: 'Si vous estimez que votre article ne se vend pas assez vite, des fonctionnalité supplémentaires sont à votre disposition afin de mettre en avant un ou plusieurs produits, voire même votre eboutique. Plusieurs offre et plusieurs durées vous sont proposées afin de correspondre au mieux à vos besoins et à votre budget. Il vous suffit d’aller dans « mon compte » puis « promouvoir ma boutique ».' },
          { question: 'Signaler un article ou un vendeur ', answer: 'Si un article vous semble inapproprié, illégale ou même si vous souhaitez signaler l’attitude d’un utilisateur, contactez nous immédiatement via le formulaire de contact dans « mon compte » puis « nous contacter ». Nous examinerons votre requête et interviendrons dans les plus brefs délais. Votre sécurité et votre intégrité sont primordiales pour tout notre équipe.' },
        ]
      },
      {
        title: 'Commande et envoie',
        icon: 'fas fa-shopping-basket',
        contents: [
          { question: 'Est-il possible d’annuler une commande? ', answer: 'Il est possible de refuser une commande si vous le souhaitez dans un délais de 48h après le paiement de l’acheteur. Si vous souhaitez annuler une commande alors que vous l’avez accepté, vous devez nous contacter via le formulaire de contact dans « mon compte » puis « nous contacter » afin d’en avertir le plus rapidement possible l’acheteur et de le rembourser. Cependant si cette requête devient trop récurrente, l’équipe d’Haden peut signaler votre compte.' },
          { question: 'Comment envoyer un article? ', answer: 'Dès que vous acceptez une commande, vous recevez les coordonnées de l’acheteur afin d’envoyer le colis dans les plus brefs délais. Vous disposez de 5 jours à partir de l’acceptation de la commande pour envoyer le colis. Dès l’envois de votre colis, nous vous invitons à mettre à jour le Status de la commande ainsi que le numéro de suivi du colis. Il vous suffit d’aller dans « mon compte » puis « mes ventes » et enfin de sélectionner la commande concernée et d’y insérer le numéro de suivi ainsi que l’adresse URL du suivi (adresse http du transporteur). ' },
          { question: 'Comment mettre a jour le Status d’une commande?', answer: 'Dans « mon compte » vous retrouvez toutes vos ventes. Sélectionnez la vente que vous souhaitez mettre à jour. Grâce au numéro de colis et de l’URL (adresse HTTP du transporteur ) l’acheteur pourra suivre le colis. Lors de la réception c’est l’acheteur qui confirmera la réception et la conformité de l’article.' },
          { question: 'J’ai envoyé le mauvais article ou la mauvaise personnalisation', answer: 'Vous devez attendre que l’acheteur reçoive la commande et lui demander de la retourner en le contactant via le service de messagerie interne. Nous vous conseillons de prendre à votre charge les frais de retour dans ce cas et de nous contacter au plus vite afin de mettre en attente le paiement de la commande. Dès le renvoie du colis, vous pouvez renvoyer la bonne commande et envoyer le numéro de suivi. N’oubliez pas de nous informer de la réception de la commande par l’acheteur.' },
          { question: 'Comment voir l’historique de mes commandes?', answer: 'Dans « mon compte » et « mes ventes » vous avez tout l’historique de vos ventes. ' },
        ]
      },
      {
        title: 'Paiement et remboursement',
        icon: 'fas fa-money-check-alt',
        contents: [
          { question: 'Quelles informations dois je entrer pour recevoir mon paiement?', answer: 'Lors de la création de votre compte vendeur il vous sera demandé votre IBAN afin de percevoir le règlements de vos ventes directement sur votre compte.' },
          { question: 'Qui doit payer les frais de retour?', answer: 'Selon la loi, c’est l’acheteur qui dois payer les frais de retour, cependant afin d’attirer plus de commande vous pouvez notifier dans votre box « conditions retours » que vous prenez à votre charge les frais de retour en cas de mécontentement ou d’échange. Nous vous conseillons également de prendre à votre charge les frais de retour si vous avez commis une erreur d’article ou de personnalisation lors de l’envoie.' },
          { question: 'Quand serai-je payer?', answer: 'Vous serez payer 15 jours après la réception de l’article par l’acheteur. Ces 15 jours correspondent aux 14 jours de délais de rétractation et 1jours de délai de traitement du paiement.' },
          { question: 'A quoi correspond le montant de la commission sur les ventes?', answer: 'Le montant de la commissions d’Haden est de 12% sur le montant TTC et frais de port inclus d’une commande. Ils correspondent aux frais de notre prestataire de service de paiement sécurisé, aux frais de maintenance du site et de nos services. ' },
          { question: 'Comment rembourser une commande? ', answer: 'Afin de rembourser une commande vous devez nous contacter via le formulaire de contact dans « mon compte » puis « nous contacter ». Ce sont nos équipes qui prendront la main sur le paiement et rembourseront le paiement de l’acheteur. Aucun frais ne vous sera demandé. Cependant si cette intervention devient trop récurrente, l’équipe d’Haden peut signaler votre compte. ' },
        ]
      },
      {
        title: 'Avis et messagerie',
        icon: 'fas fa-comments',
        contents: [
          { question: 'Un acheteur a laissé un avis injurieux, que puis je faire? ', answer: 'Il n’est ni possible de modifier, de supprimer ou de répondre à un avis. Cependant si un avis est hors de propos, injurieux ou inapproprié, nous vous demandons de nous contacter via le formulaire de contact dans « mon compte » puis « nous contacter » afin de rétablir un terrain d’entente et peut être intervenir sur la suppression de cet avis.' },
          { question: 'Comment répondre à un avis ?', answer: 'Il n’est pas possible de répondre à un avis. Cependant vous pouvez contacter l’acheteur pour comprendre ce qu’il ne lui a pas convenu dans cette commande et vous permettre de vous améliorer.' },
          { question: 'Un acheteur n’a toujours pas laissé d’avis sur un article / ou vendeur', answer: 'l’équipe d’Hadeéen relance régulièrement les acheteurs pour avoir les avis sur les commandes ou vendeurs. Cependant si les acheteurs ne souhaitent pas laisser d’avis, il ne nous est pas possible de l’obliger. Vous pouvez toujours contacter les acheteurs via la messagerie interne et leur demander un retour sur leur expérience.' },
          { question: 'Hadeen peut-il retirer un avis?', answer: 'Oui si l’avis est injurieux, inapproprié ou tout autre raison justifiant notre intervention. Pour cela il vous suffit de nous contacter via le formulaire de contact dans « mon compte » puis « nous contacter ». ' },
          { question: 'Un utilisateur me harcèle', answer: 'N’hésitez pas à nous contacter via le formulaire de contact dans « mon compte » puis « nous contacter » dès qu’un comportement vous semble inapproprié. ' },
        ]
      },
      {
        title: 'E-boutique',
        icon: 'fas fa-store',
        contents: [
          { question: 'Comment modifier les informations de ma boutique?', answer: 'Vous pouvez seulement modifier la photo de votre logo en allant dans « ma boutique » et « profil vendeur ». Pour tout autre modification vous devez nous contacter via le formulaire de contact dans « mon compte » puis « nous contacter ».' },
          { question: 'Comment modifier un article? ', answer: 'Dans « ma boutique » et « mes produits » vous pouvez modifier vos articles.' },
          { question: 'Comment booster un article?', answer: 'Dans  « ma boutique », puis « mes articles » vous pouvez « booster un article, c’est à dire le mettre en tête de liste . Vous pouvez également mettre en avant un article soit sur la page d’accueil, soit sur son univers soit l’univers plus le sous univers . Pour cela vous devez aller dans « mon compte » et « Promouvoir ma boutique/mes produits. Il ne vous reste plus qu’a sélectionner le modele de mise en avant puis l’univers ou le sous univers du groupe de produit que vous souhaitez mettre en avant. Attention, tous les produits doivent être du même sous univers ou du même sous univers suivant le type de mise en avant que vous choisissez. Une facture vous sera envoyé par mail immédiatement lors de la validation.' },
          { question: 'Comment mettre en avant  une e-boutique? ', answer: 'Vous devez aller dans « mon compte » puis « promouvoir ma eboutique ». Choisissez le modele de mise en avant que vous souhaitez, procédez au paiement puis confirmez. La validation peut prendre jusque’a 24h. ' },
          { question: 'Comment mettre ma e-boutique en vacances? ', answer: 'La mise en congés de vos articles vous permet de partir en vacances sans vous soucier de l’envoie des commandes. Vous devez aller dans « mon compte » puis « ma eboutique » et cliquer sur « mettre ma boutique en congés ».  Vos articles seront grisés, les acheteurs pourront toujours vous contacter pour savoir quand reprendront les commandes, mais ils ne pourront pas passer commandes. Cependant vous devez honorer les commandes passées avant la mise en congés.' },
          { question: 'Je me suis trompé dans le stock', answer: 'Vous pouvez modifier la quantité des articles a tout moment en cliquent sur « mon compte » puis « ma boutiques et enfin « mes articles ». ' },
          { question: 'Je souhaite avoir une facture de la commission.', answer: 'Dès la fin du délai de rétractation de 14 jours à partir de la réception de la commande par l’acheteur, le montant de la commande déduis des frais de commissions vous sera reversée directement sur votre compte bancaire et la facture de commission envoyée sur votre boite mail.  ' },
        ]
      },
      {
        title: 'Profil',
        icon: 'fas fa-user',
        contents: [
          { question: 'Je n\'arrive pas à créer de compte', answer: "Si vous n’arrivez pas à créer de compte c’est soit qu’une information du formulaire a été mal remplie (erreur de format d’insertion par exemple) , qu’un des documents ou son format ne se télécharge pas ou encore votre navigateur internet ne permet pas le transfert de vos données. Tout d’abord essayez de changer de navigateur internet (google chrome, opéra ou Mozilla par exemple), remplissez les données personnelles puis chargez des document en format JPEG ou PNG.  Si nous n’arrivez toujours pas à la confirmation de votre compte merci de nous contacter via le formulaire de contact tout en bas de la page d’accueil" },
          { question: 'Comment modifier mon profil?', answer: 'Il n’est pas possible de modifier votre profil a part votre mot de passe et le logo de votre société. Pour toute demande de modification merci de nous contacter. via le formulaire de contact dans « mon compte » puis « nous contacter »' },
          { question: 'Que faire si j’ai un accès limité au site? ', answer: 'Si vous avez un accès limité au site c’est que vous n’avez pas accepté tous les cookies. C’est l’un de nos conditions générales d’utilisation. Pour avoir un accès total vous devez accepter tous les cookies en vous rendant dans votre navigateur de recherche et modifier les paramètre de cookies. ' },
          { question: 'Pourquoi mon profil est-il bloqué?', answer: 'Si votre profil est bloqué c’est peut être que votre compte a été signalé pour différentes raisons : comportement inapproprié, vente d’articles non autorisés, usurpation d’identité, moyen de paiement frauduleux, commandes remboursées en grands nombre … Dans tous ces cas vous recevez un email automatiquement vous indiquant les raisons et le délais de cette restriction. N’hesitez pas à nous contacter via le formulaire tout en bas de la page d’accueil pour avoir plus de renseignement.' },
          { question: 'J’ai des difficultés à utiliser le site/l’application ', answer: 'Des difficultés de connexions peuvent apparaitre pour différentes raisons : accès a internet restreint, maintenance ou mise a jour du site. Dans la plupart des cas, le problème se recoud rapidement, si ce n’est pas le cas, n’hésitez pas à nous contacter. ' },
          { question: 'Mon compte a été usurpé', answer: 'Si vous suspectez l’usurpation de votre compte, vous devez nous contacter au plus vite.' },
          { question: 'Comment supprimer mon compte?', answer: 'Pour la suppression de votre compte est possible en cliquant sur le pictogramme « Mon Compte » puis en cliquant sur bouton « Supprimer mon Compte ». Avant de procéder à la suppression définitive de votre compte, une confirmation vous sera demandée. Toute suppression est définitive et entraîne la suppression de vos données personnelles définitivement.' },
          { question: 'Nous contacter par mail', answer: 'service.client@hadeen-place.fr' },
        ]
      }

    ]
  }

  // Comment trouver des articles sur le site ?

  dataAcheteur = {
    univers : [
      {
        title : 'Acheter',
        icon: 'fas fa-shopping-cart',
        contents : [
          { question : 'Comment trouver des articles sur le site ?', answer : 'Pour rechercher des articles, vous pouvez soit écrire directement dans la barre de recherche avec la loupe le nom de l’article soit naviguer à travers les univers, sous univers et catégories. '},
          { question : 'Que faire si un article semble épuisé, sera t’il réapprovisionné? ', answer : 'Pour toute demande relative à la disponibilité d’un article vous pouvez contacter directement le vendeur via notre service de messagerie interne qui se trouve dans la page détail de l’article'},
          { question : 'A quoi corresponde les tailles?', answer : 'Toutes les tailles sont des tailles françaises et correspondent donc à des standards français. N’hésitez pas à contacter le vendeur directement via notre service de messagerie interne afin d’avoir plus de renseignement sur les mensurations ou dimensions d’un article. '},
          { question : 'Que signifie les impacts? ', answer : 'Les impacts sont des valeurs qualitatives mettant en avant chaque article. Tous nos articles regroupent au moins un impact : bio, fait en France, sans cruauté animale, fait à la main, circuit court, végan, 100% recyclable'},
          { question : 'Quels modes de paiement sont acceptés? ', answer : 'Les Cartes banquaire Visa, Mastercards et American-Express.'},
          { question : 'Signaler un article ou un vendeur', answer : 'Si un article vous semble inapproprié, illégale ou même si vous souhaitez signaler l’attitude d’un utilisateur, contactez nous immédiatement via le formulaire de contact dans « mon compte » puis nous contacter ». Nous examinerons votre requête et interviendront dans les plus bref délais. Votre sécurité et votre intégrité sont primordiales pour tout notre équipe.'},          
        ]
      },
      {
        title : 'Commande',
        icon: 'fas fa-clipboard-list',
        contents : [
          { question : 'Pourquoi ma commande n’est pas validée? ', answer : 'Dès la réception de votre paiement via notre prestataire de paiement sécurisé, le vendeur à 48h pour accepter ou refuser la commande. Si passé ce délais il la refuse ou n’y répond pas, votre commande vous sera intégralement remboursée.'},
          { question : 'Comment commander une personnalisation ou un article sur mesure?', answer : 'Pour toute demande spécifique, nous vous conseillons de contacter le vendeur avant la commande pour vérifier la faisabilité de votre demande. Vous pouvez le contacter même après votre commande jusque’a ce qu’il la valide. Cependant aucun retour n’est accepté sur les articles personnalisés sauf accord avec le vendeur. '},
          { question : 'Est-il possible de modifier/d’annuler une commande?', answer : 'Dès lors votre paiement validé il ne vous ait plus possible de modifier ou d’annuler une commande. Vous devez contacter le vendeur et l’équipe Hadeen pour afin qu’il puisse traiter votre requête. Cependant, si la commande a déjà été expédié, vous devez attendre la réception du colis et la renvoyer au vendeur à vos frais. Des frais peuvent vous être retenus dans certains cas. '},
          { question : 'Je me suis trompée d’adresse, puis je modifier?', answer : 'Si l’article n’a pas été expédié vous pouvez contacter le vendeur via la messagerie interne afin de lui transmettre la bonne adresse. Cependant si l’article a déjà été envoyé, il est de votre responsabilité de réceptionner l’article. '},
          { question : 'Où est ma commande? ', answer : 'Dans « mon compte » et « mes achats » vous pouvez suivre le Status de vos commandes : acceptée, expédiée, livrée. '},
          { question : 'Combien de temps prend une livraison?', answer : 'Tout dépend du mode d’expédition choisi par le vendeur. Il faut compter entre 3 et 5 jours.'},
          { question : 'Le systeme de paiement est-il sécurisé?', answer : 'Nous avons choisi le leader du système de paiement sécurisé afin de garantir à nos utilisateurs une sécurité de leur données cryptées et confidentielles. L’équipe Hadéen n’a aucun accès à vos données bancaires même lors des remboursements. '},
          { question : 'Pourquoi ma commande a t’elle était annulée?', answer : 'Si votre commande a été annulée c’est soit le paiement a été refusé, soit que le vendeur ne l’a pas accepté dans le délais de 48h ou qu’il l’a annulé, soit l’équipe Hadén a stoppé le processus de la vente pour des raisons internes. N’hésitez pas à nous contacter pour plus d’informations via le formulaire de contact dans « mon compte » puis « nous contacter ». '},
          { question : 'Pourquoi mon paiement est il toujours en cours de traitement?', answer : 'Si votre paiement est en cours de traitement c’est que notre prestataire de service de paiement sécurisé étudie ce paiement ou que le vendeur n’a pas encore accepté votre commande.'},
          { question : 'Je souhaite avoir une facture?', answer : 'Vous devez contacter le vendeur directement via la messagerie interne afin de lui demander une facture. Il vous suffit d’aller dans « mon compte », « mes achats » et de sélectionnée la commande concernée.'},
        ]
      },
      {
        title : 'Retours et remboursement',
        icon: 'fas fa-exchange-alt',
        contents : [
          { question : 'Comment puis-je retourner ou échanger un article ?', answer : 'Pour toute demande relative à une commande vous devez contacter directement le vendeur via notre messagerie interne dans « mon compte » , mes achats » et sélectionner la commande concernée. . Les frais de retour sont à votre charge mais un accord peut être trouvé entre les deux parties. Vous avez 14 jours à partir de la date de réception du colis pour retourner un article (hors hygiène, personnalisé etc) et dans son emballage d’origine. '},
          { question : 'J’ai annulé ma commande, quand vais je être remboursé?', answer : '? Le délais de remboursement peut prendre jusqu’a 5 jours ouvrés. C’est le temps nécessaire pour avertir le vendeur, et transférer le montant de la commande.'},
          { question : 'L’article envoyé n’est pas celui commandé?', answer : 'Pour toute demande relative à une commande vous devez contacter directement le vendeur via notre messagerie interne dans « mon compte », mes achats » et sélectionner la commande concernée. Dans ce cas où l’erreur vient du vendeur, un accord peut être trouvé avec lui afin qu’il prenne à sa charge les frais de retour. '},
          { question : 'L’article est défectueux après les 14 jours de rétractation', answer : 'La loi du code de consommation donne droit à un remboursement durant 2 ans en cas de défaut de l’article (voir condition générales de vente). Dans ce cas vous devez prendre contact avec le vendeur car Hadeen ne pourra plus directement procéder au remboursement de la commande. '},
          { question : 'Quels articles peuvent être retournés?', answer : 'Selon la loi du code de la consommation tous les produits peuvent être retournés sauf : denrées alimentaires, produits personnalisés, produits alcoolisés, les articles cosmétiques, santé, hygiène, sous vêtements dont l’emballage d’origine a été ouvert. '},
          { question : 'L’article retourné a t’il était reçu?', answer : 'Pour toute demande relative à une commande ou un retour vous devez contacter directement le vendeur via notre messagerie interne dans « mon compte », mes achats » et sélectionner la commande concernée. Nous vous conseillons fortement de garder la preuve de votre retour ainsi que du numéro de suivi.'},
          { question : 'Quand et comment vais-je recevoir mon remboursement?', answer : '4 jours après réception du colis vous recevrez le remboursement. Gardez bien la preuve de l’envoie ainsi que du numéro de suivi. '},
          { question : 'Je n’ai toujours pas eu mon remboursement.', answer : 'Dans ce cas contactez nous directement via le formulaire de contact dans « mon compte » puis « nous contacter », un oubli est possible.'},
          { question : 'Le montant du remboursement n’est pas correct', answer : 'Le remboursement doit correspondre au montant total de la commande, si ce n’est pas le cas, contactez nous directement via le formulaire de contact dans « mon compte » puis « nous contacter ».'},
          { question : 'Que faire si ma carte n’est plus valide au moment du remboursement?', answer : 'Dans ce cas si singulier nous vous invitons à nous contacter directement via le formulaire de contact dans « mon compte » puis « nous contacter »'},
          { question : 'Qui doit payer les frais de retours?', answer : 'Selon la loi, les frais de retour sont à votre charge, mais un accord peut être trouvé entre vous et l’acheteur.'},          
        ]
      },
      {
        title : 'Listes d\'achat et favoris',
        icon: 'fas fa-heart',
        contents : [
          { question : 'A quoi sert les favoris?',answer : 'Les « favoris » sont vos articles préférés qui sont collectés dans votre liste « favoris » chaque fois que vous cliquez sur le « coeur » d’un article. Cette liste vous permet de retrouver en un clic tous vos produits « coup de coeur ». '},
          { question : 'Comment créer une liste d’achat ?', answer : 'Les listes d’achat vous permettent de créer une liste dans laquelle vous pouvez ajouter un ou plusieurs articles, pour une personne ou un événement, et de la partager avec vos proches. Ils pourront ainsi acheter des articles de cette listes et les faire livrer directement chez vous ou vous les offrir lors de l’évènement. Il vous suffit de créer un compte, puis allez dans l’espace «mes listes d’achats » puis de « créer une liste d’achat ». Il vous suffira de la nommer. Pour ajouter des articles, vous devez aller sur le détail de l’article que vous souhaitez ajouter à votre liste, puis cliquez sur « ajouter à une liste d’achat » et de sélectionner la liste dans laquelle vous souhaitez l’ajouter.'},
          { question : 'Comment partager une liste d’achat?', answer : 'Dès que vous ajoutez un article, il vous est proposé « d’ajouter d’autres articles » ou de « partager la liste », il vous suffit de cliquer sur partager et de choisir le moyen de partage. Si vos proches souhaitent découvrir votre liste, il doivent se créer un compte puis rentrer le code qu’ils ont reçu avec le partage de la liste dans l’espace « rechercher une liste ». '},
          { question : 'Comment retirer des articles des listes d’achats/favoris? ', answer : 'Il vous suffit d’aller dans « mon compte » puis « mes listes d’achats » et enfin de choisir la liste que vous souhaitez modifier. Vous aurez accès à l’ensemble des articles ajoutés dans cette liste avec la possibilité, sous chaque articles de le supprimer.'},
          { question : 'Combien d’articles puis je ajouter sur une liste/favoris?', answer : 'Le nombre d’articles est illimité.'},
          { question : 'Où seront livrés les articles d’une liste d’achat?', answer : 'La personne qui choisi un article dans une liste peut choisir de le faire livrer chez elle ou chez vous directement. '},
          { question : 'Comment rechercher une liste d’achat ?', answer : 'Si vos proches souhaitent découvrir votre liste, il doivent se créer un compte puis rentrer le code qu’ils ont reçu avec le partage de la liste dans l’espace « rechercher une liste ».'},
          { question : 'Comment retourner un cadeau? ', answer : 'Seule la personne ayant commandé l’article peut demander un retour, et dans un délais de 14 jours à compté de la date de réception de l’article. '},
        ]
      },
      {
        title : 'Avis et messagerie',
        icon: 'fas fa-comments',
        contents : [
          { question : 'Quand et comment laisser un avis sur un article ou vendeur?', answer : 'Dès que la commande est réputée « livrée » vous avez possibilité de laisser un avis à propos de l’article ainsi que sur le vendeur. Il vous suffit d’aller dans « mon compte » puis « mes commandes » et sélectionnez la commande concernée. Vous aurez donc deux boxs « avis article » et avis client ». '},
          { question : 'Comment modifier ou supprimer un avis? ', answer : 'Une fois que vous avez mis un avis, il ne peut plus être modifié ou supprimé. Si vous le souhaitez vraiment, vous pouvez nous contacter via le formulaire de contact dans « mon compte » puis « nous contacter » pour effectuer une modification ou une suppression.'},
          { question : 'Comment contacter un vendeur? ', answer : 'Vous pouvez contacter n’importe quel vendeur sur la page détail d’un produit.  Dès que la commande est « accéptée » par la vendeur, vous avez également possibilité de le contacter à tout moment pour une personnalisation, un suivi, un retour etc .… Il vous suffit d’aller dans « mon compte » puis « mes commandes » et sélectionnez la commande concernée. Vous trouverez une box « contacter le vendeur », il recevra votre message instantanément. '},
          { question : 'Le vendeur ne me répond pas', answer : '. Si le vendeur ne répond pas alors qu’il est nécessaire d’avoir une communication avec lui, vous pouvez nous contacter afin de le joindre et de prendre contact avec vous. Il vous suffit de remplir le formulaire de contact dans « mon compte » puis « mes commandes » et sélectionnez la commande concernée. Vous trouverez une box « nous contacter », nous recevrons votre message instantanément. '},
          { question : 'Un utilisateur me harcèle', answer : 'Il vous suffit de nous contacter via le formulaire que vous retrouvez dans « mon compte » et de nous transmettre les informations nécessaires.'},
        ]
      },
      {
        title : 'Profil',
        icon: 'fas fa-user',
        contents : [
          { question : 'Je n\'arrive pas à créer de compte', answer : 'Si vous n’arrivez pas à créer de compte c’est soit qu’une information du formulaire a été mal remplie (erreur de format d’insertion par exemple) , qu’un des documents ou son format ne se télécharge pas ou encore votre navigateur internet ne permet pas le transfert de vos données. Tout d’abord essayez de changer de navigateur internet (google chrome par exemple), remplissez les données personnelles puis chargez des document en format JPEG ou PNG.  Si nous n’arrivez toujours pas à la va confirmation de votre compte merci de nous contacter en bas de la page d’accueil.'},
          { question : 'Comment puis-je modifier les informations de mon compte? ', answer : 'Il n’est pas possible de modifier votre profil a part votre mot de passe. Pour toute demande de modification merci de nous contacter via le formulaire que vous retrouvez dans « mon compte » et de nous transmettre les informations nécessaires. '},
          { question : 'Mon compte à été usurpé', answer : 'Si vous vous apercevez que votre compte a été usurpé, contactez nous dès que possible via notre formulaire de contact tout en bas de la page d’accueil.'},
          { question : 'J’ai des difficultés à utiliser le site/l’application', answer : "Si vous avez un accès limité au site c’est que vous n’avez pas accepté tous les cookies. C’est l’un de nos conditions générales d’utilisation. Pour avoir un accès total vous devez accepter tous les cookies en vous rendant dans votre navigateur de recherche et modifier les paramètre de cookies. Si les difficultés persistes,  des difficultés de connexions peuvent apparaitre pour différentes raisons : accès a internet restreint, maintenance ou mise a jour du site. Dans la plupart des cas, le problème se recoud rapidement, si ce n’est pas le cas, n’hésitez pas à nous contacter. "},
          { question : 'Comment supprimer mon compte? ', answer : 'Pour la suppression de votre compte est possible en cliquant sur le pictogramme « Mon Compte » puis en cliquant sur bouton « Supprimer mon Compte ». Avant de procéder à la suppression définitive de votre compte, une confirmation vous sera demandée. Toute suppression est définitive et entraîne la suppression de vos données personnelles définitivement.'},
          { question : 'Nous contacter par mail ', answer : 'service.client@hadeen-place.fr'},
        ]
      }
    ]
  }

  itemChoisi;
  panelOpenState;

  section;
  sousSection;
  breakpoint;
  height;

  addFooter = true;

  selectedSection = 0;

  constructor(
    @Inject(PLATFORM_ID) private platformId,
    private titleService: Title, private metaService: Meta) {

  }

  ngOnInit(): void {
    this.titleService.setTitle('Centre d’aide |Hadéen-Place | Nous sommes là pour vous');
    this.metaService.updateTag({
      name: 'description',
      // tslint:disable-next-line: max-line-length
      content: 'Rejoins notre communauté et consommons responsable. Besoin d’aide? Notre équipe est là pour vous. Bio, vegan, made in France, circuit court, fait main, naturel, Éco-responsable, écologique, recyclable, zéro déchet, cruelty fee'
    });
    if (isPlatformBrowser(this.platformId)) {
      const path = window.location.pathname;
      if (path.includes('centre-d-aide-app')) {
        this.addFooter = false;
      }
      this.breakpoint = (window.innerWidth <= 400) ? 2 : 3;
      this.height = (this.breakpoint === 2) ? '2:2' : '2:1';
    }
  }

  chooseItem(item, section) {
    this.itemChoisi = item;
    this.sousSection = item.title;
    this.section = section;
  }

  reset(selectedIndex?) {

    this.selectedSection = this.section === 'Acheteur' && selectedIndex ? 1 : 0;

    this.itemChoisi = undefined;
    this.section = undefined;
    this.sousSection = undefined;
  }

  onResize(event) {
    this.breakpoint = (event.target.innerWidth <= 400) ? 2 : 3;
    this.height = (this.breakpoint === 2) ? '2:2' : '2:1';
  }

}
