import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { HttpService } from 'src/app/services/http.service';
import { Component, Inject, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { HttpParams } from '@angular/common/http';

declare var Stripe: any;

@Component({
  selector: 'app-dialog-payement-dialog',
  templateUrl: './dialog-payement-dialog.component.html',
  styleUrls: ['./dialog-payement-dialog.component.scss']
})
export class DialogPayementDialogComponent implements OnInit {

  stripe = Stripe(environment.keys);
  showSpinner = false;
  errorPaiement = false;
  elements;
  card;
  titulaire;
  invalidCard = true;
  modifierCarte = true;
  infosPaiements;
  cardAllImage = [
    {title: 'visa', imgPath: '../../../../assets/images/card/visa.svg'},
    {title: 'mastercard', imgPath: '../../../../assets/images/card/master card.svg'},
    {title: 'amex', imgPath: '../../../../assets/images/card/american-express.png'},
  ];

  constructor(
    private httpService: HttpService, private router: Router, private toastr: ToastrService,
    @Inject(MAT_DIALOG_DATA) public data: any, private dialogRef: MatDialogRef<DialogPayementDialogComponent>) { }

  ngOnInit(): void {
    if (this.data.type === 'create') {
      this.cardInit();
    } else {
      this.stripeInit();
      this.httpService.get('users/info-paiements').subscribe((respData: any) => {
        if (respData.data.response && respData.data.response.data.length > 0) {
          const brandShow = this.cardAllImage.filter(elt => elt.title === respData.data.response.data[0].card.brand);
          if (brandShow.length) {
            respData.data.response.data[0].card.imgPath = brandShow[0].imgPath;
          } else {
            respData.data.response.data[0].card.imgPath = this.cardAllImage[0].imgPath;
          }
          this.infosPaiements = respData.data.response.data[0].card;
          this.modifierCarte = false;
        }
      }, (error => { }));
    }
  }

  cardInit() {
    // this.elements.unmount();
    this.modifierCarte = true;
    this.infosPaiements = undefined;
    this.stripeInit();
  }

  stripeInit() {
    setTimeout(() => {
      this.elements = this.stripe.elements();
      const styleCss = {
        base: {
          color: '#32325d',
        }
      };
      this.card = this.elements.create('card', { hidePostalCode: true, style: styleCss });
      this.card.mount('#cardElement');
      this.card.addEventListener('change', (event) => {
        const displayError = document.getElementById('cardErrors');
        if (event.error || !event.complete) {
          displayError.textContent = event.error?.message;
          this.invalidCard = true;
        } else {
          displayError.textContent = '';
          this.invalidCard = false;
        }
      });
      this.invalidCard = true;
    }, 500);
  }

  validerStep() {
    this.showSpinner = true;
    if (this.data.type === 'payement') {
      // this.btnLoading = true;
      const formData = new FormData();
      formData.set(`is_save`, 'true');
      formData.set(`abonnement_id`, this.data.abns.id);
      if (!this.modifierCarte) {
        formData.append(`use_saved`, 'true');
      } else {
        formData.append(`use_saved`, 'false');
      }

      // if (this.data.abns && this.data.abns.price !== 0) {
      if (this.data.abnsAct && this.data.abns.price !== 0) {
        const body = new HttpParams().set(`abonnement_id`, this.data.abns.id);
        this.httpService.put(`abonnements/change?abonnement_id=${this.data.abns.id}`, body.toString()).subscribe(
          (dataByu: any) => {
            if (this.data.abns.price !== 0 && this.data.abnsAct?.price === 0) {
              if (!this.modifierCarte && dataByu.data.paiement_method_id) {
                this.processPayment(dataByu.data, true);
              } else if (this.modifierCarte) {
                this.processPayment(dataByu.data);
              }
            } else {
              this.showSpinner = false;
              this.dialogRef.close('simpleChange');
            }
          },
          (err: any) => {
            this.toastr.error('erreur');
          }
        );
      } else {
        this.httpService.post('abonnements/buy', formData).subscribe(
          (dataByu: any) => {
            if (!this.modifierCarte && dataByu.data.paiement_method_id) {
              this.processPayment(dataByu.data, true);
            } else if (this.modifierCarte) {
              this.processPayment(dataByu.data);
            }
          },
          (err: any) => {
            this.toastr.error('erreur');
          }
        );
      }
    }
    if (this.data.type === 'create') {
      this.cardCreate();
    }
  }

  processPayment(resultIntent, isValue?) {
    if (isValue) {
      this.stripe.confirmCardPayment(resultIntent.intent_paiement_secret, {
        payment_method: resultIntent.paiement_method_id
      }).then((result) => {
        // this.btnLoading = false;
        // this.showSpinner = false;
        if (result.error) {
          this.toastr.error(result.error.code);
        } else {
          // this.closeStep.emit();
          this.toastr.success('Votre abonnement a bien été pris en compte', '', { timeOut: 5000 });
          // this.router.navigate(['']);
        }
        this.showSpinner = false;
        this.dialogRef.close('payement');
      });
    } else {
      this.stripe.confirmCardPayment(resultIntent.intent_paiement_secret, {
        payment_method: {
          card: this.card,
          billing_details: {
            name: this.titulaire
          }
        }}).then((result) => {
        // this.btnLoading = false;
        // this.showSpinner = false;
        if (result.error) {
          this.toastr.error(result.error.code);
        } else {
          // this.closeStep.emit();
          this.toastr.success('Votre produit a bien été pris en compte', '', { timeOut: 5000 });
          // this.router.navigate(['']);
        }
        this.showSpinner = false;
        this.dialogRef.close('payement');
      });
    }

  }

  async cardCreate() {
    this.stripe.createPaymentMethod({
      type: 'card',
      card: this.card,
      billing_details: {
        name: this.titulaire,
      },
    })
    .then((result) => {
      this.httpService.delete('users/info-paiements').subscribe((respCart: any) => {
        const formData = new FormData();
        formData.set(`pm_id`, result.paymentMethod.id);
        this.httpService.post('users/info-paiements', formData).subscribe((resp: any) => {
          this.showSpinner = true;
          this.dialogRef.close(this.data.type = 'create');
        });
        // Handle result.error or result.paymentMethod
      }, (error => { }));
    });
  }

  cardFormat(brand, lastSSN) {
    if (brand === 'amex') {
      return `XXXX XXXXXX X${lastSSN}`;
    } else {
      return `XXXX XXXX XXXX ${lastSSN}`;
    }
  }

  async cardRemove() {
    await this.httpService.delete('users/info-paiements').subscribe((respCart: any) => {
      // console.log('last cart saved: ', respCart);
    }, (error => { }));
  }
}
