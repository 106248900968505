import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit {

  @Input() mailAdmin;
  sujetContact;
  messageContact;

  mailText = '';
  constructor() { }

  ngOnInit() {
  }

  mailMe() {
    this.mailText = `mailto:${ this.mailAdmin }?subject=${ this.sujetContact }&body=${ this.messageContact }`; // add the links to body
    window.location.href = this.mailText;
  }
}
