import { ProductService } from 'src/app/services/product.service';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpService } from 'src/app/services/http.service';

@Component({
  selector: 'app-vente-list',
  templateUrl: './vente-list.component.html',
  styleUrls: ['./vente-list.component.scss']
})
export class VenteListComponent implements OnInit, OnDestroy {

  page = 1;
  commandAcheteurByIDPaiment;
  CommandAcheteurSubscription: Subscription;
  constructor(
    public productService: ProductService, private router: Router, private activatedRoute: ActivatedRoute,
    private httpService: HttpService) { }

  ngOnInit(): void {
    let pageParam;
    this.activatedRoute.queryParams.subscribe(params => {
      this.page = params.page;
      pageParam = params.page;
    });
    this.productService.getCommandeAcheteur();
    // const pageParam = this.activatedRoute.snapshot.paramMap.get('page');
    if (pageParam) {
      this.getNewPageCommande();
    } else {
      this.CommandAcheteurSubscription = this.productService.CommandeSellerSubject.subscribe((commande) => {
        this.commandAcheteurByIDPaiment = commande;
      });
    }
  }

  prix(itemm) {
    // let prixAcheteur;
    // if(itemm.sous_produit.produit.prix_promotion && itemm.sous_produit.produit.prix_promotion > 0 ){
    //   prixAcheteur = itemm.sous_produit.produit.prix_promotion;
    // }else{
    //   prixAcheteur = itemm.sous_produit.produit.prix;
    // }
    // //prix de vente qui s'affiche dans "detail-commandes" pour le vendeur
    // return (prixAcheteur - ((itemm.sous_produit.produit.id.plateforme.commision * prixAcheteur)/100));
    return itemm.prix_vendeur;
  }

  getColor(itemStatus) {
    if (itemStatus.statut_acheteur === 'LIVREE' || itemStatus.statut_acheteur === 'DECLINEE') {
      return 'red';
    } else {
      return '#2b9746';
    }
  }

  ngOnDestroy() {
    this.CommandAcheteurSubscription.unsubscribe();
  }


  changePage($event) {
    this.page = $event;

    const linkParams = {};
    linkParams['page'] = this.page;
    this.router.navigate(['.'], { relativeTo: this.activatedRoute, queryParams: linkParams });
    this.getNewPageCommande();
  }

  getNewPageCommande() {
    this.httpService.get(`commandes/vendeur/mes?par_page=12&page=${this.page}`).subscribe((resp: any) => {
      this.commandAcheteurByIDPaiment = resp.data.commandes;
    });
  }

}
