<div class="row">
    <div class="col-12 mb-0">
        <div class="header">
            <ul class="breadcrumb mb-0">
                <li [routerLink]="['/compte']"><a>Compte</a></li>
                <li class="active"><a>Frais de livraison</a></li>
            </ul>
        </div>
    </div>
    <div class="col-12 mb-4">
        <h1 class="text-center">Paramétrage frais de livraison</h1>
    </div>

    <div class="col-12">
        <p class="text-center">Actuellement les frais de port sont paramétrés en « Inclus dans le prix de vente » pour tous vos produits.</p>
        <p class="text-center">Si vous souhaitez conserver ce système de frais de port vous n’avez rien à faire (uniquement inclure les frais de port dans le prix de vente lorsque vous mettez en vente vos produits) et cliquer sur VALIDER.
        </p>
        <p class="text-center">Souhaitez vous paramétrer vos frais de port ?</p>
        <p class="text-center">
            Non
            <mat-slide-toggle color="sucess" [(ngModel)]="isAvant"></mat-slide-toggle> Oui
        </p>
        <div class="text-center" *ngIf="!isAvant && !isDefaultValid">
            <button (click)="validerDefault()" class="h-btn-black px-4 py-2">VALIDER</button>
        </div>

        <div *ngIf="isAvant">
            <p class="text-center" id="tete" [ngClass]="{'mb-0': isPret}">Afin de paramétrer les frais de livraison pour votre boutique vous pouvez:</p>
            <p class="mt-0 text-right" *ngIf="isPret || showCase" (click)="showCase = !showCase">
                <a style="color: blue; text-decoration: underline; font-size: 14px;">
                Voir plus
              </a>
            </p>
            <div *ngIf="!isPret || showCase">
                <ul>
                    <li>Option 1 : sélectionner un coût de livraison diffèrent pour chaque article de votre boutique que les clients ajouteront au panier (exemple 4 € pour 1 article au panier, 5 € pour 2 articles au panier, 6 € pour 3 articles et ainsi de
                        suite).
                    </li>
                    <li>Option 2 : sélectionner un coût de livraison diffèrent pour une fourchette d’articles de votre boutique que les clients ajouteront au panier (exemple 4 € entre 1 et 3 articles au panier, 5 € entre 4 et 10 articles au panier, 6 € entre
                        11 et 200 articles au panier).</li>
                    <li>Option 3 : tout simplement mélanger l’option 1 et l’option 2 (exemple 4 € pour 1 article au panier, 5 € entre 2 et 4 articles au panier, 6 € pour 5 articles au panier, 7 € entre 6 et 200 articles au panier).</li>
                </ul>
                <p class="text-center">Nous avons aussi prévu la possibilité d’ajouter des gratuités sur les frais de port à partir d’un certain montant en € et/ou pour un certain nombre d’articles de votre boutiques au panier.</p>
            </div>
        </div>
        <div class="text-center" *ngIf="isAvant">
            <button class="h-btn-black px-4 py-2" *ngIf="!isPret" (click)="scroolTo()">Vous êtes prêts?</button>
        </div>



        <div>

            <div class="row" *ngIf="isPret && isAvant">
                <div class="col-md-6 col-lg-3 py-2">
                    <button (click)="addFraisArticle()" class="w-100 h-100 h-btn-black px-3 py-1">Ajouter les frais pour 1
      article</button>
                </div>
                <div class="col-md-6 col-lg-3 py-2">
                    <button (click)="addFraisArticleRange()" class="w-100 h-100 h-btn-black px-3 py-1">Ajouter les frais pour une
      fourchette d’articles</button>
                </div>
                <div class="col-md-6 col-lg-3 py-2">
                    <button (click)="addGratuitPrix()" class="w-100 h-100 h-btn-black px-3 py-1">Ajouter la
      gratuité à partir d’un montant en €</button>
                </div>
                <div class="col-md-6 col-lg-3 py-2">
                    <button (click)="addGratuitArticle()" class="w-100 h-100 h-btn-black px-3 py-1">Ajouter la gratuité
      à partir d’un nombre d’articles au panier</button>
                </div>
            </div>

            <p *ngFor="let hero of fraisArticle; let ind = index">
                Pour
                <input type="number" [value]="ind + 1" disabled="true" style="width: 30px; text-align: center; font-size: 16px;"> article(s) coût de livraison
                <input type="number" [(ngModel)]="hero.prix" min="12" (ngModelChange)="addGratuitPrix(true)" style="width: 55px; text-align: center; font-size: 16px;"> €
                <span class="suppr" (click)="deleteFraisArticle(ind)">Supprimer</span>
            </p>

            <p *ngFor="let hero of fraisArticleRange; let ind = index">
                Entre
                <input type="number" [value]="hero.minQuantity" disabled="true" style="width: 30px; text-align: center; font-size: 16px;"> et
                <input type="number" [(ngModel)]="hero.maxQuantity" (ngModelChange)="addGratuitArticle(true)" [disabled]="ind != fraisArticleRange.length - 1" style="width: 30px; text-align: center; font-size: 16px;"> articles coût de livraison
                <input type="number" [(ngModel)]="hero.prix" (ngModelChange)="addGratuitPrix(true)" style="width: 55px; text-align: center; font-size: 16px;"> €
                <span class="suppr" (click)="deleteFraisArticleRange(ind)">Supprimer</span>
            </p>

            <p *ngIf="gratuitPrix.state">
                Gratuit à partir de
                <input type="number" [(ngModel)]="gratuitPrix.prix" style="width: 55px; text-align: center; font-size: 16px;"> €
                <span class="suppr" (click)="gratuitPrix.state= !gratuitPrix.state">Supprimer</span>
            </p>

            <p *ngIf="gratuitNb.state">
                Gratuit à partir de
                <input type="number" disabled="true" [(ngModel)]="gratuitNb.nb" style="width: 30px; text-align: center; font-size: 16px;"> articles
                <span class="suppr" (click)="gratuitNb.state= !gratuitNb.state">Supprimer</span>
            </p>
        </div>

        <div class="text-center" *ngIf="isPret && isAvant">
            <button (click)="fraisLivraison()" class="h-btn-black px-4 py-2">VALIDER</button>
            <button (click)="deleteFraisLivraison()" class="h-btn-black px-4 py-2">SUPPRIMER</button>
        </div>
    </div>
</div>