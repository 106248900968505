<div class="container my-5" style=" background-color: '#F6FAF0'">

  <div class="row">
    <div class="col-12 text-center">
      <h1 style="font-size: 2rem;">INFORMATIONS LEGALES HADEEN PLACE</h1>
      <!-- <h2>MENTIONS LEGALES HADEEN PLACE</h2> -->
    </div>
  </div>

  <div class="row">
    <div class="col-12">
      <p class="mb-0" style="font-weight: 600;">Merci de lire ces conditions attentivement avant d'utiliser nos Services. En utilisant nos services, vous acceptez d'être soumis aux présentes conditions.</p>
      <p class="mb-0">Conformément à l’article n°6 de la Loi n°2004-575 du 21 juin 2004 pour la confiance dans l’économie numérique, le(s) responsable(s) du présent site internet et application confirme les mentions d’identification et la politique de confidentialité et de suivantes :</p>

      <h2 class="mb-0">1. Mentions Légales</h2>
      <ul style="list-style: disc;" class="mt-0">
        <li>SAS HADEEN</li>
        <li>Capital social: 1 050 €</li>
        <li>Siret: 884 015 983 00015</li>
        <li>TVA Intracommunautaire : FR61884015983</li>
        <li>Siège social : 82 allée du Petit Pont, 83240 Cavalaire</li>
        <li>Site : https://hadeen-place.fr/</li>
        <li>Responsable éditorial : Jennifer Corna</li>
      </ul>
      <p>Le responsable du présent site et application se réserve le droit d’en modifier le contenu, à tout moment</p>

      <h3 class="mb-1 pl-3" style="font-size: 1.25rem;">1.1 Hébergement du Site et de l’Application :</h3>
      OVH, 2 rue Kellermann 59 000 ROUBAIX
      <p class="mt-0">Site web: <a href="https://www.ovh.com" target="_blank" rel="noopener noreferrer">https://www.ovh.com</a></p>

      <h3 class="mb-1 pl-3" style="font-size: 1.25rem;">1.2 Conception et Développement du Site et de l’Application</h3>
      VatiLab SAS, 24 rue de Clichy, 75009 Paris
      <p class="mt-0">Site web: <a href="https://www.vatilab.com" target="_blank" rel="noopener noreferrer">https://www.vatilab.com/</a></p>

      <h3 class="mb-1 pl-3" style="font-size: 1.25rem;">1.3 Objectif des Contenus présents sur notre Site et Application</h3>
      <p class="mt-0">Le site <a href="https://www.hadeen-place.fr" target="_blank" rel="noopener noreferrer">https://hadeen-place.fr</a> et l’application Hadéen-Place sont des solutions marketplace soit des plateformes multi vendeurs, donnant la possibilité à des TPE et PME françaises de vendre des Articles de la vie quotidienne.</p>

      <h3 class="mb-1 pl-3" style="font-size: 1.25rem;">1.4 Autres Informations</h3>
      Nous vous recommandons de recourir à des navigateurs modernes comme Internet explorer, Safari, Firefox, Google Chrome, etc…pour la navigation sur le site web.
      <p class="mt-0">Si vous constatez une erreur ou un dysfonctionnement, merci de bien vouloir le signaler par email à l’adresse suivante : <a href="mailto:service.client@hadeen-place.fr">service.client@hadeen-place.fr</a></p>
    
    
      <h2 class="mb-0">2. Politique de confidentialité</h2>
      <p class="mt-0">En s’inscrivant sur le Site, l’Utilisateur reconnaît et accepte le traitement de ses données personnelles par Hadéen-Place conformément à la loi applicable et aux stipulations des Conditions Générales d’Utilisation Toutes ces données que vous saisissez sont sécurisées et cryptées pour préserver votre sécurité.</p>

      <h3 class="mb-1 pl-3" style="font-size: 1.25rem;">2.1 But de cette politique de confidentialité :</h3>
      Le but de cette politique de confidentialité est d'informer les utilisateurs de notre site des données personnelles que nous recueillerons ainsi que les informations suivantes, le cas échéant :
      <ul class="px-3" style="list-style: lower-alpha;">
        <li>Les données personnelles que nous recueillerons</li>
        <li>L'utilisation des données recueillies</li>
        <li>Qui a accès aux données recueillies</li>
        <li>Les droits des utilisateurs du site</li>
        <li>La politique de cookies du site</li>
      </ul>
      <p>Cette politique de confidentialité fonctionne parallèlement aux conditions générales d'utilisation de notre site</p>

      <h3 class="mb-1 pl-3" style="font-size: 1.25rem;">2.2 Lois applicables</h3>
      Conformément au Règlement général sur la protection des données (RGPD), cette politique de confidentialité est conforme aux règlements suivants
      <p class="mb-0">Les données à caractère personnel doivent être</p>
      <ul class="px-3" style="list-style: lower-alpha;">
        <li>traitées de manière licite, loyale et transparente au regard de la personne concernée (licéité, loyauté, transparence) ;</li>
        <li>collectées pour des finalités déterminées, explicites et légitimes, et ne pas être traitées ultérieurement d'une manière incompatible avec ces finalités; le traitement ultérieur à des fins archivistiques dans l'intérêt public, à des fins de recherche scientifique ou historique ou à des fins statistiques n'est pas considéré, conformément à l'article 89, paragraphe 1, comme incompatible avec les finalités initiales (limitation des finalités) ;</li>
        <li>adéquates, pertinentes et limitées à ce qui est nécessaire au regard des finalités pour lesquelles elles sont traitées (minimisation des données) ;</li>
        <li>exactes et, si nécessaire, tenues à jour; toutes les mesures raisonnables doivent être prises pour que les données à caractère personnel qui sont inexactes, eu égard aux finalités pour lesquelles elles sont traitées, soient effacées ou rectifiées sans tarder (exactitude) ;</li>
        <li>conservées sous une forme permettant l'identification des personnes concernées pendant une durée n'excédant pas celle nécessaire au regard des finalités pour lesquelles elles sont traitées; les données à caractère personnel peuvent être conservées pour des durées plus longues dans la mesure où elles seront traitées exclusivement à des fins archivistiques dans l'intérêt public, à des fins de recherche scientifique ou historique ou à des fins statistiques conformément à l'article 89, paragraphe 1, pour autant que soient mises en œuvre les mesures techniques et organisationnelles appropriées requises par le règlement afin de garantir les droits et libertés de la personne concernée (limitation de la conservation) ;</li>
        <li>traitées de façon à garantir une sécurité appropriée des données à caractère personnel, y compris la protection contre le traitement non autorisé ou illicite et contre la perte, la destruction ou les dégâts d'origine accidentelle, à l'aide de mesures techniques ou organisationnelles appropriées (intégrité et confidentialité).</li>
      </ul>

      <p class="mb-0">Le traitement n'est licite que si, et dans la mesure où, au moins une des conditions suivantes est remplie :</p>
      <ul class="px-3" style="list-style: lower-alpha;">
        <li>la personne concernée a consenti au traitement de ses données à caractère personnel pour une ou plusieurs finalités spécifiques ;</li>
        <li>le traitement est nécessaire à l'exécution d'un contrat auquel la personne concernée est partie ou à l'exécution de mesures précontractuelles prises à la demande de celle-ci ;</li>
        <li>le traitement est nécessaire au respect d'une obligation légale à laquelle le responsable du traitement est soumis ;</li>
        <li>le traitement est nécessaire à la sauvegarde des intérêts vitaux de la personne concernée ou d'une autre personne physique ;</li>
        <li>le traitement est nécessaire à l'exécution d'une mission d'intérêt public ou relevant de l'exercice de l'autorité publique dont est investi le responsable du traitement ;</li>
        <li>le traitement est nécessaire aux fins des intérêts légitimes poursuivis par le responsable du traitement ou par un tiers, à moins que ne prévalent les intérêts ou les libertés et droits fondamentaux de la personne concernée qui exigent une protection des données à caractère personnel, notamment lorsque la personne concernée est un enfant.</li>
      </ul>

      <h3 class="mb-1 pl-3" style="font-size: 1.25rem;">2.3 Consentement</h3>
        Les utilisateurs conviennent qu'en utilisant notre site, ils consentent à
        <ul class="px-3" style="list-style: lower-alpha;">
          <li>les conditions énoncées dans la présente politique de confidentialité et</li>
          <li>la collecte, l'utilisation et la conservation des données énumérées dans la présente politique.</li>
        </ul>

      <h3 class="mb-1 pl-3" style="font-size: 1.25rem;">2.4 Données personnelles que nous collectons</h3>
      <h3 class="pl-5">2.4.1. Données collectées automatiquement</h3>
        Nous ne collectons aucune donnée automatiquement sur notre site.
      
      <h3 class="pl-5">2.4.2. Données recueillies de façon non automatique</h3>
        <p>Nous pouvons également collecter les données suivantes lorsque vous effectuez certaines fonctions sur notre site.</p>
        <p class="mb-0">La protection de vos données est essentielle pour nous. Les demandes marquées d’un astérisque sont obligatoires afin de vous permettre de continuer une navigation sur notre site sans restriction. Les données collectées concernent :</p>
        <ul class="px-5">
          <li>a) votre identité (nom, prénom, âge)</li>
          <li>b) vos coordonnées (adresse de livraison, facturation, téléphone, mail)</li>
          <li>c) vos données de paiement (protégées par notre prestataire STRIPE)</li>
          <li>d) vos coordonnées web (adresse IP) et géolocalisation</li>
          <li>e) prospection commerciale (acceptation ou refus)</li>
          <li>f) votre navigation sur le site (préférences, ouverture, clic,)</li>
          <li>g) consentement à l’utilisation des données</li>
        </ul>

        <p class="mb-0">Certaines données peuvent être fournies via les réseaux sociaux que vous avez connecté sur notre site. Par votre activité sur nos services, nous pouvons également recueillir les données suivantes :</p>
        <ul class="px-5">
          <li>a) n historique des échanges intervenus entre Hadéen-Place et vous</li>
          <li>b) un historique de vos commandes et ventes via nos services</li>
          <li>c) un historique des opérations financières ou comptables effectuées via nos services</li>
          <li>d) un historique de votre activité sur l’application ou le site (en outre visites et contenus générés en rapport avec votre compte)</li>
          <li>e) si vous êtes Vendeur, le détail de vos évaluations données par vos clients</li>
          <li>f) si vous êtes Acheteur, le détail des évaluations données aux Vendeurs</li>
          <li>g) si vous avez répondu à nos questionnaires, le détail de vos réponses.</li>
        </ul>
        <p>Nous ne recueillerons pas de données supplémentaires sans vous en informer au préalable.</p>
        <p>Ces données nous permettent également de vous inscrire à notre Newsletter, de créer votre compte, finaliser une commande, vous proposer des offres commerciales ciblées , lutte contre la fraude, élaborer des statistiques, des études et des sondages, enquête de satisfaction, afin d’améliorer notre site et notre base de données. Ces données nous sont également essentielles lors de la gestion de votre compte, livraison, retour, remboursement, facturation.</p>

      <h3 class="pl-5">2.4.3. Comment nous utilisons les données personnelles</h3>
        <p>Les données personnelles recueillies sur notre site seront utilisées uniquement aux fins précisées dans la présente politique ou indiquées sur les pages pertinentes de notre site. Nous n'utiliserons pas vos données au-delà de ce que nous divulguerons.</p>
        <p>Les données que nous recueillons lorsque l'utilisateur exécute certaines fonctions peuvent être utilisées aux fins suivantes :</p>
        <p>communication, gestion compte, lutte contre la fraude, enquête de satisfaction, études et sondages, statistiques, amélioration site et base de données</p>

      <h3 class="pl-5">2.4.4. Avec qui nous partageons les données personnelles</h3>
        Toutes les personnes internes ou externes à Hadéen-Place qui utiliseront, recueilleront ou traiteront ces données seront soumis à une obligation contractuelle.
        <h4 style="font-family: 'SFCOmpact Semibold';margin-top: 14px;">Employés</h4>
          Seuls les services habilités par Hadéen-Place ont accès à ces données selon leur degré d’habilitation :
          <ul class="px-5" style="list-style: square;">
            <li>la direction de SAS HADEEN (gestion des litiges, modification ou suppression des données …)</li>
            <li>la section commerciale (concours, prospection, recommandations</li>
            <li>Section relation client (Sondage, statistique, avis …)</li>
          </ul>

        <h4 style="font-family: 'SFCOmpact Semibold';margin-top: 14px;">Tierces parties</h4>
          Nous pouvons partager les données utilisateur avec les tiers suivants :
          <ul class="px-5" style="list-style: lower-alpha;">
            <li>Prestataire de paiement (finalisation de commande, remboursement …)(Stripe)</li>
            <li>le prestataire digital et technique ( hébergement, technicité informatique …)</li>
          </ul>
          <p>Nous pouvons partager les données utilisateur avec des tiers aux fins suivantes : données relatives au paiement et à l'identité. Les tiers ne seront pas en mesure d'accéder aux données des utilisateurs au-delà de ce qui est raisonnablement nécessaire pour atteindre l'objectif donné.</p>

        <h4 style="font-family: 'SFCOmpact Semibold';margin-top: 14px;">Autres divulgations</h4>
          Nous nous engageons à ne pas vendre ou partager vos données avec d'autres tiers, sauf dans les cas suivants
          <ul class="px-5" style="list-style: lower-alpha;">
            <li>si la loi l'exige</li>
            <li>si elle est requise pour toute procédure judiciaire</li>
            <li>pour prouver ou protéger nos droits légaux</li>
            <li>à des acheteurs ou des acheteurs potentiels de cette société dans le cas où nous cherchons à la vendre la société</li>
          </ul>
          <p>Si vous suivez des hyperliens de notre site vers un autre site, veuillez noter que nous ne sommes pas responsables et n'avons pas de contrôle sur leurs politiques et pratiques de confidentialité.</p>

      <h3 class="pl-5">2.4.5. Combien de temps nous stockons les données personnelles</h3>
      Pour des raisons légales les données sont conservées trois ans à compter du dernier échange commercial puis archivées ou rendues anonymes afin de procéder à des études statistiques. Pour ne plus recevoir de publicité vous devez adresser un courrier au siège social de Hadéen-Place.

      <h3 class="pl-5">2.4.6. Comment nous protégeons vos données personnelles</h3>
      <p class="mt-0">Afin d'assurer la protection de votre sécurité, nous utilisons le protocole de sécurité de la couche transport pour transmettre des renseignements personnels dans notre système.</p>
      <p class="mt-0">Toutes les données stockées dans notre système sont bien sécurisées et ne sont accessibles qu'à nos employés. Nos employés sont liés par des accords de confidentialité stricts et une violation de cet accord entraînerait le licenciement de l'employé.</p>
      <p class="mt-0">Alors que nous prenons toutes les précautions raisonnables pour nous assurer que nos données d'utilisateur sont sécurisées et que les utilisateurs sont protégés, il reste toujours du risque de préjudice. L'Internet en sa totalité peut être, parfois, peu sûr et donc nous sommes incapables de garantir la sécurité des données des utilisateurs au-delà de ce qui est raisonnablement pratique.</p>

      <h3 class="pl-5">2.4.7. Mineurs</h3>
      Le RGPD précise que les personnes de moins de 15 ans sont considérées comme des mineurs aux fins de la collecte de données. Les mineurs doivent avoir le consentement d'un représentant légal pour que leurs données soient recueillies, traitées et utilisées.

      <h3 class="pl-5">2.4.8. Vos droits en tant qu'utilisateur</h3>
      Conformément à la loi « Informatique et Libertés » n°78-17 du 6 janvier 1978, l'Utilisateur peut à tout moment accéder aux informations personnelles le concernant qu'il a communiquées au Site, et/ou faire valoir : 
      <ul class="px-5" style="list-style: lower-alpha;">
        <li>droit de rectification</li>
        <li>droit à l'effacement</li>
        <li>droit de restreindre le traitement</li>
        <li>droit à la portabilité des données</li>
        <li>droit d'objection</li>
      </ul>
      Le site hadeen-place.fr est conçu pour être très attentif aux besoins de ses Utilisateurs. 

      <h3 class="pl-5">2.4.9. Comment modifier ; supprimer ou contester les données recueillies</h3>
      Si vous souhaitez que vos renseignements soient supprimés ou modifiés d'une façon ou d'une autre, veuillez communiquer en s'adressant au Service Client via la rubrique Mon Compte > Nous contacter ou par simple lettre (SAS HADEEN, 82 allée du petit pont, 83240 CAVALAIRE), en indiquant ses nom, prénom, date et lieu de naissance, adresse postale et électronique ainsi que son identifiant      
      <h4 style="font-family: 'SFCOmpact Semibold';margin-top: 14px;">Politique sur les cookies</h4>

        Les cookies sont de petits fichiers stockés dans le navigateur lors de l’utilisation d’un site afin de suivre les mouvements lors de le navigation des Utilisateurs sur ce site pour comprendre les préférences, se souvenir des identifiants de connexion, reprendre là où l’Utilisateur a quitté la navigation ou tout autre personnalisation. Les cookies sur notre site ne sont pas utilisés pour révéler votre identité ou tout autre information permettant votre identification personnelle.
        <p>En gérant les cookies depuis le navigateur, l’Utilisateur peut permettre l'arrêt du ciblage publicitaire s’il le souhaite. Cependant en supprimant les cookies l’accès sera limité aux fonctions de bases du site.</p>
        <p>Si l’Utilisateur supprime l'enregistrement de Cookies, ou supprime ceux qui y sont enregistrés, l’Utilisateur ne pourra plus bénéficier de certaines fonctionnalités nécessaires pour naviguer dans certains espaces du Site ou Applications. </p>
        <p>Nous utilisons les types de cookies suivants sur notre site :</p>
        <h3 class="pl-5">2.4.10. Cookies fonctionnels</h3>
          Nous les utilisons pour mémoriser toutes les sélections que vous faites sur notre site afin qu'elles soient sauvegardées pour vos prochaines visites.
        
        <h3 class="pl-5">2.4.11. Cookies analytiques</h3>
          Cela nous permet d'améliorer la conception et la fonctionnalité de notre site en recueillant des données sur le contenu auquel vous accédez et sur lequel vous vous accrochez en utilisant notre site.
          <p>Vous pouvez choisir d'être averti chaque fois qu'un cookie est transmis. Vous pouvez également choisir de désactiver les cookies entièrement dans votre navigateur Internet, mais cela peut diminuer la qualité de votre expérience d'utilisation.</p>
        
        <h3 class="pl-5">2.4.12. Cookies tiers</h3>
          Nous pouvons utiliser des cookies tiers sur notre site pour atteindre les objectifs suivants : cookies de mesure d'audience
      
      <h3 class="pl-3">2.5. Modifications</h3>
      Cette politique de confidentialité peut être modifiée à l'occasion afin de maintenir la conformité avec la loi et de tenir compte de tout changement à notre processus de collecte de données. Nous recommandons à nos utilisateurs de vérifier notre politique de temps à autre pour s'assurer qu'ils soient informés de toute mise à jour. Au besoin, nous pouvons informer les utilisateurs par courriel des changements apportés à cette politique.

      <h3 class="pl-3">2.6. Contact</h3>
      Si vous avez des questions à nous poser, n'hésitez pas à communiquer avec nous en utilisant ce qui suit : 82 allée du Petit Pont, 83240, Cavalaire sur Mer

      <p class="text-end" style="font-weight: 600;">Date d'entrée en vigueur : le 01 décembre 2023</p>
    </div>
  </div>
</div>
