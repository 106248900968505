import { HttpClient } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
import { Component, Inject, OnInit, PLATFORM_ID, Renderer2  } from '@angular/core';
import { HttpService } from '../../../services/http.service';
import { ChatService } from './../../../services/chat.service';

import { ActivatedRoute } from '@angular/router';

import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { isPlatformBrowser, DOCUMENT } from '@angular/common';
import { CommonService } from 'src/app/services/common.service';
import { StorageService } from 'src/app/services/storage.service';


declare var $;

@Component({
  selector: 'app-messagedetail',
  templateUrl: './messagedetail.component.html',
  styleUrls: ['./messagedetail.component.scss']
})
export class MessagedetailComponent implements OnInit {

  url = 'messages/discussions';
  myMessage = 'nei nein';
  user;

  optionsNews = {
    toolbar: [
      'bold', 'italic', 'Underline'
    ],
  };

  test = true;

  urls = [];
  files: any[] = [];
  zoomedImage = '';
  displayModal = 'none';
  showSpinner = false;

  video;
  userAgent;
  iPhone;

  constructor(
    private http: HttpService, public commonService: CommonService, private storageService: StorageService,
    private activatedRoute: ActivatedRoute, private toast: ToastrService, public chatService: ChatService,
    private domSanitizer: DomSanitizer, private httpClient: HttpClient, @Inject(PLATFORM_ID) private platformId,
    private _renderer2: Renderer2, @Inject(DOCUMENT) private _document) {
      this.user = JSON.parse(this.storageService.getItem('User'));
      this.userAgent = navigator.userAgent || navigator.vendor;
      this.loadCkEditor();
  }

  ngOnInit(): void {
    const id = this.activatedRoute.snapshot.params['discussion_id'];
    const link = this.url + '/' + id;
    this.getPlateform();
    this.http.get(link).subscribe(data => {
      data.data.discussion.messages.sort((b, a) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime());
      this.chatService.discussion = data.data.discussion;
    });

  }

  loadCkEditor() {
    const script = this._renderer2.createElement('script');
    script.type = 'application/javascript';
    script.src = 'https://cdn.ckeditor.com/ckeditor5/23.0.0/decoupled-document/ckeditor.js';
    script.text = `
    ${(script.onload = async () => {
        const CKEditor = (window as any).DecoupledEditor;//DecoupledEditor; //ClassicEditor
        const editor = await CKEditor.create(document.querySelector('#editor'),
          {
            toolbar: ['bold', 'italic', 'Underline']
          })
          .then(editor => {
            const toolbarContainer = document.querySelector('#toolbar-container');
            editor.model.document.on( 'change', () => {

              const text = JSON.stringify(editor.getData());
              this.myMessage = text.substr(1,text.length-2);
            } );
            toolbarContainer.appendChild(editor.ui.view.toolbar.element);

            const btn2 = this.createButton('image');
            $('.ck.ck-toolbar.ck-toolbar_grouping>.ck-toolbar__items').prepend(btn2);

          })
          .catch(error => {
            console.error(error);
          });
      })}
    `;
    this._renderer2.appendChild(this._document.body, script);
  }


  async sendMessage() {

      if (this.myMessage) {

        const newMessage = {
          createdAt: new Date(),
          file: null,
          id: 0,
          is_sent: true,
          lu: false,
          message: this.myMessage,
          published_at: {},
          type: null,
          files: this.urls
        };

        if (this.urls.length > 0) {
          this.showSpinner = true;
          const formData = new FormData();
          formData.set('receiver', '' + this.chatService.discussion.receiver.id);
          formData.set('objet', this.chatService.discussion.objet);
          formData.set('message', this.myMessage);
          for (var x = 0; x < this.urls.length; x++) {
            formData.append('files', this.urls[x].file);
          }

          this.http.post('messages', formData).subscribe((resp: any) => {
            this.chatService.discussion.messages.push(newMessage);
            this.showSpinner = false;
            this.toast.success('Message envoyé', '', {
              timeOut: 3000
            });
            this.myMessage = '';
            this.urls = [];
          }, (error => {
            this.toast.error('Il y a erreur : message non envoyé', '', {
             timeOut: 3000
            });
            this.showSpinner = false;
          }));

        } else {
          const message = {
            receiver: this.chatService.discussion.receiver.id,
            objet: this.chatService.discussion.objet,
            message: this.myMessage
          };

          this.chatService.sendMessage(message);
          this.chatService.discussion.messages.push(newMessage);
          this.myMessage = '';
        }

      }
  }

  async urlToObject(image) {
    const proxyUrl = 'https://cors-anywhere.herokuapp.com/';
    const response = await fetch(proxyUrl + image, {
      headers: new Headers({
        'Origin': location.origin,
      }),
      mode: 'cors'
    });
    const blob = await response.blob();
    // const file = new File([blob], 'image.jpg', {type: blob.type});
    const file = new File([blob], 'video.mp4', {type: blob.type});
    return file;
  }


  testDate(d) {
    const today = new Date();
    d = new Date(d);
    const value = Math.floor(Math.floor((today.getTime() - d.getTime()) / 1000 / 60 / 60) / 24);
    return value > 0;
  }

  onReady( editor ) {
    const that = this;
    editor.ui.getEditableElement().parentElement.insertBefore(
        editor.ui.view.toolbar.element,
        editor.ui.getEditableElement()
    );

    // editor.plugins.get('FileRepository').createUploadAdapter = (loader) => {
    //   loader.file.then(fileT => new Promise((resolve, reject) => {
    //     const myReader = new FileReader();
    //     myReader.onload = (e: any ) => {
    //       // that.urls.push(e.target.result);
    //       // that.urls.push({type:file.type,url:e.target.result});
    //       that.urls.push({type: fileT.type, url: e.target.result, name: fileT.name, file: fileT});
    //     };
    //     myReader.readAsDataURL(fileT);

    //   }));
    //   // that.texte="";
    //   return new UploadAdapter(loader);
    // };

    // const elements = $('.ck.ck-button.ck-off');
    // const link = elements[0];
    // link.setAttribute('onclick', `$('#fileinput').trigger('click')`);

    const btn2 = this.createButton('image');
    $('.ck.ck-toolbar.ck-toolbar_grouping>.ck-toolbar__items').prepend(btn2);

    // //Video button
    // const btn = this.createButton();
    // $('.ck.ck-toolbar.ck-toolbar_grouping>.ck-toolbar__items').prepend(btn);

  }

  /** add pièce jointes */
  fileChangeEvent(fileInput) {
    const that = this;
    if (fileInput.target.files && fileInput.target.files[0]) {
      const typeV = fileInput.target.files[0].type;
      const reader = new FileReader();

      reader.onload = (e: any) => {
        that.urls.push({type: typeV, url: e.target.result, name: fileInput.target.files[0].name, file: fileInput.target.files[0]});
      };

      reader.readAsDataURL(fileInput.target.files[0]);
    }
  }

  deleteFile(item) {
    const i = this.urls.findIndex(objet => objet === item);
    this.urls.splice(i, 1);
  }

  isImage(item) {
    const tab = item.trim().split('/');
    return tab[0].toLowerCase() === 'image' || tab[0].toLowerCase() === 'video';
  }

  isVideo(type) {
    return type.split('/')[0] === 'video';
  }

  isSupported(type) {
    const extensions = ['webm', 'mp4', 'ogg'];
    const ext = type.split('/')[1];
    return extensions.includes(ext);
  }

  getExtension(type) {
    return type.split('/')[1];
  }

  getMessageImages(items) {
    return items.filter(file => this.isImage(file.type));
  }

  getMessageOtherFiles(items) {
    const liste = items.filter(file => !this.isImage(file.type));
    return liste;
  }

  getFilename(url) {
    // if (url.length < 50) {
    // }
    const tab = url.trim().split('/');
    return tab[tab.length - 1];
  }

  forceDownload(blob, filename) {
    const a = document.createElement('a');
    a.download = filename;
    a.href = blob;
    document.body.appendChild(a);
    a.click();
    a.remove();
  }

  // Current blob size limit is around 500MB for browsers
  downloadResource(file, filename?) {
    const proxyUrl = 'https://cors-anywhere.herokuapp.com/';
    if (!filename) {
      filename = file.url.split('\\').pop().split('/').pop();
    }
    if (file.name) {
      this.forceDownload(file.url, file.name);
    } else {
      fetch(proxyUrl + file.url, {
          headers: new Headers({
            'Origin': location.origin,
          }),
          mode: 'cors'
        })
        .then(response => response.blob())
        .then(blob => {
          if (isPlatformBrowser(this.platformId)) {
            const blobUrl = window.URL.createObjectURL(blob);
            this.forceDownload(blobUrl, filename);
          }
        })
        .catch(e => console.log(e));
    }

  }

  zoomImage(element) {
    this.zoomedImage = element;
    this.displayModal = 'block';
  }

  createButton(test?) {
    const button = document.createElement('BUTTON');
    if (test) {
      button.innerHTML = `<h5 style="font-size: 18px; margin-right: 12px;">&#x1f4f7;</h5>`;

      button.onclick = () => {
        $('#fileinput').trigger('click');
      };
    } else {
      button.innerHTML = `<h5 style="font-size: 17px; margin-right: 12px;">&#x1F3B6;</h5>`;
      button.onclick = () => {
        $('#videoinput').trigger('click');
      };
    }
    button.classList.add('btn');
    return button;
  }

  playerSrc(file) {
    if (isPlatformBrowser(this.platformId)) {
      const test: SafeResourceUrl = this.domSanitizer.bypassSecurityTrustResourceUrl(window.URL.createObjectURL(file));
      return test;
    }
  }

  playVideo(file?) {
    const proxyUrl = 'https://cors-anywhere.herokuapp.com/';

    fetch(proxyUrl + file.url, {
      headers: new Headers({
        'Origin': location.origin,
      }),
      mode: 'cors'
    })
    .then(response => response.blob())
    .then(blob => {
      file.video = this.playerSrc(blob);
    })
    .catch(e => console.log('error', e));

  }


  getPlateform() {
    if (/android/i.test(this.userAgent)) {
      this.iPhone = false;
    } else if (/iPad|iPhone|iPod/.test(this.userAgent)) {
      this.iPhone = true;
    } else {
      this.iPhone = false;
    }
  }

}

// END MessageDetailsComponent


export class UploadAdapter {
  private loader;
  constructor( loader ) {
     this.loader = loader;
  }

  upload() {
    return Promise.reject();
  }

  abort() {
    return Promise.reject();
  }
}
