<div [ngClass]="isCompte ? 'container-xxl' : 'container'" *ngIf="allAbns && allAbns.abonnements.length">
  <div class="col-12 col-md-12" *ngIf="isCompte">
    <div class="row">
      <div class="col-12 mb-0">
        <div class="header">
          <ul class="breadcrumb mb-0">
            <li [routerLink]="['/compte']"><a>Compte</a></li>
            <li class="active"><a>Mon abonnement</a></li>
          </ul>
        </div>
      </div>
      <div class="col-12 mb-4">
        <h1 class="text-center">MON ABONNEMENT</h1>
      </div>
    </div>
  </div>

  <div class="top-header mb-4" *ngIf="!isCompte">
    <hr>
    <div class="container">
      <div class="header">
        <ul class="breadcrumb mb-0">
          <li><a routerLinkActive="active" [routerLinkActiveOptions]="{exact:true}" routerLink="/">Hadéen-place</a></li>
          <li>Abonnement</li>
        </ul>
      </div>
    </div>
    <div class="text-center">
      <h1>Abonnement</h1>
    </div>
  </div>

  <div class="col-12 p-0" *ngIf="isCompte">
    <div class="row mb-5">
      <div class="col-12 col-md-6">
        <div *ngIf="varAbonnement" class="p-3" style="border: 1px solid #d9d9d9; cursor: pointer; height: 100%">
          <h5 class="card-title">Votre abonnement</h5>
          <div *ngIf="varAbonnement.abonnement">
            <p class="card-text m-0">{{varAbonnement.abonnement.title}}</p>
            <div *ngIf="varAbonnement.abonnement.price > 0">
              <p class="card-text m-0">Status: {{varAbonnement.statut}}</p>
              <div class="row">
                <p class="card-text m-0 col">Debut: {{varAbonnement.achatDate | date:'dd/MM/yyyy'}}</p>
                <p class="card-text m-0 col">Fin: {{varAbonnement.dateFin | date:'dd/MM/yyyy'}}</p>
              </div>
            </div>
          </div>
          <div class="mt-3" *ngIf="varAbonnement.abonnementIdForNextMonth && varAbonnement.abonnementForNextMonthTitle !== varAbonnement.abonnement.title">
            <h5 class="card-title">Prochain abonnement: </h5>
            <p class="card-text m-0">{{varAbonnement.abonnementForNextMonthTitle}}</p>
            <p class="card-text m-0">Debut: {{varAbonnement.dateFin | date:'dd/MM/yyyy'}}</p>
          </div>
        </div>
        <div *ngIf="!varAbonnement || !varAbonnement.abonnement" class="p-3" style="border: 1px solid #d9d9d9; cursor: pointer; height: 100%">
          <p class="text-center my-3 my-md-5">Vous n'avez pas d'abonnement</p>
        </div>
      </div>
      <div class="col-12 col-md-6">
        <div *ngIf="varCart && varCart.response && varCart.response.data.length" class="p-3" style="border: 1px solid #d9d9d9; cursor: pointer; height: 100%">
          <h5 class="card-title">Information de payement</h5>
          <p class="card-text m-0">Bonjour {{varCart.response.data[0].billing_details.name}},</p>
          <p class="card-text m-0">
            <img [src]="varCart.response.data[0].card.imgPath" alt="" style="height: 30px;">
            {{cardFormat(varCart.response.data[0].card.brand, varCart.response.data[0].card.last4)}}
          </p>
          <p class="card-text m-0">Expiration: {{varCart.response.data[0].card.exp_month}} / {{varCart.response.data[0].card.exp_year}}</p>
          <div class="text-center mt-3">
            <button (click)="openDialogPayement(null, 'create')" style="background-color: #345b2b; color: #fff; border: 1px solid #345b2b;">Modifier votre carte</button>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="mb-5"*ngIf="varCart && isCompte">
    <div [ngSwitch]="errorState">
      <div *ngSwitchCase="1">
        <div class="alert alert-danger alert-dismissible fade show pr-2" role="alert">
          <div class="row">
            <div class="col">
              <strong>Votre payement est echoué</strong>
            </div>
            <div class="col-auto">
              <button mat-icon-button class="btn-close" data-bs-dismiss="alert" aria-label="Close">
                <mat-icon >close</mat-icon>
              </button>
            </div>
          </div>
        </div>
      </div>
      <div *ngSwitchCase="2">
        <div class="alert alert-danger alert-dismissible fade show pr-2" role="alert">
          <div class="row">
            <div class="col">
              <strong>Votre cart est perimé</strong>, Le renouvellement de votre abonnement n'as pas abouti. Merci de <span style="text-decoration: underline; cursor: pointer;" (click)="openDialogPayement(null, 'create')">modifier votre carte</span>.
            </div>
            <div class="col-auto">
              <button mat-icon-button class="btn-close" data-bs-dismiss="alert" aria-label="Close">
                <mat-icon >close</mat-icon>
              </button>
            </div>
          </div>
        </div>
      </div>
      <div *ngSwitchCase="3">
        <div class="alert alert-warning alert-dismissible fade show pr-2" role="alert">
          <div class="row">
            <div class="col">
              <strong>La date de validité de votre carte est proche</strong>, Le renouvellement de votre abonnement pourait ne pas aboutir. Merci de <span style="text-decoration: underline; cursor: pointer;" (click)="openDialogPayement(null, 'create')">modifier votre carte</span>.
            </div>
            <div class="col-auto">
              <button mat-icon-button class="btn-close" data-bs-dismiss="alert" aria-label="Close">
                <mat-icon >close</mat-icon>
              </button>
            </div>
          </div>
        </div>
      </div>
      <div *ngSwitchDefault></div>
    </div>
    <!-- <app-abonnement [isTitle]="false" [isCompte]="true"></app-abonnement> -->
  </div>

  <p class="text-center">Sans engagement, modifiables et remboursables : voici les abonnements d'Hadéen-place.</p>
  <p class="text-center">Profitez de nos offres pour mettre en avant votre boutique et vos articles sur notre Home Page, Blog et Réseaux Sociaux mais aussi de nos collaborations avec des influenceurs.</p>
  <p class="text-center">
    <span *ngFor="let item of allAbns.abonnements; let ind=index">
      <span *ngIf="ind === allAbns.abonnements.length - 1"> ou </span>
      « {{item.title}} »
      <span *ngIf="ind <= allAbns.abonnements.length - 1 && ind != allAbns.abonnements.length - 1">, </span>
    </span>
    <!-- « Libre comme l'air », « Green », « Silver » ou « Gold », -->
    vous trouverez forcément l'abonnement qui vous correspond.
  </p>

  <div class="row pt-3 px-2" [ngClass]="{'miniForm': isCompte}" style="background-color: #DEAC8E; border-radius: 15px;">
    <div class="col-6 col-md-6 mb-4 itemAbns px-2" [class]="isFull ? 'col-lg-3' : 'col-lg-6'" *ngFor="let item of allAbns.abonnements; let ind=index">
      <div class="w-100 px-4 abnm_item_imgBox" style="height: 75px;">
        <img *ngIf="item.image" [src]="item.image" alt="" class="w-100 h-100" style="object-fit: cover;">
      </div>
      <div class="card itemAbns2" style="border-radius: 20px; height: 80%;" [class.abns-active]="item.id === abnsSelected" (click)="selectPlan(item)">
        <div class="card-body pt-3 pl-2 pr-2 pb-0">
          <img class="abnm_item_most" *ngIf="item.mostSubscription" src="../../../../assets/images/picto_meilleure_vente.png" alt="" style="border-radius: 50%; width: 60px; height: 60px; position: absolute; top: -35px; right: -20px;">
          <div class="mb-4">
            <div class="mx-auto px-3 abnm_item_price" style="font-family: 'SFCompactDisplay-Regular'; border-radius: 5px; background-color: #AF4C36; color: #FFF; width: fit-content;">
              <span *ngIf="item.price == 0;else elseBlock">FREE</span>
              <ng-template #elseBlock>
                <div class="text-center">{{item.price | number:'0.2-2'}} € HT / mois</div>
              </ng-template>
            </div>
          </div>
          <mat-divider style="border-bottom: 2px solid #AF4C36;"></mat-divider>
          <h5 class="card-title text-center mb-0 abnm_item_title" style="margin-top: -14px;">
            <div class="m-auto px-1" style="font-family: 'SFCompactDisplay-Bold'; width: fit-content; background-color: #FFF; color: #AF4C36; font-weight: 700;">
              {{item.title}}
            </div>
          </h5>
          <h4 style="font-size: 16px; color: #AF4C36;" class="mt-0 my-3 text-center abnm_item_title2" *ngIf="ind===0">{{item.title}}</h4>
          <h4 class="mt-0 my-3 text-center abnm_item_title2" style="font-size: 16px; color: #AF4C36;" *ngIf="ind>0">
            <div style="display: flex; align-items: center;">
              <span class="ml-auto"></span>{{allAbns.abonnements[ind-1].title}} <mat-icon class="mr-auto">add_box</mat-icon>
            </div>
          </h4>
          <div class="row mx-0 mt-2" style="font-family: 'SFCompactDisplay-Regular';" *ngFor="let itemOption of item.options">
            <img class="col-auto p-0 abnm_item_check" src="../assets/images/logo/success.png" alt="" style="width: 20px; height: 20px; margin-top: 3px; color: #AF4C36;">
            <p class="col my-0 text-left abnm_item_option pl-1" style="color: #AF4C36;">{{itemOption.option}}</p>
          </div>
        </div>
        <div class="card-footer pb-4 text-center mt-2" style="background-color: transparent; border-top: none;">
          <p class="mt-4 mb-1 abnm_item_description" style="color: #AF4C36;">
            {{item.description}}
            <img [src]="item.flag" alt="" style="height: 17px; margin-top: -5px;">
          </p>
          <button class="h-btn-green-green" style="font-size: 13px;" (click)="openDialogPayement(item, 'payement')">{{item.btnText}}</button>
        </div>
      </div>
      <p class="mt-2 mb-0 text-center" (click)="openVoirPlusDialog(ind, item.title)" style="text-decoration: underline; cursor: pointer;">Voir plus</p>
    </div>
  </div>

  <h2 class="text-center">Modalités de remboursement</h2>
  <p class="mb-0" style="font-weight: 600;">Remboursable? Oui, 100% remboursable si, malgré le suivi de nos 10 recommandations, vous n'avez pas vendu pour un montant supérieur ou égal à votre abonnement annuel, nous vous remboursons l'intégralité de vos forfaits annuels.</p>
  <p class="mt-0" *ngIf="allAbns.abonnements">Par exemple, si vous avez souscrit à l'offre {{removeChar(allAbns.abonnements[2].title, 'Abonnement')}} à {{allAbns.abonnements[2].price | number:'0.2-2'}}€ (soit {{(allAbns.abonnements[2].price * 12) | number:'0.2-2'}}€ /an) et que le montant de vos ventes via Hadéen-Place n'atteint pas ce montant alors que vous avez respecté les conseils préconisés par la brigade d'Hadéen, vous serez intégralement remboursé de vos abonnements annuels*.</p>

  <p class="mb-0">Voici les 10 recommandations :</p>
  <ul class="mt-0">
    <li>Mettre à jour les photos de vos articles, les mettre en valeur sous leur meilleur angle. (Cela permettra aux utilisateurs de mieux se rendre compte de vos articles en situation. )</li>
    <li>Présenter vos annonces avec des mots clefs pertinents dans vos titres (pour aider les acheteurs à trouver vos articles par le biais de l'outil de recherche)</li>
    <li>Ajouter au moins 10# à votre description (votre référencement sera augmenté de manière considérable)</li>
    <li>Aligner vos prix de vente sur toutes les market-place et votre site web (la cohérence de vos prix est toute aussi importante que les photos)</li>
    <li>Mettre plusieurs photos par article (des photos dans plusieurs situations ou différents fonds rendent l'article plus réel et réduisent les probabilités de retours)</li>
    <li>Mettre le lien URL de votre boutique Hadéen-Place sur vos réseaux sociaux (permettre à vos acheteurs d'acheter rapidement vos articles)</li>
    <li>Proposer un franco de livraison (afin d'encourager les utilisateurs à acheter plusieurs articles dans votre boutique Hadéen)</li>
    <li>Décrire votre boutique et mettre vos valeurs bien en évidence (cette présentation augmentera votre crédibilité auprès des acheteurs)</li>
    <li>Proposer des conditions de retours assez larges ou gratuitement (la confiance de vos acheteurs sera accrue s'ils savent qu'ils peuvent retourner les articles aisément et gratuitement)</li>
    <li>Communiquer régulièrement sur votre boutique Hadéen via vos réseaux sociaux (publication et story)</li>
  </ul>
  <p>*La demande de remboursement de vos abonnements n'est valable seulement pour la première année (à partir de la première souscription) pour un montant maximum de 1198,80€.</p>
</div>
