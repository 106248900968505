<div [ngClass]="item.FullWidth ? 'hero hero--fullwidth container-fluid p-0' : 'container'"
  [ngStyle]="{'margin-top': item.FullWidth ? '0px' : '25px', 'margin-bottom': indBuilder === 0 ? '0px' : '25px'}"
  style="padding: 0px;">

  <div class="hero__image d-none d-md-block" [ngClass]="{'full-width-image': item.FullWidth}">
    <img width="auto" height="auto" *ngIf="item.Image" class="w-100 sliderHome" src="https://strapi.hadeen-place.fr{{ item.Image.url }}" alt=""
      [ngStyle]="{'border-radius': item.FullWidth ? '0px' : '10px'}">
  </div>

  <div class="hero__image d-block d-md-none" [ngClass]="{'full-width-image': item.FullWidth}">
    <img width="auto" height="auto" *ngIf="item.Image" class="w-100 sliderHome" src="https://strapi.hadeen-place.fr{{ item.Image.url }}" alt=""
      [ngStyle]="{'border-radius': item.FullWidth ? '0px' : '10px 10px 0px 0px'}">
  </div>

  <div class="hero__box d-block d-md-none onlyMobil" *ngIf="!isMobile">
    <div style="background-color: #fffffff0; border-radius: 10px; padding: 20px 20px 8px 20px;"
      *ngIf="item.Title || item.ShortDescription">
      <h1 *ngIf="item.isFirst" style="font-size: 18px; font-weight: 700; color: #000000;">{{ item.Title }}</h1>
      <h2 *ngIf="!item.isFirst" style="font-size: 18px; font-weight: 700; color: #000000;">{{ item.Title }}</h2>
      <h2 *ngIf="item.ShortDescription" style="font-size: 13px; font-weight: 400;">{{ item.ShortDescription }}</h2>
      <p *ngIf="item.Link">
        <!-- attr.href="#{{item.Link.InternalAnchor}}" uk-scroll -->
        <a *ngIf="item.Link.InternalAnchor" (click)="redirectButton(item.Link.InternalAnchor)">
          <button
            [ngClass]="item.Link.Type === 'ButtonPrimary' ? 'h-btn-green-green': item.Link.Type === 'h-btn-green-bordered' ? 'h-btn-green-bordered': 'h-btn-link'">{{ item.Link.Title }}
            <!-- <img
              src="{{ item.Link.Type === 'ButtonPrimary' ? '../../../assets/images/right-arrow-white.svg' : item.Link.Type === 'h-btn-green-bordered' ? '../../../assets/images/right-arrow-red.svg' : '../../../assets/images/next-red.svg' }}"
              alt=""> -->
          </button>
        </a>
        <a *ngIf="!item.Link.InternalAnchor && item.Link.ExternalURL" [href]="item.Link.ExternalURL" rel="nofollow"
          target="Blank">
          <button
            [ngClass]="item.Link.Type === 'ButtonPrimary' ? 'h-btn-green-green': item.Link.Type === 'h-btn-green-bordered' ? 'h-btn-green-bordered': 'h-btn-link'">{{ item.Link.Title }}
            <!-- <img
              src="{{ item.Link.Type === 'ButtonPrimary' ? '../../../assets/images/right-arrow-white.svg' : item.Link.Type === 'h-btn-green-bordered' ? '../../../assets/images/right-arrow-red.svg' : '../../../assets/images/next-red.svg' }}"
              alt=""> -->
          </button>
        </a>
        <a *ngIf="!item.Link.InternalAnchor && !item.Link.ExternalURL && item.Link.InternalURL"
          [routerLink]="['/Blog/' + generateUrl(item.Link.InternalURL)]"
          routerLinkActive="router-link-active">
          <button
            [ngClass]="item.Link.Type === 'ButtonPrimary' ? 'h-btn-green-green': item.Link.Type === 'h-btn-green-bordered' ? 'h-btn-green-bordered': 'h-btn-link'">{{ item.Link.Title }}
            <!-- <img
              src="{{ item.Link.Type === 'ButtonPrimary' ? '../../../assets/images/right-arrow-white.svg' : item.Link.Type === 'h-btn-green-bordered' ? '../../../assets/images/right-arrow-red.svg' : '../../../assets/images/next-red.svg' }}"
              alt=""> -->
          </button>
        </a>
      </p>
    </div>
  </div>

  <div class="hero__box d-none d-md-block onlyWeb" style="position: absolute; bottom: 120px;" *ngIf="isMobile"
    [ngStyle]="{'margin-left': item.Link.Align === 'Center' ? '35%' : item.Link.Align === 'Right' ? '60%' : item.FullWidth ? '0px' : '100px'}">

    <!-- width: 350px; -->
    <div style="background-color: #fffffff0; border-radius: 10px; padding: 20px 20px 8px 20px;max-width:370px;"
      [ngStyle]="{'width': item.isFirst ? '350px' : 'auto'}"
      *ngIf="item.Title || item.ShortDescription">

      <h1 *ngIf="item.isFirst && item.Title" style="font-size: 18px; font-weight: 700; color: #707070;">{{ item.Title }}</h1>
      <h2 *ngIf="!item.isFirst && item.Title" style="font-size: 18px; font-weight: 700; color: #707070;">{{ item.Title }}</h2>
      <!-- <div *ngIf="item.Title">
        <h1 style="font-size: 18px; font-weight: 700; color: #707070;" *ngIf="item.isFirst; else elseBlock">EEEE!!!{{ item.Title }}</h1>
        <ng-template #elseBlock>
          <h2 *ngIf="!item.isFirst && item.Title" style="font-size: 18px; font-weight: 700; color: #707070;">EEEEEEE{{ item.Title }}</h2>
        </ng-template>
      </div> -->
      <h2 *ngIf="item.ShortDescription" style="font-size: 13px; margin: 15px 0px 18px; color: #707070; font-weight: 400;">{{ item.ShortDescription }}</h2>
      <p *ngIf="item.Link" id="{{ item.Link.IDs }}" [ngClass]="item.Link.Align === 'Center' ? 'text-center' : item.Link.Align === 'Right' ? 'text-right' : 'text-left'">
        <!-- uk-scroll attr.href="#{{item.Link.InternalAnchor}}" -->
        <a *ngIf="item.Link.InternalAnchor" (click)="redirectButton(item.Link.InternalAnchor)">
          <button (click)="setDataLayer()"
            [ngClass]="item.Link.Type === 'ButtonPrimary' ? 'h-btn-green-green': item.Link.Type === 'h-btn-green-bordered' ? 'h-btn-green-bordered': 'h-btn-link'">{{ item.Link.Title }}
          </button>
        </a>
        <a *ngIf="!item.Link.InternalAnchor && item.Link.ExternalURL" [href]="item.Link.ExternalURL" rel="nofollow"
          target="Blank">
          <button (click)="setDataLayer()"
            [ngClass]="item.Link.Type === 'ButtonPrimary' ? 'h-btn-green-green': item.Link.Type === 'h-btn-green-bordered' ? 'h-btn-green-bordered': 'h-btn-link'">{{ item.Link.Title }}
          </button>
        </a>
        <!-- <a *ngIf="!item.Link.InternalAnchor && !item.Link.ExternalURL && item.Link.InternalURL" [routerLink]="['/' + langue + '/' + item.Link.InternalURL.Slug2]" routerLinkActive="router-link-active"> -->
        <a *ngIf="!item.Link.InternalAnchor && !item.Link.ExternalURL && item.Link.InternalURL"
          [routerLink]="['/Blog/' + generateUrl(item.Link.InternalURL)]"
          routerLinkActive="router-link-active">
          <button (click)="setDataLayer()"
            [ngClass]="item.Link.Type === 'ButtonPrimary' ? 'h-btn-green-green': item.Link.Type === 'h-btn-green-bordered' ? 'h-btn-green-bordered': 'h-btn-link'">{{ item.Link.Title }}
          </button>
        </a>
      </p>
    </div>
  </div>
</div>
