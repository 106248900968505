<!--<div class="header-lg" *ngIf="!type || type === 'fade-out'">-->
<div class="header-lg {{type}}">
  <div class="container-header" (mouseover)="leaveM()" style="background-color: #F6FAF0">
    <div class="container header-menu">
      <div class="row">
        <div class="col-md-4 text-md-center" *ngIf="showHeaders">
          <form [formGroup]="searchFormGroup" (ngSubmit)="searchElastic($event)" style="width: 100%;">
            <mat-form-field appearance="fill" style="max-width: 100%;" class="search_underline">
              <mat-label style="font-size: 14px;font-family: 'SFCompactDisplay-Regular'">Rechercher...</mat-label>
              <mat-icon matPrefix style="margin-right: 14px">search</mat-icon>
              <input type="search" matInput placeholder="Rechercher" formControlName="searchkeyword">
            </mat-form-field>
          </form>
        </div>
        <div class="logo-hadeen" [ngClass]="showHeaders ? 'col-md-4' : 'col-12'">
          <a [routerLink]="['']" routerLinkActive="router-link-active" class="img-logo">
            <!-- <img width="auto" height="auto" src="assets/images/logo/logo2.png" alt="hadeenPlace" class="hadeen-place logo" title="hadeen-place logo" loading="eager" style="width: 330px; height: 60px;"></a> -->
            <img width="auto" height="auto" src="assets/images/hadeen-logo.svg" alt="hadeenPlace" class="hadeen-place logo" title="hadeen-place logo" loading="eager" style="width: 330px; height: 60px;"></a>
        </div>
        <div class="col-md-4" *ngIf="showHeaders">
          <div class="row justify-content-end">
            <div class="col-3 account text-center" dropdown>
              <img width="auto" height="auto" loading="eager" src="assets/images/logo/account.svg" style="width: 21px; height: 26px;" dropdownToggle alt="account hadeen" title="account hadeen">
              <p style="max-height: 24px; overflow: hidden;">{{ commonService.user ? commonService.user.pseudo ? commonService.user.pseudo :
                commonService.user.prenom : 'Compte' }}</p>
              <div *dropdownMenu class="dropdown-menu account-drop" role="menu">
                <ul style="margin-top: 5%;">
                  <li style="font-weight: bold;">Bonjour {{ MenuListServ.name2 }} </li>
                  <li *ngFor="let menu of menuList" (click)="drawer.toggle()" (click)="goTo(menu, loginTemplate)">{{menu}}</li>
                </ul>
                <p style="text-align: center; margin-left:0; cursor: pointer;" (click)="goTo('contact', loginTemplate)">
                  Besoin d’aide? Contactez-nous</p>
              </div>
            </div>
            <div class="col-3 account text-center" (click)="goTo('favoris', loginTemplate)">
              <img width="auto" height="auto" loading="eager" src="assets/images/icons/heart.svg" alt="whishlist hadeen" title="whishlist hadeen" style="width: 25px; height: 26px;">
              <span *ngIf="commonService.user && productS.mesEnvies" class="badge-notif"
                style="background-color: #DC2D4F">{{ productS.mesEnvies.nb_total }}</span>
              <p style="margin-top: 5.5px;">Favoris</p>
            </div>
            <div class="col-3 account text-center" dropdown>
              <img width="auto" height="auto" loading="eager" src="assets/images/logo/panier.svg" alt="panier hadeen" title="panier hadeen" style="width: 25px; height: 26px;" dropdownToggle>
              <span class="badge-notif" style="background-color: #345B2B;" *ngIf="commonService.nbPanier > 0">{{
                commonService.nbPanier }}</span>
              <p style="margin-left: 0; margin-top: 4.6px;">Panier</p>
              <div *dropdownMenu class="dropdown-menu panier-drop" role="menu"
                style="max-height: 500px;overflow-y:auto;overflow-x: hidden;">

                <div class="row m-0 py-2" *ngFor="let item of commonService.paniers;let i=index">
                  <div class="col-3 pr-0">
                    <img width="auto" height="auto" loading="eager" src="{{ item.images[0] }}" alt="panier hadeen" title="panier hadeen" class="panier-img">
                  </div>
                  <div class="col-8">
                    <h4 style="word-break: break-word;">{{ item.sous_produit.produit.model }}</h4>
                  </div>
                  <div class="col-1 pl-0">
                    <button type="button" (click)="removePanier(item.id,i)" style="background: none; border: none;">
                      <span>&times;</span>
                    </button>
                  </div>
                </div>

                <div class="row">
                  <div class="col-12">
                    <div class="d-flex flex-wrap justify-content-around">
                      <p style="font-size: 15px;">Quantité : {{getTotalArticle().totalQte}}</p>
                      <p style="font-size: 15px;">TOTAL:
                        <span style="color: #42594F;">
                          {{ getTotalArticle().totalPrice | number : '1.2-2' }} €
                        </span>
                      </p>
                    </div>
                  </div>
                  <button [routerLink]="['/mon-panier']" style="background-color: #42594F;" class="col-10 signin">VOIR MON
                    PANIER</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!--
<div class="header-lg" *ngIf="type && type === 'fixe fade-in'">
  <div class="container-header" (mouseover)="leaveM()" style="background-color: #DBFBE4">
    <div class="container header-menu">
      <div class="row">
        <div class="logo-hadeen2 col-1" *ngIf="showHeaders">
          <a [routerLink]="['']" routerLinkActive="router-link-active" class="img-logo2">
            <img width="auto" height="auto" loading="eager" src="assets/images/logo/logo6.svg" alt="hadeenPlace" title="hadeenPlace" class="img-logo2" style="width: fit-content; height: 60px;">
          </a>
        </div>

        <div class="col-md-8 mt-auto" *ngIf="showHeaders">
          <form action="" [formGroup]="searchFormGroup" (ngSubmit)="searchElastic($event)" style="width: 100%;">
            <div class="input-group mb-3">
              <input name="search" type="search" enterkeyhint="search" class="form-controlSSS" formControlName="searchkeyword" placeholder="Rechercher..." aria-label="Rechercher..." aria-describedby="button-addon2">
              <div class="input-group-append">
                <button class="btn btn-outline-secondary" type="button" id="button-addon2" style="height: 38px; border-radius: 0px 7px 7px 0px !important;">
                  <mat-icon>search</mat-icon>
                </button>
              </div>
            </div>
          </form>
        </div>

        <div class="col-md-3" *ngIf="showHeaders">
          <div class="row">
            <div class="col-4 account text-center" dropdown>
              <img width="auto" height="auto" src="assets/images/logo/account.svg" style="width: 21px; height: 26px;" dropdownToggle alt="account hadeen" title="account hadeen">
              <p style="max-height: 24px; overflow: hidden;">{{ commonService.user ? commonService.user.pseudo ? commonService.user.pseudo :
                commonService.user.prenom : 'Compte' }}</p>
              <div *dropdownMenu class="dropdown-menu account-drop" role="menu">
                <ul style="margin-top: 5%;">
                  <li style="font-weight: bold;">Bonjour {{ MenuListServ.name2 }} </li>
                  <li *ngFor="let menu of menuList" (click)="drawer.toggle()" (click)="goTo(menu, loginTemplate)">
                    {{menu}}</li>
                </ul>
                <p style="text-align: center; margin-left:0; cursor: pointer;" (click)="goTo('contact', loginTemplate)">
                  Besoin d’aide? Contactez-nous</p>
              </div>
            </div>
            <div class="col-4 account text-center" (click)="goTo('favoris', loginTemplate)">
              <img width="auto" height="auto" loading="eager" src="assets/images/icons/heart.svg" alt="whishlist hadeen" title="whislist hadeen" style="width: 25px; height: 26px;">
              <span *ngIf="commonService.user && productS.mesEnvies" class="badge-notif"
                style="background-color: #DC2D4F">{{ productS.mesEnvies.nb_total }}</span>
              <p style="margin-top: 5.5px;">Favoris</p>
            </div>
            <div class="col-4 account text-center" dropdown>
              <img width="auto" height="auto" loading="eager" src="assets/images/logo/panier.svg" alt="panier hadeen" title="panier hadeen" style="width: 25px; height: 26px;" dropdownToggle>
              <span class="badge-notif" style="background-color: #345B2B;" *ngIf="commonService.nbPanier > 0">{{
                commonService.nbPanier }}</span>
              <p style="margin-left: 0; margin-top: 4.6px;">Panier</p>
              <div *dropdownMenu class="dropdown-menu panier-drop" role="menu"
                style="max-height: 500px;overflow-y:auto;overflow-x: hidden;">

                <div class="row m-0 py-2" *ngFor="let item of commonService.paniers;let i=index">
                  <div class="col-3 pr-0">
                    <img width="auto" height="auto" loading="eager" src="{{ item.images[0] }}" alt="panier hadeen" title="panier hadeen" class="panier-img">
                  </div>
                  <div class="col-8">
                    <h4>{{ item.sous_produit.produit.model }}</h4>
                  </div>
                  <div class="col-1 pl-0">
                    <button type="button" (click)="removePanier(item.id,i)" style="background: none; border: none;">
                      <span>&times;</span>
                    </button>
                  </div>
                </div>

                <div class="row">
                  <div class="col-12">
                    <div class="d-flex flex-wrap justify-content-around">
                      <p style="font-size: 15px;">Quantité : {{commonService.nbPanier}}</p>
                      <p style="font-size: 15px;">TOTAL:
                        <span style="color: #345B2B;">
                          {{ getTotalArticle() | number : '1.2-2' }} €
                        </span>
                      </p>
                    </div>
                  </div>
                  <button [routerLink]="['/mon-panier']" class="col-10 signin">VOIR MON
                    PANIER</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
-->
<!-- LISTING WS -->
<!-- END OF LISTING WS -->

<nav class="navbar navbar-expand-md navbar-light" style="background-color: #F6FAF0;">
  <div class="col-3 p-0">
    <button class="navbar-toggler mt-2 p-0" type="button" style="border: none;"
      (click)="drawer.toggle(); openToggle('Open')">
      <mat-icon aria-hidden="false" aria-label="Example home icon">menu</mat-icon>
    </button>
    <button class="navbar-toggler mt-2 p-0 pl-2" style="border: none;" type="button" data-toggle="collapse"
      data-target="#collapseExample" aria-expanded="false" aria-controls="collapseExample">
      <mat-icon aria-hidden="false" aria-label="Example home icon">search</mat-icon>
    </button>
  </div>

  <div class="col-6 p-0" style="display: flex;height: auto;">
    <a [routerLink]="['']" routerLinkActive="router-link-active" class="img-logo">
      <img width="auto" height="auto" loading="eager" src="assets/images/hadeen-logo.svg" alt="hadeenPlace" title="hadeenPlace" class="img-logo" style="width: 155px; height: auto;">
    </a>
  </div>
  <div class="col-3">
    <div class="row justify-content-end">
      <div class="col-auto account p-2 p-md-4 text-right" (click)="goTo('favoris', loginTemplate)">
        <img width="auto" height="auto" loading="eager" src="assets/images/icons/heart.svg" alt="whishlist" title="whislist" style="width: 20px; height: 21px;">
        <span class="badge-notif" style="background-color: #DC2D4F; position: absolute; width: 16px;" *ngIf="commonService.user && productS.mesEnvies > 0">{{ productS.mesEnvies.nb_total }}</span>
      </div>
      <div class="col-auto account p-2 p-md-4">
        <a [routerLink]="['/mon-panier']"> <img width="auto" height="auto" loading="eager" src="assets/images/logo/panier.svg" alt="panier" title="panier" style="width: 20px; height: 21px;"></a>
        <span class="badge-notif" style="background-color: #345B2B; position: absolute; width: 16px;" *ngIf="commonService.nbPanier > 0">{{ commonService.nbPanier }}</span>
      </div>
    </div>
  </div>

  <div class="collapse w-100" id="collapseExample">
    <form action="" (ngSubmit)="searchElastic($event, 'mobil')">
      <mat-form-field class="w-100" appearance="legacy">
        <mat-label>Rechercher</mat-label>
        <input type="search" id="search" enterkeyhint="search" class="uk-search-input" autocorrect="off" name="search" matInput (keyup.enter)="searchElastic($event, 'mobil')" placeholder="Rechercher..." [(ngModel)]="textSearch">
        <mat-icon matSuffix *ngIf="textSearch" (click)="textSearch = ''">clear</mat-icon>
        <mat-icon class="ml-2" matSuffix (click)="searchElastic($event, 'mobil')">search</mat-icon>
      </mat-form-field>
    </form>
  </div>

</nav>

<mat-drawer-container class="{{example}} " autosize style="z-index: 6;">
  <mat-drawer #drawer class="example-sidenav w-100 p-0" mode="side" style="border-right: solid 1px #42594f4d;border-radius: 1%;">
    <div class="modal-header" style="background-color: #42594F;">
      <div class="container" style="color: white">
        <button type="button" class="close pull-right" aria-label="Close" (click)="drawer.toggle()"
          (click)="openToggle('Close')">
          <span aria-hidden="true" style="color: white">&times;</span>
        </button>
        <p class="hello-burger pull-left m-0 pt-1 newHeaderClass">Bonjour <span style="font-weight: bold;"> {{MenuListServ.name2}}</span></p>
      </div>
    </div>
    <div class="modal-body" style="font-family: SFCompactDisplay-Medium;">
      <mat-list>
        <div *ngFor="let menu of menuList" (click)="goTo(menu, loginTemplate)" (click)="drawer.toggle()"
          (click)="openToggle('Close')">
          <mat-list-item style="height: auto; line-height: 30px;">{{menu}}</mat-list-item>
        </div>
        <div (click)="drawer.toggle()" (click)="openToggle('Close')" [routerLink]="['/Blog']">
          <mat-list-item style="height: auto; line-height: 30px;">Blog</mat-list-item>
        </div>
      </mat-list>
      <div class="mt-4" style="border: 1px solid #345B2B;"></div>
      <mat-list class="mt-3">
        <div *ngFor="let menu of menuWS" (click)="drawer.close()" (click)="openToggle('Close')"
          (click)="menuM(menu, sousMenu, 'enfants', 'xx')">
          <mat-list-item style="height: auto; line-height: 30px;">{{menu.nom}}</mat-list-item>
        </div>
      </mat-list>
    </div>
  </mat-drawer>
</mat-drawer-container>

<ng-template #sousMenu>
  <div class="col-12 pt-3 pb-3" style="background-color: #DBFBE4;">
    <button type="button" style="margin-right: 10px; margin-top: -5px;" class="close pull-right" aria-label="Close"
      (click)="modalMobilMenu.hide()">
      <span aria-hidden="true">&times;</span>
    </button>
    <h4 class="m-0" (click)="retourMenu(drawer)">
      < Retour</h4>
  </div>
  <div class="modal-body" style="font-family: SFCompactDisplay-Medium; background-color: #ffffff;">
    <div class="text-center">
      <h4 style="font-weight: bold">{{titreMobile}}</h4>
    </div>
    <mat-list *ngIf="!sousMenuWS2">
      <mat-list-item style="height: auto; line-height: 30px;" (click)="modalMobilMenu.hide()"
        [routerLink]="['/all-product/' + titreUniver]">Voir tout</mat-list-item>
      <mat-divider></mat-divider>
      <div *ngFor="let menu of sousMenuWS" (click)="menuM(menu, categorie, 'categorie')">
        <mat-list-item style="height: auto; line-height: 30px; padding: 0%;">{{menu.nom}}</mat-list-item>
        <mat-divider></mat-divider>
      </div>
    </mat-list>
    <mat-list *ngIf="sousMenuWS2">
      <div *ngFor="let menu of sousMenuWS2">
        <mat-list-item style="height: auto; line-height: 30px; padding: 0%;"
          (click)="modalMobilMenu.hide(); retourMenu(drawer)"
          [routerLink]="['/all-product/' + titreUniver + '/' + titreSousUniv + '/' + menu.slug]">{{menu.nom}}
        </mat-list-item>
        <mat-divider></mat-divider>
      </div>
    </mat-list>
  </div>
</ng-template>
<ng-template #categorie>
  <div class="modal-header" style="background-color: #DBFBE4;">
    <h4 class="m-0 mt-1">
      < Retour</h4>
        <button type="button" style="margin-right: 10px;" class="close pull-right" aria-label="Close"
          (click)="modalCategorie.hide()">
          <span aria-hidden="true">&times;</span>
        </button>
  </div>

  <div class="modal-body" style="font-family: SFCompactDisplay-Medium; background-color: #ffffff">
    <div class="text-center">
      <h4 style="font-weight: bold">{{titreMobile}}</h4>
    </div>

    <mat-list>
      <div *ngFor="let menu of sousMenuWS">
        <mat-list-item style="height: auto; line-height: 30px;">{{menu.nom}}</mat-list-item>
        <mat-divider></mat-divider>
      </div>
    </mat-list>
  </div>
</ng-template>

<!-- DROPDOWN -->

<!-- END OF DROPDOWN -->
<ng-template #loginTemplate>
  <app-login [modal]="modalRef2" [newsCustomer]="newsCustomer"></app-login>
</ng-template>

<!-- Modal compte non valide -->
<div class="modal fade" id="staticBackdropInvalidAccount" data-backdrop="static" data-keyboard="false" tabindex="-1"
  aria-labelledby="staticBackdropLabel" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-body">
        <p class="text-center newHeaderClass">Votre compte est en attente de validation</p>
        <p class="text-center newHeaderClass">pour toute information n'hesitez pas à nous contacter.</p>
        <div class="text-center mt-3">
          <button type="button" class="h-btn-green" style="padding: 5px 30px; width: fit-content;"
            (click)="closeModal('compte')">Ok</button>
        </div>
      </div>
    </div>
  </div>
</div>
