<div style="margin-bottom: 25px;" [ngStyle]="{'margin-top': item.HeroIndent && screenWidth > 576 ? '-55px' : '20px'}"
  [ngClass]="{'onlyWeb': item.HeroIndent && screenWidth < 576}">
  <mat-card *ngIf="item.Boxed"
    style="border-radius: 15px; padding-top: 30px; padding-bottom: 15px; box-shadow: 0 0 15px rgba(0,0,0,.08);">
    <div class="row pictoHome">
      <div class="col-12 mb-2"
        [ngClass]="item.Columns === 'Two' ? 'col-md-6' : item.Columns === 'Three' ? 'col-md-4' : item.Columns === 'Four' ? 'col-md-3' : ''"
        *ngFor="let itemMiniCard of item.MiniCards" id="{{ itemMiniCard.IDs }}">
        <div class="mini-card--boxed row" *ngIf="item.ImageType === 'Icon'">
          <div class="d-flex">
            <div class="mini-card__img p-0 border-info">
              <img width="auto" height="auto" loading="" *ngIf="itemMiniCard.Image" src="https://strapi.hadeen-place.fr{{ itemMiniCard.Image.url }}" [alt]="itemMiniCard.Title" [title]="itemMiniCard.Title"
                style="width: 60px; height: 60px; border-radius: 50%;">
            </div>
            <div class="mini-card__content px-2" style="width:80%;">
              <h2 class="mini-card__content--title" style="font-weight: 700; font-size: 14px;">{{ itemMiniCard.Title }}</h2>
              <p class="mini-card__content--description col-12 col-md-10 pl-0 mb-0 pr-0"
                style="font-size: 12px; font-weight: 400;color: #707070!important;">
                <span *ngIf="!itemMiniCard.readmore">
                  {{(itemMiniCard.Description && itemMiniCard.Description.length>120)? (itemMiniCard.Description |
                  slice:0:120)+' ...':(itemMiniCard.Description) }}
                  <a *ngIf="itemMiniCard.Description && itemMiniCard.Description.length>120"
                    (click)="readMore(itemMiniCard)" style="color:#70707080;cursor:pointer;">Show more</a>
                </span>
                <span *ngIf="itemMiniCard.readmore">{{itemMiniCard.Description}}
                  <a (click)="readMore(itemMiniCard)" style="color:#70707080;cursor:pointer;">Hide</a>
                </span>
              </p>
              <div>
                <a class="mini-card__content--cta" href="{{itemMiniCard.ExternalURL}}" rel="nofollow"
                  target="{{itemMiniCard.targetType}}" style="text-decoration: none;"
                  *ngIf="itemMiniCard.ExternalURL && itemMiniCard.LinkText">
                  <span style="color: #345b2b; font-size: 13px;">
                    {{ itemMiniCard.LinkText }}
                  </span>
                </a>
                <a class="mini-card__content--cta" style="text-decoration: none;"
                  *ngIf="!itemMiniCard.ExternalURL && itemMiniCard.InternalURL && itemMiniCard.LinkText"
                  [routerLink]="['/Blog/' + generateUrl(itemMiniCard.InternalURL)]">
                  <span style="color: #345b2b; font-size: 13px;">
                    {{ itemMiniCard.LinkText }}
                  </span>
                </a>
              </div>
            </div>
          </div>
        </div>

        <div *ngIf="item.ImageType !== 'Icon'" style="min-height: 118px;">
          <div class="mini-card--boxed row">
            <div class="mini-card__img col-4">
              <img width="auto" height="auto" loading="lazy" class="w-100" src="https://strapi.hadeen-place.fr{{ itemMiniCard.Image.url }}" [alt]="itemMiniCard.Title" [title]="itemMiniCard.Title">
            </div>
            <div class="mini-card__content col-8" style="padding: 0px 15px 0px 0px;">
              <h2 class="mini-card__content--title" style="font-weight: 700; font-size: 14px;">{{ itemMiniCard.Title }}</h2>
              <p class="mini-card__content--description col-12 col-md-10 pl-0 mb-0 pr-0"
                style="font-size: 12px; font-weight: 400; height: 38px; overflow: hidden;">
                {{ itemMiniCard.Description }}</p>
              <div>
                <a class="mini-card__content--cta" href="{{itemMiniCard.ExternalURL}}" rel="nofollow"
                  target="{{itemMiniCard.targetType}}" style="text-decoration: none;"
                  *ngIf="itemMiniCard.ExternalURL && itemMiniCard.LinkText">
                  <span style="color: #345b2b; font-size: 13px;">
                    {{ itemMiniCard.LinkText }}
                  </span>
                </a>
                <a class="mini-card__content--cta" style="text-decoration: none;"
                  *ngIf="!itemMiniCard.ExternalURL && itemMiniCard.InternalURL && itemMiniCard.LinkText"
                  [routerLink]="['/Blog/' + generateUrl(itemMiniCard.InternalURL)]">
                  <span style="color: #345b2b; font-size: 13px;">
                    {{ itemMiniCard.LinkText }}
                  </span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </mat-card>

  <div class="row" *ngIf="!item.Boxed">
    <div class=" col-12 mb-4"
      [ngClass]="item.Columns === 'Two' ? 'col-md-6' : item.Columns === 'Three' ? 'col-12 col-md-6 col-lg-4' : item.Columns === 'Four' ? 'col-md-3' : ''"
      *ngFor="let itemMiniCard of item.MiniCards" id="{{ itemMiniCard.IDs }}">
      <div *ngIf="item.ImageType === 'Icon'"
        style="height: 100%; padding: 15px; box-shadow: 0 0 15px rgba(0,0,0,.08);; border: 1px solid #efefef;">
        <div class="mini-card--boxed row">
          <div class="mini-card__img col-3 pr-0">
            <img width="60px" height="60px" loading="lazy" src="https://strapi.hadeen-place.fr{{ itemMiniCard.Image.url }}"  [alt]="itemMiniCard.Title" [title]="itemMiniCard.Title"
              style="width: 60px; height: 60px; border-radius: 50%;">
          </div>
          <div class="mini-card__content col-9" style="padding-right: 15px;">
            <h2 class="mini-card__content--title" style="font-weight: 700; font-size: 14px;">{{ itemMiniCard.Title }}</h2>
            <p class="mini-card__content--description col-12 col-md-10 pl-0 mb-0 pr-0"
              style="font-size: 12px; font-weight: 400;color: #707070!important;">
              <span *ngIf="!itemMiniCard.readmore">
                {{(itemMiniCard.Description && itemMiniCard.Description.length>120)? (itemMiniCard.Description |
                slice:0:120)+' ...':(itemMiniCard.Description) }}
                <a *ngIf="itemMiniCard.Description && itemMiniCard.Description.length>120"
                  (click)="readMore(itemMiniCard)" style="color:#70707080;cursor:pointer;">Show more</a>
              </span>
              <span *ngIf="itemMiniCard.readmore">{{itemMiniCard.Description}}
                <a (click)="readMore(itemMiniCard)" style="color:#70707080;cursor:pointer;">Hide</a>
              </span>
            </p>
            <div>
              <a class="mini-card__content--cta" href="{{itemMiniCard.ExternalURL}}" rel="nofollow"
                target="{{itemMiniCard.targetType}}" style="text-decoration: none;"
                *ngIf="itemMiniCard.ExternalURL && itemMiniCard.LinkText">
                <span style="color: #345b2b; font-size: 13px;">
                  {{ itemMiniCard.LinkText }}
                </span>
              </a>
              <a class="mini-card__content--cta" style="text-decoration: none;"
                *ngIf="!itemMiniCard.ExternalURL && itemMiniCard.InternalURL && itemMiniCard.LinkText"
                [routerLink]="['/Blog/' + generateUrl(itemMiniCard.InternalURL)]">
                <span style="color: #345b2b; font-size: 13px;">
                  {{ itemMiniCard.LinkText }}
                </span>
              </a>
            </div>
          </div>
        </div>
      </div>

      <div *ngIf="item.ImageType !== 'Icon'"
        style="border-radius: 5px; box-shadow: 0 0 15px rgba(0,0,0,.08);; border: 1px solid #efefef; height: 100%;">
        <div class="mini-card--boxed row m-0 h-100">
          <div class="mini-card__img col-auto m-auto">
            <img width="95px" height="95px" loading="lazy" src="https://strapi.hadeen-place.fr{{ itemMiniCard.Image.url }}" [alt]="itemMiniCard.Title" [title]="itemMiniCard.Title"
              style="width: 95px; height: 95px; object-fit: cover;">
          </div>
          <div class="mini-card__content col" style="padding: 15px 10px 15px 0px;">
            <div style="height: 85%;">
              <h2 class="mini-card__content--title" style="font-weight: 700; font-size: 14px;">{{ itemMiniCard.Title }}</h2>
              <p class="mini-card__content--description col-12 col-md-11 pl-0 mb-0 pr-0"
                style="font-size: 12px; font-weight: 400;color: #707070!important; height: 70%;">
                <span *ngIf="!itemMiniCard.readmore">
                  {{(itemMiniCard.Description && itemMiniCard.Description.length > 80) ? (itemMiniCard.Description |
                  slice:0:80)+' ...':(itemMiniCard.Description) }}
                  <a *ngIf="itemMiniCard.Description && itemMiniCard.Description.length > 80"
                    (click)="readMore(itemMiniCard)" style="color:#70707080;cursor:pointer;">Show more</a>
                </span>
                <span *ngIf="itemMiniCard.readmore">{{itemMiniCard.Description}}
                  <a (click)="readMore(itemMiniCard)" style="color:#70707080;cursor:pointer;">Hide</a>
                </span>
              </p>
            </div>
            <div>
              <a class="mini-card__content--cta" href="{{itemMiniCard.ExternalURL}}" rel="nofollow"
                target="{{itemMiniCard.targetType}}" style="text-decoration: none;"
                *ngIf="itemMiniCard.ExternalURL && itemMiniCard.LinkText">
                <span style="color: #345b2b; font-size: 13px;">
                  {{ itemMiniCard.LinkText}}
                </span>
              </a>
              <a class="mini-card__content--cta" style="text-decoration: none;"
                *ngIf="!itemMiniCard.ExternalURL && itemMiniCard.InternalURL && itemMiniCard.LinkText"
                [routerLink]="['/Blog/' + generateUrl(itemMiniCard.InternalURL)]">
                <span style="color: #345b2b; font-size: 13px;">
                  {{ itemMiniCard.LinkText}}
                </span>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
