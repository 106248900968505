import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { StorageService } from './storage.service';

@Injectable({
  providedIn: 'root'
})
export class MenuListService {
  items: any;
  // private menuSubject: BehaviorSubject<any> = new BehaviorSubject(['Je suis nouveau client', 'J\'ai déjà un compte', 'Devenir Vendeur']);
  private menuSubject: BehaviorSubject<any> = new BehaviorSubject(['Je suis nouveau client', 'J\'ai déjà un compte']);
  private name: BehaviorSubject<any> = new BehaviorSubject([', Identifiez-vous']);
  private tokenGlobal: BehaviorSubject<any> = new BehaviorSubject(['']);
  private userSubject: BehaviorSubject<any> = new BehaviorSubject(['']);

  public liste = [];
  name2 = ', Identifiez-vous';
  public isNewCustomer = false;

  constructor(private storageService?: StorageService) {
    this.menuSubject.subscribe(_ => this.items = _);
    this.name.subscribe(_ => this.items = _);
    this.tokenGlobal.subscribe(_ => this.items = _);
    this.userSubject.subscribe(_ => this.items = _);
   }
  setMenu(a) {
    this.menuSubject.next(a);
  }
  getMenu(): Observable<any> {
    return this.menuSubject.asObservable();
  }
  setName(a) {
    this.name.next(a);
  }
  getName(): Observable<any> {
    return this.name.asObservable();
  }
  setToken(a) {
    this.tokenGlobal.next(a);
  }
  getToken(): Observable<any> {
    return this.tokenGlobal.asObservable();
  }
  setUser(a) {
    this.userSubject.next(a);
  }
  getUser(): Observable<any> {
    return this.userSubject.asObservable();
  }

  setMenuList() {
    // const user = localStorage.getItem('User');
    const user = this.storageService.getItem('User');
    if (user) {
      const userJson = JSON.parse(user);
      if (userJson.role === 'ACHETEUR' || userJson.etapeVendeur <= 2) {
        // this.setMenu(['Devenir Vendeur', 'Votre compte', 'Vos commandes', 'Favoris', 'Vos messages', 'Se déconnecter']);
        this.setMenu(['Votre compte', 'Vos commandes', 'Favoris', 'Vos messages', 'Se déconnecter']);
      } else if (userJson.role === 'VENDEUR_VALIDEE' || userJson.etapeVendeur === 3) {
        // tslint:disable-next-line: max-line-length
        this.setMenu(['Ajouter un produit', 'Votre compte', 'Vos commandes', 'Favoris', 'Vos messages', 'Votre Boutique', 'Se déconnecter']);
      } else {
        // tslint:disable-next-line: max-line-length
        // this.setMenu(['Ajouter un produit', 'Devenir Vendeur', 'Votre compte', 'Vos commandes', 'Favoris', 'Vos messages', 'Se déconnecter']);
        this.setMenu(['Ajouter un produit', 'Votre compte', 'Vos commandes', 'Favoris', 'Vos messages', 'Se déconnecter']);
      }
      const aaa = userJson.pseudo ? userJson.pseudo : userJson.prenom;
      this.name2 = `, ${aaa}`;
    } else {
      // tslint:disable-next-line: max-line-length
      this.setMenu(['Je suis nouveau client', 'J\'ai déjà un compte']);
      this.name2 = `, Identifiez-vous`;
    }
  }
}
