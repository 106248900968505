import { ProductService } from 'src/app/services/product.service';
import { CommonService } from 'src/app/services/common.service';
import { Component, OnInit, ViewChild } from '@angular/core';

import {BreakpointObserver, Breakpoints} from '@angular/cdk/layout';
import { Router } from '@angular/router';

import {style, state, animate, transition, trigger} from '@angular/animations';
import { ChatService } from 'src/app/services/chat.service';
import { StorageService } from 'src/app/services/storage.service';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MenuListService } from 'src/app/services/menu-list.service';
import { HttpService } from 'src/app/services/http.service';

declare var $;

declare var $;

@Component({
  selector: 'app-compte-nav',
  templateUrl: './compte-nav.component.html',
  styleUrls: ['./compte-nav.component.scss']
})
export class CompteNavComponent implements OnInit {

  showMenuListeAchat = false;
  showMenuMesCommandes = false;
  breakpointObserver;
  user;
  messageText;


  constructor(
    public commonService: CommonService, public productService: ProductService, public chatService: ChatService,
    breakpointObserver: BreakpointObserver, private router: Router, private storageService: StorageService,
    private dialog: MatDialog, public MenuListServ: MenuListService, private httpService: HttpService) {
      this.breakpointObserver = breakpointObserver;
  }

  ngOnInit(): void {
    this.user = JSON.parse(this.storageService.getItem('User'));
  }

  goTo(link) {
    const isSmallScreen = this.breakpointObserver.isMatched('(max-width: 599px)');
    if (isSmallScreen) {
      if (link === 'mes-achats') {
        this.showMenuListeAchat = !this.showMenuListeAchat;
      } else{
        this.showMenuMesCommandes = !this.showMenuMesCommandes;
      }
    } else{
      this.showMenuListeAchat = false;
      this.router.navigate(['/compte/' + link]);
    }
  }

  goTo2(link) {
    if (this.user.etapeVendeur === 3 && this.user.role === 'VENDEUR_VALIDEE') {
      this.router.navigate([`/compte/${link}`]);
    } else {
      this.openModal();
    }
  }

  openModal() {
    $('#staticBackdropInvalidAccount').modal('show');
  }

  closeModal(statex) {
    $('#staticBackdropInvalidAccount').modal('hide');
    if (state) {
      this.router.navigate(['/' + statex]);
    }
  }

  openModalContact() {
    $('#modalContactModerateur2').modal('toggle');
  }

  sendMessage() {
    const idModerateur = 14;
    const messageToModerateur = {
      receiver: idModerateur,
      objet: 'Hadeen',
      message: this.messageText
    };
    this.chatService.sendMessage(messageToModerateur);
  }

  supprimerCompte() {
    const dialogRef = this.dialog.open(DialogConfirmation, {
      maxWidth: '350px'
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
      if (result) {
        this.httpService.delete(`users/delete-mon-compte`).subscribe(response => {
          this.storageService.removeItem('Token');
          this.storageService.removeItem('User');
          this.storageService.removeItem('Details');
          this.storageService.removeItem('Vendeur');
          this.storageService.removeItem('Boutique');
          this.storageService.removeItem('tokenCompany');
          this.storageService.removeItem('etape1');
          this.commonService.getPanier();
          this.commonService.reinitCodePromo();
          this.commonService.user = null;
          this.router.navigate(['']);
          this.MenuListServ.setMenuList();
          this.MenuListServ.setName([ ', Identifiez-vous']);
        })
      }
    });
  }
}


@Component({
  selector: 'dialog-confirmation',
  templateUrl: 'dialog-confirmation.html',
})
export class DialogConfirmation {
  constructor(public dialogRef: MatDialogRef<DialogConfirmation>) {}

  onNoClick(): void {
    this.dialogRef.close();
  }
}