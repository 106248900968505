<!-- <div [innerHTML]="responseEx"></div> -->

<div class="container">
  <div class="top-header">
    <div class="container">
      <div class="header d-none d-md-block">
        <ul class="breadcrumb">
          <li [routerLink]="['/']"><a>Hadéen-place</a></li>
          <li *ngIf="univer" [routerLink]="['/all-product', nom_univerFormated]"> {{ nom_univer | univerPipe }} </li>
          <li *ngIf="nom_sousUniver"> {{nom_sousUniver | univerPipe}} </li>
        </ul>
      </div>
    </div>
    <hr>
  </div>

  <div class="row">
    <div class="col-12 my-3 mis_en_avant" *ngIf="univer">
      <div class="text-center" style="position: absolute; top: 50%; left: 50%; transform: translate(-50%, -50%); text-shadow: #fff 1px 1px, #fff -1px 1px, #fff -1px -1px, #fff 1px -1px;">
        <h1 *ngIf="univer.nom" loading="lazy" style="font-family: 'SFCompactDisplay-Regular'">{{univer.nom}} <span style="font-size: 0px;">articles eco responsables</span></h1>
      </div>
      <div class="ratio image_head">
        <img [src]="univer.photo" class="w-100 h-100" loading="lazy" width="auto" height="auto" style="object-fit: cover;" [title]="univer.translation.traduction1 || univer.nom" [alt]="univer.translation.traduction1 || univer.nom">
      </div>
    </div>

    <!-- <div id="descrUniver" #descrUniver class="offset-xl-1 col-xl-10 mb-3" *ngIf="univer">
      {{univer.description}}
      <p [ngClass]="{'limitTextHeight': isReadMore}">
        {{univer.description}}
      </p>
      <div *ngIf="univer.description && univer.description.length > 50" class="text-right mt-2 d-block d-md-none" style="font-size: 13px;"><a (click)="showText()">{{ isReadMore ? '... Voir plus': 'Voir moins ...' }}</a></div>
    </div> -->

    <div class="offset-xl-1 col-xl-10 mb-3" *ngIf="univer && univer.description2" id="descrUniver">
      <p class="mb-1 text-justify" [ngClass]="{'limitTextHeight': isReadMore}" [innerHTML]="univer.description2"></p>
      <div class="text-right">
        <button type="button" style="background: none; border: none; color: blue;" (click)="isReadMore=!isReadMore">
            {{ isReadMore ? '... voir plus': 'voir moins ...' }}
        </button>
      </div>
    </div>

  </div>

  <div class="row justify-content-md-center" style="flex-flow: nowrap; overflow: auto; -ms-flex-flow: nowrap; -webkit-overflow-scrolling: auto;">
    <div class="enPromotion col-6 col-sm-4 col-md-3 col-lg-auto text-center" style="width:150px; cursor: pointer;" *ngFor="let item of misEnAvant" (click)="navigateTo('avant', item)">
      <img [src]="item.image" loading="lazy" width="auto" height="auto" [title]="item.sous_univers ? item.sous_univers.translation.traduction1 || item.name : item.univers.translation.traduction1 || item.name" [alt]="item.sous_univers ? item.sous_univers.translation.traduction1 || item.name : item.univers.translation.traduction1 || item.name" style="width: 110px; height: 110px;">
      <h2 style="margin-top: 0px; font-size: 1.3rem; white-space: pre-line; font-size: 16px;">{{item.name}} <span style="font-size: 0px;">articles eco responsables</span></h2>
    </div>
  </div>
  <mat-divider class="m-auto" style="width: 80%; border-top-width: 2px;"></mat-divider>
</div>

<div class="container-lg">
  <div class="sous row mt-5">
    <div class="sous-item col-6 col-md-3 mb-4" *ngFor="let item of sousList">
      <div class="item_univer" style="cursor: pointer;" (click)="navigateTo('sous', item)">
        <div class="ratio" style="aspect-ratio: 1/1.2;">
          <img [src]="item.photo" loading="lazy" width="auto" height="auto" [title]="item.translation.traduction1 || item.nom" [alt]="item.translation.traduction1 || item.nom" class="w-100 h-100" style="object-fit: cover;">
        </div>
        <div class="content text-center">
          <h2 *ngIf="item.nom" class="m-0 pt-4">{{item.nom | uppercase}}</h2>
          <h3 *ngIf="item.titre" class="m-0" style="font-size: .8rem; color: #000;">{{item.titre}}</h3>
        </div>
      </div>
    </div>
  </div>
</div>