import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { ProductService } from 'src/app/services/product.service';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { ChatService } from 'src/app/services/chat.service';
import { CommonService } from 'src/app/services/common.service';

declare var $;
declare var tp;

@Component({
  selector: 'app-detail-vente',
  templateUrl: './detail-vente.component.html',
  styleUrls: ['./detail-vente.component.scss']
})
export class DetailVenteComponent implements OnInit, OnDestroy {

  @ViewChild('closeModalContact') closeModalContact;
  @ViewChild('closeModalNote') closeModalNote;

  fraisLivraison = 0;
  commandeId;
  commandShopper = [];
  commadShopperById: any[];

  maxRate = 5;
  noteActuelle = 1.0;

  noteSellerForm: FormGroup;
  contactSellerForm: FormGroup;
  contactSellerByForm: FormGroup;

  commandeShopperSubscription: Subscription;

  commandSelected;
  commandSelectedCommentaire;
  elementSelectedNoteSeller;
  elementSelectedModal;
  page;

  constructor(
    private productService: ProductService,
    private activatedRoute: ActivatedRoute,
    private formBuilder: FormBuilder,
    private chatService: ChatService, public commonService: CommonService) { }

  ngOnInit(): void {
    this.activatedRoute.queryParams.subscribe(params => {
      this.page = params.p;
    });
    this.commandeId = this.activatedRoute.snapshot.paramMap.get('idCommande');
    this.initComponent();
    this.initForm();
  }

  initForm() {
    this.noteSellerForm = this.formBuilder.group({
      comment: ['']
    });

    this.contactSellerForm = this.formBuilder.group({
      message: ['', Validators.required]
    });

    this.contactSellerByForm = this.formBuilder.group({
      article: ['', Validators.required],
      Message: ['', Validators.required]
    });
  }

  totalPriceCommande(products, withPromo?) {
    let total = 0;
    if (products.length) {
        products.forEach(element => {
          total += element.prix_acheteur;
        });
    }
    return total;
  }

  totalPromo(products) {
    let remise = 0;
    if (products.length) {
      const product = products.find(e => e.code_promo && e.code_promo !== null);
      const codePromo = product ? product.code_promo : undefined;
      if (codePromo && codePromo.activated) {
        if (codePromo.type === 'POURCENTAGE') {
          const total = this.totalPriceCommande(products);
          remise = total * (+codePromo.valeur) / 100;
        } else {
          remise = codePromo.valeur;
        }
      }
    }
    return remise;
  }

  initComponent() {
    this.productService.getCommandeAcheteur(this.page);
    this.commandeShopperSubscription = this.productService.CommandeShopperSubject.subscribe(
      (commandShoppers: any[]) => {
        this.commandShopper = commandShoppers;
        this.commadShopperById = this.commandShopper.filter(elt => elt.paiement.id === parseInt(this.commandeId, 10));
        this.calculFraisDeLivraison();
      }
    );
    this.productService.emitCommandeShopperSubject();
  }

  // confirmation de la commande
  onConfirmation(commandes) {
    const id = commandes.id;
    this.productService.confirmation(id);
    this.initComponent();
    document.addEventListener('DOMContentLoaded', () => {
      const trustpilot_invitation = {
        recipientEmail: this.commonService.user.email,
        recipientName: this.commonService.user.pseudo,
        referenceId: commandes.id,
        source: 'InvitationScript',
      };
      tp('createInvitation', trustpilot_invitation);
    });
    this.productService.emitCommandeShopperSubject();

  }

  // getColorStatus
  getColor(i) {
    if (this.commadShopperById[i].statut_acheteur === 'LIVREE' || this.commadShopperById[i].statut_acheteur === 'DECLINEE') {
      return 'red';
    } else {
      return '#2b9746';
    }
  }

  onItemNoteSeller(item) {
    this.elementSelectedNoteSeller = item;
  }

  // note vendeur
  onSendNoteVendeur() {
    const formValue = this.noteSellerForm.value;
    const comment = formValue['comment'];
    this.noteSellerForm.patchValue({
      comment: ''
    });
    const starVar = this.noteActuelle;
    const vendeurId = this.elementSelectedNoteSeller.vendeur.id;

    const objet = {
      idVendeur: vendeurId,
      star: starVar,
      idCommand : this.elementSelectedNoteSeller.id,
      commentaire : comment
    };

    this.productService.sendNoteVendeur(objet);
    this.closeModalNote.nativeElement.click();
    this.productService.emitCommandeShopperSubject();

    this.commadShopperById.forEach(cmd => {
      if (cmd.id === this.elementSelectedNoteSeller.id) {
        cmd.is_feed_vendeur = true;
      }
    });
  }

  onItemShoppers(commandeShopper) {
    this.elementSelectedModal = commandeShopper;
  }

  // envoie message au vendeur
  onContactVendeur() {
    const formValue = this.contactSellerForm.value;
    const msg = formValue['message'];
    this.contactSellerForm.patchValue({
      message: ''
    });
    const message = {
      receiver: this.elementSelectedModal.vendeur.id,
      objet: `Nom: ${this.elementSelectedModal.sous_produit.produit.model}, Commande N°: ${this.elementSelectedModal.id}`,
      message: msg,
    };
    this.chatService.sendMessage(message);
    this.closeModalContact.nativeElement.click();
    this.productService.emitCommandeShopperSubject();
  }

  // detail-acheteur formulaire de contact
  onContactSellerByCommande(itemCommadShopperById) {
    const form = this.contactSellerByForm.value;
    const Message = form['Message'];
    const article = form['article'];
    itemCommadShopperById = itemCommadShopperById.filter(elt => elt.sous_produit.produit.model === article);
    this.contactSellerByForm.patchValue({
      Message : ''
    });
    const message = {
      receiver: 14,
      objet: `Nom: ${itemCommadShopperById[0].sous_produit.produit.model}, Commande N°: ${itemCommadShopperById[0].id}`,
      message: Message
    };
    this.chatService.sendMessage(message);
    this.productService.emitCommandeShopperSubject();
  }


  addNoteProduit() {
    const objet = {
      id: this.commandSelected.sous_produit.produit.id.produitId,
      star: this.commandSelected.sous_produit.produit.star,
      idCommand : this.commandSelected.id
    };
    if (this.commandSelectedCommentaire !== undefined) {
      objet['comment'] = this.commandSelectedCommentaire;
    }
    this.productService.addFeedbackProduit(objet);
    this.commadShopperById.forEach(cmd => {
      if (cmd.id === this.commandSelected.id) {
        cmd.is_feed_produit = true;
      }
    });
  }

  openModalProduit(product) {
    this.commandSelectedCommentaire = undefined;
    this.commandSelected = product;
    this.commandSelected.sous_produit.produit.star = +this.commandSelected.sous_produit.produit.star;
    $('#modalNoteProduit').modal('toggle');
  }

  //
  ngOnDestroy() {
    this.commandeShopperSubscription.unsubscribe();
  }

  calculFraisDeLivraison() {
    this.fraisLivraison = 0;
    this.commadShopperById.forEach(element => {
      this.fraisLivraison += element.frais_livraison;
    });
  }
}
