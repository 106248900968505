<div style="background-color: #F6FAF0;">
  <div class="container my-5">
    <div class="row">
      <div class="col-sm-12 col-md-8 offset-md-2 text-center" style="background-color: transparent !important;;">
        <p class="pourquoiClass text-center mb-3" style="color: #345B2B; font-size: 28px; font-family: 'DIN Bold';">POURQUOI HADÉEN-PLACE?</p>
        <p style="font-size: 14px; letter-spacing: 0.3px; line-height: 1.2rem; margin: 0px 0px 10px 0px !important; color: #000" class="text-center">
          Hadéen Place est la nouvelle plateforme de vente française.
          <br>- 100% de nos vendeurs sont des sociétés françaises.
          <br>- 100% de ces entreprises sont des artisans, auto-entrepreneurs, TPE ou PME
        </p>

        <p style="font-size: 14px; letter-spacing: 0.3px; line-height: 1.2rem; margin: 0px 0px 10px 0px !important; color: #000" class="text-center">
          Chaque article correspond au moins à un impact écologique :
          <br>Made in France, Circuit court, Vegan, Bio, 100% recyclable, Éco responsable, Fait main, 100% d’ingrédients naturels,
            Issu du commerce équitable, À partir de matériaux recyclés, Sans cruauté animale, Zéro déchet, et tant d’autres …
        </p>
      </div>
    </div>
  </div>
</div>
