import { HttpClient } from '@angular/common/http';
import { Component, OnInit, Input  } from '@angular/core';
import { environment } from 'src/environments/environment';



@Component({
  selector: 'app-minicard-group',
  templateUrl: './minicard-group.component.html',
  styleUrls: ['./minicard-group.component.scss']
})
export class MinicardGroupComponent implements OnInit {

  @Input() item: any;
  @Input() indBuilder: any;
  @Input() screenWidth: any;
  langue;

  constructor(private httpClient: HttpClient) { }

  ngOnInit() {
    // this.langue = localStorage.getItem('langueName');
    this.item.MiniCards.forEach(etlMinicard => {
      if (etlMinicard.InternalURL) {
        etlMinicard.InternalURL['Title2'] = etlMinicard.InternalURL.Title.trim().split(' ').join('_');
        etlMinicard.InternalURL['Slug2'] = etlMinicard.InternalURL.Slug.trim().split(' ').join('-');
        if (etlMinicard.InternalURL && etlMinicard.InternalURL.parent_parent_page) {
          // tslint:disable-next-line: max-line-length
          this.httpClient.get(`${environment.strapi_url}pages/${etlMinicard.InternalURL.parent_parent_page}`).subscribe((dataCurrentPage: any) => {
            etlMinicard.InternalURL.parent_parent_page = dataCurrentPage;
          });
        }
        if (etlMinicard.InternalURL && etlMinicard.InternalURL.parent_page) {
          // tslint:disable-next-line: max-line-length
          this.httpClient.get(`${environment.strapi_url}pages/${etlMinicard.InternalURL.parent_page}`).subscribe((dataCurrentPage: any) => {
            etlMinicard.InternalURL.parent_page = dataCurrentPage;
          });
        }
      }
      if (etlMinicard.ExternalURL && (etlMinicard.ExternalURL).includes('onthemountain')) {
        etlMinicard.targetType = '';
      } else {
        etlMinicard.targetType = 'Blank';
      }
    });
  }

  readMore(text) {
    if (text['readmore']) {
      delete text['readmore'];
    } else {
      text['readmore'] = true;
    }
  }

  // getTitle2(title) {
  //   return title.trim().split(' ').join('_');
  // }

  generateUrl(page) {
    let url = '';
    if (page.parent_parent_page) {
      url += page.parent_parent_page.Slug + '/';
    }
    if (page.parent_page) {
      url += page.parent_page.Slug + '/';
    }
    return url += page.Slug;
  }
}
