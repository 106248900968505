import { CommonService } from './../../../services/common.service';
import { ChatService } from './../../../services/chat.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, ElementRef, Inject, OnInit, PLATFORM_ID, TemplateRef, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbRatingConfig } from '@ng-bootstrap/ng-bootstrap';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { AppComponent } from 'src/app/app.component';
import { environment } from 'src/environments/environment';
import { Meta, Title } from '@angular/platform-browser';
import { isPlatformBrowser } from '@angular/common';

import * as FormDataServer from 'form-data';

@Component({
  selector: 'app-detail-vendeur',
  templateUrl: './detail-vendeur.component.html',
  styleUrls: ['./detail-vendeur.component.scss'],
  styles: [`
    .star {
      position: relative;
      display: inline-block;
      color: #d3d3d3;
    }
    .full {
      color: #F8B53E;
    }
    .half {
      position: absolute;
      display: inline-block;
      overflow: hidden;
      color:#F8B53E;
    }
  `]
})
export class DetailVendeurComponent implements OnInit {
  @ViewChild('loginTemplate') loginTemplate: ElementRef;
  productCompletes = [];
  note = 4;
  page;
  modalVendeur: BsModalRef;
  modalRef2: BsModalRef;

  // new
  idVendeur;
  vendeurData;
  produitsByVendeur;
  avisToShow;

  isBrowser;

  newText;

  myMessage;
  constructor(
    private router: Router, @Inject(PLATFORM_ID) private platformId,
    private titleService: Title, private metaService: Meta,
    public commonService: CommonService, public chatService: ChatService,
    config: NgbRatingConfig, private httpClient: HttpClient, private activatedRoute: ActivatedRoute,
    private modalService: BsModalService
  ) {
    config.max = 5;
    config.readonly = true;
    const urlVendeur = this.activatedRoute.snapshot.paramMap.get('id_vendeur');
    this.idVendeur = Number(urlVendeur.substr(urlVendeur.lastIndexOf('-') + 1));
    this.isBrowser = isPlatformBrowser(platformId);
  }

  contactVendeur(template: TemplateRef<any>) {
    this.modalVendeur = this.modalService.show(
      template,
      Object.assign({ backdrop: 'static' }),
    );
  }

  ngOnInit() {
    this.getVendeur2(this.idVendeur);
    this.getProduitsByVendeur(this.idVendeur);
  }

  async getVendeur2(idVendeur) {
    await this.httpClient.get(environment.ws_url + 'users/HADEEN/vendeur/' + idVendeur).subscribe((response: any) => {
      // tslint:disable-next-line:max-line-length
      // this.titleService.setTitle(response.data.vendeur.boutique.nom + ' | découvrez la sélection d’articles de ' + response.data.vendeur.boutique.nom + '| Hadéen-Place | Consommer Responsable |');
      this.titleService.setTitle(response.data.vendeur.boutique.nom + ' | Hadéen Place | Consommer Responsable');
      // const desc = response.data.vendeur.boutique.description;
      const desc = `Découvrez les articles de « Nom du vendeur » dès maintenant sur Hadéen-Place.`;
      this.metaService.updateTag({
        name: 'description',
        content: desc
      });

      this.vendeurData = response.data;
      this.avisToShow = this.vendeurData.avis.slice(Math.max(this.vendeurData.avis.length - 4, 0));
      this.note = this.vendeurData.vendeur.star;

      if (this.vendeurData.frais_livraisons.length) {
        const isGratuitPrix = this.vendeurData.frais_livraisons.filter(elt => elt.type === 'GRATUIT_MONTANT');
        const isGratuitNb = this.vendeurData.frais_livraisons.filter(elt => elt.type === 'GRATUIT_PRODUIT');
        if (isGratuitPrix.length) {
          this.newText = `${isGratuitPrix[0].prix} €`;
        }
        if (isGratuitNb.length) {
          if (isGratuitPrix.length) {
            this.newText += ` ou à partir de ${isGratuitNb[0].minQuantity} articles au panier`;
          } else {
            this.newText = `${isGratuitNb[0].minQuantity} articles au panier`;
          }
        }
      }
      // this.newText
    });
  }

  async getProduitsByVendeur(idVendeur) {
    if (this.activatedRoute.snapshot.queryParams['page']) {
      this.page = this.activatedRoute.snapshot.queryParams['page'];
    } else {
      this.page = 1;
    }

    const formData = new FormDataServer();
    formData.append('plateforme', 'HADEEN');
    formData.append('trie_par.attribut', 'DEFAULT');
    formData.append('trie_par.is_croissant', 'true');
    formData.append('pagination.par_page', '12');
    formData.append('pagination.page', this.page);
    formData.append('vendeur', idVendeur);

    if(this.isBrowser){
      this.httpClient.post(environment.ws_url + 'produits/search', formData).subscribe((resp: any) => {
        this.produitsByVendeur = resp.data;
      });
    } else{

      const formdataHeaders = formData.getHeaders();
      let headers2 = new HttpHeaders();
      headers2 = headers2.append('Content-Type', formdataHeaders['content-type']);

      this.httpClient.post(environment.ws_url + 'produits/search', this.toArrayBuffer(formData.getBuffer()), { headers: headers2 })
      .subscribe((resp: any) => {
        this.produitsByVendeur = resp.data;
      });
    }
  }


  toArrayBuffer(buf) {
    var ab = new ArrayBuffer(buf.length);
    var view = new Uint8Array(ab);
    for (var i = 0; i < buf.length; ++i) {
      view[i] = buf[i];
    }
    return ab;
  }

  sendMessage() {
    if (this.myMessage) {
      const message = {
        receiver: this.idVendeur,
        objet: this.vendeurData.boutique.nom,
        message: this.myMessage,
      };
      this.myMessage = '';
      this.chatService.sendMessage(message, this.loginTemplate);
    }
  }
  openLogin() {
    this.chatService.loginModal(this.loginTemplate);
  }

  // loginModal(template: TemplateRef<any>) {
  loginModal(template) {
    this.modalVendeur = this.modalService.show(
      template,
      Object.assign({ backdrop: 'static' }),
    );
  }

  getMoyenne() {
    let total = 0;
    this.vendeurData.avis.forEach((elt: any) => {
      total += elt.star;
    });

    return (total) / (this.vendeurData.avis.length);
  }

  async changePage($event) {
    this.page = $event;

    const linkParams = {};
    linkParams['page'] = this.page;
    this.router.navigate(['.'], { relativeTo: this.activatedRoute, queryParams: linkParams });

    setTimeout(() => {
      this.getProduitsByVendeur(this.idVendeur);
      if (isPlatformBrowser(this.platformId)) {
        window.scrollTo(0, 0);
      }
    }, 500);

  }
}
