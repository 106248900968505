<div class="spinner" style="position: fixed; top: 30%; z-index: 1;" *ngIf="showSpinner">
  <mat-spinner></mat-spinner>
</div>

<form class="addProduit row" [formGroup]="formGroupe" [ngClass]="{'formError': showError}">
  <div class="col-12 p-1 p-md-3" style="background-color: transparent;">
    <mat-form-field class="col-12 hadeenInput mb-2">
      <mat-label>Titre de l'article</mat-label>
      <input matInput #reference placeholder="Titre de l'article" maxlength="70" formControlName="reference" required>
      <mat-hint>Entre 35 à 70 caractères</mat-hint>
      <mat-hint align="end">{{reference.value.length}} / 70</mat-hint>
      <mat-icon class="icon-info" style="color: #999;" matSuffix mat-raised-button
      #tooltip="matTooltip" (click)="tooltip.toggle()"
      matTooltip="Nous vous conseillons d’écrire une qualité et un avantage dans votre titre. C’est la première chose que l’acheteur va lire.">info</mat-icon>
    </mat-form-field>
    <mat-form-field class="col-12 hadeenInput mt-0 mt-md-2 mb-3">
      <mat-label>Description courte du produit</mat-label>
      <input matInput #model placeholder="Description courte du produit" maxlength="170" formControlName="model" required>
      <mat-hint>Entre 50 à 170 caractères</mat-hint>
      <mat-hint align="end">{{model.value.length}} / 170</mat-hint>
      <mat-icon class="icon-info" style="color: #999;" matSuffix mat-raised-button
      #tooltip="matTooltip" (click)="tooltip.toggle()"
      matTooltip="Reprenez les éléments du titre et rajoutez des caractéristiques comme “made in france “ ou bio par exemple et le nom de votre marque.">info</mat-icon>
    </mat-form-field>
    <mat-form-field class="col-12 hadeenInput mb-0" *ngIf="!productEdit" (click)="clickUniver(1);openModal(template)">
      <mat-label>Univers</mat-label>
      <input matInput placeholder="Univers" readonly formControlName="univer" required>
      <mat-icon class="icon-info" style="color: #999;" matSuffix mat-raised-button
      #tooltip="matTooltip" (click)="tooltip.toggle()"
      matTooltip="Choisissez la catégorie la plus pertinente, si plusieurs catégories correspondent à votre article, contactez nous afin de dupliquer l’annonce dans plusieurs catégories.">info</mat-icon>
    </mat-form-field>
    <mat-form-field class="col-12 hadeenInput mb-0" *ngIf="productEdit">
      <mat-label>Univers</mat-label>
      <input matInput placeholder="Univers" readonly formControlName="univer" required>
    </mat-form-field>

    <mat-form-field class="col-12 hadeenInput mb-0" *ngIf="!productEdit" (click)="clickUniver(2);openModal(template)">
      <mat-label>Sous univers</mat-label>
      <input matInput placeholder="Sous univers" readonly formControlName="sousUniver" required>
    </mat-form-field>
    <mat-form-field class="col-12 hadeenInput mb-0" *ngIf="productEdit">
      <mat-label>Sous univers</mat-label>
      <input matInput placeholder="Sous univers" readonly formControlName="sousUniver" required>
    </mat-form-field>

    <mat-form-field class="col-12 hadeenInput mb-0" *ngIf="!productEdit" (click)="clickUniver(3);openModal(template)">
      <mat-label>Categorie</mat-label>
      <input matInput placeholder="Categorie" readonly formControlName="categorie" required>
    </mat-form-field>
    <mat-form-field class="col-12 hadeenInput mb-0" *ngIf="productEdit">
      <mat-label>Categorie</mat-label>
      <input matInput placeholder="Categorie" readonly formControlName="categorie" required>
    </mat-form-field>

    <mat-form-field class="col-12 hadeenInput">
      <mat-label>Décrivez votre article en terminant par des # afin d’augmenter vos intentions de recherches.</mat-label>
      <textarea #description matInput placeholder="Décrivez votre produit" rows="6" formControlName="description"
        required></textarea>
      <!-- <mat-hint align="end">{{description.value.length}} / 450</mat-hint> -->
      <mat-icon class="icon-info" style="color: #999;" matSuffix mat-raised-button
      #tooltip="matTooltip" (click)="tooltip.toggle()"
      matTooltip="L’outil smodin vous permettra de reformuler automatiquement votre description pour éviter les “copiés-collés” détestés par GOOGLE. Nous vous conseillons de relire votre texte avant de le valider pour éviter les reformulation intempestives de l’intelligence artificielle.">info</mat-icon>
        <button class="btn btn-dark btn-sm mt-2" (click)="rewriteText(templateRewrite)" *ngIf="formGroupe.get('description').status === 'VALID' && useSmodin =='0'">
          Remanier le texte avec Smodin
          <div class="spinner-border" *ngIf="rewriteLoading" style="width: 20px;height: 20px;" role="status">
            <span class="sr-only">Loading...</span>
          </div>
        </button>
        <button class="btn btn-outline-success btn-sm mt-2" *ngIf="useSmodin =='1'">
          <i class="fa fa-check text-success"></i> Texte modifié par smodin
        </button>
    </mat-form-field>
    
    <div class="col-12">
      <button class="signin mb-2" (click)="validerStep()">Valider</button>
    </div>
    <div class="col-12">
      <div class="text-danger" *ngIf="formSubmitted && formGroupe.get('reference')?.hasError('required')">
        Champ titre de l'article requis
      </div>
      <div class="text-danger" *ngIf="formSubmitted && formGroupe.get('model')?.hasError('required')">
        Champ description courte du produit requis
      </div>
      <div class="text-danger" *ngIf="formSubmitted && formGroupe.get('model').value.length<50 && formGroupe.get('model').value.length>=1">
        Veuillez écrire une description courte du produit supérieur à 50 caractères
      </div>
      <div class="text-danger" *ngIf="formSubmitted && formGroupe.get('univer')?.hasError('required')">
        Champ univers requis
      </div>
      <div class="text-danger" *ngIf="formSubmitted && formGroupe.get('description')?.hasError('required')">
        Champ description requis
      </div>
    </div>
  </div>
</form>
<ng-template #templateRewrite>
  <div class="modal-header text-center" style="padding: 1rem 1rem 0;">
    <h4 class="col-2"> </h4>
    <h4 class="col-8 text-center my-0"> Description par Smodin </h4>
    <button type="button" class="close text-right col-2" aria-label="Close" style="padding: 1rem 1rem;"
      (click)="closeModal()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="row pl-2 pr-2">
      <div class="col-12 mb-0">
        <mat-form-field class="col-12 hadeenInput mb-0">
          <textarea matInput placeholder="Texte original" disabled rows="6">{{this.formGroupe.controls["description"].value}}</textarea>
        </mat-form-field>
      </div>
      <div class="col-12 text-center m-0" style="padding-bottom: 15px;">
        <i class="fa fa-arrow-down" aria-hidden="true"></i>
      </div>
      <div class="col-12 mb-0">
        <mat-form-field class="col-12 hadeenInput mb-0">
          <textarea matInput placeholder="Texte proposé par smodin" rows="6" [(ngModel)]="txtRewrite"></textarea>
        </mat-form-field>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-danger btn-sm" (click)="closeModal()" style="width: 100px;">ANNULER</button>
    <button class="my-0 btn btn-sm" (click)="validateRewrite()" style="background: black;color: white;width: 100px;">VALIDER</button>
  </div>  
</ng-template>

<ng-template #template>
  <div [ngSwitch]="step">
    <ng-template [ngSwitchCase]="1">
      <div class="modal-header text-center" style="padding: 1rem 1rem 0;">
        <h4 class="col-3"> </h4>
        <h4 class="col-6 text-center"> Univers </h4>
        <button type="button" class="close pull-right" aria-label="Close" style="padding: 1rem 1rem;"
          (click)="closeModal()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="row pl-2 pr-2">
          <div class="col-4 p-2 pb-3" *ngFor="let item of listAffiche">
            <div class="card" style="width: 100%; border: none;" (click)="selectItem(item)">
              <img width="auto" height="auto" [src]="item.photo" class="card-img-top" [alt]="item.nom" style="height: 120px; object-fit: cover;">
              <div class="p-2 h-100">
                <p class="card-text text-center"> {{item.nom}} </p>
              </div>
            </div>
          </div>


        </div>
      </div>
    </ng-template>
    <ng-template [ngSwitchCase]="2">
      <div class="modal-header" style="padding: 1rem 1rem 0;">
        <h4 class="col-3">
          <a mat-flat-button style=" font-size: 16px; padding: 0px; " (click)="clickUniver(1)">
            <mat-icon aria-hidden="false" aria-label="Example home icon">keyboard_arrow_left</mat-icon>
            Retour
          </a>
        </h4>
        <h4 class="col-6 text-center"> Sous Univers </h4>
        <button type="button" class="close pull-right" aria-label="Close" style="padding: 1rem 1rem;"
          (click)="closeModal()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body" style="padding: 0.5rem 1rem; overflow: auto; max-height: 85vh;">
        <div class="row pl-2 pr-2 choixcategorie">
          <div class="w-100" *ngFor="let item of listAffiche; let index = index;" style="padding: 5px 15px;">
            <mat-accordion class="test">
              <mat-expansion-panel [expanded]="item.step" (opened)="setStep(item)">
                <mat-expansion-panel-header [collapsedHeight]="'200px'" [expandedHeight]="'200px'" class="p-0">
                  <img width="auto" height="auto" [src]="item.photo" class="card-img-top" [alt]="item.nom" (click)="setStep(item)"
                    style="height: 150px; object-fit: cover;">
                  <p class="pt-2">{{item.nom}}</p>
                </mat-expansion-panel-header>
                <ul class="list-group">
                  <li class="list-group-item text-center" *ngFor="let sous of item.children"
                    (click)="clickCategorie(item, sous)"> {{ sous.nom }} </li>
                </ul>
              </mat-expansion-panel>
            </mat-accordion>
          </div>

        </div>
      </div>

    </ng-template>
  </div>


</ng-template>
