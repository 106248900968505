import { Platform } from "@angular/cdk/platform";
import { isPlatformBrowser } from "@angular/common";
import { Component, Inject, OnInit, PLATFORM_ID } from "@angular/core";
import { Meta, Title } from "@angular/platform-browser";
import { ActivatedRoute, Router } from "@angular/router";
import { HttpService } from "src/app/services/http.service";

@Component({
  selector: "app-result-search",
  templateUrl: "./result-search.component.html",
  styleUrls: ["./result-search.component.scss"],
})
export class ResultSearchComponent implements OnInit {
  keyWord;
  keyWordBrute;
  allResult;
  ITEM_PER_PAGE = "24";
  p: number = 0;
  nbrProd = 0;
  constructor(
    private activatedRoute: ActivatedRoute,
    private httpService: HttpService,
    private router: Router,
    public platform: Platform,
    private titleService: Title,
    private metaService: Meta,
    @Inject(PLATFORM_ID) private platformId
  ) {
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
  }

  ngOnInit(): void {
    const keyWordUrl = this.activatedRoute.snapshot.queryParams["mot"];
    this.p = this.activatedRoute.snapshot.queryParams["page"] || 0;
    this.keyWord = encodeURIComponent(keyWordUrl);
    this.keyWordBrute = decodeURIComponent(keyWordUrl);
    this.httpService
      .get(`produits/es-search?plateforme=HADEEN&q=${this.keyWord}`)
      .subscribe((resp: any) => {
        this.allResult = resp.data;
        this.updateMeta(this.keyWord, this.keyWord);
      });
  }

  searchElastic() {
    const linkParams = {};
    linkParams["mot"] = this.keyWord;
    this.router.navigate(["/recherche"], {
      relativeTo: this.activatedRoute,
      queryParams: linkParams,
    });
  }

  changePage($event) {
    // this.p = $event;
    // window.scrollTo(0, 0);
    this.p = $event;
    const linkParams = {};
    linkParams["mot"] = this.keyWordBrute;
    linkParams["page"] = this.p;
    this.router.navigate(["."], {
      relativeTo: this.activatedRoute,
      queryParams: linkParams,
    });
    // if (this.isEnAvant) {
    //   this.getCatalogPromotion();
    // } else {
    //   if (this.filterList.length < 1) {
    //     this.getProducts(true);
    //   } else {
    //     this.getProductByCriteriaSearch();
    //   }
    // }
    if (isPlatformBrowser(this.platformId)) {
      window.scrollTo(0, 0);
    }
  }

  updateMeta(title, description) {
    let newDescription = `Explorez notre marketplace dédiée aux produits éco-responsables et découvrez notre gamme d'articles ${String(description.trim())})`
    this.titleService.setTitle(title + " | Consommer Responsable");
    this.metaService.updateTag({
      name: "description",
      content: newDescription,
    });
    this.metaService.updateTag({
      property: "og:title",
      content: title + " | Consommer Responsable",
    });
    this.metaService.updateTag({ property: "og:type", content: "articles" });
    this.metaService.updateTag({ property: "og:url", content: this.router.url });
    this.metaService.updateTag({
      property: "og:description",
      content: newDescription,
    });
  }
}
