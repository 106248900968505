import { ToastrService } from 'ngx-toastr';
import { CommonService } from './common.service';
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { HttpService } from './http.service';
import { HttpParams } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class ProductService {
  mesEnvies: any;
  mesEnviesShow = [];
  listeAchat = [];
  allCommande = [];
  commandeAcheteur = [];

  commandSeller = [];
  commandSellerTotal = 0;
  commandShopper = [];
  commandShopperTotal = 0;

  commandAllTotal = 0;

  CommandeShopperSubject = new Subject<any[]>();
  CommandeSellerSubject = new Subject<any[]>();
  offresDuMomentShow = [];

  constructor(private http: HttpService, private commonService: CommonService, private toast: ToastrService) {  }

  emitCommandeShopperSubject() {
    this.CommandeShopperSubject.next(this.commandShopper.slice());
  }
  emitCommandeSellerSubject() {
    this.CommandeSellerSubject.next(this.commandSeller.slice());
  }

  getUniv(has_product?): Observable<any> {
    if (has_product) {
      return this.http.get('univers?has_product=true');
    }
    return this.http.get('univers');
  }
  getSousUniv(code, has_product?): Observable<any> {
    if (has_product) {
      return this.http.get('sous-univers/list?univers_code=' + code + '&has_product=true');
    }
    return this.http.get('sous-univers/list?univers_code=' + code);
  }
  getcategorie(code, has_product?): Observable<any> {
    if (has_product) {
      return this.http.get('categories/list?sous_univers_code=' + code + '&has_product=true');
    }
    return this.http.get('categories/list?sous_univers_code=' + code);
  }

  getEnvies(page?: any) {
    if (!page) {
      page = 1;
    }
    this.http.get(`users/wishes?par_page=12&page=${page}`).subscribe((resp: any) => {
      this.mesEnvies = resp.data;
      this.mesEnviesShow = resp.data;
    });
  }

  getListeAchat() {
    this.http.get('achats').subscribe((resp: any) => {
      const dataList = resp.data;
      if ((resp.data).length) {
        this.listeAchat = (resp.data).filter(elt => elt.status !== 'SUPPRIMEE');
      } else {
        this.listeAchat = [];
      }
    });
    this.emitCommandeShopperSubject();
  }

  getCommandeAcheteur(page?: any) {
    let url = 'commandes/acheteur/mes?par_page=12';
    if (page) {
      url += `&page=${page}`;
    }
    this.http.get(url).subscribe((resp: any) => {
      // this.commandeAcheteur = (resp.data.commandes).sort((a, b) => a.id - b.id);
      this.commandeAcheteur = resp.data.commandes;
      // this.commandShopper = (resp.data.commandes).sort((a, b) => b.id - a.id);
      this.commandShopper = resp.data.commandes;
      this.commandShopperTotal = resp.data.nb_total;
      this.emitCommandeShopperSubject();
      this.emitCommandeSellerSubject();
      if (this.commonService.user.role === 'VENDEUR_VALIDEE') {
        this.getCommandeVendeur();
        // this.emitCommandeShopperSubject();
      }
    });
  }
  getCommandeVendeur(page?: any) {
    let url = 'commandes/vendeur/mes?par_page=12';
    if (page) {
      url += `&page=${page}`;
    }
    this.http.get(url).subscribe((resp: any) => {
      this.allCommande = this.commandeAcheteur.concat(resp.data.commandes);

      this.commandSeller = (resp.data.commandes).sort((a, b) => b.id - a.id);
      this.commandSellerTotal = resp.data.nb_total;
      this.commandAllTotal = this.commandShopperTotal + this.commandSellerTotal;
      this.emitCommandeSellerSubject();
    });
  }

  // validate or refused commande
  validate_Refused_Commande(id: number, etat: string) {
    this.http.get('commandes/answer/' + id + '/' + etat).subscribe((resp: any) => {
      // console.log('reponse :', resp);
    });
    this.getCommandeVendeur();
    this.emitCommandeSellerSubject();
  }

  // commande Expedition
  expedition(id: number, param1, param2) {
    const paramsVal = new HttpParams().set('codeExpedition', param1).set('lienExpedition', param2);
    this.http.get('commandes/expedier/' + id, {params: paramsVal}).subscribe((resp: any) => {
    });
    this.getCommandeAcheteur();
    this.emitCommandeSellerSubject();
  }

  // confirmationCommande
  confirmation(id: number) {
    this.http.get('commandes/confirm/' + id).subscribe((resp: any) => {
    });
    this.getCommandeAcheteur();
    this.emitCommandeShopperSubject();
  }

  // note vendeur ancien params => comment, star, vendeurId
  sendNoteVendeur(objet) {

    const formData = new FormData();
    formData.append('commentaire', objet.commentaire);
    formData.append('star', objet.star);
    formData.append('vendeur', objet.idVendeur);
    formData.append('commande', objet.idCommand);

    this.http.post('feedbacks', formData).subscribe(
      (resp: any) => {
        this.toast.success('Vendeur noté avec succès', '' , {
          timeOut :  3000
        });
      }, (error => {
        this.toast.error('Vous avez déjà noté ce vendeur', '' , {
          timeOut :  3000
        });
      }));
    this.emitCommandeShopperSubject();
  }

  // addFeedbackProduit
  addFeedbackProduit(objet) {
    const formData = new FormData();

    formData.append('star', objet.star);
    formData.append('produit ', objet.id);
    formData.append('commande', objet.idCommand);
    if (objet.comment) {
      formData.append('commentaire', objet.comment);
    }
    this.http.post('feedback-produits', formData).subscribe(
    (resp: any) => {
      this.toast.success('Produit noté avec succès', '' , {
        timeOut :  3000
      });
    });
    this.emitCommandeShopperSubject();
  }
}
