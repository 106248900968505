<div class="container">
  <div class="row">
    <div class="col-md-12 p-0">
      <div class="container">
        <div class="row">
          <div class="col-md-12 py-3">
            <div id="coupDeCoeurCarousel" class="carousel slide" data-ride="carousel">
              <div class="carousel-inner">
                <div class="carousel-item" *ngFor="let slider of productsToShow; let i= index;"
                  [ngClass]="{'active': i==0}">
                  <div class="row">
                    <div class="col text-center" *ngFor="let item of slider.products">
                      <img width="auto" height="auto" loading="lazy" [src]="item.a_la_une.publicice_detail.file.url" alt="" class="img-fluid mx-auto"
                        style="height:241px;object-fit: cover;">
                      <h4>{{item.a_la_une.publicice_detail.texte_achrochage}}</h4>
                    </div>
                  </div>
                </div>
              </div>

              <ng-container *ngIf="productsToShow.length>1">
                <div class="d-flex flex-row justify-content-center">
                  <a class="carousel-control-prev pb-3" href="#coupDeCoeurCarousel" role="button" data-slide="prev">
                    <i class="fa fa-chevron-left"></i>
                  </a>
                  <ul class="carousel-indicators mx-0">
                    <li class="mx-2" data-target="#coupDeCoeurCarousel"
                      *ngFor="let slider of productsToShow; let i=index" [attr.data-slide-to]="i"
                      [ngClass]="{'active' : i == 0}"></li>
                  </ul>
                  <a class="carousel-control-next pb-3" href="#coupDeCoeurCarousel" role="button" data-slide="next">
                    <i class="fa fa-chevron-right"></i>
                  </a>
                </div>
              </ng-container>

            </div>

          </div>
        </div>
      </div>
    </div>
  </div>
</div>
