import { ChatService } from "./../../../services/chat.service";
import { HttpService } from "./../../../services/http.service";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import {
  Component,
  ElementRef,
  Inject,
  OnInit,
  PLATFORM_ID,
  TemplateRef,
  ViewChild,
} from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { BsModalService } from "ngx-bootstrap/modal";
import { BsModalRef } from "ngx-bootstrap/modal/bs-modal-ref.service";
import { environment } from "src/environments/environment";

import * as FormDataServer from "form-data";

declare var $;
import { Options } from "ng5-slider";
import { Meta, Title } from "@angular/platform-browser";
import { DOCUMENT, isPlatformBrowser } from "@angular/common";
import { CommonService } from "src/app/services/common.service";
import { StorageService } from "src/app/services/storage.service";
import { Platform } from "@angular/cdk/platform";

@Component({
  selector: "app-listing-produit",
  templateUrl: "./listing-produit.component.html",
  styleUrls: ["./listing-produit.component.scss"],
})
export class ListingProduitComponent implements OnInit {
  newTitle = "Hadéen-Place | Tous nos Articles pour vous";
  // tslint:disable-next-line:max-line-length
  newDescription =
    "Économat, Hygiène et Santé, Femme, Homme ,Bébé et Enfant, Maison et Cuisine, Loisir et Animaux, Brico, Jardin et Auto-Moto, Idées Cadeau et Diy Un large choix d’articles vous attend. Bio, vegan, made in France, circuit court, fait main, naturel, Éco-responsable, écologique, recyclable, zéro déchet, cruelty fee";

  @ViewChild("loginTemplate") loginTemplate: ElementRef;
  // valueNg5Slider = 100;
  // highValueNg5Slider = 200;
  valueNg5Slider = 0;
  highValueNg5Slider = 0;
  optionsNg5Slider: Options = {
    floor: this.valueNg5Slider,
    ceil: this.highValueNg5Slider,
  };

  p: number;
  sousUniv = [];
  dispobCheck = [
    {
      name: "En stock",
      value: "EN_ETOCK",
      checked: false,
    },
    {
      name: "En rupture",
      value: "EN_RUPTURE",
      checked: false,
    },
    {
      name: "Limité",
      value: "LIMITE",
      checked: false,
    },
    {
      name: "Sur commande",
      value: "SUR_COMMANDE",
      checked: false,
    },
  ];
  marques = [
    {
      name: "NIKE",
      checked: false,
    },
    {
      name: "ADIDAS",
      checked: false,
    },
    {
      name: "D&G",
      checked: false,
    },
    {
      name: "CHANNEL",
      checked: false,
    },
    {
      name: "JORDAN",
      checked: false,
    },
  ];
  trier: [
    { nom: "le moins cher" },
    { nom: "le plus cher" },
    { nom: "la plus récente" }
  ];
  trieM = "le moins cher";
  modalRef: BsModalRef;

  // Byjo
  productsToShow = [];
  productsAll = [];
  listUnivers = [];
  listSousUnivers = [];
  nbrProd = 0;
  univer;
  univerBrute;
  categorie;
  codeCategorie;
  sousUniver;
  sousUniverBrute;
  codeSousCategorie;
  filter: any = {};

  caracs;
  allCaracs;
  couleur;
  taille;
  sousCategorieSelected;

  fd: FormDataServer = new FormDataServer();
  filterList = []; // filtre caractéristique
  trierParPrix;
  panelOpenState;
  vendeur;

  isBrowser;
  fdProperties;
  init;

  isVenteFlash = false;

  ITEM_PER_PAGE = "24";
  isEnAvant = false;
  slugIsAvant: string;
  // mea_detail: any = {
  //   description: "Test mise en avant home chaussure description",
  //   id: 2,
  //   image: "https://apipreprod.hadeen-place.fr/uploads/catalog_promotions/c5e19736d890ac4d93145457a405b6c4-hei_1.png",
  //   name:  "Test mise en avant home chaussure",
  //   produit_ids: "416,417,418,419,420",
  //   slug:  "2-Test_mise_en_avant_home_chaussure",
  //   sous_univers: {
  //     code:  "405",
  //     createdAt: "12-06-2020 10:23:47",
  //     description: null,
  //     nom: "Chaussures",
  //     photo: "https://apipreprod.hadeen-place.fr/uploads/ceb4b3da3be37a00e30b57a629a54ed1-capture-decran-2020-10-14-a-202151.png\r\n",
  //     slug:  "405-chaussures",
  //     titre: null,
  //     translation: {traduction1: '', traduction2: '', traduction3: '', traduction4: '', plateforme: 'HADEEN'}
  //   },
  //   univers: {
  //     code: "4",
  //     createdAt: "30-05-2020 03:50:42",
  //     description: null,
  //     nom: "Homme",
  //     photo: "https://apipreprod.hadeen-place.fr/uploads/94c549abbc3becc4d903655d8997eabe-capture-decran-2020-10-13-a-095614.png\r\n",
  //     slug:  "4-homme",
  //     titre: null,
  //     translation: {traduction1: '', traduction2: '', traduction3: '', traduction4: '', plateforme: 'HADEEN'},
  //     updatedAt: "01-08-2020 08:30:21"
  //   }
  // }
  mea_detail: any;
  isReadMore = false;

  slugUniver: any;
  slugSousUnivers: any;
  slugCategorie: any;

  constructor(
    private title: Title,
    private meta: Meta,
    @Inject(PLATFORM_ID) private platformId,
    private modalService: BsModalService,
    private httpClient: HttpClient,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private httpService: HttpService,
    public chatService: ChatService,
    public platform: Platform,
    private storageService: StorageService,
    @Inject(DOCUMENT) private document: Document
  ) {
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;

    let slug_enAvant = (this.univerBrute =
      this.activatedRoute.snapshot.paramMap.get("slug"));
    if (slug_enAvant) {
      this.isEnAvant = true;
      this.slugIsAvant = slug_enAvant;
    }

    this.univerBrute = this.activatedRoute.snapshot.paramMap.get("univer");
    if (this.univerBrute) {
      this.univer = this.univerBrute.split("_").join(" ");
      this.slugUniver = this.activatedRoute.snapshot.paramMap.get("univer");
    }
    this.sousUniverBrute =
      this.activatedRoute.snapshot.paramMap.get("sous_univer");
    if (this.sousUniverBrute) {
      this.sousUniver = this.sousUniverBrute.split("_").join(" ");
      this.slugSousUnivers =
        this.activatedRoute.snapshot.paramMap.get("sous_univer");
    }

    const categorieBrute =
      this.activatedRoute.snapshot.paramMap.get("categorie");
    if (categorieBrute) {
      this.categorie = categorieBrute.split("_").join(" ");
      this.slugCategorie =
        this.activatedRoute.snapshot.paramMap.get("categorie");
    }
    this.fdProperties = new Object();
    this.fdProperties["plateforme"] = "HADEEN";
    this.fdProperties["trie_par.attribut"] = "DEFAULT";
    this.fdProperties["trie_par.is_croissant"] = "true";

    this.activatedRoute.queryParams.subscribe((params) => {
      if (params.vendeur) {
        this.vendeur = params.vendeur;
      }
      if (params.type) {
        this.isVenteFlash = params.type === "venteflash";
      }
      this.p = params.page ? +params.page : 1;
    });
    this.isBrowser = isPlatformBrowser(platformId);
    if (window.innerWidth < 670) {
      this.isReadMore = true;
    }
  }

  showText() {
    this.isReadMore = !this.isReadMore;
  }

  selecteDispo(dispo) {
    this.dispobCheck.forEach((res: any) => {
      res.color = "grey";
      res.checked = false;
    });
    dispo.color = "black";
    dispo.checked = true;
    this.filter.disponnible = dispo.value;
    this.search();
  }

  selectPick() {}

  selecteMarque(marque) {
    this.marques.forEach((res: any) => {
      res.color = "grey";
      res.checked = false;
    });
    marque.color = "black";
    marque.checked = true;
  }

  formatLabel(value: number) {
    if (value >= 1000) {
      return Math.round(value / 1000) + "k";
    }

    return value;
  }

  openModalFiltre(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template);
  }

  ngOnInit(): void {
    this.filter.croissant = null;
    if (this.isEnAvant) {
      this.getCatalogPromotion();
    } else {
      if (this.isVenteFlash) {
        this.getAllVenteFlash();
      } else {
        this.getUnivers();
      }
    }
  }

  getCatalogPromotion() {
    const user = this.storageService.getItem("User");
    const prodUrl = user ? "catalog-promotion/with-wish" : "catalog-promotion";
    this.ITEM_PER_PAGE = "18";
    const body = {
      slug: this.slugIsAvant,
      "pagination.par_page": this.ITEM_PER_PAGE,
      "pagination.page": `${this.p}`,
    };
    this.httpClient
      .get(`${environment.ws_url}${prodUrl}`, { params: body })
      .subscribe((dataMenu: any) => {
        this.productsAll = dataMenu.data.produits.produits;
        this.productsToShow = dataMenu.data.produits.produits;
        this.nbrProd = dataMenu.data.produits.nb_total;
        this.mea_detail = dataMenu.data;
        this.updateImage(dataMenu.data.image);
        this.updateMeta(
          dataMenu.data.name,
          dataMenu.data.description,
          dataMenu.data.image
        );
      });
  }

  getAllVenteFlash() {
    const user = this.storageService.getItem("User");
    const prodUrl = user
      ? "accueil/produits-with-wish/HADEEN"
      : "accueil/produits/HADEEN";
    const body = {
      types: "VENTE_FLASH",
    };
    this.httpClient
      .get(environment.ws_url + prodUrl, { params: body })
      .subscribe((dataMenu: any) => {
        this.productsAll = dataMenu.data.produits;
        this.productsToShow = dataMenu.data.produits;
        this.nbrProd = dataMenu.data.nb_total;
      });
  }

  getFilters(title?) {
    const fdServer = new FormDataServer();
    const objet = { ...this.fdProperties };
    Object.keys(objet).forEach(function (key) {
      fdServer.append(key, objet[key]);
    });

    this.httpService
      .post("valeurs/filtres", fdServer)
      .subscribe((dataMenu: any) => {
        const filters = dataMenu.data;
        /**
         * prices
         */
        this.valueNg5Slider = filters.prices !== null ? filters.prices.min : 0;
        this.highValueNg5Slider =
          filters.prices !== null ? filters.prices.max : 0;
        const option = new Options();
        option.floor = this.valueNg5Slider;
        option.ceil = this.highValueNg5Slider;
        this.optionsNg5Slider = option;

        /**
         * caracteristiques
         */
        filters.caracteristiques.forEach((elt) => {
          elt.valeurs = elt.valeurs.map((val) => {
            return { item: val, checked: false };
          });
        });
        this.allCaracs = filters;
        this.caracs = { ...this.allCaracs };
        if (this.caracs && this.caracs.caracteristiques && this.categorie) {
          const aaa = this.caracs.caracteristiques.filter(
            (elt) =>
              elt.caracteristique.code === "1" ||
              elt.caracteristique.code === "2"
          );
          let ttt = "";
          if (aaa.length) {
            aaa.forEach((elt) => {
              elt.valeurs.forEach((elt2, ind) => {
                if (ind === elt.valeurs.length - 1) {
                  ttt += `${elt2.item.valeur}`;
                } else {
                  ttt += `${elt2.item.valeur} | `;
                }
              });
            });
            this.newDescription = `${this.categorie} : Découvrez la sélection de nos vendeurs ${ttt}`;
          }
          this.meta.updateTag({
            name: "description",
            content: `${this.newDescription}`,
          });
        }
      });
  }

  async getProducts(isNotAll?) {
    // console.log("GET PRODUCT");

    const user = this.storageService.getItem("User");
    const fdServer = new FormDataServer();
    fdServer.append("plateforme", "HADEEN");
    fdServer.append("pagination.par_page", this.ITEM_PER_PAGE);
    fdServer.append("pagination.page", this.p);
    const prodUrl = user ? "produits-with-wish" : "produits/search";
    if (!isNotAll) {
      console.log("GET PRODUCT WITH FILTERS");
      if (!(this.highValueNg5Slider === 0 && this.valueNg5Slider === 0)) {
        fdServer.append("prix.maximum", "" + this.highValueNg5Slider);
        fdServer.append("prix.minimum", "" + this.valueNg5Slider);
      }
      if (this.trierParPrix) {
        fdServer.append("trie_par.attribut", "PRICE");
        fdServer.append("trie_par.is_croissant", "" + this.filter.croissant);
      }
      if (this.vendeur) {
        fdServer.append("vendeur", this.vendeur);
      }
      if (this.isBrowser) {
        return this.httpClient
          .post(environment.ws_url + prodUrl, fdServer)
          .subscribe((dataMenu: any) => {
            this.productsAll = dataMenu.data.produits;
            this.nbrProd = dataMenu.data.nb_total;
            this.productsToShow = this.productsAll;
          });
      } else {
        const formdataHeaders = fdServer.getHeaders();
        let headers2 = new HttpHeaders();
        headers2 = headers2.append(
          "Content-Type",
          formdataHeaders["content-type"]
        );
        return this.httpClient
          .post(
            environment.ws_url + prodUrl,
            this.toArrayBuffer(fdServer.getBuffer()),
            { headers: headers2 }
          )
          .subscribe((dataMenu: any) => {
            this.productsAll = dataMenu.data.produits;
            this.nbrProd = dataMenu.data.nb_total;
            this.productsToShow = this.productsAll;
          });
      }
    } else {
      // console.log("GET PRODUCT ALL", this.isBrowser);
      if (!(this.highValueNg5Slider === 0 && this.valueNg5Slider === 0)) {
        fdServer.append("prix.maximum", "" + this.highValueNg5Slider);
        fdServer.append("prix.minimum", "" + this.valueNg5Slider);
      }
      if (this.univer) {
        // const univ = this.listUnivers.filter(elt => elt.nom === this.univer);
        const univ = this.listUnivers.filter((elt) => elt.slug === this.univer);
        fdServer.append("catalogue.code_univers", univ[0].code);
      }
      if (this.sousUniver) {
        // const sous = this.listSousUnivers.filter(elt => elt.nom === this.sousUniver);
        const sous = this.listSousUnivers.filter(
          (elt) => elt.slug === this.sousUniver
        );
        fdServer.append("catalogue.code_sous_univers", sous[0].code);
      }
      if (this.categorie) {
        // const categorie = this.sousUniv.filter(elt => elt.nom === this.categorie);
        const categorie = this.sousUniv.filter(
          (elt) => elt.slug === this.categorie
        );
        fdServer.append("catalogue.categorie_code", categorie[0].code);
      }
      if (this.vendeur) {
        fdServer.append("vendeur", this.vendeur);
      }
      if (this.trierParPrix) {
        fdServer.append("trie_par.attribut", "PRICE");
        fdServer.append("trie_par.is_croissant", "" + this.filter.croissant);
      }

      if (this.isBrowser) {
        this.httpClient
          .post(environment.ws_url + prodUrl, fdServer)
          .subscribe((dataMenu: any) => {
            this.productsAll = dataMenu.data.produits;
            this.nbrProd = dataMenu.data.nb_total;
            this.productsToShow = this.productsAll;
          });
      } else {
        const formdataHeaders = fdServer.getHeaders();
        let headers2 = new HttpHeaders();
        headers2 = headers2.append(
          "Content-Type",
          formdataHeaders["content-type"]
        );

        this.httpClient
          .post(
            environment.ws_url + prodUrl,
            this.toArrayBuffer(fdServer.getBuffer()),
            { headers: headers2 }
          )
          .subscribe((dataMenu: any) => {
            this.productsAll = dataMenu.data.produits;
            this.nbrProd = dataMenu.data.nb_total;
            this.productsToShow = this.productsAll;
          });
      }
    }
  }

  toArrayBuffer(buf) {
    const ab = new ArrayBuffer(buf.length);
    const view = new Uint8Array(ab);
    for (let i = 0; i < buf.length; ++i) {
      view[i] = buf[i];
    }
    return ab;
  }

  search() {
    this.trierParPrix = true;
    if (this.filterList.length > 0) {
      this.getProductByCriteriaSearch();
    } else {
      this.getProducts(true);
    }
  }

  async getUnivers() {
    return await this.httpClient
      .get(environment.ws_url + "univers?plateforme=HADEEN")
      .subscribe((dataMenu: any) => {
        const matches = dataMenu.data || [];
        this.listUnivers = matches;
        if (this.univer) {
          this.newTitle = `Hadéen-Place | ${this.univer} | Consommer Responsable`;
          // const univ = this.listUnivers.filter(elt => elt.nom === this.univer);
          const univ = this.listUnivers.filter(
            (elt) => elt.slug === this.univer
          );
          if (univ.length) {
            // console.log("UNIVERS: ", univ);
            this.fdProperties["catalogue.code_univers"] = univ[0].code;

            if (!this.sousUniver) {
              this.getFilters("univers");
            }
            this.getSousUnivers(univ[0].code);
          }
        } else {
          this.newTitle = `Hadéen-Place | Tous nos Articles pour vous | Consommer Responsable`;
          let newDescription2 = "Découvrez la sélection de nos vendeurs : ";
          dataMenu.data.forEach((elt, ind) => {
            if (ind === dataMenu.data.length - 1) {
              newDescription2 += `${elt.nom}`;
            } else {
              newDescription2 += `${elt.nom} | `;
            }
          });
          this.title.setTitle(this.newTitle);
          this.meta.updateTag({
            name: "description",
            content: newDescription2,
          });
          this.getProducts();
        }
      });
  }

  async getSousUnivers(code) {
    return await this.httpClient
      .get(environment.ws_url + "sous-univers/list?univers_code=" + code)
      .subscribe((dataMenu: any) => {
        // console.log("SOUS UNIVERS: ", dataMenu);
        const matches = dataMenu.data || [];
        this.listSousUnivers = matches;
        if (this.sousUniver) {
          // const univ = this.listSousUnivers.filter(elt => elt.nom === this.sousUniver);
          const univ = this.listSousUnivers.filter(
            (elt) => elt.slug === this.sousUniver
          );
          if (univ.length) {
            // console.log("CATEGORIE: ", univ);
            this.fdProperties["catalogue.code_sous_univers"] = univ[0].code;

            if (!this.categorie) {
              this.getFilters("sous univers");
            }

            this.getCategories(univ[0].code);
          }
        } else {
          this.newTitle = `Hadéen-Place | ${this.univer} | Consommer Responsable`;
          let newDescription2 = "Découvrez la sélection de nos vendeurs : ";
          dataMenu.data.forEach((elt, ind) => {
            if (ind === dataMenu.data.length - 1) {
              newDescription2 += `${elt.nom}`;
            } else {
              newDescription2 += `${elt.nom} | `;
            }
          });
          this.title.setTitle(this.newTitle);
          this.meta.updateTag({
            name: "description",
            content: newDescription2,
          });
          this.getProducts(true);
        }
      });
  }

  async getCategories(code) {
    return await this.httpClient
      .get(environment.ws_url + "categories/list?sous_univers_code=" + code)
      .subscribe((dataMenu: any) => {
        // console.log("RESULT PRODUCTS: ", dataMenu);

        const matches = dataMenu.data || [];
        let newDescription2 = `Découvrez la sélection de nos vendeurs : `;
        this.sousUniv = matches;
        // console.log("RESULT PRODUCTS 2: ", this.categorie);
        if (this.categorie) {
          this.newTitle = `Hadéen-Place | ${this.categorie} | Consommer Responsable`;
          newDescription2 = `${this.categorie} : Découvrez la sélection de nos vendeurs : `;
          // const univ = this.sousUniv.filter(elt => elt.nom === this.categorie);
          const univ = this.sousUniv.filter(
            (elt) => elt.slug === this.categorie
          );
          // console.log("RESULT PRODUCTS 3: ", univ);
          if (univ.length) {
            this.fdProperties["catalogue.categorie_code"] = univ[0].code;
            this.sousCategorieSelected = univ[0].code;
            this.getFilters("carac by id");
          }
          this.getProducts(true);
        } else {
          this.newTitle = `Hadéen-Place | ${this.sousUniver} | Consommer Responsable`;
          this.getProducts(true);
        }
        dataMenu.data.forEach((elt, ind) => {
          if (ind === dataMenu.data.length - 1) {
            newDescription2 += `${elt.nom}`;
          } else {
            newDescription2 += `${elt.nom} | `;
          }
        });
        this.newDescription = newDescription2;
        this.title.setTitle(this.newTitle);
        this.meta.updateTag({
          name: "description",
          content: newDescription2,
        });
      });
  }

  getSousCategorie() {
    if (this.univer) {
      const listCategories = [];
      return this.httpClient
        .get(
          environment.ws_url +
            "categories/search?plateforme=HADEEN&q=" +
            this.univer
        )
        .subscribe((dataMenu: any) => {
          const matches = dataMenu.data.matches || [];
          matches.forEach((element) => {
            if (
              element.sousUnivers &&
              element.sousUnivers.nom === this.categorie
            ) {
              this.codeCategorie = element.sousUnivers.code;
              if (element.nom === this.sousUniver) {
                this.codeSousCategorie = element.code;
              }
              listCategories.push(element);
            }
          });
          this.sousUniv = listCategories;
          this.getProducts(true);
        });
    } else {
      this.getProducts();
    }
  }

  async getCaracsByCategId(code) {
    console.log("getCaracsByCategId");

    return await this.httpClient
      .get(environment.ws_url + `categories/${code}/details`)
      .subscribe((response: any) => {
        const test = response.data;
        test.caracteristiques.forEach((elt) => {
          if (elt.unites && elt.unites.length) {
            elt.unites.forEach((eltUnite) => {
              eltUnite.checked = false;
            });
          }
          if (elt.valeurs && elt.valeurs.length) {
            elt.valeurs.forEach((eltValeur) => {
              eltValeur.checked = false;
            });
          }
        });
        this.caracs = test;
        if (this.categorie) {
          this.getFilters("carac by id");
        }
      });
  }

  /** filtre prix */
  filterProduct() {
    if (!this.init) {
      this.init = true;
    } else {
      this.p = 1;
      if (this.filterList.length < 1) {
        this.getProducts(true);
      } else {
        this.getProductByCriteriaSearch();
      }
    }
  }

  setSet(items, ind) {
    this.p = 1;
    let currentElement;
    let fieldName;
    items.forEach((elt, index) => {
      if (ind === index) {
        currentElement = elt;
        fieldName = currentElement.codeHexa ? "couleurs" : "tailles";
      } else {
        elt.checked = false;
      }
    });
    if (!currentElement.checked) {
      delete this.fdProperties[fieldName];
    }

    if (currentElement.codeHexa) {
      this.couleur =
        currentElement.checked === true ? currentElement.id : undefined;
    } else {
      this.taille =
        currentElement.checked === true ? currentElement.id : undefined;
    }

    if (this.couleur || this.taille || this.filterList.length > 0) {
      this.getProductByCriteriaSearch();
    } else {
      this.getProducts(true);
    }
  }

  sliderRangeInit() {
    // $( '#slider-range' ).slider({
    //   range: true,
    //   min: 0,
    //   max: 500,
    //   values: [ 75, 300 ],
    //   slide: function( event, ui ) {
    //    $( '#amount' ).val( '$' + ui.values[ 0 ] + ' - $' + ui.values[ 1 ] );
    //   }
    // });
    // $( '#amount' ).val( '$' + $( '#slider-range' ).slider( 'values', 0 ) +
    //  ' - $' + $( '#slider-range' ).slider( 'values', 1 ) );
  }

  changePage($event) {
    this.p = $event;
    const linkParams = {};
    linkParams["page"] = this.p;
    this.router.navigate(["."], {
      relativeTo: this.activatedRoute,
      queryParams: linkParams,
    });
    if (this.isEnAvant) {
      this.getCatalogPromotion();
    } else {
      if (this.filterList.length < 1) {
        this.getProducts(true);
      } else {
        this.getProductByCriteriaSearch();
      }
    }
    if (isPlatformBrowser(this.platformId)) {
      window.scrollTo(0, 0);
    }
  }

  addNewFilter(code, value) {
    const codeImpacts = ["1", "2"];

    if (codeImpacts.includes(code)) {
      const codeTmp = codeImpacts.find((e) => e !== code);
      this.caracs.caracteristiques = this.allCaracs.caracteristiques.filter(
        (c) => c.caracteristique.code !== codeTmp
      );
    }

    this.p = 1;

    const test = this.filterList
      .filter((elt) => elt.caracteristique_code === code)
      .every((e) => {
        return e.valeur !== value.item.id;
      });

    if (!test) {
      this.filterList = this.filterList.filter(
        (elt) =>
          !(elt.caracteristique_code === code && elt.valeur === value.item.id)
      );
    } else {
      const objet = {
        caracteristique_code: code,
        valeur: value.item.id,
      };
      this.filterList.push(objet);
    }
    if (this.filterList.length > 0) {
      const found = this.filterList.some(
        (r) => codeImpacts.indexOf(r.caracteristique_code) >= 0
      );
      if (!found) {
        this.caracs = { ...this.allCaracs };
      }

      this.getProductByCriteriaSearch();
    } else {
      this.caracs = { ...this.allCaracs };
      this.getProducts(true);
    }
  }

  getProductByCriteriaSearch() {
    const url = "produits/criteria-search";
    console.clear();

    const fdServer = new FormDataServer();

    const objet = { ...this.fdProperties };

    Object.keys(objet).forEach(function (key) {
      fdServer.append(key, objet[key]);
    });

    const tab = this.filterList.map((elt) => elt.caracteristique_code);
    const codes = tab.filter((v, i) => tab.indexOf(v) == i);
    const items = codes.map((elt) => {
      const ids = this.filterList
        .filter((e) => e.caracteristique_code === elt)
        .map((e1) => e1.valeur);
      return { code: elt, valeurs: ids };
    });

    items.forEach((eltItem, i) => {
      fdServer.append(
        "select_criteria[" + i + "].caracteristique_code",
        eltItem.code
      );
      fdServer.append(
        "select_criteria[" + i + "].valeurs",
        eltItem.valeurs.toString()
      );
    });

    fdServer.append("prix.maximum", "" + this.highValueNg5Slider);
    fdServer.append("prix.minimum", "" + this.valueNg5Slider);
    fdServer.append("pagination.par_page", this.ITEM_PER_PAGE);
    fdServer.append("pagination.page", this.p);

    if (this.trierParPrix) {
      fdServer.append("trie_par.attribut", "PRICE");
      fdServer.append("trie_par.is_croissant", "" + this.filter.croissant);
    }

    if (this.couleur) {
      fdServer.append("couleurs", [this.couleur].toString());
    }

    if (this.taille) {
      fdServer.append("tailles", [this.taille].toString());
    }

    if (this.isBrowser) {
      this.httpService.post(url, fdServer).subscribe((dataMenu: any) => {
        this.productsAll = dataMenu.data.produits;
        this.productsToShow = this.productsAll;
        this.nbrProd = dataMenu.data.nb_total;
      });
    }
  }

  formatUrl(name) {
    return name.split(" ").join("_");
  }

  formatUrl2(name) {
    return name.split("_").join(" ");
  }

  updateImage(image) {
    const head = this.document.getElementsByTagName("head")[0];
    let eltHtml: HTMLLinkElement =
      this.document.querySelector(`link[rel='image_src']`) || null;
    if (eltHtml == null) {
      eltHtml = this.document.createElement("link") as HTMLLinkElement;
      head.appendChild(eltHtml);
    }
    eltHtml.setAttribute("rel", "image_src");
    eltHtml.setAttribute("href", image);
  }

  updateMeta(nom, description, image) {
    if (nom) {
      this.title.setTitle(nom + " | Hadéen-Place | Consommer Responsable");
      this.meta.updateTag({
        property: "og:title",
        content: nom + " | Hadéen-Place | Consommer Responsable |",
      });
    }
    if (description) {
      this.meta.updateTag({
        name: "description",
        content: String(description.trim()),
      });
      this.meta.updateTag({
        property: "og:description",
        content: String(description.trim()),
      });
    }
    if (image) {
      // this.altImage = nom + ' | Hadéen-Place | Consommer Responsable |';
      this.meta.updateTag({ property: "og:image", content: image });
    }
    this.meta.updateTag({ property: "og:type", content: "article" });
    this.meta.updateTag({ property: "og:url", content: this.router.url });
  }
}
