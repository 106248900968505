import { Component, OnInit, Input, OnChanges, SimpleChanges  } from '@angular/core';
import { CommonService } from 'src/app/services/common.service';

@Component({
  selector: 'app-produit-list',
  templateUrl: './produit-list.component.html',
  styleUrls: ['./produit-list.component.scss']
})
export class ProduitListComponent implements OnInit, OnChanges {

  @Input() idSlide;
  @Input() data: any;
  @Input() login;
  @Input() type;
  @Input() arraySplit;
  @Input() screenWidth;
  // venteflash
  nbrSlide = [];

  constructor(private commonService: CommonService) { }

  ngOnInit(): void {
    this.loadData();
  }
  ngOnChanges(changes: SimpleChanges) {
    if (changes.screenWidth) {
      this.loadData();
    }
  }
  isView: boolean = false;
  loadData(){
    if(this.arraySplit=='no' && this.arraySplit!=undefined && !this.isView){
      let max, min, moyen = null;      
      max = this.commonService.createNewArray(5, this.data);
      moyen = this.commonService.createNewArray(4, this.data);
      min = this.data;
      this.data = {'max': max, 'moyen': moyen, 'min': min}; 
      this.isView = true;
    }else{
      if(this.screenWidth>=768){
        this.nbrSlide = Array(this.data.max.length).fill(0).map((_, index) => index + 1);
      }else if(this.screenWidth<768 && this.screenWidth>=576){
        this.nbrSlide = Array(this.data.moyen.length).fill(0).map((_, index) => index + 1);;
      }
    }    
  }
}
