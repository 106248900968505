import { Directive, HostListener, ElementRef } from '@angular/core';

@Directive({
  selector: '[appDateFormat]'
})

export class DateFormatDirective {

  constructor() { }

  // @HostListener('input', ['$event']) onKeyDown(event: KeyboardEvent) {
  //   const input = event.target as HTMLInputElement;
  //   let trimmed = input.value.replace(/\s+/g, '');
  //   const len = trimmed.length;
  //   const slash = [...trimmed].filter(l => l === '/').length;
  //   if (event.inputType === 'insertText') {
  //       switch (slash) {
  //         case 0:
  //           const nb = parseInt(trimmed, 10);
  //           if (len < 2 && nb > 3) {
  //             trimmed = '0' + trimmed + '/';
  //           } else if (nb > 31) {
  //             trimmed = trimmed[0];
  //           } else if (len === 2) {
  //             trimmed += '/';
  //           }
  //           break;
  //         case 1:
  //           const tab = trimmed.split('/');
  //           const mois = tab[1];
  //           if (mois.length < 2 && parseInt(mois, 10) > 1) {
  //             trimmed = tab[0] + '/0' + mois + '/';
  //           } else if (parseInt(mois, 10) > 12) {
  //             trimmed = tab[0] + '/1';
  //           } else if (mois.length === 2) {
  //             trimmed += '/';
  //           }
  //           break;
  //         case 2:
  //           if (len === 5) {
  //             trimmed += '/';
  //           }
  //           const tmp = trimmed.split('/');
  //           const year = tmp[2];
  //           if (year.length > 4) {
  //             trimmed = tmp[0] + '/' + tmp[1] + '/' + year.substr(0, 4);
  //           }
  //           break;
  //       }
  //   }
  //   input.value = trimmed;
  // }

}
