import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatMenu } from '@angular/material/menu';
import { Router } from '@angular/router';

@Component({
  selector: 'app-menu-item',
  templateUrl: './menu-item.component.html',
  styleUrls: ['./menu-item.component.scss']
})
export class MenuItemComponent implements OnInit {

  @ViewChild('menu', {static: true}) menu: MatMenu;
  @Input() items: {nom: string, children: string[]}[];
  @Input() mere?;
  @Input() univer?;
  @Input() isChild?;

  constructor(private router: Router) { }

  ngOnInit(): void {
  }

  goTo(univ, enfant?) {
    // const nomUniver = (this.univer.nom).split(' ').join('_');
    // const nomMere = (this.mere.nom).split(' ').join('_');

    // const nomUniver = this.univer.nom;
    // const nomUniver = this.univer.slug;
    const nomUniver = (this.univer.nom).split(' ').join('_');
    let nomMere;
    if (this.mere) {
      // nomMere = this.mere.nom;
      // nomMere = this.mere.slug;
      nomMere = (this.mere.nom).split(' ').join('_');
    }
    if (enfant) {
      // const nomEnfant = enfant.nom;
      // const nomEnfant = enfant.slug;
      const nomEnfant = (enfant.nom).split(' ').join('_');
      this.router.navigate([`/all-product/${nomUniver}/${nomMere}/${nomEnfant}`]);
    } else {
      if (this.mere) {
        this.router.navigate([`/all-product/${nomUniver}/${nomMere}`]);
      } else {
        this.router.navigate([`/all-product/${nomUniver}`]);
      }
    }
  }
}
