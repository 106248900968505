import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { CommonService } from 'src/app/services/common.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-detail',
  templateUrl: './detail.component.html',
  styleUrls: ['./detail.component.scss']
})
export class DetailComponent implements OnInit {

  productId;
  product;
  codeLast;
  dataList;
  imgPle;
  constructor(
    private httpClient: HttpClient, private commonService: CommonService, private router: Router,
    private activatedRoute: ActivatedRoute, private toastrService: ToastrService) { }

  ngOnInit(): void {
    this.productId = this.activatedRoute.snapshot.paramMap.get('id_produit');
    this.codeLast = this.commonService.user.code_achat;
    if (this.codeLast) {
      this.httpClient.get(`${environment.ws_url}achats/code/${this.commonService.user.code_achat}`).subscribe((resp: any) => {
        this.dataList = resp.data;
        this.product = this.dataList.sous_produits.filter(elt => elt.produit.id.produitId === parseInt(this.productId, 10))[0];
        this.imgPle = this.product.images[0].nom;
      });
    } else {
      this.router.navigate(['/compte/mes-achats/consulter-achat']);
    }
  }

  ajoutPanierV2() {
    const dataPanier = new FormData();
    dataPanier.set('quantite', String(this.product.disponibilite_liste_achat));
    dataPanier.set('sous_produit_id', this.product.id);
    dataPanier.set('liste_achat_id', this.dataList.achat.id);
    this.httpClient.post(environment.ws_url + 'paniers', dataPanier).subscribe((response: any) => {
      this.commonService.getPanier();
      this.toastrService.success('Produit ajouté au panier', '', {
        timeOut: 3000
      });
    });
  }

  setImgPle(item) {
    this.imgPle = item.nom;
  }
}
