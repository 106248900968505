<div class="top-header" id="detail_produit">
  <div class="container d-none d-md-block isFixed" style="top:60px!important">
    <div class="header">
      <ul class="breadcrumb" *ngIf="product" style="color: #bbbbbb; font-weight: 400; padding: 0px; font-size: 13px;margin-top: 10px;margin-bottom: 10px;">
        <li style="font-size: 13px;"><a routerLinkActive="active"
          style="color: #bbbbbb; font-weight: 400; font-size: 13px;" [routerLinkActiveOptions]="{exact:true}"
          routerLink="/" class="PoppinsRegular">Hadéen-place</a></li>
        <li  class="PoppinsRegular" style="font-size: 13px;" *ngIf="product.univers"
          [routerLink]="['/all-product/' + encodeLien(product.univers.slug)]">{{ product.univers.nom }}
        </li>
        <li class="PoppinsRegular" style="font-size: 13px;" *ngIf="product.sous_univers"
          [routerLink]="['/all-product/' + encodeLien(product.univers.slug + '/' + product.sous_univers.slug)]">
          {{ product.sous_univers.nom }}</li>
        <li class="PoppinsRegular" style="font-size: 13px;" *ngIf="product.categorie"
          [routerLink]="['/all-product/' + encodeLien(product.univers.slug + '/' + product.sous_univers.slug)] + '/' + encodeLien(product.categorie.slug)">
          {{ product.categorie.nom }}</li>
        <li class="PoppinsRegular" style="font-size: 13px;">{{ product.model }}</li>
      </ul>
    </div>
  </div>

  <div style="padding-bottom: 3%;background: #F6FAF0;" id="mattab">
    <div class="container-md p-md-0" *ngIf="product">
      <div class="row">
        <div class="col-12 col-md-8 mb-0 isFixed" #col8Element>
          <div class="row">
            <div class="col-md-3 d-none d-md-block" style="height: 460px; overflow: auto;">
              <div class="test h-100">
                <div class="text-center">
                  <a *ngIf="imgListeV3?.length > 1" class="carousel-control-nexta" href="#carouselExampleControlsItem"
                    role="button" data-slide="next">
                    <span class="carousel-control-next-icon rounded-circle" aria-hidden="true"
                      style="transform: rotate(-90deg); background-color: #dfdfdf; opacity: .9;"></span>
                    <span class="sr-only">Next</span>
                  </a>
                  <div id="carouselExampleControlsItem" class="carousel vert slide" data-interval="false"
                    data-ride="carousel">
                    <div class="carousel-inner" *ngIf="imgListeV3">
                      <div class="carousel-item" *ngFor="let item of imgListeV3; let ind=index"
                        [ngClass]="{'active active2': ind === 0}">
                        <div data-target="#carouselExampleControls" attr.data-slide-to="{{ind > 0 ? ind * 3 + i : i}}"
                          style="height: 122px" *ngFor="let itemFils of item.fils; let i=index"
                          [ngClass]="{'mb-3': i < 2, 'mb-2': i == 2}">
                          <img width="auto" height="auto" class="d-block img-fluid w-100" [src]="commonService.getImageFormated(200, 200, itemFils.nom)" alt="First slide" style="height: 122px;object-fit: cover;">
                        </div>
                      </div>
                    </div>
                  </div>
                  <a *ngIf="imgListeV3?.length > 1" class="carousel-control-preva mt-2"
                    href="#carouselExampleControlsItem" role="button" data-slide="prev"
                    style="position: absolute; bottom: 0px; right: 46%;">
                    <span class="carousel-control-prev-icon rounded-circle" aria-hidden="true"
                      style="transform: rotate(-90deg); background-color: #dfdfdf; opacity: .9;"></span>
                    <span class="sr-only">Previous</span>
                  </a>
                </div>
              </div>
            </div>
            <div class="col-md-9 pl-0 pr-0 pr-md-4 img-prod">
              <div id="carouselExampleControls" class="carousel slide" data-ride="carousel" data-interval="false" *ngIf="imgListeV2">
                <ol class="carousel-indicators">
                    <li data-target="#carouselExampleControls" *ngFor="let item of imgListeV2; let ind=index" [attr.data-slide-to]="ind" [ngClass]="{'active': ind==0}"></li>
                  </ol>
                <div class="carousel-inner">                  
                  <div class="carousel-item" *ngFor="let item of imgListeV2; let ind=index"
                    [ngClass]="{active: ind === 0}" style="background-color: transparent !important;">
                    <img width="auto" height="auto" class="d-block w-100 newImgProduct" [src]="commonService.getImageFormated(0, 0, item.nom)" style="object-fit: contain;"
                      (click)="openModal(template, ind)" alt="...">
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="row mt-1">
            <div class="offset-md-3 col-md-9 col-12 mb-0">
              <div class="row px-3 px-md-0" *ngFor="let itemCaracs of product?.caracs">
                <ng-container *ngIf="itemCaracs.valeurs.length > 0 && (itemCaracs.caracteristique.code === '1' || itemCaracs.caracteristique.code === '2')">
                  <div class="px-2 mt-2" *ngFor="let val of itemCaracs.valeurs | slice:0:4; let i=index"
                    [ngClass]="{'mr-2': i < itemCaracs.valeurs.length}"
                    style="background-color: #d9d9d9; border-radius: 5px; width: fit-content; padding-bottom: 2px;">
                    <img width="auto" height="auto" *ngIf="val.id === 6 || val.id === 17"
                      src="../../../../assets/images/impactsvg/Impact 2/100_ INGRÉDIENTS NATURELS.svg" alt=""
                      style="width: 15px;">
                    <img width="auto" height="auto" *ngIf="val.id === 4 || val.id === 15" src="../../../../assets/images/impactsvg/Impact 2/ARTISANAL.svg" alt=""
                      style="width: 15px;">
                    <img width="auto" height="auto" *ngIf="val.id === 5 || val.id === 16" src="../../../../assets/images/impactsvg/Impact 2/BIO.svg" alt=""
                      style="width: 15px;">
                    <img width="auto" height="auto" *ngIf="val.id === 8 || val.id === 19" src="../../../../assets/images/impactsvg/Impact 2/ÉCO RESPONSABLE.svg"
                      alt="" style="width: 15px;">
                    <img width="auto" height="auto" *ngIf="val.id === 197" src="../../../../assets/images/impactsvg/Impact 2/FABRIQUÉ EN EUROPE.svg"
                      alt="" style="width: 15px;">
                    <img width="auto" height="auto" *ngIf="val.id === 3 || val.id === 14" src="../../../../assets/images/impactsvg/Impact 2/FABRIQUÉ EN FRANCE.svg"
                      alt="" style="width: 15px;">
                    <img width="auto" height="auto" *ngIf="val.valeur === 'INGRÉDIENTS ISSU DU COMMERCE ÉQUITABLE'"
                      src="../../../../assets/images/impactsvg/Impact 2/INGRÉDIENTS ISSU DU COMMERCE ÉQUITABLE.svg" alt=""
                      style="width: 15px;">
                    <img width="auto" height="auto" *ngIf="val.valeur === 'ISSU DE MATÉRIAUX RECYCLÉS'"
                      src="../../../../assets/images/impactsvg/Impact 2/ISSU DE MATÉRIAUX RECYCLÉS.svg" alt=""
                      style="width: 15px;">
                    <img width="auto" height="auto" *ngIf="val.id === 9 || val.id === 20" src="../../../../assets/images/impactsvg/Impact 2/RECYCLABLE.svg" alt=""
                      style="width: 15px;">
                    <img width="auto" height="auto" *ngIf="val.id === 2 || val.id === 13" src="../../../../assets/images/impactsvg/Impact 2/SANS CRUAUTÉ ANIMALE.svg"
                      alt="" style="width: 15px;">
                    <img width="auto" height="auto" *ngIf="val.id === 1 || val.id === 12" src="../../../../assets/images/impactsvg/Impact 2/VEGAN.svg" alt=""
                      style="width: 15px;">
                    <img width="auto" height="auto" *ngIf="val.valeur === 'ZÉRO DÉCHET'"
                      src="../../../../assets/images/impactsvg/Impact 2/ZÉRO DÉCHET.svg" alt="" style="width: 15px;">

                    <img width="auto" height="auto" *ngIf="val.valeur === 'SANS GLUTEN'"
                      src="../../../../assets/images/impactsvg/Impact 2/sans-gluten.svg" alt="" style="width: 15px;">
                    <img width="auto" height="auto" *ngIf="val.valeur === 'SANS SUCRE AJOUTÉS'"
                      src="../../../../assets/images/impactsvg/Impact 2/sans-sucre-ajoutés.svg" alt="" style="width: 15px;">
                    <img width="auto" height="auto" *ngIf="val.valeur === 'SANS SEL AJOUTÉS'"
                      src="../../../../assets/images/impactsvg/Impact 2/sans-sel-ajouté.svg" alt="" style="width: 15px;">
                    <img width="auto" height="auto" *ngIf="val.valeur === 'SANS LACTOSE'"
                      src="../../../../assets/images/impactsvg/Impact 2/sans lactose.svg" alt="" style="width: 15px;">
                    <img width="auto" height="auto" *ngIf="val.valeur === 'SANS HUILE DE PALME'"
                      src="../../../../assets/images/impactsvg/Impact 2/sans huile de palme.svg" alt="" style="width: 15px;">
                    <span style="font-size: 10px; margin: 0px 0px 0px 5px;">{{val.valeur}}</span>
                  </div>
                </ng-container>
              </div>
            </div>
          </div>
        </div>

        <div class="col-12 col-md-4 px-3 px-md-0" style="font-size: 13px;">
          <h1 class="mt-0 mb-2" style="text-align: left; font-size: 21px; font-family: 'DIN Bold'; margin: 3% 0;">
            <span style="word-wrap: break-word;">{{codeOUmodel}}</span><span style="font-size: 0px;"> {{productId}}</span>
          </h1>
          <!-- <h2 *ngIf="product.model && product.code" class="mt-0 mb-2" style="text-align: left; font-size: 16px; font-family: 'DIN Bold'; margin: 3% 0;">{{ product.model }} <span style="font-size: 0px;">{{product.id.produitId}}</span></h2> -->
          <div *ngIf="product?.feedback_produits?.length">
            <ngb-rating [(rate)]="moyenneNon">
              <ng-template let-fill="fill" let-index="index">
                <span class="star" [class.full]="fill === 100">
                  <span class="half" [style.width.%]="fill">&#9733;</span>&#9733;
                </span>
              </ng-template>
            </ngb-rating>
            <label class="mb-0"> {{moyenne}} / 5
              <span *ngIf="product.vendeur.details" (click)="scrollToElement(product.feedback_produits.length)"
                style="cursor: pointer;">
                ({{product.feedback_produits.length}} avis)
              </span>
            </label>
          </div>

          <h2 class="d-none">{{product?.sous_univers.nom}} | {{product?.categorie.nom}}</h2>
          <h2 class="buyby mt-0 mb-0 mb-md-4 text-left" style="font-size: 13px; font-family: 'SFCompactDisplay-Regular'; text-transform: 'none'">Vendu par : <span *ngIf="product?.vendeur?.details"
            [routerLink]="['/detail-vendeur/' + formatUrl(product.vendeur.pseudo) + '-' + product.vendeur.id ]"
            style="color: #345B2B; font-weight: 600; cursor: pointer;">{{ product.vendeur.details.boutique.nom
            }}</span>
          </h2>
          <div class="mb-2 mt-0 mt-md-3 d-block d-md-none">
            Livraison estimée le {{dateEstimation | date:'dd/MM/yyyy'}}
          </div>

          <div class="d-flex flex-column-reverse flex-md-column">
            <div class="pictos row w-100 mx-0">
                <div class="col-6 mb-3 d-flex align-items-center picto-item" *ngFor="let item of pictos">
                  <img [src]="item.picto.url" alt="picto" class="mr-2">
                  <div>{{item.picto_text}}</div>
                </div>
            </div>

            <!-- description<p class="clearfix">
              <span class="collapsable float-left">
                <p class="text-left mb-0" *ngIf="product.descriptionTag.length <= 200"
                  style="white-space: pre-line; font-size: 16px;" [innerHTML]="product.descriptionTag"></p>
                <p class="text-left mb-0" *ngIf="product.descriptionTag.length > 200"
                  style="white-space: pre-line; font-size: 16px;"
                  [innerHTML]="(product.descriptionTag | slice:0:counter)+'..'"></p>
                <span *ngIf="(product.descriptionTag.length > 200)" class="redTxt"
                  style="float: right; background-color: transparent !important; color: #345B2B"
                  (click)="goToDesc($event); setStep(1)">
                  {{showTxt}}
                </span>
              </span>
            </p> -->
            <div class="row w-100 mx-0" [ngClass]="{'mb-3': !sousProduits || !sousProduits[0].couleur}">
              <div *ngIf="tailleByColor && tailleByColor.length && (product.prix2 || product.prix3)" [ngClass]="{'col-12': sousProduits && sousProduits[0].couleur, 'col-5': !sousProduits || !sousProduits[0].couleur}" class="px-0 mb-0">
                <!-- <p class="d-none mb-2 d-md-block">Taille</p> -->
                <!-- <div class="row d-none d-md-flex" style="height: 37px;">
                  <div class="col-6 col-sm-6 col-md-6 col-lg-4" *ngFor="let itemTaille of tailleByColor;">
                    <p class="selectSize" style="border: 1px solid #E6E6E6; cursor: pointer;"
                      (click)="setTailleV2(itemTaille)" [ngClass]="{'active': sousProdSelected === itemTaille}">
                      {{ itemTaille.taille.valeur }}</p>
                  </div>
                </div> -->
                <mat-form-field class="mt-3 inputEffect px-0 col-5" style="height: 50px;max-width: initial;">
                  <mat-label>Taille</mat-label>
                  <mat-select [(value)]="sousProdSelected" (selectionChange)="setTailleV2(sousProdSelected)">
                    <mat-option *ngFor="let itemTaille of tailleByColor" [value]="itemTaille">
                      {{ itemTaille.taille.valeur }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
              <div class="col-5 col-md-12 col-lg-5 px-0 mb-0 couleur_box" *ngIf="sousProduits && sousProduits[0].couleur">
                <mat-form-field class="w-100 inputEffect">
                  <mat-label>COULEUR</mat-label>
                  <mat-select [(value)]="colorSelectedIndex" (selectionChange)="setColorV2(colorSelectedIndex, true)">
                    <mat-select-trigger>
                      <div *ngIf="colorSelectedIndex >= 0" class="row m-0">
                        <div class="custom-icon"></div>
                        <div style="width: 15px; height: 15px; border-radius: 50%; margin-right: 7px;" [ngStyle]="{
                          'background' : !sousProduits[colorSelectedIndex].couleur ? 'url(assets/images/noCouleur.PNG)' : sousProduits[colorSelectedIndex].couleur.code_hexa === 'MULTI' ? 'url(assets/images/multicolor.png)' : sousProduits[colorSelectedIndex].couleur.code_hexa}"></div>
                        {{sousProduits[colorSelectedIndex].couleur?.nom}}
                      </div>
                    </mat-select-trigger>
                    <mat-option style="background: white;color: black;" *ngFor="let itemColor of sousProduits; let ind = index" [value]="ind">
                      <div class="row m-0">
                        <div class="my-auto" style="width: 15px; height: 15px; border-radius: 50%; margin-right: 7px;" [ngStyle]="{
                          'background' : !itemColor.couleur ? 'url(assets/images/noCouleur.PNG)' : itemColor.couleur.code_hexa === 'MULTI' ? 'url(assets/images/multicolor.png)' : itemColor.couleur.code_hexa}"></div>
                        {{itemColor.couleur?.nom}}
                      </div>
                    </mat-option>

                  </mat-select>
                </mat-form-field>
              </div>
              <div [ngClass]="{'col-4 col-md-6 col-lg-4 mb-2': sousProduits && sousProduits[0].couleur, 'col-6 mb-3': !sousProduits || !sousProduits[0].couleur}" class="px-0 text-center">
                <p style="margin: 10px 0 0 0;">
                  <span style="font-size: 16px;">
                    <span style="padding: 5px 12px; background-color: #F4F4F4; font-size: 18px;"
                      (click)="crementQuantite(-1)">-</span>
                    <span style="padding: 10px; background-color: #fbfafa;">{{ quantiteV2 }} </span>
                    <span style="padding: 5px 12px; background-color: #F4F4F4; font-size: 18px;"
                      (click)="crementQuantite(1)">+</span>
                  </span>
                </p>
              </div>
              <div [ngClass]="{'col-3 col-md-6 col-lg-3 mb-2': sousProduits && sousProduits[0].couleur, 'col-6 mb-3': !sousProduits || !sousProduits[0].couleur}" class="px-0 text-center mb-2">
                <p class="m-auto p-0" style="margin-top: 11px!important;">
                  <span [ngStyle]="{'color': statutSousProd > 0 ? '#345B2B': '#ff0000'}"
                    style="background-color: #DBFBE4; padding: 2px 6px 4px 4px;font-size: 14px;">
                    <img  width="auto" height="auto"*ngIf="statutSousProd > 0 && product.statut ==='EN_LIGNE'" src="assets/images/logo/checkGreen.png"
                      alt="">
                    <img width="auto" height="auto" *ngIf="statutSousProd < 1 || quantiteV2 < 1 || product.statut !=='EN_LIGNE'"
                      src="../../../../assets/images/icons/exclamation-mark.svg" alt="" style="width: 13px;">
                    {{ statutSousProd < 1 || quantiteV2 < 1 || product.statut !=='EN_LIGNE' ? 'En rupture' : 'En stock' }}
                      </span>
                </p>
              </div>
              <div *ngIf="tailleByColor && tailleByColor.length && (product.prix2==null && product.prix3==null)" [ngClass]="{'col-12': sousProduits && sousProduits[0].couleur, 'col-5': !sousProduits || !sousProduits[0].couleur}" class="px-0 mb-0">
                <!-- <p class="d-none mb-2 d-md-block">Taille</p> -->
                <!-- <div class="row d-none d-md-flex" style="height: 37px;">
                  <div class="col-6 col-sm-6 col-md-6 col-lg-4" *ngFor="let itemTaille of tailleByColor;">
                    <p class="selectSize" style="border: 1px solid #E6E6E6; cursor: pointer;"
                      (click)="setTailleV2(itemTaille)" [ngClass]="{'active': sousProdSelected === itemTaille}">
                      {{ itemTaille.taille.valeur }}</p>
                  </div>
                </div> -->
                <mat-form-field class="mt-0 inputEffect px-0 col-5" style="height: 50px;max-width: inherit;">
                  <mat-label>Taille</mat-label>
                  <mat-select [(value)]="sousProdSelected" (selectionChange)="setTailleV2(sousProdSelected)">
                    <mat-option *ngFor="let itemTaille of tailleByColor" [value]="itemTaille">
                      {{ itemTaille.taille.valeur }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
              <div class="row w-100 mx-0 mt-0">
                <ng-container *ngIf="product.prix2 || product.prix3">
                  <div class="pl-0 pr-1 mb-2" [ngClass]="{'col-4': this.product.prix2 && this.product.prix3, 'col-6': !this.product.prix2 || !this.product.prix3 || statutSousProd<=2}">
                    <div class="display-responsive-eco h-resp">
                        
                    </div>
                    <div class="box_prix PoppinsRegular" [ngClass]="{'active-box': activeBox==1}" (click)="selectedBox(1)">
                      <div class="float-left mr-2 ovale">
                        <img [src]="commonService.getImageFormated(70, 70, imgListeV2[0].nom)" style="border-radius: 100%;transform: rotate(24deg);height: 46px;width: 46px;">
                      </div>
                      <div class="text-center">
                        <div class="PoppinsMedium">Achetez 1</div>
                        <div style="text-decoration: line-through; margin-right: 10px;" *ngIf="product?.prix_promotion > 0">{{
                          product.prix | number : '1.2-2' }} €</div>
                        <div style="color: #d9b96f;font-family: 'Poppins-Bold';">{{prixOneProduct | number : '1.2-2'}}€</div>
                      </div>
                    </div>
                  </div>
                  <div class="px-1 mb-2" [ngClass]="{'col-4': this.product.prix3, 'col-6': !this.product.prix3 || statutSousProd<=2}" *ngIf="this.product.prix2 && statutSousProd>=2">
                    <div class="top display-responsive-eco">
                        Économisez {{ (this.product.prix*2)-(this.product.prix2*2) | number : '1.2-2' }}€
                    </div>
                    <div class="box_prix PoppinsRegular" [ngClass]="{'active-box': activeBox==2}" (click)="selectedBox(2)">    
                      <div class="top display-responsive-ecomd">
                        Économisez {{ (this.product.prix*2)-(this.product.prix2*2) | number : '1.2-2' }}€
                      </div>                  
                      <div class="float-left no-ovale d-flex justify-content-center">
                        <img [src]="commonService.getImageFormated(47, 47, imgListeV2[0].nom)" style="margin-right: 8px;margin-left: 0px;border-radius: 100%;transform: rotate(0);height: 30px;width: 30px;">
                        <img [src]="commonService.getImageFormated(47, 47, imgListeV2[0].nom)" style="border-radius: 100%;transform: rotate(0);height: 30px;width: 30px;">
                      </div>
                      <div class="text-center">
                        <div class="PoppinsMedium">Achetez 2</div>
                        <span style="color: #d9b96f;font-family: 'Poppins-Bold';">{{ (product.prix2*2) | number : '1.2-2' }}€</span>
                      </div>
                    </div>
                  </div>
                  <div class="pl-1 pr-0 mb-2" [ngClass]="{'col-4': this.product.prix2, 'col-6': !this.product.prix2}" *ngIf="this.product.prix3 && statutSousProd>=3">
                    <div class="top display-responsive-eco">
                        Économisez {{ (this.product.prix*3)-(this.product.prix3*3) | number : '1.2-2' }}€
                      </div>
                    <div class="box_prix PoppinsRegular" [ngClass]="{'active-box': activeBox==3}" (click)="selectedBox(3)">                      
                      <div class="top display-responsive-ecomd">
                        Économisez {{ (this.product.prix*3)-(this.product.prix3*3) | number : '1.2-2' }}€
                      </div>
                      <div class="float-left no-ovale d-flex justify-content-center">
                        <img [src]="commonService.getImageFormated(47, 47, imgListeV2[0].nom)" style="margin-right: 1px;margin-left: 1px;border-radius: 100%;transform: rotate(0deg);height: 30px;width: 30px;">
                        <img [src]="commonService.getImageFormated(47, 47, imgListeV2[0].nom)" style="margin-right: 1px;margin-left: 1px;border-radius: 100%;transform: rotate(0deg);height: 30px;width: 30px;">
                        <img [src]="commonService.getImageFormated(47, 47, imgListeV2[0].nom)" style="margin-left: 1px;border-radius: 100%;transform: rotate(0deg);height: 30px;width: 30px;">
                      </div>
                      <div class="text-center">
                        <div class="PoppinsMedium">Achetez 3</div>
                        <span style="color: #d9b96f;font-family: 'Poppins-Bold';">{{ (product.prix3*3) | number : '1.2-2' }}€</span>
                      </div>
                    </div>
                  </div>
                  <!-- <div class="px-1 mb-2" [ngClass]="{'col-4': this.product.prix3, 'col-6': !this.product.prix3 || statutSousProd<=2}" *ngIf="this.product.prix2 && statutSousProd>=2">
                    <div class="top display-responsive-eco">
                        Économisez {{ (this.product.prix*2)-(this.product.prix2*2) | number : '1.2-2' }}€
                    </div>
                    <div class="box_prix PoppinsRegular" [ngClass]="{'active-box': activeBox==2}" (click)="selectedBox(2)">    
                      <div class="top display-responsive-ecomd">
                        Économisez {{ (this.product.prix*2)-(this.product.prix2*2) | number : '1.2-2' }}€
                      </div>                  
                      <div class="float-left mr-2 ovale">
                        <img [src]="commonService.getImageFormated(47, 47, imgListeV2[0].nom)" style="border-radius: 100%;transform: rotate(24deg);height: 46px;width: 46px;">
                      </div>
                      <div class="text-center">
                        <div class="PoppinsMedium">Achetez 2</div>
                        <span style="color: #d9b96f;font-family: 'Poppins-Bold';">{{ (product.prix2*2) | number : '1.2-2' }}€</span>
                      </div>
                    </div>
                  </div>
                  <div class="pl-1 pr-0 mb-2" [ngClass]="{'col-4': this.product.prix2, 'col-6': !this.product.prix2}" *ngIf="this.product.prix3 && statutSousProd>=3">
                    <div class="top display-responsive-eco">
                        Économisez {{ (this.product.prix*3)-(this.product.prix3*3) | number : '1.2-2' }}€
                      </div>
                    <div class="box_prix PoppinsRegular" [ngClass]="{'active-box': activeBox==3}" (click)="selectedBox(3)">                      
                      <div class="top display-responsive-ecomd">
                        Économisez {{ (this.product.prix*3)-(this.product.prix3*3) | number : '1.2-2' }}€
                      </div>
                      <div class="float-left mr-2 ovale">
                        <img [src]="commonService.getImageFormated(47, 47, imgListeV2[0].nom)" style="border-radius: 100%;transform: rotate(24deg);height: 46px;width: 46px;">
                      </div>
                      <div class="text-center">
                        <div class="PoppinsMedium">Achetez 3</div>
                        <span style="color: #d9b96f;font-family: 'Poppins-Bold';">{{ (product.prix3*3) | number : '1.2-2' }}€</span>
                      </div>
                    </div>
                  </div> -->
              </ng-container>
              </div>
            </div>
          </div>
          <div class="mb-2 d-md-block d-none" style="margin-top: 0;">
            <button *ngIf="statutSousProd < 1 || quantiteV2 < 1 || product.statut !=='EN_LIGNE'"
                  class="w-100 rounded-0 h-btn-dore PoppinsRegular" (click)="openModalContactDisponibilite()" style="width: auto; font-size: 13px;">DEMANDER LA DISPONIBILITÉ</button>

                  <button class="h-btn-dore rounded-0 w-100 d-none d-md-block PoppinsRegular"
                *ngIf="statutSousProd > 0 && product.statut ==='EN_LIGNE'"
                [ngClass]="sousProdSelected && quantiteV2 > 0? 'h-btn-dore': 'chartDisabled disabled'"
                style="width: auto; font-size: 15px;" [disabled]="!sousProdSelected || quantiteV2 < 1 ? true : false"
                (click)="ajoutPanierV2()">AJOUTER AU PANIER - {{ prixFinal | number : '1.2-2'}} € <div style="text-decoration: line-through; margin-right: 10px;font-size: 12px;" *ngIf="product?.prix_promotion > 0">({{
                  product.prix | number : '1.2-2' }} €)</div></button>
          </div>
          <div class="mt-2 d-none d-md-block">
            Livraison estimée le {{dateEstimation | date:'dd/MM/yyyy'}}
          </div>
          <!-- <div class="row w-100">
            <div *ngIf="tailleByColor && tailleByColor.length" class="col">
              <p class="d-none mb-2 d-md-block">Taille</p>
              <div class="row d-none d-md-flex">
                <div class="col-6 col-sm-6 col-md-6 col-lg-4" *ngFor="let itemTaille of tailleByColor;">
                  <p class="selectSize" style="border: 1px solid #E6E6E6; cursor: pointer;"
                    (click)="setTailleV2(itemTaille)" [ngClass]="{'active': sousProdSelected === itemTaille}">
                    {{ itemTaille.taille.valeur }}</p>
                </div>
              </div>
              <mat-form-field class="d-md-none mt-3 hadeenInput">
                <mat-label>Taille</mat-label>
                <mat-select [(value)]="sousProdSelected">
                  <mat-option *ngFor="let itemTaille of tailleByColor" [value]="itemTaille">
                    {{ itemTaille.taille.valeur }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </div> -->
          <div class="uk-inline" *ngIf="user && product.statut !== 'ARCHIVE'">
            <button class="buy2" type="button" style="font-size: 13px;">AJOUTER À MA LISTE D'ACHATS</button>
            <div class="text-left" uk-drop="mode: click; pos: top-right" style="width: 375px;" #test id="test">
              <div class="uk-card uk-card-default pt-2 pb-2" style="border-radius: 5px;">
                <p class="col-12 m-0">
                  Voulez vous ajouter le produit à la la liste
                  <span class="uk-margin-small-right uk-drop-close" uk-icon="close" type="button"
                    style="position: absolute; top: 0px; right: 0px;"></span>
                </p>
                <p class="col-12 m-0 ml-2" *ngFor="let itemListeAchat of achatsListe; let ind = index">
                  <mat-checkbox *ngIf="itemListeAchat.status === 'ACTIF'" class="example-margin"
                    (change)="selectList(achatsListe, ind)" [(ngModel)]="itemListeAchat.state">
                    {{ itemListeAchat.event }}: {{ itemListeAchat.title }}</mat-checkbox>
                </p>
                <div class="col-12 mt-2">
                  <div class="row">
                    <div class="col-6 pr-2 uk-drop-close"><button mat-flat-button
                        (click)="ajouterAuListe(achatActive)" class="h-btn-grey p-0"
                        style="font-size: 13px;">Valider</button></div>
                    <div class="col-6 pl-2 uk-drop-close"><button mat-flat-button
                        (click)="validerListe(achatActive, templateResultList)" class="h-btn-green p-0"
                        style="font-size: 13px;">Partager</button></div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <table class="w-100 mt-1 noBorder">
            <!-- <tr class="noBorder d-none d-md-block">
              <td class="noBorder" style="padding: 0; white-space: nowrap;">
                  <span style="text-decoration: line-through; margin-right: 10px;" *ngIf="product.prix_promotion > 0">{{
                    product.prix | number : '1.2-2' }} €</span>
                  <span style="font-size: 30px; color: #42594F; font-family: 'SFCOmpact Semibold';"
                    *ngIf="product.prix_promotion > 0">{{ product.prix_promotion | number : '1.2-2' }} €</span>
                  <span style="font-size: 28px; color: #42594F; font-family: 'SFCOmpact Semibold';"
                    *ngIf="!product.prix_promotion || product.prix_promotion === 0">{{ product.prix | number : '1.2-2' }}
                    €</span>
              </td>
            </tr> -->
            <!-- <tr class="noBorder" *ngIf="user && user.sousVendeur && product.statut !== 'ARCHIVE'">
              <td class="noBorder"></td>
              <td colspan="2" class="px-0 pt-3 noBorder text-right">
                <div class="uk-inline" *ngIf="user && user.sousVendeur && product.statut !== 'ARCHIVE'">
                  <button class="buy2" type="button" style="font-size: 13px;">AJOUTER À MA LISTE D'ACHATS</button>
                  <div class="text-left" uk-drop="mode: click; pos: top-right" style="width: 375px;" #test id="test">
                    <div class="uk-card uk-card-default pt-2 pb-2" style="border-radius: 5px;">
                      <p class="col-12 m-0">
                        Voulez vous ajouter le produit à la la liste
                        <span class="uk-margin-small-right uk-drop-close" uk-icon="close" type="button"
                          style="position: absolute; top: 0px; right: 0px;"></span>
                      </p>
                      <p class="col-12 m-0 ml-2" *ngFor="let itemListeAchat of achatsListe; let ind = index">
                        <mat-checkbox *ngIf="itemListeAchat.status === 'ACTIF'" class="example-margin"
                          (change)="selectList(achatsListe, ind)" [(ngModel)]="itemListeAchat.state">
                          {{ itemListeAchat.event }}: {{ itemListeAchat.title }}</mat-checkbox>
                      </p>
                      <div class="col-12 mt-2">
                        <div class="row">
                          <div class="col-6 pr-2 uk-drop-close"><button mat-flat-button
                              (click)="ajouterAuListe(achatActive)" class="h-btn-grey p-0"
                              style="font-size: 13px;">Valider</button></div>
                          <div class="col-6 pl-2 uk-drop-close"><button mat-flat-button
                              (click)="validerListe(achatActive, templateResultList)" class="h-btn-green p-0"
                              style="font-size: 13px;">Partager</button></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </td>
            </tr> -->
          </table>

          <!-- <p class="mt-1 mb-0">
            <span style="text-decoration: line-through; margin-right: 10px;" *ngIf="product.prix_promotion > 0">{{
              product.prix | number : '1.2-2' }} €</span>
            <span style="font-size: 30px; color: #345B2B; font-family: 'SFCOmpact Semibold';"
              *ngIf="product.prix_promotion > 0">{{ product.prix_promotion | number : '1.2-2' }} €</span>
            <span style="font-size: 30px; color: #345B2B; font-family: 'SFCOmpact Semibold';"
              *ngIf="!product.prix_promotion || product.prix_promotion === 0">{{ product.prix | number : '1.2-2' }}
              €</span>
          </p> -->

          <!-- <div class="m-auto">
            <p style="margin: 1% 0 1% 0;">
              Quantité: <span style="font-size: 16px;">
                <span style="padding: 5px 12px; background-color: #F4F4F4; font-size: 18px;"
                  (click)="crementQuantite(-1)">-</span>
                <span style="padding: 10px; background-color: #fbfafa;">{{ quantiteV2 }} </span>
                <span style="padding: 5px 12px; background-color: #F4F4F4; font-size: 18px;"
                  (click)="crementQuantite(1)">+</span>
              </span>
            </p>
          </div> -->

          <!-- <div class="d-inline-flex" style="margin-top: 5%">
            <div class="uk-inline mr-2" *ngIf="user && user.sousVendeur && product.statut !== 'ARCHIVE'">
              <button class="buy2" type="button" style="font-size: 13px;">AJOUTER À MA LISTE D'ACHATS</button>
              <div uk-drop="mode: click; pos: top-right" style="width: 375px;" #test id="test">
                <div class="uk-card uk-card-default pt-2 pb-2" style="border-radius: 5px;">
                  <p class="col-12 m-0">
                    Voulez vous ajouter le produit à la la liste
                    <span class="uk-margin-small-right uk-drop-close" uk-icon="close" type="button"
                      style="position: absolute; top: 0px; right: 0px;"></span>
                  </p>
                  <p class="col-12 m-0 ml-2" *ngFor="let itemListeAchat of achatsListe; let ind = index">
                    <mat-checkbox *ngIf="itemListeAchat.status === 'ACTIF'" class="example-margin"
                      (change)="selectList(achatsListe, ind)" [(ngModel)]="itemListeAchat.state">
                      {{ itemListeAchat.event }}: {{ itemListeAchat.title }}</mat-checkbox>
                  </p>
                  <div class="col-12 mt-2">
                    <div>
                      <div class="row">
                        <div class="col-6 pr-2 uk-drop-close"><button mat-flat-button
                            (click)="ajouterAuListe(achatActive)" class="h-btn-grey p-0"
                            style="font-size: 13px;">Valider</button></div>
                        <div class="col-6 pl-2 uk-drop-close"><button mat-flat-button
                            (click)="validerListe(achatActive, templateResultList)" class="h-btn-green p-0"
                            style="font-size: 13px;">Partager</button></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div id="idBlockDescription">
              <button *ngIf="quantiteV2 < 1 || product.statut === 'ARCHIVE' || product.statut === 'EN_CONGE'"
                class="chart" (click)="openModalContactDisponibilite()" style="width: auto; font-size: 13px;">DEMANDER LA DISPONIBILITÉ</button>
              <button class="h-btn-green-green"
                *ngIf="quantiteV2 > 0 && product.statut !== 'ARCHIVE' && product.statut !== 'EN_CONGE'"
                [ngClass]="sousProdSelected && quantiteV2 > 0? 'h-btn-green-green': 'chartDisabled disabled'"
                style="width: auto; font-size: 13px;" [disabled]="!sousProdSelected || quantiteV2 < 1 ? true : false"
                (click)="ajoutPanierV2()">AJOUTER AU PANIER</button>
            </div>
          </div> -->

          <!-- <div class="mb-5">Livraison estimée le {{dateEstimation | date:'dd/MM/yyyy'}}</div> -->

          <!-- <div class="mt-2" id="idBlockDescription" style="font-size: 11px; font-family: 'SFCompactDisplay-Regular'">Vous aimez ? <span
              style="font-size: 11px; font-family: 'SFCOmpact Semibold'; color: #345B2B;">Partagez-le!</span> &nbsp; &nbsp;<span>
              <div class="image-card" style="display: inline-block;">
                <a target="_blank"
                  style="background-color: #345B2B; color: white; padding: 5px 10px 5px 8px; border-radius: 50%;"
                  href="https://www.facebook.com/sharer/sharer.php?u={{path}}">
                  <i class="fab fa-facebook-f"></i>
                  <span class="d-none">facebook</span>
                </a>
                <a target="_blank"
                  style="margin-left: 10px; background-color: #345B2B; color: white; padding: 5px 7px; border-radius: 50%;"
                  href="https://www.instagram.com/?url={{path}}">
                  <i class="fab fa-instagram"></i>
                  <span class="d-none">instagram</span>
                </a>
                <a target="_blank"
                  style="margin-left: 10px; background-color: #345B2B; color: white; padding: 5px 7px; border-radius: 50%;"
                  href="mailto:?subject=Invitation&body={{path}}">
                  <i class="fas fa-envelope"></i>
                  <span class="d-none">message</span>
                </a>
              </div>
            </span>
          </div> -->
          <div class="mobile-detail mt-3" *ngIf="product && product.vendeur.details">
            <mat-expansion-panel [expanded]="step === 1 || step === 0" (opened)="setStep(1)">
              <mat-expansion-panel-header>
                <p id="idBlockDescriptionMobile" class="my-auto PoppinsMedium">Descriptif</p>
              </mat-expansion-panel-header>
              <div class="text-left" style="white-space: pre-line;" [innerHTML]="expanded ? product?.descriptionTag : showDesceriptif(product?.descriptionTag)"></div>
              <a href="#" (click)="readMore($event)">{{expanded ? 'Voir moins': 'Voir plus'}}</a>
            </mat-expansion-panel>
            <mat-expansion-panel [expanded]="step === 2" (opened)="setStep(2)">
              <mat-expansion-panel-header>
                <p class="my-auto PoppinsMedium">Caractéristiques</p>
              </mat-expansion-panel-header>
              <table class="m-auto">
                <tr *ngFor="let itemCaracs of product.caracs">
                  <td class="pl-2" style="font-size: 13px;">{{ itemCaracs.caracteristique.nom }}</td>
                  <ng-container *ngIf="itemCaracs.valeurs.length>0; else elseTemplate">
                    <td style="font-size: 13px;">
                      <span *ngFor="let val of itemCaracs.valeurs;let i=index">{{val.valeur | lowercase}}<i *ngIf="i<(itemCaracs.valeurs.length-1)">, </i> </span>
                      <span *ngFor="let u of itemCaracs.unites">{{u.unite}}</span>
                    </td>
                  </ng-container>
                  <ng-template #elseTemplate>
                    <td style="font-size: 13px;">{{ removeLastComma(itemCaracs.valeur) }}
                      <ng-container *ngIf="itemCaracs.unites.length>0; else elseTemplate">
                        <span *ngFor="let u of itemCaracs.unites">{{u.unite}}</span>
                      </ng-container>
                      <ng-template #elseTemplate>
                        <span *ngIf="itemCaracs.caracteristique.unite">{{itemCaracs.unite}}</span>
                      </ng-template>
                    </td>
                  </ng-template>
                </tr>
              </table>
            </mat-expansion-panel>
            <mat-expansion-panel *ngIf="avis.length" [expanded]="step == 3" (opened)="setStep(3)" id="avismobile">
              <mat-expansion-panel-header>
                <h2 class="my-auto" style="font-family: 'DIN Bold'; font-size: 15px;">Avis <span style="font-size: 0px;">{{product.code}}</span></h2>
              </mat-expansion-panel-header>
              <div class="row">
                <div class="col-md-6 col-lg-6 col-12" *ngFor="let a of avis">
                  <div class="text-center avis" style="border: 1px solid;padding: 4%;margin-bottom: 5%;">
                    <div>
                      <ngb-rating [(rate)]="a.star">
                        <ng-template let-fill="fill" let-index="index">
                          <span class="star" [class.full]="fill === 100">
                            <span class="half" [style.width.%]="fill">&#9733;</span>&#9733;
                          </span>
                        </ng-template>
                      </ngb-rating>
                      <label> {{a.star}} / 5</label>
                    </div>
                    <p>{{a.commentaire}}</p>
                    <p> {{a.acheteur.pseudo | titlecase }}, <span>{{a.created_at|date}}</span></p>
                  </div>
                </div>
              </div>
            </mat-expansion-panel>
            <mat-expansion-panel [expanded]="step === 4" (opened)="setStep(4)">
              <mat-expansion-panel-header>
                <p class="my-auto PoppinsMedium">Le vendeur</p>
              </mat-expansion-panel-header>
              <div class="vendeur mt-3" style="padding: 2%;">
                <div class="row">
                  <div class="col-md-4 col-sm-3 text-center">
                    <img width="auto" height="auto" class="rounded-circled" [src]="'https://service-pic.vatilab.com/unsafe/fit-in/82x82/filters:format(webp)/'+product.vendeur.details.boutique.logo" alt="..."
                      style="border-radius: 50%; cursor: pointer;">
                    <button class="boutique w-auto"
                      [routerLink]="['/detail-vendeur/' + formatUrl(product.vendeur.pseudo) + '-' + product.vendeur.id ]">BOUTIQUE</button>
                  </div>
                  <div class="col-8">
                    <div class="row">
                      <div class="col-12">
                        <h2>{{ product.vendeur.details.boutique.nom }}</h2>
                        <p style="color: #666666;">{{ removeLastComma(product.vendeur.details.vendeur.adresse_societe) }}</p>
                        <p style="font-size: 13px;">{{ product.vendeur.details.vente }} produits en vente</p>
                      </div>
                      <div class="col-12">
                        <ngb-rating [(rate)]="note" class="aaa">
                          <ng-template let-fill="fill" let-index="index">
                            <span class="star" [class.full]="fill === 100">
                              <span class="half" [style.width.%]="fill">&#9733;</span>&#9733;
                            </span>
                          </ng-template>
                        </ngb-rating>
                        <label style="font-size: 13px;"> {{note}} / 5 | <span style="text-decoration: underline;"
                            [routerLink]="['/detail-vendeur/' + formatUrl(product.vendeur.pseudo) + '-' + product.vendeur.id]"
                            routerLinkActive="router-link-active"> Voir tous les
                            avis({{product.vendeur.details.avis.length}})</span></label>
                      </div>
                    </div>
                  </div>
                  <div class="d-flex flex-column mt-3 px-3">
                    <div>
                      <p class="font-weight-bold" style="font-size: 13px;">Conditions de ventes</p>
                      <p *ngIf="product.vendeur.details" style="white-space: pre-line;">
                        {{ product.vendeur.details.vendeur.condition_retour | titlecase}}
                      </p>
                    </div>
                    <div>
                      <p class="font-weight-bold" style="font-size: 13px;">Déscription de la société</p>
                      <p *ngIf="product.vendeur.details" style="white-space: pre-line;">
                        {{product.vendeur.details.boutique.description | titlecase}}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </mat-expansion-panel>
            <mat-expansion-panel [expanded]="step === 5" (opened)="setStep(5)">
              <mat-expansion-panel-header>
                <p class="my-auto PoppinsMedium">Nous contacter</p>
              </mat-expansion-panel-header>
              <div class="text-center">
                <div style="font-size: 13px; font-family: 'Poppins-Bold';">Une question sur un produit, une commande ou tout simplement envie de connaitre <br> notre fonctionnement  ? </div>
                <div class="my-3" style="font-family: 'Poppins-Regular';">
                  Notre service client est rapide et aux petits soins pour vous !
Vous pouvez nous contacter par tchat en vous connectant et en cliquant sur :
                </div>
                <div>
                  <a href="#" (click)="openModalContact($event)" style="font-size: 14px; font-family: 'Poppins-Bold';text-decoration: underline;">Contactez-nous</a>
                </div>
                <div class="my-2" style="font-family: 'Poppins-Regular';">
                  Ou par mail :
                </div>
                <div><a href="mailto:service.client@hadeen-place.fr" style="font-size: 14px; font-family: 'Poppins-Bold';text-decoration: underline;">service.client@hadeen-place.fr</a></div>
                <div class="my-2" style="font-family: 'Poppins-Regular';">
                  Ou encore via Whatsapp, nous sommes disponibles du
 Lundi au Samedi de 9h à 18h :
                </div>
                <div> <a href="https://wa.me/33613830193" target="_blank" style="font-size: 14px; font-family: 'Poppins-Bold';text-decoration: underline;">whatsapp pro</a></div>
              </div>
                  <!-- <div *ngIf="commonService.user">
                    <mat-form-field class="w-100 hadeenInput">
                      <mat-label>Entrer votre message</mat-label>
                      <textarea matInput cdkTextareaAutosize cdkAutosizeMinRows="3"
                        [(ngModel)]="messageForSeller"></textarea>
                    </mat-form-field>
                    <div class="text-right">
                      <button type="button" mat-flat-button (click)="sendMessage();"
                        style="background-color: #42594F; border: none; color: #ffffff; margin-top: 7px; border-radius: 0em;">
                        Envoyer
                      </button>
                    </div>
                  </div> -->
            </mat-expansion-panel>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="d-none" *ngIf="product" style="font-size: 0px !important;">
  <div *ngFor="let itemCaracs of product.caracs">
    <h2 style="font-size: 0px !important;">{{itemCaracs.caracteristique.nom}}</h2>
    <h3 style="font-size: 0px !important;" *ngIf="(itemCaracs.valeurs && itemCaracs.valeurs.length) || itemCaracs.valeur">
      <span *ngIf="itemCaracs.valeurs && itemCaracs.valeurs.length else elseBlock">
        <span *ngFor="let val of itemCaracs.valeurs; let i=index"><span *ngIf="i > 0">, </span>{{val.valeur | lowercase}}</span>
      </span>
      <ng-template #elseBlock>
        {{itemCaracs.valeur}}
      </ng-template>
      <!-- <span *ngFor="let val of itemCaracs.valeurs; let i=index"><span *ngIf="i > 0">, </span>{{val.valeur | lowercase}}</span>
      <span *ngFor="let val of itemCaracs.unites; let i=index"><span *ngIf="i > 0">, </span>{{val.valeur | lowercase}}</span> -->
    </h3>
  </div>
</div>

<div class="detail_product" *ngIf="false" style="padding-bottom: 2%; margin-top: -1%;">
  <mat-tab-group mat-align-tabs="center" *ngIf="product" [selectedIndex]="selectedMatTab.value"
    (selectedIndexChange)="selectedMatTab.setValue($event)">
    <mat-tab label="Descriptif">
      <div class="container">
        <div class="descriptif">
          <p style="font-size: 28px; text-align: center; text-transform: uppercase; font-family: 'DIN Bold'; margin: 3% 0;">DESCRIPTIF</p>
          <p class="text-left" style="white-space: pre-line; font-size: 16px;" [innerHTML]="product.descriptionTag"></p>
        </div>
      </div>
    </mat-tab>
    <mat-tab label="Caractéristiques">
      <div class="container">
        <p style="font-size: 28px; text-align: center; text-transform: uppercase; font-family: 'DIN Bold'; margin: 3% 0;">CARACTÉRISTIQUES</p>
        <table class="m-auto">
          <tr *ngFor="let itemCaracs of product.caracs">
            <td class="pl-3" style="min-width: 175px;" *ngIf="itemCaracs.valeurs || itemCaracs.unites[0]">
              <h2 class="text-left" style="font-family: 'SFCompactDisplay-Regular'; font-size: 16px; font-style: none; text-transform: none;">{{itemCaracs.caracteristique.nom }}</h2>
            </td>
            <td style="display: -webkit-box; flex-wrap: wrap; border: none !important;">
              <ng-container *ngIf="itemCaracs.valeurs.length>0; else elseTemplate">
                <h3 class="my-2" style="font-size: 16px; margin: 0; width: fit-content !important;" *ngFor="let val of itemCaracs.valeurs;let i=index">
                  {{val.valeur | lowercase}}<i *ngIf="i<(itemCaracs.valeurs.length-1)">, &nbsp;</i>
                </h3>
                <h3 class="mb-3" style="font-size: 16px; margin: 0; width: fit-content !important;" *ngFor="let u of itemCaracs.unites">{{u.unite}}</h3>
              </ng-container>
              <ng-template #elseTemplate>
                <h3 class="my-2" style="font-family: 'SFCompactDisplay-Regular'; font-size: 16px;" *ngIf="itemCaracs.valeur">
                  {{ removeLastComma(itemCaracs.valeur) }}
                  <ng-container *ngIf="itemCaracs.unites.length>0; else elseTemplate">
                    <span style="font-size: 16px; margin: 0" *ngFor="let u of itemCaracs.unites">{{u.unite}}</span>
                  </ng-container>
                  <ng-template #elseTemplate>
                    <span style="font-size: 16px; margin: 0" *ngIf="itemCaracs.caracteristique.unite">{{itemCaracs.unite}}</span>
                  </ng-template>
                </h3>
              </ng-template>
            </td>
            <!-- <ng-container class="row" *ngIf="itemCaracs.valeurs.length>0; else elseTemplate">
              <td class="row" style="border: none !important">
                <h3 class="col-4 my-2 w-auto" style="font-size: 16px; margin: 0;" *ngFor="let val of itemCaracs.valeurs;let i=index">
                  {{val.valeur | lowercase}}<i *ngIf="i<(itemCaracs.valeurs.length-1)">, </i>
                </h3>
                <h3 class="col-4 mb-3 w-auto" style="font-size: 16px; margin: 0;" *ngFor="let u of itemCaracs.unites">{{u.unite | lowercase}}</h3>
              </td>
            </ng-container>
            <ng-template #elseTemplate>
              <td>
                <h3 class="my-2" style="font-family: 'SFCompactDisplay-Regular'; font-size: 16px;" *ngIf="itemCaracs.valeur">
                  {{ removeLastComma(itemCaracs.valeur) }}
                  <ng-container *ngIf="itemCaracs.unites.length>0; else elseTemplate">
                    <span style="font-size: 16px; margin: 0" *ngFor="let u of itemCaracs.unites">{{u.unite}}</span>
                  </ng-container>
                  <ng-template #elseTemplate>
                    <span style="font-size: 16px; margin: 0" *ngIf="itemCaracs.caracteristique.unite">{{itemCaracs.unite}}</span>
                  </ng-template>
                </h3>
              </td>
            </ng-template> -->
          </tr>
        </table>
      </div>
    </mat-tab>
    <mat-tab label="Avis" *ngIf="avis.length">
      <div class="container" id="avis">
        <h2 style="font-size: 28px; text-align: center; text-transform: uppercase; font-family: 'DIN Bold'; margin: 3% 0;">AVIS <span style="font-size: 0px;">{{product.code}}</span></h2>
        <div class="row">
          <div class="col-md-6 col-lg-6 col-12" *ngFor="let a of avis">
            <div class="text-center avis" style="border: 1px solid;padding: 4%;margin-bottom: 5%;">
              <div>
                <ngb-rating [(rate)]="a.star">
                  <ng-template let-fill="fill" let-index="index">
                    <span class="star" [class.full]="fill === 100">
                      <span class="half" [style.width.%]="fill">&#9733;</span>&#9733;
                    </span>
                  </ng-template>
                </ngb-rating>
                <label> {{a.star}} / 5</label>
              </div>
              <p>{{a.commentaire}}</p>
              <p *ngIf="a.acheteur.pseudo">{{a.acheteur.pseudo | titlecase }} , <span>{{a.created_at|date}}</span></p>
            </div>
          </div>
        </div>
      </div>
    </mat-tab>
    <mat-tab label="Le vendeur" *ngIf="product.vendeur" style="border:solid;">
      <div class="container-fluid">
        <h2>Le vendeur</h2>
        <div class="vendeur" style="padding: 2%;">
          <div class="row" *ngIf="product.vendeur.details">
            <div class="col-md-3 col-lg-3 col-sm-3">
              <img width="auto" height="auto" class="rounded-circled" [src]="product.vendeur.details.boutique.logo" alt="..."
                style="border-radius: 50%; cursor: pointer; width: 95px; height: 94px;">
              <button class="boutique"
                [routerLink]="['/detail-vendeur/' + formatUrl(product.vendeur.pseudo) + '-' + product.vendeur.id ]">BOUTIQUE</button>
            </div>
            <div class="col-9">
              <div class="row">
                <div class="col-md-8 col-lg-8 col-sm-12">
                  <h2>{{ product.vendeur.details.boutique.nom }}</h2>
                  <p *ngIf="product.vendeur.details.vendeur.adresse_societe" style="color: #666666;">{{ removeLastComma(product.vendeur.details.vendeur.adresse_societe) }}</p>
                  <p style="font-size: 13px;">{{ product.vendeur.details.vente }} produits en vente</p>
                </div>
                <div class="col-md-4 col-lg-4 col-sm-12">
                  <div *ngIf="commonService.user">
                    <ng-template #popTemplate class="text-right">
                      <p class="text-left" style="font-size: 20px; font-weight: 500;">Contacter le vendeur</p>
                      <mat-form-field class="w-100 hadeenInput">
                        <mat-label>Entrer votre message</mat-label>
                        <textarea matInput cdkTextareaAutosize cdkAutosizeMinRows="3"
                          [(ngModel)]="messageForSeller"></textarea>
                      </mat-form-field>
                      <div class="text-right">
                        <button type="button" mat-flat-button (click)="sendMessage(); pop.hide()"
                          style="background-color: #345b2b; border: none; color: #ffffff; margin-top: 7px; border-radius: 0em;">
                          Envoyer
                        </button>
                      </div>
                    </ng-template>
                    <button class="contact" [popover]="popTemplate" [placement]="'right'" [adaptivePosition]="false" #pop="bs-popover">Contacter</button>
                  </div>
                  <button *ngIf="!commonService.user" class="contact" (click)="openLogin()">Contacter</button>
                </div>
                <div class="col-12" *ngIf="product.vendeur.details.avis.length">
                  <ngb-rating [(rate)]="note" class="aaa">
                    <ng-template let-fill="fill" let-index="index">
                      <span class="star" [class.full]="fill === 100">
                        <span class="half" [style.width.%]="fill">&#9733;</span>&#9733;
                      </span>
                    </ng-template>
                  </ngb-rating>
                  <label style="font-size: 13px;"> {{note}} / 5 | <span style="text-decoration: underline;"
                      [routerLink]="['/detail-vendeur/' + formatUrl(product.vendeur.pseudo) + '-' + product.vendeur.id ]"
                      *ngIf="product.vendeur.details">
                      Voir tous les avis({{product.vendeur.details.avis.length}})</span></label>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="container">
          <div class="row mt-5">
            <div class="col-md-6 col-lg-6 col-12">
              <div>
                <h4 class="font-weight-bold">Conditions de ventes</h4>
                <p *ngIf="product.vendeur.details">
                  {{ product.vendeur.details.vendeur.condition_retour }}
                </p>
              </div>
            </div>
            <div class="col-md-6 col-lg-6 col-12">
              <div>
                <h4 class="font-weight-bold">Déscription de la société</h4>
                <p *ngIf="product.vendeur.details">
                  {{ product.vendeur.details.boutique.description }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </mat-tab>
  </mat-tab-group>
</div>

<div *ngIf="productSuggestion && productSuggestion.length">
  <h2 class="head-section">NOTRE BRIGADE VOUS CONSEILLE</h2>
  <!-- <div class="row" *ngIf="productSuggestion && productSuggestion.length">
    <div class="col-md-3 col-lg-3 col-6" *ngFor="let product of productSuggestion">
      <app-produit-input [data]="product" [parametres]="{class: 'normal'}"></app-produit-input>
    </div>
  </div> -->
  <app-produit-list [screenWidth]="screenWidth" [data]="productSuggestion" arraySplit="no" [idSlide]="'vf'" [login]="loginTemplate2"></app-produit-list>
</div>
<div class="container mt-4 mb-5">
  <div id="stat">
    <div class="row">
      <div class="col-md-3 col-6 text-center h-100 border-r">
        <div class="nbr">250</div>
        <div class="txt-desc">Marques sélectionnées <br> et triées</div>
      </div>
      <div class="col-md-3 col-6 text-center h-100 border-md-r">
        <div class="nbr">3,5K</div>
        <div class="txt-desc">Articles éco-conçus <br>de qualité et tendance</div>
      </div>
      <div class="mt-4 mt-md-0 col-md-3 col-6 text-center h-100 border-r">
        <div class="nbr">4,6/5</div>
        <div class="txt-desc">d'indice confiance <br>Truspilot et Google</div>
      </div>
      <div class="mt-4 mt-md-0 col-md-3 col-6 text-center h-100">
        <div class="nbr">100%</div>
        <div class="txt-desc">Français, engagés <br>et stylés</div>
      </div>
    </div>
  </div>
</div>

<div class="d-md-none">
  <button *ngIf="statutSousProd < 1 || quantiteV2 < 1 || product.statut !=='EN_LIGNE'"
    class="h-btn-dore PoppinsRegular" (click)="openModalContactDisponibilite()" style="height: 50px; left: 5%; width: 90%; font-size: 15px; position: fixed; bottom: 5px; z-index: 1; border-radius: 0 !important;">
    DEMANDER LA DISPONIBILITÉ
    <!-- (<span style="text-decoration: line-through; margin-right: 10px;" *ngIf="product?.prix_promotion > 0">{{
      product.prix | number : '1.2-2' }} €</span>
    <span style="color: #FFF; font-family: 'SFCOmpact Semibold';"
      *ngIf="product?.prix_promotion > 0">{{ product?.prix_promotion | number : '1.2-2' }} €</span>
    <span style="color: #FFF; font-family: 'SFCOmpact Semibold';"
      *ngIf="!product?.prix_promotion || product?.prix_promotion === 0">{{ product?.prix | number : '1.2-2' }}
      €</span>) -->
  </button>
  <button class="h-btn-dore"
    *ngIf="statutSousProd > 0 && quantiteV2 > 0 && product.statut !== 'ARCHIVE' && product.statut !== 'EN_CONGE'"
    [ngClass]="sousProdSelected && quantiteV2 > 0? 'h-btn-dore': 'chartDisabled disabled'"
    style="height: 50px; left: 5%; width: 90%!important; font-size: 15px; position: fixed; bottom: 5px; z-index: 3; border-radius: 0 !important; font-family: 'SFCompactDisplay-Regular';" [disabled]="!sousProdSelected || quantiteV2 < 1 ? true : false"
    (click)="ajoutPanierV2()">

    AJOUTER AU PANIER &nbsp;&nbsp;
    <span style="text-decoration: line-through; margin-right: 10px;font-size: 12px;" *ngIf="product?.prix_promotion > 0">({{
      product.prix | number : '1.2-2' }} €)</span>
    <span style="color: #FFF;">{{ prixFinal | number : '1.2-2'}} €</span>
  </button>
</div>

<ng-template #vendeur style="border-radius: 10px;">
  <div class="modal-header pl-3">
    <h4 class="m-0" style="font-size: 26px;">Contacter le Vendeur</h4>
  </div>
  <div class="modal-body pt-0" style="font-family: SFCompactDisplay-Medium;">
    <textarea name="message" style="width: 100%;font-family: 'SFCompactDisplay-Regular'; border-color: #d9d9d9;"
      rows="6"></textarea>
    <button class="send mb-0 mt-3 pl-3 pr-3" style="width: max-content;">ENVOYER</button>
  </div>
</ng-template>

<ng-template #loginTemplate>
  <app-login [modal]="modalOpen.modalRef2" [newsCustomer]="true" title="Se connecter avec votre compte pour nous envoyer un message."></app-login>
</ng-template>

<ng-template #templateResultList>
  <div class="modal-header">
    <h4 class="modal-title pull-left">Partagez votre liste</h4>
    <button type="button" class="close pull-right" aria-label="Close"
      style="color: #000000; width: fit-content; margin-right: 0px; font-size: 22px;"
      (click)="modalRefListValid.hide()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <p *ngFor="let item of achatsListe">Code {{ item.code }} - {{ item.event }}: {{ item.title }}</p>
    <p>Vous pouvez partager votre code sur les reseaux sociaux pour augmenter la visibilité de votre liste</p>
    <textarea class="form-control noOutline" (input)="onchangeInput()" id="exampleFormControlTextarea1" rows="9"
      [(ngModel)]="message2" style="font-size: 12px;"></textarea>
    <p>
      <button mat-flat-button (click)="copierMessage()" class="h-btn-green p-0" style="font-size: 13px; width: fit-content;">Copier</button>
    </p>

    <div class="image-card w-100 text-center" style="display: inline-block;">
      <span>Partager sur: </span>
      <a href="https://api.whatsapp.com/send?text={{message}}" target="_blank"
        style="margin-left: 9px; background-color: #345B2B; padding: 8px 7px 4px 7px; border-radius: 50%;">
        <i class="fab fa-whatsapp" style="color: white; font-size: 21px;"></i>
      </a>
      <a href="mailto:?subject=Invitation&body={{message}}"
        style="margin-left: 9px; background-color: #345B2B; padding: 8px 5px 4px 5px; border-radius: 50%;">
        <i class="fas fa-at" style="color: white; font-size: 21px;"></i>
      </a>
      <a href="https://www.instagram.com/?url=https%3A//hadeen.vatilab.com%2Fcode-achat%2F{{message}}" target="_blank"
        style="margin-left: 9px; background-color: #345B2B; padding: 8px 6px 4px 6px; border-radius: 50%;">
        <i class="fab fa-instagram" style="color: white; font-size: 21px;"></i>
      </a>
      <a href="http://pinterest.com/pin/create/button/?url=http%3A%2F%2Fwww.aliasdmc.com%2Fdeveloppement%2Fwebmaster%2Ffaire_un_lien_de_partage_sur_les_reseaux_sociaux.php&media=http%3A%2F%2Fwww.aliasdmc.com%2Fimages%2Fthumbnail.jpg"
        target="_blank"
        style="margin-left: 9px; background-color: #345B2B; padding: 8px 8px 3px 8px; border-radius: 50%;">
        <i class="fab fa-pinterest-p" style="color: white; font-size: 21px;"></i>
      </a>
      <a href="sms:&body=Bonjour, merci de consulter mon liste d'achat, rendez-vous sur https://hadeenplace.fr"
        target="_blank"
        style="margin-left: 9px; background-color: #345B2B; padding: 8px 5px 3px 5px; border-radius: 50%;">
        <i class="fa fa-sms" style="color: white; font-size: 21px;"></i>
      </a>
    </div>
  </div>
</ng-template>

<!-- modal pour contacter le moderateur -->
<div class="modal fade" id="modalDisponibilite" tabindex="-1" role="dialog" aria-labelledby="modalLabel"
  aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content" style="border-radius: 8px;">
      <div class="modal-header pb-0">
        <div class="col-12" *ngIf="product && product.vendeur.details">
          <p style="font-size: 15px;">À : {{ product.vendeur.details.boutique.nom }}</p>
        </div>
      </div>
      <div class="modal-body">
        <div class="col-12">
          <p style="font-size: 15px;" class="m-0">Demande de disponibilité pour:</p>
          <p class="mb-1 ml-3" *ngIf="product">- {{ product.model }} {{ product.id.produitId }}</p>
        </div>
        <div class="col-sm-12">
          <textarea class="form-control col-sm-12 area" rows="5" placeholder="Ecrire le message ici..."
            [(ngModel)]="messageForSeller"></textarea>
          <button class="h-btn-black mt-2 float-right" style="width: fit-content; padding: 5px 15px;"
            data-dismiss="modal" (click)="sendMessage()">Envoyer</button>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="modal fade" id="modalContactModerateur2" tabindex="-1" role="dialog" aria-labelledby="modalLabel"
  aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content" style="border-radius: 8px;">

      <div class="modal-header pb-0 row">
        <div class="col-12" *ngIf="product && product.vendeur.details">
          <p style="font-size: 15px;">À : {{ product.vendeur.details.boutique.nom }}</p>
        </div>
        <div class="col-12">
          <p style="font-size: 15px;" class="m-0">Produit: </p>
          <p class="mb-1 ml-3" *ngIf="product">- {{ product.model }} {{ product.id.produitId }}</p>
        </div>
      </div>
      <div class="modal-body">
        <div class="col-sm-12">
          <textarea class="form-control col-sm-12 area" rows="5" placeholder="Ecrire le message ici..."
            [(ngModel)]="messageText"></textarea>
          <button type="button" mat-flat-button (click)="sendMessage();"
                        style="background-color: #42594F; border: none; color: #ffffff; margin-top: 7px; border-radius: 0em;">
                        Envoyer
                      </button>
        </div>
      </div>

    </div>
  </div>
</div>

<ng-template #template>
  <div>
    <div class="modal-header text-center">
        <button type="button" class="close pull-right" style="padding: 16px 1rem;font-size: 25px;color: #42594F;width: 60px;opacity: 1"
          (click)="closeModal()">
          <span aria-hidden="true" style="box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.3);border-radius: 50%;padding: 0px 9px 4px 9px;">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="row">
            <div class="col-md-9 pl-0 pr-0 pr-md-4 img-prod">
              <div id="carouselExampleControlss" class="carousel slide" data-ride="carousel">
                <div class="carousel-inner">
                  <ol class="carousel-indicators my-0">
                    <li data-target="#carouselExampleControlss" *ngFor="let item of imgListeV2; let ind=index" [attr.data-slide-to]="ind" [ngClass]="{'active': ind==indexCarouselModal}"></li>
                  </ol>
                  <div class="carousel-item" *ngFor="let item of imgListeV2; let ind=index"
                    [ngClass]="{active: ind === indexCarouselModal}" style="background-color: transparent !important;">
                    <img width="auto" height="auto" class="d-block w-100 newImgProduct" [src]="commonService.getImageFormated(0, 0, item.nom)" style="object-fit: contain;height: 100%!important;" alt="...">
                  </div>
                </div>
              </div>
            </div>
          </div>
      </div>
  </div>
</ng-template>
<!-- end modal pour contacter le moderateur -->

<ng-template adHost></ng-template>

<!-- <img src="../../../../assets/images/Bandeau page détail version 2a.png" alt="" srcset="" class="d-none d-md-block" style="margin-bottom: 35px;"> -->
