<div class="containt">
  <span class="love" style="z-index: 2;" (click)="likeProduct()">
    <img width="auto" height="auto" *ngIf="!data.is_liked" loading="lazy" src="assets/images/heart.svg" class="fo" alt="heart" title="heart"
      style="width: 18px; height: 19px;">
    <img width="auto" height="auto" *ngIf="data.is_liked" loading="lazy" src="assets/images/red--heart.svg" class="fo" alt="heart" title="heart"
      style="width: 18px; height: 19px;">
  </span>
  <div [routerLink]="['/detail-produit/' + data.slug]" style="cursor: pointer;">
    <ng-container>
      <a>
        <img width="auto" height="auto" loading="lazy" *ngIf="data.image" [src]="commonService.getImageFormated(500, 600, data.image)" [alt]="data.code || data.model" [title]="data.code || data.model" class="img-product">
        <img width="auto" height="auto" loading="lazy" *ngIf="!data.image" src="assets/static/list2.png" alt="hadeen place" title="hadeen place" class="img-product">
        <div class="top-text {{data.class}} {{class}}" *ngIf="data.titre">
          <h2 style="overflow-wrap: anywhere;">{{data.titre}}</h2>
        </div>
      </a>
    </ng-container>
    <div class="text-center" style="padding-top: 5px;">
      <h2 class="PoppinsMedium mb-0" style="overflow-y: hidden;height: 40px;overflow-wrap: anywhere; font-size: 14px; font-weight: 500; line-height: 1.4;color: #1A1A1A;">{{ showTitle((data.code ? data.code : data.model) | uppercase) }}</h2>
      <!--<p class="mt-0" *ngIf="data.prix_promotion && data.prix_promotion > 0" style="font-size: 17px; color: #345B2B; font-weight: 600; font-family: 'SFCompactDisplay-Medium'">{{ data.prix_promotion | number : '1.2-2'}} €</p>
      <p class="mt-0" *ngIf="!data.prix_promotion || data.prix_promotion === 0" style="font-size: 17px; color: #345B2B; font-weight: 600; font-family: 'SFCompactDisplay-Medium'">{{ data.prix | number : '1.2-2'}} €</p>-->
      <!-- <button style="height: 30px;margin-top: 5px;" class="PoppinsMedium mt-0 w-100 rounded-0 btn cta" *ngIf="data.prix_promotion && data.prix_promotion > 0">AJOUTER - {{ data.prix_promotion | number : '1.2-2'}} €</button>
      <button style="height: 30px;margin-top: 5px;" class="PoppinsMedium mt-0 w-100 rounded-0 btn cta" *ngIf="!data.prix_promotion || data.prix_promotion === 0">AJOUTER - {{ data.prix | number : '1.2-2'}} €</button> -->
      <button style="height: 30px;margin-top: 5px;" class="PoppinsMedium mt-0 w-100 rounded-0 btn cta">AJOUTER - {{ this.commonService.calculPrix(data, 1) | number : '1.2-2'}} €</button>
    </div>
  </div>
</div>

<ng-template #loginTemplate>
  <app-login [modal]="modalOpen.modalRef2" [newsCustomer]="true"></app-login>
</ng-template>
