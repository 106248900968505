<div class="spinner" style="position: fixed; top: 30%; left: 45%; z-index: 1;" *ngIf="showSpinner">
    <mat-spinner></mat-spinner>
</div>

<div class="row">

    <div class="col-12 col-md-12">
        <div class="row">
            <div class="col-12 mb-0">
                <div class="header">
                    <ul class="breadcrumb mb-0">
                        <li [routerLink]="['/compte']"><a>Compte</a></li>
                        <li class="active"><a>Consulter les listes d'achats</a></li>
                    </ul>
                </div>
            </div>

            <div class="col-12 mb-4 text-right">
                <mat-form-field appearance="legacy">
                    <mat-label>Insérez le code de la liste</mat-label>
                    <input matInput placeholder="Insérez le code de la liste" [(ngModel)]="codeList">
                    <mat-icon *ngIf="!dataList" matSuffix (click)="getListeAchatByCode(codeList)" style="cursor: pointer;">search</mat-icon>
                    <button class="h-btn-green-bordered" style="padding: 7px 15px; font-family: 'SFCOmpact Semibold'" *ngIf="codeList && dataList" matSuffix (click)="deleteListeAchatActive()">modifier</button>
                </mat-form-field>
            </div>

            <div class="col-12 mb-4" *ngIf="dataList">
                <p>Liste d'achats de: {{ dataList.achat.user.pseudo }}</p>
                <div style="padding: 5px; border: 1px solid #D6D6D6; border-bottom: none; cursor: pointer;">
                    <div class="col-12 col-md-10 offset-md-1">
                        <div class="col text-center" style="font-size: 16px">{{ dataList.achat.event }}: {{ dataList.achat.title }}</div>
                    </div>
                </div>

                <div class="mb-3" style="padding: 5px; border: 1px solid #D6D6D6; cursor: pointer;">
                    <div class="row pl-3 pr-3" *ngIf="dataList.sous_produits">
                        <div class="col-6 col-md-4 p-1 text-center" *ngFor="let itemProduct of dataList.sous_produits" style="margin-bottom: 25px;" [routerLink]="'/achat/detail-produit/' + itemProduct.produit.id.produitId">
                            <img width="auto" height="auto" class="produit__img" [src]="itemProduct.images[0].nom" alt="">
                            <p class="mb-0 text-center" style="font-size: 12px;">{{ itemProduct.produit.model }}</p>
                            <p class="mb-0 text-center" style="font-size: 12px;" *ngIf="itemProduct.produit.prix_promotion > 0 ">
                                {{ itemProduct.produit.prix_promotion | number : '1.2-2' }} €
                            </p>
                            <p class="mb-0 text-center" style="font-size: 12px;" *ngIf="!itemProduct.produit.prix_promotion || itemProduct.produit.prix_promotion === 0 ">
                                {{ itemProduct.produit.prix | number : '1.2-2' }} €
                            </p>

                            <p class="mb-0 text-center" style="font-size: 12px;">Quantité: {{ itemProduct.disponibilite_liste_achat }}</p>
                            <p class="mb-0 text-center" style="font-size: 12px;">(Disponibilité: {{ itemProduct.disponibilite }})
                            </p>

                        </div>

                        <h3 class="col-12 text-center" *ngIf="!dataList.sous_produits.length">
                            Cette liste d'achat ne contient aucun produit
                        </h3>
                    </div>
                </div>
            </div>

            <div class="col-12 mb-4" *ngIf="!dataList">
                <h2 class="text-center">Merci d’insérer le code que vous avez reçu pour afficher les produits de la liste</h2>
            </div>


        </div>
    </div>
</div>
