import { CommonService } from 'src/app/services/common.service';
import { ProductService } from 'src/app/services/product.service';
import { ToastrService } from 'ngx-toastr';
import { ChatService } from './../../../services/chat.service';
import { Component, OnInit, Input, ViewChild, ElementRef } from '@angular/core';
import { StorageService } from 'src/app/services/storage.service';
import { ModalOpenService } from 'src/app/services/modal-open.service';
declare var fbq;

@Component({
  selector: 'app-produit-input',
  templateUrl: './produit-input.component.html',
  styleUrls: ['./produit-input.component.scss']
})
export class ProduitInputComponent implements OnInit {
  @ViewChild('loginTemplate') loginTemplate2: ElementRef;
  @Input() data: any;
  @Input() parametres: any;
  @Input() loginTemplate: any;
  class: string;

  constructor(
    private chatService: ChatService, private toast: ToastrService, private productService: ProductService,
    private storageService: StorageService, public modalOpen: ModalOpenService, public commonService: CommonService) { }

  ngOnInit(): void {
    if (this.parametres === undefined) {
      this.class = '';
    } else {
      this.class = 'normal';
    }
    if (this.data.titre === undefined) {
      this.data.class = '';
    } else if (this.data.titre.includes('vente')) {
      this.data.class = 'green';
    } else if (this.data.titre.includes('EDITION')) {
      this.data.class = 'rose';
    }
  }

  likeProduct(): void {
    this.likeProductNew();
    
  }

  likeProductLast() {
    const user = JSON.parse(this.storageService.getItem('User'));
    if (user) {
      let path = '';
      const isLiked = this.data.is_liked;
      if (isLiked) {
        path = '/app/unlike';
      } else {
        path = '/app/like';
      }
      const data = {
        produit: this.data.id.produitId
      };
      this.chatService.likeProd(path, data);
      this.data.is_liked = !this.data.is_liked;
    } else {
      this.toast.error('Vous devriez se connecter pour pouvoir faire cette action', '', {
        timeOut: 3000
      });
      this.chatService.loginModal(this.loginTemplate);
    }
  }

  likeProductNew() {
    const user = JSON.parse(localStorage.getItem('User'));
    if (user) {
      let path = '';
      const isLiked = this.data.is_liked;
      if (isLiked) {
        path = '/app/unlike';
      } else {
        path = '/app/like';
        
        // const priceProduct = this.data.prix_promotion && this.data.prix_promotion > 0
        //   ? this.data.prix_promotion
        //   : this.data.prix;
        // fbq('track', 'AddToWishlist', {
        //   content_ids: [this.data.id.produitId],
        //   currency: "EUR", value: priceProduct
        // });
      }
      const data = {
        produit: this.data.id.produitId
      };
      this.modalOpen.likeProd(path, data);
      this.data.is_liked = !this.data.is_liked;
    } else {
      this.modalOpen.loginModal(this.loginTemplate2);
    }
  }

  generateUrl(item) {
    return item.trim().split(' ').join('_');
  }

  showTitle(title: string){
    let word = title.split(" ");
    let show = "";
    if(word.length==1){
      return title.substring(0, 42)
    }else{
      for (let i = 0; i < word.length; i++) {
        if ((show + word[i]).length <= 42) {
          show += word[i] + " ";
        }else{
          break;
        }
      }
      let lastChar = show.trim().slice(-1);
      return (lastChar === "-" || lastChar === "," || lastChar === ";") ? show.trim().slice(0, -1) : show;
    }        
  }
}
