import { environment } from './../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { Component, ElementRef, Inject, Input, OnInit, PLATFORM_ID, TemplateRef, ViewChild } from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { AuthServices } from '../../services/auth.service';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import User from '../../interfaces/user';
import { AuthService } from 'angularx-social-login';
import { FacebookLoginProvider, GoogleLoginProvider } from 'angularx-social-login';
import { ProductService } from 'src/app/services/product.service';
import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { Router } from '@angular/router';
import { MatDrawer } from '@angular/material/sidenav';
import { MenuListService } from 'src/app/services/menu-list.service';
import { CommonService } from 'src/app/services/common.service';
import { WebSocketAPI } from 'src/app/services/WebSocketAPI';
import { isPlatformBrowser } from '@angular/common';
import { StorageService } from 'src/app/services/storage.service';
import { ModalOpenService } from 'src/app/services/modal-open.service';
import { MatDialog } from '@angular/material/dialog';

declare var pintrk;

@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.scss"],
})
export class LoginComponent implements OnInit {
  @Input() modal: BsModalRef;
  @Input() newsCustomer;
  @Input() title = 'Se connecter avec votre compte';
  @ViewChild("loginTemplate") loginTemplate: ElementRef;
  isCollapse = true;
  menuList: any;
  menuWS = [];
  modalRef2: BsModalRef;
  modalMobilMenu: BsModalRef;
  modalCategorie: BsModalRef;
  name = "";
  signe = "password";
  faLogin = "fa-eye";
  example = "";
  accountClass = "";
  newClass = "activModal";
  MselectSexe = "selectedSexe";
  FselectSexe = "unselectedSexe";
  votreEmail;
  mdp;
  sexe = "HOMME";
  formRegister: FormGroup;
  newsL = true;
  currentUserSubject: BehaviorSubject<any>;
  currentUser: any;
  sousMenuWS: any;
  sousMenuWS2: any;
  sousMenuBool = false;
  menuHover = false;
  menuMobil = true;
  titreMobile: string;
  codeCategorie: any;
  activeMenu = "";
  activeBorder = "";
  selectedIdx: number;
  sousUniverMenuWS: any[];
  sousMenuSUniv: any;
  titreSousUniv: any;
  titreUniver: any;
  drawer: MatDrawer;
  public menuSubject: Observable<any> = of([]);
  public nameSubject: Observable<any> = of([]);
  showSpinner = false;
  requestProcess = false;

  webSocketAPI: WebSocketAPI;

  constructor(
    @Inject(PLATFORM_ID) private platformId,
    private chatService?: ModalOpenService,
    private modalService?: BsModalService,
    private formBuilder?: FormBuilder,
    private auth?: AuthServices,
    private toastr?: ToastrService,
    private authService?: AuthService,
    public commonService?: CommonService,
    private productS?: ProductService,
    private router?: Router,
    public dialog?: MatDialog,
    public breakpointObserver?: BreakpointObserver,
    private MenuListServ?: MenuListService,
    private storageService?: StorageService,
    private httpClient?: HttpClient
  ) {
    this.createformRegister();
    this.getMenuWS();
    commonService.getPanier();
    this.nameSubject = this.MenuListServ.getName();
    this.nameSubject.subscribe((data: any) => {
      this.name = data;
    });
    this.menuSubject = this.MenuListServ.getMenu();
    this.menuSubject.subscribe((data: any) => {
      this.menuList = data;
    });
    // this.currentUserSubject = new BehaviorSubject<any>(localStorage.getItem('Token'));
    this.currentUserSubject = new BehaviorSubject<any>(
      storageService.getItem("Token")
    );
    if (this.currentUserSubject.getValue() !== null) {
      // const a = JSON.parse(localStorage.getItem('User'));
      const a = JSON.parse(storageService.getItem("User"));
      if (a.etapeVendeur === 3) {
        // tslint:disable-next-line: max-line-length
        // this.MenuListServ.setMenu(['Ajouter un produit', 'Votre compte', 'Vos commandes', 'Votre liste d’envies', 'Vos messages', 'Se déconnecter']);
        this.MenuListServ.setMenuList();
      } else {
        // tslint:disable-next-line: max-line-length
        // this.MenuListServ.setMenu(['Devenir Vendeur', 'Votre compte', 'Vos commandes', 'Votre liste d’envies', 'Vos messages', 'Se déconnecter']);
        this.MenuListServ.setMenuList();
      }
      this.name = a.prenom;
    } else {
      // this.MenuListServ.setMenu(['Je suis nouveau client', 'J\'ai déjà un compte', 'Devenir Vendeur']);
      this.MenuListServ.setMenuList();
      this.name = ", Identifiez-vous";
    }
    this.currentUser = this.currentUserSubject.asObservable();

    this.newsCustomer = this.MenuListServ.isNewCustomer;
    if (this.newsCustomer) {
      this.accountClass = "activModal";
      this.newClass = "";
    } else {
      this.newClass = "activModal";
      this.accountClass = "";
    }
  }
  
  public get currentUserValue(): User {
    return this.currentUserSubject.value;
  }
  createformRegister() {
    this.formRegister = this.formBuilder.group({
      nom: ["", Validators.required],
      prenom: ["", Validators.required],
      email: ["", Validators.compose([Validators.required, this.checkMail])],
      password: ["", Validators.compose([Validators.required, this.checkpw])],
    });
  }

  checkMail(controls) {
    // const regExp = new RegExp(/^[a-z0-9_.+-]+@[a-z0-9-]+.[a-z0-9-.]+$/);
    const regExp = new RegExp(/\S+@\S+\.\S+/);
    if (regExp.test(controls.value)) {
      return null;
    } else {
      return { checkMail: true };
    }
  }
  createsignInWithGoogle(): void {
    this.authService
      .signIn(GoogleLoginProvider.PROVIDER_ID)
      .then((googleUSer) => {
        this.createAccountGoogle(
          googleUSer.lastName,
          googleUSer.firstName,
          googleUSer.email,
          googleUSer.id,
          "HOMME"
        );
      });
  }
  signInWithGoogle() {
    this.authService
      .signIn(GoogleLoginProvider.PROVIDER_ID)
      .then((googleUSer) => {
        this.signGoogle(googleUSer.email, googleUSer.id);
      });
  }
  signGoogle(email, pw) {
    const dataGOogle = new FormData();
    dataGOogle.set("email", email.trim());
    dataGOogle.set("password", pw);
    dataGOogle.set("plateforme", "HADEEN");
    this.auth.signIn(dataGOogle).subscribe(
      (respons: any) => {
        if (respons.status === 200) {
          this.toastr.success("Bienvenue  " + respons.data.user.prenom, "", {
            timeOut: 1000,
          });
          this.MenuListServ.setName([respons.data.user.prenom]);
          // localStorage.setItem('Token', JSON.stringify(respons.data.token));
          this.storageService.setItem(
            "Token",
            JSON.stringify(respons.data.token)
          );
          this.currentUserSubject.next(respons.data.token);
          this.modal.hide();
          // tslint:disable-next-line: max-line-length
          // this.MenuListServ.setMenu(['Ajouter un produit', 'Devenir Vendeur', 'Votre compte', 'Vos commandes', 'Votre liste d’envies', 'Vos messages', 'Se déconnecter']);
          this.MenuListServ.setMenuList();
          return respons.data.token;
        }
      },
      (err) => {
        this.toastr.error("Erreur de connexion", "", {
          timeOut: 2000,
        });
      }
    );
  }
  signInWithFB(): void {
    this.authService
      .signIn(FacebookLoginProvider.PROVIDER_ID)
      .then((socialUser) => {
        const a = socialUser.authToken;
        // localStorage.setItem('Token', a);
        this.storageService.setItem("Token", a);
        const data = new FormData();
        data.set("access_token", a);
        data.set("plateforme", "HADEEN");
        this.auth.signUpfb(data).subscribe(
          (rsp: any) => {
            const data3 = new FormData();
            // data3.set('access_token', localStorage.getItem('token'));
            data3.set("access_token", this.storageService.getItem("Token"));
            data3.set("plateforme", "HADEEN");
            this.auth.loginFb(data3).subscribe((respons: any) => {
              this.toastr.success("Votre compte à été crée", "", {
                timeOut: 1000,
              });
              if (respons.status === 200) {
                this.toastr.success(
                  "Bienvenue  " + respons.data.user.prenom,
                  "",
                  {
                    timeOut: 1000,
                  }
                );
                this.MenuListServ.setName([respons.data.user.prenom]);
                this.currentUserSubject.next(respons.data.token);
                this.modal.hide();
                // tslint:disable-next-line: max-line-length
                // this.MenuListServ.setMenu(['Ajouter un produit', 'Devenir Vendeur', 'Votre compte', 'Vos commandes', 'Votre liste d’envies', 'Vos messages', 'Se déconnecter']);
                this.MenuListServ.setMenuList();
                return respons.data.token;
              }
            });
          },
          (err) => {
            if (err.includes("400")) {
              const data2 = new FormData();
              // data2.set('access_token', localStorage.getItem('token'));
              data2.set("access_token", this.storageService.getItem("Token"));
              data2.set("plateforme", "HADEEN");
              this.auth.loginFb(data2).subscribe((respons: any) => {
                if (respons.status === 200) {
                  this.toastr.success(
                    "Bienvenue  " + respons.data.user.prenom,
                    "",
                    {
                      timeOut: 1000,
                    }
                  );
                  this.MenuListServ.setName([respons.data.user.prenom]);
                  this.currentUserSubject.next(respons.data.token);
                  this.modal.hide();
                  // tslint:disable-next-line: max-line-length
                  // this.MenuListServ.setMenu(['Ajouter un produit', 'Devenir Vendeur', 'Votre compte', 'Vos commandes', 'Votre liste d’envies', 'Vos messages', 'Se déconnecter']);
                  this.MenuListServ.setMenuList();
                  return respons.data.token;
                }
              });
            } else {
              this.toastr.error("Une erreur est survenu", "", {
                timeOut: 1000,
              });
            }
          }
        );
      });
  }

  errorForm: boolean = false;
  createAccount() {
    this.errorForm = true;
    if (!this.formRegister.invalid) {
      let errMsg = 'Erreur de création de compte';
      this.requestProcess = true;
      this.showSpinner = true;
      const data = new FormData();
      data.set("nom", this.formRegister.get("nom").value);
      data.set("prenom", this.formRegister.get("prenom").value);
      data.set("email", this.formRegister.get("email").value.trim());
      data.set("password", this.formRegister.get("password").value);
      data.set("recevoir_email", this.newsL.toString());
      data.set("device_type", "WEB");
      data.set("sexe", this.sexe);
      data.set("plateforme", "HADEEN");
      // new Response(data).text().then(console.log);
      this.auth.register(data).subscribe(
        (resp: any) => {
          const dataLogin = new FormData();
          dataLogin.set("email", this.formRegister.get("email").value.trim());
          dataLogin.set("password", this.formRegister.get("password").value);
          dataLogin.set("plateforme", "HADEEN");

          pintrk("track", "signup");

          this.auth.signIn(dataLogin).subscribe(
            (respons: any) => {
              if (respons.status === 200) {
                this.webSocketAPI = new WebSocketAPI();
                this.chatService.testConnectionSOcket();
                this.showSpinner = false;
                this.toastr.success("Bienvenue  " + respons.data.user.pseudo, "", {
                  timeOut: 1000,
                });
                this.name = respons.data.user.pseudo;
                this.MenuListServ.setName([respons.data.user.pseudo]);
                this.auth.storeUserData(
                  JSON.stringify(respons.data.user),
                  respons.data.token
                );
                this.commonService.user = respons.data.user;
                this.currentUserSubject.next(respons.data.token);
                this.modal.hide();
                // tslint:disable-next-line: max-line-length
                // this.MenuListServ.setMenu(['Ajouter un produit', 'Devenir Vendeur', 'Votre compte', 'Vos commandes', 'Votre liste d’envies', 'Vos messages', 'Se déconnecter']);
                this.MenuListServ.setMenuList();
                this.commonService.getPanier();
                if (isPlatformBrowser(this.platformId)) {
                  window.scrollTo(0, 0);
                }
                return respons.data.token;
              }
              this.requestProcess = false;
            },
            (err) => {
              this.showSpinner = false;
              this.requestProcess = false;
              this.toastr.error("Erreur de connexion", "", {
                timeOut: 2000,
              });
            }
          );
        },
        (err) => {
          if (err.error.data?.Utilisateur) {
            errMsg = "Adresse e-mail déjà associée à un utilisateur";
          }
          this.toastr.error(errMsg, "", {
            timeOut: 2000,
          });
          this.requestProcess = false;
        },
      );
    }
  }

  createAccountGoogle(nom, prenom, email, pw, sexe) {
    let errMsg = 'Erreur de création de compte';
    const data = new FormData();
    data.set("nom", nom);
    data.set("prenom", prenom);
    data.set("email", email.trim());
    data.set("password", pw);
    data.set("recevoir_email", true.toString());
    data.set("device_type", "WEB");
    data.set("sexe", this.sexe);
    data.set("plateforme", "HADEEN");
    new Response(data).text().then(console.log);
    this.auth.register(data).subscribe(
      (resp: any) => {
        pintrk("track", "signup");
        const dataLogin = new FormData();
        dataLogin.set("email", email.trim());
        dataLogin.set("password", pw);
        dataLogin.set("plateforme", "HADEEN");
        this.auth.signIn(dataLogin).subscribe(
          (respons: any) => {
            if (respons.status === 200) {
              this.MenuListServ.setName([respons.data.user.prenom]);
              this.auth.storeUserData(
                JSON.stringify(respons.data.user),
                respons.data.token
              );
              this.currentUserSubject.next(respons.data.token);
              this.modal.hide();
              // tslint:disable-next-line: max-line-length
              // this.MenuListServ.setMenu(['Ajouter un produit', 'Devenir Vendeur', 'Votre compte', 'Vos commandes', 'Votre liste d’envies', 'Vos messages', 'Se déconnecter']);
              this.MenuListServ.setMenuList();
              this.toastr.success(
                "Bienvenue  " + respons.data.user.prenom,
                "",
                {
                  timeOut: 1000,
                }
              );
              this.router.navigate([""]);
              return respons.data.token;
            }
          },
          (err) => {
            if (err.error.data?.Utilisateur) {
              // errMsg = err.error.data.Utilisateur;
              errMsg = "Adresse e-mail déjà associée à un utilisateur";
            }
            this.toastr.error(errMsg, "", {
              timeOut: 2000,
            });
          }
        );
      },
      (err) => {
        console.log(err);
        
        if (err.error.data?.Utilisateur) {
          // errMsg = err.error.data.Utilisateur;
          errMsg = "Adresse e-mail déjà associée à un utilisateur";
        }
        this.toastr.error(errMsg, "", {
          timeOut: 2000,
        });
      }
    );
  }

  signIn(votreEmail, mdp) {
    this.showSpinner = true;
    const data = new FormData();
    data.set("email", votreEmail.trim());
    data.set("password", mdp);
    data.set("plateforme", "HADEEN");
    /* new Response(data).text().then(console.log); */
    this.auth.signIn(data).subscribe(
      (resp: any) => {
        if (resp.status === 200) {
          this.showSpinner = false;
          this.toastr.success("Bienvenue  " + resp.data.user.pseudo, "", {
            timeOut: 1000,
          });
          this.name = resp.data.user.pseudo;
          this.auth.storeUserData(
            JSON.stringify(resp.data.user),
            resp.data.token
          );
          this.commonService.user = resp.data.user;
          this.webSocketAPI = new WebSocketAPI();
          this.chatService.testConnectionSOcket();
          this.currentUserSubject.next(resp.data.token);
          this.modal.hide();
          // tslint:disable-next-line: max-line-length
          // this.MenuListServ.setMenu(['Ajouter un produit', 'Devenir Vendeur', 'Votre compte', 'Vos commandes', 'Votre liste d’envies', 'Vos messages', 'Se déconnecter']);
          this.MenuListServ.setMenuList();
          this.commonService.getPanier();
          if (isPlatformBrowser(this.platformId)) {
            window.scrollTo(0, 0);
          }

          this.httpClient
            .get(`${environment.ws_url}users/vendeur/byId`)
            .subscribe((respVendeur: any) => {
              resp.data.user["has_frais_livraison"] =
                respVendeur.data.vendeur.has_frais_livraison;
              this.auth.storeUserData(
                JSON.stringify(resp.data.user),
                resp.data.token
              );
              const aaa = resp.data.user;
              this.postModalFrais();
            });
          return resp.data.token;
        }
      },
      (err) => {
        this.toastr.error("Erreur de connexion", "", {
          timeOut: 2000,
        });
      }
    );
  }

  checkpw(controls) {
    // const regExp = new RegExp(/^[a+A-Z+0-9]+$/);
    const regExp = /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)/;
    if (regExp.test(controls.value)) {
      return null;
    } else {
      return { checkPseudo: true };
    }
  }

  checknewl(ev) {
    if (ev.target.checked === true) {
      this.newsL = true;
    } else {
      this.newsL = false;
    }
  }

  selectSexe() {
    if (
      this.MselectSexe === "selectedSexe" &&
      this.FselectSexe === "unselectedSexe"
    ) {
      this.FselectSexe = "selectedSexe";
      this.MselectSexe = "unselectedSexe";
      this.sexe = "FEMME";
    } else if (
      this.FselectSexe === "selectedSexe" &&
      this.MselectSexe === "unselectedSexe"
    ) {
      this.FselectSexe = "unselectedSexe";
      this.MselectSexe = "selectedSexe";
      this.sexe = "HOMME";
    }
  }

  logout() {
    // localStorage.removeItem('Token');
    // localStorage.removeItem('User');
    // localStorage.removeItem('Details');
    // localStorage.removeItem('Vendeur');
    // localStorage.removeItem('Boutique');
    // localStorage.removeItem('tokenCompany');
    // localStorage.removeItem('etape1');

    this.storageService.removeItem("Token");
    this.storageService.removeItem("User");
    this.storageService.removeItem("Details");
    this.storageService.removeItem("Vendeur");
    this.storageService.removeItem("Boutique");
    this.storageService.removeItem("tokenCompany");
    this.storageService.removeItem("etape1");

    // this.testDisconectionSOcket();
    this.commonService.getPanier();
    this.commonService.user = null;
    this.currentUserSubject.next(null);
    // this.MenuListServ.setMenu(['Je suis nouveau client', 'J\'ai déjà un compte', 'Devenir Vendeur']);
    this.MenuListServ.setMenuList();
    this.MenuListServ.setName([", Identifiez-vous"]);
    this.authService.signOut().then((resp: any) => {});
    this.toastr.warning("à Bientot", "", {
      timeOut: 1000,
    });
    this.router.navigate(["/"]);
  }

  loginModal(template: TemplateRef<any>) {
    this.modalRef2 = this.modalService.show(
      template,
      Object.assign({}, { class: "login" })
      // Object.assign({ backdrop: 'static' }),
    );
  }

  showPassLogin() {
    if (this.signe === "password" && this.faLogin === "fa-eye") {
      this.signe = "text";
      this.faLogin = "fa-eye-slash";
    } else {
      this.signe = "password";
      this.faLogin = "fa-eye";
    }
  }
  newsCustomerBtn() {
    if (this.newsCustomer === false) {
      this.newsCustomer = true;
      this.newClass = "activModal";
      this.accountClass = "";
    } else if (this.newsCustomer === true) {
      this.newsCustomer = false;
      this.newClass = "";
      this.accountClass = "activModal";
    }
  }

  public goTo(a, loginTemplate?) {
    if (a.includes("Votre compte")) {
      this.router.navigate(["/compte"]);
    } else if (a.includes("nouveau client")) {
      // this.loginModal(loginTemplate);
      this.newsCustomer = true;
      this.newsCustomerBtn();
      this.chatService.loginModal(loginTemplate);
      // this.router.navigate(['/login-devenir-vendeur']);
    } else if (a.includes("déjà un compte")) {
      // this.loginModal(loginTemplate);
      this.newsCustomer = false;
      this.newsCustomerBtn();
      this.chatService.loginModal(loginTemplate);
      // this.router.navigate(['/login-devenir-vendeur']);
    } else if (a.includes("déconnecter")) {
      this.logout();
    } else if (a.includes("reset-mdp")) {
      this.modal.hide();
      this.router.navigate(["/reset-mdp"]);
    } else if (a.includes("Devenir Vendeur")) {
      // const ab = localStorage.getItem('Token');
      const ab = this.storageService.getItem("Token");
      if (ab !== null) {
        this.router.navigate(["/devenir-vendeur"]);
      } else {
        this.router.navigate(["login-devenir-vendeur"]);
      }
    } else if (a.includes("Ajouter un produit")) {
      this.router.navigate(["/ajout-prod"]);
    } else if (a.includes("Vos messages")) {
      this.router.navigate(["/compte/mes-messages"]);
    }
  }

  getMenuWS() {
    this.productS.getUniv().subscribe((data: any) => {
      this.menuWS = data.data;
    });
  }

  seeSousUniv(a) {
    this.titreSousUniv = a.nom;
    this.productS.getcategorie(a.code).subscribe((data: any) => {
      this.sousMenuSUniv = data.data;
      this.sousMenuSUniv.forEach((element) => (element.categorie = a));
    });
  }

  seeMenu(a, i) {
    if (this.menuHover === true) {
      this.titreUniver = a.nom;
      this.productS.getSousUniv(a.code).subscribe((data: any) => {
        this.sousMenuWS = data.data;
        this.sousMenuBool = true;
        this.selectedIdx = i;
        switch (i) {
          case (i = 0):
            return (this.activeMenu = "activDropG activDrop");
          case (i = 1):
            return (this.activeMenu = "activDropG activDrop");
          case (i = 2):
            return (this.activeMenu = "activDropG activDrop");
          case (i = 3):
            return (this.activeMenu = "activDropG activDrop");
          case (i = 4):
            return (this.activeMenu = "activDropC activDrop");
          case (i = 5):
            return (this.activeMenu = "activDropC activDrop");
          case (i = 6):
            return (this.activeMenu = "activDropD activDrop");
          case (i = 7):
            return (this.activeMenu = "activDropD activDrop");
          case (i = 8):
            return (this.activeMenu = "activDropD activDrop");
        }
      });
    }
  }

  menuM(a, template: TemplateRef<any>, c, x?) {
    if (c === "enfants") {
      if (x) {
        this.titreUniver = a.nom;
      }
      this.titreMobile = a.nom;
      this.productS.getSousUniv(a.code).subscribe((data: any) => {
        this.sousMenuWS = data.data;
        this.modalMobilMenu = this.modalService.show(
          template,
          Object.assign({}, { class: "burgermenu" })
        );
      });
    } else if (c === "categorie") {
      if (!x) {
        this.titreSousUniv = a.nom;
      }
      this.titreMobile = a.nom;
      this.codeCategorie = a.univers_code;
      this.productS.getcategorie(a.code).subscribe((data: any) => {
        this.sousMenuWS2 = data.data;
      });
    }
  }

  leaveM() {
    this.sousMenuBool = false;
    this.activeMenu = "";
    this.selectedIdx = null;
    this.sousMenuSUniv = null;
    this.titreSousUniv = null;
    this.titreUniver = null;
  }

  openToggle(a) {
    if (a === "Open") {
      this.example = "example-container";
    } else {
      this.example = "sidenavClose";
    }
  }

  ngOnInit(): void {
    this.breakpointObserver
      .observe(["(min-width: 767px)"])
      .subscribe((state: BreakpointState) => {
        if (state.matches) {
          this.menuHover = true;
        }
      });
    // const user = localStorage.getItem('User');
    const user = this.storageService.getItem("User");
    if (user) {
      this.webSocketAPI = new WebSocketAPI();
      // this.appComponent.testConnectionSOcket();
      this.chatService.testConnectionSOcket();
    }
    // this.webSocketAPI = new WebSocketAPI(new AppComponent());
  }

  changeQuery(item) {
    this.router.navigate([`/all-product/${item.categorie.nom}/${item.nom}`]);
  }

  retourMenu(drawer) {
    if (this.sousMenuWS2) {
      this.sousMenuWS2 = null;
    } else {
      // this.openToggle('Open');
      this.modalMobilMenu.hide();
      drawer.open();
      this.openToggle("Open");
    }
  }

  // NEW
  removePanier(idItem) {
    this.commonService.removePanier("1", idItem);
  }

  connecterViaFB() {
    this.authService
      .signIn(FacebookLoginProvider.PROVIDER_ID)
      .then((socialUser) => {
        const tokenFB = socialUser.authToken;
        const data3 = new FormData();
        data3.set("access_token", tokenFB);
        data3.set("device", "WEB");
        data3.set("plateforme", "HADEEN");
        this.auth.loginFb(data3).subscribe((respons: any) => {
          if (respons.status === 200) {
            this.toastr.success("Bienvenue  " + respons.data.user.prenom, "", {
              timeOut: 3000,
            });
            this.MenuListServ.setName([respons.data.user.pseudo]);
            this.auth.storeUserData(
              JSON.stringify(respons.data.user),
              respons.data.token
            );
            this.commonService.user = respons.data.user;
            this.currentUserSubject.next(respons.data.token);

            this.MenuListServ.setMenuList();
            this.commonService.getPanier();
            if (isPlatformBrowser(this.platformId)) {
              window.scrollTo(0, 0);
            }
            this.webSocketAPI = new WebSocketAPI();
            this.chatService.testConnectionSOcket();
            this.router.navigate(["/compte"]);
            this.modal.hide();
            // return respons.data.token;
          }
        });
      });
  }

  connecterViaGoogle(): void {
    this.authService
      .signIn(GoogleLoginProvider.PROVIDER_ID)
      .then((socialUser) => {
        const data3 = new FormData();
        data3.set("email", socialUser.email.trim());
        data3.set("google_id", socialUser.id);
        data3.set("device_type", "WEB");
        data3.set("plateforme", "HADEEN");
        this.auth.googleLogin(data3).subscribe(
          (respons: any) => {
            if (respons.status === 200) {
              this.toastr.success(
                "Bienvenue  " + respons.data.user.prenom,
                "",
                {
                  timeOut: 3000,
                }
              );
              this.MenuListServ.setName([respons.data.user.prenom]);
              // this.MenuListServ.setName([respons.data.user.nickname]);
              this.auth.storeUserData(
                JSON.stringify(respons.data.user),
                respons.data.token
              );
              this.commonService.user = respons.data.user;
              this.currentUserSubject.next(respons.data.token);

              this.MenuListServ.setMenuList();
              this.commonService.getPanier();
              if (isPlatformBrowser(this.platformId)) {
                window.scrollTo(0, 0);
              }
              this.webSocketAPI = new WebSocketAPI();
              this.chatService.testConnectionSOcket();
              this.router.navigate(["/compte"]);
              this.modal.hide();
              // return respons.data.token;
            }
          },
          (error) => {
            // tslint:disable-next-line:max-line-length
            this.toastr.warning(
              "Cet email est déjà lié à un profil, veuillez cliquer sur mot de passe oublié pour récupérer le mot de passe",
              "",
              {
                timeOut: 3000,
              }
            );
          }
        );
      });
  }

  creationCompteFb() {
    console.log("ok fb");
    this.authService
      .signIn(FacebookLoginProvider.PROVIDER_ID)
      .then((socialUser) => {
        console.log("ok sd");
        const tokenFB = socialUser.authToken;
        const data3 = new FormData();
        data3.set("access_token", tokenFB);
        data3.set("device", "WEB");
        data3.set("plateforme", "HADEEN");
        this.auth.signUpfb(data3).subscribe((respSingUp: any) => {
          pintrk("track", "signup");
          this.auth.loginFb(data3).subscribe((respons: any) => {
            if (respons.status === 200) {
              this.toastr.success(
                "Bienvenue  " + respons.data.user.prenom,
                "",
                {
                  timeOut: 3000,
                }
              );
              this.MenuListServ.setName([respons.data.user.pseudo]);
              this.auth.storeUserData(
                JSON.stringify(respons.data.user),
                respons.data.token
              );
              this.commonService.user = respons.data.user;
              this.currentUserSubject.next(respons.data.token);

              this.MenuListServ.setMenuList();
              this.commonService.getPanier();
              if (isPlatformBrowser(this.platformId)) {
                window.scrollTo(0, 0);
              }
              this.webSocketAPI = new WebSocketAPI();
              this.chatService.testConnectionSOcket();
              this.router.navigate(["/compte"]);
              this.modal.hide();
              // return respons.data.token;
            }
          });
        });
      });
  }

  creationCompteGoogle() {
    this.authService
      .signIn(GoogleLoginProvider.PROVIDER_ID)
      .then((socialUser: any) => {
        const data3 = new FormData();
        // data3.set('device_type', 'WEB');
        data3.set("device", "WEB");
        data3.set("email", socialUser.email.trim());
        data3.set("family_name", socialUser.firstName);
        data3.set("given_name", socialUser.lastName);
        data3.set("google_id", socialUser.id);
        data3.set("name", socialUser.lastName);
        data3.set("picture", socialUser.photoUrl);
        data3.set("plateforme", "HADEEN");
        this.auth.googleRegister(data3).subscribe(
          (respons: any) => {
            if (respons.status === 200 || respons.status === 201) {
              pintrk("track", "signup");
              const dataConnect = new FormData();
              dataConnect.set("email", socialUser.email.trim());
              dataConnect.set("google_id", socialUser.id);
              dataConnect.set("device_type", "WEB");
              dataConnect.set("plateforme", "HADEEN");
              this.auth
                .googleLogin(dataConnect)
                .subscribe((responsConnect: any) => {
                  this.toastr.success(
                    "Bienvenue  " + responsConnect.data.user.prenom,
                    "",
                    {
                      timeOut: 3000,
                    }
                  );
                  this.MenuListServ.setName([responsConnect.data.user.prenom]);
                  this.auth.storeUserData(
                    JSON.stringify(responsConnect.data.user),
                    responsConnect.data.token
                  );
                  this.commonService.user = responsConnect.data.user;
                  this.currentUserSubject.next(responsConnect.data.token);

                  this.MenuListServ.setMenuList();
                  this.commonService.getPanier();
                  if (isPlatformBrowser(this.platformId)) {
                    window.scrollTo(0, 0);
                  }
                  this.webSocketAPI = new WebSocketAPI();
                  this.chatService.testConnectionSOcket();
                  this.router.navigate([""]);
                  this.modal.hide();
                });
            }
          },
          (err: any) => {
            this.toastr.warning("Votre email est déjà lié a une profile", "", {
              timeOut: 3000,
            });
          }
        );
      });
  }

  postModalFrais() {
    const userStorage = localStorage.getItem("User");
    if (userStorage) {
      const user = JSON.parse(userStorage);
      setTimeout(() => {
        if (
          user.role === "VENDEUR_COMPLET" &&
          (!user.has_frais_livraison || user.has_frais_livraison === 0)
        ) {
          this.commonService.openDialog(false);
        }
        if (
          user.role === "VENDEUR_VALIDEE" &&
          (!user.has_frais_livraison || user.has_frais_livraison === 0)
        ) {
          this.commonService.openDialog(true);
        }
      }, 200);
    }
  }
}
