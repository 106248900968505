import { ToastrService } from 'ngx-toastr';
import { HttpClient } from '@angular/common/http';
import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

declare var $;
declare var Stripe;

// export interface DialogData {
//   animal: string;
//   name: string;
// }

@Component({
  selector: 'app-boutique',
  templateUrl: './boutique.component.html',
  styleUrls: ['./boutique.component.scss']
})
export class BoutiqueComponent implements OnInit {
  // animal: string;
  // name: string;

  isEdit;

  boutiqueForm: FormGroup;
  avatar = '../../../../assets/images/img/Groupe 1364.webp';
  myProducts;
  stripe = Stripe(environment.keys);
  elements;
  card;
  titulaire;
  dataForm;
  myList = true;

  nom;
  siret;
  tva;
  adresse;
  mail;
  description;
  conditions;
  page;
  statutBoutique;
  archives = [];
  currentStatut;
  idVendeur;

  isExistProdOnline = false;


  constructor(
    private formBuilder: FormBuilder, private httpClient: HttpClient, private activatedRoute: ActivatedRoute,
    private router: Router, private toast: ToastrService, public dialog: MatDialog) {
    const params = this.activatedRoute.snapshot.queryParams['page'];
    this.page = (params) ? params : 1;
  }

  ngOnInit(): void {
    this.boutiqueForm = this.formBuilder.group({
      nom: [{value: ''}, Validators.required],
      siret: [{value: ''}, Validators.required],
      tva: [{value: ''}, Validators.required],
      adresse: [{value: ''}, Validators.required],
      mail: [{value: ''}, Validators.required],
      description: [{value: ''}, Validators.required],
      conditions: [{value: ''}, Validators.required],
    });
    this.getProduitsByVendeur();
    this.getVendeur();
  }

  get f() {
    return this.boutiqueForm.controls;
  }

  onSubmit(value1?, value2?) {
    // alert('submit form');
  }

  boostProduct(produit) {
    this.dataForm = {1: {id: produit}};
  }

  detectFile(event) {
    const files = event.target.files;
    if (files) {
      const reader = new FileReader();
      reader.onload = (e: any) => {
        this.avatar = e.target.result;
      };
      reader.readAsDataURL(files[0]);
    }
  }


  async getProduitsByVendeur(pp?) {
    const formData = new FormData();
    formData.set('plateforme', 'HADEEN');
    formData.set('trie_par.attribut', 'DEFAULT');
    formData.set('trie_par.is_croissant', 'true');
    formData.set('trie_par.is_croissant', 'true');
    if (this.page) {
      formData.set('pagination.page', this.page);
    } else {
      formData.set('pagination.page', '1');
    }
    formData.set('pagination.par_page', '9');
    this.httpClient.post(environment.ws_url + 'produits/mes', formData).subscribe((resp: any) => {
      const statuts = ['EN_LIGNE', 'ARCHIVE'];
      this.myProducts = resp.data;
      const test = this.myProducts.produits.every(e => e.statut !== 'EN_LIGNE');
      this.isExistProdOnline = !test;
      this.myProducts.produits.forEach(e => {
        e.isArchive = statuts.includes(e.statut) ? false : null;
        e.isEdit = false;
        e.prixOrig = e.prix;
        e.prix_promotionOrig = e.prix_promotion;
      });
    });
  }

  closeModal() {
    $('#staticBackdrop').modal('hide');
  }

  formatUrl(url) {
    url = url.trim().split('&').join('___')
    return (url.trim()).split(' ').join('_');
  }

  getVendeur() {
    this.httpClient.get(environment.ws_url + 'users/vendeur/byId').subscribe((resp: any) => {
      this.avatar = resp.data.boutique.logo;
      this.nom = resp.data.boutique.nom;
      this.siret = resp.data.vendeur.siret;
      this.tva = resp.data.vendeur.tva;
      this.adresse = resp.data.vendeur.adresse_societe;
      this.mail = '';
      this.description = resp.data.boutique.description;
      this.conditions = resp.data.vendeur.condition_retour;
      this.statutBoutique = resp.data.boutique.statut;
      this.idVendeur = this.formatUrl(resp.data.user.pseudo) + '-' + resp.data.user.id;

    });
  }


  setShow(id) {
    if (id === 1) {
      this.myList = false;
    } else {
      this.myList = true;
    }
  }

  changePage($event) {
    this.page = $event;
    const linkParams = {};
    linkParams['page'] = this.page;
    this.router.navigate(['.'], { relativeTo: this.activatedRoute, queryParams: linkParams });
    this.getProduitsByVendeur(this.page);
  }

  changeStatutBoutique() {
    const newStatut = (this.statutBoutique === 'EN_LIGNE') ? 'EN_CONGE' : 'EN_LIGNE';
    this.httpClient.get(environment.ws_url + 'boutiques/' + newStatut).subscribe((resp: any) => {
      this.statutBoutique = resp.data.status;
      if (this.statutBoutique === 'EN_LIGNE') {
        this.toast.success('Votre boutique a été mise en ligne avec succès');
      } else {
        this.toast.success('Votre boutique a été mise en congé avec succès');
      }

    });
  }

  archiveProduct(item) {
    if (!item.isArchive) {
      this.archives = this.archives.filter( e => e.id.produitId !== item.id.produitId);
    } else { this.archives.push(item); }
    this.currentStatut = this.archives.length > 0 ? this.archives[0].statut : undefined;
    if (this.currentStatut) {
      this.myProducts.produits.forEach(e => {
        if (e.statut !== this.currentStatut) { e.isArchive = null; }
      });
    } else {
      const statuts = ['EN_LIGNE', 'ARCHIVE'];
      this.myProducts.produits.forEach(e => { e.isArchive = statuts.includes(e.statut) ? false : null; });
    }

  }

  sendListArchive() {
    if (this.archives.length > 0 ) {
      let url = 'produits/archiver';
      let message = 'Produit(s) archivé avec succès';
      if (this.currentStatut === 'ARCHIVE') {
        url = 'produits/desarchiver';
        message = 'Produit(s) désarchiver avec succès';
      } else {
        url = 'produits/archiver';
        message = 'Produit(s) archivé avec succès';
      }
      const ids = this.archives.map(e => e.id.produitId);
      const formData = new FormData();
      formData.append('ids', ids.toString());
      this.httpClient.post(environment.ws_url + url, formData).subscribe((resp: any) => {
        this.archives = [];
        this.toast.success(message);
        if (this.page) {
          this.getProduitsByVendeur(this.page);
        } else {
          this.getProduitsByVendeur();
        }
      });
    }
  }

  editPrix(item, reset?) {
    item.isEdit = false;
    if (!reset) {
      const formData = new FormData();
      if (item.prix_promotion) {
        formData.set(`prix_promotion`, item.prix_promotion);
      }
      if (item.prix) {
        formData.set(`prix`, item.prix);
      }
      // formData.set(`plateforme`, 'HADEEN');
      formData.set(`id`, item.id.produitId);
      this.httpClient.post(`${environment.ws_url}produits/HADEEN/${item.id.produitId}`, formData).subscribe((dataMenu: any) => {
        // console.log('modification effectué');
      });
    } else {
      item.prix = item.prixOrig;
      item.prix_promotion = item.prix_promotionOrig;
    }
  }
}
