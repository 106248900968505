<div class="text-center" [ngSwitch]="data.index" style="position: relative;">
  <button mat-icon-button (click)="onNoClick()" style="position: absolute; top: -40px; right: -20px;" aria-label="Example icon button with a close icon">
    <mat-icon>close</mat-icon>
  </button>
  <p style="font-weight: 600;">
    {{data.nom}}
  </p>
  <div *ngSwitchCase="0">
    <p>Hadéen-Place vous propose de mettre en ligne autant d'annonces que vous le souhaitez gratuitement. Vous aurez aussi la possibilité d’accéder à des <span style="font-weight: 700;">mises en avant ponctuelles</span> en gérant en toute autonomie la durée, l'emplacement, votre boutique ou catégorie d'article, etc.</p>
  </div>

  <div *ngSwitchCase="1">
    L’offre « {{data.nom2}} »  vous permet de vous <span style="font-weight: 700;">mettre en avant sur notre site</span> et contribue également à <span style="font-weight: 700;">votre référencement naturel</span>. L'abonnement idéal pour les vendeurs qui veulent communiquer et agir sur leur SEO tout en maîtrisant leur budget. En effet, le forfait {{data.nom2}} vous donne droit à :
    <ul>
      <li style="list-style-type: disc;">Une publicité dédiée à votre boutique sur notre Home Page dans les "A la Une" (publicité fixe) durant 7 jours.</li>
      <li style="list-style-type: disc;">Un article sur notre blog incluant un lien vers votre site web et un deuxième vers vos réseaux sociaux durant 1 mois.</li>
    </ul>
    <p style="font-weight: 700;">Un bon compromis entre budget et résultat.</p>
  </div>

  <div *ngSwitchCase="2">
    Très prisée par nos vendeurs, l’objectif de l’offre {{data.nom2}} est d’allier communication, référencement naturel et notoriété.
    Cet abonnement inclut les mêmes avantages que le forfait Terre plus
    <ul>
      <li style="list-style-type: disc;">Un slider (publicité défilante) soit le premier visuel lorsque les internautes arrivent sur notre site. Vous serez donc mis en avant 2 semaines sur la page d'accueil : 7 jours en fixe dans les "A la une" puis 7 jours en version défilante.</li>
      <li style="list-style-type: disc;">Une publication Instagram dans notre fil d’actualité ainsi qu’en Story, et toujours en y intégrant un lien vers votre boutique « Hadéen-Place » et un autre vers votre profil Instagram. La promesse d’être vu par des milliers de personnes.</li>
      <li style="list-style-type: disc;">Et l'article sur notre blog sera présent pour un mois. En plus d’une présentation de votre boutique/articles, la publication inclura deux liens : un premier vers votre site web et un deuxième vers un de vos réseaux sociaux.</li>
    </ul>
    <p style="font-weight: 700;">La recette parfaite pour bien se lancer.</p>
  </div>

  <div *ngSwitchCase="3">
    Tous les services qu’Hadéen-Place peut vous proposer se retrouve dans ce pack : l’ensemble des mises en avant possible sur notre Home Page (Slider/ A la Une…), Publication et Story Instagram, un article dédié sur notre blog, plus  :

    <ul>
      <li style="list-style-type: disc;">Une publication réalisée en partenariat avec un influenceur  et ainsi accroître votre notoriété auprès de millier d’abonnés</li>
      <li style="list-style-type: disc;">Votre présence dans notre Newsletter mensuelle envoyée à près de 37 000 personnes</li>
    </ul>

    {{data.nom2}} ? On aurait pu appeler cet abonnement «  Sérénité » tant il vous permet de vous adresser directement à des influenceurs efficaces, avoir un retour sur investissement pertinent et tout ceci pour un budget raisonnable. Fondé par des artisans, Hadéen-Place sait qu’il n’est ni raisonnable ni possible pour des structures à taille humaine d’investir l’équivalent d’un « salaire » voire plus dans une seule publication.

    <p style="font-weight: 700;">On a pensé cette offre pour vous et on se charge de tout.</p>
  </div>
  <!-- <div *ngSwitchDefault>output2</div> -->
</div>
