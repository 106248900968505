<div class="spinner" style="position: fixed; top: 30%; z-index: 1;" *ngIf="showSpinner">
  <mat-spinner></mat-spinner>
</div>

<div class="top-header">
  <hr>
  <div class="container">
    <div class="header">
      <ul class="breadcrumb">
        <li><a routerLinkActive="active" [routerLinkActiveOptions]="{exact:true}" routerLink="/">Hadéen-place</a></li>
        <li>Creation compte vendeur</li>
      </ul>
    </div>
  </div>
</div>


<div>
  <div class="container text-center body">
    <h1>Devenir vendeur</h1>

    <ng-template #name [ngIf]="!etapeVendeur">
      <div class="header-logo">
        <span>
          <img width="auto" height="auto" [src]="!etapeVendeur ? '../../../../assets/images/devenir_vendeur/1_selected.svg' : '../../../../assets/images/devenir_vendeur/1.svg'" alt="" style="border-radius: 50%;">
          <label [ngStyle]="{
            'font-weight': !etapeVendeur ? '600' : '400',
            'color': !etapeVendeur ? '#000' : '#9f9f9f'
          }">Information sur la société</label>
        </span>
        <span class="second">
          <img width="auto" height="auto" src="../../../../assets/images/devenir_vendeur/Tracé 73.svg" alt="" style="width: 40px;">
          <img width="auto" height="auto" [src]="etapeVendeur ? '../../../../assets/images/devenir_vendeur/2_selected.svg' : '../../../../assets/images/devenir_vendeur/2.svg'" alt="" style="border-radius: 50%;">
          <label [ngStyle]="{
            'font-weight': etapeVendeur ? '600' : '400',
            'color': etapeVendeur ? '#000' : '#9f9f9f'
          }">Information sur le dirigeant</label>
        </span>
      </div>
      <h3>Information sur la société</h3>
      <h6> Les champs marqués d’une * sont obligatoires</h6>


      <div class="col-12">
        <img width="auto" height="auto" *ngIf="!societeLogoState" src="../../../../assets/images/devenir_vendeur/avatar_logo.svg" alt="hadeen-place"
          style="width: 98px; height: 98px; border-radius: 50%;">
        <img width="auto" height="auto" *ngIf="societeLogoState" src="{{ societeLogoPath }}" alt=""
          style="width: 98px; height: 98px; border-radius: 50%;">
      </div>
      <div class="col-12 mt-1" (click)="fileInput.click()">
        <input style="display: none" #fileInput type="file" accept='image/*' (change)="preview($event, 1)">
        <button
          style="width: fit-content; background-color: #345B2B; color: #ffffff; border: 1px solid #e9e9e9; padding: 5px 10px;">Importer
          fichier</button>
      </div>
      <form [formGroup]="societeFormGroup" autocomplete="off" #ngForm
        [ngClass]="{'formError': societeFormGroup && societeFormGroup.invalid && isSocieteValid, 'form-submitted': isSFGSubmitted}">
        <mat-form-field class="example-full-width col-12 hadeenInput" appearance="fill" [floatLabel]="'auto'">
          <mat-label>Nom de la Société *</mat-label>
          <input matInput placeholder="Nom de la Société" formControlName="societeCtrlNom">
        </mat-form-field>
        <mat-form-field class="example-full-width col-12 hadeenInput" appearance="fill" [floatLabel]="'auto'">
          <mat-label>Siret*</mat-label>
          <input matInput type="number" placeholder="Siret*" formControlName="societeCtrlSiret">
        </mat-form-field>
        <mat-form-field class="example-full-width col-12 hadeenInput" appearance="fill" [floatLabel]="'auto'">
          <mat-label>Tva*</mat-label>
          <input matInput placeholder="Tva*" formControlName="societeCtrlTva">
        </mat-form-field>

        <!-- <p class="col-12 mb-0" style="font-family: 'Roboto'; color: #707070; font-weight: 300;">Assurez-vous que l’adresse inséré soit le même que l’adresse sur votre pièce d’identité et/ou passeport</p> -->
        <mat-form-field class="example-full-width col-12 hadeenInput" appearance="fill" [floatLabel]="'auto'">
          <mat-label>Adresse*</mat-label>
          <input matInput ngx-google-places-autocomplete [options]='options' pattern="[\-.'\/&a-zA-ZÀ-ÿ0-9 ]+"
            (onAddressChange)="handleAddressChange($event)" placeholder="Adresse de la societé*"
            formControlName="societeCtrlAdresse">
          <!-- <input matInput placeholder="Adresse de la societé*" formControlName="societeCtrlAdresse"> -->
          <mat-error>Caractère non autorisé</mat-error>
        </mat-form-field>

        <mat-form-field class="example-full-width col-12 hadeenInput" appearance="fill" [floatLabel]="'auto'">
          <mat-label>Code postal*</mat-label>
          <input matInput placeholder="Code postal*" formControlName="societeCtrlCode">
        </mat-form-field>
        <mat-form-field class="example-full-width col-12 hadeenInput" appearance="fill" [floatLabel]="'auto'">
          <mat-label>Ville*</mat-label>
          <input matInput placeholder="Ville*" formControlName="societeCtrlVille">
        </mat-form-field>
        <mat-form-field class="example-full-width col-12 hadeenInput" appearance="fill" [floatLabel]="'auto'">
          <mat-label>Pays*</mat-label>
          <input matInput placeholder="Pays*" formControlName="societeCtrlPays">
        </mat-form-field>
        <mat-form-field class="example-full-width col-12 hadeenInput" appearance="fill" [floatLabel]="'auto'">
          <mat-label>Email*</mat-label>
          <input matInput type="email" placeholder="Email*" formControlName="societeCtrlEmail">
        </mat-form-field>
        <div class="col-12">
          <div class="field text-left col-12 hadeenInput pb-2">
            <ngx-intl-tel-input id="phone" class="customInput" [cssClass]="'control-form hadeenInput country-tel-code'"
              [preferredCountries]="[CountryISO.France, CountryISO.UnitedKingdom]" [enableAutoCountrySelect]="true"
              [enablePlaceholder]="true" [searchCountryFlag]="true"
              [searchCountryField]="[SearchCountryField.Iso2, SearchCountryField.Name]" [selectFirstCountry]="false"
              [selectedCountryISO]="CountryISO.France" [maxLength]="15" [tooltipField]="TooltipLabel.Name"
              [phoneValidation]="true" [separateDialCode]="false" name="phone" formControlName="societeCtrlNumero"
              (input)="ngModelChange($event)" style="background-color: transparent; width: 87%;">
            </ngx-intl-tel-input>
            <label class="m-0 mt-2" for="numero" style="font-size: 12px;">Numéro de téléphone*</label>
          </div>
        </div>

        <mat-form-field class="example-full-width col-12 hadeenInput" appearance="fill" floatLabel="always">
          <mat-label>Description*</mat-label>
          <textarea matInput cdkTextareaAutosize cdkAutosizeMinRows="3"
            placeholder="Présentez votre boutique, son histoire, ses atouts, slogan ...."
            formControlName="societeCtrlDescription">
              </textarea>
        </mat-form-field>
        <mat-form-field class="example-full-width col-12 hadeenInput" appearance="fill" floatLabel="always">
          <mat-label>Conditions*</mat-label>
          <textarea matInput cdkTextareaAutosize cdkAutosizeMinRows="3"
            placeholder="Exemple : 14 jours de délais de rétractation à compter de la date de reception. Frais de retours à la charge du consommateur."
            formControlName="societeCtrlConditions"></textarea>
        </mat-form-field>

        <div class="col-12 mb-4">
          <mat-checkbox class="example-margin" formControlName="societeCtrlAccept">
            En enregistrant votre compte, vous acceptez nos <a [routerLink]="[ '/conditions-de-ventes' ]">Conditions
              Générales d'utilisation</a> et le <a href="https://stripe.com/fr/connect-account/legal">contrat de
              création
              de compte StripeConnected</a>
          </mat-checkbox>
        </div>

        <div class="col-12">
          <button mat-flat-button type="submit" (click)="step1_stripeCompanyToken()"
            style="background-color: #000000; border: 1px solid #000000; border-radius: 0px; color: #ffffff;">ÉTAPE
            SUIVANTE</button>
        </div>
      </form>

    </ng-template>

    <!-- <ng-template #abonement [ngIf]="etapeVendeur === 1 || etapeVendeur === 2">
      <app-abonnement></app-abonnement>
    </ng-template> -->

    <ng-template #name2 [ngIf]="etapeVendeur === 1 || etapeVendeur === 2">
      <div class="header-logo">
        <span>
          <img width="auto" height="auto" src="../../../../assets/images/devenir_vendeur/1.svg" alt="">
          <label style="color: gray; font-weight: 300;">Information sur la société</label></span>
        <span class="second"> <img width="auto" height="auto" src="../../../../assets/images/devenir_vendeur/2_selected.svg" alt="">
          <label>Information sur le dirigeant</label></span>
      </div>
      <h3>Information sur le dirigeant</h3>
      <h6> Les champs marqués d’une * sont obligatoires</h6>

      <form [formGroup]="dirigeantFormGroup" autocomplete="off"
        [ngClass]="{'formError': dirigeantFormGroup && dirigeantFormGroup.invalid && isDirigeantValid}">
        <mat-form-field class="example-full-width col-12 hadeenInput" appearance="fill" [floatLabel]="'auto'">
          <mat-label>Nom</mat-label>
          <input matInput placeholder="Nom" formControlName="dirigeantCtrlNom" required>
        </mat-form-field>
        <mat-form-field class="example-full-width col-12 hadeenInput" appearance="fill" [floatLabel]="'auto'">
          <mat-label>Prénom</mat-label>
          <input matInput placeholder="Prénom" formControlName="dirigeantCtrlPrenom" required>
        </mat-form-field>

        <mat-form-field class="example-full-width col-12 hadeenInput pb-0" appearance="fill" [floatLabel]="'always'">
          <mat-label>Date de naissance</mat-label>
          <input hidden matInput type="text" formControlName="dirigeantCtrlNaissance">

          <select class="custom-select pl-0" id="inputGroupSelectJours"
            style="border:none; background-color: transparent; width: 75px; margin-right: 15px;"
            (change)="onChange($event.target.value, 'days')" formControlName="dirigeantCtrlNaissanceDay">
            <option value="" disabled selected>Jours</option>
            <option value="{{item}}" *ngFor="let item of jours">{{ item }}</option>
          </select>
          <select class="custom-select pl-0" id="inputGroupSelectMois"
            style="border:none; background-color: transparent; width: 75px; margin-right: 15px;"
            (change)="onChange($event.target.value, 'month')" formControlName="dirigeantCtrlNaissanceMounth">
            <option value="" disabled selected>Mois</option>
            <option value="{{item}}" *ngFor="let item of mois">{{ item }}</option>
          </select>
          <select class="custom-select pl-0" id="inputGroupSelectAnnee"
            style="border:none; background-color: transparent; width: 95px;"
            (change)="onChange($event.target.value, 'year')" formControlName="dirigeantCtrlNaissanceYears">
            <option value="" disabled selected>Année</option>
            <option value="{{item}}" *ngFor="let item of annees">{{ item }}</option>
          </select>
        </mat-form-field>

        <p class="col-12 mb-0" style="font-family: 'Roboto'; color: #707070; font-weight: 300;">Assurez-vous que
          l’adresse
          insérée soit la même que celle inscrite sur la pièce d’identité et/ou passeport du gérant</p>
        <mat-form-field class="example-full-width col-12 hadeenInput" appearance="fill" [floatLabel]="'auto'">
          <mat-label>Adresse</mat-label>

          <input matInput ngx-google-places-autocomplete [options]='options' pattern="[\-.'\/&a-zA-ZÀ-ÿ0-9 ]+"
            (onAddressChange)="handleAddressChange($event, 2)" placeholder="Adresse*"
            formControlName="dirigeantCtrlAdresse" required>
          <mat-error>Caractère non autorisé</mat-error>
        </mat-form-field>
        <mat-form-field class="example-full-width col-12 hadeenInput" appearance="fill" [floatLabel]="'auto'">
          <mat-label>Code postal</mat-label>
          <input matInput placeholder="Code postal" formControlName="dirigeantCtrlCode" required>
        </mat-form-field>
        <mat-form-field class="example-full-width col-12 hadeenInput" appearance="fill" [floatLabel]="'auto'">
          <mat-label>Ville*</mat-label>
          <input matInput placeholder="Ville*" formControlName="dirigeantCtrlVille">
        </mat-form-field>
        <mat-form-field class="example-full-width col-12 hadeenInput" appearance="fill" [floatLabel]="'auto'">
          <mat-label>Pays*</mat-label>
          <input matInput placeholder="Pays*" formControlName="dirigeantCtrlPays">
        </mat-form-field>
        <mat-form-field class="example-full-width col-12 hadeenInput" appearance="fill" [floatLabel]="'auto'">
          <mat-label>Fonction*</mat-label>
          <input matInput placeholder="Fonction*" formControlName="dirigeantCtrlFonction">
        </mat-form-field>
        <mat-form-field class="example-full-width col-12 hadeenInput" appearance="fill" [floatLabel]="'auto'">
          <mat-label>Part de détention de la societé (%)*</mat-label>
          <input matInput (input)="change_part()" appOnlyDigit min="0" type="number"
            placeholder="Part de détention de la societé (%)*" formControlName="dirigeantCtrlPart">
          <mat-error
            *ngIf="dirigeantFormGroup.get('dirigeantCtrlPart').hasError('required') || dirigeantFormGroup.get('dirigeantCtrlPart').hasError('min')">
            Vous devez détenir des parts de la société afin de créer un compte vendeur
          </mat-error>
        </mat-form-field>
        <mat-form-field class="example-full-width col-12 hadeenInput" appearance="fill" [floatLabel]="'auto'">
          <mat-label>Iban*</mat-label>
          <input matInput placeholder="Iban*" formControlName="dirigeantCtrlIban">
        </mat-form-field>
        <div class="col-12">
          <div class="field text-left col-12 hadeenInput pb-2">
            <ngx-intl-tel-input id="numeroDir" [cssClass]="'control-form hadeenInput country-tel-code'"
              [preferredCountries]="[CountryISO.France, CountryISO.UnitedKingdom]" [enableAutoCountrySelect]="true"
              [enablePlaceholder]="true" [searchCountryFlag]="true"
              [searchCountryField]="[SearchCountryField.Iso2, SearchCountryField.Name]" [selectFirstCountry]="false"
              [selectedCountryISO]="CountryISO.France" [maxLength]="15" [tooltipField]="TooltipLabel.Name"
              [phoneValidation]="true" [separateDialCode]="false" name="phoneDir" formControlName="dirigeantCtrlNumero"
              (input)="ngModelChange($event, 'dir')" style="background-color: transparent;">
            </ngx-intl-tel-input>
            <label class="m-0 mt-2" for="numero" style="font-size: 12px;">Numéro de téléphone*</label>
          </div>
        </div>

        <div class="col-12">

          <div class="row m-0">
            <mat-form-field class="example-full-width col-7 col-md-9 p-0 hadeenInput" appearance="fill"
              [floatLabel]="'auto'">
              <mat-label>Kbis*</mat-label>
              <input matInput formControlName="dirigeantCtrlKbis1">
            </mat-form-field>
            <input style="display: none" #fileInput2 type="file" accept='image/*, application/pdf'
              (change)="preview($event, 2)">
            <button mat-flat-button (click)="fileInput2.click()" class="col-5 col-md-3" type="button"
              style="height: 62px; width: fit-content; background-color: #345B2B; border: 1px solid #345B2B; border-radius: 0px; color: #ffffff;">
              Importer fichier
            </button>
          </div>
        </div>
        <div class="col-12">
          <div class="row m-0">
            <mat-form-field class="example-full-width col-7 col-md-9 p-0 hadeenInput" appearance="fill"
              [floatLabel]="'auto'">
              <mat-label>Document d'identité récto*</mat-label>
              <input matInput formControlName="dirigeantCtrlKbis2">
            </mat-form-field>
            <input style="display: none" #fileInput3 type="file" accept='image/*, application/pdf'
              (change)="preview($event, 3)">
            <button mat-flat-button (click)="fileInput3.click()" class="col-5 col-md-3" type="button"
              style="height: 62px; width: fit-content; background-color: #345B2B; border: 1px solid #345B2B; border-radius: 0px; color: #ffffff;">
              Importer fichier
            </button>
          </div>
        </div>
        <div class="col-12">
          <div class="row m-0">
            <mat-form-field class="example-full-width col-7 col-md-9 p-0 hadeenInput" appearance="fill"
              [floatLabel]="'auto'">
              <mat-label>Document d'identité verso</mat-label>
              <input matInput formControlName="dirigeantCtrlKbis3">
            </mat-form-field>
            <input style="display: none" #fileInput4 type="file" accept='image/*, application/pdf'
              (change)="preview($event, 4)">
            <button mat-flat-button (click)="fileInput4.click()" class="col-5 col-md-3" type="button"
              style="height: 62px; width: fit-content; background-color: #345B2B; border: 1px solid #345B2B; border-radius: 0px; color: #ffffff;">
              Importer fichier
            </button>
          </div>
        </div>

        <div class="col-12">
          <button mat-flat-button (click)="step2_file()"
            style="background-color: #000000; border: 1px solid #000000; border-radius: 0px; color: #ffffff;">ÉTAPE
            SUIVANTE</button>
          <h6>En cliquant sur Valider vous acceptez nos conditions générales d’utilisation et les
            <a href="https://stripe.com/fr/connect-account/legal"> conditions d’utilisation de Stripe Connected</a>
          </h6>
        </div>
      </form>

    </ng-template>


    <div *ngIf="etapeVendeur >= 3">
      <h1>Vous etes deja vendeur</h1>
    </div>

  </div>

  <!-- Modal -->
  <div class="modal fade" id="staticBackdrop" data-backdrop="static" data-keyboard="false" tabindex="-1"
    aria-labelledby="staticBackdropLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-body">
          <p class="m-0 text-center">{{ messageTop }}</p>
          <div class="text-center">
            <mat-spinner *ngIf="userService.stateStep === 0" class="m-auto" style="width: 35px; margin-top: -25px;">
            </mat-spinner>
          </div>

          <div class="text-center m-3" *ngIf="userService.stateStep === 1"><img width="auto" height="auto"
              src="../../../../assets/images/logo/refus@2x.png" alt="" style="width: 35px;"></div>

          <div class="text-center m-3" *ngIf="userService.stateStep === 2"><img width="auto" height="auto"
              src="../../../../assets/images/logo/success@2x.png" alt="" style="width: 35px;"></div>

          <p class="m-0 text-center" style="margin-top: -25px;">{{ messageModal }}</p>

          <div class="text-center mt-3">
            <button type="button" *ngIf="userService.stateStep === 1" class="h-btn-green"
              style="padding: 5px 30px; width: fit-content;" (click)="closeModal()">Modifier</button>
            <button type="button" *ngIf="userService.stateStep === 2 && isWeb" class="h-btn-green"
              style="padding: 5px 30px; width: fit-content;" (click)="closeModal(2)">OK</button>
            <a href="app://hadeen.place.fr" *ngIf="userService.stateStep === 2 && !isWeb">
              <button type="button" class="h-btn-green" style="padding: 5px 30px; width: fit-content;">OK</button>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>

  <footer *ngIf="isWeb">
    <hr>
    <!-- <app-ads></app-ads> -->
  </footer>
</div>
