<div class="top-header" #topPanier0 id="topPanier0">
  <div class="container">
    <div class="header">
      <ul class="breadcrumb">
        <li><a>Hadéen-place</a></li>
        <li>panier</li>
      </ul>
    </div>
  </div>
</div>

<div class="pb-3" #topPanier1 id="topPanier1">
  <mat-vertical-stepper class="col-sm-10 col-md-8 col-xl-6 p-0 m-auto" (selectionChange)="resetStepFormValidation($event)" [linear]="true" #stepper style="background-color: transparent;">
    <mat-step state="step1" completed="false" [stepControl]="panierFormGroup">
      <ng-template matStepLabel><h2 class="m-0" style="font-weight: 700; color: #475850; font-size: 1.5rem;">Mon panier</h2></ng-template>
      <form>
        <div class="col-12" style="border: 1px solid #D6D6D6; padding: 2%; margin-bottom: 2%; background-color: #fff;"
          *ngFor="let itemPanier of paniers; let ind = index">
          <div class="row">
            <div class="col-12" *ngIf="itemPanier.liste_achat">
              <p>Le produit est de la liste <span style="font-weight: 600;">{{ itemPanier.liste_achat.event }}: {{
                  itemPanier.liste_achat.title }}</span> de <span style="font-weight: 600;">{{
                  itemPanier.liste_achat.user.pseudo }}</span></p>
            </div>
            <div class="col-3 col-md-2 pr-2" [routerLink]="['/detail-produit/', itemPanier.slug]">
              <img width="auto" height="auto" [src]="itemPanier.images[0]" class="m-md-auto"
                style="max-width: 100%; display: block; height: 99px; object-fit: contain;" alt="">
            </div>
            <div class="col-9 col-md-10">
              <div class="row">
                <div class="col-12 col-md-6" [routerLink]="['/detail-produit/', itemPanier.slug]">
                  <h4 style="word-break: break-word;" [ngClass]="{'h-txt-disabled': itemPanier.sous_produit.disponibilite < 1}">
                    {{
                    itemPanier.sous_produit.produit.model }}</h4>
                  <p *ngIf="itemPanier.sous_produit.disponibilite < 1" class="m-0" style="font-size: 12px; color: #ff0000;">
                    Cet article n'est plus disponible pour le moment
                  </p>
                  <p *ngIf="itemPanier.sous_produit.disponibilite > 0 && itemPanier.quantite < itemPanier.quantiteInitial"
                    class="m-0" style="font-size: 12px; color: #ff0000;">
                    Votre panier a été mise à jour avec la quantité disponible
                  </p>
                </div>
                <div class="col col-md-3 mt-sm-2 mt-lg-0">
                  <h5 [ngClass]="{'h-txt-disabled': itemPanier.sous_produit.disponibilite < 1}">Quantité</h5>
                  <div class="d-flex" style="font-size: 16px;">
                    <button class="rounded-0 w-auto" (click)="moins(itemPanier)" style="border: none; padding: 2px 11px; background-color: F1F1F1; color: #000;">-</button>
                    <p class="my-auto text-center" style="min-width: 30px;">{{ itemPanier.quantite }}</p>
                    <button class="rounded-0 w-auto" (click)="plus(itemPanier)" style="border: none; padding: 2px 10px; background-color: F1F1F1; color: #000;">+</button>
                  </div>
                  <h5 [ngClass]="{'h-txt-disabled': itemPanier.sous_produit.disponibilite < 1}"
                    *ngIf="itemPanier.sous_produit.taille">Taille: {{ itemPanier.sous_produit.taille.valeur }}</h5>
                  <div *ngIf="itemPanier.sous_produit.couleur" style="display: inline-flex; margin-top: 10px;">
                    <span [ngClass]="{'h-txt-disabled': itemPanier.sous_produit.disponibilite < 1}"
                      style="margin-right: 5px;">Couleur: </span>
                    <div style="width: 18px; height: 18px; border: 1px solid #d9d9d9; margin: auto;"
                      [ngStyle]="{'background' : !itemPanier.sous_produit.couleur ? 'url(assets/images/noCouleur.PNG)' : itemPanier.sous_produit.couleur.codeHexa === 'MULTI' ? 'url(assets/images/multicolor.png)' : itemPanier.sous_produit.couleur.codeHexa}">
                    </div>
                  </div>
                </div>
        
                <div class="col col-md-3 text-right">
                  <h3>{{ this.commonService.calculPrix(itemPanier.sous_produit.produit, itemPanier.quantite) | number : '1.2-2'
                    }} €</h3>
                  <a (click)="removePanier(ind, itemPanier.id)" style="text-decoration: underline; color: gray;">Supprimer</a>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-12 p-0 mt-1">
          <table class="w-100"
            style="background-color: #fff; border-left: 1px solid #e9e9e9; border-right: 1px solid #e9e9e9;">
            <tr>
              <td class="py-2" style="font-weight: 700;">Total articles ({{ paniers ? paniers.length : 0 }})</td>
              <td class="price text-right py-2">{{ getTotalArticle() | number : '1.2-2' }} €</td>
            </tr>
            <tr *ngIf="getTotalPromo()>0">
              <td class="py-2">Total Promotion</td>
              <td class="price text-right py-2">-{{ getTotalPromo() | number : '1.2-2' }} €</td>
            </tr>
            <tr class="livraison">
              <td class="py-2">{{fraisLivraisonTotal === 0 ? 'Livraison incluse' : 'Frais de livraison'}}</td>
              <td class="price text-right py-2"><span *ngIf="fraisLivraisonTotal > 0">{{ fraisLivraisonTotal }} €</span></td>
            </tr>
    
            <tr *ngIf="paniers">
              <ng-container *ngIf="addCodePromo; then thenTemplate; else elseTemplate"></ng-container>
              <ng-template #thenTemplate>
                <td colspan="2" class="py-2">
    
                  <button class="h-btn-green-bordered" (click)="addCodePromo = false;">Masquer code promo</button>
                  <div class="input-group mb-3">
                    <input type="text" class="form-control" name="codePromo" placeholder="Entrer le code ici" style="height: 37px;"
                      [(ngModel)]="commonService.codePromo.value">
                    <div class="input-group-append">
                      <button class="h-btn-green-green mt-0" type="button" style="height: 37px;"
                        (click)="validateCodePromo()">Valider</button>
                    </div>
                  </div>
                </td>
              </ng-template>
              <ng-template #elseTemplate>
                <td style="width:60%" class="py-2">
                  <span *ngIf="commonService.codePromo.isValid">Codepromo validé</span>
                  <span *ngIf="!commonService.codePromo.isValid">Vous avez un code promo ou un chèque cadeau?</span>
                </td>
                <td class="py-2 text-right">
                  <button class="h-btn-green rounded-0" style="font-size: 15px; width: auto;" (click)="addCodePromo = true">
                    {{ commonService.codePromo.isValid ? 'CHANGER' : 'AJOUTER'}}
                  </button>
                </td>
              </ng-template>
            </tr>

            <tr>
              <td class="py-2" style="color: #35483E; font-weight: 700;">Total TTC</td>
              <td class="total text-right py-2" style="font-weight: 700;">{{ getTotalArticle() - getTotalPromo() + fraisLivraisonTotal | number : '1.2-2'
                }} €</td>
            </tr>
          </table>
          <button *ngIf="commonService.user" [ngClass]="isInvalidShop ? 'h-btn-disabled rounded-0 w-100 pt-2 pb-2' : 'h-btn-dore rounded-0 w-100 pt-2 pb-2'" [disabled]="isInvalidShop" (click)="goToAdrs()">VALIDER MON PANIER</button>
          <button *ngIf="!commonService.user" (click)="openlogin()" class="h-btn-green rounded-0">VALIDER MON PANIER</button>
        </div>
      </form>
    </mat-step>
    
    <mat-step state="step2" completed="false" [stepControl]="adrsFormGroup">
      <ng-template matStepLabel><h2 class="m-0 d-flex" style="font-weight: 700; color: #475850; font-size: 1.5rem;">Vos coordonnées <span class="d-none d-md-block">&nbsp; pour la livraison</span></h2></ng-template>
      <form>
        <div *ngIf="panierFormGroup.get('panierValid').value">
          <app-adrs-livraison from="step" (adrsLivrEvent)="adrsLivrEvent($event)"></app-adrs-livraison>
        </div>
      </form>
    </mat-step>

    <mat-step state="step3" completed="false">
      <ng-template matStepLabel><h2 class="m-0 w-100" style="font-weight: 700; color: #475850; font-size: 1.5rem;">Payment</h2></ng-template>
      <div *ngIf="adrsFormGroup.get('p_i').value">
        <app-payment from="step" [p_i]="adrsFormGroup.get('p_i').value"></app-payment>
      </div>
    </mat-step>


    <!-- Icon overrides. -->
    <ng-template matStepperIcon="step1">
      <img src="../../../assets/images/step1.png" alt="" style="width: 20px; height: 20px; margin-top: -7px;">
    </ng-template>
    <ng-template matStepperIcon="step2">
      <img src="../../../assets/images/step2.png" alt="" style="width: 20px; height: 16px; margin-top: -3px;">
    </ng-template>
    <ng-template matStepperIcon="step3">
      <img src="../../../assets/images/step3.png" alt="" style="width: 20px; height: 16px; margin-top: -3px;">
    </ng-template>
    
    <ng-template matStepperIcon="edit" let-index="index">
      <img *ngIf="index == 0" src="../../../assets/images/step1.png" alt="" style="width: 20px; height: 20px; margin-top: -7px;">
      <img *ngIf="index == 1" src="../../../assets/images/step2.png" alt="" style="width: 20px; height: 16px; margin-top: -3px;">
      <img *ngIf="index == 2" src="../../../assets/images/step3.png" alt="" style="width: 20px; height: 16px; margin-top: -3px;">
    </ng-template>

  </mat-vertical-stepper>
</div>

<ng-template #loginTemplate>
  <app-login [modal]="chatService.modalRef2" [newsCustomer]="true"></app-login>
</ng-template>
