<mat-card class="testimonial">
  <div class="testimonials__header row">
    <div class="testimonials__header--img" *ngIf="!itemTestimonials.Avatar"></div>
    <img width="auto" height="auto" loading="lazy" class="testimonials__header--img" *ngIf="itemTestimonials.Avatar"
      src="https://strapi.hadeen-place.fr{{ itemTestimonials.Avatar.url }}" [alt]="itemTestimonials.Author" [title]="itemTestimonials.Author">
    <div class="col">
      <div style="display: flex;">
        <span class="testimonials__header--grade">{{ itemTestimonials.Rating}}/5</span>
        <div class="testimonials__header--stars">
          <span class="fa fa-star" style="margin-left: 5px;"
            [ngStyle]="{'color': itemTestimonials.Rating >= 1 ? '#007bff' : '#d4d4d4'}"></span>
          <span class="fa fa-star" style="margin-left: 5px;"
            [ngStyle]="{'color': itemTestimonials.Rating >= 2 ? '#007bff' : '#d4d4d4'}"></span>
          <span class="fa fa-star" style="margin-left: 5px;"
            [ngStyle]="{'color': itemTestimonials.Rating >= 3 ? '#007bff' : '#d4d4d4'}"></span>
          <span class="fa fa-star" style="margin-left: 5px;"
            [ngStyle]="{'color': itemTestimonials.Rating >= 4 ? '#007bff' : '#d4d4d4'}"></span>
          <span class="fa fa-star" style="margin-left: 5px;"
            [ngStyle]="{'color': itemTestimonials.Rating >= 5 ? '#007bff' : '#d4d4d4'}"></span>
        </div>
      </div>

      <p class="testimonials__header--name m-0" style="font-size: 13px; font-weight: 600;">{{ itemTestimonials.Author }}
      </p>
      <p class="testimonials__header--date m-0" style="font-size: 10px; color: #848484;">
        {{ itemTestimonials.Date | date:'d/M/yyyy'}}</p>
    </div>
  </div>

  <p class="testimonial__content" style="color: #707070; font-size: 14px; font-weight: 400; min-height: 72px;">
    <span class="testimonial__content--quote" *ngIf="!itemTestimonials.readmore">
      {{(itemTestimonials.Testimonial.length>150)? (itemTestimonials.Testimonial | slice:0:150)+'
      ...':(itemTestimonials.Testimonial) }}
      <a class="testimonial__content--more" *ngIf="itemTestimonials.Testimonial.length>150"
        (click)="readMore(itemTestimonials)" style="color:#70707080;cursor:pointer;">Show more</a>
    </span>
    <span class="testimonial__content--quote" *ngIf="itemTestimonials.readmore">
      {{itemTestimonials.Testimonial}}
      <a class="testimonial__content--hide" (click)="readMore(itemTestimonials)"
        style="color:#70707080;cursor:pointer;">Hide</a>
    </span>
  </p>
</mat-card>
