<div class="frais-dialog">
    <mat-dialog-content class="mat-typography">
        <div class="text-center" *ngIf="data.isValid == true">
            <p>Nous avons ajouté la fonctionnalité pour la gestion des frais de livraison!</p>
            <p>Actuellement les frais de port de votre compte sont paramétrés en « Inclus dans le prix de vente » pour tous vos produits.</p>
            <p>Si vous souhaitez conserver ce système de frais de port vous n’avez rien à faire (uniquement inclure les frais de port dans le prix de vente lorsque vous mettez en vente vos produits).</p>
            <p>Si vous souhaitez paramétrer vos frais de port merci de vous rendre dans Mon Compte / Paramétrer les frais de port afin de les définir</p>
        </div>

        <div class="text-center" *ngIf="data.isValid == false">
            <p>Votre compte vendeur est en attente de validation!</p>
            <p>Actuellement les frais de port sont paramétrés en « Inclus dans le prix de vente » pour tous vos produits.</p>
            <p>Si vous souhaitez conserver ce système de frais de port vous n’avez rien à faire (uniquement inclure les frais de port dans le prix de vente lorsque vous mettez en vente vos produits).</p>
            <p>Si vous souhaitez paramétrer vos frais de port merci de vous rendre dans Mon Compte / Paramétrer les frais de port afin de les définir</p>
        </div>
    </mat-dialog-content>

    <div mat-dialog-actions>
        <button class="h-btn-black px-3 py-1 m-auto" style="border-radius: 5px;" (click)="defaultFrais()">REVENIR A LA HOME</button>
        <button class="h-btn-black px-3 py-1 m-auto" style="border-radius: 5px;" [routerLink]="['/compte/frais']" (click)="onNoClick()">PARAMÉTRER LES FRAIS DE PORT</button>
    </div>
</div>