import { TemplateResult } from 'select2';
import * as $ from "jquery";

export function formatData(data: any): TemplateResult {
  if (!data.id) {
    return data.text;
  }

  const $result = $(`<span><img src="${data.url}" style="width: 25px;height: 25px;"></span>`);

  return $result;
}
