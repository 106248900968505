<ng-container *ngIf="!cguapp">
  <!--<div class="mb-3 {{fixe}}" (scroll)="onScroll($event)">-->
  <div class="{{fixe}}" (scroll)="onScroll($event)">
    <app-header [type]="fixe"></app-header>

    <div class="container-fluid d-md-block d-none" *ngIf="menusShow && showHeaders" style="background-color: #ffffff; -moz-user-select: none; -webkit-user-select: none; -ms-user-select:none; user-select:none;-o-user-select:none;"
      unselectable="on" onselectstart="return false;" onmousedown="return false;">
      <div class="row bandeau">
        <div class="m-auto py-1 div-menu" style="flex-wrap: nowrap; display: -webkit-box; overflow: auto; cursor: pointer;">
          <div class="m-2" style="width: fit-content; cursor: pointer; font-size: 15px" *ngFor="let itemMenu of menusShow; let i=index"
            [ngClass]=" i === 0 ? 'pl-0' : 'pl-3'" [ngStyle]="{'border-left': i === 0 ? 'none' : '1px solid #848484'}">
            <!-- <span class="ddpMedium" *ngIf="itemMenu.children && itemMenu.children.length > 0"
              [matMenuTriggerFor]="panel.menu" style="display: -webkit-inline-box; font-size: 15px">{{ itemMenu.nom }}
              <app-menu-item #panel [items]="itemMenu.children" [univer]="itemMenu"></app-menu-item>
            </span>
            <span class="ddpMedium" style="font-size: 15px;" *ngIf="!itemMenu.sous_univers || itemMenu.sous_univers.length === 0">{{ itemMenu.nom }}</span> -->
            <span class="ddpMedium" style="font-size: 15px;" (click)="goTo(itemMenu)">{{ itemMenu.nom }}</span>
          </div>
          <div class="m-2" style="width: fit-content; cursor: pointer; font-size: 15px; border-left: 1px solid #848484">
            <span style="margin-left: 5px; margin-right: 10px; margin-top: 1px; cursor: pointer; font-size: 15px" [routerLink]="'/Blog'">&nbsp; Blog</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-container>

<div style="background-color: '#F6FAF0';" id="topofpage">
  <router-outlet (activate)="onActivate($event)"></router-outlet>
  <div style="margin-bottom: -45px;z-index: 0;position: sticky;">
    <app-ads></app-ads>
  </div>
</div>

<app-footer *ngIf="!cguapp"></app-footer>
