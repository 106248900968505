<div class="top-header" style="background-color: #F4F4F4;">

  <div style="background-color: #f9f9f9; padding-top: 5%; padding-bottom: 3%;">
    <div class="container" *ngIf="product">
      <div class="row">
        <div class="col-md-7 col-lg-7 col-sm-12">
          <a class="uk-button uk-button-default w-100" href="#modal-full" uk-toggle style="border: none;">
            <!--toggle Modal-->
            <img width="auto" height="auto" [src]="imgPle" class="w-100"
              style="margin-bottom: 5%; height: 420px; object-fit: contain;" alt="">
          </a>
          <div id="modal-full" class="uk-modal-full" uk-modal>
            <!--debut modal-->
            <div class="uk-modal-dialog">
              <button class="uk-modal-close-full uk-background-secondary btn-close-slider" type="button"
                uk-close></button>
              <div class="uk-grid-collapse uk-child-width uk-flex-middle" uk-grid>
                <div class="uk-background-cover uk-background-secondary" uk-height-viewport>
                  <!--debut slider-->
                  <div class="uk-position-relative uk-visible-toggle uk-light" tabindex="-1"
                    uk-slideshow="animation: push">
                    <ul class="uk-slideshow-items" uk-height-viewport>
                      <li *ngFor="let item of product.images" class="carroussel">
                        <div class="uk-cover-container">
                          <img width="auto" height="auto" [src]="item.nom" alt="" class="image">
                        </div>
                      </li>
                    </ul>
                    <a class="uk-position-center-left uk-position-small uk-hidden-hover" href="#" uk-slidenav-previous
                      uk-slideshow-item="previous"></a>
                    <a class="uk-position-center-right uk-position-small uk-hidden-hover" href="#" uk-slidenav-next
                      uk-slideshow-item="next"></a>
                  </div>
                  <!--fin slider-->
                </div>
              </div>
            </div>
          </div>
          <!--fin modal-->
          <!-- <div> -->
          <div uk-slider>
            <div class="uk-position-relative">
              <div class="uk-slider-container uk-light">
                <ul class="uk-slider-items uk-child-width-1-3 uk-child-width-1-4@s uk-child-width-1-5@m uk-grid-small">
                  <li (click)="setImgPle(item)" *ngFor="let item of product.images">
                    <img width="auto" height="auto" [src]="item.nom" alt="" style="height: 120px; width: 100%; height: 100%; object-fit: contain;">
                  </li>
                </ul>
              </div>
              <div class="uk-hidden@s uk-light">
                <a class="uk-position-center-left uk-position-small" href="#" uk-slidenav-previous
                  uk-slider-item="previous"></a>
                <a class="uk-position-center-right uk-position-small" href="#" uk-slidenav-next
                  uk-slider-item="next"></a>
              </div>
              <div class="uk-visible@s">
                <a class="uk-position-center-left-out uk-position-small" href="#" uk-slidenav-previous
                  uk-slider-item="previous"></a>
                <a class="uk-position-center-right-out uk-position-small" href="#" uk-slidenav-next
                  uk-slider-item="next"></a>
              </div>
            </div>
            <ul class="uk-slider-nav uk-dotnav uk-flex-center uk-margin"></ul>

          </div>
          <!-- </div> -->
        </div>
        <div class="col-md-5 col-lg-5 col-sm-12">
          <h1>{{ product.produit.model }}</h1>
          <div class="m-auto" *ngIf="product.couleur">
            <h4>Couleur </h4>
            <div class="image-card row ml-0" style="margin-bottom: 6%;">
              <div style="width: 1.7rem; height: 1.7rem; display: inline-block; margin-right: 5%; padding: 1.2px;">
                <div class="w-100 h-100"
                  [ngStyle]="{'background' : !product.couleur.code_hexa ? 'url(assets/images/noCouleur.PNG)' : product.couleur.code_hexa === 'MULTI' ? 'url(assets/images/multicolor.png)' : product.couleur.code_hexa}">
                </div>
              </div>
            </div>
          </div>

          <div *ngIf="product.taille">
            <h4>Taille</h4>
            <div class="row">
              <h4 class="selectSize" style="border: 1px solid #E6E6E6; cursor: pointer;">
                {{ product.taille.valeur }}</h4>
            </div>
          </div>

          <p class="pt-2 pr-2 pb-2">
            <span style="background-color: #DBFBE4; padding: 2px 6px 4px 4px; color: #345B2B;">
              <img width="auto" height="auto" src="assets/images/logo/checkGreen.png" alt="">
              {{ product.disponibilite > 0 ? 'En stock': 'En rupture' }}</span>
          </p>

          <div class="m-auto">
            <h4 style="margin: 5% 0;">
              Quantité: <span style="font-size: 16px;">
                {{ product.disponibilite_liste_achat }}
              </span>
            </h4>
            <h5>
              <span style="text-decoration: line-through; margin-right: 10px;" *ngIf="product.prix_promotion > 0">{{
                product.produit.prix }} €</span>
              <span style="font-size: 30px; color: #345B2B; font-family: 'SFCOmpact Semibold';">{{
                product.produit.prix_promotion > 0 ? product.produit.prix_promotion : product.produit.prix }}
                €</span>
            </h5>
          </div>

          <button class="chart mt-3" style="width: 100%; padding: 5px;" (click)="ajoutPanierV2()">AJOUTER AU
            PANIER</button>

        </div>
      </div>
    </div>
  </div>
</div>


<!-- <app-ads></app-ads> -->
