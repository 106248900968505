import { isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class StorageService {

  constructor(@Inject(PLATFORM_ID) private platformId) { }

  getItem(name) {
    if (isPlatformBrowser(this.platformId)) {
      return localStorage.getItem(name);
    }
  }
  setItem(name, data) {
    if (isPlatformBrowser(this.platformId)) {
      return localStorage.setItem(name, data);
    } 
  }

  removeItem(name) {
    if (isPlatformBrowser(this.platformId)) {
      localStorage.removeItem(name);
    }
  }
}
