<div class="spinner" style="position: fixed; top: 40%; left: 43%; z-index: 1;" *ngIf="showSpinner">
  <mat-spinner></mat-spinner>
</div>

<div class="top-header">
  <img width="auto" height="auto" src="assets/images/devenir.jpg" class="bg-img w-100" alt="">
  <div class="container text-image">
    <div class="header">
      <ul class="breadcrumb m-0">
        <li><a routerLinkActive="active" [routerLinkActiveOptions]="{exact:true}" routerLink="/">Hadéen-place</a></li>
        <li>Creation compte vendeur</li>
      </ul>
    </div>
    <div class="d-none d-md-flex"
      style="background-color: rgba(255, 255, 255, 0.7); padding: 10px; border-radius: 10px; border: 1px solid #e9e9e9;">
      <div>
        <h1 class="text-center">DEVENIR VENDEUR</h1>
        <h6>Vous souhaitez rejoindre la communauté d'Hadéen-Place et vendre vos articles sur notre site web /
          application?
          <br><br> Vous disposerez d'un nombre d'annonces illimitées et d'une e-boutique rassemblant tous vos produits,
          service de paiement sécurisé, visibilité de vos articles sur notre site web et application, possibilité de
          mettre en avant
          votre vitrine ou tous vos articles en créant vous-même votre publicité défilante, gestion de vos commandes
          directement via votre compte vendeur et la réception de votre rémunération directement sur votre compte
          bancaire.
          <br><br> Si vous ne vendez rien = vous ne payez rien.
          <br><br> Pas d'abonnement et pas besoin de masque !!!!!
        </h6>
      </div>
    </div>
  </div>
</div>
<div class="container-fluid">
  <div class="col-12 d-block d-md-none mt-3">
    <h1 class="text-center w-100">DEVENIR VENDEUR</h1>
    <h6 class="text-center">Vous souhaitez rejoindre la communauté d'Hadéen-Place et vendre vos articles sur notre site
      web / application?
      <br><br> Vous disposerez d'un nombre d'annonces illimitées et d'une e-boutique rassemblant tous vos produits,
      service de paiement sécurisé, visibilité de vos articles sur notre site web et application, possibilité de mettre
      en avant votre
      vitrine ou tous vos articles en créant vous-même votre publicité défilante, gestion de vos commandes directement
      via votre compte vendeur et la réception de votre rémunération directement sur votre compte bancaire.
      <br><br> Si vous ne vendez rien = vous ne payez rien.
      <br><br> Pas d'abonnement et pas besoin de masque !!!!!
    </h6>
  </div>
</div>

<hr>

<div class="container web mt-5">
  <div class="row">
    <div class="signin col-md-6 col-lg-6 col-sm-12">
      <h5 class="text-justify">Je suis nouveau</h5>
      <h6 class="text-center mt-3 mb-2">Créez un compte avec</h6>
      <div class="row sociallogin">
        <div class="col-12 col-md-6 col-sm-6 col-lg-6 text-center"><button class="w-100"
            (click)="creationCompteGoogle()"><img width="auto" height="auto" src="assets/static/g.PNG"
              style="height:16px" alt=""><span>avec
              Google</span></button> </div>
        <div class="col-12 col-md-6 text-center"><button class="w-100" (click)="creerCompteViaFB()"><img width="auto" height="auto"
              src="assets/static/f.PNG" alt=""><span>avec Facebook</span></button></div>
        <div class="col-12 mt-4 mb-3">
          <h6>- ou -</h6>
        </div>
      </div>
      <form [formGroup]="singInForm">
        <div class="row">
          <div class="col-6 col-md-6 col-sm-6 col-lg-6 text-center " (click)="selectSexe()"><a
              class="w-100 btn {{MselectSexe}}">M</a> </div>
          <div class="col-6 col-md-6 col-sm-6 col-lg-6 text-center " (click)="selectSexe()"><a
              class="w-100 btn {{FselectSexe}}">Mme</a></div>
          <div class="col-12">
            <mat-form-field class="example-full-width w-100 hadeenInput" appearance="fill" [floatLabel]="'auto'">
              <mat-label>Prénom</mat-label>
              <input matInput name="prenom" id="prenom_ldv" placeholder="Prénom" formControlName="firstname" required>
            </mat-form-field>
            <mat-form-field class="example-full-width w-100 hadeenInput" appearance="fill" [floatLabel]="'auto'">
              <mat-label>Nom</mat-label>
              <input matInput name="nom" id="nom_ldv" placeholder="Prénom" formControlName="name" required>
            </mat-form-field>
            <mat-form-field class="example-full-width w-100 hadeenInput" appearance="fill" [floatLabel]="'auto'">
              <mat-label>Votre email</mat-label>
              <input matInput name="email" id="email_ldv" placeholder="Ex. hadeen@exemple.com" formControlName="email"
                required>
            </mat-form-field>
            <mat-form-field class="example-full-width w-100 hadeenInput" appearance="fill" [floatLabel]="'auto'">
              <mat-label>Votre mot de passe</mat-label>
              <input matInput [type]="hide ? 'password' : 'text'" name="password" id="password" placeholder="Prénom"
                formControlName="password" required>
              <button mat-icon-button matSuffix (click)="hide = !hide" [attr.aria-label]="'Hide password'"
                [attr.aria-pressed]="hide">
                <mat-icon>{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
              </button>
            </mat-form-field>
            <div>
              <p style="text-align: justify;font-size: 12px;float: inherit;">Le mot de passe doit contenir au moins une
                Majuscule, une minuscule et un chiffre</p>
              <h6 style="    text-align: justify;">Les champs marqués d’une * sont obligatoires</h6>
              <div class="checking">
                <label class="control control-checkbox ">Je souhaite recevoir par e-mail les remises et astuces de la
                  Brigade Hadéen-place
                  <input type="checkbox" id="emailnewsletter" name="filter" formControlName="newsletter"
                    (change)="checknewl($event)" />
                  <div class="control_indicator"></div>
                </label>
              </div>
              <div class="checking">
                <label class="control control-checkbox ">J'accepte les conditions générales et la
                  <a [routerLink]="[ '/conditions-de-ventes' ]" style="color: #000000;">politique de confidentialité</a>
                  <input type="checkbox" id="cgu" name="cgu" formControlName="condition" readonly />
                  <div class="control_indicator"></div>
                </label>
              </div>
            </div>
            <button class="signin" type="submit" (click)="createAccount()">Créer mon compte</button>
          </div>
        </div>
      </form>
    </div>

    <div class="signin col-md-6 col-lg-6 col-sm-12">
      <h5 class="text-justify">J'ai déjà un compte</h5>
      <h6 class="text-center mt-3 mb-2">Se connecter avec votre compte</h6>
      <div class="row sociallogin">
        <div class="col-12 col-md-6 col-sm-6 col-lg-6 text-center"><button class="w-100"
            (click)="connecterViaGoogle()"><img width="auto" height="auto" src="assets/static/g.PNG" style="height:16px" alt=""><span>avec
              Google</span></button> </div>
        <div class="col-12 col-md-6 text-center"><button class="w-100" (click)="connecterViaFB()"><img width="auto" height="auto"
              src="assets/static/f.PNG" alt=""><span>avec Facebook</span></button></div>
        <div class="col-12 mt-4 mb-3">
          <h6>- ou -</h6>
        </div>
      </div>
      <!-- <form action=""> -->
      <div class="row" style="margin-top: 2%;">
        <div class="col-12">
          <mat-form-field class="example-full-width w-100 hadeenInput" appearance="fill" [floatLabel]="'auto'">
            <mat-label>Votre email</mat-label>
            <input matInput name="votreemail1" id="votreemail1" type="email" placeholder="email"
              [(ngModel)]="votreEmail" required>
          </mat-form-field>
          <mat-form-field class="example-full-width w-100 hadeenInput" appearance="fill">
            <mat-label>Votre mot de passe</mat-label>
            <input matInput name="mdp" id="mdp2" [type]="hide ? 'password' : 'text'" [(ngModel)]="mdp">
            <button mat-icon-button matSuffix (click)="hide = !hide" [attr.aria-label]="'Hide password'"
              [attr.aria-pressed]="hide">
              <mat-icon>{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
            </button>
          </mat-form-field>
          <p style="cursor: pointer;" [routerLink]="['/reset-mdp']">Mot de passe oublié ?</p>
          <br>
          <div class="checking">
            <label class="control control-checkbox ">Rester connecté
              <input type="checkbox" id="beSellerID" name="filter" />
              <div class="control_indicator"></div>
            </label>
          </div>
          <button class="signin" (click)="signIn(votreEmail, mdp)">Se connecter avec votre compte</button>
        </div>
      </div>
      <!-- </form> -->
    </div>
  </div>
</div>

<div class="mobile">
  <mat-tab-group mat-align-tabs="start">
    <mat-tab label="Je suis nouveau">
      <div class="container">
        <div class="signin  col-sm-12">
          <h5 class="text-justify">Je suis nouveau</h5>
          <h6 class="text-center">Créez un compte avec</h6>
          <div class="row sociallogin">
            <div class="col-12 col-md-6 col-sm-6 col-lg-6 text-center">
              <button class="w-100" (click)="creationCompteGoogle()"><img width="auto" height="auto" src="assets/static/g.PNG" style="height:16px"
                  alt="">
                <span>avec Google</span></button>
            </div>
            <div class="col-12 col-md-6 text-center">
              <button class="w-100" (click)="creerCompteViaFB()"><img width="auto" height="auto" src="assets/static/f.PNG" alt=""><span>avec
                  Facebook</span></button>
            </div>
            <div class="col-12">
              <h6>- ou -</h6>
            </div>
          </div>

          <form [formGroup]="singInForm">
            <div class="row">
              <div class="col-6 col-md-6 col-sm-6 col-lg-6 text-center " (click)="selectSexe()"><a
                  class="w-100 btn {{MselectSexe}}">M</a> </div>
              <div class="col-6 col-md-6 col-sm-6 col-lg-6 text-center " (click)="selectSexe()"><a
                  class="w-100 btn {{FselectSexe}}">Mme</a></div>
              <div class="col-12">
                <mat-form-field class="example-full-width w-100 hadeenInput" appearance="fill" [floatLabel]="'auto'">
                  <mat-label>Prénom</mat-label>
                  <input matInput name="prenom" id="prenomMob" placeholder="Prénom" formControlName="firstname"
                    required>
                </mat-form-field>
                <mat-form-field class="example-full-width w-100 hadeenInput" appearance="fill" [floatLabel]="'auto'">
                  <mat-label>Nom</mat-label>
                  <input matInput name="nom" id="nomMob" placeholder="Prénom" formControlName="name" required>
                </mat-form-field>
                <mat-form-field class="example-full-width w-100 hadeenInput" appearance="fill" [floatLabel]="'auto'">
                  <mat-label>Votre email</mat-label>
                  <input matInput name="email" id="emailMob" placeholder="Ex. hadeen@exemple.com"
                    formControlName="email" required>
                </mat-form-field>
                <mat-form-field class="example-full-width w-100 hadeenInput" appearance="fill" [floatLabel]="'auto'">
                  <mat-label>Votre mot de passe</mat-label>
                  <input matInput [type]="hide ? 'password' : 'text'" name="password" id="passwordMob"
                    placeholder="Prénom" formControlName="password" required>
                  <button mat-icon-button matSuffix (click)="hide = !hide" [attr.aria-label]="'Hide password'"
                    [attr.aria-pressed]="hide">
                    <mat-icon>{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
                  </button>
                </mat-form-field>
                <div>
                  <p style="text-align: justify;font-size: 12px;float: inherit;">Le mot de passe doit contenir au moins
                    une Majuscule, une minuscule et un chiffre</p>
                  <h6 style="    text-align: justify;">Les champs marqués d’une * sont obligatoires</h6>
                  <div class="checking">
                    <label class="control control-checkbox ">Je souhaite recevoir par e-mail les remises et astuces de
                      la Brigade Hadéen-place
                      <input type="checkbox" id="emailNewsletterMob" name="filter" formControlName="newsletter"
                        (change)="checknewl($event)" />
                      <div class="control_indicator"></div>
                    </label>
                  </div>
                  <div class="checking">
                    <label class="control control-checkbox ">J'accepte les conditions générales et la
                      <a [routerLink]="[ '/conditions-de-ventes' ]" style="color: #000000;">politique de
                        confidentialité</a>
                      <input type="checkbox" id="cguMob" name="cgu" formControlName="condition" readonly />
                      <div class="control_indicator"></div>
                    </label>
                  </div>
                </div>
                <button class="signin" type="submit" (click)="createAccount()">Créer mon compte</button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </mat-tab>
    <mat-tab label="J'ai déjà un compte">
      <div class="container">
        <div class="signin col-sm-12">
          <h5 class="text-justify">J'ai déjà un compte</h5>
          <h6 class="text-center">Se connecter avec votre compte</h6>
          <div class="row sociallogin" style="margin-top: 15%;">
            <div class="col-12 col-md-6 col-sm-6 col-lg-6 text-center"><button class="w-100"
                (click)="connecterViaGoogle()"><img width="auto" height="auto" src="assets/static/g.PNG" style="height:16px" alt=""><span>avec
                  Google</span></button> </div>
            <div class="col-12 col-md-6 text-center"><button class="w-100" (click)="connecterViaFB()"><img width="auto" height="auto"
                  src="assets/static/f.PNG" alt=""><span>avec Facebook</span></button></div>
            <div class="col-12">
              <h6>- ou -</h6>
            </div>
          </div>
          <form class="row">
            <div class="col-12">
              <mat-form-field class="example-full-width w-100 hadeenInput" appearance="fill" [floatLabel]="'auto'">
                <mat-label>Votre email</mat-label>
                <input matInput name="votreemailM" id="votreemailM" type="email" placeholder="email"
                  [(ngModel)]="votreEmail" required>
              </mat-form-field>
              <mat-form-field class="example-full-width w-100 hadeenInput" appearance="fill">
                <mat-label>Votre mot de passe</mat-label>
                <input matInput name="mdpM" id="mdpM" [type]="hide ? 'password' : 'text'" [(ngModel)]="mdp">
                <button mat-icon-button matSuffix (click)="hide = !hide" [attr.aria-label]="'Hide password'"
                  [attr.aria-pressed]="hide">
                  <mat-icon>{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
                </button>
              </mat-form-field>
              <p style="cursor: pointer;" [routerLink]="['/reset-mdp']">Mot de passe oublié ?</p>
              <br>
              <div class="checking">
                <label class="control control-checkbox ">Rester connecté
                  <input type="checkbox" id="beSellerIDM" name="filter" />
                  <div class="control_indicator"></div>
                </label>
              </div>
              <button class="signin" (click)="signIn(votreEmail, mdp)">Se connecter avec votre compte</button>
            </div>
          </form>
        </div>
      </div>
    </mat-tab>
  </mat-tab-group>
</div>

<app-download></app-download>
