import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'univerPipe'
})
export class UniverPipePipe implements PipeTransform {

  transform(value: string): string {
    return value.split("-").slice(1).join(" ");
  }

}
