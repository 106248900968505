import { isPlatformBrowser } from '@angular/common';
import { Component, OnInit, Input, Inject, PLATFORM_ID } from '@angular/core';


declare var $;

@Component({
  selector: 'app-heartstroke',
  templateUrl: './heartstroke.component.html',
  styleUrls: ['./heartstroke.component.scss'],
  host: {
    '(window:resize)': 'onResize($event)'
  }
})


export class HeartstrokeComponent implements OnInit {

  @Input() products;
  productsToShow = [];

  constructor(@Inject(PLATFORM_ID) private platformId) { }

  ngOnInit(): void {
    setTimeout(() => {
      this.updateItemsToShow();
    }, 5000);
  }

  updateItemsToShow() {
    if (isPlatformBrowser(this.platformId)) {
      var itemPerPage = (window.innerWidth > 767) ? 5 : 2;
      var slideNumber = Math.ceil(this.products.length / itemPerPage);
      this.productsToShow = [];
      for (var i = 1; i <= slideNumber; i++) {
        this.productsToShow.push({ products: this.products.slice(i * itemPerPage - itemPerPage, i * itemPerPage) });
      }
    }
  }

  onResize(event) {
    this.updateItemsToShow();
  }

}
