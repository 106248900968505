import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-panier-new',
  templateUrl: './panier-new.component.html',
  styleUrls: ['./panier-new.component.scss']
})
export class PanierNewComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

  test($event) {
    console.log('ceci est andsahfds', $event);
  }
}
