import { Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { AuthServices } from 'src/app/services/auth.service';
import { ToastrService } from 'ngx-toastr';
import { AuthService, GoogleLoginProvider, FacebookLoginProvider } from 'angularx-social-login';
import { BehaviorSubject } from 'rxjs';
import { Router } from '@angular/router';
import { MenuListService } from 'src/app/services/menu-list.service';
import { CommonService } from 'src/app/services/common.service';
import { ChatService } from 'src/app/services/chat.service';
import { isPlatformBrowser } from '@angular/common';
import { StorageService } from 'src/app/services/storage.service';

declare var pintrk;
@Component({
  selector: 'app-login-devenir-vendeur',
  templateUrl: './login-devenir-vendeur.component.html',
  styleUrls: ['./login-devenir-vendeur.component.scss', '../../header/header.component.scss' ]
})
export class LoginDevenirVendeurComponent implements OnInit {
  formRegister: FormGroup;
  newsL = true;
  name: any;
  currentUserSubject: BehaviorSubject<any>;
  sexe = 'HOMME';
  signe = 'password';
  faLogin = 'fa-eye';
  MselectSexe = 'selectedSexe';
  FselectSexe = 'unselectedSexe';
  votreEmail: string;
  mdp: string;
  currentUser: any;
  pwRegister: any;
  nomRegister: any;
  prenomRegister: any;
  emailRegister: any;
  hide = true;
  hideReg = true;
  messageText;
  showSpinner = false;

  user;
  gapiSetup;
  authInstance;
  error;

  singInForm = new FormGroup({
    name: new FormControl('', [Validators.required]),
    firstname: new FormControl('', [Validators.required]),
    email: new FormControl('', [Validators.required, Validators.email]),
    password: new FormControl('', [
      Validators.required,
      Validators.pattern('(?=\\D*\\d)(?=[^a-z]*[a-z])(?=[^A-Z]*[A-Z]).{8,30}')
    ]),
    newsletter: new FormControl(true),
    condition: new FormControl(true, [Validators.requiredTrue])
  });

  constructor(
    @Inject(PLATFORM_ID) private platformId: any,
    private formBuilder: FormBuilder,
    private auth: AuthServices,
    private toastr: ToastrService,
    private authService: AuthService,
    private chatService: ChatService,
    private router: Router, private commonService: CommonService,
    private MenuListServ: MenuListService, private storageService: StorageService
  ) {
    this.currentUserSubject = new BehaviorSubject<any>(this.storageService.getItem('Token'));
    // this.currentUser = this.currentUserSubject.asObservable();
    // this.currentUser = localStorage.getItem('User');
    this.currentUser = this.storageService.getItem('User');
    if (this.currentUser) {
      this.router.navigate(['/compte']);
    }
  }

  createformRegister() {
    this.formRegister = this.formBuilder.group({
      nom: ['', Validators.required],
      prenom: ['', Validators.required],
      email: ['', Validators.compose([
        Validators.required,
        this.checkMail
      ])],
      password: ['', Validators.compose([
        Validators.required,
        this.checkpw
      ])]
    });
  }

  checkMail(controls) {
    const regExp = new RegExp(/\S+@\S+\.\S+/);
    if (regExp.test(controls.value)) {
      return null;
    } else {
      return { checkMail: true };
    }
  }

  createsignInWithGoogle(): void {
    this.authService.signIn(GoogleLoginProvider.PROVIDER_ID).then(googleUSer => {
      this.createAccountGoogle(googleUSer.lastName, googleUSer.firstName, googleUSer.email, googleUSer.id, 'HOMME');
    });
  }

  signInWithGoogle() {
    this.authService.signIn(GoogleLoginProvider.PROVIDER_ID).then(googleUSer => {
      this.signGoogle(googleUSer.email, googleUSer.id)
    });
  }

  signGoogle(email, pw) {
    const dataGOogle = new FormData();
    dataGOogle.set('email', email);
    dataGOogle.set('password', pw);
    dataGOogle.set('plateforme', 'HADEEN');
    this.auth.signIn(dataGOogle).subscribe((respons: any) => {
      if (respons.status === 200) {
        this.toastr.success('Bienvenue  ' + respons.data.user.prenom, '', {
          timeOut: 1000
        });
        this.MenuListServ.setName([respons.data.user.prenom]);
        this.auth.storeUserData(JSON.stringify(respons.data.user), respons.data.token);
        this.currentUserSubject.next(respons.data.token);
        return respons.data.token;
      }
    },
      err => {
        this.toastr.error('Erreur de connexion', '', {
          timeOut: 2000
        });
      });
  }

  signInWithFB(): void {
    this.authService.signIn(FacebookLoginProvider.PROVIDER_ID).then(socialUser => {
      const a = socialUser.authToken;
      this.storageService.setItem('Token', a);
      const data = new FormData();
      data.set('access_token', a);
      data.set('plateforme', 'HADEEN');
      this.auth.signUpfb(data).subscribe((rsp: any) => {
        const data3 = new FormData();
        data3.set('access_token', this.storageService.getItem('Token'));
        data3.set('plateforme', 'HADEEN');
        this.auth.loginFb(data3).subscribe((respons: any) => {
          this.toastr.success('Votre compte à été crée', '', {
            timeOut: 1000
          });
          if (respons.status === 200) {
            this.toastr.success('Bienvenue  ' + respons.data.user.prenom, '', {
              timeOut: 1000
            });
            // this.MenuListServ.setMenu(['Devenir Vendeur', 'Votre compte', 'Vos commandes', 'Favoris', 'Vos messages', 'Se déconnecter']);
            this.MenuListServ.setMenu(['Votre compte', 'Vos commandes', 'Favoris', 'Vos messages', 'Se déconnecter']);
            this.MenuListServ.setName([respons.data.user.prenom]);
            this.currentUserSubject.next(respons.data.token);
            return respons.data.token;
          }
        });
      },
        err => {
          if (err.includes('400')) {
            const data2 = new FormData();
            data2.set('access_token', this.storageService.getItem('Token'));
            data2.set('plateforme', 'HADEEN');
            this.auth.loginFb(data2).subscribe((respons: any) => {
              if (respons.status === 200) {
                this.toastr.success('Bienvenue  ' + respons.data.user.prenom, '', {
                  timeOut: 1000
                });
                this.MenuListServ.setName([respons.data.user.prenom]);
                this.currentUserSubject.next(respons.data.token);
                return respons.data.token;
              }
            });
          } else {
            this.toastr.error('Une erreur est survenu', '', {
              timeOut: 1000
            });
          }
        });
    });
  }

  createAccount() {
    if (this.singInForm.valid) {
      let errMsg = 'Erreur de connexion';
      this.showSpinner = true;
      const singInFormValue = this.singInForm.value;
      const data = new FormData();
      data.set('nom', singInFormValue.name);
      data.set('prenom', singInFormValue.firstname);
      data.set('email', singInFormValue.email);
      data.set('password', singInFormValue.password);
      data.set('recevoir_email', singInFormValue.newsletter);
      data.set('device_type', 'WEB');
      data.set('sexe', this.sexe);
      data.set('plateforme', 'HADEEN');
      // new Response(data).text().then(console.log);

      this.auth.register(data).subscribe(resp => {
        pintrk('track', 'signup');
        const dataLogin = new FormData();
        dataLogin.set('email', singInFormValue.email);
        dataLogin.set('password', singInFormValue.password);
        dataLogin.set('plateforme', 'HADEEN');
        this.auth.signIn(dataLogin).subscribe((respons: any) => {
          if (respons.status === 200) {
            this.toastr.success('Bienvenue  ' + respons.data.user.prenom, '', {
              timeOut: 1000
            });
            this.auth.storeUserData(JSON.stringify(respons.data.user), respons.data.token);
            this.MenuListServ.setName([respons.data.user.prenom]);
            this.currentUserSubject.next(respons.data.token);
            this.commonService.user = resp.data.user;
            this.MenuListServ.setMenuList();
            this.showSpinner = false;
            this.router.navigate(['/devenir-vendeur']);
            return respons.data.token;
          } else {
            this.toastr.error(errMsg, '', {
              timeOut: 2000
            });
            this.showSpinner = false;
          }
        },
        (err) => {
          if (err.error.data?.Utilisateur) {
            // errMsg = err.error.data.Utilisateur;
            errMsg = "Adresse e-mail déjà associée à un utilisateur";
          }
          this.toastr.error(errMsg, '', {
            timeOut: 2000
          });
          this.showSpinner = false;
        });
      },
      (err) => {
        if (err.error.data?.Utilisateur) {
          // errMsg = err.error.data.Utilisateur;
          errMsg = "Adresse e-mail déjà associée à un utilisateur";
        }
        this.toastr.error(errMsg, '', {
          timeOut: 2000
        });
        this.showSpinner = false;
      });
      new Response(data).text().then(console.log);
    } else {
      this.toastr.error('Merci de vérifier le formulaire', '', {
        timeOut: 2000
      });
    }
  }

  createAccountGoogle(nom, prenom, email, pw, sexe) {
    const data = new FormData();
    data.set('nom', nom);
    data.set('prenom', prenom);
    data.set('email', email);
    data.set('password', pw);
    data.set('recevoir_email', true.toString());
    data.set('device_type', 'WEB');
    data.set('sexe', sexe);
    data.set('plateforme', 'HADEEN');
    new Response(data).text().then(console.log);
    this.auth.authStep1(data).subscribe(res => {
      // console.log(res);
    });
    this.auth.register(data).subscribe((resp: any) => {
      pintrk('track', 'signup');
      const dataLogin = new FormData();
      dataLogin.set('email', email);
      dataLogin.set('password', pw);
      dataLogin.set('plateforme', 'HADEEN');
      this.auth.signIn(dataLogin).subscribe((respons: any) => {
        if (respons.status === 200) {
          this.toastr.success('Bienvenue  ' + respons.data.user.prenom, '', {
            timeOut: 1000
          });
          this.MenuListServ.setName([respons.data.user.prenom]);
          this.commonService.user = resp.data.user;
          this.MenuListServ.setMenuList();
          this.router.navigate(['/devenir-vendeur']);
          this.auth.storeUserData(JSON.stringify(respons.data.user), respons.data.token);
          this.currentUserSubject.next(respons.data.token);
          return respons.data.token;
        }
      },
        err => {
          this.toastr.error('Erreur de connexion', '', {
            timeOut: 2000
          });
        });
    },
      err => {
        console.log('error: ', err);
        
        this.toastr.error('Erreur de création de compte', '', {
          timeOut: 2000
        });
      });
  }

  signIn(votreEmail, mdp) {
    this.showSpinner = true;
    const data = new FormData();
    data.set('email', votreEmail);
    data.set('password', mdp);
    data.set('plateforme', 'HADEEN');
    /* new Response(data).text().then(console.log); */
    this.auth.signIn(data).subscribe((resp: any) => {
      if (resp.status === 200) {
        this.toastr.success('Bienvenue' + resp.data.user.prenom, '', {
          timeOut: 1000
        });
        this.MenuListServ.setName([resp.data.user.prenom]);
        this.auth.storeUserData(JSON.stringify(resp.data.user), resp.data.token);
        this.currentUserSubject.next(resp.data.token);
        this.commonService.user = resp.data.user;
        this.MenuListServ.setMenuList();
        if (isPlatformBrowser(this.platformId)) {
          window.scrollTo(0, 0);
        }
        this.chatService.testConnectionSOcket();
        this.router.navigate(['/compte']);
        return resp.data.token;
      }
      this.showSpinner = false;
    },
      err => {
        this.toastr.error('Erreur de connexion', '', {
          timeOut: 2000
        });
        this.showSpinner = false;
      });
  }

  checkpw(controls) {
    const regExp = new RegExp(/^[a+A-Z+0-9]+$/);
    if (regExp.test(controls.value)) {
      return null;
    } else {
      return { checkPseudo: true };
    }
  }

  checknewl(ev) {
    if (ev.target.checked === true) {
      this.newsL = true;
    } else {
      this.newsL = false;
    }
  }

  selectSexe() {
    if (this.MselectSexe === 'selectedSexe' && this.FselectSexe === 'unselectedSexe') {
      this.FselectSexe = 'selectedSexe';
      this.MselectSexe = 'unselectedSexe';
      this.sexe = 'FEMME';
    } else if (this.FselectSexe === 'selectedSexe' && this.MselectSexe === 'unselectedSexe') {
      this.FselectSexe = 'unselectedSexe';
      this.MselectSexe = 'selectedSexe';
      this.sexe = 'HOMME';
    }
  }

  showPassLogin() {
    if (this.signe === 'password' && this.faLogin === 'fa-eye') {
      this.signe = 'text';
      this.faLogin = 'fa-eye-slash';
    } else {
      this.signe = 'password';
      this.faLogin = 'fa-eye';
    }
  }

  ngOnInit() {
  }

  creerCompteViaFB() {
    this.authService.signIn(FacebookLoginProvider.PROVIDER_ID).then(socialUser => {
      const tokenFB = socialUser.authToken;
      const data = new FormData();
      data.set('access_token', tokenFB);
      data.set('device', 'WEB');
      data.set('plateforme', 'HADEEN');

      this.auth.signUpfb(data).subscribe(
        (rsp: any) => {
          this.toastr.success('Votre compte à été crée', '', {
            timeOut: 1000
          });
          const data3 = new FormData();
          data3.set('access_token', tokenFB);
          data.set('device', 'WEB');
          data3.set('plateforme', 'HADEEN');
          this.auth.loginFb(data3).subscribe((respons: any) => {
            if (respons.status === 200) {
              this.toastr.success('Bienvenue  ' + respons.data.user.prenom, '', {
                timeOut: 1000
              });

              this.MenuListServ.setName([respons.data.user.pseudo]);
              this.auth.storeUserData(JSON.stringify(respons.data.user), respons.data.token);
              this.commonService.user = respons.data.user;
              this.currentUserSubject.next(respons.data.token);
              this.MenuListServ.setMenuList();
              this.commonService.getPanier();
              if (isPlatformBrowser(this.platformId)) {
                window.scrollTo(0, 0);
              }
              this.chatService.testConnectionSOcket();
              return respons.data.token;
            }
          });
        },
        (err: any) => {
          if (err.includes('400')) {
            this.toastr.error('Vous avez déjà creer un compte', '', {
              timeOut: 1000
            });
            this.connecterViaFB();
          } else {
            this.toastr.error('Une erreur est survenu', '', {
              timeOut: 1000
            });
          }
        });
    });
  }

  connecterViaFB() {
    this.authService.signIn(FacebookLoginProvider.PROVIDER_ID).then(socialUser => {
      const tokenFB = socialUser.authToken;

      const data3 = new FormData();
      data3.set('access_token', tokenFB);
      data3.set('device', 'WEB');
      data3.set('plateforme', 'HADEEN');
      this.auth.loginFb(data3).subscribe((respons: any) => {
        if (respons.status === 200) {
          this.toastr.success('Bienvenue  ' + respons.data.user.prenom, '', {
            timeOut: 3000
          });
          this.MenuListServ.setName([respons.data.user.pseudo]);
          this.auth.storeUserData(JSON.stringify(respons.data.user), respons.data.token);
          this.commonService.user = respons.data.user;
          this.currentUserSubject.next(respons.data.token);

          this.MenuListServ.setMenuList();
          this.commonService.getPanier();
          if (isPlatformBrowser(this.platformId)) {
            window.scrollTo(0, 0);
          }
          this.chatService.testConnectionSOcket();
          this.router.navigate(['/compte']);
          // return respons.data.token;
        }
      });

    });
  }

  connecterViaGoogle(): void {
    this.authService.signIn(GoogleLoginProvider.PROVIDER_ID).then(socialUser => {
      const data3 = new FormData();
      data3.set('email', socialUser.email);
      data3.set('google_id', socialUser.id);
      data3.set('device_type', 'WEB');
      data3.set('plateforme', 'HADEEN');
      this.auth.googleLogin(data3).subscribe((respons: any) => {
        if (respons.status === 200) {
          this.toastr.success('Bienvenue  ' + respons.data.user.prenom, '', {
            timeOut: 3000
          });
          this.MenuListServ.setName([socialUser.firstName]);
          // this.MenuListServ.setName([respons.data.user.nickname]);
          this.auth.storeUserData(JSON.stringify(respons.data.user), respons.data.token);
          this.commonService.user = respons.data.user;
          this.currentUserSubject.next(respons.data.token);

          this.MenuListServ.setMenuList();
          this.commonService.getPanier();
          if (isPlatformBrowser(this.platformId)) {
            window.scrollTo(0, 0);
          }
          this.chatService.testConnectionSOcket();
          this.router.navigate(['/compte']);
          // return respons.data.token;
        }
      });
    });
  }

  creationCompteFb() {
    this.authService.signIn(FacebookLoginProvider.PROVIDER_ID).then(socialUser => {
      const tokenFB = socialUser.authToken;

      const data3 = new FormData();
      data3.set('access_token', tokenFB);
      data3.set('device', 'WEB');
      data3.set('plateforme', 'HADEEN');
      this.auth.loginFb(data3).subscribe((respons: any) => {
        if (respons.status === 200) {
          this.toastr.success('Bienvenue  ' + respons.data.user.prenom, '', {
            timeOut: 3000
          });
          this.MenuListServ.setName([respons.data.user.pseudo]);
          this.auth.storeUserData(JSON.stringify(respons.data.user), respons.data.token);
          this.commonService.user = respons.data.user;
          this.currentUserSubject.next(respons.data.token);

          this.MenuListServ.setMenuList();
          this.commonService.getPanier();
          if (isPlatformBrowser(this.platformId)) {
            window.scrollTo(0, 0);
          }
          this.chatService.testConnectionSOcket();
          this.router.navigate(['/compte']);
          // return respons.data.token;
        }
      });

    });
  }

  creationCompteGoogle() {
    this.authService.signIn(GoogleLoginProvider.PROVIDER_ID).then((socialUser: any) => {
      const data3 = new FormData();
      data3.set('email', socialUser.email);
      data3.set('family_name', socialUser.firstName);
      data3.set('given_name', socialUser.lastName);
      data3.set('google_id', socialUser.id);
      data3.set('picture', socialUser.photoUrl);
      data3.set('device_type', 'WEB');
      data3.set('plateforme', 'HADEEN');
      this.auth.googleRegister(data3).subscribe(
        (respons: any) => {
          if (respons.status === 200 || respons.status === 201) {
            const dataConnect = new FormData();
            dataConnect.set('email', socialUser.email);
            dataConnect.set('google_id', socialUser.id);
            dataConnect.set('device_type', 'WEB');
            dataConnect.set('plateforme', 'HADEEN');
            this.auth.googleLogin(dataConnect).subscribe((responsConnect: any) => {
              this.toastr.success('Bienvenue  ' + responsConnect.data.user.prenom, '', {
                timeOut: 3000
              });
              this.MenuListServ.setName([responsConnect.data.user.prenom]);
              this.auth.storeUserData(JSON.stringify(responsConnect.data.user), responsConnect.data.token);
              this.commonService.user = responsConnect.data.user;
              this.currentUserSubject.next(responsConnect.data.token);

              this.MenuListServ.setMenuList();
              this.commonService.getPanier();
              if (isPlatformBrowser(this.platformId)) {
                window.scrollTo(0, 0);
              }
              this.chatService.testConnectionSOcket();
              this.router.navigate(['/devenir-vendeur']);
            });

            // return respons.data.token;
          }
        },
        (err: any) => {
          this.toastr.warning('Votre email est déjà lié a une profile', '', {
            timeOut: 3000
          });
        }
      );
    });
  }
}
