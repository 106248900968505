import { Component, OnInit, Output, EventEmitter, TemplateRef, Input, Inject, PLATFORM_ID } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { HttpClient } from '@angular/common/http';

import { Select2OptionData } from 'ng-Select2';
import { Options } from 'select2';

import { environment } from 'src/environments/environment';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { isPlatformBrowser } from '@angular/common';

declare var Stripe;

@Component({
  selector: 'app-step3',
  templateUrl: './step3.component.html',
  styleUrls: ['./step3.component.scss']
})
export class Step3Component implements OnInit {
  public exampleData: Array<Select2OptionData>;
  public options: Options;
  public value: string[];
  formGroupe: FormGroup;
  listImages = [];
  listChoix = [];
  isAvant = false;
  isSave = false;
  btnLoading = false;
  errorPaiement = false;
  // showSpinner = false;
  choixOffre;
  produit: any = {};
  @Input() listCaracteristique = [];
  @Input() listCouleur = [];
  @Input() listTailles = [];
  @Input() formData;
  @Input() isExist;
  @Input() isBoost;

  @Output() nextStep: EventEmitter<any> = new EventEmitter();
  @Output() closeStep: EventEmitter<any> = new EventEmitter();

  showSpinner: any;
  showError = false;

  stripe = Stripe(environment.keys);
  elements;
  card;
  titulaire;

  modifierCarte = true;
  infosPaiements;
  productEdit;
  invalidCard = true;

  constructor(
    private formBuilder: FormBuilder, @Inject(PLATFORM_ID) private platformId,
    private router: Router, private activatedRoute: ActivatedRoute,
    private toastr: ToastrService, private httpClient: HttpClient) {}


  ngOnInit() {
    this.productEdit = this.activatedRoute.snapshot.paramMap.get('idProduit');
    if (this.isExist) {
      this.isAvant = true;
      this.stripeInit();
    }
    if (isPlatformBrowser(this.platformId)) {
      window.scrollTo(0, 0);
    }
    this.produit = this.formData[1];
    this.getOffres();
    this.formGroupe = this.formBuilder.group({
      choixAvant: [''],
    });

    this.value = ['multiple2', 'multiple4'];

    this.options = {
      multiple: true,
      tags: true
    };

    // get infos paiements
    this.httpClient.get(environment.ws_url + 'users/info-paiements').subscribe((data: any) => {
      if (data.data.response && data.data.response.data.length > 0) {
        this.infosPaiements = data.data.response.data[0].card;
        this.modifierCarte = false;
        this.stripeInit();
      }
    }, (error => { }));

  }

  stripeInit() {
    setTimeout(() => {
      this.elements = this.stripe.elements();
      const styleCss = {
        base: {
          color: '#32325d',
        }
      };
      this.card = this.elements.create('card', { hidePostalCode: true, style: styleCss });
      this.card.mount('#cardElement');
      this.card.addEventListener('change', (event) => {
        const displayError = document.getElementById('cardErrors');
        if (event.error || !event.complete) {
          displayError.textContent = event.error.message;
          this.invalidCard = true;
        } else {
          displayError.textContent = '';
          this.invalidCard = false;
        }
      });
      this.invalidCard = true;
    }, 500);
  }

  validerStep() {
    if (!this.showSpinner) {
      if (this.isBoost) {
        this.showSpinner = true;
        this.btnLoading = true;
        const formData = new FormData();
        formData.set('is_avant ', 'true');
        formData.set(`produit`, this.produit.id.produitId);
        formData.set(`offre`, this.choixOffre.id);
        formData.set(`is_save`, '' + this.isSave);
        if (!this.modifierCarte) {
          formData.append(`use_saved`, 'true');
        } else {
          formData.append(`use_saved`, 'false');
        }
        this.httpClient.post(environment.ws_url + 'produits/mettre-en-avant', formData).subscribe((dataMenu: any) => {
          if (!this.modifierCarte && dataMenu.data.paiement_method_id) {
            this.processPayment(dataMenu.data, true);
          } else if (this.modifierCarte) {
            this.processPayment(dataMenu.data);
          } else {
            this.showSpinner = false;
            this.btnLoading = false;
          }
          // this.closeStep.emit();
        });
      } else {
        this.showSpinner = true;
        this.btnLoading = true;
        this.saveStep3();
      }
    }
  }

  stateLoading(isTrue?) {
    this.showSpinner = isTrue;
    this.btnLoading = isTrue;
  }

  async saveStep3() {
    this.showSpinner = true;
    const formData = new FormData();
    formData.set('is_avant ', '' + this.isAvant);
    formData.set(`produit`, this.produit.id.produitId);
    if (this.isAvant) {
      formData.set('offre', this.choixOffre.id);
      formData.set('is_save', String(this.isSave));
    }
    if (!this.modifierCarte) {
      formData.append('use_saved', 'true');
    } else {
      formData.append('use_saved', 'false');
    }
    await this.httpClient.post(environment.ws_url + 'produits/step-three', formData).subscribe(
      (dataMenu: any) => {
        if (!this.modifierCarte && dataMenu.data.paiement_method_id) {
          this.processPayment(dataMenu.data, true);
        } else if (this.modifierCarte && dataMenu.data.intent_paiement_secret) {
          this.processPayment(dataMenu.data);
        } else {
          this.showSpinner = false;
          this.btnLoading = false;
          this.toastr.success('Votre produit a bien été ajouté', '', { timeOut: 5000 });
          this.router.navigate(['']);
        }

        this.btnLoading = false;
        // this.nextStep.emit(dataMenu.data);
        this.closeStep.emit();
      }, (error: any) => {
        this.toastr.error('Erreur', '', { timeOut: 5000 });
        this.showSpinner = false;
        this.btnLoading = false;
      }
    );
  }

  selectChoix(item) { this.choixOffre = item; }

  async getOffres() {
    return await this.httpClient.get(environment.ws_url + 'offre-publicitaires').subscribe((dataMenu: any) => {
      const listlistChoix = dataMenu.data;
      this.listChoix = listlistChoix.filter(elt => elt.type_offre === 'CATEGORIE_RESPECTIVE') || [];
      // this.listlistChoix.forEach(elt => elt.text = elt.valeur);
      this.choixOffre = this.listChoix[1];
    });
  }

  processPayment(resultIntent, isValue?) {
    if (isValue) {
      this.stripe.confirmCardPayment(resultIntent.intent_paiement_secret, {
        payment_method: resultIntent.paiement_method_id
      }).then((result) => {
        this.btnLoading = false;
        this.showSpinner = false;
        if (result.error) {
          this.toastr.error(result.error.code);
        } else {
          this.closeStep.emit();
          if (this.isBoost) {
            this.toastr.success('Votre produit a bien été mis en avant', '', { timeOut: 5000 });
          } else {
            this.toastr.success('Votre produit a bien été ajouté', '', { timeOut: 5000 });
          }
          this.router.navigate(['']);
        }
      });
    } else {
      this.stripe.confirmCardPayment(resultIntent.intent_paiement_secret, {
        payment_method: {
          card: this.card,
          billing_details: {
            name: this.titulaire
          }
        }}).then((result) => {
        this.btnLoading = false;
        this.showSpinner = false;
        if (result.error) {
          this.toastr.error(result.error.code);
        } else {
          this.closeStep.emit();
          if (this.isBoost) {
            this.toastr.success('Votre produit a bien été mis en avant', '', { timeOut: 5000 });
          } else {
            this.toastr.success('Votre produit a bien été ajouté', '', { timeOut: 5000 });
          }
          this.router.navigate(['']);
        }
      });
    }

  }

  reinitPaiement() {
    this.errorPaiement = false;
    this.titulaire = '';
    this.stripeInit();
  }

  async mettreAvant(avant?) {
    if (!avant) {
      this.btnLoading = true;
      const formData = new FormData();
      formData.set('is_avant ', 'true');
      formData.set(`produit`, this.produit.id.produitId);
      formData.set(`offre`, this.choixOffre.id);
      formData.set(`is_save`, '' + this.isSave);
      await this.httpClient.post(environment.ws_url + 'produits/mettre-en-avant', formData).subscribe((dataMenu: any) => {
        this.btnLoading = false;
        this.router.navigateByUrl('/');
      });
    } else {
      this.router.navigateByUrl('/');
    }
  }
}
