import { ChatService } from './../../../services/chat.service';
import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator, MatPaginatorIntl } from '@angular/material/paginator';
import {MatTableDataSource} from '@angular/material/table';
import { HttpService } from '../../../services/http.service';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonService } from 'src/app/services/common.service';
import { StorageService } from 'src/app/services/storage.service';
import { CustomPaginator } from './CustomPaginatorConfiguration';

@Component({
  selector: 'app-message',
  templateUrl: './message.component.html',
  styleUrls: ['./message.component.scss'],
  providers: [
    { provide: MatPaginatorIntl, useValue: CustomPaginator() }
  ]
})
export class MessageComponent implements OnInit, AfterViewInit {
  page = 1;
  url = 'messages/discussions';
  displayedColumns: string[] = ['position', 'name', 'symbol'];
  dataSource;
  rowSelected = null;
  discussion;
  myMessage: string;

  user;
  labelPaginator = "Ma page";

  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;

  constructor(
    private http: HttpService, public chatService: ChatService, private activatedRoute: ActivatedRoute,
    private router?: Router, private commonService?: CommonService, private storageService?: StorageService) {
    this.user = JSON.parse(this.storageService.getItem('User'));
    this.chatService.dataSource = new MatTableDataSource<PeriodicElement>(this.chatService.discussions);
    this.chatService.updateDiscussions();

  }

  ngOnInit(): void {

  }

  ngAfterViewInit() {
    this.chatService.dataSource.paginator = this.paginator;
  }

  selectDiscussion(row) {
    const totalUnread = row.messages.filter(m => m.lu === false && m.is_sent === false).length;
    if(totalUnread>0){
      this.chatService.unread -= totalUnread;
      this.chatService.setLu(row);
      this.router.navigate(['/compte/messages-details', row.id]);
    }else{
      this.router.navigate(['/compte/messages-details', row.id]);
    }
    
  }

  diffDate(date) {
    const d = new Date(date);
    let h = d.getHours(), m = d.getMinutes(), s = d.getSeconds();
    let value = '';
    if (h > 0) { value += h + 'h'; }
    value += (m > 0) ? (h > 0) ? m + '' : m + 'min' : '';
    if (h === 0 && m === 0) {
      value = s + ' secondes';
    }
    return value;
  }

  testDate(d) {
    const today = new Date();
    d = new Date(d);
    const value = Math.floor(Math.floor((today.getTime() - d.getTime()) / 1000 / 60 / 60) / 24);
    return value > 0;
  }

  sendMessage() {
    if (this.myMessage) {
      const message = {
        receiver: this.chatService.discussion.receiver.id,
        objet: this.chatService.discussion.objet,
        message: this.myMessage,
      };
      this.myMessage = '';
      this.chatService.sendMessage(message);
    }
  }

  /**
   *
   * @param html
   * remove the html tag in message value
   */
  removeHTMLTags(html) {
    const d = document.createElement('div');
    d.innerHTML = html;
    const textContent = (d.textContent.length > 100 || d.innerText.length > 100) ?
    d.textContent.slice(0, 100) + '...' || d.innerText.slice(0, 100) + '...' : d.textContent || d.innerText;
    return textContent;
  }

}



export interface PeriodicElement {
  createdAt: string;
  id: number;
  last_message: string;
  lu: number;
  objet: string;
  published_at: {};
  receiver: {};
  sender: {};
}
