import { Component, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { Router } from '@angular/router';

@Component({
  selector: 'app-mentions-legales',
  templateUrl: './mentions-legales.component.html',
  styleUrls: ['./mentions-legales.component.scss']
})
export class MentionsLegalesComponent implements OnInit {

  constructor(private titleService: Title, private meta: Meta, private router: Router) { }

  ngOnInit(): void {
    const nom = 'Mention légales';
    const description = 'Hadéen-Place sont des solutions marketplace multi vendeurs, donnant la possibilité à des TPE et PME françaises de vendre des Articles de la vie quotidienne.';
    this.updateMeta(nom, description)
  }

  updateMeta(nom, description) {
    this.titleService.setTitle('Hadéen-place | ' + nom);
    // this.altImage = nom + ' | Hadéen-Place | Consommer Responsable |';
    this.meta.updateTag({
      name: 'description',
      content: String(description.trim()),
    });
    // this.meta.updateTag({ property: 'og:image', content: this.product.image });
    this.meta.updateTag({ property: 'og:title', content: 'Hadéen-place | ' + nom });
    this.meta.updateTag({ property: 'og:type', content: 'page' });
    this.meta.updateTag({ property: 'og:url', content: this.router.url });
    this.meta.updateTag({ property: 'og:description', content: String(description.trim()) });
  }
}
