import { ToastrService } from 'ngx-toastr';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { BsDropdownConfig } from 'ngx-bootstrap/dropdown';
import { Router } from '@angular/router';
import { CommonService } from 'src/app/services/common.service';
import { ModalOpenService } from 'src/app/services/modal-open.service';


declare var $;

@Component({
  selector: 'app-aboutus',
  templateUrl: './aboutus.component.html',
  styleUrls: ['./aboutus.component.scss'],
  providers: [{ provide: BsDropdownConfig, useValue: { isAnimated: true, autoClose: true } }]
})
export class AboutusComponent implements OnInit {

  services = ['Newsletter', 'CB acceptation', 'Entretien', 'Nos catégories'];
  espaces = ['Mon compte', 'Espace Vendeurs', 'Contactez - nous'];
  connaitres = ['Informations Légales', 'CGU - CGV', 'Centre d\'aide'];
  panelOpenState = false;
  messageText = '';
  @ViewChild('loginTemplateFooter') loginTemplate: ElementRef;
  currentYear = new Date().getFullYear()

  constructor(
    private router: Router, private commonService: CommonService, private toast: ToastrService,
    public modalOpenService: ModalOpenService) { }

  ngOnInit(): void {
  }

  goTo(item) {
    if (item.includes('Mon compte')) {
      this.router.navigate(['/compte']);
    } else if (item.includes('Espace Vendeurs')) {
      if (this.commonService.user) {
        this.router.navigate(['/devenir-vendeur']);
      } else {
        this.router.navigate(['/login-devenir-vendeur']);
      }
    } else if (item.includes('Contactez - nous')) {
      this.openModalContact();
    } else if (item.includes('Informations Légales')) {
      this.router.navigate(['/mentions-legales']);
    } else if (item.includes('CGU')) {
      this.router.navigate(['/conditions-de-ventes']);
    } else if (item.includes('Centre d\'aide')) {
      this.router.navigate(['/centre-d-aide']);
    }
  }

  openModalContact() {
    if (this.commonService.user) {
      $('#modalContactModerateur2').modal('toggle');
    } else {
      this.modalOpenService.loginModal(this.loginTemplate);
    }
  }

  sendMessage() {
    const idModerateur = 14;
    const messageToModerateur = {
      receiver: idModerateur,
      objet: 'Hadeen',
      message: this.messageText
    };
    this.modalOpenService.sendMessage(messageToModerateur);
    this.messageText = '';
  }
}
