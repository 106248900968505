<div class="container" id="monCompte">
  <div class="row">
    <div class="col-12 col-md-3">
      <div class="onlyWeb">
        <h1 class="text-center mb-4">&nbsp;</h1>
        <div class="mb-3" style="display: flex; cursor: pointer;" [routerLink]="['/compte']">
          <img width="auto" height="auto" *ngIf="!commonService.user.imageProfil"
            src="../../../assets/images/logo/profilRounded.png" alt=""
            style="width: 40px; height: 40px; border-radius: 50%;">
          <img width="auto" height="auto" *ngIf="commonService.user.imageProfil" [src]="commonService.user.imageProfil"
            alt="" style="width: 40px; height: 40px; border-radius: 50%;">
          <h5 class="m-0 pl-2" style="line-height: 2;">{{ commonService.user.pseudo }}</h5>
        </div>

        <div [routerLink]="['/compte/mon-profil']" class="menuLeft pl-4 pr-4 mb-3"
          style="padding-top: 12px; padding-bottom: 12px; cursor: pointer;">
          Mon profil <img width="auto" height="auto" src="../../../assets/images/logo/profil.png" alt=""
            style="height: 20px; float: right;">
        </div>

        <mat-accordion>
          <mat-expansion-panel class="mb-3" hideToggle (closed)="panelOpenState = false">
            <mat-expansion-panel-header class="pr-0" style="background-color: #EDEDED; border-radius: 0px;">
              <mat-panel-title>
                <span>Mes commandes<span *ngIf="productService.allCommande && productService.commandAllTotal > 0">({{ productService.commandAllTotal}})</span></span>
              </mat-panel-title>
              <mat-panel-description>
                <mat-icon class="ml-auto"><img width="auto" height="auto" src="../../../assets/images/logo/commandes.png" alt=""
                    style="height: 20px; margin-top: -9px;"></mat-icon>
              </mat-panel-description>
            </mat-expansion-panel-header>
            <div class="pt-3">
              <p style="cursor: pointer;" [routerLink]="['/compte/mes-commandes']">> Mes achats
                ({{productService.commandShopperTotal}})
              </p>
              <p style="cursor: pointer;" *ngIf="commonService.user.etapeVendeur === 3"
                [routerLink]="['/compte/mes-commandes/ventes']">> Mes ventes ({{productService.commandSellerTotal}})
              </p>
            </div>
          </mat-expansion-panel>
        </mat-accordion>

        <div [routerLink]="['/compte/mes-messages']" class="menuLeft pl-4 pr-4 mb-3"
          style="padding-top: 12px; padding-bottom: 12px; cursor: pointer;">
          Mes messages <span *ngIf="chatService.unread>0">({{chatService.unread}})</span> <img
            src="../../../assets/images/logo/messages.png" alt="" style="height: 16px; float: right; margin-top: 5px;">
        </div>

        <mat-accordion>
          <mat-expansion-panel class="mb-3" hideToggle (closed)="panelOpenState = false">
            <mat-expansion-panel-header class="pr-0" style="background-color: #EDEDED; border-radius: 0px;">
              <mat-panel-title>
                <span>Listes d’achats <span *ngIf="productService.listeAchat && productService.listeAchat.length">({{productService.listeAchat.length}})</span></span>
              </mat-panel-title>
              <mat-panel-description>
                <mat-icon class="ml-auto"><img width="auto" height="auto" src="../../../assets/images/logo/listesAchats.png" alt=""
                    style="height: 20px; margin-top: -9px;"></mat-icon>
              </mat-panel-description>
            </mat-expansion-panel-header>
            <div class="pt-3">
              <p style="cursor: pointer;" [routerLink]="['/compte/mes-achats']">> Créer une nouvelle liste</p>
              <p style="cursor: pointer;" [routerLink]="['/compte/mes-achats/consulter-achat']">> Consulter les listes
                d'achats
              </p>
              <p *ngFor="let item of productService.listeAchat" style="cursor: pointer;"
                [routerLink]="['/compte/mes-achats/'+item.id]" [state]="true">> {{ item.event | titlecase }}: {{
                item.title }}</p>
            </div>
          </mat-expansion-panel>
        </mat-accordion>

        <div [routerLink]="['/compte/favoris']" class="menuLeft pl-4 pr-4 mb-3"
          style="padding-top: 12px; padding-bottom: 12px; cursor: pointer;">
          Favoris <span *ngIf="productService.mesEnvies && productService.mesEnvies.length">({{ productService.mesEnvies.length }})</span> <img width="auto" height="auto"
            src="../../../assets/images/logo/love_grey.png" alt="" style="height: 20px; float: right;">
        </div>

        <div *ngIf="commonService.user.etapeVendeur >= 3" (click)="goTo2('/compte/ma-boutique')"
          class="menuLeft pl-4 pr-4 mb-3" style="padding-top: 12px; padding-bottom: 12px; cursor: pointer;">
          Ma boutique <img width="auto" height="auto" src="../../../assets/images/logo/boutique.png" alt=""
            style="height: 20px; float: right;">
        </div>

        <div *ngIf="commonService.user.etapeVendeur >= 3"
          [routerLink]="['/compte/frais']" class="menuLeft pl-4 pr-4 mb-3 d-flex"
          style="padding-top: 12px; padding-bottom: 12px; cursor: pointer;">
          <span>Paramétrer frais de <br> livraison </span>
          <img width="auto" height="auto" src="../../../assets/images/logo/settings.png" alt=""
            style="height: 20px; float: right; margin-left: auto;">
        </div>

        <div *ngIf="commonService.user.etapeVendeur >= 3" (click)="goTo2('/compte/promouvoir-ma-boutique')"
          class="menuLeft pl-4 pr-4 mb-3 d-flex" style="padding-top: 12px; padding-bottom: 12px; cursor: pointer;">
          <span>Promouvoir ma boutique/mes produits </span>
          <img width="auto" height="auto" src="../../../assets/images/logo/promotion.png" alt=""
            style="height: 20px; float: right;">
        </div>

        <div *ngIf="commonService.user.etapeVendeur >= 3" [routerLink]="['/compte/mon-abonnement']" class="menuLeft pl-4 pr-4 mb-3"
          style="padding-top: 12px; padding-bottom: 12px; cursor: pointer;">
          Mon abonnement <img width="auto" height="auto" src="../../../assets/images/logo/handshake.svg" alt=""
            style="height: 22px; float: right;">
        </div>

        <div *ngIf="commonService.user.etapeVendeur >= 3" [routerLink]="['/ajout-prod']" class="menuLeft pl-4 pr-4 mb-3"
          style="padding-top: 12px; padding-bottom: 12px; cursor: pointer;">
          Ajouter un article <img width="auto" height="auto" src="../../../assets/images/logo/boutique.png" alt=""
            style="height: 20px; float: right;">
        </div>

        <div *ngIf="!commonService.user.etapeVendeur || commonService.user.etapeVendeur < 3"
          [routerLink]="['/devenir-vendeur']" class="menuLeft pl-4 pr-4 mb-3"
          style="padding-top: 12px; padding-bottom: 12px; cursor: pointer;">
          Devenir vendeur <img width="auto" height="auto" src="../../../assets/images/logo/boutique.png" alt=""
            style="height: 20px; float: right;">
        </div>

      </div>
    </div>

    <div class="col-12 col-md-9">
      <router-outlet></router-outlet>
    </div>
  </div>
</div>

<div class="container-fluid mb-4" style="border-bottom: 1px solid #DBFBE4;"></div>

<!-- Modal compte non valide -->
<div class="modal fade" id="staticBackdropInvalidAccount" data-backdrop="static" data-keyboard="false" tabindex="-1"
  aria-labelledby="staticBackdropLabel" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-body">
        <h4 class="text-center">Votre compte est en attente de validation</h4>
        <h4 class="text-center">pour toute information n'hesitez pas à nous contacter.</h4>
        <div class="text-center mt-3">
          <button type="button" class="h-btn-green" style="padding: 5px 30px; width: fit-content;"
            (click)="closeModal('compte')">Ok</button>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- Modal compte non valide -->
<div class="modal fade" id="staticBackdropInvalidAccount" data-backdrop="static" data-keyboard="false" tabindex="-1"
  aria-labelledby="staticBackdropLabel" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-body">
        <h4 class="text-center">
          Votre compte est en attente de validation
        </h4>
        <h4 class="text-center">
          pour toute information n'hesitez pas à nous contacter.
        </h4>

        <div class="text-center mt-3">
          <button type="button" class="h-btn-green" style="padding: 5px 30px; width: fit-content;"
            (click)="closeModal('compte')">Ok</button>
        </div>
      </div>
    </div>
  </div>
</div>
