import { CommonService } from 'src/app/services/common.service';
import { Component, HostListener, OnInit } from '@angular/core';

@Component({
  selector: 'app-ads',
  templateUrl: './ads.component.html',
  styleUrls: ['./ads.component.scss']
})
export class AdsComponent implements OnInit {
  maxRate = 4;

  data = [
        {
          avatar: 'assets/images/avis/libellule_shop.jpg',
          author: 'Isabelle',
          date: '2020/09/20',
          note: 4,
          // tslint:disable-next-line:max-line-length
          avis: 'Rien à redire'
        },
        {
          avatar: 'assets/images/avis/cave_bubble.jpg',
          author: 'Claire',
          date: '2022/01/24',
          note: 4,
          title: 'Nouvelle commande reçue ce jour et...',
          avis: `Nouvelle commande reçue ce jour et encore une fois parfaitement satisfaite, l'envoi est très rapide et les produits sont toujours conditionnés de manière personnalisés. L'avantage de commander auprès de créateurs indépendants.`
        },
        {
          avatar: 'assets/images/avis/cave_bubble.jpg',
          author: 'Gulia',
          date: '2023/05/14',
          note: 4,
          title: 'Des produits de beauté bio de qualité',
          avis: 'Très bon site pour trouver des produits de beauté bio et certifiés. Il y a un large choix. Les vendeurs sont des entreprises françaises qui propose aussi des produits de qualité et moins chers que ce qu’on peut trouver dans des magasins.'
        },
        {
          avatar: 'assets/images/avis/avis_eric.jpg',
          author: 'Eric CORNA',
          date: '2022/05/23',
          note: 4,
          title: 'Colis reçu rapidement',
          avis: 'Colis reçu rapidement , commande conforme Parfait'
        },
        {
          avatar: 'assets/images/avis/avis_elodie.jpg',
          author: 'Tya Fmnt',
          date: '2023/04/07',
          note: 4,
          title: "J'ai trouver mon bonheur",
          avis: "Hadéen-Place est une super référence pour trouver des articles zéro-déchet autour de la cosmétique, j'ai beaucoup cherché sur d'autres sites plus connus mais pas forcément trouver ce que je voulais .. C'est une copine qui me l'a conseillé et je conseille aussi ! J'ai reçu mes articles assez vite et je suis super contente de la qualité !"
        },
        {
          avatar: 'assets/images/avis/cave_bubble.jpg',
          author: 'cave bubble',
          date: '2020/07/01',
          note: 4,
          // tslint:disable-next-line:max-line-length
          avis: 'Site simple à utiliser, j’ai pu vendre mes articles de bébé fait main rapidement. J’ai reçu mon paiement peu de temps après la réception de l’article.'
        },
        {
          avatar: 'assets/images/avis/avis_eric.jpg',
          author: 'Eric',
          date: '2020/09/31',
          note: 4,
          // tslint:disable-next-line:max-line-length
          avis: 'j’ai trouvé de bonnes idées de cadeau pour le prochain Noel et surtout j’ai pu faire travailler des entreprises françaises.'
        },
        {
          avatar: 'assets/images/avis/avis_elodie.jpg',
          author: 'Elodie',
          date: '2020/08/13',
          note: 3,
          avis: 'j’ai pu créer facilement ma liste de naissance et me faire livrer directement chez moi avant la naissance de mon fils.'
        },
        {
          avatar: 'assets/images/avis/little_richard_clothes.jpg',
          author: 'Litlle Richard Clothes',
          date: '2020/06/12',
          note: 4,
          // tslint:disable-next-line: max-line-length
          avis: 'je vendais déjà mes produits dans mon local mais avec la crise sanitaire j’ai dû trouver une autre solution de vente rapide et sécurisée. J’ai essayé Hadéen et pour le moment j’en suis ravi.'
        },
        {
          avatar: 'assets/images/avis/avis_andy.jpg',
          author: 'Andy',
          date: '2020/08/09',
          note: 4,
          avis: 'je voulais absolument faire travailler des entreprises françaises. Je suis ravis de mes achats.'
        },    
        {
          avatar: 'assets/images/avis/avis_liliane.jpg',
          author: 'Liliane',
          date: '2020/05/12',
          note: 4,
          avis: 'habituée à utiliser d’autres sites comme A****** ici je sais que j’achète a des petites sociétés françaises.'
        },
        {
          avatar: 'assets/images/avis/avis_richard.jpg',
          author: 'Richard',
          date: '2020/06/10',
          note: 3,
          // tslint:disable-next-line:max-line-length
          avis: 'j’ai fais la surprise à ma femme de lui créer une liste de mariage , je n’en n’avait jamais fait, je ne savais même pas que ça existait. Tout a été très facile , je l’ai crée , je l’ai partagé et surtout nous avons tout eu pour notre mariage. J’y penserai pour nos prochains événements'
        },
        {
          avatar: 'assets/images/avis/libellule_shop.jpg',
          author: 'Libellule shop',
          date: '2020/09/20',
          note: 4,
          // tslint:disable-next-line:max-line-length
          avis: 'j’utilisé deja des sites spécialisés pour vendre mes cosmétiques BIO mais je devais payer des abonnements mensuels même si je ne vendais pas. Sur Haden si je ne vend rien , je ne paie rien.'
        }
  ];

  data_screen = {
    web: [],
    mobile: []
  };
  screenWidth = 0;
  colors = [
    '#ff8622',
    '#f1c117',
    '#73cf11',
    '#19b679'
  ];
  // colors = [
  //   '#ff3722',
  //   '#ff8622',
  //   '#f1c117',
  //   '#73cf11',
  //   '#19b679'
  // ];
  expandedIndex = [];
  list = [];

  constructor(private commonService: CommonService) { }
  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.screenWidth = window.innerWidth;
    this.assignData();
  }

  ngOnInit(): void {
    this.data_screen.mobile = this.commonService.createNewArray(2, this.data);
    this.data_screen.web = this.commonService.createNewArray(4, this.data);
    this.assignData();
  }
  
  readMore(text, index: number) {
    if (text['readmore']) {
      delete text['readmore'];
      this.expandedIndex = this.expandedIndex.filter(item => item != index);      
    } else {
      text['readmore'] = text['readmore'] ? false : true;
      this.expandedIndex.push(index);
    }    
    
  }

  getColor(note: number){
      return this.colors[note-1];
  }

  assignData(){
    this.list = (window.innerWidth<=768) ? this.data_screen.mobile : this.data_screen.web;
  }
}
