import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';
import { HttpService } from './http.service';


@Injectable({
  providedIn: 'root'
})
export class UserService {

  stateStep = 0;
  typeDevice = 'WEB';

  constructor(private http: HttpService) {}

  getProfil(user, plateform): Observable<any> {
    return this.http.get('users/' + plateform + user)
    .pipe(
      retry(0),
      catchError(this.handleError)
    );
  }
  stripeAccount(user): Observable<any> {
    return this.http.post('stripes/create-account', user)
    .pipe(
      retry(0),
      catchError(this.handleError)
    );;
  }

  backAccount(user): Observable<any> {
    // return this.http.post('stripe-utils/banks', user);
    return this.http.post('stripes/banks', user)
    .pipe(
      retry(0),
      catchError(this.handleError)
    );;
  }
  getVendeur(a, b): Observable<any> {
    return this.http.get('comptes/' + a + '/vendeur/' + b)
    .pipe(
      retry(0),
      catchError(this.handleError)
    );;
  }



  stripeAccountUpdate(user, options): Observable<any> {
    return this.http.put('stripes/update-account?' + options, user)
    .pipe(
      retry(0),
      catchError(this.handleError)
    );;
  }
  stripeKey(): Observable<any> {
    return this.http.get('HADEEN/stripes/test')
    .pipe(
      retry(0),
      catchError(this.handleError)
    );;
  }
  stripeFileToken(data): Observable<any> {
    return this.http.post('stripe-utils/files', data)
    .pipe(
      retry(0),
      catchError(this.handleError)
    );;
  }
  stripeFileSend(data, accId): Observable<any> { // acct_1H1G5iJhBK1ht420
    return this.http.put('HADEEN/stripe-accounts/' + accId, data)
    .pipe(
      retry(0),
      catchError(this.handleError)
    );;
  }
  stripeAccountGet(accId): Observable<any> {
    return this.http.get('HADEEN/stripe-accounts/' + accId)
    .pipe(
      retry(0),
      catchError(this.handleError)
    );;
  }

  dbAccount(mail) {
    return this.http.post('users/send-recuperation', mail);
  }

  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong.
      console.error(
        `Backend returned code ${error.status}, ` +
        `body was: ${error.error}`);
    }
    // Return an observable with a user-facing error message.
    // return throwError('Something bad happened; please try again later.');
    return throwError(error);
  }
}
