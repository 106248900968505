import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-testimonial',
  templateUrl: './testimonial.component.html',
  styleUrls: ['./testimonial.component.scss']
})
export class TestimonialComponent implements OnInit {

  @Input() itemTestimonials;
  constructor() { }

  ngOnInit() {
  }

  readMore(text) {
    if (text['readmore']) {
      delete text['readmore'];
    } else {
      text['readmore'] = true;
    }
  }
}
