<div>
  <div class="spinner" style="position: fixed; top: 45%; left: 47%; z-index: 1;" *ngIf="showSpinner">
    <mat-spinner></mat-spinner>
  </div>
</div>

<div class="text-center">
  <img src="../../../../assets/images/logo/logo2.png" alt="" style="width: 210px; margin: auto;">
</div>
<div class="row addProduit" autocomplete="off" *ngIf="!errorPaiement">
  <div class="col-12">
    <div class="col-12 my-3" style="padding: 0;">
      <ng-container *ngIf="modifierCarte; else elseTemplate">
        <div class="card">
          <div class="card-body p-2 p-md-3">
            <div class="col-12 my-2 px-0">
              <img style="height: 30px;" width="auto" height="auto" class="" src="../../../../../assets/images/Groupe 596.png">
              <img width="auto" height="auto" class="" src="../../../../../assets/images/Groupe 594.png"
                style="margin: 0 10px; height: 32px;">
              <img width="auto" height="auto" class="" src="../../../../../assets/images/Groupe 599.png"
                style="margin-right: 10px; height: 30px;">
              <img width="auto" height="auto" src="assets/images/icons/American_Express.svg" alt=""
                style="width: 55px; height: 32px;">
            </div>
            <mat-form-field class="w-100" style="font-size: 15px;">
              <mat-label>Nom du Tutilaire du Carte</mat-label>
              <input matInput placeholder="Nom du tutilaire du Carte" [(ngModel)]="titulaire">
            </mat-form-field>
            <form id="payment-form" style="border: 1px solid #d9d9d9; padding-left: 5px;">
              <span style="font-size: 12px; color: #d9d9d9;">Numero de la carte</span>
              <div id="cardElement" style="border-bottom: 1px solid #848484; padding-bottom: 5px;">
                <!-- Elements will create input elements here -->
              </div>
              <!-- We'll put the error messages in this element -->
              <div id="cardErrors" role="alert" style="font-size: 13px; color: red; margin-top: 5px; font-style: italic;"></div>
              <!-- <button id="submit">Pay</button> -->
            </form>
            <!-- <div class="col-12">
                <mat-checkbox [(ngModel)]="isSave"> Sauvegarder ma carte </mat-checkbox>
              </div> -->
          </div>
        </div>
        <p class="mt-2 mb-1" style="font-size: 13px; font-style: italic;">En finalisant la souscription de
          l'abonnement, j'accepte les conditions de vente.</p>
        <p class="my-0" style="font-size: 13px; font-style: italic;">Les informations relatives au mode
          de paiement seront conservées pour le renouvellement de l'abonnement. L'abonnement peut être résilié à tout
          moment, ceci sans délai ni frais</p>
      </ng-container>

      <ng-template #elseTemplate>
        <div class="card">
          <div class="card-body p-2 p-md-3">
            <div class="col-12 form-group px-0" style="font-size: 15px;">
              {{infosPaiements? ' Effectuer le réglement avec la carte : ':''}}
            </div>
            <div class="d-flex justify-content-around py-2">
              <img [src]="infosPaiements.imgPath" alt="" style="height: 50px;">
              <span class="m-auto" style="font-size: 14px; font-style: italic;">{{cardFormat(infosPaiements.brand, infosPaiements.last4)}}</span>
            </div>
            <div class="text-center">
              <a style="text-decoration: underline; font-size: 12px;" (click)="cardInit()">Modifier la carte</a>
            </div>
          </div>
        </div>
      </ng-template>
    </div>
    <!-- </ng-template> -->
    <div class="col-12 text-center my-2" style="padding: 0; position: relative;">
      <!-- <button class="signin" style="position: relative;" [disabled]="isAvant && invalidCard && modifierCarte ? invalidCard : false" (click)="validerStep()"> {{ isAvant ? 'Mettre en vente' : 'Valider' }}</button> -->
      <button class="signin" style="position: relative; background-color: #345B2B; border: 1px solid #345B2B;" [disabled]="showSpinner || (invalidCard && modifierCarte ? invalidCard : false)"
        (click)="validerStep()">Valider</button>
    </div>
  </div>
</div>

<div class="col-12 addProduit" autocomplete="off" *ngIf="errorPaiement">
  <div class="row" style="background-color: white;">
    <div class="col text-center my-2" style="padding: 0 5px;">
      <!-- <button class="signin" style="height: 100%;" (click)="reinitPaiement()"> Reessayer le paiement</button> -->
    </div>
    <div class="col text-center my-2" style="padding: 0 5px;">
      <!-- <button class="signin" style="height: 100%;" (click)="mettreAvant('avant')"> Mettre en avant sans payer </button> -->
    </div>
  </div>
</div>
