import { Router } from '@angular/router';
import { CommonService } from 'src/app/services/common.service';
import { HttpClient } from '@angular/common/http';
import { Component, ElementRef, Inject, OnInit, PLATFORM_ID, HostListener, ViewChild } from '@angular/core';
import { environment } from 'src/environments/environment';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { isPlatformBrowser } from '@angular/common';
import { Meta, Title } from '@angular/platform-browser';
import { StorageService } from 'src/app/services/storage.service';
import * as FormDataServer from 'form-data';
import { TrackEventService } from 'src/app/services/track-event.service';

declare var $;
declare var gtag;

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  @ViewChild('loginTemplate') loginTemplate: ElementRef;
  offresDuMomentShow = {
    min: [],
    moyen: [],
    max: []
  };
  ventesFlashShow = {
    min: [],
    moyen: [],
    max: []
  };
  coupDeCoeurShow = [];
  allSliders = [];
  allALaUnes = [];
  modalRef2: BsModalRef;
  sliders = [];
  aLaUnes = [];
  slidersWithSousCategories = [];
  aLaUnesWithSousCategories = [];
  screenWidth = 0;
  buttons = [];
  colors = [];
  dataUnivers;
  isConnected;
  dataUniverBox: any;
  screenDataUniverBox: {
    web: any,
    mobile: any
  } = {
    web: null,
    mobile: null
  };

  constructor(
    private httpClient: HttpClient, public commonService: CommonService,
    @Inject(PLATFORM_ID) private platformId, private storageService: StorageService,
    private titleService: Title, private metaService: Meta, private router: Router,
    private trackEventService: TrackEventService) { }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.screenWidth = window.innerWidth;
    if(this.screenDataUniverBox.web && this.screenDataUniverBox.mobile){
      this.dataUniverBox.sous_univers_homes = (this.screenWidth>=991) ? this.screenDataUniverBox.web : this.screenDataUniverBox.mobile;      
    }    
  }

  
  // clicked(id: number) {
  //   switch (id) {
  //     case 0:
  //       this.trackEventService.gtagTrack('conversion', null)
  //       break;
  //     case 1:
  //       this.trackEventService.gtagTrack('page_view', false)
  //       break;
  //     case 2:
  //       this.trackEventService.gtagTrack('addtocart', true)
  //       break;
  //     case 3:
  //       this.trackEventService.gtagTrack('purchase', null)
  //       break;
  
  //     case 4:
  //       this.trackEventService.pintrkTrack('page_view', null)
  //       break;
  //     case 5:
  //       this.trackEventService.pintrkTrack('page_view', null)
  //       break;
  //     case 6:
  //       this.trackEventService.pintrkTrack('page_view', null)
  //       break;
      
  //     case 7:
  //       this.trackEventService.fbqTrack('page_view', null)
  //       break;
  //     case 8:
  //       this.trackEventService.fbqTrack('page_view', null)
  //       break;
  //     case 9:
  //       this.trackEventService.fbqTrack('page_view', null)
  //       break;
  //     default:
  //       break;
  //   }
  // }

  ngOnInit(): void {
    // gtag('track', 'conversion', {
    //   'send_to': 'AW-479791588/FT4DCMyC1v0YEOST5OQB',
    //   'value': 1.0,
    //   'currency': 'EUR',
    //   'transaction_id': '',
    // });
    this.checkColor('#000');
    this.titleService.setTitle('Hadéen-Place | Consommer Responsable | 100% Entreprises Françaises');
    this.metaService.updateTag({
      name: 'description',
      // tslint:disable-next-line: max-line-length
      // content: 'Consommez responsable grâce à nos articles ayant tous au moins un impact écologique : Bio, vegan, made in France, circuit court, fait main, naturel, Éco-responsable, écologique, recyclable, zéro déchet, cruelty fee … Sans faire d’effort, en 2-3 clics !'
      // tslint:disable-next-line:max-line-length
      content: 'Consommez responsable, articles ayant tous au moins un impact écologique: Bio, vegan, made in France, fait main, naturel, Éco-responsable, écologique, recyclable, ...'
    });

    this.metaService.updateTag({ property: 'og:title', content: 'Hadéen-Place | Consommer Responsable | 100% Entreprises Françaises' });
    this.metaService.updateTag({ property: 'og:type', content: 'website' });
    this.metaService.updateTag({ property: 'og:url', content: this.router.url });
    // tslint:disable-next-line:max-line-length
    this.metaService.updateTag({ property: 'og:description', content: 'Consommez responsable grâce à nos articles ayant tous au moins un impact écologique : Bio, vegan, made in France, circuit court, fait main, naturel, Éco-responsable, écologique, recyclable, zéro déchet, cruelty fee … Sans faire d’effort, en 2-3 clics !' });

    this.isConnected = this.storageService.getItem('User');
    if (isPlatformBrowser(this.platformId)) {
      window.scrollTo(0, 0);
      this.screenWidth = window.innerWidth;
    }

    this.getOffreMoment();
    this.getHomeProducts('VENTE_FLASH');

    this.buttons = this.commonService.styleButtons;
    this.colors = this.commonService.couleurTexte;

    this.httpClient.get(environment.ws_url + 'arborescences').subscribe((dataMenu: any) => {
      this.dataUnivers = dataMenu.data;
      const fd = new FormDataServer();
      fd.append('plateforme', 'HADEEN');
      this.httpClient.post(environment.ws_url + 'publicites/search', fd).subscribe((data: any) => {
        const pubs = data.data.demande_publicites;
        this.allSliders = pubs.filter(pub => pub.slider !== null);
        this.allALaUnes = pubs.filter(pub => pub.a_la_une !== null);

        this.allSliders.forEach(pub => {
          if (pub.slider.publicice_detail.catalogue && pub.slider.publicice_detail.catalogue.code_sous_categorie !== null) {
            this.slidersWithSousCategories.push(pub);
          } else {
            if (pub.slider.publicice_detail.choix === 'PRODUIT') {
              const u = pub.slider.publicice_detail.catalogue.code_univers;
              const su = pub.slider.publicice_detail.catalogue.code_sous_univers;
              const c = pub.slider.publicice_detail.catalogue.code_categorie;
              pub['link'] = this.getArborescence(u, su, c);
            }
            this.sliders.push(pub);
          }
        });

        this.allALaUnes.forEach(pub => {
          if (pub.a_la_une.publicice_detail.catalogue && pub.a_la_une.publicice_detail.catalogue.code_sous_categorie !== null) {
            this.aLaUnesWithSousCategories.push(pub);
          } else {
            if (pub.a_la_une.publicice_detail.choix === 'PRODUIT') {
              const u = pub.a_la_une.publicice_detail.catalogue.code_univers;
              const su = pub.a_la_une.publicice_detail.catalogue.code_sous_univers;
              const c = pub.a_la_une.publicice_detail.catalogue.code_categorie;
              pub['link'] = this.getArborescence(u, su, c);
            }
            this.aLaUnes.push(pub);
          }
        });
      });
    });
    this.getUniverBox();
  }

  getUniverBox() {
    this.httpClient.get(environment.ws_url + 'sous-univers-slide-home/web?page=1&par_page=20').subscribe((dataUniver: any) => {
      this.dataUniverBox = dataUniver.data;
      this.screenDataUniverBox.web = this.commonService.createNewArray(4, dataUniver.data.sous_univers_homes);      
      this.screenDataUniverBox.mobile = this.commonService.createNewArray(3, dataUniver.data.sous_univers_homes);
      this.dataUniverBox.sous_univers_homes = (this.screenWidth>=991) ? this.screenDataUniverBox.web : this.screenDataUniverBox.mobile;
    });
  }

  getOffreMoment() {
    // const user = localStorage.getItem('User');
    let urlOffre = 'produits/search';
    const dataOffre = new FormDataServer();
    dataOffre.append('plateforme', 'HADEEN');
    if (this.isConnected) {
      dataOffre.append('pagination.page', '1');
      dataOffre.append('pagination.par_page', '16');
      urlOffre = 'produits-with-wish';
    }
    return this.httpClient.post(`${environment.ws_url}${urlOffre}`, dataOffre).subscribe((response: any) => {
      // this.offresDuMomentShow = this.createNewArray(4, response.data.produits);
      // if (this.screenWidth >= 768) {
        // this.offresDuMomentShow = this.createNewArray(5, response.data.produits);
        this.offresDuMomentShow.max = this.commonService.createNewArray(5, response.data.produits);
      // } else {
        this.offresDuMomentShow.moyen = this.commonService.createNewArray(4, response.data.produits);
        this.offresDuMomentShow.min = response.data.produits;
        
      // }
    });
  }

  getHomeProducts(type) {
    if (isPlatformBrowser(this.platformId)) {
      let prodUrl = '';
      const body = {
        types: [type]
      };
      // const body = {
      //   'types[0]': 'VENTE_FLASH',
      //   'types[1]': 'COUP_DE_COEUR'
      // };
      if (this.isConnected) {
        prodUrl = 'accueil/produits-with-wish/HADEEN';
      } else {
        prodUrl = 'accueil/produits/HADEEN';
      }
      this.httpClient.get(environment.ws_url + prodUrl, {params: body}).subscribe((dataMenu: any) => {
        if (type === 'VENTE_FLASH') {
          // if (this.screenWidth >= 768) {
            this.ventesFlashShow.max = this.commonService.createNewArray(5, dataMenu.data.produits);
          // } else {
            this.ventesFlashShow.moyen = this.commonService.createNewArray(4, dataMenu.data.produits);
            this.ventesFlashShow.min = dataMenu.data.produits;
          // }
          
        }
      });
    }
  }

  getStyleButton(id) {
    return this.buttons.filter(btn => btn.id === id)[0].nomClasse;
  }

  getColor(id) {
    return this.colors.filter(color => color.id === id)[0].hexa;
  }

  getArborescence(u, su, c) {
    const univers = this.dataUnivers.filter(elt => elt.code === u)[0];
    const sousUnivers = univers.sous_univers.filter(elt => elt.code === su)[0];
    let categorie;
    if (sousUnivers && sousUnivers.categories.length) {
      categorie = sousUnivers.categories.filter(elt => elt.code === c)[0];
    }
    return {
      univers : univers.nom || '',
      sousUnivers : (sousUnivers) ? sousUnivers.nom : '',
      categorie : (categorie) ? categorie.nom : ''
    };
  }

  formatUrl(url) {
    return (url.trim()).split(' ').join('_');
  }

  goTo() {
    if (this.commonService.user) {
      if (this.commonService.user.role === 'VENDEUR_VALIDEE') {
        this.router.navigate(['/compte']);
      } else {
        this.router.navigate(['/devenir-vendeur']);
      }
    } else {
      this.router.navigate(['/login-devenir-vendeur']);
    }
  }

  goToListing(item: any) {
    if (item.sous_univers) {
      // this.router.navigate([`/all-product/${item.univers.nom.split(' ').join('_')}/${item.sous_univers.nom.split(' ').join('_')}`]);
      this.router.navigate([`/all-product/${item.univers.slug}/${item.sous_univers.slug}`]);
    } else if (item.univers) {
      // this.router.navigate([`/all-product/${item.univers.nom.split(' ').join('_')}`]);
      this.router.navigate([`/all-product/${item.univers.slug}`]);
    } else if (item.url) {
      window.open(item.url, '_blank');
    } else {
      // console.log('urien');
    }
  }

  checkColor(colorA) {
    const reg = /^#([0-9a-f]{3}){1,2}$/i;
    return reg.test(colorA);
  }
}
