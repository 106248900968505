<div *ngIf="showSpinner" style="position: fixed; text-align: center; width: auto; margin: auto; z-index: 1; left: 50%;">
  <div style="background-color: white; width: fit-content; margin: auto; border-radius: 50%;">
    <img width="auto" height="auto" src="../../../../assets/images/favicon.jpg" alt=""
      style="width: 50px; position: absolute; margin: 20px -25px;">
    <mat-spinner class="m-auto custom-spinner-color" color="accent"></mat-spinner>
  </div>
</div>

<div class="top-header" *ngIf="from == 'page' && (etape === 1 || etape === 2)">
  <hr>
  <div class="container">
    <div class="header">
      <ul class="breadcrumb">
        <li><a>Hadéen-place</a></li>
        <li>panier</li>
        <li>paiement</li>
      </ul>
    </div>
    <div class="main-header" style="margin-top: -10px;">
    </div>
  </div>
</div>

<div style="cursor: pointer;margin-bottom: 6%;" *ngIf="etape === 1 || etape === 2">
  <div class="row p-2">
    <div class="col-12" *ngIf="from == 'page'">
      <div class="uk-grid-small col-md-7 mb-3 pt-2" uk-grid style="height: 50px;">
        <div class="uk-width-expand pt-1" uk-leader>
          <span style="border: 1px solid #d9d9d9; border-radius: 50%; padding: 5px 11px;">1</span> Livraison
        </div>
        <div class="pt-1">
          <span style="border: 1px solid #d9d9d9; border-radius: 50%; padding: 5px 9px;">2</span> paiement
        </div>
      </div>
    </div>

    <div class="col-12 mb-4">
      <!-- <div class="col-md-5 col-lg-5 col-12 p-0 pl-md-3 pr-md-3 pt-md-3"> -->
      <div style="background-color: #F9F9F9; border: 1px solid #D6D6D6;">
        <ul uk-accordion class="m-0">
          <li>
            <a class="uk-accordion-title p-3" href="#">
              <span>Ma commande</span>
              <span style="float: right; font-size: 14px; margin-top: 6px;">Afficher le détail</span>
            </a>
            <div class="uk-accordion-content m-0" style="background-color: #ffffff;">
              <div class="p-3" style="border-bottom: 1px solid #D6D6D6;"
                *ngFor="let itemPanier of paniers; let ind = index">
                <div class="row">
                  <div class="col-3 col-md-2 pr-2">
                    <img width="auto" height="auto" [src]="itemPanier.sous_produit.produit.image" class="m-md-auto"
                      style="display: block; height: 68px;" alt="">
                  </div>
                  <div class="col-9 col-md-10">
                    <div class="row">
                      <div class="col-12 col-md-4 p-0">
                        <h4 style="font-size: 15px; overflow: hidden;">{{ itemPanier.sous_produit.produit.model }}</h4>
                      </div>
                      <div class="col-7 col-md-5 mt-sm-2 mt-lg-0 pr-0">
                        <h5 style="font-size: 15px;">Quantité:
                          <span style="font-size: 15px;">
                            <span style="padding: 10px; background-color: #fbfafa;"> {{ itemPanier.quantite }} </span>
                          </span>
                        </h5>
                        <h5 style="font-size: 15px;" *ngIf="itemPanier.sous_produit.taille">Taille: {{
                          itemPanier.sous_produit.taille.valeur }}</h5>
                        <p *ngIf="itemPanier.sous_produit.couleur">
                          <span style="font-size: 15px;">Coleur: </span>
                          <span>
                            <div [ngStyle]="{'background-color': itemPanier.sous_produit.couleur.codeHexa}"
                              style="width: 18px; height: 18px; border: 1px solid #d9d9d9;display: inline-block; margin-top: 2px; margin-right: 5%;">
                            </div>
                          </span>
                        </p>
                      </div>

                      <div class="col-5 col-md-3 pl-0 text-right">
                        <h3 style="font-size: 16px;">{{ this.commonService.calculPrix(itemPanier.sous_produit.produit, itemPanier.quantite) | number : '1.2-2' }} €</h3>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </li>
        </ul>

        <div class="p-3" style="border-top: 1px solid #D6D6D6">
          <span>Total articles ({{ paniers.length }})</span>
          <span style="float: right;">{{ getTotalArticle() | number : '1.2-2' }} €</span>
        </div>
        <div *ngIf="commonService.codePromo.isValid" class="p-3" style="border-top: 1px solid #D6D6D6">
          <span>Total promotion</span>
          <span style="float: right;">-{{ getTotalArticleWithPromo() | number : '1.2-2' }} €</span>
        </div>
        <div class="p-3" style="border-top: 1px solid #D6D6D6">
          <span>{{fraisLivraisonTotal === 0 ? 'Livraison incluse' : 'Frais de livraison'}}</span>
          <span style="float: right;" *ngIf="fraisLivraisonTotal > 0">{{ fraisLivraisonTotal }} €</span>
        </div>
        <div class="p-3" style="border-top: 1px solid #D6D6D6">
          <span>Total TTC</span>
          <span style="float: right;">{{ getTotalArticle() - getTotalArticleWithPromo() + fraisLivraisonTotal | number :
            '1.2-2' }} €</span>
        </div>
      </div>
    </div>

    <div class="col-12">
      <!-- <div class="col-12 col-md-7 col-lg-7 p-0 p-md-3"> -->
      <!-- Etape 2 -->
      <ng-template #step2 [ngIf]="etape === 2">
        <div class="pt-4" style="border: 1px solid #D6D6D6; background-color: #fff;">
          <div class="row">
            <div class="col-12 col-md-10 offset-md-1">
              <h4 class="col-12 text-center">PAIEMENT SÉCURISÉ</h4>
              <p class="col-12 text-center">Les champs marqués d’une * sont obligatoires.</p>
              <p class="col-12 text-center">
                Veuillez renseigner votre numéro de carte, vous serez alors dirigé(e) vers l'interface de paiement sécurisée de votre banque. <br>
                Une double authentification peut être requise pour confirmer votre commande.
              </p>

              <ul class="uk-iconnav col-12 mb-3 justify-content-center">
                <li class="mt-auto mb-auto"><img width="50" height="auto" src="../../../../assets/images/mastcard.png" alt="master card"></li>
                <li class="mt-auto mb-auto"><img width="54" height="auto" src="../../../../assets/images/cb.png" alt="cb"></li>
                <li class="mt-auto mb-auto"><img width="51" height="auto" src="../../../../assets/images/visa.png" alt="visa"></li>
                <li><img width="51" height="auto" src="../../../../assets/images/amexpr.png" alt="american express"></li>
              </ul>
              <div class="col-12">
                <div>
                  <mat-form-field class="w-100 example-full-width hadeenInput">
                    <mat-label>Nom du Tutilaire du Carte *</mat-label>
                    <input matInput placeholder="Nom du tutilaire du Carte" [(ngModel)]="titulaire">
                  </mat-form-field>
                  <form class="mb-2 pl-3" id="payment-form" style="border: 1px solid #d9d9d9; min-height: 49px;">
                    <span style="font-size: 12px; color: #0000008a;">Numero de la carte *</span>
                    <div id="cardElement" style="padding-bottom: 5px;">
                      <!-- Elements will create input elements here -->
                    </div>

                    <!-- We'll put the error messages in this element -->
                    <div id="cardErrors" role="alert"></div>

                  </form>
                </div>
              </div>

              
              <div class="pr-4">
                <mat-checkbox class="example-margin px-3 my-2" style="color: #000;" [(ngModel)]="isReceiveMail">Je souhaite recevoir par e-mail les remises et astuces de la Brigade HADEEN-PLACE.</mat-checkbox>
                <mat-radio-group class="pl-3 my-2" aria-label="Select an option" [(ngModel)]="selectedValue">
                  <mat-radio-button value="1" (click)="selectedValue = !selectedValue" style="color: #000;">J'accepte les <a routerLink="/conditions-de-ventes" style="color: #2b2b2b; text-decoration: underline;">conditions générales et la politique de confidentialité.</a></mat-radio-button>
                </mat-radio-group>
              </div>


              <div class="col-12 mb-md-4">
                <button [disabled]="isAlreadyCliked" type="submit" id="submit" (click)="createPIntent()"
                  class="h-btn-dore rounded-0 w-100 pt-2 pb-2">VALIDER ET PAYER MA COMMANDE</button>
              </div>
            </div>
          </div>
        </div>
      </ng-template>
    </div>
  </div>
</div>

<div class="container pt-5 pb-5" *ngIf="etape === 4">
  <div class="row">
    <div class="col-12 text-center mb-2"><img width="auto" height="auto"
        src="../../../../assets/images/logo/refus@2x.png" alt="" style="width: 35px;"></div>
    <p class="col-12 text-center m-0">Votre commande n’a pas été enregistrée suite au refus du paiement.</p>
    <p class="col-12 text-center">Elle porte la référence YQICJVYNB</p>
  </div>
  <div class="row">
    <div class="col-12 col-md-6 text-center text-md-right">
      <button mat-flat-button class="h-btn-green" (click)="setEtape(2)">RÉESSAYER LE PAIEMENT</button>
    </div>
    <div class="col-12 col-md-6 text-center text-md-left">
      <button mat-flat-button class="h-btn-black" [routerLink]="['']">REVENIR A LA PAGE D’ACCUEIL</button>
    </div>
  </div>
</div>

<!-- <hr> -->
<!-- <app-ads></app-ads> -->
