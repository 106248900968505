<div class="spinner" style="position: fixed; top: 30%; z-index: 1;" *ngIf="showSpinner">
  <mat-spinner></mat-spinner>
</div>

<div class="row addProduit"  autocomplete="off" *ngIf="!errorPaiement">
  <div class="col-12" style="background-color: white;">
    <div class="col-12 my-2" style="padding: 0;" *ngIf="!isExist">
      <mat-label>Vous souhaitez vendre votre article plus rapidement ? Sélectionnez une offre pour mettre en avant votre annonce.</mat-label>
      
      <span style="padding: 0 1.5rem 0 .5rem;">Non</span>
      <mat-slide-toggle color="sucess" [(ngModel)]="isAvant" (change)="stripeInit()"></mat-slide-toggle>
      <span  style="padding: 0 1.5rem 0 3rem;">Oui</span>
    </div>
    <ng-template [ngIf]="isAvant">
      <div class="col-12 my-2 listChoix"  style="padding: 15px 0 0;">
        <div class="d-flex align-items-stretch flex-wrap">
          <div class="col-12 col-md-4" *ngFor="let choix of listChoix;let i=index" (click)="selectChoix(choix)" [ngClass]="{'mt-md-3': i>2}">
            <div class="card text-center" style="width: 100%;height: 100%;">
              <div class="col-12 radioChamp">
                <mat-radio-group [(ngModel)]="choixOffre" aria-labelledby="example-radio-group-label">
                  <mat-radio-button [value]="choix"></mat-radio-button>
                </mat-radio-group>
              </div>
              <div class="col-12 card-body">
                <p>
                  <span> {{choix.description}} à</span>
                  <span class="prix" style="color: #28a745"> {{choix.price}} € HT</span>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-12 my-3"  style="padding: 0;">
        <div class="card">
          <div class="card-body">
            <div class="col-12 form-group">
              {{infosPaiements? ' Vous avez déjà une carte enregistrée lors de votre paiement précédente':''}}
            </div>
            <ng-container *ngIf="modifierCarte; else elseTemplate">
              <div class="col-12 my-2">
                <img width="auto" height="auto" class="" src="../../../../../assets/images/Groupe 596.png">
                <img width="auto" height="auto" class="" src="../../../../../assets/images/Groupe 594.png" style="margin: 0 10px;">
                <img width="auto" height="auto" class="" src="../../../../../assets/images/Groupe 599.png" style="margin-right: 10px;">
                <img width="auto" height="auto" src="assets/images/icons/American_Express.svg" alt="" style="width: 55px;">
              </div>
              <mat-form-field class="w-100">
                <mat-label>Nom du Tutilaire du Carte</mat-label>
                <input matInput placeholder="Nom du tutilaire du Carte" [(ngModel)]="titulaire">
              </mat-form-field>
              <form id="payment-form" style="border: 1px solid #d9d9d9; padding-left: 5px;">
                <span style="font-size: 12px; color: #d9d9d9;">Numero de la carte</span>
                <div id="cardElement" style="border-bottom: 1px solid #848484; padding-bottom: 5px;">
                  <!-- Elements will create input elements here -->
                </div>
                <!-- We'll put the error messages in this element -->
                <div id="cardErrors" role="alert"></div>
                <!-- <button id="submit">Pay</button> -->
              </form>
              <div class="col-12">
                <mat-checkbox [(ngModel)]="isSave"> Sauvegarder ma carte </mat-checkbox>
              </div>
          </ng-container>
          <ng-template #elseTemplate>
            <div class="d-flex justify-content-around py-2">
              <img width="auto" height="auto" *ngIf="infosPaiements.brand === 'visa'" src="../../../../../assets/images/Groupe 599.png">
              <img width="auto" height="auto" *ngIf="infosPaiements.brand !== 'visa'" src="../../../../../assets/images/Groupe 596.png">
              <span>{{infosPaiements.last4}}</span>
              <a (click)="modifierCarte = true;infosPaiements = undefined;stripeInit()">Modifier</a>
            </div>
          </ng-template>
          </div>
        </div>
      </div>
    </ng-template>
    <div class="col-12 text-center my-2" style="padding: 0; position: relative;">
      <button class="signin" style="position: relative;" [disabled]="isAvant && invalidCard && modifierCarte ? invalidCard : false" (click)="validerStep()"> {{ isAvant ? 'Mettre en vente' : 'Valider' }}</button>
    </div>
  </div>
</div>

<div class="col-12 addProduit"  autocomplete="off" *ngIf="errorPaiement">
  <div class="row" style="background-color: white;">
    <div class="col text-center my-2" style="padding: 0 5px;">
      <button class="signin" style="height: 100%;" (click)="reinitPaiement()"> Reessayer le paiement</button>
    </div>
    <div class="col text-center my-2" style="padding: 0 5px;">
      <button class="signin" style="height: 100%;" (click)="mettreAvant('avant')"> Mettre en avant sans payer </button>
    </div>
  </div>
</div>
