<div class="container py-5" style="background-color: '#F6FAF0'">
    <div class="row">

        <div class="col-12 text-center">
            <h2>Centre d'aide</h2>
        </div>

        <div class="col-12 col-md-12">
            <!-- <router-outlet></router-outlet> -->

            <nav aria-label="breadcrumb" *ngIf="itemChoisi">
                <ol class="breadcrumb">
                  <li class="breadcrumb-item"><a (click)="reset()">Centre d'aide</a></li>
                  <li class="breadcrumb-item"><a (click)="reset(true)">{{section}}</a></li>
                  <li class="breadcrumb-item active">{{sousSection}}</li>
                </ol>
            </nav>

           <ng-container *ngIf="itemChoisi; else elseTemplate">

                <mat-accordion id="centre-d-aide">

                    <mat-expansion-panel class="my-2" *ngFor="let content of itemChoisi.contents;let i=index" (opened)="panelOpenState = true" (closed)="panelOpenState = false">
                        <mat-expansion-panel-header>
                            <mat-panel-title>
                                {{i+1}}° {{content.question}}
                            </mat-panel-title>

                        </mat-expansion-panel-header>
                        <p>
                            {{content.answer}}
                        </p>
                    </mat-expansion-panel>
                </mat-accordion>


           </ng-container>
           <ng-template #elseTemplate>

                <mat-tab-group mat-align-tabs="center" [selectedIndex]="selectedSection">

                    <mat-tab label="Vendeur">
                        <mat-grid-list [cols]="breakpoint" rowHeight="{{height}}" (window:resize)="onResize($event)">
                            <mat-grid-tile *ngFor="let item of dataVendeur.univers" class="text-center">
                                <div class="d-flex flex-column item" style="cursor: pointer;" (click)="chooseItem(item,'Vendeur')">
                                    <i class="{{item.icon}} fa-3x"></i>
                                    <span class='mt-2'>{{item.title}}</span>
                                </div>
                            </mat-grid-tile>

                        </mat-grid-list>
                    </mat-tab>

                    <mat-tab label="Acheteur">

                        <mat-grid-list [cols]="breakpoint" rowHeight="{{height}}" (window:resize)="onResize($event)">
                            <mat-grid-tile *ngFor="let item of dataAcheteur.univers" class="text-center">
                                <div class="d-flex flex-column item" style="cursor: pointer;" (click)="chooseItem(item,'Acheteur')">
                                    <i class="{{item.icon}} fa-3x"></i>
                                    <span class='mt-2'>{{item.title}}</span>
                                </div>
                            </mat-grid-tile>

                        </mat-grid-list>

                    </mat-tab>

                </mat-tab-group>

            </ng-template>


        </div>
    </div>
</div>

<ng-container *ngIf="addFooter">
    <app-ads></app-ads>

    <!-- <app-footer></app-footer> -->
</ng-container>
