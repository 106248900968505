<mat-menu #menu>
  <span class="ddpMedium mt-1" mat-menu-item *ngIf="!isChild" style="height: auto; line-height: 30px;" (click)="goTo('univ')">Voir toutAAA</span>

  <span class="ddpMedium mt-1" mat-menu-item *ngIf="items.length > 1 && isChild" style="height: auto; line-height: 30px;" (click)="goTo('univ')">Voir toutBBB</span>
  <ng-container *ngFor="let item of items">
    <span class="ddpMedium" *ngIf="!item.children || item.children.length === 0; else branch" mat-menu-item
      style="height: auto; line-height: 30px;" (click)="goTo('univ', item)">{{item.nom}}CCC</span>
    <ng-template #branch>
      <span class="ddpMedium" mat-menu-item [matMenuTriggerFor]="innerPanel.menu" style="height: auto; line-height: 30px;">{{item.nom}}DDD</span>
      <app-menu-item #innerPanel [items]="item.children" [mere]="item" [univer]="univer" [isChild]="true"></app-menu-item>
    </ng-template>
  </ng-container>

</mat-menu>
