import * as Stomp from 'webstomp-client';
import * as SockJS from 'sockjs-client';
import { environment } from 'src/environments/environment';
import { StorageService } from './storage.service';
// import { isPlatformBrowser } from '@angular/common';
import { Inject, OnInit, PLATFORM_ID } from '@angular/core';

export class WebSocketAPI {
  topic = '/topic/HADEEN.wishlist.*';
  stompClient: any;

  interval: any;
  callback = [];
  isConnected = false;
  subscriptionIds;

  constructor(@Inject(PLATFORM_ID) private platformId?, private storageService?: StorageService) {
  }

  getUrl() {
    // let token;
    // if (isPlatformBrowser(this.platformId)) {
    //   // token = localStorage.getItem('Token');
    //   token = localStorage.getItem('Token');
    //   // token = this.storageService.getItem('Token');
    //   if (token) {
    //     const stoken = btoa(`WEB:${token}`);
    //     return `${environment.socket_url}?access_token=${ stoken }`;
    //   }
    //   return `${environment.socket_url}`;
    // }

    const token = localStorage.getItem('Token');
    // const token = this.storageService.getItem('Token');
    if (token) {
      const stoken = btoa(`WEB:${token}`);
      return `${environment.socket_url}?access_token=${stoken}`;
    }
    return `${environment.socket_url}`;
  }

  init() {
    this.callback = [];
    this.isConnected = false;
    this.subscriptionIds = [];
  }

  connectTo() {
    const ws = new SockJS(this.getUrl());
    this.stompClient = Stomp.over(ws);
    this.stompClient.debug = () => {};
    const _this = this;
    _this.stompClient.connect({},
      frame => {
        this.successCallback();
      },
      e => {
        this.failure();
      }
    );
  }
  failure() {
    clearInterval(this.interval);
    this.isConnected = false;
    this.interval = setInterval(() => {
      this.connectTo();
    }, 3000);
  }
  successCallback() {
    clearInterval(this.interval);
    this.isConnected = true;
    for (const cb of this.callback) {
      cb();
    }
  }
  _disconnect() {
    if (this.stompClient !== null) {
      this.stompClient.disconnect();
    }
  }
  registerCallback(cb) {
    this.callback.push(cb);
    if (this.isConnected) {
      cb();
    }
  }
  subscribe(destination, cb) {
    if (this.isConnected) {
      this.subscriptionIds.push(this.stompClient.subscribe(destination, cb));
    }
    this.callback.push(() => {
      this.subscriptionIds.push(this.stompClient.subscribe(destination, cb));
    });
  }
  send(path, data) {
    if (this.stompClient) {
      this.stompClient.send(path, JSON.stringify(data));
    } else {
      this.connectTo();
      setTimeout(() => {
        this.stompClient.send(path, JSON.stringify(data));
      }, 1000);
    }
  }
}
