<div class="contactez-nous__form mb-4">
  <!-- <form class="example-form"> -->
    <h4 class="col-12 mb-0">Message</h4>

    <hr class="uk-divider-icon mt-2">

    <mat-form-field class="example-full-width col-6">
      <mat-label>Email</mat-label>
      <input matInput placeholder="Email">
      <mat-hint style="color: red;">Connecte-toi pour t'identifier</mat-hint>
    </mat-form-field>

    <mat-form-field class="example-full-width col-6">
      <mat-label>Téléphone</mat-label>
      <input matInput placeholder="+33123456789">
    </mat-form-field>

    <hr class="uk-divider-icon">

    <mat-form-field class="example-full-width col-12">
      <mat-label>Sujet</mat-label>
      <textarea matInput placeholder="Sujet" [(ngModel)]="sujetContact"></textarea>
    </mat-form-field>
    <mat-form-field class="example-full-width col-12">
      <mat-label>Message</mat-label>
      <textarea matInput placeholder="Message" [(ngModel)]="messageContact"></textarea>
    </mat-form-field>

    <hr class="uk-divider-icon">

    <div class="row p-4">
      <button class="col-auto" mat-flat-button style="background-color: red; color: #ffffff;">FICHIER</button>
    </div>

    <p class="col-12">AJOUTER UN AUTRE FICHIER</p>

    <hr class="uk-divider-icon mb-0">

    <div class="row p-4">
      <button class="col-auto ml-auto" mat-flat-button style="color: red;">C'EST REGLE MERCI</button>
      <button class="col-auto" mat-flat-button style="background-color: red; color: #ffffff" (click)="mailMe()">ENVOYER LE MESSAGE</button>
    </div>
  <!-- </form> -->
</div>
