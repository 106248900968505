<div style="background-color: #475850; color: white;padding-bottom: 2%; padding-top: 4%;">
  <div class="container">
    <div class="row">
      <div class="d-none d-md-flex w-100">
        <div class="col-sm-12 col-md-3 col-lg-3 join" style="margin-top: -8px;">
          <div>
            <div class="col-md-12">
              <a href="https://www.hadeen-place.fr">
                <img width="auto" height="auto" loading="eager" class="img-fluid" src="assets/images/hadeen-logo-white.svg" alt="hadeen-place" title="hadeen-place" style="width: 200px;">
                <p class="my-2" style="text-align: center;font-weight: 300;font-size: 10px; text-decoration: none; color: white;
                  font-family: 'SFCompactDisplay-Regular';">
                  Copyright {{currentYear}} &#169; www.hadeen-place.fr
                </p>
              </a>
            </div>
            <div class="col-md-12 text-center" style="margin-top: 2.5%;">
              <a href="https://www.vatilab.com">
                <img width="auto" height="auto" loading="lazy" src="../../../../assets/images/vatilab-logo-white.png" alt="Vatilab" title="Vatilab" style="max-width: 100px; height: 20px;">
              </a>
              <a href="https://www.kateandyou.com">
                <p class="my-1"
                  style="text-align: center;font-weight: 300;font-size: 10px; text-decoration: none; color: white; font-family: 'SFCompactDisplay-Regular';">
                  Based on Kate&You MarketPlace’ solution
                </p>
              </a>
            </div>
          </div>
        </div>
        <div class="col-sm-12 col-md-3 col-lg-3 join text-center">
          <div style=" margin-bottom: 7%;">
            <p class="mb-1 text" style="font-size: 15px; font-family: 'SFCOmpact Semibold';">REJOIGNEZ LA COMMUNAUTÉ</p>
            <div class="image-card" style="display: inline-block;">
              <a href="https://www.facebook.com/hadeen.place.fr/" target="blank"
                style="padding: 5px;">
                <!-- <i class="fab fa-facebook-f" style="color: white;"></i> -->
                <img src="../../../../assets/images/icons/facebook.png" alt="Facebook" width="22">
                <span class="d-none">facebook</span>
              </a>
              <a href="#" target="_blank"
                style="padding: 5px;">
                <!-- <i class="fab fa-twitter" style="color: white;"></i> -->
                <img src="../../../../assets/images/icons/youtube.png" alt="Facebook" width="25">
                <span class="d-none">twitter</span>
              </a>
              <a href="https:///www.instagram.com/hadeen.place/" target="_blank"
                style="padding: 5px;">
                <!-- <i class="fab fa-instagram" style="color: white;"></i> -->
                <img src="../../../../assets/images/icons/instagram.png" alt="Facebook" width="22">
                <span class="d-none">instagram</span>
              </a>
            </div>
          </div>

          <div>
            <p class="text mb-0" style="font-size: 15px; font-family: 'SFCOmpact Semibold';">
              <!-- style="width: 12px;position: absolute;left: 58px;" -->
              <img src="assets/images/icons/secure.svg" alt="icon paiment" class="my-0 mr-1">
              PAIEMENT SÉCURISÉ
            </p>
            <div class="image-card" style="display: block;margin: 0 auto;">
              <img width="auto" height="auto" src="assets/images/card/master card.svg" loading="lazy" alt="Master Card" title="Master Card" style="width:36px;margin-right: 5px;">
              <img width="auto" height="auto" src="assets/images/card/cb.svg" loading="lazy" alt="C B" title="C B" style="width:29px;">
              <img width="auto" height="auto" src="assets/images/card/visa.svg" loading="lazy" alt="VISA" title="VISA" style="width:27px;">
              <img class="mr-0" width="auto" height="auto" src="assets/images/card/american-express.png" loading="lazy" alt="American express" title="American express" style="width: 28px;height: 26px;">
            </div>
          </div>
        </div>

        <div class="col-md-3 col-lg-3 offset-lg-1">
          <p class="mb-2" style="font-size: 15px; font-family: 'SFCOmpact Semibold';">ESPACE INFORMATIONS</p>
          <p class="my-1" *ngFor="let espace of espaces">
            <span (click)="goTo(espace)" style="cursor: pointer;font-size: 14px;">{{espace}}</span>
          </p>
        </div>

        <div class="col-md-3 col-lg-2">
          <p class="mb-2" style="font-size: 15px; font-family: 'SFCOmpact Semibold';">NOUS CONNAÎTRE</p>
          <p class="my-1" *ngFor="let c of connaitres">
            <span (click)="goTo(c)" style="cursor: pointer;font-size: 14px;">{{c}}</span>
          </p>
        </div>
      </div>
      <!-- version mobile -->
      <div class="col-12 mt-2 d-block d-md-none" style="margin-bottom: 1%;">
        <div class="row">
          <div class="col-6">
            <div>
              <a href="https://www.hadeen-place.fr">
                <img width="auto" height="auto" loading="eager" class="img-fluid" src="assets/images/hadeen-logo-white.svg" alt="hadeen-place" title="hadeen-place" style="width: 145px;height: 100%;">
                <p class="mt-2 mb-1" style="text-align: center;font-weight: 300;font-size: 7px; text-decoration: none; color: #FFF;
                  font-family: 'SFCompactDisplay-Regular';">
                  Copyright {{currentYear}} &#169; www.hadeen-place.fr<!--</i>-->
                </p>
              </a>
            </div>
            <div class="text-center" style="margin-top: 2.5%;">
              <a href="https://www.vatilab.com">
                <img width="auto" height="auto" loading="lazy" src="../../../../assets/images/vatilab-logo-white.png" alt="Vatilab" title="Vatilab" style="max-width: 100px; height: 13px;">
              </a>
              <a href="https://www.kateandyou.com">
                <p class="my-1"
                  style="text-align: center;font-weight: 300;font-size: 7px; text-decoration: none; color: #FFF; font-family: 'SFCompactDisplay-Regular';">
                  Based on Kate&You MarketPlace’ solution
                </p>
              </a>
            </div>
          </div>
          
            <div class="col-6 join text-center" style="margin-bottom: 3%;">
              <div style=" margin-bottom: 0%;">
                <p class="text" style="padding-top: 10px;font-size: 10px; font-family: 'SFCOmpact Semibold'; text-align: center; margin-bottom: 10px;">REJOIGNEZ LA COMMUNAUTÉ</p>
                <div class="image-card" style="display: inline-block;">
                  <a href="https://www.facebook.com/hadeen.place.fr/" target="blank"
                    style="padding: 5px;">
                    <!-- <i class="fab fa-facebook-f" style="color: white;"></i> -->
                    <img src="../../../../assets/images/icons/facebook.png" alt="Facebook" width="16">
                    <span class="d-none">facebook</span>
                  </a>
                  <a href="#" target="_blank"
                    style="padding: 5px;">
                    <!-- <i class="fab fa-twitter" style="color: white;"></i> -->
                    <img src="../../../../assets/images/icons/youtube.png" alt="Facebook" width="19">
                    <span class="d-none">twitter</span>
                  </a>
                  <a href="https:///www.instagram.com/hadeen.place/" target="_blank"
                    style="padding: 5px;">
                    <!-- <i class="fab fa-instagram" style="color: white;"></i> -->
                    <img src="../../../../assets/images/icons/instagram.png" alt="Facebook" width="16">
                    <span class="d-none">instagram</span>
                  </a>
                </div>
              </div>
            </div>
        </div>
        <div class="row mt-3">
            <div class="col-4">
              <p style="font-size: 10px; font-family: 'SFCOmpact Semibold';margin-bottom: 2px;">ESPACE INFORMATIONS</p>
              <div class="my-0" *ngFor="let espace of espaces">
                <span (click)="goTo(espace)" style="cursor: pointer;font-size: 10px;">{{espace}}</span>
              </div>
            </div>
            <div class="col-4 join px-0">
              <div>
                <p class="text" style="font-weight: 400; font-size: 10px; font-family: 'SFCOmpact Semibold'; text-align: center; margin-bottom: 2px;">PAIEMENT SÉCURISÉ</p>
                <div class="image-card" style="display: block;margin: 0 auto;">
                  <img width="auto" height="auto" src="assets/images/card/master card.svg" loading="lazy" alt="Master Card" title="Master Card" style="width:20px; margin-right: 3px;">
                  <img width="auto" height="auto" src="assets/images/card/cb.svg" loading="lazy" alt="C B" title="C B" style="width:20px; ">
                  <img width="auto" height="auto" src="assets/images/card/visa.svg" loading="lazy" alt="VISA" title="VISA" style="width:20px;">
                </div>
              </div>
            </div>
            <div class="col-4">
              <p style="font-size: 10px; font-family: 'SFCOmpact Semibold';margin-bottom: 2px;">NOUS CONNAÎTRE</p>
              <div class="my-0" *ngFor="let c of connaitres">
                <span (click)="goTo(c)" style="cursor: pointer;font-size: 10px;">{{c}}</span>
              </div>
            </div>
        </div>

      </div>
    </div>
  </div>
</div>

<!-- modal pour contacter le moderateur -->
<div class="modal fade" id="modalContactModerateur2" tabindex="-1" role="dialog" aria-labelledby="modalLabel"
  aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content" style="border-radius: 8px;">

      <div class="modal-header pb-0">
        <div class="col-12">
          <p>À : Hadéen-place</p>
        </div>
      </div>
      <div class="modal-body">
        <div class="col-sm-12">
          <textarea class="form-control col-sm-12 area" rows="5" placeholder="Ecrire le message ici..."
            [(ngModel)]="messageText"></textarea>
          <button class="h-btn-grey-green float-right mt-2 px-3" data-dismiss="modal" mat-flat-button
            (click)="sendMessage()">Envoyer</button>
        </div>
      </div>

    </div>
  </div>
</div>
<!-- end modal pour contacter le moderateur -->

<ng-template #loginTemplateFooter>
  <app-login [modal]="modalOpenService.modalRef2" [newsCustomer]="true"></app-login>
</ng-template>
