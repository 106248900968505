<div class="reset">
  <div class="header">
    <div class="container">
      <ul class="breadcrumb">
        <li><a routerLinkActive="active" [routerLinkActiveOptions]="{exact:true}" routerLink="/">Hadéen-place</a></li>
        <li>Mot de passe oublié?</li>
      </ul>
    </div>
    <div class="container body">
      <div *ngIf="step">
        <h1>Mot de passe oublié?</h1>
        <div>
          <h4>Veuillez entrer votre adresse e-mail ci dessous. Vous allez recevoir un lien pour reinitialisé votre mot de passe. </h4>
          <form class="reset">
            <mat-form-field class="example-full-width w-100">
              <mat-label>Adresse email*</mat-label>
              <input type="email" matInput [formControl]="emailFormControl" placeholder="Ex. hadeen@example.com">
              <mat-error *ngIf="emailFormControl.hasError('email') && !emailFormControl.hasError('required')">
                Merci d'entré une adresse email valide
              </mat-error>
              <mat-error *ngIf="emailFormControl.hasError('required')">
                l'email est <strong>obligatoire</strong>
              </mat-error>
            </mat-form-field>
            <p>Le mot de passe doit contenir au moins une Majuscule, une minuscule et un chiffre</p>
            <button (click)="Soumettre()">Récupérer mon compte</button>
          </form>
        </div>
      </div>
      <div *ngIf="!step">
        <h1>Mot de passe oublié?</h1>
        <h4>Nous vous avons envoyé un code pour réinitialiser votre mot de passe (merci de vérifier aussi dans vos courriels indésirables)</h4>
        <form class="step2" [formGroup]="confirmForm">
          <mat-form-field class="w-100">
            <mat-label>Code reçu</mat-label>
            <input matInput formControlName="cff_code" type="password">
          </mat-form-field>
          <h2>Votre nouveau mot de passe</h2>
          <mat-form-field class="w-100">
            <mat-label>Nouveau mot de passe</mat-label>
            <input matInput type="password" formControlName="cff_newMdp">
          </mat-form-field>
          <mat-form-field class="w-100">
            <mat-label>Confirmer votre mot de passe</mat-label>
            <input matInput [type]="hide ? 'password' : 'text'" formControlName="cff_newMdpConfirm">
            <button mat-icon-button matSuffix (click)="hide = !hide" [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hide" style="border: none; background-color: transparent; color: #000;">
              <mat-icon>{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
            </button>
          </mat-form-field>
          <p>Le mot de passe doit contenir au moins une Majuscule, une minuscule et un chiffre</p>
          <button (click)="Envoyer()">Récupérer mon compte</button>
        </form>
      </div>
    </div>
  </div>
</div>
