<div class="spinner" style="position: fixed; top: 30%; left: 45%; z-index: 1;" *ngIf="showSpinner">
    <mat-spinner></mat-spinner>
</div>

<div class="row">

    <div class="col-12 col-md-12">
        <!-- Liste d'achats -->
        <div class="row">
            <div class="col-12 mb-4">
                <h1 class="text-center">MA LISTES D’ACHATS</h1>
            </div>

            <!-- Detail -->
            <div class="col-12 mb-4" *ngIf="codeAchatList && myProductsList">
                <div style="padding: 5px; border: 1px solid #D6D6D6; border-bottom: none; cursor: pointer;">
                    <div class="col-12 col-md-10 offset-md-1">
                        <div class="col text-center" style="font-size: 16px">{{ myProductsList.achat.event }}: {{ myProductsList.achat.title }}</div>
                    </div>
                </div>

                <div class="mb-3" style="padding: 5px; border: 1px solid #D6D6D6; cursor: pointer;">
                    <div class="row pl-3 pr-3" *ngIf="myProductsList">
                        <div class="col-4 p-1 text-ce" *ngFor="let itemProduct of myProductsList.sous_produits" style="margin-bottom: 25px;">
                            <img width="auto" height="auto" [routerLink]="'/detail-produit/' + itemProduct.produit.id.produitId" [src]="itemProduct.images[0].nom" alt="" style="height: 225px;">
                            <p class="mb-0 text-center" style="font-size: 12px;">{{ itemProduct.produit.model }}</p>
                            <p class="mb-0 text-center" style="font-size: 12px;">
                                {{ itemProduct.produit.prix_promotion > 0 ? itemProduct.produit.prix_promotion : itemProduct.produit.prix }} €
                            </p>

                            <p class="mb-0 text-center" style="font-size: 12px;">Quantité: {{ itemProduct.disponibilite_liste_achat }}</p>
                            <p class="mb-0 text-center" style="font-size: 12px;">(Disponibilité: {{ itemProduct.disponibilite }})
                            </p>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
