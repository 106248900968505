import { UserService } from './../services/user.service';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/internal/operators/map';
import { catchError } from 'rxjs/internal/operators/catchError';
import { ToastrService } from 'ngx-toastr';
import { MenuListService } from '../services/menu-list.service';
import { StorageService } from '../services/storage.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  token: any;
  text: any;
  public tokenSubject: Observable<any> = of([]);
  tokenFinal: string;
  // userAgent = navigator.userAgent || navigator.vendor;

  constructor(
    private toastr: ToastrService, private userSerice: UserService,
    private menuListServ: MenuListService, private userService: UserService, private storageService: StorageService) {
    this.tokenSubject = this.menuListServ.getToken();
    // if (/android/i.test(this.userAgent)) {
    //   this.typeDevice = 'IOS';
    // } else if (/iPad|iPhone|iPod/.test(this.userAgent)) {
    //   this.typeDevice = 'ANDROID';
    // } else {
    //   this.typeDevice = 'WEB';
    // }
    // let typeDevice = 'WEB';
    // if (localStorage.typeDevice) {
    //   typeDevice = localStorage.getItem('type_device');
    // }
    this.tokenSubject.subscribe((data: any) => {
      this.token = data;
      if (this.storageService.getItem('Token') || data !== '') {
        this.token = this.storageService.getItem('Token');
        // this.tokenFinal = 'Basic ' + btoa(`${typeDevice}: ${this.token}`);
      } else {
        this.tokenFinal = '';
      }
    });
  }
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (this.userService.typeDevice) {
      // this.tokenFinal = 'Basic ' + btoa(`${this.userService.typeDevice}:${this.token}`);
      this.tokenFinal = 'Basic ' + this.b2a(`${this.userService.typeDevice}:${this.token}`);
    } else {
      // this.tokenFinal = 'Basic ' + btoa(`WEB:${this.token}`);
      this.tokenFinal = 'Basic ' + this.b2a(`WEB:${this.token}`);
    }
    const isConnected = this.storageService.getItem('User');
    if (isConnected) {
      if (!(req.url).includes('strapi')) {
        req = req.clone({
          setHeaders: {
            Authorization: this.tokenFinal,
          },
        });
      }
      return next.handle(req);
    } else {
      req = req.clone();
      return next.handle(req);
    }
  }

  b2a(a) {
    var c, d, e, f, g, h, i, j, o, b = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/=', k = 0, l = 0, m = "", n = [];
    if (!a) { return a; }
    do {
      c = a.charCodeAt(k++), d = a.charCodeAt(k++), e = a.charCodeAt(k++), j = c << 16 | d << 8 | e, f = 63 & j >> 18, g = 63 & j >> 12, h = 63 & j >> 6, i = 63 & j, n[l++] = b.charAt(f) + b.charAt(g) + b.charAt(h) + b.charAt(i);
    } while (k < a.length);
    return m = n.join(''), o = a.length % 3, (o ? m.slice(0, o - 3) : m) + '==='.slice(o || 3);
  }
}
