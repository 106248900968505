import {AbonnementComponent} from './Component/vendeur/abonnement/abonnement.component';
import {CguAppComponent} from './Component/cgu/cgu-app/cgu-app.component';
import {CompteNavComponent} from './Component/moncompte/compte-nav/compte-nav.component';
import {DetailVenteComponent} from './Component/moncompte/commande/vente-list/detail-vente/detail-vente.component';
import {VenteListComponent} from './Component/moncompte/commande/vente-list/vente-list.component';
import {ConsulterComponent} from './Component/moncompte/achat/consulter/consulter.component';
import {ListeByCodeComponent} from './Component/moncompte/achat/liste-by-code/liste-by-code.component';
import {DetailCommandeComponent} from './Component/moncompte/commande/detail-commande/detail-commande.component';
import {NgModule, Component} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {HomeComponent} from './Component/home/home.component';
import {ListingProduitComponent} from './Component/produit/listing-produit/listing-produit.component';
import {DetailProduitComponent} from './Component/produit/detail-produit/detail-produit.component';
import {MdpoublierComponent} from './Component/mdpoublier/mdpoublier.component';
import {PanierComponent} from './Component/panier/panier.component';
import {DetailVendeurComponent} from './Component/vendeur/detail-vendeur/detail-vendeur.component';
import {DevenirvendeurComponent} from './Component/vendeur/devenirvendeur/devenirvendeur.component';
import {LoginDevenirVendeurComponent} from './Component/vendeur/login-devenir-vendeur/login-devenir-vendeur.component';
import {AjoutProdMobComponent} from './Component/produit/ajout-prod-mob/ajout-prod-mob.component';
import {MoncompteComponent} from './Component/moncompte/moncompte.component';
import {CommandeComponent} from './Component/moncompte/commande/commande.component';
import {ProfilComponent} from './Component/moncompte/profil/profil.component';
import {MessageComponent} from './Component/moncompte/message/message.component';
import {BoutiqueComponent} from './Component/moncompte/boutique/boutique.component';
import {EnviesComponent} from './Component/moncompte/envies/envies.component';
import {AchatComponent} from './Component/moncompte/achat/achat.component';
import {AdrsLivraisonComponent} from './Component/panier/adrs-livraison/adrs-livraison.component';
import {PaymentComponent} from './Component/panier/payment/payment.component';
import {SuccessComponent} from './Component/panier/success/success.component';
import {MessagedetailComponent} from './Component/moncompte/messagedetail/messagedetail.component';
import {PromotionBoutiqueComponent} from './Component/moncompte/promotion-boutique/promotion-boutique.component';
import {AuthGuard} from './guard/auth.guard';
import {DetailComponent} from './Component/moncompte/achat/consulter/detail/detail.component';
import {CguComponent} from './Component/cgu/cgu.component';
import {CentreDAideComponent} from './Component/centre-d-aide/centre-d-aide.component';
import {ResultSearchComponent} from './Component/result-search/result-search.component';
import {PageDynamiqueComponent} from './Component/page-dynamique/page-dynamique.component';
import {FraisLivraisonComponent} from './Component/moncompte/frais-livraison/frais-livraison.component';
import { UniverCategorieComponent } from './Component/univer-categorie/univer-categorie.component';
import { MentionsLegalesComponent } from './Component/mentions-legales/mentions-legales.component';
import { PanierNewComponent } from './Component/panier-new/panier-new.component';

const routes: Routes = [
  {path: '', component: HomeComponent},
  {path: 'recherche', component: ResultSearchComponent},
  {path: 'all-product', component: ListingProduitComponent},
  // {path: 'all-product/:univer', component: ListingProduitComponent},
  // {path: 'all-product/:univer/:sous_univer', component: ListingProduitComponent},
  {path: 'all-product/en-avant/:slug', component: ListingProduitComponent},
  {path: 'all-product/:univer', component: UniverCategorieComponent},
  {path: 'all-product/:univer/:sous_univer', component: UniverCategorieComponent},
  {path: 'all-product/:univer/:sous_univer/:categorie', component: ListingProduitComponent},
  {path: 'reset-mdp', component: MdpoublierComponent},
  {path: 'detail-produit/:id_produit', component: DetailProduitComponent},
  {path: 'achat/detail-produit/:id_produit', component: DetailComponent},
  {path: 'mon-panier',
    children: [
      {path: '', component: PanierComponent},
      {path: 'new', component: PanierNewComponent},
      {path: 'livraison', component: AdrsLivraisonComponent},
      {path: 'payment/:paiement_id', component: PaymentComponent},
      {path: 'success', component: SuccessComponent},
      { path: '**', component: PanierComponent }
    ],
  },
  {path: 'detail-vendeur/:id_vendeur', component: DetailVendeurComponent},
  {path: 'login-devenir-vendeur', component: LoginDevenirVendeurComponent},
  {path: 'devenir-vendeur', component: DevenirvendeurComponent},
  {path: 'abonnement', component: AbonnementComponent},
  {path: 'devenir-vendeur-app', component: DevenirvendeurComponent},
  {path: 'ajout-prod', component: AjoutProdMobComponent},
  {path: 'edit-prod/:idProduit', component: AjoutProdMobComponent},
  {path: 'code-achat/:codeAchatList', component: ListeByCodeComponent},
  {path: 'compte', component: MoncompteComponent,
    children: [
      {path: '', component: CompteNavComponent},
      {path: 'mon-profil', component: ProfilComponent},
      {path: 'mes-commandes',
        children: [
          {path: '', component: CommandeComponent},
          {path: 'achats/:idCommande', component: DetailVenteComponent},
          {
            path: 'ventes',
            children: [
              {path: '', component: VenteListComponent},
              {path: ':idCommande', component: DetailCommandeComponent},
              { path: '**', component: VenteListComponent }
            ]
          },
          { path: '**', component: CommandeComponent }
        ]
      },
      {path: 'mon-abonnement', component: AbonnementComponent},
      {path: 'mes-messages', component: MessageComponent},
      {path: 'frais', component: FraisLivraisonComponent},
      {path: 'mes-achats',
        children: [
          {path: '', component: AchatComponent},
          {path: 'consulter-achat', component: ConsulterComponent},
          {path: 'consulter-achat/:id_produit', component: DetailComponent},
          {path: ':idList', component: AchatComponent},
          { path: '**', component: AchatComponent }
        ]
      },
      {path: 'favoris', component: EnviesComponent},
      {path: 'ma-boutique', component: BoutiqueComponent},
      {path: 'promouvoir-ma-boutique', component: PromotionBoutiqueComponent},
      {path: 'promouvoir-ma-boutique/slider', component: PromotionBoutiqueComponent},
      {path: 'promouvoir-ma-boutique/a-la-une', component: PromotionBoutiqueComponent},
      {path: 'messages-details/:discussion_id', component: MessagedetailComponent},
      {path: '**', component: CompteNavComponent}
    ],
    canActivate: [AuthGuard]
  },
  {path: 'frais-livraison', component: FraisLivraisonComponent },
  {path: 'conditions-de-ventes', component: CguComponent },
  {path: 'conditions-generales-de-ventes-app', component: CguAppComponent },
  {path: 'mentions-legales', component: MentionsLegalesComponent },
  {path: 'centre-d-aide', component: CentreDAideComponent },
  {path: 'centre-d-aide-app', component: CentreDAideComponent},
  {path: 'Blog',
    children: [
      {path: '', component: PageDynamiqueComponent},
      {path: ':slug-page', component: PageDynamiqueComponent},
      {path: '**', component: PageDynamiqueComponent }
    ],
  },
  {path: '**', component: HomeComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    scrollPositionRestoration: 'enabled'})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
