<div class="spinner" style="position: fixed; top: 30%; left: 45%; z-index: 1;" *ngIf="showSpinner">
  <mat-spinner></mat-spinner>
</div>

<div class="row">
  <div class="col-12 col-md-12">
    <!-- Liste d'achats -->
    <div class="row">
      <div class="col-12 mb-0">
        <div class="header">
          <ul class="breadcrumb mb-0">
            <li [routerLink]="['/compte']"><a>Compte</a></li>
            <li class="active"><a>Achat</a></li>
          </ul>
        </div>
      </div>
      <div class="col-12 mb-4">
        <h1 class="text-center">MA LISTES D’ACHATS</h1>
      </div>

      <!-- Detail -->
      <div class="col-12 mb-4" *ngIf="idList && myProductsList">
        <div style="padding: 5px; border: 1px solid #D6D6D6; border-bottom: none; cursor: pointer;">
          <div class="row">
            <div class="col-auto" (click)="supprimerLA()">SUPPRIMER <i class="fas fa-trash-alt"></i></div>
            <div class="col">
              <div class="col text-center" style="font-size: 16px">
                {{ myProductsList.achat.event }}: {{ myProductsList.achat.title }}
              </div>
            </div>
            <div class="col-auto" (click)="validerListe(templateResultList2)">PARTAGER <i class="fas fa-share-alt"></i>
            </div>
          </div>
        </div>

        <div class="mb-3" style="padding: 5px; border: 1px solid #D6D6D6; cursor: pointer;">
          <div class="col-12 text-right">
          </div>
          <div class="row pl-3 pr-3" *ngIf="myProductsList">
            <div class="col-6 col-md-4 p-1 text-center" *ngFor="let itemProduct of myProductsList.sous_produits"
              style="margin-bottom: 25px;">
              <div [routerLink]="'/detail-produit/' + itemProduct.produit.slug">
                <img width="auto" height="auto" [src]="itemProduct.images[0].nom" alt="" style="height: 225px;">
                <h4 class="mb-0 text-center"
                  style="font-size: 14px; font-family: 'SFCompactDisplay-Medium'; color: #1a1a1a;">{{
                  itemProduct.produit.model }}</h4>
                <p class="mb-0 text-center" style="font-size: 12px;" *ngIf="itemProduct.produit.prix_promotion > 0">
                  {{ itemProduct.produit.prix_promotion | number : '1.2-2' }} €
                </p>
                <p class="mb-0 text-center" style="font-size: 12px;"
                  *ngIf="!itemProduct.produit.prix_promotion || itemProduct.produit.prix_promotion === 0">
                  {{ itemProduct.produit.prix | number : '1.2-2' }} €
                </p>
                <div>
                  <div class="row">
                    <p class="col-12 mb-0" style="font-size: 13px;" *ngIf="itemProduct.taille">
                      taille: {{ itemProduct.taille.valeur}}
                    </p>
                    <p class="col-auto mb-0 text-center ml-auto" style="font-size: 13px;">Quantité: {{
                      itemProduct.disponibilite_liste_achat }}
                    </p>

                    <div class="col-auto" *ngIf="itemProduct.couleur">
                      <div class="m-auto"
                        [ngStyle]="{'background' : itemProduct.couleur.code_hexa === 'MULTI' ? 'url(assets/images/multicolor.png)' : itemProduct.couleur.code_hexa}"
                        style="width: 20px; height: 20px;"></div>
                    </div>
                    <p class="col-auto mb-0 text-center mr-auto" *ngIf="itemProduct.couleur" style="font-size: 13px;">{{ itemProduct.couleur.nom }}
                    </p>
                  </div>
                </div>
              </div>

              <p class="col-auto mt-2 mb-0 text-right" *ngIf="commonService.user.sousVendeur" style="font-size: 12px;"
                (click)="supprimerProduit(itemProduct)">Supprimer
              </p>
            </div>

            <h3 *ngIf="!myProductsList.sous_produits.length" class="col-12 text-center">Cette liste ne contient aucun
              produit</h3>
          </div>
        </div>
      </div>


      <!-- Creation -->
      <div class="col-12 mb-4" *ngIf="!idList">
        <div style=" padding: 5px; border: 1px solid #D6D6D6; border-bottom: none; cursor: pointer;">
          <div class="col-12 col-md-10 offset-md-1">
            <div class="row mt-3 mb-3">
              <div class="col text-right pr-0" (click)="showList = false">
                <button style="padding: 7px 12px;"
                  [ngClass]="showList ? 'h-btn-green-bordered': 'h-btn-black-bordered'">CRÉER UNE NOUVELLE
                  LISTE</button>
              </div>
              <div class="col pl-0" (click)="showList = true" style="font-size: 16px">
                <button style="padding: 7px 12px;"
                  [ngClass]="showList ? 'h-btn-black-bordered': 'h-btn-green-bordered'">MES LISTES D’ACHAT <span
                    *ngIf="productService.listeAchat">({{ productService.listeAchat.length }})</span></button>
              </div>
            </div>
          </div>
        </div>

        <div class="mb-3" style="padding: 5px; border: 1px solid #D6D6D6; cursor: pointer;">
          <div class="col-12 col-md-10 offset-md-1" *ngIf="showList">
            <div *ngFor="let item of productService.listeAchat" [ngStyle]="{'background-image': item.event === 'MARIAGE' ? 'url(assets/static/bg-marriage.png)':
                            item.event === 'NAISSANCE' ? 'url(assets/static/bg-naissance.png)':
                                item.event === 'ANNIVERSAIRE' ? 'url(assets/static/bg-anniv.png)': 'url(assets/static/bg-autres.png)'
                            }" style="border-radius: 8px; margin-bottom: 20px;">
              <p class="m-0 p-3" [routerLink]="[item.id]">
                <img width="auto" height="auto" *ngIf="item.event === 'MARIAGE'" src="../../../assets/images/logo/mariage@2x.png" alt=""
                  style="height: 18px; margin-right: 10px;">
                <img width="auto" height="auto" *ngIf="item.event === 'NAISSANCE'" src="../../../assets/images/logo/naissance@2x.png" alt=""
                  style="height: 18px; margin-right: 10px;">
                <img width="auto" height="auto" *ngIf="item.event === 'ANNIVERSAIRE'" src="../../../assets/images/logo/anniv@2x.png" alt=""
                  style="height: 18px; margin-right: 10px;">
                <img width="auto" height="auto" *ngIf="item.event === 'AUTRES'" src="../../../assets/images/logo/autres@2x.png" alt=""
                  style="height: 18px; margin-right: 10px;"> {{ item.event }}: {{ item.title }}
              </p>
            </div>
          </div>

          <div class="col-12 col-md-10 offset-md-1" *ngIf="!showList">
            <p class="m-0" style="font-family: 'SFCompactDisplay-Regular'; font-size: 16px; color: #6A6B6A;">Type de
              liste
            </p>
            <div class="row">
              <div class="col-6 col-md-3 mb-4">
                <button mat-flat-button class="w-100" (click)="setTypeList(1)"
                  [ngClass]="typeList === 1 ? 'h-btn-green': 'h-btn'">
                  <img width="auto" height="auto" *ngIf="typeList !== 1" src="../../../assets/images/logo/mariage@2x.png" alt=""
                    style="height: 20px;">
                  <img width="auto" height="auto" *ngIf="typeList === 1" src="../../../assets/images/logo/mariage-active@2x.png" alt=""
                    style="height: 20px;">
                  Mariage
                </button>
              </div>
              <div class="col-6 col-md-3 mb-4">
                <button mat-flat-button class="w-100" (click)="setTypeList(2)"
                  [ngClass]="typeList === 2 ? 'h-btn-green': 'h-btn'">
                  <img width="auto" height="auto" *ngIf="typeList !== 2" src="../../../assets/images/logo/naissance@2x.png" alt=""
                    style="height: 20px;">
                  <img width="auto" height="auto" *ngIf="typeList === 2" src="../../../assets/images/logo/naissance-active@2x.png" alt=""
                    style="height: 20px;">
                  Naissance
                </button>
              </div>
              <div class="col-6 col-md-3 mb-4">
                <button mat-flat-button class="w-100" (click)="setTypeList(3)"
                  [ngClass]="typeList === 3 ? 'h-btn-green': 'h-btn'">
                  <img width="auto" height="auto" *ngIf="typeList !== 3" src="../../../assets/images/logo/anniv@2x.png" alt=""
                    style="height: 20px;">
                  <img width="auto" height="auto" *ngIf="typeList === 3" src="../../../assets/images/logo/anniv-active@2x.png" alt=""
                    style="height: 20px;">
                  Anniversaire
                </button>
              </div>
              <div class="col-6 col-md-3 mb-4">
                <button mat-flat-button class="w-100" (click)="setTypeList(4)"
                  [ngClass]="typeList === 4 ? 'h-btn-green': 'h-btn'">
                  <img width="auto" height="auto" *ngIf="typeList !== 4" src="../../../assets/images/logo/autres@2x.png" alt=""
                    style="height: 20px;">
                  <img width="auto" height="auto" *ngIf="typeList === 4" src="../../../assets/images/logo/autres-active@2x.png" alt=""
                    style="height: 20px;">
                  Autres
                </button>
              </div>
            </div>

            <form [formGroup]="achatFormGroup">
              <div class="row mb-2">
                <mat-form-field class="example-full-width col-12 CInput" appearance="fill" [floatLabel]="'auto'">
                  <mat-label>Nom de la liste</mat-label>
                  <input matInput id="achatTitre" placeholder="Nom de la liste" formControlName="titreCtrl" required>
                </mat-form-field>
              </div>

              <div class="row mb-2">
                <span class="col-12 mb-2 text-secondary">Image de couverture de votre liste</span>
                <div class="col-12 hover-shadow" style="height: 128px;">
                  <img width="auto" height="auto" *ngIf="typeList === 1" src="../../../assets/static/bg-marriage.png" alt="">
                  <img width="auto" height="auto" *ngIf="typeList === 2" src="../../../assets/static/bg-naissance.png" alt="">
                  <img width="auto" height="auto" *ngIf="typeList === 3" src="../../../assets/static/bg-anniv.png" alt="">
                  <img width="auto" height="auto" *ngIf="typeList === 4" src="../../../assets/static/bg-autres.png" alt="">
                </div>
              </div>

              <div class="row mb-2">
                <div>
                  <div class="card-body">
                    <h6 class="card-title" style="color: #345B2B;">
                      <span
                        style="background-color: #345B2B; color: #ffffff; padding: 1px 8px; border-radius: 50%;">i</span>
                      &nbsp;
                      <span style="text-decoration: underline;">Comment ajouter des produits dans une liste ?</span>
                    </h6>
                    <div id="content">
                      <ul class="timeline mt-2">
                        <li class="event">
                          <p>
                            Les listes d’achat vous permettent de créer une liste dans laquelle vous pouvez ajouter un
                            ou plusieurs articles, pour une personne ou un événement, et de la partager avec vos
                            proches. Ils pourront ainsi acheter des articles de cette liste et les faire
                            livrer directement chez vous ou les offrir lors de l’événement.
                          </p>
                        </li>
                        <li class="event">
                          <p>
                            Pour créer les listes d'achats vous devez créer un compte, puis « créer une liste d’achat ».
                            Il vous suffira ensuite de la nommer.
                          </p>
                        </li>
                        <li class="event">
                          <p>
                            Pour ajouter des articles, vous devez aller sur le détail de l’article que vous souhaitez
                            ajouter à votre liste, puis cliquez sur «ajouter à une liste d’achat» et sélectionnez la
                            liste dans laquelle vous souhaitez l’ajouter. L'ajout d'article est illimité.
                          </p>
                        </li>
                        <li class="event">
                          <p>
                            Dès que vous ajoutez un article, il vous est proposé «d’ajouter d’autres articles» ou de
                            «partager la liste», il vous suffit de cliquer sur partager et de choisir le moyen de
                            partage (SMS, whatsapp, mail ...).
                          </p>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>

              <div class="row mb-4">
                <p class="m-0 col">
                  <span class="w-100">Ajouter à ma liste d’achat (Détail produit)</span>
                  <img width="auto" height="auto" src="../../../../assets/static/achatleft.png" alt="">
                </p>
                <p class="m-0 col-auto m-auto"><img width="auto" height="auto" src="../../../../assets/static/fleches.png" alt=""
                    style="width: 15px;"></p>
                <p class="m-0 col">
                  <span class="w-100">Confirmation</span>
                  <img width="auto" height="auto" src="../../../../assets/static/achatright22.png" alt="">
                </p>
              </div>


              <div class="row mb-2">
                <div class="col-12" id="content">
                  <ul class="mt-2 px-0">
                    <li class="event">
                      <p>
                        Si vos proches souhaitent découvrir votre liste, ils doivent se créer un compte puis rentrer le
                        code qu’ils ont reçu avec le partage de la liste dans l’espace « rechercher une liste ».
                      </p>
                    </li>
                    <li class="event">
                      <p>
                        Vous pouvez à tout moment consulter, modifier et partager vos listes d'achats.
                      </p>
                    </li>
                  </ul>
                </div>

              </div>

              <div class="row mb-2">
                <button mat-flat-button type="submit" class="h-btn-black m-auto pl-5 pr-5" (click)="createAchat()">
                  CREER LA LISTE
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>


<!-- Modal compte non valide -->
<div class="modal fade" id="staticBackdropListeAdd" data-backdrop="static" data-keyboard="false" tabindex="-1"
  aria-labelledby="staticBackdropLabel" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-body">
        <h4 class="text-center">
          Votre liste a bien été créée,
        </h4>
        <h4 class="text-center">
          Désormais, vous pouvez ajouter des produits à cette liste
        </h4>

        <div class="text-center mt-3">
          <button type="button" class="h-btn-green" style="padding: 5px 30px; width: fit-content;"
            (click)="closeModal('all-product')">Ok</button>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- Partager -->
<ng-template #templateResultList2>
  <div class="modal-header">
    <h4 class="modal-title pull-left">Partagez votre liste</h4>
    <button type="button" class="close pull-right" aria-label="Close"
      style="color: #000000; width: fit-content; margin-right: 0px; font-size: 22px;"
      (click)="modalRefListValid.hide()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <p>Code {{ myProductsList.achat.code }} - {{ myProductsList.achat.event }}: {{ myProductsList.achat.title }}</p>

    <p>Vous pouvez partager votre code sur les reseaux sociaux pour augmenter la visibilité de votre liste</p>

    <textarea class="form-control noOutline" (input)="onchangeInput()" id="exampleFormControlTextarea1" rows="9"
      [(ngModel)]="message2" style="font-size: 12px;"></textarea>

    <div class="mt-3 image-card w-100 text-center" style="display: inline-block;">
      <span>Partager sur: </span>
      <a href="https://api.whatsapp.com/send?text={{message}}" target="_blank"
        style="margin-left: 9px; background-color: #345B2B; padding: 8px 7px 4px 7px; border-radius: 50%;">
        <i class="fab fa-whatsapp" style="color: white; font-size: 21px;"></i>
      </a>
      <a href="mailto:?subject=Invitation&body={{message}}"
        style="margin-left: 9px; background-color: #345B2B; padding: 8px 5px 4px 5px; border-radius: 50%;">
        <i class="fas fa-at" style="color: white; font-size: 21px;"></i>
      </a>
      <a href="https://www.instagram.com/?url=https%3A//hadeen.vatilab.com%2Fcode-achat%2F{{message}}" target="_blank"
        style="margin-left: 9px; background-color: #345B2B; padding: 8px 6px 4px 6px; border-radius: 50%;">
        <i class="fab fa-instagram" style="color: white; font-size: 21px;"></i>
      </a>
      <a href="http://pinterest.com/pin/create/button/?url=http%3A%2F%2Fwww.aliasdmc.com%2Fdeveloppement%2Fwebmaster%2Ffaire_un_lien_de_partage_sur_les_reseaux_sociaux.php&media=http%3A%2F%2Fwww.aliasdmc.com%2Fimages%2Fthumbnail.jpg"
        target="_blank"
        style="margin-left: 9px; background-color: #345B2B; padding: 8px 8px 3px 8px; border-radius: 50%;">
        <i class="fab fa-pinterest-p" style="color: white; font-size: 21px;"></i>
      </a>
      <a href="sms:&body=Bonjour, merci de consulter mon liste d'achat, rendez-vous sur https://hadeenplace.fr"
        target="_blank"
        style="margin-left: 9px; background-color: #345B2B; padding: 8px 5px 3px 5px; border-radius: 50%;">
        <i class="fa fa-sms" style="color: white; font-size: 21px;"></i>
      </a>
    </div>

  </div>
</ng-template>
