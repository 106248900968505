import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { CommonService } from 'src/app/services/common.service';
import { StorageService } from 'src/app/services/storage.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-consulter',
  templateUrl: './consulter.component.html',
  styleUrls: ['./consulter.component.scss']
})
export class ConsulterComponent implements OnInit {

  showSpinner = false;
  codeList = '';
  dataList;

  constructor(private httpClient: HttpClient, private commonService: CommonService, private storageService: StorageService) { }

  ngOnInit(): void {
    const listConsulte = this.commonService.user.code_achat;
    if (listConsulte) {
      this.codeList = listConsulte;
      this.getListeAchatByCode(listConsulte);
    }
  }

  getListeAchatByCode(code) {
    if (this.codeList) {
      this.httpClient.get(`${environment.ws_url}achats/code/${code}`).subscribe((resp: any) => {
        this.dataList = resp.data;
        this.httpClient.get(`${environment.ws_url}users/acheteur/byId`).subscribe((respUser: any) => {
          this.storageService.setItem('User', JSON.stringify(respUser.data.user));
          this.commonService.user = respUser.data.user;
        });
      });
    }
  }
  deleteListeAchatActive() {
    this.storageService.removeItem('listeConsult');
    this.dataList = null;
    this.codeList = '';
  }
}
