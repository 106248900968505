import { CommonService } from './../../../services/common.service';
import { DialogPayementDialogComponent } from './../../common/dialog-payement-dialog/dialog-payement-dialog.component';
import { Router } from '@angular/router';
import { HttpService } from 'src/app/services/http.service';
import { Component, Inject, Input, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import { ToastrService } from 'ngx-toastr';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DatePipe, formatDate } from '@angular/common';

declare var Stripe;

@Component({
  selector: 'app-abonnement',
  templateUrl: './abonnement.component.html',
  styleUrls: ['./abonnement.component.scss'],
  providers: [DatePipe]
})
export class AbonnementComponent implements OnInit {

  @Input() isFull = true;
  @Input() isTitle = true;
  @Input() isCompte = false;
  abnsSelected: any;
  stripe = Stripe(environment.keys);
  allAbns: any;
  elements;
  card;
  titulaire;
  invalidCard = true;
  modifierCarte = true;
  infosPaiements;
  varAbonnement: any;
  varCart: any;
  errorState;
  dateNow = new Date();
  cardAllImage = [
    {title: 'visa', imgPath: '../../../../assets/images/card/visa.svg'},
    {title: 'mastercard', imgPath: '../../../../assets/images/card/master card.svg'},
    {title: 'amex', imgPath: '../../../../assets/images/card/american-express.png'},
  ];
  abnmSvg = [
    {logo: '../../../../assets/images/icons/1.PNG'},
    {logo: '../../../../assets/images/icons/2.PNG'},
    {logo: '../../../../assets/images/icons/3.PNG'},
    {logo: '../../../../assets/images/icons/4.PNG'}
  ];

  constructor(
    private httpService: HttpService, private toastr: ToastrService, private router: Router,
    public dialog: MatDialog, private datePipe: DatePipe, private commonService: CommonService) {
    }

  ngOnInit(): void {
    this.httpService.get(`abonnements?page=1&par_page=20`).subscribe((response: any) => {
      this.allAbns = response.data;
      let indSvg = 0;
      if (this.allAbns && this.allAbns.abonnements && this.allAbns.abonnements.length) {
        this.allAbns.abonnements.forEach(elt => {
          if (elt.mostSubscription) {
            this.selectPlan(elt);
          }
          if (elt.description) {
            elt['flag'] = this.abnmSvg[indSvg].logo;
            indSvg ++;
          }
        });
      }
    });
    this.getInfoPayement();
    this.getInfoAbonnement();
    if (this.router.url !== '/abonnement') {
      this.isCompte = true;
    }
  }


  stripeInit() {
    setTimeout(() => {
      this.elements = this.stripe.elements();
      const styleCss = {
        base: {
          color: '#32325d',
        }
      };
      this.card = this.elements.create('card', { hidePostalCode: true, style: styleCss });
      this.card.mount('#cardElement');
      this.card.addEventListener('change', (event) => {
        const displayError = document.getElementById('cardErrors');
        if (event.error || !event.complete) {
          displayError.textContent = event.error.message;
          this.invalidCard = true;
        } else {
          displayError.textContent = '';
          this.invalidCard = false;
        }
      });
      this.invalidCard = true;
    }, 500);
  }

  selectPlan(itemAbns) {
    this.abnsSelected = itemAbns.id;
  }

  openDialogPayement(itemAbns, typeDialog): void {
    if (itemAbns) {
      this.selectPlan(itemAbns);
    }
    if (itemAbns && (!itemAbns.price || itemAbns.price === 0)) {
      // this.router.navigate(['']);
      const formData = new FormData();
      formData.set(`is_save`, 'true');
      formData.set(`abonnement_id`, itemAbns.id);
      if (!this.modifierCarte) {
        formData.append(`use_saved`, 'true');
      } else {
        formData.append(`use_saved`, 'false');
      }
      this.httpService.post('abonnements/buy', formData).subscribe(
        (dataByu: any) => {
          this.getInfoAbonnement();
          this.getInfoPayement();
          this.toastr.success('Votre abonnement a bien été pris en compte', '', { timeOut: 5000 });
        },
        (err: any) => {
          this.toastr.error('erreur');
        }
      );
    } else {
      const dialogRef = this.dialog.open(DialogPayementDialogComponent, {
        data: {type: typeDialog, abns: itemAbns, abnsAct: this.varAbonnement},
        hasBackdrop: true,
        panelClass: 'bgHadeenColor',
        width: '380px'
      });

      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          if (result === 'create') {
            this.getInfoPayement();
            this.toastr.success('Votre carte a bien été modifié');
          }
          if (result === 'payement') {
            this.toastr.success('Votre payement est effectué');
          }
          setTimeout(() => {
            this.getInfoAbonnement();
            this.commonService.openDialog(false);
            if (!this.isCompte) {
              this.router.navigate(['']);
            }
          }, 500);
        }
      });
    }
  }

  getInfoPayement() {
    this.httpService.get('users/info-paiements').subscribe((respCart: any) => {
      this.varCart = respCart.data;
      if (this.varCart.response.data && this.varCart.response.data.length) {
        const brandShow = this.cardAllImage.filter(elt => elt.title === this.varCart.response.data[0].card.brand);
        if (brandShow.length) {
          this.varCart.response.data[0].card.imgPath = brandShow[0].imgPath;
        } else {
          this.varCart.response.data[0].card.imgPath = this.cardAllImage[0].imgPath;
        }
        // tslint:disable-next-line:max-line-length
        if ((this.varCart.response.data[0].card.exp_month <= this.dateNow.getMonth() + 1) && this.varCart.response.data[0].card.exp_year <= this.dateNow.getFullYear()) {
          this.errorState = 2;
        }
        // tslint:disable-next-line:max-line-length
        if ((this.varCart.response.data[0].card.exp_month === this.dateNow.getMonth() + 1) && this.varCart.response.data[0].card.exp_year === this.dateNow.getFullYear()) {
          this.errorState = 3;
        }
      }
    }, (error => { }));
  }

  getInfoAbonnement() {
    this.httpService.get('abonnements/check').subscribe(
      (respAbns: any) => {
        this.varAbonnement = respAbns.data;
        if (this.varAbonnement && this.varAbonnement.statut !== 'VALIDE') {
          this.errorState = 1;
        }
      },
      (err: any) => {
        this.varAbonnement = null;
      }
    );
  }

  cardFormat(brand, lastSSN) {
    if (brand === 'amex') {
      return `XXXX XXXXXX X${lastSSN}`;
    } else {
      return `XXXX XXXX XXXX ${lastSSN}`;
    }
  }

  openVoirPlusDialog(ind, varNom): void {
    let newNom = varNom;
    if (newNom !== 'sans abonnement') {
      newNom = newNom.replace('Abonnement ', '');
    }
    const dialogRef = this.dialog.open(DialogVoirPlus, {
      maxWidth: '650px',
      data: {index: ind, nom: varNom, nom2: newNom, abns: this.varAbonnement},
    });

    dialogRef.afterClosed().subscribe(result => {
      // this.animal = result;
    });
  }

  removeChar(texte, removed) {
    return texte.replace(removed, '');
  }
}


@Component({
  // tslint:disable-next-line:component-selector
  selector: 'dialog-voir-plus',
  templateUrl: 'dialog-voir-plus.html',
})
// tslint:disable-next-line:component-class-suffix
export class DialogVoirPlus {
  constructor(
    public dialogRef: MatDialogRef<DialogVoirPlus>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {}

  onNoClick(): void {
    this.dialogRef.close();
  }
}
