import { GoogleAnalyticsEventsService } from './../../../services/google-analytics-events.service';
import { ToastrService } from 'ngx-toastr';
import { CommonService } from './../../../services/common.service';
import { HttpClient } from '@angular/common/http';
import { Component, Inject, Input, OnInit, PLATFORM_ID } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { isPlatformBrowser } from '@angular/common';
import { StorageService } from 'src/app/services/storage.service';
import { TrackEventService } from 'src/app/services/track-event.service';

declare var Stripe;
// declare var pintrk;
declare var fbq;

@Component({
  selector: "app-payment",
  templateUrl: "./payment.component.html",
  styleUrls: ["./payment.component.scss"],
})
export class PaymentComponent implements OnInit {
  @Input() from = 'page';
  @Input() p_i = '';

  isAlreadyCliked = false;
  fraisLivraisonTotal = 0;
  showSpinner = false;
  paniers = [];
  etape = 2;
  secondFormGroup: FormGroup;
  selectedValue: boolean;
  isReceiveMail: boolean = false;
  numTel;

  testAdress = "";

  stripe = Stripe(environment.keys, { apiVersion: "2020-03-02" });
  // stripe = Stripe(environment.keys, {apiVersion: '2020-03-02'}, { stripeAccount: '{{CONNECTED_STRIPE_ACCOUNT_ID}}'});
  titulaire;
  elements;
  card;
  paiementId;
  coordonner;
  formPaiment = document.getElementById("payment-form");

  constructor(
    @Inject(PLATFORM_ID) private platformId,
    public commonService: CommonService,
    private formBuilder: FormBuilder,
    private httpClient: HttpClient,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private toast: ToastrService,
    public googleAnalyticsEventsService: GoogleAnalyticsEventsService,
    private storageService: StorageService,
    private trackEventService: TrackEventService
  ) {
    console.log('pi construct', this.p_i);
  }

  ngOnInit(): void {
    const user = this.storageService.getItem("User");
    let userJson: any;
    if (user) {
      userJson = JSON.parse(user);
      this.titulaire = userJson.prenom + " " + userJson.nom;
    }
    console.log('pi oninit', this.p_i);
    this.paiementId = this.p_i || this.activatedRoute.snapshot.paramMap.get("paiement_id");
    this.secondFormGroup = this.formBuilder.group({
      secondCtrl: ["", Validators.required],
    });

    this.getPanier();
    this.stripeInit();
  }

  getPanier() {
    this.httpClient
      .get(environment.ws_url + "paniers")
      .subscribe((response: any) => {
        this.paniers = response.data;
        this.getFraisDeLivraison();
      });
  }

  setEtape(etape) {
    this.etape = etape;
    this.stripeInit();
    if (isPlatformBrowser(this.platformId)) {
      window.scrollTo(0, 0);
    }
  }

  getTotalArticle() {
    let total = 0;
    if (this.paniers && this.paniers.length) {
      this.paniers.forEach((elt) => {
        // tslint:disable-next-line:max-line-length
        // const prix = elt.sous_produit.produit.prix_promotion && elt.sous_produit.produit.prix_promotion > 0 ? elt.sous_produit.produit.prix_promotion : elt.sous_produit.produit.prix;
        total += this.commonService.calculPrix(
          elt.sous_produit.produit,
          elt.quantite
        );
        // total += elt.quantite * prix;
      });
    }

    // if(this.commonService.codePromo.isValid){

    //   if(this.commonService.codePromo.data.type === 'POURCENTAGE'){
    //     const remise = total * (+this.commonService.codePromo.data.valeur) /100;
    //     total -= remise;
    //   } else if(total > this.commonService.codePromo.data.min_valeur){
    //     total -= this.commonService.codePromo.data.valeur;
    //   }
    // }

    return total;
  }

  getTotalArticleWithPromo() {
    let total = 0;
    if (this.commonService.codePromo.isValid) {
      const totalPanier = this.getTotalArticle();
      if (this.paniers && this.paniers.length) {
        this.paniers.forEach((elt) => {
          // tslint:disable-next-line:max-line-length
          const prix =
            elt.sous_produit.produit.prix_promotion &&
            elt.sous_produit.produit.prix_promotion > 0
              ? elt.sous_produit.produit.prix_promotion
              : elt.sous_produit.produit.prix;
          total += elt.quantite * prix;
        });
      }
      if (this.commonService.codePromo.data.type === "POURCENTAGE") {
        total = (totalPanier * +this.commonService.codePromo.data.valeur) / 100;
      } else if (totalPanier > this.commonService.codePromo.data.min_valeur) {
        total = this.commonService.codePromo.data.valeur;
      }
    }
    return total;
  }

  stripeInit() {
    setTimeout(() => {
      this.elements = this.stripe.elements();

      const styleCss = {
        base: {
          color: "#32325d",
        },
      };

      this.card = this.elements.create("card", {
        hidePostalCode: true,
        style: styleCss,
      });
      this.card.mount("#cardElement");

      this.card.addEventListener("change", (event) => {
        const displayError = document.getElementById("cardErrors");
        if (event.error) {
          displayError.textContent = event.error.message;
        } else {
          displayError.textContent = "";
        }
      });
    }, 500);
  }

  createPIntent() {
    // this.trackMe();
    if (!this.selectedValue) {
      this.toast.error("Veuillez accepter les conditions générales", "Erreur", {
        timeOut: 2000,
      });
    } else {
      this.isAlreadyCliked = true;
      this.showSpinner = true;
      const formData = new FormData();
      formData.set("commande_id", this.paiementId);
      formData.set("recevoir_email", this.isReceiveMail.toString());
      if (this.commonService.codePromo.isValid) {
        formData.set("code_promo", "" + this.commonService.codePromo.data.code);
      }
      this.httpClient
        .post(environment.ws_url + `commandes/validate-paiement`, formData)
        .subscribe(
          (response: any) => {
            this.coordonner = response.data.adresse_livraison;
            this.commonService.reinitCodePromo();
            this.processPayment(response.data.intent_paiement_secret);
            setTimeout(() => {
              this.isAlreadyCliked = false;
            }, 1000);
          },
          (error) => {
            this.showSpinner = false;
            setTimeout(() => {
              this.isAlreadyCliked = false;
            }, 1000);
          }
        );
    }
  }

  processPayment(pIntent) {
    if (pIntent) {
      this.stripe
        .confirmCardPayment(pIntent, {
          payment_method: {
            card: this.card,
            billing_details: {
              name: this.titulaire,
            },
          },
        })
        .then((result) => {
          if (result.error) {
            this.etape = 4;
            this.showSpinner = false;
          } else {
            this.showSpinner = false;

            let _value = (this.getTotalArticle() - this.getTotalArticleWithPromo() + this.fraisLivraisonTotal).toFixed(2);
            let _contents = []
            for (let index = 0; index < this.paniers.length; index++) {
              _contents.push({id: this.paniers[index].sous_produit ? this.paniers[index].sous_produit.id : this.paniers[index].id, quantity: this.paniers[index].quantite})
            }

            let fbqGtag = {
              num_items: this.paiementId,
              currency: 'EUR',
              value: _value,
              contents: _contents,
              content_type: 'product'
            }
            this.trackEventService.gtagTrack('purchase', fbqGtag );

            let dataGtag = {
              value: _value,
              currency: 'EUR',
              // transaction_id: this.paiementId,
            }
            this.trackEventService.gtagTrack('purchase', dataGtag );

            let dataGtagConversion = {
              send_to: 'AW-479791588/FT4DCMyC1v0YEOST5OQB',
              value: _value,
              currency: 'EUR',
              // transaction_id: this.paiementId,
            }
            this.trackEventService.gtagTrack('conversion', dataGtagConversion );
            console.log('dataGtagConversion: ', dataGtagConversion);
            
            let _contents2 = []
            for (let index = 0; index < this.paniers.length; index++) {
              _contents2.push({
                product_id: this.paniers[index].sous_produit ? this.paniers[index].sous_produit.id : this.paniers[index].id,
                product_name: this.paniers[index].sous_produit.produit.model,
                product_price: this.paniers[index].sous_produit.produit.prix,
                product_quantity: this.paniers[index].quantite
              })
            }
            let pintrkGtag = {
              currency: 'EUR',
              order_quantity: this.paniers.length,
              value: _value,
              line_items: _contents2
            }
            this.trackEventService.gtagTrack('checkout', pintrkGtag );
            
            this.router.navigate(["/mon-panier/success"]);
            // // pintrk('track', 'checkout', {
            // //   value: 100,
            // //   order_quantity: 1,
            // //   currency: 'USD'
            // // });
            // fbq("track", "Purchase", {
            //   num_items: this.paiementId,
            //   currency: "EUR",
            //   value:
            //     this.getTotalArticle() -
            //     this.getTotalArticleWithPromo() +
            //     this.fraisLivraisonTotal,
            // });
            setTimeout(() => {
              this.commonService.getPanier();
            }, 4000);
          }
        });
    } else {
      this.showSpinner = false;
    }
  }

  trackMe() {
    this.googleAnalyticsEventsService.trackValidateCommand();
  }

  trackPaymentSuccess() {
    this.googleAnalyticsEventsService.trackPaymentSuccess();
  }

  getFraisDeLivraison() {
    const datadata = new FormData();
    if (this.paniers.length) {
      this.paniers.forEach((element, ind) => {
        datadata.set(`lignes[${ind}].quantite`, element.quantite);
        datadata.set(`lignes[${ind}].sous_produit `, element.sous_produit.id);
      });
    }
    this.httpClient
      .post(`${environment.ws_url}frais-livraisons/paniers`, datadata)
      .subscribe((resp: any) => {
        // if (resp.data.frais.length) {
        //   resp.data.frais.forEach(element => {
        //     this.fraisLivraisonTotal += element
        //   });
        // }
        this.fraisLivraisonTotal = resp.data.total;
      });
  }
}
