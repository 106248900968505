<div class="spinner" style="position: fixed; top: 30%; z-index: 1;" *ngIf="showSpinner">
  <mat-spinner></mat-spinner>
</div>

<div class="container">
  <div class="col-12 col-md-8 offset-md-2 col-lg-6 offset-lg-3">
    <h1 class="text-center" *ngIf="step">Ajouter produit en vente</h1>
    <ul class="stepper horizontal d-none d-md-flex" id="horizontal" *ngIf="step">
      <li class="step " [ngClass]="{'active': step === 1}">
        <div class="step-title waves-effect waves-dark">
          <button style="background-color: transparent; border-radius: 50% !important;">
            1
          </button> Produit
        </div>
      </li>
      <li class="step" [ngClass]="{'active': step === 2}">
        <div class="step-title waves-effect waves-dark">
          <button style="background-color: transparent; border-radius: 50% !important;">
            2
          </button> Informations
        </div>
      </li>
      <li class="step" [ngClass]="{'active': step === 3}">
        <div class="step-title waves-effect waves-dark">
          <button style="background-color: transparent; border-radius: 50% !important;">
            3
          </button> Promo
        </div>
      </li>
    </ul>


    <div [ngSwitch]="step" class="row" style=" margin-top: 2%;">
      <ng-template [ngSwitchCase]="1">
        <app-step1 class="mt-3" [formData]="dataForm" (nextStep)="saveDataForm($event)"></app-step1>
      </ng-template>
      <ng-template [ngSwitchCase]="2">
        <app-step2 class="w-100 mt-3" [formData]="dataForm" [listCaracteristique]="listCaracteristique"
          [listCouleur]="listCouleur" [listTailles]="listTailles" (nextStep)="saveDataForm($event)"></app-step2>
      </ng-template>
      <ng-template [ngSwitchCase]="3">
        <app-step3 class="w-100 mt-3" [formData]="dataForm" (nextStep)="saveDataForm($event)"></app-step3>
      </ng-template>
      <!-- <ng-template [ngSwitchCase]="4">
      <app-step3 class="mt-3" (nextStep)="saveDataForm($event)"></app-step3>
    </ng-template> -->
    </div>

    <ng-template [ngIf]="attenteValidation">
      <h4 class="text-center">
        Votre compte est en attente de validation
      </h4>
      <h4 class="text-center">
        pour toute information n'hesitez pas à nous contacter.
      </h4>
    </ng-template>

  </div>
</div>
<hr>
<!-- <app-ads></app-ads> -->
