<h1 class="d-none">Achetez des articles eco responsables sur la marketplace 100% française.</h1>
<div style="background-color: '#F6FAF0';">
  
  <!-- <button (click)="clicked(0)">gtag conversion 0</button>
  <button (click)="clicked(1)">gtag pageview 1</button>
  <button (click)="clicked(2)">gtag addtocart 2</button>
  <button (click)="clicked(3)">gtag checkout 3</button>

  <br>
  <button (click)="clicked(4)">pinterest pageview 4</button>
  <button (click)="clicked(5)">pinterest addtocart 5</button>
  <button (click)="clicked(6)">pinterest checkout 6</button>

  <br>
  <button (click)="clicked(7)">fbq pageview 7</button>
  <button (click)="clicked(8)">fbq addtocart 8</button>
  <button (click)="clicked(9)">fbq checkout 9</button> -->

  <!-- SLIDER -->
  <div class="p-0 hero sliderBox mb-4" *ngIf="sliders.length">
    <carousel [interval]="3200" [isAnimated]="false">
      <slide *ngFor="let item of sliders">
        <div class="d-flex align-items-center slider position-relative" [ngClass]="{'justify-content-center': item.slider.niveau_affichage === 'CENTER','justify-content-end': item.slider.niveau_affichage === 'RIGHT'}">
          <img class="w-100 h-100 position-absolute" [src]="commonService.getImageFormated(0, 0, item.slider.publicice_detail.file.url)">
          <div [ngClass]="{'text-center':item.slider.niveau_affichage === 'CENTER', 'text-right': item.slider.niveau_affichage === 'RIGHT'}" style="padding-left: 70px; padding-right: 70px;z-index: 1;">
            <h2 class="slider--title mb-0 PoppinsSemiBold" style="font-size: 43px;letter-spacing: 2px;" [ngStyle]="{'color': getColor(item.slider.publicice_detail.couleur_texte)}">
              {{item.slider.publicice_detail.texte_achrochage}}</h2>
              <h3 class="PoppinsRegular d-md-block d-none" [ngClass]="{'text-center':item.slider.niveau_affichage === 'CENTER'}" style="font-size: 27px; text-align: left;margin-top: 0px;"
              [ngStyle]="{'color': getColor(item.slider.couleur_phrase), 'margin-bottom': ((item.slider.publicice_detail.choix === 'BOUTIQUE' && item.vendeur) || item.slider.publicice_detail.choix !== 'BOUTIQUE') ? '0px': '60px'}">{{item.slider.phrase_acrochage | titlecase}}</h3>
            <!-- <h3 class="font-weight-bold PoppinsRegular d-md-block d-none" [ngClass]="{'text-center':item.slider.niveau_affichage === 'CENTER'}" style="font-size: 27px; text-align: left;margin-top: 0px;"
              [ngStyle]="{'color': getColor(item.slider.couleur_phrase), 'margin-bottom': ((item.slider.publicice_detail.choix === 'BOUTIQUE' && item.vendeur) || item.slider.publicice_detail.choix !== 'BOUTIQUE') ? '0px': '60px'}">{{item.slider.phrase_acrochage | titlecase}}</h3> -->
            <button
              *ngIf="(item.slider.publicice_detail.choix === 'BOUTIQUE' && item.vendeur) || item.slider.publicice_detail.choix !== 'BOUTIQUE'"
              class="btn {{getStyleButton(item.slider.publicice_detail.style_bouton)}} mx-auto"
              [routerLink]="[item.slider.publicice_detail.choix === 'BOUTIQUE'?'/detail-vendeur/'+item.vendeur.user.id : 'all-product/'+item.link.univers+'/'+item.link.sousUnivers+'/'+item.link.categorie]"
              [queryParams]="{vendeur: item.slider.publicice_detail.choix === 'BOUTIQUE' ? item.vendeur.user.id : null}"
              style="font-family: 'Poppins-Medium';font-size: 19px;width: 225px;margin-top: 40px;">
              {{item.slider.publicice_detail.nom_bouton | uppercase }}
            </button>
          </div>
        </div>
      </slide>
    </carousel>
  </div>

  <!-- PICTO -->
  <div class="aboutUs pt-2 mb-5" style="background-color: '#F6FAF0'">
    <div class="container">
      <!-- <ng-container *ngIf="screenWidth > 768;"> -->
              <div class="row">
                <div class="col-md col-2 text-center payment border-right">
                  <div>
                    <img width="auto" height="auto" loading="eager" src="assets/images/payment.svg" alt="hadeen payement" title="hadeen payement">
                  </div>
                  <p class="sfCompactDisplayMedium" style="color: #000; font-size: 14px; margin-top: 2px;">{{'Paiement 100% sécurisé' | uppercase}}</p>
                </div>
                <div class="col-md col-2 text-center eco border-right">
                  <div>
                    <img width="auto" height="auto" loading="eager" src="assets/images/picto/picto-contact.svg" alt="hadeen contact" title="hadeen contact">
                  </div>
                  <p class="sfCompactDisplayMedium" style="color: #000; font-size: 14px; margin-top: 2px;">{{'Retours et échanges' | uppercase}}</p>
                </div>
                <div class="col-md col-2 text-center eco border-right">
                  <div>
                    <img width="auto" height="auto" loading="eager" src="assets/images/picto/picto-coeur.svg" alt="hadeen coeur" title="hadeen coeur">
                  </div>
                  <p class="sfCompactDisplayMedium" style="color: #000; font-size: 14px; margin-top: 2px;">{{'250 marques engagées' | uppercase}}</p>
                </div>
                <div class="col-md col-2 text-center eco border-right">
                  <div>
                    <img width="auto" height="auto" loading="eager" src="assets/images/picto/picto-shopping-pag.svg" alt="hadeen shop" title="hadeen shop">
                  </div>
                  <p class="sfCompactDisplayMedium" style="color: #000; font-size: 14px; margin-top: 2px;">{{'3500 articles éco-conçus' | uppercase}}</p>
                </div>
                <div class="col-md col-2 text-center eco">
                  <div>
                    <img width="auto" height="auto" loading="eager" src="assets/images/french.svg" alt="hadeen flag" title="hadeen flag">
                  </div>
                  <p class="sfCompactDisplayMedium" style="color: #000; font-size: 14px; margin-top: 2px;">{{'100% entreprises françaises' | uppercase}}</p>
                </div>
              </div>
      <!-- </ng-container> -->
        <!-- <div id="carouselExampleSlidesOnly" [ngClass]="{'d-none': screenWidth > 768}" class="carousel slide" data-ride="carousel" data-interval="8000">
          <div class="carousel-inner">
            <div class="carousel-item active">
              <div class="row">
                <div class="col-md col-4 text-center payment border-right">
                  <div>
                    <img width="auto" height="auto" loading="eager" src="assets/images/payment.svg" alt="hadeen payement" title="hadeen payement">
                  </div>
                  <p class="sfCompactDisplayMedium" style="color: #000; font-size: 14px; margin-top: 2px;">{{'Paiement 100% sécurisé' | uppercase}}</p>
                </div>
                <div class="col-md col-4 text-center eco border-right">
                  <div>
                    <img width="auto" height="auto" loading="eager" src="assets/images/picto/picto-contact.svg" alt="hadeen contact" title="hadeen contact">
                  </div>
                  <p class="sfCompactDisplayMedium" style="color: #000; font-size: 14px; margin-top: 2px;">{{'Retours et échanges' | uppercase}}</p>
                </div>
                <div class="col-md col-4 text-center eco border-right">
                  <div>
                    <img width="auto" height="auto" loading="eager" src="assets/images/picto/picto-coeur.svg" alt="hadeen coeur" title="hadeen coeur">
                  </div>
                  <p class="sfCompactDisplayMedium" style="color: #000; font-size: 14px; margin-top: 2px;">{{'250 marques engagées' | uppercase}}</p>
                </div>
              </div>
            </div>
            <div class="carousel-item">
              <div class="row justify-content-center">
                <div class="col-md col-5 text-center eco border-right">
                  <div>
                    <img width="auto" height="auto" loading="eager" src="assets/images/picto/picto-shopping-pag.svg" alt="hadeen shop" title="hadeen shop">
                  </div>
                  <p class="sfCompactDisplayMedium" style="color: #000; font-size: 14px; margin-top: 2px;">{{'3500 articles éco-conçus' | uppercase}}</p>
                </div>
                <div class="col-md col-5 text-center eco">
                  <div>
                    <img width="auto" height="auto" loading="eager" src="assets/images/french.svg" alt="hadeen flag" title="hadeen flag">
                  </div>
                  <p class="sfCompactDisplayMedium" style="color: #000; font-size: 14px; margin-top: 2px;">{{'100% entreprises françaises' | uppercase}}</p>
                </div>
              </div>
            </div>
          </div>
        </div> -->
    </div>
  </div>

  <div *ngIf="slidersWithSousCategories && slidersWithSousCategories.length" class="container imageBlock">
    <div class="row imgpub pt-5">
      <div class="col-6 eco-friendly" *ngFor="let item of slidersWithSousCategories">
        <img width="auto" height="auto" loading="lazy" [src]="item.slider.publicice_detail.file.url" alt="" title="" style="height: 132px;object-fit: cover;">
        <div class="d-flex"
          [ngClass]="{'pl-3':item.slider.niveau_affichage === 'LEFT','justify-content-center': item.slider.niveau_affichage === 'CENTER','justify-content-end pr-3': item.slider.niveau_affichage === 'RIGHT'}"
          style="position: relative; top:-50%;">
          <div class="">
            <h4 [ngStyle]="{'color': getColor(item.slider.publicice_detail.couleur_texte)}">
              {{item.slider.publicice_detail.texte_achrochage}}</h4>
            <h5 [ngStyle]="{'color': getColor(item.slider.couleur_phrase)}">{{item.slider.phrase_acrochage}}</h5>
            <button
              class="btn {{getStyleButton(item.slider.publicice_detail.style_bouton)}}">{{item.slider.publicice_detail.nom_bouton
              | titlecase }}</button>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- NOTRE SELECTION -->
  <div class="offer mb-2 mb-md-5" style="background-color: '#F6FAF0';" *ngIf="ventesFlashShow.min && ventesFlashShow.min.length">
    <p class="head-section" style="margin: 2% 0;">LES CHOUCHOUS DU MOIS</p>
    <app-produit-list [screenWidth]="screenWidth" [data]="ventesFlashShow" [idSlide]="'vf'" [login]="loginTemplate" type="venteflash"></app-produit-list>
  </div>

  <!-- UNIVERS -->
  <div *ngIf="dataUniverBox && dataUniverBox.sous_univers_homes && dataUniverBox.sous_univers_homes.length">
    <p class="head-section ajustement-title" style="margin: 2% 0;">LES DERNIÈRES TENDANCES</p>
    <div id="carouselExampleControls" class="carousel slide" data-ride="carousel" data-touch="true" data-interval="3000">
      <div class="carousel-inner">
        <div class="carousel-item" *ngFor="let itemArray of dataUniverBox.sous_univers_homes; let ind=index" [ngClass]="{'active': ind === 0}">
          <div class="row cont">
            <div class="col-12 col-md-4 col-lg-3 mb-3 pl-2" *ngFor="let item of itemArray.fils" style="min-height: 150px; cursor: pointer">
              <div class="univer_box py-3 py-md-4 px-md-3" [ngStyle]="{'background-color': item.value && checkColor(item.value) ? item.value : '#345B2B'}"
                style="width: 100%; height: 100%;" (click)="goToListing(item)">
                <div class="row pl-4 pl-md-0 flex-row-reverse">
                  <div class="col-6 col-md-12 pr-5 px-md-0 text-right text-md-center div-img">
                    <img width="auto" height="auto" loading="lazy" class="ml-auto my-auto horizontal-img" [src]="commonService.getImageFormated(250, 250, item.image ? item.image : item.sous_univers?.url ? item.sous_univers.url : item.univers?.url)" [alt]="item.texte || item.texte2 || item.sous_univers.nom" [title]="item.texte || item.texte2 || item.sous_univers.nom" style="border-radius: 50%;">
                  </div>
                  <div class="col-6 col-md-12 text-md-center mt-2 content-suniver" style="height: 115px;overflow: hidden;">
                    <div class="d-flex flex-column-reverse flex-md-column">
                      <h3 class="mt-0 mb-0 PoppinsBold" *ngIf="item.texte && item.position.texte === 2" style="color: white; font-size: 20px; line-height: 28px;">{{ item.texte }}</h3>
                      <h3 class="mt-0 mb-0 PoppinsBold" *ngIf="item.value && item.position.texte2 === 2" style="color: white; font-size: 20px; line-height: 28px;">{{ item.texte2 }}</h3>
                      <h3 class="mt-0 mb-0 PoppinsBold" *ngIf="item.sous_univers && item.sous_univers.nom && item.position.sousUnivers === 2" style="color: white; font-size: 20px; line-height: 28px">{{ item.sous_univers.nom }}</h3>
                      <p class="mt-0 mb-0 PoppinsBold text-left" *ngIf="item.univers && item.univers.nb_produit && item.position.nbProduit === 2" style="color: white; font-size: 20px; line-height: 28px">{{ item.univers.nb_produit }} produits en vente</p>

                      <h2 class="mt-0 mb-0 PoppinsRegular" *ngIf="item.texte && item.position.texte === 1" style="color: white; font-size: 15px; font-weight: 400;word-wrap: break-word;"><span style="font-size: 0px;">articles </span>{{ item.texte }}<span style="font-size: 0px;"> Eco responsable</span></h2>
                      <h2 class="mt-0 mb-0 PoppinsRegular" *ngIf="item.value && item.position.texte2 === 1" style="color: white; font-size: 15px; font-weight: 400;word-wrap: break-word;"><span style="font-size: 0px;">articles </span>{{ item.texte2 }}<span style="font-size: 0px;"> Eco responsable</span></h2>
                      <h2 class="mt-0 mb-0 PoppinsRegular" *ngIf="item.sous_univers && item.sous_univers.nom && item.position.sousUnivers === 1" style="color: white; font-size: 15px; font-weight: 400;word-wrap: break-word;">{{ item.sous_univers.nom }}</h2>
                      <p class="mt-0 mb-0 PoppinsRegular text-left" *ngIf="item.univers && item.univers.nb_produit && item.position.nbProduit === 1" style="color: white; font-size: 15px; font-weight: 400;">{{ item.univers.nb_produit }} produits en vente</p>
                    </div>


                    <h4 class="mt-0 mb-0" *ngIf="item.texte && item.position.texte === 3" style="color: white; font-size: 15px;">{{ item.sous_univers.nom }}</h4>
                    <h4 class="mt-0 mb-0 " *ngIf="item.value && item.position.texte2 === 3" style="color: white; font-size: 15px;">{{ item.texte2 }}</h4>
                    <h4 class="mt-0 mb-0" *ngIf="item.sous_univers && item.sous_univers.nom && item.position.sousUnivers === 3" style="color: white; font-size: 15px;">{{ item.sous_univers.nom }}</h4>
                    <p class="mt-0 mb-0 text-left" *ngIf="item.univers && item.univers.nb_produit && item.position.nbProduit === 3" style="color: white; font-size: 15px;">{{ item.univers.nb_produit }} produits en vente</p>

                    <p class="mt-0 mb-1 text-left" *ngIf="item.texte && item.position.texte === 4" style="color: white; font-size: 14px; max-width: 200px;">{{ item.texte }}</p>
                    <p class="mt-0 mb-1 text-left" *ngIf="item.value && item.position.texte2 === 4" style="color: white; font-size: 14px; max-width: 200px;">{{ item.texte2 }}</p>
                    <p class="mt-0 mb-1 text-left" *ngIf="item.sous_univers && item.sous_univers.nom && item.position.sousUnivers === 4" style="color: white; font-size: 14px; max-width: 200px;">{{ item.sous_univers.nom }}</p>
                    <p class="mt-0 mb-1 text-left text-left" *ngIf="item.univers && item.univers.nb_produit && item.position.nbProduit === 4" style="color: white; font-size: 14px; max-width: 200px;">{{ item.univers.nb_produit }} produits en vente</p>
                  </div>                  
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    <!--<button class="carousel-control-prev py-4 slideOpacity" type="button" data-target="#carouselExampleControls" data-slide="prev" style="opacity: 0.8; top: 25% !important; bottom: 35% !important; border: none; border-radius: 0px 15px 15px 0px !important;">
        <span class="carousel-control-prev-icon" aria-hidden="true"></span>
        <span class="sr-only">Previous</span>
      </button>
      <button class="carousel-control-next py-4 slideOpacity" type="button" data-target="#carouselExampleControls" data-slide="next" style="opacity: 0.8; top: 25% !important; bottom: 35% !important; border: none; border-radius: 15px 0px 0px 15px !important;">
        <span class="carousel-control-next-icon" aria-hidden="true"></span>
        <span class="sr-only">Next</span>
      </button>-->
    </div>
  </div>

  <!--<div class="d-none d-md-block p-0 mt-5">
    <img src="../../../assets/images/Bandeau HP version 2 a.png" alt="" srcset="" style="margin-top: -45px; margin-bottom: 35px;">
  </div>-->

  <div class="valeur_engagement mt-4">
    <div class="text-center main-container border-custom">
      <p class="head-section" style="font-size: 22px; font-family: 'Poppins-Bold'; color: #475850; margin: 2% 0;">NOS VALEURS ET ENGAGEMENTS</p>
      <img class="logo-hadeen" src="./../../../assets/images/hadeen-logo-no-description.svg" alt="Logo HADEEN" style="width: 18rem;">
      <p class="txt-1" style="line-height: 30px; font-size: 28px; color: #475850; margin: 2% 15%;">c'est la référence de l'éco-résponsabilité à la française : <br>
        éthique & stylé.</p>
      <img src="./../../../assets/images/icons/quote.png" alt="icon quote" class="mt-2 quote" style="width: 40px;">
      <p class="PoppinsRegular txt-2" style="margin: 15px 25%;line-height: 18px;font-size: 14px;color: #000000;">Hadéen, c'est l'opportunité de revenir aux valeurs initiales, sans plastique par exemple,
        là où tout repart à zéro, et où l'on peut consommer responsable
      </p>
      <p class="mt-0"><span>Jennifer Corna - Créatrice d’Hadéen-Place</span></p>
      <a [routerLink]="['Blog/hadeen-place-qu-est-ce-que-c-est-qui-sommes-nous']" class="btn btn-custom-green PoppinsMedium rounded-0" style="text-decoration: none;">QUI SOMMES-NOUS</a>
    </div>
  </div>

  <!-- A LA UNE -->
  <div class="listing-image mb-5 container px-0" *ngIf="aLaUnes && aLaUnes.length">
    <p class="text-center head-section" style="margin: 40px 0;">LES PÉPITES FRANÇAISES</p>
    <div class="row row-eq-height m-0 px-0 px-md-2">
      <div *ngFor="let item of aLaUnes" class="col-md-4 col-6 mb-2 col-xs-6 pb-0" [ngClass]="{'text-center': true}"
        [routerLink]="[item.a_la_une.publicice_detail.choix === 'PRODUIT' ? 'all-product/' + formatUrl(item.link.univers) + '/' + formatUrl(item.link.sousUnivers) + '/' + formatUrl(item.link.categorie) : item.vendeur ? '/detail-vendeur/' + formatUrl(item.vendeur.user.pseudo) + '-' + item.vendeur.user.id : '']"
        [queryParams]="{vendeur: item.a_la_une.publicice_detail.choix === 'BOUTIQUE' ? null : item.vendeur ? item.vendeur.user.id : null}">
        <div class="px-2 pb-2 une justify-content-center justify-content-between position-relative">
          <!-- <p class="mb-0" [ngStyle]="{'color': getColor(item.a_la_une.publicice_detail.couleur_texte)}">
            {{item.a_la_une.publicice_detail.texte_achrochage | titlecase}}
          </p> -->
          <img class="w-100 h-100 position-absolute top-0" [src]="commonService.getImageFormated(350, 350, item.a_la_une.publicice_detail.file.url)" [alt]="item.a_la_une.publicice_detail.texte_achrochage" style="object-fit: cover;">
          <div class="h-50 d-sm-flex align-items-end">
            <h2 class="my-0 d-none d-sm-block" *ngIf="item.a_la_une.publicice_detail.texte_achrochage" style="line-height: 28px;z-index: 1;" [ngStyle]="{'color': getColor(item.a_la_une.publicice_detail.couleur_texte)}">
              {{item.a_la_une.publicice_detail.texte_achrochage | titlecase}}
            </h2>
          </div>
          <!-- *ngIf="(item.a_la_une.publicice_detail.choix === 'BOUTIQUE' && item.vendeur) || item.a_la_une.publicice_detail.choix !== 'BOUTIQUE'" -->
          <button
            class="h-50 {{getStyleButton(item.a_la_une.publicice_detail.style_bouton)}} btn-sm mb-md-2 rounded-pill"
            type="button" mat-flat-button>
            <!-- {{item.a_la_une.publicice_detail.nom_bouton | titlecase }} -->
            <h2 class="m-auto txt-btn">
              {{item.a_la_une.publicice_detail.nom_bouton | titlecase }}
            </h2>
          </button>
        </div>
      </div>
    </div>
  </div>

  <!-- LES NOUVEAUTES -->
  <div class="offer mb-5" *ngIf="(offresDuMomentShow.min || offresDuMomentShow.max) && (offresDuMomentShow.min.length && offresDuMomentShow.max.length)">
    <p class="head-section" style="margin: 2% 0;">LES PETITS DERNIERS</p>
    <app-produit-list [screenWidth]="screenWidth" [data]="offresDuMomentShow" [idSlide]="'om'" [login]="loginTemplate" type="offres"></app-produit-list>        
  </div>

  <!-- BLOG -->
  <!-- <div class="container mb-5">
    <p class="text-center" style="font-weight: 600; color: #345B2B; font-size: 2rem; font-family: 'DIN Bold';">BLOG</p>
    <img width="auto" height="auto" loading="lazy" [routerLink]="['/Blog']" src="../../../assets/images/blog_image_2.png" alt="blog" title="blog" class="w-100">
  </div> -->

  <ng-container *ngIf="aLaUnesWithSousCategories.length>0">
    <div class="offer" style="background-color:#f0f0f0;padding: 3% 0 3%;">
      <h2 class="head-section" style="margin: 0 0 2% 0;">NOS COUPS DE COEUR</h2>
      <app-heartstroke [products]="aLaUnesWithSousCategories"></app-heartstroke>
    </div>
  </ng-container>

  <!-- CREER VOTRE E BOUTIQUE QRCODE -->
  <div class="main-container border-custom" style="padding-top: 40px;padding-bottom: 40px;background-position-x: 65%;background-color: #EEE4BE;background-image: url(../../../assets/images/bg_banner_v2_4.png); background-size: cover;border: solid 1px #DDB254;">
    <div class="row w-100">
      <div class="col-1"></div>
      <div class="col-6">
        <h2 class="m-0 PoppinsBold e-bootik">CRÉEZ VOTRE E-BOUTIQUE</h2>
        <p class="m-0 text-left PoppinsMedium" style="color: #000000;font-size: 13px;">Vendez tous vos articles et créations sur le <span class="break-on-mobile">Web & Mobile</span></p>
        <button class="btn-custom-green btn-bootik rounded PoppinsMedium" style="font-size: 12px;" (click)="goTo()">JE CRÉE MA E-BOUTIQUE GRATUITMENT</button>
      </div>
      <div class="col-5 col-md-4 offset-md-1 small-bootik">
        <p class="text-left mt-2" style="font-size: 15px; color: #345B2C;">
          <img width="auto" height="auto" loading="lazy" src="../../../assets/images/icons/check-mark.png" style="width: 25px;" alt="check" title="check"> GRATUIT</p>
        <p class="text-left mt-2" style="font-size: 15px; color: #345B2C;">
          <img width="auto" height="auto" loading="lazy" src="../../../assets/images/icons/check-mark.png" style="width: 25px;"  alt="check" title="check"> SANS ABONNEMENT</p>
        <p class="text-left mt-2" style="font-size: 15px; color: #345B2C;">
          <img width="auto" height="auto" loading="lazy" src="../../../assets/images/icons/check-mark.png" style="width: 25px;"  alt="check" title="check"> SANS ENGAGEMENT</p>
      </div>
    </div>
  </div>

  <div class="main-container" style="padding-top: 40px;padding-bottom: 40px;">
    <p class="head-section" style="margin-bottom: 35px;">LA PRESSE PARLE DE NOUS</p>
    <div class="row mb-4 logo-press">
      <div class="col-md-3 col-6 text-center mb-5 mb-md-0 text-md-left">
        <img src="../../../assets/images/euro1.png" alt="Logo Europe1">
      </div>
      <div class="col-md-3 col-6 text-center mb-5 mb-md-0 text-md-center">
        <img src="../../../assets/images/voici.png" alt="Logo Voici">
      </div>
      <div class="col-md-3 col-6 text-center mb-5 mb-md-0 text-md-center">
        <img src="../../../assets/images/nrj.png" alt="Logo NRJ">
      </div>
      <div class="col-md-3 col-6 text-center mb-5 mb-md-0 text-md-right">
        <img src="../../../assets/images/gala.png" alt="Logo Gala">
      </div>
    </div>
  </div>
  <!-- Telecharger -->
  <!--<div class="container py-5">
    <div class="text-center">
      <img width="auto" height="auto" loading="lazy" src="../../../assets/images/Groupe 78.png" alt="telecharger app" title="telecharder app" style="position: absolute; margin-top: -40px; width: 110px; margin-left: -55px;">
    </div>
    <div class="row py-3" style="background-image: url(../../../assets/images/bg_banner_v2_2.png); background-size: cover;">
      <div class="col offset-md-2">
        <p class="m-0 text-left" style="color: white; font-size: 15px;">Hadéen place pour votre mobile</p>
        <p class="m-0 text-left" style="color: white; font-size: 1.7rem; line-height: 1.3;font-family: -apple-system,BlinkMacSystemFont,'Segoe UI',Roboto,'Helvetica Neue',Arial,'Noto Sans',sans-serif,'Apple Color Emoji','Segoe UI Emoji','Segoe UI Symbol','Noto Color Emoji';">Telecharger</p>
        <p class="m-0 text-left" style="color: white; font-size: 1.5rem; line-height: 1.4; font-family: -apple-system,BlinkMacSystemFont,'Segoe UI',Roboto,'Helvetica Neue',Arial,'Noto Sans',sans-serif,'Apple Color Emoji','Segoe UI Emoji','Segoe UI Symbol','Noto Color Emoji';">gratuitement</p>
      </div>
      <div class="col-2"></div>
      <div class="col">
        <a href="https://play.google.com/store/apps/details?id=com.place.hadeen">
          <img width="auto" height="auto" loading="eager" src="../../../assets/images/btn_googlePlayV2.png" alt="google play" title="google play" style="width: 120px; margin-bottom: 15px;">
        </a> <br>
        <a href="https://apps.apple.com/fr/app/id1531299991">
          <img width="auto" height="auto" loading="eager" src="../../../assets/images/btn_appStoreV2.png" alt="appstore" title="appstore" style="width: 120px;">
        </a>
      </div>
    </div>
  </div>-->

  <!-- <app-download></app-download> -->

</div>
