import { ToastrService } from 'ngx-toastr';
import { HttpService } from './../../services/http.service';
import { Component, Input, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';


@Component({
  selector: "app-footer",
  templateUrl: "./footer.component.html",
  styleUrls: ["./footer.component.scss"],
})
export class FooterComponent implements OnInit {
  email;
  scrollTopView: boolean = true;
  constructor(
    private http: HttpService,
    private toast: ToastrService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.router.events.subscribe((val) => {
      if (val instanceof NavigationEnd) {
        // console.log(val.url);
       this.scrollTopView = !val.url.includes("/detail-produit/"); 
      }
    });
  }

  inscrireToNewsLetter() {
    if (this.email && this.email.length > 0) {
      const test = this.validateEmail(this.email);
      if (test) {
        const fd = new FormData();
        fd.append("email", this.email);
        this.http.post("news-letters", fd).subscribe((response) => {
          this.email = "";
          this.toast.success("Email enregistré avec succès", "", {
            timeOut: 2000,
          });
        });
      } else {
        this.email = "";
        this.toast.error("Veuillez entrer un email valide", "Erreur", {
          timeOut: 2000,
        });
      }
    } else {
      this.toast.error("Veuillez entrer votre email", "Erreur", {
        timeOut: 2000,
      });
    }
  }

  validateEmail(email) {
    const re =
      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
  }

  scrollTo() {
    window.scrollTo(0, 0);
  }
}
