import { HttpClient } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-card',
  templateUrl: './card.component.html',
  styleUrls: ['./card.component.scss']
})
export class CardComponent implements OnInit {

  @Input() item;
  langue;

  constructor(private httpClient: HttpClient) { }

  ngOnInit() {
    // this.langue = localStorage.getItem('langueName');
    this.item.Card.forEach(element => {
      if (element.page) {
        if (element.page && element.page.parent_parent_page) {
          this.httpClient.get(`${environment.strapi_url}pages/${element.page.parent_parent_page}`).subscribe((dataCurrentPage: any) => {
            element.page.parent_parent_page = dataCurrentPage;
          });
        }
        if (element.page && element.page.parent_page) {
          this.httpClient.get(`${environment.strapi_url}pages/${element.page.parent_page}`).subscribe((dataCurrentPage: any) => {
            element.page.parent_page = dataCurrentPage;
          });
        }
      }
      if (element.ExternalURL && (element.ExternalURL).includes('onthemountain')) {
        element.targetType = '';
      } else {
        element.targetType = 'Blank';
      }
      if (element.ExternalURL2 && (element.ExternalURL2).includes('onthemountain')) {
        element.targetType2 = '';
      } else {
        element.targetType2 = 'Blank';
      }
    });
  }

  generateUrl(page) {
    let url = '';
    if (page.parent_parent_page) {
      url += page.parent_parent_page.Slug + '/';
    }
    if (page.parent_page) {
      url += page.parent_page.Slug + '/';
    }
    return url += page.Slug;
  }
}
