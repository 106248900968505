import { Component, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { Router } from '@angular/router';

@Component({
  selector: 'app-cgu',
  templateUrl: './cgu.component.html',
  styleUrls: ['./cgu.component.scss']
})
export class CguComponent implements OnInit {

  constructor(private titleService: Title, private meta: Meta, private router: Router) { }

  ngOnInit(): void {
    // tslint:disable-next-line: max-line-length
    // this.titleService.setTitle('Hadéen-Place|Conditions générales d’utilisation et de vente');
    // this.metaService.updateTag({
    //   name: 'description',
    //   // tslint:disable-next-line: max-line-length
    //   content: 'Nos Conditions générales d’utilisation et de vente pour les vendeurs et les acheteurs'
    // });

    const nom = 'Conditions générales d’utilisation et de vente';
    const description = 'Nos Conditions générales d’utilisation et de vente pour les vendeurs et les acheteurs';
    this.updateMeta(nom, description);
  }

  updateMeta(nom, description) {
    this.titleService.setTitle('Hadéen-place | ' + nom);
    // this.altImage = nom + ' | Hadéen-Place | Consommer Responsable |';
    this.meta.updateTag({
      name: 'description',
      content: String(description.trim()),
    });
    // this.meta.updateTag({ property: 'og:image', content: this.product.image });
    this.meta.updateTag({ property: 'og:title', content: 'Hadéen-place | ' + nom });
    this.meta.updateTag({ property: 'og:type', content: 'page' });
    this.meta.updateTag({ property: 'og:url', content: this.router.url });
    this.meta.updateTag({ property: 'og:description', content: String(description.trim()) });
  }
}
