<div class="row">
  <div class="col-12 col-md-12">
    <div class="row">
      <div class="col-12 mb-0">
        <div class="header">
          <ul class="breadcrumb mb-0">
            <li [routerLink]="['/compte']"><a>Compte</a></li>
            <li class="active"><a>Commande</a></li>
          </ul>
        </div>
      </div>
      <div class="col-12 mb-4">
        <h1 class="text-center">MES COMMANDES</h1>
      </div>

      <div class="col-12 mb-4">
        <div class="pl-2 pr-2 pt-4 pb-4 mb-3" style="border: 1px solid #D6D6D6"
          *ngFor="let itemCommande of commandAcheteurByIDPaiment | paginate: { itemsPerPage: 12, currentPage: page ,totalItems: productService.commandSellerTotal}">
          <p class="col-12" *ngIf="itemCommande.lste_achat">Ce produit est sur la liste d'achats: {{
            itemCommande.lste_achat.event }} {{ itemCommande.lste_achat.title }}</p>
          <div class="col-12">
            <div class="row">
              <div class="col-12 col-md-4">
                <div class="row">
                  <p class="col-12 m-0 col pr-0" style="font-size: 14px; color:#6A6B6A;;">Status
                    <span [ngStyle]="{'color' : getColor(itemCommande)}">( {{ itemCommande.statut_vendeur }})</span>
                  </p>
                </div>
                <div class="row">
                  <p class="m-0 col pr-0" style="font-size: 14px; color: #6A6B6A;">Date :</p>
                  <p class="m-0 col-auto pl-0" style="font-size: 14px;">{{ itemCommande.created_at | date }}</p>
                </div>
                <div class="row">
                  <p class="m-0 col pr-0" style="font-size: 14px; color: #6A6B6A;">N° de commande :</p>
                  <p class="m-0 col-auto pl-0" style="font-size: 14px;">{{ itemCommande.id }}</p>
                </div>
                <div class="row">
                  <p class="m-0 col pr-0" style="font-size: 14px; color: #6A6B6A;">N° de produit :</p>
                  <p class="m-0 col-auto pl-0" style="font-size: 14px;">{{
                    itemCommande.sous_produit.produit.id.produitId }}</p>
                </div>
                <div class="row">
                  <p class="m-0 col pr-0" style="font-size: 14px; color: #6A6B6A;">Prix :</p>
                  <p class="m-0 col-auto pl-0" style="font-size: 14px;">{{ prix(itemCommande) | number : '1.2-2' }} €
                  </p>
                </div>
                <div class="row">
                  <p class="m-0 col pr-0" style="font-size: 14px; color: #6A6B6A;">{{ itemCommande.frais_livraison > 0 ?
                    'Frais de livraison :' : 'Frais incluse'}}</p>
                  <p class="m-0 col-auto pl-0" style="font-size: 14px;" *ngIf="itemCommande.frais_livraison">{{
                    itemCommande.frais_livraison | number : '1.2-2' }} €
                  </p>
                </div>
              </div>

              <div class="col-12 col-md-5 pr-0" style="border-left: 1px solid #D6D6D6;">
                <div class="row">
                  <div uk-slider="finite: true" class="col-12 col-md-11 offset-0 offset-md-1">
                    <div class="uk-position-relative">
                      <div class="uk-slider-container uk-light">
                        <ul class="uk-grid-small uk-slider-items uk-child-width-1-2 uk-child-width-1-3@s" uk-grid>
                          <li>
                            <div class="uk-card-default" style="box-shadow: none;">
                              <div class="text-center">
                                <img width="auto" height="auto" [src]="itemCommande.sous_produit.produit.image" alt=""
                                  style="height: 80px;">
                              </div>
                              <div>
                                <p class="text-center" style="font-size: 13px;">{{
                                  itemCommande.sous_produit.produit.model }}</p>
                              </div>
                            </div>
                          </li>
                        </ul>
                      </div>

                      <div class="uk-hidden@s uk-light">
                        <a class="uk-position-center-left uk-position-small" href="#" uk-slidenav-previous
                          uk-slider-item="previous"></a>
                        <a class="uk-position-center-right uk-position-small" href="#" uk-slidenav-next
                          uk-slider-item="next"></a>
                      </div>
                      <div class="uk-visible@s">
                        <a class="uk-position-center-left-out uk-position-small mr-0" href="#" uk-slidenav-previous
                          uk-slider-item="previous" style="color: #1A1A1A;"></a>
                        <a class="uk-position-center-right-out uk-position-small ml-0" href="#" uk-slidenav-next
                          uk-slider-item="next" style="color: #1A1A1A; z-index: 1;"></a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-12 col-md-3 m-auto text-right">
                <button *ngIf="!page" mat-flat-button [routerLink]="[itemCommande.id]"
                  style="border-radius: 0px; background-color: #EDEDED; color: #345B2B;">Afficher le detail</button>
                <button *ngIf="page > 1" mat-flat-button [routerLink]="[itemCommande.id]" [queryParams]="{'p': page}"
                  style="border-radius: 0px; background-color: #EDEDED; color: #345B2B;">Afficher le detail</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <pagination-controls class="modal-pagination text-center" maxSize="5" (pageChange)="changePage($event)"
      directionLinks="true" responsive="true" autoHide="true" previousLabel="PRÉCÉDENT" nextLabel="SUIVANT">
    </pagination-controls>

  </div>
</div>
