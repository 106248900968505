<div class="spinner mx-auto" style="position:fixed;top: 40%;left:50%; z-index: 2;" *ngIf="showSpinner">
  <mat-spinner></mat-spinner>
</div>
<div class="row">
  <div class="col-12 col-md-12">
    <div class="col-12">
      <div class="header">
        <ul class="breadcrumb mb-0">
          <li [routerLink]="['/compte']"><a>Compte</a></li>
          <ng-container *ngIf="page === 1; else elseTemplate">
            <li class="active">Ma boutique</li>
          </ng-container>
          <ng-template #elseTemplate>
            <li (click)="changePage(1)"><a>Boutique</a></li>
            <li class="active">{{pageTitle}}</li>
          </ng-template>
        </ul>
      </div>
    </div>
    <div class="col-12 mb-4">
      <h1 class="text-center">PROMOUVOIR MA BOUTIQUE/MES PRODUITS</h1>
    </div>

    <!-- menu list  -->
    <ng-container *ngIf="page === 1">
      <div class="col-12 mb-4">
        <p class="text-center pl-3 pr-3">
          "Vous souhaitez vendre plus rapidement vos articles? Différentes offres de mise en avant sur plusieurs durées
          sont à votre disposition afin de trouver le pack qui vous convient le mieux. Vous pouvez booster un, plusieurs
          ou tous vos articles d'un même
          univers, ou simplement créer vous même la publicité défilante sur la page d'accueil de votre propre boutique.
        </p>
      </div>

      <div class="col-12 mb-4">
        <div class="d-flex flex-column flex-md-row">
          <!-- case 1 -->
          <div class="col-12 col-md-4 ">
            <div class="border-grey col-12 px-0 py-4 case text-center">
              <img width="auto" height="auto" style="height: 25px;" src="../../../assets/images/icons/case1.png" alt="">
              <div class="px-3 px-md-1 pt-3">
                <small>Promouvoir ma boutique ou mes articles</small>
                <h6>Publicité défilante page d'accueil</h6>
                <img width="auto" height="auto" class="mt-2" (click)="changePage(2)" style="height: 25px;cursor: pointer;"
                  src="../../../assets/images/arrow-right.png" alt="">
              </div>
            </div>
          </div>
          <!-- case 2 -->
          <div class="col-12 col-md-4">
            <div class="border-grey col-12 px-0 py-4 case text-center">
              <img width="auto" height="auto" style="height: 25px;" src="../../../assets/images/icons/case2.png" alt="">
              <div class="px-3 px-md-1 pt-3">
                <small>Mettre en avant ma boutique ou mes articles</small>
                <h6>"A la Une" de la page d'accueil</h6>
                <img width="auto" height="auto" class="mt-2" (click)="changePage(3)" style="height: 25px;cursor: pointer;"
                  src="../../../assets/images/arrow-right.png" alt="">
              </div>
            </div>
          </div>
          <!-- case 3 -->
          <div class="col-12 col-md-4">
            <div class="border-grey col-12 px-0 py-4 case text-center">
              <img width="auto" height="auto" style="height: 25px;" src="../../../assets/images/icons/case3.png" alt="">
              <div class="px-3 px-md-1 pt-3">
                <small>Remonter mes articles</small>
                <h6>Tête de catégorie</h6>
                <img width="auto" height="auto" class="mt-2" style="height: 25px;cursor: pointer;" src="../../../assets/images/arrow-right.png"
                  alt="">
              </div>
            </div>
          </div>


        </div>
      </div>

      <div class="col-12">
        <div class="d-flex flex-column flex-md-row align-items-stretch">

          <!-- case 1 -->
          <div class="col-12 col-md-4">
            <div class="col-12 px-0 py-4 case text-center" style="border:solid 1px #636363;background-color: #F9F9F9;">
              <div class="px-3">
                <h6>Besoin d'aide?</h6>
                <small>
                  Vous avez une question ou une demande particulière?
                  Consultez notre <a href="#">FAQ</a> ou contactez notre <a href="#">support client</a>
                </small>
              </div>
            </div>
          </div>

          <!-- case 2 -->
          <div class="col-12 col-md-4">
            <div class="col-12 px-0 py-4 case text-center" style="border:solid 1px #636363;background-color: #F9F9F9;">
              <div class="px-3">
                <h6>A propos d'Hadeen place</h6>
                <small>
                  Vous souhaitez mieux nous connaitre?
                  Découvrez notre rubrique <a href="#">Qui sommes-nous</a>? et notre <a href="#">Blog</a>
                </small>
              </div>
            </div>
          </div>

          <!-- case 3 -->
          <div class="col-12 col-md-4">
            <div class="col-12 px-0 py-4 case text-center"
              style="border:solid 1px #636363;background-color: #F9F9F9;min-height: 172px;">
              <div class="px-3">
                <h6>Nos CGU</h6>
                <small>
                  Quelques liens utiles :
                  <a href="#">Livraison</a>, <a href="#">Paiement</a>, <a href="#">conditions de retours</a>
                </small>
              </div>
            </div>
          </div>


        </div>
      </div>
    </ng-container>


    <!-- slider home  -->
    <ng-container *ngIf="page !== 1">
      <div class="col-12">
        <div class="row mb-3" style="border: 1px solid #D6D6D6; cursor: pointer;">

          <div class="col-12 p-0 mb-2">
            <div class="text-center border-bottom p-2">
              <img width="auto" height="auto" src="../../../assets/images/icons/case1.png" alt="" *ngIf="page === 2">
              <img width="auto" height="auto" src="../../../assets/images/icons/case2.png" alt="" *ngIf="page === 3"> {{pageTitle}}
            </div>
          </div>

          <div class="col-12 col-md-10 offset-md-1 py-3">

            <form [formGroup]="sectionForm" (ngSubmit)="onSubmit()">
              <div class="d-flex flex-column">
                <!-- buttons  -->
                <div class="d-flex flex-column flex-md-row mb-4">
                  <button type="button" [ngClass]="[aMettreEnAvant === 'boutique' ? 'h-btn-green':'h-btn']"
                    (click)="aMettreEnAvant = 'boutique';initForm();" class="w-100 sfCompactDisplayMedium mr-0 mr-md-2"
                    mat-flat-button>
                    <img width="auto" height="auto" src="../../../assets/images/logo/boutique.png" alt="">
                    METTRE EN AVANT LA BOUTIQUE
                  </button>

                  <button type="button" [ngClass]="[aMettreEnAvant === 'produit' ? 'h-btn-green':'h-btn']"
                    (click)="aMettreEnAvant = 'produit';initForm();"
                    class="w-100 sfCompactDisplayMedium ml-0 ml-md-2 mt-2 mt-md-0" mat-flat-button>
                    <img width="auto" height="auto" src="../../../assets/images/Groupe 2502.png" alt="">
                    METTRE EN AVANT UN PRODUIT
                  </button>
                </div>

                <div class="col-12 text-center mb-4" *ngIf="aMettreEnAvant === 'produit'">
                  Pour mettre en avant tous les produits d’un de vos univers ? <br> Sélectionnez uniquement l’univers
                  des produits.
                </div>

                <!-- univers select -->
                <mat-form-field *ngIf="aMettreEnAvant === 'produit'" appearance="fill"
                  class="promotion-boutique__input">
                  <mat-label>Univers</mat-label>
                  <mat-select formControlName="univers" (selectionChange)="chooseUnivers(1)">
                    <mat-option>None</mat-option>
                    <mat-option *ngFor="let u of univers" [value]="u.code">{{u.nom}}</mat-option>
                  </mat-select>
                </mat-form-field>

                <!-- sous univers select -->
                <mat-form-field *ngIf="aMettreEnAvant === 'produit'" appearance="fill"
                  class="promotion-boutique__input">
                  <mat-label>Sous univers</mat-label>
                  <mat-select formControlName="sousUnivers" (selectionChange)="chooseUnivers(2)">
                    <mat-option>None</mat-option>
                    <ng-container *ngIf="universSelected">
                      <mat-option *ngFor="let us of universSelected.sous_univers" [value]="us.code">{{us.nom}}
                      </mat-option>
                    </ng-container>
                  </mat-select>
                </mat-form-field>

                <!-- catégorie select -->
                <mat-form-field *ngIf="aMettreEnAvant === 'produit'" appearance="fill"
                  class="promotion-boutique__input">
                  <mat-label>Catégorie</mat-label>
                  <mat-select formControlName="categorie">
                    <mat-option>None</mat-option>
                    <ng-container *ngIf="sousUniversSelected">
                      <mat-option *ngFor="let c of sousUniversSelected.categories" [value]="c.code">{{c.nom}}
                      </mat-option>
                    </ng-container>
                  </mat-select>
                </mat-form-field>

                <!-- photo ou vidéo  -->
                <div [ngClass]="{'col-12': page === 2, 'col-8 col-md-4 mx-auto': page === 3}" class="py-4 text-center"
                  style="border:dashed 1.5px #D6D6D6;" (click)="chooseMedia()">
                  <img width="auto" height="auto" src="../../../assets/images/icons/cloud.png" alt="">
                  <div *ngIf="!media">
                    <small class="text-muted">
                      ajoutez votre logo ou une photo, en JPG ou PNG <br>
                      {{ page === 2 ? '1920 x 480px' : '350 x 310px'}} taille recommandée
                    </small>
                  </div>
                  <p *ngIf="media">{{media.name}} - {{media.type}}</p>
                </div>

                <div class="hiddenfile">
                  <input type="file" id="fileinput" (change)="fileChangeEvent($event)"
                    accept="image/x-png,image/gif,image/jpeg" />
                  <!--video/*,-->
                </div>
                <image-cropper style="z-index: 0;" [imageChangedEvent]="imageChangedEvent" [maintainAspectRatio]="true"
                  [aspectRatio]="aspect" format="png" (imageCropped)="imageCropped($event)"
                  (imageLoaded)="imageLoaded()" (cropperReady)="cropperReady()" (loadImageFailed)="loadImageFailed()">
                </image-cropper>

                <!-- texte d'accroche -->
                <mat-form-field class="mt-4 promotion-boutique__input hadeenInput" appearance="fill"
                  [floatLabel]="'auto'">
                  <mat-label>Titre ou nom de la boutique (20 caractères max)</mat-label>
                  <input matInput placeholder="Titre ou nom de la boutique" formControlName="texte" required>
                </mat-form-field>

                <!-- couleur de texte  -->
                <div class="d-flex mb-4">
                  <span>Couleur du titre :</span>
                  <div class="d-flex flex-wrap">
                    <div *ngFor="let color of textColors" (click)="changeColor(1,color)" class="carre text-center"
                      [ngStyle]="{'background-color': color.hexa}">
                      <img width="auto" height="auto" src="../../../assets/images/icons/true.png" alt=""
                        *ngIf="color === textColorSelected && color.nom !== 'Blanc'">
                      <img width="auto" height="auto" src="../../../assets/images/true-green2.png" alt=""
                        *ngIf="color === textColorSelected && color.nom === 'Blanc'">
                    </div>
                  </div>

                </div>

                <!-- phrase d'accroche -->
                <mat-form-field class="hadeenInput" appearance="fill" [floatLabel]="'auto'" *ngIf="page === 2">
                  <mat-label>Texte d'accroche (60 caractères max)</mat-label>
                  <textarea matInput placeholder="Description de la société" formControlName="phrase"
                    required></textarea>
                </mat-form-field>

                <!-- couleur de texte  -->
                <div class="d-flex mb-4" *ngIf="page === 2">
                  <span>Couleur texte :</span>
                  <div class="d-flex flex-wrap">
                    <div *ngFor="let color of textColors" (click)="changeColor(2,color)" class="carre text-center"
                      [ngStyle]="{'background-color': color.hexa}">
                      <img width="auto" height="auto" src="../../../assets/images/icons/true.png" alt=""
                        *ngIf="color === phraseColorSelected && color.nom !== 'Blanc'">
                      <img width="auto" height="auto" src="../../../assets/images/true-green2.png" alt=""
                        *ngIf="color === phraseColorSelected && color.nom === 'Blanc'">
                    </div>
                  </div>
                </div>

                <!-- bouton d'action -->
                <mat-form-field class="promotion-boutique__input hadeenInput" appearance="fill" [floatLabel]="'auto'">
                  <mat-label>Nom du bouton d'action (12 caractères max)</mat-label>
                  <input matInput placeholder="Nom du bouton" formControlName="nomBouton" required>
                </mat-form-field>

                <!-- style bouton -->
                <div class="mb-4">
                  <span>Style du bouton :</span>
                  <div class="d-flex justify-content-md-between flex-wrap" id="buttons">
                    <button *ngFor="let bouton of styleButtons;let i=index"
                      (click)="styleButtonSelected = bouton; this.sectionForm.get('styleBouton').setValue(bouton.id);"
                      class="{{bouton.nomClasse}} mt-2 mr-2 mr-md-0" type="button" mat-flat-button>
                      Bouton {{bouton.id}}
                      <img width="auto" height="auto" *ngIf="styleButtonSelected === bouton" src="../../../assets/images/true-green2.png" alt=""
                        style="position: absolute;top: 30px;left: 35px;">
                    </button>
                  </div>
                </div>

                <!-- niveau d'affichage -->
                <mat-form-field appearance="fill" class="promotion-boutique__input" *ngIf="page === 2">
                  <mat-label>Emplacement</mat-label>
                  <mat-select formControlName="niveauAffichage">
                    <mat-option value="LEFT">LEFT</mat-option>
                    <mat-option value="RIGHT">RIGHT</mat-option>
                    <mat-option value="CENTER">CENTER</mat-option>
                  </mat-select>
                </mat-form-field>

                <!-- aperçu -->
                <span class="mb-2 text-center">Aperçu :</span>

                <div class="mb-4">
                  <div *ngIf="!media || (media && media.type === 'image')"
                    [ngClass]="{'col-12': page === 2, 'col-8 col-md-4 mx-auto': page === 3,'text-center': (f.niveauAffichage && f.niveauAffichage.value === 'CENTER') || page === 3, 'text-right': f.niveauAffichage && f.niveauAffichage.value === 'RIGHT'}"
                    class="py-5" [ngStyle]="{'border': media ? '0' : 'dashed 1.5px #D6D6D6'}"
                    style="min-height: 180px;">
                    <img width="auto" height="auto" *ngIf="croppedImage" class="child1" [src]="croppedImage"
                      style="height:100%;object-fit:cover;" />
                    <div class="child1 child2 pt-5">
                      <p [ngStyle]="{'color': textColorSelected.hexa}">
                        {{ f.texte.value.length > 0 ? f.texte.value : "Texte d'accroche"}}
                      </p>
                      <small [ngStyle]="{'color': phraseColorSelected.hexa}" *ngIf="page === 2">
                        {{ f.phrase.value.length > 0 ? f.phrase.value : "Phrase d'accroche"}}
                      </small> <br>
                      <button class="{{styleButtonSelected.nomClasse}} mr-2 mr-md-0" type="button" mat-flat-button>
                        {{ f.nomBouton.value.length > 0 ? f.nomBouton.value : 'Bouton'}}
                      </button>
                    </div>
                  </div>

                </div>

                <div class="col-12 text-center mb-4">
                  Selectionnez une offre qui vous convient mettre en avant votre article
                </div>

                <!-- tarif promotion -->
                <div class="d-flex flex-column flex-md-row align-items-stretch">
                  <div *ngFor="let choice of offresToShow;let i=index"
                    [ngClass]="{'mt-4 mt-md-0': i>0, 'px-0 px-md-2': i === 1}">
                    <div class="card" style="height: 100%;">
                      <div class="card-body text-center">
                        <div class="numberCircle">
                          <mat-radio-group [(ngModel)]="choixOffre" [ngModelOptions]="{standalone: true}">
                            <mat-radio-button [value]="choice"></mat-radio-button>
                          </mat-radio-group>
                        </div>
                        <p class="card-text">
                          {{choice.description}} à <span style="color:#345B2B;"> {{choice.price}} € HT
                          </span> <br>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

                <!-- paiement -->
                <div class="col-12 my-3 px-0">
                  <div class="card">
                    <div class="card-body px-3 px-md-5">
                      <div class="col-12 px-0 form-group" *ngIf="infosPaiements">
                        {{infosPaiements?'Vous avez déjà une carte enregistrée lors de votre paiement précédente':''}}
                      </div>
                      <ng-container *ngIf="modifierCarte; else elseTemplate">

                        <div class="col-12 px-0 my-2">
                          <img width="auto" height="auto" class="" src="../../../../../assets/images/Groupe 596.png">
                          <img width="auto" height="auto" class="" src="../../../../../assets/images/Groupe 594.png" style="margin: 0 10px;">
                          <img width="auto" height="auto" class="" src="../../../../../assets/images/Groupe 599.png" style="margin-right: 10px;">
                          <img width="auto" height="auto" src="assets/images/icons/American_Express.svg" alt="" style="width: 55px;">
                        </div>

                        <mat-form-field class="w-100 promotion-boutique__input hadeenInput">
                          <mat-label>Nom du Titulaire de la carte</mat-label>
                          <input matInput placeholder="Nom du titulaire de la carte" formControlName="titulaire">
                        </mat-form-field>

                        <div id="cardElement" style="border:1px solid #d9d9d9; padding: 15px;">
                          <!-- Elements will create input elements here -->
                        </div>

                        <div id="cardErrors" role="alert"></div>

                        <mat-checkbox formControlName="saveCarte">Sauvegarder ma carte</mat-checkbox>
                      </ng-container>
                      <ng-template #elseTemplate>
                        <div class="d-flex justify-content-around">
                          <img width="auto" height="auto" *ngIf="infosPaiements.brand === 'visa'"
                            src="../../../../../assets/images/Groupe 599.png">
                          <img width="auto" height="auto" *ngIf="infosPaiements.brand !== 'visa'"
                            src="../../../../../assets/images/Groupe 596.png">
                          <span>{{infosPaiements.last4}}</span>
                          <a (click)="modifierCarte = true;infosPaiements = undefined;stripeInit()">Modifier</a>
                        </div>
                      </ng-template>
                    </div>
                  </div>
                </div>
                <button class="h-btn-black w-100" [disabled]="this.sectionForm.invalid" type="submit"
                  mat-flat-button>VALIDER</button>

              </div>
              <!-- end div flex  -->
            </form>
          </div>

        </div>
        <!-- end row -->
      </div>
    </ng-container>



  </div>

</div>
<!-- end row -->

<!-- Modal -->
<div class="modal fade" id="staticBackdrop" data-backdrop="static" data-keyboard="false" tabindex="-1"
  aria-labelledby="staticBackdropLabel" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-body">
        <p class="m-0 text-center">merci d'avoir rempli le formulaire</p>
        <div class="text-center">
          <mat-spinner *ngIf="state === 0" class="m-auto" style="width: 35px; margin-top: -25px;">
          </mat-spinner>
        </div>

        <div class="text-center m-3" *ngIf="state === 1"><img width="auto" height="auto" src="../../../../assets/images/logo/refus@2x.png" alt=""
            style="width: 35px;"></div>

        <div class="text-center m-3" *ngIf="state === 2"><img width="auto" height="auto" src="../../../../assets/images/logo/success@2x.png" alt=""
            style="width: 35px;"></div>

        <p class="m-0 text-center" style="margin-top: -25px;">{{ messageModal }}</p>

        <div class="text-center mt-3">
          <button type="button" *ngIf="state === 1" class="h-btn-green" style="padding: 5px 30px; width: fit-content;"
            (click)="closeModal()">Modifier</button>
          <button type="button" *ngIf="state === 2" class="h-btn-green" style="padding: 5px 30px; width: fit-content;"
            (click)="closeModal(2)">OK</button>
        </div>
      </div>
    </div>
  </div>
</div>
