import { ToastrService } from 'ngx-toastr';
import { StorageService } from './../../../services/storage.service';
import { environment as env } from './../../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

// export interface DialogData {
//   animal: string;
//   name: string;
//   isValid: boolean;
// }

@Component({
  selector: 'app-dialog-frais-livraison-dialog',
  templateUrl: './dialog-frais-livraison-dialog.component.html',
  styleUrls: ['./dialog-frais-livraison-dialog.component.scss']
})
export class DialogFraisLivraisonDialogComponent implements OnInit {

  // constructor() { }
  constructor(
      public dialogRef: MatDialogRef<DialogFraisLivraisonDialogComponent>, private toastr: ToastrService,
      @Inject(MAT_DIALOG_DATA) public data: any, private httpClient: HttpClient, private storageService: StorageService) {}

  ngOnInit(): void {
  }

  onNoClick(): void {
    this.dialogRef.close();
  }


  defaultFrais() {
    const data = new FormData();
    // data.set('enable', 'false');
    data.set(`frais[0].frais_livraisons[0].type`, 'GRATUIT_PRODUIT');
    data.set(`frais[0].frais_livraisons[0].minQuantity`, '1');
    data.set(`frais[0].type`, 'GRATUIT_PRODUIT');
    this.httpClient.post(`${env.ws_url}frais-livraisons`, data).subscribe((resp: any) => {
      this.toastr.success('Mise à jour reussie');
      this.getVendeur();
    });
  }


  getVendeur() {
    this.httpClient.get(`${env.ws_url}users/vendeur/byId`).subscribe((respVendeur: any) => {
      const newUser = JSON.parse(this.storageService.getItem('User'));
      newUser['has_frais_livraison'] = respVendeur.data.vendeur.has_frais_livraison;
      this.storageService.setItem('User', JSON.stringify(newUser));
      this.onNoClick();
    });
  }
}
