import { ProductService } from 'src/app/services/product.service';
import { CommonService } from './../../../services/common.service';
import { ToastrService } from 'ngx-toastr';
import { Component, OnInit, OnDestroy, Input, TemplateRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { HttpService } from 'src/app/services/http.service';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { StorageService } from 'src/app/services/storage.service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';

declare var $;

@Component({
  selector: 'app-achat',
  templateUrl: './achat.component.html',
  styleUrls: ['./achat.component.scss']
})
export class AchatComponent implements OnInit, OnDestroy {
  user;
  message = '';
  message2 = '';
  modalRefListValid: BsModalRef;

  showSpinner = false;
  typeList = 1;
  eventDefault = 'MARIAGE';
  imgDefault = 'assets/static/bg-marriage.png';
  achatFormGroup: FormGroup;

  @Input() showList?;

  idList;
  myProductsList;
  dataParam;
  classNouveau = 'h-btn-black-bordered';
  classListe = 'h-btn-green-bordered';

  constructor(
    private httpService: HttpService, private formBuilder: FormBuilder, private toastr: ToastrService,
    private router: Router, public commonService: CommonService, private activatedRoute: ActivatedRoute,
    public productService: ProductService, private storageService: StorageService,  private modalService: BsModalService) {
      this.router.routeReuseStrategy.shouldReuseRoute = () => false;
  }

  ngOnInit(): void {
    this.showList = this.showList ? true : false;
    this.dataParam = this.activatedRoute.data.subscribe(v => {
      if (v.some_data === '2') {
        this.showList = true;
      }
    });

    this.idList = this.activatedRoute.snapshot.paramMap.get('idList');
    if (this.idList) {
      this.getListAchatById(this.idList);
    }
    this.achatFormGroup = this.formBuilder.group({
      titreCtrl: ['', Validators.required]
    });
  }

  ngOnDestroy() {
    this.dataParam.unsubscribe();    
  }

  // Liste d'achats
  setTypeList(ind) {
    this.typeList = ind;
    switch (ind) {
      case 1:
        this.imgDefault = 'assets/static/bg-marriage.png';
        this.eventDefault = 'MARIAGE';
        break;
      case 2:
        this.imgDefault = 'assets/static/bg-naissance.png';
        this.eventDefault = 'NAISSANCE';
        break;
      case 3:
        this.imgDefault = 'assets/static/bg-anniv.png';
        this.eventDefault = 'ANNIVERSAIRE';
        break;
      case 4:
        this.imgDefault = 'assets/static/bg-autres.png';
        this.eventDefault = 'AUTRE';
        break;
      default:
        break;
    }
  }

  async createAchat() {
    if (!this.achatFormGroup.invalid) {
      this.showSpinner = true;
      const achatDataValue = this.achatFormGroup.value;
      const imgFile = this.urlToObject(this.imgDefault);
      const formData = new FormData();
      formData.set('event', this.eventDefault); // MARIAGE, NAISSANCE, ANNIVERSAIRE
      formData.set('image', await imgFile);
      formData.set('title', achatDataValue.titreCtrl);
      this.httpService.post('achats', formData).subscribe((resp: any) => {
        this.productService.getListeAchat();

        this.httpService.get('users/acheteur/byId').subscribe((respUser: any) => {
          const user = this.commonService.user;
          user.sousVendeur = true;
          this.commonService.user = user;
          this.storageService.setItem('User', JSON.stringify(user));
          this.showSpinner = false;
          this.openModal();
        });
      });
    } else {
      document.getElementById('achatTitre').focus();
      this.toastr.error('veuiller vérifier les informations', '', {
        timeOut: 3000
      });
    }
  }

  async urlToObject(image) {
    const response = await fetch(image);
    const blob = await response.blob();
    const file = new File([blob], 'image.jpg', {type: blob.type});
    return file;
  }


  activerListeAchat() {
    if (!this.commonService.user.sousVendeur) {
      const formData = new FormData();
      this.httpService.post('achats/modifier', formData).subscribe((resp: any) => {
        this.commonService.user = resp.data.user;
        this.storageService.setItem('User', JSON.stringify(resp.data.user));
        this.toastr.success('Votre liste est activé', '', {
          timeOut: 3000
        });
      });
    } else {
      const formData = new FormData();
      formData.set('id', '1');
      this.httpService.post(`achats/finaliser`, formData).subscribe((resp: any) => {
        const user = this.commonService.user;
        user.sousVendeur = false;
        this.commonService.user = user;
        this.storageService.setItem('User', JSON.stringify(user));
      });
    }
  }

  getListAchatById(idList) {
    this.showSpinner = true;
    this.httpService.get(`achats/${idList}`).subscribe((resp: any) => {
      this.myProductsList = resp.data;
      setTimeout(() => {
        this.showSpinner = false;
      }, 500);
    });
  }
  supprimerProduit(itemProduct) {
    this.httpService.get(`achats/${this.idList}/remove/${itemProduct.id}`).subscribe((resp: any) => {
      this.toastr.warning('Article supprimé', '', {
        timeOut: 3000
      });
      this.getListAchatById(this.idList);
    });
  }


  openModal() {
    $('#staticBackdropListeAdd').modal('show');
  }

  closeModal(state) {
    $('#staticBackdropListeAdd').modal('hide');
    if (state) {
      this.router.navigate(['/' + state]);
    }
  }

  setShow(id) {
    if (id === 1) {
      this.classNouveau = 'h-btn-black-bordered';
      this.classListe = 'h-btn-green-bordered';
    } else {
      this.classNouveau = 'h-btn-green-bordered';
      this.classListe = 'h-btn-black-bordered';
    }
  }

  supprimerLA(): void {
    this.showSpinner = true;
    this.httpService.delete(`achats/${this.idList}`).subscribe((resp: any) => {
      this.toastr.success('La liste a bien été supprimé', '', {
        timeOut: 3000
      });
      this.showSpinner = true;
      this.router.navigate(['/compte/mes-achats']);
    });
  }

  validerListe(template: TemplateRef<any>) {
    const user = this.commonService.user;
    this.user = user;

    this.message2 = 'Bonjour,\n';
    this.message2 += '«' + this.user.pseudo + '» t’invite à consulter :\n';
    
    this.message2 += '- ' + this.myProductsList.achat.title + ':' + 'code ' + this.myProductsList.achat.code + '\n';

    this.message2 += `\n N’hésitez pas à vous rendre sur www.hadeen-place.fr ou télécharger l’application mobile, vous connecter et vous rendre dans la rubrique Mon compte /Consulter une liste d’achat et insérer le code reçu.

    À très vite sur hadeen-place.fr`;

    this.message = encodeURIComponent(this.message2);
    this.modalRefListValid = this.modalService.show(template, {keyboard: false});
  }
  
  onchangeInput() {
    this.message = encodeURIComponent(this.message2);
  }
}
