<div class="row">

  <div class="col-12 col-md-12">
    <!-- Mon compte -->
    <div class="row">
      <div class="col-12 mb-0">
        <div class="header">
          <ul class="breadcrumb mb-0">
            <li [routerLink]="['/compte']"><a>Compte</a></li>
            <li class="active"><a>Mon profil</a></li>
          </ul>
        </div>
      </div>
      <div class="col-12 mb-4">
        <h1 class="text-center">MES INFORMATIONS PERSONNELLES</h1>
      </div>

      <form [formGroup]="userForm" id="ngForm" #bForm="ngForm" class="w-100" (ngSubmit)="onSubmit()">
        <div class="col-12 mb-4">
          <div class="mb-3 pl-3 pr-3 pl-md-5 pr-md-5 pt-4 pb-4" style="border: 1px solid #D6D6D6; cursor: pointer;">
            <div class="row mb-2">
              <div class="col-6">
                <button class="w-100" (click)="userForm.get('genre').setValue(1)"
                  [ngClass]="f.genre.value === 1 ? 'h-btn-green': 'h-btn-grey-green'" type="button"
                  mat-flat-button>M</button>
              </div>
              <div class="col-6">
                <button class="w-100" (click)="userForm.get('genre').setValue(2)"
                  [ngClass]="f.genre.value === 2 ? 'h-btn-green': 'h-btn-grey-green'" type="button"
                  mat-flat-button>Mme</button>
              </div>
            </div>

            <div class="row mb-2">
              <mat-form-field class="col-6 hadeenInput" appearance="fill" [floatLabel]="'auto'">
                <mat-label>Prénom</mat-label>
                <input matInput placeholder="Nom de la Société" formControlName="prenom" required>
              </mat-form-field>
              <mat-form-field class="col-6 hadeenInput" appearance="fill" [floatLabel]="'auto'">
                <mat-label>Nom</mat-label>
                <input matInput placeholder="Nom de la Société" formControlName="nom" required>
              </mat-form-field>
              <mat-form-field class="col-12 hadeenInput" appearance="fill" [floatLabel]="'auto'">
                <mat-label>Email</mat-label>
                <input matInput placeholder="Nom de la Société" formControlName="mail" required>
              </mat-form-field>

            </div>

            <div class="row">
              <mat-checkbox class="example-margin col-12">Inscrivez-vous à notre newsletter !</mat-checkbox>
              <mat-checkbox class="example-margin col-12">Je souhaite recevoir par e-mail les remises et astuces de la
                Brigade Hadéen-place</mat-checkbox>
            </div>


            <!-- Mot de passe -->
            <div class="row mt-5">
              <p class="col-12 mb-0">Modifier mon mot de passe</p>
              <mat-form-field class="col-12 CInput" appearance="fill" floatLabel="auto">
                <mat-label>Votre mot de passe actuel</mat-label>
                <input matInput [type]="hideActuel ? 'password' : 'text'" formControlName="mdpActuel"
                  placeholder="Votre mot de passe actuel" required>
                <button mat-icon-button matSuffix (click)="hideActuel = !hideActuel" [attr.aria-label]="'Hide password'"
                  [attr.aria-pressed]="hideActuel">
                  <mat-icon>{{hideActuel ? 'visibility_off' : 'visibility'}}</mat-icon>
                </button>
              </mat-form-field>
              <mat-form-field class="col-12 CInput" appearance="fill" floatLabel="auto">
                <mat-label>Nouveau mot de passe</mat-label>
                <input matInput [type]="hideNew ? 'password' : 'text'" formControlName="mdpNouveau"
                  placeholder="Nouveau mot de passe" required>
                <button mat-icon-button matSuffix (click)="hideNew = !hideNew" [attr.aria-label]="'Hide password'"
                  [attr.aria-pressed]="hideNew">
                  <mat-icon>{{hideNew ? 'visibility_off' : 'visibility'}}</mat-icon>
                </button>
                <mat-hint>Le mot de passe doit contenir au moins une Majuscule, une minuscule et une chiffre</mat-hint>
              </mat-form-field>
              <mat-form-field class="col-12 mt-3 CInput" appearance="fill" floatLabel="auto">
                <mat-label>Confirmer votre nouveau mot de passe</mat-label>
                <input matInput type="password" formControlName="mdpConfirmation"
                  placeholder="Confirmer votre nouveau mot de passe" required>
              </mat-form-field>
            </div>

            <div class="row">
              <mat-radio-group class="col-12" aria-label="Select an option" [(ngModel)]="selectedValue"
                [ngModelOptions]="{standalone: true}">
                <mat-radio-button value="1" (click)="selectedValue = selectedValue ? false : true">
                  <span style="white-space: initial;">J'accepte les conditions générales et la politique de
                    confidentialité</span>
                </mat-radio-button>
              </mat-radio-group>
            </div>

            <div class="row mb-2">

              <div class="col-12 col-md-6 text-center">
                <button type="button" class="h-btn-grey-green" (click)="openModalContact()" mat-flat-button>Besoin
                  d’aide ? Nous-contacter</button>
              </div>
              <div class="col-12 col-md-6">
                <button class="w-100 mt-3 mt-md-0 h-btn-black" type="submit" mat-flat-button>
                  CHANGER MON MOT DE PASSE
                </button>
              </div>
            </div>
          </div>
        </div>
      </form>
      <!-- end form  -->
    </div>

  </div>
</div>



<!-- modal pour contacter le moderateur -->
<div class="modal fade" id="modalContactModerateur" tabindex="-1" role="dialog" aria-labelledby="modalLabel"
  aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content" style="border-radius: 8px;">

      <div class="modal-header pb-0">
        <div class="col-12">
          <h6>À : Hadéen-place</h6>
        </div>
      </div>
      <div class="modal-body">
        <div class="col-sm-12">
          <textarea class="form-control col-sm-12 area" rows="5" placeholder="Ecrire le message ici..."
            [(ngModel)]="messageText"></textarea>
          <button class="h-btn-grey-green float-right mt-2 px-3" data-dismiss="modal" mat-flat-button
            (click)="sendMessage()">Envoyer</button>
        </div>
      </div>

    </div>
  </div>
</div>
<!-- end modal pour contacter le moderateur -->
