import { HttpClient } from '@angular/common/http';
import { Component, ElementRef, Inject, OnInit, PLATFORM_ID, ViewChild } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import { ChatService } from 'src/app/services/chat.service';
import { CommonService } from 'src/app/services/common.service';
import { BsModalService } from 'ngx-bootstrap/modal';
// import * as L from 'leaflet';
import * as FormDataServer from 'form-data';
declare var $;

@Component({
  selector: 'app-page-dynamique',
  templateUrl: './page-dynamique.component.html',
  styleUrls: ['./page-dynamique.component.scss']
})
export class PageDynamiqueComponent implements OnInit {
  @ViewChild('loginTemplate') loginTemplate: ElementRef;
  myMessage = '';
  myMessage2 = [];
  showSpinner = false;
  screenWidth;
  pageName;
  dataPage4;
  isHero = 0;
  bsRangeValue;
  panelOpenState = false;

  constructor(
    @Inject(PLATFORM_ID) private platformId, @Inject(DOCUMENT) private document: Document, private activatedRoute: ActivatedRoute, private router: Router, private httpClient: HttpClient,
    private titleService: Title, private metaService: Meta, public chatService: ChatService, public commonService: CommonService,
    private modalService: BsModalService) {
    this.pageName = this.activatedRoute.snapshot.paramMap.get('slug-page');
    // this.pageName = this.pageName.trim();
    if (isPlatformBrowser(this.platformId)) {
      this.screenWidth = screen.width;
    }
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
  }

  ngOnInit(): void {
    if (!this.pageName) {
      this.pageName = 'home-page';
    }
    this.httpClient.get(`${environment.strapi_url}pages?Slug=${this.pageName}`).subscribe((dataHomeArray: any) => {
      if (dataHomeArray.length) {
        const dataHome = dataHomeArray[0];
        dataHome.Builder.forEach(elt => {
          if (elt.__component === 'composants.hero') {
            this.isHero += 1;
            if (this.isHero < 2) {
              elt.isFirst = true;
            } else {
              elt.isFirst = false;
            }
          }
          if (elt.__component === 'composants.mini-card-group') {
            if (elt.mini_card) {
              // tslint:disable-next-line: max-line-length
              this.httpClient.get(`${environment.strapi_url}mini-card-groups/${elt.mini_card.id}`).subscribe((dataCardGroup: any) => {
                elt.MiniCards = dataCardGroup.MiniCards;
                elt.MiniCards.forEach(eltNew => {
                  if (eltNew.InternalURL) {
                    eltNew.InternalURL['Title2'] = (eltNew.InternalURL.Title).trim().split(' ').join('_');
                    eltNew.InternalURL['Slug2'] = (eltNew.InternalURL.Slug).trim().split(' ').join('_');
                    if (eltNew.InternalURL && eltNew.InternalURL.parent_parent_page) {
                      // tslint:disable-next-line: max-line-length
                      this.httpClient.get(`${environment.strapi_url}pages/${eltNew.InternalURL.parent_parent_page}`).subscribe((dataCurrentPage: any) => {
                        eltNew.InternalURL.parent_parent_page = dataCurrentPage;
                      });
                    }
                    if (eltNew.InternalURL && eltNew.InternalURL.parent_page) {
                      // tslint:disable-next-line: max-line-length
                      this.httpClient.get(`${environment.strapi_url}pages/${eltNew.InternalURL.parent_page}`).subscribe((dataCurrentPage: any) => {
                        eltNew.InternalURL.parent_page = dataCurrentPage;
                      });
                    }
                  }
                });
              });
            }
            if (elt.MiniCards) {
              elt.MiniCards.forEach(eltItem => {
                if (eltItem.InternalURL) {
                  eltItem.InternalURL.Title = ((eltItem.InternalURL.Title).trim()).split(' ').join('_');
                }
              });
            }
          }
          if (elt.__component === 'composants.youtube-embed' || elt.YoutubeEmbed) {
            let videoUrl = elt.YoutubeEmbed;
            if (videoUrl.includes('youtu.be/')) {
              videoUrl = videoUrl.replace('youtu.be/', 'www.youtube.com/watch?v=');
            }
            const arepl = videoUrl.replace('watch?v=', 'embed/');
            elt.YoutubeEmbed = arepl;
          }
          if (elt.__component === 'composants.section-texte') {
            const corpsh1 = this.replaceAll(elt.corps, 'h2>', 'h1>');
            const corpsh2 = this.replaceAll(corpsh1, 'h3>', 'h2>');
            const corpsh3 = this.replaceAll(corpsh2, 'h4>', 'h3>');
            elt.corps = this.HASHTAG_FORMATTER(corpsh3);
            // elt.corps = this.HASHTAG_FORMATTER(elt.corps);
          }
          if (elt.__component === 'composants.media-and-text' && elt.MapEmbed) {
            setTimeout(() => {
              this.initMap(elt.MapEmbed.Latitude, elt.MapEmbed.Longitude, elt.MapEmbed.zoom);
            }, 200);
            if (elt.Link && elt.Link.InternalURL) {
              elt.Link.InternalURL['Title2'] = (elt.Link.InternalURL.Title).trim().split(' ').join('_');
              elt.Link.InternalURL['Slug2'] = (elt.Link.InternalURL.Slug).trim().split(' ').join('_');
            }
          }
          if (elt.__component === 'composants.produit-group') {
            let ids = '';
            elt['data'] = [];
            elt.Produits.forEach((eltProd, ind) => {
              // this.httpClient.get(environment.ws_url + `produits/${'HADEEN'}/${eltProd.idProduit}`).subscribe((response: any) => {
              //   elt['data'].push(response.data);
              // });
              if (ind > 0 && ind !== elt.Produits.length) {
                ids += ',';
              }
              ids += eltProd.idProduit
            });
            const fd = new FormDataServer();
            fd.append('plateforme', 'HADEEN');
            fd.append('ids', ids);
            this.httpClient.post(environment.ws_url + `produits/search`, fd).subscribe((response: any) => {
              elt['data'] = response.data.produits;
            });
          }
          if (elt.__component === 'composants.vendeur-group') {
            // let ids = '';
            elt.Vendeurs.forEach((eltSeller, ind) => {
              this.httpClient.get(environment.ws_url + `users/HADEEN/vendeur/${eltSeller.IDVenndeur}`).subscribe((response: any) => {
                eltSeller['data'] = response.data;
              });
              // if (ind > 0 && ind !== elt.Vendeurs.length) {
              //   ids += ',';
              // }
              // ids += eltSeller.IDVenndeur
            });
            // const fd = new FormDataServer();
            // fd.append('plateforme', 'HADEEN');
            // fd.append('ids', ids);
            // this.httpClient.post(environment.ws_url + `produits/search`, fd).subscribe((response: any) => {
            //   elt.Vendeurs = response.data.produits;
            // });
          }
          if (elt.__component === 'composants.testims-group') {
            if (elt.testimonials_group) {
              // tslint:disable-next-line: max-line-length
              this.httpClient.get(`${environment.strapi_url}testims-groups/${elt.testimonials_group.id}`).subscribe((dataProduct: any) => {
                elt.testimonials = dataProduct.testims;
              });
            }
          }
          if (elt.__component === 'composants.data-group') {
            if(elt.data_group && elt.data_group.id) {
              this.httpClient.get(`${environment.strapi_url}data-groups/${elt.data_group.id}`).subscribe((dataProduct: any) => {
                elt.data_group = dataProduct;
              });
            } else {
              elt.data_group = {
                'Data': elt.Data || {}
              };
            }
          }
          if (elt.__component === 'composants.questions-group') {
            if (elt.faq) {
              // tslint:disable-next-line: max-line-length
              this.httpClient.get(`${environment.strapi_url}questions-groups/${elt.faq.id}`).subscribe((dataProduct: any) => {
                elt.faq = dataProduct;
              });
            }
          }
        });
        this.dataPage4 = dataHome;
        let iiimmmggg = null;
        if (this.dataPage4.Thumbnail) {
          iiimmmggg = `https://strapi.hadeen-place.fr${this.dataPage4.Thumbnail.url}`
        }
        this.updateMeta(this.dataPage4.Title, this.dataPage4.Description, iiimmmggg);

        setTimeout(() => {
          this.showSpinner = false;
        }, 500);

      } else {
        this.router.navigate(['/']);
      }
    });
  }


  clearDate() {
    this.bsRangeValue = [];
  }
  goTo() {
    // if (this.bsRangeValue) {
    //   this.commonService.bsDateRang = this.bsRangeValue;
    // }
    // if (this.activites === '1') {
    //   this.router.navigate(['/' + this.langue + '/activites']);
    // } else {
    //   this.router.navigate(['/' + this.langue + '/rental']);
    // }
  }

  initMap(lat: number, lang: number, zoom: number) {
    // $('.leaflet-bottom').hide();
    // const map = L.map('mapid2').setView([lat, lang], 12);
    // // const map = L.map('mapid2').setView([46.168448, 7.324027], 12);
    // L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
    //   attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
    // }).addTo(map);
  }


  testFunct(produits, idStok) {
    const taaaag = produits.stocks.map(s => s.idStock);
    return taaaag.includes(idStok);
  }

  readMore(text) {
    if (text['readmore']) {
      delete text['readmore'];
    } else {
      text['readmore'] = true;
    }
  }
  public onShowPicker(event) {
    const dayHoverHandler = event.dayHoverHandler;
    const hoverWrapper = ($event) => {
      const cell = $event;
      cell.isHovered = false;
      return dayHoverHandler($event);
    };
    event.dayHoverHandler = hoverWrapper;
  }

  updateMeta(title, description, image?) {
    this.titleService.setTitle(title + ' | Hadéen-Place | Consommer Responsable |');
    // const altImage = title + ' | Hadéen-Place | Consommer Responsable |';
    this.metaService.updateTag({
      name: 'description',
      // tslint:disable-next-line: max-line-length
      content: String(description)
    });
    this.metaService.updateTag( { property: 'og:title', content: title } );
    this.metaService.updateTag( { property: 'og:description', content: description } );
    if (image) {
      this.metaService.updateTag( { property: 'og:image', content: image } );
      this.metaService.updateTag( { property: 'og:image:alt', content: title + ' | Hadéen-Place | Consommer Responsable |' } );

      this.updateImage(image);
    }
    this.metaService.updateTag({property: 'og:url', content: `${environment.sousDomaine}${this.router.url}`})
  }

  updateImage(image) {
    const head = this.document.getElementsByTagName('head')[0];
    let element: HTMLLinkElement = this.document.querySelector(`link[rel='image_src']`) || null;
    if (element == null) {
      element = this.document.createElement('link') as HTMLLinkElement;
      head.appendChild(element);
    }
    element.setAttribute('rel', 'image_src');
    element.setAttribute('href', image);
  }

  HASHTAG_FORMATTER(description): any {
    // return description.replace(/(^|\s)(#[a-z\d-]+)/ig, (m, g1, g2) => {
    // return description.replace(/\#(\S+)/g, (m, g1, g2) => {
    return description.replace(/\#(\S+)/ig, (m, g1, g2) => {
      return `<a href="${environment.sousDomaine}/recherche?mot=${g1}" style="color: #707070 !important"> ${m} </a>`;
    });
  }


  openLogin() {
    this.chatService.loginModal(this.loginTemplate);
    // this.router.navigate(['/login-devenir-vendeur']);
  }

  sendMessage(vendeur) {
    if (this.myMessage2[vendeur.id]) {
      const message = {
        receiver: vendeur.id,
        objet: vendeur.data.boutique.nom,
        message: this.myMessage2[vendeur.id],
      };
      this.myMessage = '';
      this.myMessage2[vendeur.id] = '';
      this.chatService.sendMessage(message, this.loginTemplate);
    }
  }

  formatUrl(url) {
    return (url.trim()).split(' ').join('_');
  }

  replaceAll(str: string, find: string, replace: string) {
    var escapedFind=find.replace(/([.*+?^=!:${}()|\[\]\/\\])/g, "\\$1");
    return str.replace(new RegExp(escapedFind, 'g'), replace);
  }
}
