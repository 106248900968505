<div class="card__group">
  <div class="uk-grid-medium" uk-grid style="margin-top: 10px; padding-left: 0px;">
    <div
      [ngClass]="item.Columns === 'Two'? 'card__group__item uk-width-1-2@m uk-width-1-1': item.Columns === 'Three' ? 'card__group__item uk-width-1-3@m uk-width-1-1': item.Columns === 'Four' ? 'card__group__item uk-width-1-4@m uk-width-1-2': 'uk-width-1-3@m uk-width-1-1'"
      *ngFor="let itemCard of item.Card">

      <div class="card">
        <div class="card__img">
          <img width="auto" height="auto" loading="lazy" *ngIf="itemCard.Image" src="https://strapi.hadeen-place.fr{{ itemCard.Image.url }}" [alt]="itemCard.Title" [title]="itemCard.Title">
          <div
            style="z-index:9; background-image: url('assets/images/labelBackground.png'); background-repeat: no-repeat; background-position: left; background-size: cover; position: absolute; right: 0px; top: 8px; color: #ffffff; padding-left: 20px; padding-right: 5px; font-weight: 500; font-size: 12px;">
            <span>{{ itemCard.Favorite }}</span>
          </div>
        </div>

        <div class="card__content">
          <h3 class="card__content--title">{{ itemCard.Title }}</h3>
          <p class="card__content--desc">{{ itemCard.Description }}</p>
        </div>

        <div class="card__footer">
          <a class="card__footer--cta cta--one" *ngIf="itemCard.ExternalURL" [href]="itemCard.ExternalURL"
            rel="nofollow" target="{{itemCard.targetType}}">
            <button
              [ngClass]="item.LinkType === 'ButtonPrimary' ? 'h-btn-green-green' : item.LinkType === 'ButtonSecondary' ? 'h-btn-green-bordered' : 'h-btn-link'"
              *ngIf="itemCard.LinkText">
              {{ itemCard.LinkText }}
            </button>
          </a>
          <a class="card__footer--cta cta--one" *ngIf="!itemCard.ExternalURL && itemCard.page"
            [routerLink]="['/Blog/' + generateUrl(itemCard.page)]">
            <button
              [ngClass]="item.LinkType === 'ButtonPrimary' ? 'h-btn-green-green' : item.LinkType === 'ButtonSecondary' ? 'h-btn-green-bordered' : 'h-btn-link'"
              *ngIf="itemCard.LinkText">
              {{ itemCard.LinkText }}
            </button>
          </a>

          <a class="card__footer--cta cta--two" *ngIf="itemCard.ExternalURL2" [href]="itemCard.ExternalURL2"
            rel="nofollow" target="{{itemCard.targetType2}}">
            <button
              [ngClass]="item.LinkType === 'ButtonPrimary' ? 'h-btn-green-green' : item.LinkType === 'ButtonSecondary' ? 'h-btn-green-bordered' : 'h-btn-link'"
              *ngIf="itemCard.LinkText2">
              {{ itemCard.LinkText2 }}
            </button>
          </a>
          <a class="card__footer--cta cta--two" *ngIf="!itemCard.ExternalURL2 && itemCard.page2"
            [routerLink]="['/Blog/' + generateUrl(itemCard.page2)]">
            <button
              [ngClass]="item.LinkType === 'ButtonPrimary' ? 'h-btn-green-green' : item.LinkType === 'ButtonSecondary' ? 'h-btn-green-bordered' : 'h-btn-link'"
              *ngIf="itemCard.LinkText2">
              {{ itemCard.LinkText2 }}
            </button>
          </a>
        </div>
      </div>
    </div>
  </div>
</div>
