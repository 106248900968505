<div class="row">
  <div class="col-12 col-md-12">
    <div class="row">
      <div class="col-12 mb-4">
        <h1 class="text-center">MESSAGES DETAILS</h1>
      </div>

      <!-- <video *ngIf="video" width="320" height="240" controls autoplay>
        <source [src]="video" type="video/mp4">
      </video>        -->

      <div class="col-12">
        <h5 style="margin-bottom: 25px;">{{chatService.discussion.objet|titlecase }}
          <small class="float-right" style="font-size:70%;">Affichage : {{ test ? 'Réduit' : 'Tout'}} &nbsp;
            <a>
              <i *ngIf="test" class="fa fa-angle-down" aria-hidden="true"></i>
              <i *ngIf="!test" class="fa fa-angle-up" aria-hidden="true"></i>
            </a>
          </small>
        </h5>
        <!-- style="border: 1px solid #d9d9d9;" -->
        <div class="border-right border-left border-bottom"
          *ngFor="let item of chatService.discussion.messages; let i=index;" [ngClass]="{'border-top': i===0}"
          [hidden]="test && i<chatService.discussion.messages.length-3 && i>0">
          <div class="row p-3">
            <div class="col-6">
              <p></p>
              <img width="auto" height="auto" *ngIf="!item.is_sent && !chatService.discussion.receiver.imageProfil"
                src="../../../assets/images/logo/profilRounded.png" alt="" style="border-radius: 50%; width: 30px;">
              <img width="auto" height="auto" *ngIf="!item.is_sent && chatService.discussion.receiver.imageProfil"
                [src]="chatService.discussion.receiver.imageProfil" alt="" style="border-radius: 50%; width: 30px;">
              <b class="ml-2" *ngIf="!item.is_sent">{{chatService.discussion.receiver.pseudo | titlecase}}</b>

              <img width="auto" height="auto" *ngIf="item.is_sent && !commonService.user.imageProfil"
                src="../../../assets/images/logo/profilRounded.png" alt="" style="border-radius: 50%; width: 30px;">
              <img width="auto" height="auto" *ngIf="item.is_sent && commonService.user.imageProfil"
                [src]="commonService.user.imageProfil" alt="" style="border-radius: 50%; width: 30px;">
              <b class="ml-2" *ngIf="item.is_sent">Moi</b>
            </div>
            <div class="col-6">

              <small class="float-right" *ngIf="testDate(item.createdAt|date)">{{item.createdAt | date:"dd/MM/yy, h:mm
                a"}}</small>

            </div>
          </div>
          <div class="row px-3">
            <div class="col-12">
              <p [innerHTML]="item.message"
                [ngClass]="{'mb-0': i===0 && test && chatService.discussion.messages.length>4}"></p>

              <div *ngIf="item.files && item.files.length>0" class="mb-2">
                <div class="d-flex flex-column">
                  <a *ngFor="let file of getMessageOtherFiles(item.files)" (click)="downloadResource(file)">
                    <img width="auto" height="auto" src="../../../assets/images/logo/strombone.png" alt="" />
                    {{file.name ? file.name : getFilename(file.url)}}</a>
                </div>
                <div class="d-flex">
                  <div class="mr-2" *ngFor="let item of getMessageImages(item.files);let i=index;">
                    <figure *ngIf="!isVideo(item.type)">
                      <img width="auto" height="auto" [src]="item.url" style="max-width:150px;" class="rounded img-fluid border"
                        (click)="zoomImage(item.url)">
                    </figure>
                  </div>
                </div>

              </div>

            </div>
            <div class="col-4 col-md-2 mx-auto p-0 text-center"
              *ngIf="i===0 && chatService.discussion.messages.length>4"
              style="z-index: 3; background-color: white;position: relative;top:10px;">
              <a (click)="test = !test">
                <ng-container *ngIf="test; else elseTemplate">
                  + {{chatService.discussion.messages.length-4}} messages <i class="fa fa-chevron-circle-down"></i>
                </ng-container>
                <ng-template #elseTemplate>
                  Afficher moins <i class="fa fa-chevron-circle-up"></i>
                </ng-template>
              </a>

            </div>
          </div>
        </div>
      </div>

      <div class="col-12">
        <div *ngIf="showSpinner" class="spinner" style="position: fixed; top: 50%; left: 50%; z-index: 1;">
          <mat-spinner></mat-spinner>
        </div>
        <h6 class="my-2">Répondre</h6>
        <div class="ckeditor d-flex flex-column-reverse">
          <div id="toolbar-container"></div>

          <div id="editor">

          </div>
        </div>

        <div class="hiddenfile">
          <input type="file" id="fileinput" (change)="fileChangeEvent($event)"
            accept="image/*, .txt,.doc,.docx,application/msword,application/pdf,application/vnd.ms-excel" />
          <input type="file" id="videoinput" (change)="fileChangeEvent($event)" accept="video/*" />
        </div>

        <div class="row pt-2" *ngIf="urls.length>0">
          <div class="d-flex flex-column">
            <a *ngFor="let file of getMessageOtherFiles(urls)">
              <small class="badge badge-danger float-right" (click)="deleteFile(file)"><i
                  class="fa fa-close"></i></small>
              <img width="auto" height="auto" src="../../../assets/images/logo/strombone.png" alt="" class="ml-1" /> {{file.name}}</a>
          </div>
          <div *ngFor="let item of getMessageImages(urls);let i=index;"
            [ngClass]="{'col-2': !isVideo(item.type),'col-4': isVideo(item.type)}">
            <small class="badge badge-danger float-right" (click)="deleteFile(item)"><i class="fa fa-close"></i></small>
            <figure *ngIf="!isVideo(item.type)">
              <img width="auto" height="auto" [src]="item.url" class="rounded img-fluid border">
            </figure>
            <video *ngIf="isVideo(item.type) && playerSrc(item.file)" controls>
              <source [src]="playerSrc(item.file)">
              Your browser does not support the video tag.
            </video>
          </div>
        </div>

        <button type="button" (click)="sendMessage()" class="rounded-0 btn px-3 float-right my-2"
          style="background-color: #345B2B;color:white;">
          Envoyer
        </button>

      </div>
    </div>
  </div>
</div>



<div id="myModal" class="modal" [ngStyle]="{'display': displayModal}">
  <span class="close" (click)="displayModal = 'none'">&times;</span>
  <img width="auto" height="auto" class="modal-content" id="img01" [src]="zoomedImage">
</div>
