<div class="top-header">
    <hr>
    <div class="container">
        <div class="header">
            <ul class="breadcrumb">
                <li><a routerLinkActive="active" [routerLinkActiveOptions]="{exact:true}" routerLink="/">Hadéen-place</a></li>
                <li *ngIf="vendeurData">{{ vendeurData.boutique.nom }}</li>
            </ul>
        </div>
    </div>
</div>

<div class="container blockBody" *ngIf="vendeurData">
    <div class="containt" style="position: relative;">
        <img width="auto" height="auto" *ngIf="vendeurData.boutique.logo" class="rounded-circle d-block" [src]="vendeurData.boutique.logo" alt="" style="width: 113px; height: 113px; border: 1px solid #d9d9d9;">
        <img width="auto" height="auto" *ngIf="!vendeurData.boutique.logo" class="rounded-circle d-block" src="assets/static/av.png" alt="" style="width: 113px; height: 113px;">
        <h1>{{ vendeurData.boutique.nom }}</h1>
        <p style="white-space: pre-line;">{{ vendeurData.boutique.description }}</p>

        <div *ngIf="commonService.user">
            <ng-template #popTemplate class="text-right">
                <p class="text-left" style="font-size: 20px; font-weight: 500;">Contacter le vendeur</p>
                <mat-form-field class="w-100 hadeenInput">
                    <mat-label>Entrer votre message</mat-label>
                    <textarea matInput [(ngModel)]="myMessage" cdkTextareaAutosize cdkAutosizeMinRows="3"></textarea>
                </mat-form-field>
                <div class="text-right">
                    <button type="button" mat-flat-button (click)="sendMessage(); pop.hide()" style="background-color: #345b2b; border: none; color: #ffffff; margin-top: 7px; border-radius: 0em;">
            Envoyer
          </button>
                </div>
            </ng-template>
            <button class="contact" [popover]="popTemplate" containerClass="contactPopover" adaptivePosition="false" #pop="bs-popover">Contacter</button>
        </div>
        <button *ngIf="!commonService.user" (click)="openLogin()" class="contact">Contacter</button>
        <p class="mb-0" *ngIf="newText">{{ vendeurData.boutique.nom }} vous offre les frais de livraison à partir de {{ newText }}</p>
    </div>


    <div style="margin-top: 5%;" *ngIf="produitsByVendeur">
        <h2>PRODUITS</h2>
        <p class="text-center">{{ produitsByVendeur.nb_total || 0 }} produits en vente</p>
        <div class="row">
            <div class="col-md-3 col-lg-3 col-6 px-xs-0" *ngFor="let product of produitsByVendeur.produits | paginate: { itemsPerPage: 12, currentPage: page ,totalItems: produitsByVendeur.nb_total};let i=index">
                <div [ngClass]="i%2 === 0 ? 'pr-15' : 'pl-15'">
                    <app-produit-input [data]="product" [loginTemplate]="loginTemplate"></app-produit-input>
                </div>
            </div>
            <div class="col-12">
                <pagination-controls
                    class="modal-pagination text-center m-auto"
                    (pageChange)="changePage($event)"
                    directionLinks="true"
                    responsive="true"
                    autoHide="true"
                    previousLabel="PRÉCÉDENT"
                    nextLabel="SUIVANT">
                </pagination-controls>
            </div>
        </div>
    </div>
    <div style="margin: 5% 0;" id="avis-sur-le-vendeur">
        <h2>AVIS <span style="font-size: 0px;">{{ vendeurData.boutique.nom }}</span></h2>
        <div class="text-center">
            <ngb-rating [(rate)]="note">
                <ng-template let-fill="fill" let-index="index">
                    <span class="star" [class.full]="fill === 100">
            <span class="half" [style.width.%]="fill">&#9733;</span>&#9733;
                    </span>
                </ng-template>
            </ngb-rating>
            <label> {{vendeurData.vendeur.star | number: '1.2-2'}} / 5 | <span style="text-decoration: underline;">Voir tous les avis ({{vendeurData.avis.length}})</span></label>
        </div>
        <div class="row">
            <!-- avis -->
            <!-- <div class="col-md-6 col-lg-6 col-12" *ngFor="let a of avisToShow">
        <div class="text-center avis" style="border: 1px solid;padding: 4%;margin-bottom: 5%;">
          <div>
            <ngb-rating [(rate)]="a.star">
              <ng-template let-fill="fill" let-index="index">
                <span class="star" [class.filled]="fill === 100">&#9733;</span>
              </ng-template>
            </ngb-rating>
            <label> {{a.star}} / 5</label>
          </div>
          <p>{{a.commentaire}}</p>
          <small> {{a.acheteur.prenom|titlecase}} {{a.acheteur.nom |titlecase}}, <span class="text-muted">{{a.created_at|date}}</span></small>
        </div>
      </div> -->
            <!-- end avi  -->
            <div class="col-12">
                <div class="d-flex align-items-stretch flex-wrap">
                    <div class="col-md-6 col-lg-6 col-12" *ngFor="let a of avisToShow;let i=index" [ngClass]="{'mt-0 mt-md-4': i>1}">
                        <div class="text-center avis" style="border: 1px solid;padding: 4%;margin-bottom: 5%;height:100%;">
                            <div>
                                <ngb-rating [(rate)]="a.star">
                                    <ng-template let-fill="fill" let-index="index">
                                        <span class="star" [class.full]="fill === 100">
                      <span class="half" [style.width.%]="fill">&#9733;</span>&#9733;
                                        </span>
                                    </ng-template>
                                </ngb-rating>
                                <label> {{a.star}} / 5</label>
                            </div>
                            <p>{{a.commentaire}}</p>
                            <small> {{a.acheteur.pseudo | titlecase }}, <span
                  class="text-muted">{{a.created_at|date}}</span></small>
                        </div>
                    </div>
                </div>
            </div>


        </div>
    </div>
</div>
<hr>
<!-- <app-ads></app-ads> -->

<ng-template #vendeur>
    <div class="modal-header">
        <h4>Contacter le Vendeur</h4>
        <a type="button" class="close pull-right" aria-label="Close" (click)="modalVendeur.hide()">
            <span aria-hidden="true">&times;</span>
        </a>

    </div>
    <div class="modal-body" style="font-family: SFCompactDisplay-Medium;">
        <textarea name="message" style="width: 100%;font-family: 'SFCompactDisplay-Regular';" rows="10"></textarea>
        <button class="send">Envoyer</button>
    </div>
</ng-template>


<ng-template #loginTemplate>
    <app-login [modal]="chatService.modalRef2" [newsCustomer]="true"></app-login>
</ng-template>
