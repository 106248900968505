import { isPlatformBrowser } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { environment } from 'src/environments/environment';
import { DialogFraisLivraisonDialogComponent } from '../Component/common/dialog-frais-livraison-dialog/dialog-frais-livraison-dialog.component';

@Injectable({
  providedIn: 'root'
})
export class CommonService {

  user;
  nbPanier = 0;
  paniers = [];

  styleButtons = [
    {
      id : 1,
      nomClasse : 'h-btn-black'
    },
    {
      id : 2,
      nomClasse: 'h-btn-dore'
    },
    {
      id : 3,
      nomClasse : 'h-btn-green'
    },
    {
      id : 4,
      nomClasse : 'h-btn-green-clair'
    },
    {
      id : 5,
      nomClasse : 'h-btn-white'
    }
  ];

  couleurTexte = [
    { id: 1, nom: 'Noir', hexa: '#000000' },
    { id: 2, nom: 'Vert', hexa: '#B1BDB2' },
    { id: 3, nom: 'Blanc', hexa: '#ffffff' },
    // { id: 11, nom: 'Noir', hexa: '#000000' },
  ];

  niveau = [
    { pos: 'center', key: 'CENTER', nom: 'Centré' },
    { pos: 'flex-start', key: 'LEFT', nom: 'Gauche' },
    { pos: 'flex-end', key: 'RIGHT', nom: 'Droite' },
  ];

  codePromo = {
    value : '',
    isValid : false,
    data : {
      activated: false,
      code: "",
      createdAt: "",
      email: "",
      id: 0,
      min_valeur: 0,
      titre: "",
      type: "",
      updatedAt: "0",
      valeur: 0
    }
  };


  constructor(private httpClient: HttpClient, private toastrService: ToastrService,
    @Inject(PLATFORM_ID) private platformId, public dialog: MatDialog) {
    if (isPlatformBrowser(this.platformId)) {
      const isConnected = localStorage.getItem('User');
      if (isConnected) {
        this.user = typeof(isConnected) === 'string' ? JSON.parse(isConnected) : isConnected;
      }
      this.getPanier();

      const code = localStorage.getItem('codepromo');
      if(code){
        this.codePromo = JSON.parse(code);
      }
    }
  }

  async getPanier() {
    if (isPlatformBrowser(this.platformId)) {
      const userLoc = localStorage.getItem('User');
      const pans = localStorage.getItem('paniers');
      if (userLoc) {
        if (pans) {
          const resp = JSON.parse(pans);
          const dataPanier = new FormData();
          resp.forEach((elt, index) => {
            dataPanier.set(`lignes[${index}].quantite`, elt.quantite);
            dataPanier.set(`lignes[${index}].sous_produit`, elt.id);
          });
          this.httpClient.post(environment.ws_url + 'paniers/local', dataPanier).subscribe((response: any) => {
            localStorage.removeItem('paniers');
            this.getPanier();
          });
        } else {
          this.httpClient.get(environment.ws_url + 'paniers').subscribe((response: any) => {
            this.nbPanier = (response.data).length;
            response.data.forEach(elt => {
              elt['quantiteInitial'] = elt.quantite;
            });
            this.paniers = response.data;
          });
        }
      } else {
        if (pans) {
          const test = JSON.parse(pans);
          test.forEach(elt => {
            if (elt.sous_produit && elt.sous_produit.couleur) {
              elt.sous_produit.couleur.codeHexa = elt.sous_produit.couleur.code_hexa;
            }
          });
          this.paniers = test;
          // this.paniers = JSON.parse(pans);
          this.nbPanier = this.paniers.length;
        } else {
          this.paniers = [];
          this.nbPanier = 0;
        }
      }
    }
  }

  createNewArray(nbChild: number, arrayInit) {
    const arrayRes = [];
    const nbPage = arrayInit.length / nbChild;
    for (let index = 0; index < nbPage; index++ ) {
      arrayRes.push({fils: this.paginate(arrayInit, nbChild, index + 1)});
    }
    return arrayRes;
  }
  paginate(array, pageSize, pageNumber) {
    // human-readable page numbers usually start with 1, so we reduce 1 in the first argument
    return array.slice((pageNumber - 1) * pageSize, pageNumber * pageSize);
  }

  removePanier(ind, idPanierItem?) {
    if (this.user) {
      this.httpClient.delete(environment.ws_url + `paniers/${idPanierItem}`).subscribe((response: any) => {
        this.getPanier();
        this.toastrService.warning('Article supprimé', '', {
          timeOut: 3000
        });
      });
    } else if (isPlatformBrowser(this.platformId)) {
      const paniersString = localStorage.getItem('paniers');
      if(paniersString) {
        let paniers = JSON.parse(paniersString);
        paniers.splice(ind, 1);
        if (paniers.length) {
          localStorage.setItem('paniers', JSON.stringify(paniers));
        } else {
          localStorage.removeItem('paniers');
        }
        this.getPanier();
        this.toastrService.warning('Article supprimé', '', {
          timeOut: 3000
        });
      }
    }
  }

  reinitCodePromo(){
    const code = localStorage.getItem('codepromo');
    if(code){
      localStorage.removeItem('codepromo');
    }

    this.codePromo = {
      value : '',
      isValid : false,
      data : {
        activated: false,
        code: "",
        createdAt: "",
        email: "",
        id: 0,
        min_valeur: 0,
        titre: "",
        type: "",
        updatedAt: "0",
        valeur: 0
      }
    };
  }

  checkCodePromo(total){
    let test = true;
    if(this.codePromo.isValid){
      if(this.codePromo.data.type === 'VALEUR'){
        this.codePromo.isValid = total > this.codePromo.data.min_valeur;
        if(!this.codePromo.isValid){
          test = false;
          // this.toastrService.warning("Le code promo ne s'applique que si l'achat dépasse "+this.codePromo.data.min_valeur+" €");
          this.reinitCodePromo()
        }
      }
    }
    return test;
  }

  openDialog(isValidVar?): void {
    const dialogRef = this.dialog.open(DialogFraisLivraisonDialogComponent, {
      // width: '250px',
      panelClass: 'backdropBackground',
      data: {isValid: isValidVar}
    });

    dialogRef.afterClosed().subscribe(result => {
      // this.animal = result;
    });
  }

  getUser() {
    if (this.user) {
      this.httpClient.get(`${environment.ws_url}users/acheteur/byId`).subscribe((response: any) => {
        if (!this.user.has_frais_livraison) {
          this.user['has_frais_livraison'] = null;
        }
        localStorage.setItem('User', JSON.stringify(this.user));
      });
    }
  }


  getVendeur() {
    if (this.user && (this.user.role === 'VENDEUR' || this.user.role === 'VENDEUR_COMPLET' || this.user.role ==='VENDEUR_VALIDEE')) {
      this.httpClient.get(`${environment.ws_url}users/vendeur/byId`).subscribe((response: any) => {
        this.user['has_frais_livraison'] = response.data.vendeur.has_frais_livraison;
        localStorage.setItem('User', JSON.stringify(this.user));
      });
    }
  }

  getImageFormated(w, h, url){
    return environment.service_format(w, h, url);
  }

  calculPrix(product, nbrProduct){
    let prixFinal = 0;
    let prix1 = (product.prix_promotion && product.prix_promotion > 0) ? product.prix_promotion : product.prix;
    
    if (nbrProduct === 1) {
      prixFinal = prix1;
    } else {
      prixFinal = prix1 * nbrProduct;
      if (nbrProduct === 2 && product.prix2) {
        prixFinal = product.prix2 * nbrProduct;
      } else if (nbrProduct === 3 && product.prix3) {
        prixFinal = product.prix3 * nbrProduct;
      } else if (nbrProduct > 3 && product.prix3) {
        prixFinal = product.prix3 * nbrProduct;
      }
    }
    return prixFinal;
  }
}
