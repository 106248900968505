import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { FormBuilder , FormGroup, Validators, FormControl} from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { CommonService } from 'src/app/services/common.service';
import { Router } from '@angular/router';
import { ImageCroppedEvent } from 'ngx-image-cropper';

declare var $;
declare var Stripe;

@Component({
  selector: 'app-promotion-boutique',
  templateUrl: './promotion-boutique.component.html',
  styleUrls: ['./promotion-boutique.component.scss']
})
export class PromotionBoutiqueComponent implements OnInit {

  page  = 1;
  pageTitle = '';
  sectionForm: FormGroup;
  submitted = false;

  choixOffre  = {id: '1'};
  offresToShow = [];
  typeOffre = '';
  listChoix = [];
  textColors = [];
  styleButtons;

  textColorSelected = {id: '1', hexa: ''};
  phraseColorSelected = {id: '1', hexa: ''};
  styleButtonSelected = { id: 3, nomClasse: 'h-btn-lightgreen' };
  media;

  stripe = Stripe(environment.keys);
  elements;
  card;
  changeCardNumber = false;

  univers = [];
  universSelected;
  sousUniversSelected;
  categorieSelected;

  idVendeur;
  infosPaiements;
  modifierCarte = true;
  showSpinner = false;

  aMettreEnAvant = 'boutique';

  file: File;
  state = 0;
  messageModal = '';
  aspect = 0;

  imageChangedEvent: any = '';
  croppedImage: any = '';

  constructor(
    private httpClient: HttpClient, private fb: FormBuilder, private toastr: ToastrService,
    public commonService: CommonService, private router: Router) {
      this.idVendeur = this.commonService.user.id.userId;
      this.styleButtons = this.commonService.styleButtons;
      this.styleButtonSelected = this.styleButtons[2];
      this.textColors = this.commonService.couleurTexte;
      this.textColorSelected = this.textColors[0];
      this.phraseColorSelected = this.textColors[0];
  }

  ngOnInit(): void {
    if (this.router.url === '/compte/promouvoir-ma-boutique/slider') {
      this.page = 2;
      this.pageTitle = 'Dans le slider de la home page';
      this.typeOffre = 'SLIDER_HOME_PAGE';
      this.aspect = 7 / 2;
      this.initForm();
    } else if (this.router.url === '/compte/promouvoir-ma-boutique/a-la-une') {
      this.page = 3;
      this.pageTitle = 'Dans la section A LA UNE de l’home page';
      this.typeOffre = 'A_LA_UNE_HOME_PAGE';
      this.aspect = 4 / 3;
      this.initForm();
    } else {
      this.page = 1;
      this.aspect = 3 / 3;
    }

    this.getOffres();
    // this.stripeInit();
    this.httpClient.get(environment.ws_url + 'catalogues/by-vendeur').subscribe((data: any) => {
      this.univers = data.data;
      // this.stripeInit();
    }, (error => {}));

    // get infos paiements
    this.httpClient.get(environment.ws_url + 'users/info-paiements').subscribe((data: any) => {
      if (data.data.response && data.data.response.data.length > 0) {
        this.infosPaiements = data.data.response.data[0].card;
        this.modifierCarte = false;
      }
      if (this.modifierCarte) {
        this.stripeInit();
      }

      if (this.page > 1 && this.modifierCarte) {
        this.stripeInit();
      }

    }, (error => { }));

  }

  initForm() {
    this.sectionForm = this.fb.group({
      media : [''],
      texte : ['', [Validators.required,  Validators.maxLength(20)]],
      texteCouleur : [this.textColorSelected, [Validators.required, Validators.maxLength(20)]],
      nomBouton : ['', [Validators.required, Validators.maxLength(12)]],
      styleBouton : [this.styleButtonSelected.id, Validators.required],
      titulaire : [''],
      saveCarte : [false]
    });


    if (this.aMettreEnAvant === 'produit') {
      this.sectionForm.addControl('univers', new FormControl('', Validators.required));
      this.sectionForm.addControl('sousUnivers', new FormControl('', Validators.required));
      this.sectionForm.addControl('categorie', new FormControl('', Validators.required));
    }

    if (this.page === 2) {
      this.sectionForm.addControl('phrase', new FormControl('', [Validators.required, Validators.maxLength(60)]));
      this.sectionForm.addControl('phraseCouleur', new FormControl(this.phraseColorSelected, Validators.required));
      this.sectionForm.addControl('niveauAffichage', new FormControl('CENTER', Validators.required));
    }

    this.media = undefined;
  }

  stripeInit() {
    setTimeout(() => {
      this.elements = this.stripe.elements();

      const styleCss = {
        base: {
          color: '#32325d',
        }
      };

      this.card = this.elements.create('card', { hidePostalCode: true, style: styleCss });
      this.card.mount('#cardElement');

      this.card.addEventListener('change', (event) => {
        this.changeCardNumber = event.complete;
        const displayError = document.getElementById('cardErrors');
        if (event.error) {
          displayError.textContent = event.error.message;
        } else {
          displayError.textContent = '';
        }
      });
    }, 1000);
  }

  changePage(pageNumber) {
    if (pageNumber === 2) {
      this.router.navigate(['/compte/promouvoir-ma-boutique/slider']);
    } else if (pageNumber === 3) {
      this.router.navigate(['/compte/promouvoir-ma-boutique/a-la-une']);
    } else if (pageNumber === 1) {
      this.router.navigate(['/compte/promouvoir-ma-boutique']);
    }
  }

  changeColor(key, color) {
    if (key === 1) {
      this.textColorSelected = color;
      this.sectionForm.get('texteCouleur').setValue(color.id);
    } else if (key === 2) {
      this.phraseColorSelected = color;
      this.sectionForm.get('phraseCouleur').setValue(color.id);
    }
  }

  async getOffres() {
    return await this.httpClient.get(environment.ws_url + 'offre-publicitaires').subscribe((dataMenu: any) => {
      const data = dataMenu.data;
      this.listChoix = data || [];
      this.offresToShow = this.listChoix.filter(choix => choix.type_offre === this.typeOffre);
      this.choixOffre = this.offresToShow[0];
    });
  }

  processPayment(clientSecret) {
    this.stripe.confirmCardPayment(clientSecret,
      {
        payment_method: {
          card: this.card,
          billing_details: {
            name: this.sectionForm.get('titulaire').value
          }
        }
      }
    ).then((result) => {
      if (result.error) {
        this.showSpinner = false;
        this.state = 1;
        this.toastr.error('error payment', '', {
          timeOut: 5000
        });
      } else {
        this.showSpinner = false;
        this.state = 2;
        this.router.navigate(['/compte/promouvoir-ma-boutique']);
        this.toastr.success('Paiement réussi', '', {
          timeOut: 5000
        });
      }
    });

  }

  async onSubmit() {
    this.submitted = true;
    const element = $('.CardNumberField-input-wrapper .InputContainer');
    const errors = $('#cardErrors').text();
    if (this.sectionForm.invalid || errors.length > 0 || (!this.changeCardNumber && this.modifierCarte)) {
      const message = (!this.changeCardNumber) ? 'Veuillez entrer votre numéro de carte' : errors;
      this.toastr.error(message, '', {
        timeOut: 5000
      });
      return;
    } else if (this.media) {
      this.showSpinner = true;
      // this.openModal();
      const link = (this.page === 2) ? 'publicites/slider' : 'publicites/a-la-une';
      const choix = (this.aMettreEnAvant === 'boutique') ? 'BOUTIQUE' : 'PRODUIT';
      const formData = new FormData();
      formData.append('choix', choix);
      formData.append('couleur_texte', this.textColorSelected.id);
      formData.append('texte_acrochage', this.sectionForm.get('texte').value);
      formData.append('nom_bouton', this.sectionForm.get('nomBouton').value);
      formData.append('style_bouton', '' + this.styleButtonSelected.id);
      formData.append('offre_id', this.choixOffre.id);
      formData.append('file', this.file);
      if (this.sectionForm.get('saveCarte').value) {
        formData.append('is_save', 'true');
      }
      if (!this.modifierCarte) {
        formData.append('use_saved', 'true');
      }
      if (choix === 'PRODUIT') {
        formData.append('catalogue.code_categorie', this.sectionForm.get('categorie').value);
        formData.append('catalogue.code_sous_univers', this.sectionForm.get('sousUnivers').value);
        formData.append('catalogue.code_univers', this.sectionForm.get('univers').value);
      }
      if (this.page === 2) {
        formData.append('niveau_affichage', this.sectionForm.get('niveauAffichage').value);
        formData.append('couleur_phrase', this.phraseColorSelected.id);
        formData.append('phrase_acrochage', this.sectionForm.get('phrase').value);
      }
      this.showSpinner = false;
      this.httpClient.post(environment.ws_url + link, formData).subscribe(
        (response: any) => {
        this.messageModal = 'Demande de publicité';
        if (this.modifierCarte) {
          if (response.data.intent_paiement_secret) {
            this.processPayment(response.data.intent_paiement_secret);
          }
        } else {
          this.showSpinner = false;
          if (response.data.paiement_method_id === null) {
            this.router.navigate(['/compte/promouvoir-ma-boutique']);
            this.toastr.success('Paiement réussi', '', { timeOut: 5000 });
          }
        }
        $('#staticBackdrop').modal('hide');
      },
      (error) => {
        this.showSpinner = false;
        $('#staticBackdrop').modal('hide');
      });

    }
  }

  async urlToObject(image) {
    const response = await fetch(image);
    const blob = await response.blob();
    const file = new File([blob], 'image.jpg', {type: blob.type});
    return file;
  }

  chooseMedia() {
    $('#fileinput').trigger('click');
  }

  fileChangeEvent(fileInput: any) {
    this.imageChangedEvent = fileInput;

    const that = this;
    if (fileInput.target.files && fileInput.target.files[0]) {
      const nameFile = fileInput.target.files[0].name;
      this.file = fileInput.target.files[0];
      const reader = new FileReader();

      reader.onload = (e: any) => {
        that.media = { url: e.target.result, name: nameFile, type: fileInput.target.files[0].type.split('/')[0]};
      };
      reader.readAsDataURL(fileInput.target.files[0]);
    }
  }

  get f() {
    return this.sectionForm.controls;
  }

  chooseUnivers(key) {
    switch (key) {
      case 1:
        this.universSelected = this.univers.filter(element => element.code === this.f.univers.value)[0];
        this.sectionForm.get('sousUnivers').reset();
        this.sousUniversSelected = undefined;
        this.categorieSelected = undefined;
        this.sectionForm.get('categorie').reset();
        break;
      case 2:
        this.sousUniversSelected = this.universSelected.sous_univers.filter(element => element.code === this.f.sousUnivers.value)[0];
        this.categorieSelected = undefined;
        this.sectionForm.get('categorie').reset();
        break;
      case 3:
        this.categorieSelected = this.sousUniversSelected.categories.filter(element => element.code === this.f.categorie.value)[0];
        break;
      default:
        break;
    }
  }

  openModal() {
    $('#staticBackdrop').modal('show');
  }

  closeModal(state?) {
    $('#staticBackdrop').modal('hide');
    if (state) {
      this.router.navigate(['/compte/promouvoir-ma-boutique']);
    }
  }





  imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = event.base64;

    this.urltoFile(this.croppedImage, 'img.png', 'image/png').then((file) => {
      this.file = file;
    });
    const nameFile = this.file.name;
    this.file = this.file;
    const reader = new FileReader();
    reader.onload = (e: any) => {
      this.media = { url: e.target.result, name: nameFile, type: this.file.type.split('/')[0]};
    };
    reader.readAsDataURL(this.file);
  }
  imageLoaded() {
      // show cropper
  }
  cropperReady() {
      // cropper ready
  }
  loadImageFailed() {
      // show message
  }

  urltoFile(url, filename, mimeType): any {
    return (fetch(url)
      .then((res) => {
        return res.arrayBuffer();
      })
      .then((buf) => {
        return new File([buf], filename, { type: mimeType });
      })
    );
  }
}


