import { HttpService } from 'src/app/services/http.service';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-liste-by-code',
  templateUrl: './liste-by-code.component.html',
  styleUrls: ['./liste-by-code.component.scss']
})
export class ListeByCodeComponent implements OnInit {

  showSpinner = false;
  codeAchatList;
  myProductsList;
  constructor(private activatedRoute: ActivatedRoute, private httpService: HttpService) { }

  ngOnInit(): void {
    this.codeAchatList = this.activatedRoute.snapshot.paramMap.get('codeAchatList');
    this.getListAchatByCode(this.codeAchatList);
  }

  getListAchatByCode(codeAchatList) {
    this.httpService.get(`achats/code/${codeAchatList}`).subscribe((resp: any) => {
      this.myProductsList = resp.data;
    });
  }
}
