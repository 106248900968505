import { ProductService } from 'src/app/services/product.service';
import { HttpService } from './../../../services/http.service';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-commande',
  templateUrl: './commande.component.html',
  styleUrls: ['./commande.component.scss']
})
export class CommandeComponent implements OnInit, OnDestroy {

  page = 1;
  azaz = false;
  sousDomaine = environment.sousDomaine;

  commandAcheteur = [];
  commandAcheteurByIDPaiment = [];

  newCommande = [];

  commandeAcheteurSubscription: Subscription;

  constructor(
    public productService: ProductService, public router: Router, private activatedRoute: ActivatedRoute,
    private httpService: HttpService) { }

  ngOnInit(): void {
    this.productService.getCommandeAcheteur();

    let pageParam;
    this.activatedRoute.queryParams.subscribe(params => {
      this.page = params.page;
      pageParam = params.page;
    });
    if (pageParam) {
      this.getNewPageCommande();
    } else {
      this.commandeAcheteurSubscription = this.productService.CommandeShopperSubject.subscribe(
        (commande) => {
          this.newCommande = commande;
          const aaa = this.groupBy(this.newCommande, 'id');
          this.newCommande = Object.keys(aaa).map((key) => [Number(key), aaa[key]]);
          this.newCommande.sort((a, b) => b[0] - a[0]);
        }
      );
    }
  }

  // Accepts the array and key
  groupBy(array, key) {
    // Return the end result
    return array.reduce((result, currentValue) => {
      // If an array already present for key, push it to the array. Else create an array and push the object
      (result[currentValue['paiement'][key]] = result[currentValue['paiement'][key]] || []).push(
        currentValue
      );
      // Return the current iteration `result` value, this will be taken as next iteration `result` value and accumulate
      return result;
    }, {}); // empty object is the initial value for result object
  }

  getColor(itemStatus) {
    if (itemStatus.statut_vendeur === 'LIVREE' || itemStatus.statut_vendeur === 'DECLINEE') {
      return 'red';
    } else {
      return '#2b9746';
    }
  }

  //
  onIdCmdVendeur(id: number) {
    this.router.navigate(['/compte', 'mes-commandes', id]);
  }

  onIdCmdAcheteur(id: number) {
    this.router.navigate(['/compte', 'mes-commandes', id]);
  }

  // Commandes
  totalItemCommande(item) {
    let total = 0;
    item.produits.forEach(elt => {
      total += elt.prix;
    });
    return total;
  }

  ngOnDestroy() {
    this.commandeAcheteurSubscription.unsubscribe();
  }


  changePage($event) {
    this.page = $event;

    const linkParams = {};
    linkParams['page'] = this.page;
    this.router.navigate(['.'], { relativeTo: this.activatedRoute, queryParams: linkParams });
    this.getNewPageCommande();
  }

  getNewPageCommande() {
    this.httpService.get(`commandes/acheteur/mes?par_page=12&page=${this.page}`).subscribe((resp: any) => {
      this.newCommande = resp.data.commandes;
      const aaa = this.groupBy(this.newCommande, 'id');
      this.newCommande = Object.keys(aaa).map((key) => [Number(key), aaa[key]]);
      this.newCommande.sort((a, b) => b[0] - a[0]);
    });
  }
}
