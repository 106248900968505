import { Platform } from "@angular/cdk/platform";
import { DOCUMENT } from "@angular/common";
import { HttpClient } from "@angular/common/http";
import {
  Component,
  ElementRef,
  Inject,
  OnInit,
  ViewChild,
} from "@angular/core";
import { Meta, Title } from "@angular/platform-browser";
import { ActivatedRoute, Router } from "@angular/router";
import { environment } from "src/environments/environment";
import { UniverPipePipe } from "./univer-pipe.pipe";
import { findIndex } from "rxjs/operators";

@Component({
  selector: "app-univer-categorie",
  templateUrl: "./univer-categorie.component.html",
  styleUrls: ["./univer-categorie.component.scss"],
})
export class UniverCategorieComponent implements OnInit {
  @ViewChild("descrUniver") myIdentifier: ElementRef;
  univer: any;
  nom_univer: any;
  nom_univerFormated: any;
  nom_sousUniver: any;
  misEnAvant: any = [];
  sousList: any = [];
  isReadMore = false;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private httpClient: HttpClient,
    public platform: Platform,
    @Inject(DOCUMENT) private document: Document,
    private titleService: Title,
    private metaService: Meta
  ) {
  }

  getParts(sentence: string, first: string, last: string): string[] { 
    let goodParts: string[] = [];
    
    const allParts = sentence.split(first);
  
    allParts.forEach((part: string) => {
      if (part.indexOf(last) > -1) {
        const goodOne = (part.split(last))[0];
        goodParts = goodParts.concat(goodOne);
      }
    });
    
    return goodParts;
  }

  replaceText(description: string) {
    const newArray = this.getParts(description, '[', ']');
    let str = description;
    newArray.forEach(element => {
      const childElem = element.split(';')

      if (childElem.length > 2) {
        str = str.replace(`[${element}]`, `<a href='${childElem[1]}' target='${childElem[2]}'>${childElem[0]}</a>`);
      } else {
        str = str.replace(`[${element}]`, `<a href='${childElem[1]}'>${childElem[0]}</a>`);
      }
    });
    return str;
  }

  ngOnInit(): void {
    // this.updateMeta(
    //   'sfdshfjkdhs',
    //   'vcxvxcvxvcxvcxvcxvcx vcxv cxv cxv cxv cx vcx',
    //   'this.univer.photo'
    // );
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    let sousUniverBrute =
      this.activatedRoute.snapshot.paramMap.get("sous_univer");
    let univerBrute = this.activatedRoute.snapshot.paramMap.get("univer");
    if (univerBrute) {
      this.nom_univer = univerBrute.split("_").join(" ");
      this.nom_univerFormated = univerBrute.split(" ").join("_");
      this.httpClient
        .get(`${environment.ws_url}univers?plateforme=HADEEN&has_product=true`)
        .subscribe((response: any) => {
          let univerLoc = response.data.filter(
            (elt) => elt.slug === univerBrute
          );
          if (univerLoc) {
            this.httpClient
              .get(`${environment.ws_url}sous-univers/list?univers_code=${univerLoc[0].code}&plateforme=HADEEN&has_product=true`)
              .subscribe((response2: any) => {
                if (sousUniverBrute) {
                  let sousUniverLoc = response2.data.filter(
                    (elt) => elt.slug === sousUniverBrute
                  );
                  if (sousUniverLoc) {
                    this.univer = sousUniverLoc[0];
                    this.univer.description2 = this.replaceText(this.univer.description);
                    setTimeout(() => {
                      if (
                        this.document.getElementById("descrUniver")
                          .clientHeight > 76
                      ) {
                        this.isReadMore = true;
                      }
                    }, 50);
                    this.updateImage(this.univer.photo);
                    console.log('this.univer: ', this.univer);
                    
                    this.updateMeta(
                      this.univer.nom,
                      this.univer.description,
                      this.univer.photo
                    );
                    this.nom_sousUniver = sousUniverLoc[0].slug;
                    this.getMisEnAvant(sousUniverLoc[0].code);
                    this.httpClient
                      .get(
                        environment.ws_url +
                          `categories/list?sous_univers_code=${sousUniverLoc[0].code}&plateforme=HADEEN&has_product=true`
                      )
                      .subscribe((response3: any) => {
                        this.sousList = response3.data;
                      });
                  }
                } else {
                  this.univer = univerLoc[0];
                  this.univer.description2 = this.replaceText(this.univer.description);
                  this.updateMeta(
                    this.univer.nom,
                    this.univer.description,
                    this.univer.photo
                  );
                  this.updateImage(this.univer.photo);
                  this.sousList = response2.data;
                  this.getMisEnAvant(univerLoc[0].code);
                  setTimeout(() => {
                    if (this.document.getElementById("descrUniver").clientHeight > 20) {
                      this.isReadMore = true;
                    }
                  }, 50);
                }
              });
          }
        });
    }

    if (window.innerWidth < 670) {
      this.isReadMore = true;
    }
  }

  showText() {
    this.isReadMore = !this.isReadMore;
  }

  getMisEnAvant(id: number) {
    const params: URLSearchParams = new URLSearchParams();
    params.set("catalog_code", id.toString());
    this.httpClient
      .get(`${environment.ws_url}catalog-promotions?${params.toString()}`)
      .subscribe((response: any) => {
        this.misEnAvant = response.data.data;
      });
  }

  navigateTo(from: string, item: any) {
    switch (from) {
      case "avant":
        this.router.navigate(["/all-product/en-avant", item.slug]);
        break;
      case "sous":
        let sousUniverBrute =
          this.activatedRoute.snapshot.paramMap.get("sous_univer");
        if (sousUniverBrute) {
          this.router.navigate([
            "/all-product/",
            this.nom_univer,
            this.nom_sousUniver,
            item.slug,
          ]);
        } else {
          this.router.navigate(["/all-product/", this.nom_univer, item.slug]);
        }
        break;
      default:
        break;
    }
  }

  updateImage(image) {
    const head = this.document.getElementsByTagName("head")[0];
    let eltHtml: HTMLLinkElement =
      this.document.querySelector(`link[rel='image_src']`) || null;
    if (eltHtml == null) {
      eltHtml = this.document.createElement("link") as HTMLLinkElement;
      head.appendChild(eltHtml);
    }
    eltHtml.setAttribute("rel", "image_src");
    eltHtml.setAttribute("href", image);
  }

  updateMeta(nom, description, image) {
    this.titleService.setTitle(nom + " | Hadéen-Place | Consommer Responsable |");
    this.metaService.updateTag({ name: "description", content: String(description.trim()) });

    this.metaService.updateTag({ property: "og:title", content: nom + " | Consommer Responsable" });
    this.metaService.updateTag({ property: "og:description", content: String(description.trim()) });
    this.metaService.updateTag({ property: "og:image", content: image });
    this.metaService.updateTag({ property: "og:type", content: "article" });
    this.metaService.updateTag({ property: "og:url", content: this.router.url });
    // if (nom) {
    //   this.titleService.setTitle(nom + " | Hadéen-Place | Consommer Responsable |");
    //   this.meta.updateTag({
    //     property: "og:title",
    //     content: nom + " | Hadéen-Place | Consommer Responsable |",
    //   });
    // }
    // if (description) {
    //   this.meta.updateTag({
    //     name: "description",
    //     content: String(description.trim()),
    //   });
    //   this.meta.updateTag({
    //     property: "og:description",
    //     content: String(description.trim()),
    //   });
    // }
    // if (image) {
    //   this.meta.updateTag({ property: "og:image", content: image });
    // }
    // this.meta.updateTag({ property: "og:type", content: "article" });
    // this.meta.updateTag({ property: "og:url", content: this.router.url });
  }
}
