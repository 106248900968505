import { isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { environment } from 'src/environments/environment';

declare var pintrk;
declare var fbq;
declare var gtag;

@Injectable({
  providedIn: 'root'
})
export class TrackEventService {

  constructor(@Inject(PLATFORM_ID) private platformId,) { }

  fbqTrack(type: string, data?: any) { // type: page_view: PageView, AddToCart, Purchase
    if (isPlatformBrowser(this.platformId)) {
      if (type == 'page_view') { // OK
        fbq('track', 'PageView');
      }
      if (type == 'addtocart') {
        // let _data = {
        //   value: 115.00,
        //   currency: 'EUR'
        // }
        fbq('track', 'AddToCart', data);
      }
      if (type == 'purchase') { // OK
        // let data = {
        //   value: 115.00,
        //   currency: 'EUR',
        //   contents: [
        //     {
        //       id: '301',
        //       quantity: 1
        //     },
        //     {
        //       id: '401',
        //       quantity: 2
        //     }
        //   ],
        //   content_type: 'product'
        // }
        fbq('track', 'Purchase', data);
      }
      removeEventListener
    }
    return
  }

  gtagTrack(type: string, data?: any) {
    // https://developers.google.com/analytics/devguides/collection/gtagjs/events?hl=fr#send-events
    if (isPlatformBrowser(this.platformId)) {
      const TAG_ID = 'AW-479791588/FT4DCMyC1v0YEOST5OQB';
      if (type == 'page_view') {
        gtag('event', 'page_view');
      }
      if (type == 'addtocart') {
        gtag('event', 'add_to_cart', data);
      }
      if (type == 'purchase') {}
      if (type == 'conversion') { // OK
        gtag('event', 'conversion', data);
      }
      removeEventListener
    }
    return
  }

  pintrkTrack(type: string, data?: any) {
    if (isPlatformBrowser(this.platformId)) {
      if (type == 'page_view') { // OK
        pintrk('track', 'pagevisit');
      }
      if (type == 'addtocart') { // OK
        pintrk('track', 'addtocart', data);
      }
      if (type == 'checkout') { // OK
        pintrk('track', 'checkout', data);
        // pintrk('track', 'checkout', {
        //   value: 116,
        //   order_quantity: 2,
        //   currency: 'EUR',
        //   // line_items: [
        //   //   {
        //   //     product_name: 'Pillows (Set of 2)',
        //   //     product_id: '11',
        //   //     product_price: 48.00,
        //   //     product_quantity: 1
        //   //   },
        //   //   {
        //   //     product_name: 'Pillows, Large (Set of 2)',
        //   //     product_id: '15',
        //   //     product_price: 68.00,
        //   //     product_quantity: 1
        //   //   },
        //   // ]
        // });
      }
      removeEventListener
    }
    return
  }
  
}
