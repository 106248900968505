import { ActivatedRoute, Router } from '@angular/router';
import { ProductService } from 'src/app/services/product.service';
import { HttpService } from './../../../services/http.service';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-envies',
  templateUrl: './envies.component.html',
  styleUrls: ['./envies.component.scss']
})
export class EnviesComponent implements OnInit {

  page = 1;
  productsAll;

  constructor(
    private httpService: HttpService, public productService: ProductService, private router: Router,
    private activatedRoute: ActivatedRoute) { }

  ngOnInit(): void {
    this.activatedRoute.queryParams.subscribe(params => {
      if (params.page) {
        this.page = params.page;
      }
    });
    this.getEnvies();
  }

  getEnvies() {
    this.productService.getEnvies(this.page);
  }

  changePage($event) {
    this.page = $event;
    const linkParams = {};
    linkParams['page'] = this.page;
    this.router.navigate(['.'], { relativeTo: this.activatedRoute, queryParams: linkParams });
    this.getEnvies();
  }
}
