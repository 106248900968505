<div class="container pt-5 pb-5">
    <div class="row">
        <div class="col-12 text-center mb-2"><img width="auto" height="auto" src="../../../../assets/images/logo/success@2x.png" alt="" style="width: 50px;"></div>
        <p class="col-12 text-center m-0">Votre commande a été bien enregistrée!</p>
    </div>
    <div class="row">
        <div class="col-12 col-md-6 text-center text-md-right">
            <button mat-flat-button class="h-btn-green" [routerLink]="['/all-product']">CONTINUER MES ACHATS</button>
        </div>
        <div class="col-12 col-md-6 text-center text-md-left">
            <button mat-flat-button class="h-btn-black" [routerLink]="['']">REVENIR A LA PAGE D’ACCUEIL</button>
        </div>
    </div>
</div>

<hr>
<!-- <app-ads></app-ads> -->
