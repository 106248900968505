import { UserService } from './../../../services/user.service';
import { ActivatedRoute } from '@angular/router';
import { AuthService } from 'angularx-social-login';
import { CommonService } from './../../../services/common.service';
import { StorageService } from './../../../services/storage.service';
import { ToastrService } from 'ngx-toastr';
import { HttpClient } from '@angular/common/http';
import { Component, Inject, OnInit } from '@angular/core';
import { environment as env } from 'src/environments/environment';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DecimalPipe } from '@angular/common';

export interface DataModal {
  isSuccess: boolean,
  isAddDefault: boolean,
  maxQuantite: number,
  prix: number
}
export interface Frais {
  minQuantity: number;
  maxQuantity?: number;
  prix: number;
}
@Component({
  selector: 'app-frais-livraison',
  templateUrl: './frais-livraison.component.html',
  styleUrls: ['./frais-livraison.component.scss']
})
export class FraisLivraisonComponent implements OnInit {
  continuer = false;
  isAccept = false;
  isFRaisArticleDefault = false;
  isEdit = false;
  isAvant = true;
  isPret = false;
  isDefaultValid = false;
  showCase = false;
  fraisArticle: Frais[] = [];
  fraisArticleRange: Frais[] = [];
  gratuitPrix = { prix: null, state: false, minQuantity: null, maxQuantity: 1000 };
  gratuitNb = { nb: null, state: false };

  tokenApp;
  typeDevice;

  constructor(private httpClient: HttpClient, private toastr: ToastrService, private authService: AuthService,
    private storageService: StorageService, public dialog: MatDialog, private commonService: CommonService,
    private decimalPipe: DecimalPipe, private activatedRoute: ActivatedRoute, private userService: UserService) {
      this.tokenApp =  this.activatedRoute.snapshot.queryParamMap.get('token');
      this.typeDevice =  this.activatedRoute.snapshot.queryParamMap.get('device');
      if (this.tokenApp && this.tokenApp.length) {
        this.userService.typeDevice = this.typeDevice;
        this.storageService.setItem('Token', this.tokenApp);
        // this.httpClient.get(`${env.ws_url}users/acheteur/byId`, {headers: {Authorization: 'Basic ' + btoa(`${this.typeDevice}:${this.tokenApp}`)}}).subscribe((resp: any) => {
        //   this.storageService.setItem('User', JSON.stringify(resp.data.user));
        //   this.commonService.user = resp.data.user;
        //   this.getVendeur();
        // });
      }
    }

  ngOnInit(): void {
    if (this.tokenApp && this.tokenApp.length) {
      this.storageService.removeItem('Token');
      // this.userService.typeDevice = this.typeDevice;
      this.storageService.setItem('Token', this.tokenApp);
      setTimeout(() => {
        this.httpClient.get(`${env.ws_url}users/acheteur/byId`, {headers: {Authorization: 'Basic ' + this.b2a(`${this.typeDevice}:${this.tokenApp}`)}}).subscribe((resp: any) => {
          this.storageService.setItem('User', JSON.stringify(resp.data.user));
          this.commonService.user = resp.data.user;
          this.getVendeur();
        });
      }, 200);
    } else {
      this.getVendeur();
    }
  }

  b2a(a) {
    var c, d, e, f, g, h, i, j, o, b = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/=', k = 0, l = 0, m = "", n = [];
    if (!a) { return a; }
    do {
      c = a.charCodeAt(k++), d = a.charCodeAt(k++), e = a.charCodeAt(k++), j = c << 16 | d << 8 | e, f = 63 & j >> 18, g = 63 & j >> 12, h = 63 & j >> 6, i = 63 & j, n[l++] = b.charAt(f) + b.charAt(g) + b.charAt(h) + b.charAt(i);
    } while (k < a.length);
    return m = n.join(''), o = a.length % 3, (o ? m.slice(0, o - 3) : m) + '==='.slice(o || 3);
  }

  addFraisArticle() {
    if (!this.fraisArticle.length) {
      this.fraisArticle.push({ minQuantity: 1, prix: null });
    } else {
      if (this.fraisArticle[this.fraisArticle.length - 1].prix) {
        this.fraisArticle.push({ minQuantity: this.fraisArticle.length + 1, prix: null });
      } else {
        this.toastr.warning('Merci de remplir le prix');
      }
    }
    this.editFraisArticleRange();
  }
  deleteFraisArticle(ind) {
    this.fraisArticle.splice(ind, 1);
    this.editFraisArticleRange();
  }

  addFraisArticleRange() {
    if (!this.fraisArticleRange.length) {
      let min = this.fraisArticle.length ? this.fraisArticle.length + 1 : 1;
      this.fraisArticleRange.push({ minQuantity: min, maxQuantity: null, prix: null });
    } else {
      let arrayLength: number = this.fraisArticleRange.length;
      if (this.fraisArticleRange[arrayLength - 1].maxQuantity && this.fraisArticleRange[arrayLength - 1].prix) {
        let newMin = Number(this.fraisArticleRange[arrayLength - 1].maxQuantity) + 1;
        this.fraisArticleRange.push({ minQuantity: newMin, maxQuantity: null, prix: null });
      } else {
        this.toastr.warning('Merci de bien remplir les informations');
      }
    }
    this.addGratuitArticle(true);
    this.addGratuitPrix(true);
  }
  editFraisArticleRange() {
    this.fraisArticleRange.forEach((element, ind) => {
      if (ind === 0) {
        element.minQuantity = this.fraisArticle.length ? this.fraisArticle.length + 1 : 1;
        element.maxQuantity = element.minQuantity < element.maxQuantity - 1 ? element.maxQuantity : element.minQuantity + 2;
      } else {
        element.minQuantity = Number(this.fraisArticleRange[ind - 1].maxQuantity) + 1;
        element.maxQuantity = element.minQuantity < element.maxQuantity - 1 ? this.fraisArticleRange[ind - 1].maxQuantity : element.minQuantity + 2;
      }
    });
    this.addGratuitArticle(true);
  }
  deleteFraisArticleRange(ind) {
    const min = this.fraisArticleRange[ind].minQuantity;
    this.fraisArticleRange.splice(ind, 1);
    if (this.fraisArticleRange.length) {
      this.fraisArticleRange[ind].minQuantity = min;
    }
    this.addGratuitArticle(true);
    this.addGratuitPrix(true);
  }

  addGratuitArticle(isEdit?) {
    if (!isEdit) {
      this.gratuitNb.state = !this.gratuitNb.state;
    }
    if (this.fraisArticleRange.length > 0) {
      this.gratuitNb.nb = Number(this.fraisArticleRange[this.fraisArticleRange.length - 1].maxQuantity) + 1;
    } else if (this.fraisArticle.length > 0) {
      this.gratuitNb.nb = this.fraisArticle[this.fraisArticle.length - 1].minQuantity + 1;
    } else {
      this.gratuitNb.nb = 1;
    }
  }

  addGratuitPrix(isEdit?) {
    if (!isEdit) {
      this.gratuitPrix.state = !this.gratuitPrix.state;
    }
    const aaa = [...this.fraisArticle, ...this.fraisArticleRange];
    if (this.fraisArticleRange.length) {
      this.gratuitPrix.minQuantity = Number(this.fraisArticleRange[this.fraisArticleRange.length - 1].maxQuantity) + 1;
      // this.gratuitPrix.prix = Math.max(...aaa.map(elt => elt.prix)) + 1;
      this.gratuitPrix.prix = null;
    } else if (this.fraisArticle.length) {
      this.gratuitPrix.minQuantity = Number(this.fraisArticle[this.fraisArticle.length - 1].minQuantity) + 1;
      // this.gratuitPrix.prix = Math.max(...aaa.map(elt => elt.prix)) + 1;
      this.gratuitPrix.prix = null;
    } else {
      this.gratuitPrix.prix = null;
    }
  }

  getFraisLivraison() {
    this.httpClient.get(`${env.ws_url}frais-livraisons`).subscribe((resp: any) => {
      if (resp.data && resp.data.length) {
        this.isAvant = true;
        this.isPret = true;
        this.isEdit = true;
      }
      const isFA = resp.data.filter(elt => elt.type === 'PRIX_PRODUIT');
      if (isFA.length) {
        isFA.forEach(element => {
          element.prix = this.decimalPipe.transform(element.prix, '1.2-2');
        });
        this.fraisArticle = isFA;
      }
      const isFAR = resp.data.filter(elt => elt.type === 'PRIX_INTERVALLE');
      if (isFAR.length) {
        isFAR.forEach(element => {
          element.prix = this.decimalPipe.transform(element.prix, '1.2-2');
        });
        this.fraisArticleRange = isFAR;
      }
      const isGA = resp.data.filter(elt => elt.type === 'GRATUIT_MONTANT');
      if (isGA.length) {
        this.gratuitPrix.state = true;
        // this.gratuitPrix.prix = isGA[0].prix;
        this.gratuitPrix.prix = this.decimalPipe.transform(isGA[0].prix, '1.2-2');
      }
      const isGP = resp.data.filter(elt => elt.type === 'GRATUIT_PRODUIT');
      if (isGP.length) {
        this.gratuitNb.state = true;
        this.gratuitNb.nb = isGP[0].minQuantity;
      }
      // if (resp.data.length) {
      //   this.isDefaultValid = true;
      // }
    });
  }

  fraisLivraison() {
    if (!this.fraisArticle.length && !this.fraisArticleRange.length && !this.gratuitNb.state && !this.gratuitPrix.state) {
      this.deleteFraisLivraison(false);
    } else {
      if (this.isEdit) {
        this.deleteFraisLivraison(true);
      } else {
        this.creerFraisLivraison();
      }
    }
  }

  creerFraisLivraison(isEdit?) {
    let maxMax = 0;
    let prixPrix = 0;
    if (this.gratuitNb.state || this.continuer) {
      this.validerFraisLivraison();
    } else {
      if (this.fraisArticle.length) {
        maxMax = this.fraisArticle.length;
        prixPrix = this.fraisArticle[this.fraisArticle.length - 1].prix;
      }
      if (this.fraisArticleRange.length) {
        maxMax = this.fraisArticleRange[this.fraisArticleRange.length - 1].maxQuantity;
        prixPrix = this.fraisArticleRange[this.fraisArticleRange.length - 1].prix;
      }
      this.openDialogValid(false, false, Number(maxMax), prixPrix);
    }


  }

  validerFraisLivraison() {
    const data = new FormData();
    let i = 0;
    // // Prix par produit
    if (this.fraisArticle && this.fraisArticle.length) {
      this.fraisArticle.forEach((item, ind) => {
        data.set(`frais[${i}].frais_livraisons[${ind}].type`, 'PRIX_PRODUIT');
        data.set(`frais[${i}].frais_livraisons[${ind}].prix`, String(item.prix));
        data.set(`frais[${i}].frais_livraisons[${ind}].maxQuantity`, (item.minQuantity).toString());
        // data.set(`frais[${i}].frais_livraisons[${ind}].minQuantity`, (item.minQuantity).toString());
      });
      data.set(`frais[${i}].type`, 'PRIX_PRODUIT');
      i += 1;
    }

    // Prix entre X et Y produit
    if (this.fraisArticleRange && this.fraisArticleRange.length) {
      this.fraisArticleRange.forEach((item, ind) => {
        data.set(`frais[${i}].frais_livraisons[${ind}].type`, 'PRIX_INTERVALLE');
        data.set(`frais[${i}].frais_livraisons[${ind}].maxQuantity`, item.maxQuantity ? (item.maxQuantity).toString() : null);
        data.set(`frais[${i}].frais_livraisons[${ind}].minQuantity`, item.minQuantity ? (item.minQuantity).toString() : null);
        data.set(`frais[${i}].frais_livraisons[${ind}].prix`, String(item.prix));
      });
      data.set(`frais[${i}].type`, 'PRIX_INTERVALLE');
      i += 1;
    }

    // Gratuit a partit de XX produit
    if (this.gratuitNb.state) {
      data.set(`frais[${i}].frais_livraisons[0].type`, 'GRATUIT_PRODUIT');
      data.set(`frais[${i}].frais_livraisons[0].minQuantity`, this.gratuitNb.nb);
      data.set(`frais[${i}].type`, 'GRATUIT_PRODUIT');
      i += 1;
    }

    // Gratuit a partit de XX $
    if (this.gratuitPrix.state) {
      data.set(`frais[${i}].frais_livraisons[0].prix`, this.gratuitPrix.prix);
      data.set(`frais[${i}].frais_livraisons[0].type`, 'GRATUIT_MONTANT');
      // data.set(`frais[${i}].frais_livraisons[0].minQuantity`, this.gratuitPrix.minQuantity ? this.gratuitPrix.minQuantity : null);
      // data.set(`frais[${i}].frais_livraisons[0].maxQuantity`, this.gratuitPrix.maxQuantity ? String(this.gratuitPrix.maxQuantity) : null);
      data.set(`frais[${i}].type`, 'GRATUIT_MONTANT');
    }

    let err = false;
    if (this.fraisArticle.length && !this.fraisArticle[this.fraisArticle.length - 1].prix) {
      err = true;
    }
    // tslint:disable-next-line:max-line-length
    if (this.fraisArticleRange.length && (!this.fraisArticleRange[this.fraisArticleRange.length - 1].maxQuantity || !this.fraisArticleRange[this.fraisArticleRange.length - 1].prix)) {
      err = true;
    }
    if (this.gratuitNb.state && !this.gratuitNb.nb) {
      err = true;
    }
    if (this.gratuitPrix.state && !this.gratuitPrix.prix) {
      err = true;
    }
    if (!err) {
      this.httpClient.post(`${env.ws_url}frais-livraisons`, data).subscribe(
        (resp: any) => {
          this.toastr.success('Mise à jour réussie');
          if (this.isEdit) {
            this.getFraisLivraison();
          } else {
            this.isEdit = !this.isEdit;
          }
          this.getVendeur();
          this.continuer = false;
        }
      );
    } else {
      this.toastr.warning('Merci de vérifié les informations');
    }
  }

  deleteFraisLivraison(isEdit?) {
    this.httpClient.delete(`${env.ws_url}frais-livraisons`).subscribe((resp: any) => {
      if (isEdit) {
        this.creerFraisLivraison(true);
      } else {
        this.deleteFraisLivraison2();
        this.toastr.warning('Frais de livraison pas configuré');
      }
    }, (err) => {
      if (err.error.data === "Vous n'avez pas configuré aucun frais de livraison.") {
        this.creerFraisLivraison(true);
      }
    });
  }

  deleteFraisLivraison2() {
    this.fraisArticle = [];
    this.fraisArticleRange = [];
    this.gratuitNb.state = false;
    this.gratuitPrix.state = false;
  }
  validerDefault() {
    const newUser = this.storageService.getItem('User');
    if (newUser && JSON.parse(newUser).has_frais_livraison > 0) {
      this.httpClient.delete(`${env.ws_url}frais-livraisons`).subscribe(
        (resp: any) => {
          this.defaultFrais();
        }, (err: any) => {
          if (err.error.data === "Vous n'avez pas configuré aucun frais de livraison.") {
            this.defaultFrais();
          }
        }
      );
    } else {
      this.defaultFrais();
    }
  }
  defaultFrais() {
    const data = new FormData();
    // data.set('enable', 'false');
    data.set(`frais[0].frais_livraisons[0].type`, 'GRATUIT_PRODUIT');
    data.set(`frais[0].frais_livraisons[0].minQuantity`, '1');
    data.set(`frais[0].type`, 'GRATUIT_PRODUIT');
    this.httpClient.post(`${env.ws_url}frais-livraisons`, data).subscribe((resp: any) => {
      // this.toastr.success("Votre frais de livraison est configuré gratuit à partir du premier article");
      this.toastr.success("mise à jour reussie");
      this.isAvant = true;
      this.isPret = true;
      // this.getFraisLivraison();
      this.getVendeur();
    });
  }


  openDialogValid(isEnd, isAdd, maxQte, prix2): void {
    const dialogRef = this.dialog.open(DialogFraisDialog, {
      // width: '250px',
      panelClass: 'backdropBackground',
      data: {isSuccess: isEnd, isAddDefault: isAdd, maxQuantite: maxQte, prix: prix2}
    });

    dialogRef.afterClosed().subscribe(result => {
      // this.animal = result;
        if (result) {
            this.addGratuitArticle();
            this.validerFraisLivraison();
        } else {
          this.continuer = true;
        }
    });
  }

  getVendeur() {
    this.httpClient.get(`${env.ws_url}users/vendeur/byId`).subscribe((respVendeur: any) => {
      let newUser = JSON.parse(this.storageService.getItem('User'));
      if (newUser.has_frais_livraison > 0) {
        this.isDefaultValid = true;
      } else {
        this.isDefaultValid = false;
      }
      newUser['has_frais_livraison'] = respVendeur.data.vendeur.has_frais_livraison;
      this.storageService.setItem('User', JSON.stringify(newUser));
      this.getFraisLivraison();
    });
  }

  scroolTo() {
    this.isPret = true;
    // el.scrollIntoView({behavior:"smooth"});
    document.querySelector('#tete').scrollIntoView({ behavior: 'smooth', block: 'center' });
  }
}



@Component({
  selector: 'app-dialog-frais-dialog',
  templateUrl: './dialog-frais-dialog.html',
  // styleUrls: ['./dialog-frais-dialog.scss']
})
export class DialogFraisDialog implements OnInit {

  // constructor() { }
  constructor(
      public dialogRef: MatDialogRef<DialogFraisDialog>,
      @Inject(MAT_DIALOG_DATA) public dataSucc: DataModal) {
      }

  ngOnInit(): void {
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

}
