import { Component, OnInit } from '@angular/core';
declare var gtag: any;
@Component({
  selector: 'app-success',
  templateUrl: './success.component.html',
  styleUrls: ['./success.component.scss']
})
export class SuccessComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
    gtag('event', 'conversion', {
      send_to: 'AW-479791588/T7mdCMD4jvQBEOST5OQB'
    });
  }

}
