import { isPlatformBrowser } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Component, EventEmitter, Inject, Input, OnInit, Output, PLATFORM_ID } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';

import { SearchCountryField, TooltipLabel, CountryISO } from 'ngx-intl-tel-input';
import { ToastrService } from 'ngx-toastr';
import { CommonService } from 'src/app/services/common.service';
import { StorageService } from 'src/app/services/storage.service';
import { environment } from 'src/environments/environment';

declare var Stripe;

@Component({
  selector: 'app-adrs-livraison',
  templateUrl: './adrs-livraison.component.html',
  styleUrls: ['./adrs-livraison.component.scss']
})
export class AdrsLivraisonComponent implements OnInit {
  @Input() from: string = 'page'
  @Output() adrsLivrEvent = new EventEmitter<string>();

  fraisLivraisonTotal = 0;
  paniers = [];
  etape = 1;
  firstFormGroup: FormGroup;
  secondFormGroup: FormGroup;
  selectedValue = true;
  numTel;

  testAdress = '';
  options = {
    componentRestrictions : {
      country: ['FR']
    }
  };

  separateDialCode = true;
  SearchCountryField = SearchCountryField;
  TooltipLabel = TooltipLabel;
  CountryISO = CountryISO;
  preferredCountries: CountryISO[] = [CountryISO.UnitedStates, CountryISO.UnitedKingdom];
  phoneForm = new FormGroup({
    phone: new FormControl(undefined, [Validators.required])
  });

  stripe = Stripe(environment.keys);
  elements;
  card;
  testNum;
  
  constructor(
    @Inject(PLATFORM_ID) private platformId, public commonService: CommonService,
    private formBuilder: FormBuilder, private httpClient: HttpClient, private router: Router,
    private toastr: ToastrService, private storageService: StorageService) { }

  ngOnInit(): void {
    const user = this.storageService.getItem('User');
    let userJson: any;
    if (user) {
      userJson = JSON.parse(user);
    }
    this.firstFormGroup = this.formBuilder.group({
      adresseCtrlPrenom: [userJson.prenom, Validators.required],
      adresseCtrlNom: [userJson.nom, Validators.required],
      adresseCtrlAdress: ['', Validators.required],
      adresseCtrlComplement: ['', ''],
      adresseCtrlCode: ['', Validators.required],
      adresseCtrlVille: ['', Validators.required],
      adresseCtrlNumero: ['', Validators.required]
    });
    this.secondFormGroup = this.formBuilder.group({
      secondCtrl: ['', Validators.required]
    });

    this.getPanier();
  }

  getPanier() {
    this.httpClient.get(environment.ws_url + 'paniers').subscribe((response: any) => {
      this.paniers = response.data;
      this.getFraisDeLivraison();
    });
  }

  public handleAddressChange(address: any) {
    const codePostal = address.address_components.filter(elt => elt.types[0] === 'postal_code');
    const ville = address.address_components.filter(elt => elt.types[0] === 'locality');
    const pays = address.address_components.filter(elt => elt.types[0] === 'country');

    this.firstFormGroup.controls['adresseCtrlAdress'].setValue(address.name);
    this.firstFormGroup.controls['adresseCtrlCode'].setValue(codePostal.length ? codePostal[0].long_name : '');
    this.firstFormGroup.controls['adresseCtrlVille'].setValue(ville.length ? ville[0].long_name : '');
  }

  ngModelChange(event) {
    const adrsDataValue = this.firstFormGroup.value;
    const num = adrsDataValue.adresseCtrlNumero.internationalNumber;
    if (num) {
      this.firstFormGroup.controls['adresseCtrlNumero'].setValue(num);
    }
  }

  setEtape(etape) {
    this.etape = etape;
    if (isPlatformBrowser(this.platformId)) {
      window.scrollTo(0, 0);
    }
  }

  removePanier(ind, idPanierItem?) {
    this.httpClient.delete(environment.ws_url + `paniers/${idPanierItem}`).subscribe((response: any) => {
      this.getPanier();
    });
  }

  getTotalArticle() {
    let total = 0;
    if (this.paniers && this.paniers.length) {
      this.paniers.forEach(elt => {
        total += this.commonService.calculPrix(elt.sous_produit.produit, elt.quantite);
      });
    }
    return total;
  }

  getTotalPromo(){
    let total = 0;
    if(this.commonService.codePromo.isValid){
      const totalPanier = this.getTotalArticle();
      if(this.commonService.codePromo.data.type === 'POURCENTAGE'){
        total = totalPanier * (+this.commonService.codePromo.data.valeur) /100;
      } else if(totalPanier > this.commonService.codePromo.data.min_valeur){
        total = this.commonService.codePromo.data.valeur;
      }
    }
    return total;
  }

  setAdresseLivraison() {
    if (!this.firstFormGroup.invalid) {
      const adresseData = new FormData();
      const adrsDataValue = this.firstFormGroup.value;
      adresseData.set('adresse.adresse', adrsDataValue.adresseCtrlAdress);
      adresseData.set('adresse.complement_adresse', adrsDataValue.adresseCtrlComplement);
      adresseData.set('adresse.code_postal', adrsDataValue.adresseCtrlCode);
      adresseData.set('adresse.nom', adrsDataValue.adresseCtrlNom);
      adresseData.set('adresse.prenom', adrsDataValue.adresseCtrlPrenom);
      adresseData.set('adresse.telephone', (adrsDataValue.adresseCtrlNumero.internationalNumber).split(' ').join(''));
      adresseData.set('adresse.ville', adrsDataValue.adresseCtrlVille);
      this.httpClient.post(environment.ws_url + 'commandes', adresseData).subscribe((resp: any) => {
        if (this.from == 'page') {
          this.router.navigate(['/mon-panier/payment/' + resp.data.paiement_id]);
        } else {
          this.adrsLivrEvent.next(resp.data.paiement_id);
        }
      });
    } else {
      this.toastr.error('Merci de remplir le formulaire', '', {
        timeOut: 3000
      });
    }
  }

  stripeInit() {
    this.elements = this.stripe.elements();

    const styleCss = {
      base: {
        color: '#32325d',
      }
    };

    this.card = this.elements.create('card', { hidePostalCode: true, style: styleCss });
    this.card.mount('#cardElement');

    this.card.addEventListener('change', (event) => {
      const displayError = document.getElementById('cardErrors');
      if (event.error) {
        displayError.textContent = event.error.message;
      } else {
        displayError.textContent = '';
      }
    });
  }

  getFraisDeLivraison() {
    const datadata = new FormData();
    if (this.paniers.length) {
      this.paniers.forEach((element, ind) => {
        datadata.set(`lignes[${ind}].quantite`, element.quantite);
        datadata.set(`lignes[${ind}].sous_produit `, element.sous_produit.id);
      });
    }
    this.httpClient.post(`${environment.ws_url}frais-livraisons/paniers`, datadata).subscribe((resp:any) => {
      // if (resp.data.frais.length) {
      //   resp.data.frais.forEach(element => {
      //     this.fraisLivraisonTotal += element
      //   });
      // }
      this.fraisLivraisonTotal = resp.data.total;
    });
  }

}
