import { GoogleAnalyticsEventsService } from './../../../services/google-analytics-events.service';
import { HttpService } from './../../../services/http.service';
import { HttpClient } from '@angular/common/http';
import { Component, ElementRef, HostListener, Inject, OnInit, PLATFORM_ID, TemplateRef, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbRatingConfig } from '@ng-bootstrap/ng-bootstrap';
import { BsModalRef, BsModalService, ModalDirective } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { CommonService } from 'src/app/services/common.service';
import { WebSocketAPI } from 'src/app/services/WebSocketAPI';
import { environment } from 'src/environments/environment';
import { Meta, Title } from '@angular/platform-browser';
import { DOCUMENT, isPlatformBrowser, ViewportScroller } from '@angular/common';
import { StorageService } from 'src/app/services/storage.service';
import { ModalOpenService } from 'src/app/services/modal-open.service';
import { ChatService } from 'src/app/services/chat.service';
import { FormControl } from '@angular/forms';
import { CarouselComponent } from 'ngx-bootstrap/carousel';
import { TrackEventService } from 'src/app/services/track-event.service';

declare var $;
declare var gtag;
declare var pintrk;
declare var fbq;
declare var $: any;


@Component({
  selector: "app-detail-produit",
  templateUrl: "./detail-produit.component.html",
  styleUrls: ["./detail-produit.component.scss"],
  styles: [
    `
      .star {
        position: relative;
        display: inline-block;
        color: #d3d3d3;
      }
      .full {
        color: #f8b53e;
      }
      .half {
        position: absolute;
        display: inline-block;
        overflow: hidden;
        color: #f8b53e;
      }
    `,
  ],
  providers: [NgbRatingConfig],
})
export class DetailProduitComponent implements OnInit {
  lastIndex = 200;
  counter = 200;
  showTxt = "Voir plus";
  firstCount = 200;

  messageText = "";
  note = 4;
  avis = [];
  selectedIdx;
  nbQuantity = 1;
  itemsPerSlide = 4;
  singleSlideOffset = false;
  noWrap = true;
  slidesChangeMessage: string;
  menuDescriptif = true;
  caract = false;
  livraisonRetour = false;
  avisClient = false;
  modalVendeur: BsModalRef;
  vendeurBool = false;
  selectedIdxActive = 0;
  product;
  productId;
  user;
  sousProduits;
  couleurSelected;
  couleurDispo = [];
  couleurShow = [];
  tailleSelected;
  tailleDispoInit = [];
  tailleDispo = [];
  tailleShow = [];
  imgSousProd = [];
  idSousProduit;
  listSousProduit = [];
  colorSelectedIndex;
  tailleProdByColor;
  tailleByColor = [];
  imgPlle;
  imgListeV2 = [];
  imgListeV3 = [];
  sousProdSelected;
  statutSousProd;
  quantiteV2 = 0;
  productSuggestion = [];
  webSocketAPI: WebSocketAPI;
  messageForSeller;
  achatsListe;
  modalRef: BsModalRef;
  modalRef2: BsModalRef;
  achatActive: any;
  @ViewChild("loginTemplate") loginTemplate2: ElementRef;
  modalRefListValid: BsModalRef;
  favoriteList;
  likeState = "assets/images/heart.svg";
  message = "";
  message2 = "";
  message3 = "";
  moyenne = 0;
  moyenneNon = 0;
  path = "";
  altImage = "";
  selectedIndex = 0;
  step = 3;
  selectedImagePle = 0;

  showFlag = false;
  selectedImageIndex = -1;
  selectedMatTab = new FormControl(0);
  codeOUmodel: any;
  dateEstimation: any;

  prixFinal = 0;
  prixOneProduct = 0;

  currentIndex: number = 0;
  screenWidth = 0;

  pictos = [];

  @HostListener("window:resize", ["$event"])
  onResize(event) {
    this.screenWidth = window.innerWidth;
  }
  @ViewChild("tabGroup") tabGroup: ElementRef;

  constructor(
    @Inject(PLATFORM_ID) private platformId,
    private titleService: Title,
    private metaService: Meta,
    private httpService: HttpService,
    @Inject(DOCUMENT) private document: Document,
    config: NgbRatingConfig,
    private httpClient: HttpClient,
    private modalService: BsModalService,
    private activatedRoute: ActivatedRoute,
    private toastrService: ToastrService,
    public commonService: CommonService,
    private router: Router,
    public modalOpen: ModalOpenService,
    private chatService: ChatService,
    public googleAnalyticsEventsService: GoogleAnalyticsEventsService,
    private storageService: StorageService,
    private viewportScroller: ViewportScroller,
    private el: ElementRef,
    private trackEventService: TrackEventService
  ) {
    config.max = 5;
    config.readonly = true;
    const pageName = this.activatedRoute.snapshot.paramMap.get("id_produit");
    this.productId = Number(pageName.substr(0, pageName.indexOf("-")));
  }

  showLightbox(index) {
    this.selectedImageIndex = index;
    this.showFlag = true;
  }

  closeEventHandler() {
    this.showFlag = false;
    // this.currentIndex = -1;
  }

  indexCarouselModal = 0;

  openModal(template: TemplateRef<any>, id) {
    if (this.screenWidth <= 700) {
      this.indexCarouselModal = id;
      this.modalRef = this.modalService.show(template);
      if (this.modalRef) {
        $(".carousel").carousel();
      }
    }
  }

  closeModal() {
    this.modalRef.hide();
  }

  ngOnInit() {
    this.getPictos(this.productId);
    this.getProductByIdV2(this.productId);
    if (isPlatformBrowser(this.platformId)) {
      this.path = window.location.href;
    }
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;

    const isConnected = this.storageService.getItem("User");
    if (isConnected) {
      this.user = JSON.parse(isConnected);
      if (this.user.sousVendeur) {
        this.getListeAchat();
      }
    }
    this.getBrigade();

    var startDate = new Date();
    this.dateEstimation = new Date(startDate.setDate(startDate.getDate() + 6));
    // var noOfDaysToAdd = 4, count = 0;
    // while(count < noOfDaysToAdd){
    //   this.dateEstimation = new Date(startDate.setDate(startDate.getDate() + 1));
    //   if(this.dateEstimation.getDay() != 0 && this.dateEstimation.getDay() != 6){
    //     //Date.getDay() gives weekday starting from 0(Sunday) to 6(Saturday)
    //     count++;
    //   }
    // }
    setTimeout(() => {
      window.scrollTo({ top: 0, left: 0 });
    }, 2000);
    this.setStep(1);
    this.screenWidth = window.innerWidth;
  }

  @ViewChild("carousel", { static: true }) carousel: CarouselComponent;
  onMouseWheel(event: WheelEvent) {
    if (event.deltaY < 0) {
      // Scroller vers le haut
      this.carouselPrev();
    } else {
      // Scroller vers le bas
      this.carouselNext();
    }
    // Empêcher le défilement de la page entière
    event.preventDefault();
  }

  carouselPrev() {
    this.currentIndex =
      (this.currentIndex - 1 + this.imgListeV3.length) % this.imgListeV3.length;
  }

  carouselNext() {
    this.currentIndex = (this.currentIndex + 1) % this.imgListeV3.length;
  }

  goToDesc(event) {
    this.selectedIndex = 0;
    this.selectedMatTab.setValue(0);
    const nomBlock = "idBlockDescription";
    this.viewportScroller.scrollToAnchor(nomBlock);
  }

  toggleSkil(textDescription) {
    if (this.counter < 101) {
      this.counter = this.product.descriptionTag.length;
      this.showTxt = "Voir moins";
    } else {
      this.counter = this.lastIndex;
      this.showTxt = "Voir plus";
    }
  }

  updateImage(image) {
    const head = this.document.getElementsByTagName("head")[0];
    let eltHtml: HTMLLinkElement =
      this.document.querySelector(`link[rel='image_src']`) || null;
    if (eltHtml == null) {
      eltHtml = this.document.createElement("link") as HTMLLinkElement;
      head.appendChild(eltHtml);
    }
    eltHtml.setAttribute("rel", "image_src");
    eltHtml.setAttribute("href", image);
  }

  updateMeta(nom, description) {
    this.titleService.setTitle(nom + " | Consommer Responsable");
    this.altImage = nom + " | Hadéen-Place | Consommer Responsable |";
    this.metaService.updateTag({
      name: "description",
      content: String(description.trim()),
    });
    this.metaService.updateTag({ property: "og:image", content: this.product.image });
    this.metaService.updateTag({
      property: "og:title",
      content: nom + " | Consommer Responsable",
    });
    this.metaService.updateTag({ property: "og:type", content: "article" });
    this.metaService.updateTag({ property: "og:url", content: this.router.url });
    this.metaService.updateTag({
      property: "og:description",
      content: String(description.trim()),
    });
  }

  // Google analytics
  trackMe() {
    this.googleAnalyticsEventsService.trackAddToCart(this.productId);
    // fbq('track', 'Purchase', {currency: "USD", value: 30.00});
    //  [{'id': 'ABC123', 'quantity': 2}, {'id': 'XYZ789', 'quantity': 2}].
  }

  selectSiz(a) {
    this.selectedIdx = a;
  }

  moins() {
    if (this.nbQuantity === 1) {
      return (this.nbQuantity = 1);
    } else {
      return this.nbQuantity--;
    }
  }

  plus() {
    return this.nbQuantity++;
  }

  seeMenu(i, index) {
    this.selectedIdxActive = index;
    switch (i) {
      case (i = "Descriptif"):
        this.caract = false;
        this.livraisonRetour = false;
        this.avisClient = false;
        this.vendeurBool = false;
        this.menuDescriptif = true;
        break;
      case (i = "Caractéristiques"):
        this.menuDescriptif = false;
        this.livraisonRetour = false;
        this.avisClient = false;
        this.vendeurBool = false;
        this.caract = true;
        break;
      case (i = "Livraison et retour"):
        this.menuDescriptif = false;
        this.caract = false;
        this.avisClient = false;
        this.vendeurBool = false;
        this.livraisonRetour = true;
        break;
      case (i = "Avis"):
        this.menuDescriptif = false;
        this.caract = false;
        this.livraisonRetour = false;
        this.vendeurBool = false;
        this.avisClient = true;
        break;
      case (i = "Le vendeur"):
        this.menuDescriptif = false;
        this.caract = false;
        this.livraisonRetour = false;
        this.avisClient = false;
        this.vendeurBool = true;
        break;
      default:
        break;
    }
  }

  onSlideRangeChange(indexes: number[]): void {
    this.slidesChangeMessage = `Slides have been switched: ${indexes}`;
  }

  // loginModal(template: TemplateRef<any>) {
  loginModal(template) {
    this.modalVendeur = this.modalService.show(
      template,
      Object.assign({ backdrop: "static" })
    );
  }

  setImgPlle(img) {
    this.product.image = img.nom;
  }

  getVendeur(idVendeur) {
    this.httpClient
      .get(environment.ws_url + `users/HADEEN/vendeur/${idVendeur}`)
      .subscribe((response: any) => {
        this.product.vendeur.details = response.data;
        this.note =
          this.product.vendeur.details.avis.length > 0
            ? this.product.vendeur.details.avis[0].star
            : 0;
        return response.data;
      });
  }

  HASHTAG_FORMATTER(description): any {
    // return description.replace(/(^|\s)(#[a-z\d-]+)/ig, (m, g1, g2) => {
    return description.replace(/\#(\S+)/g, (m, g1, g2) => {
      return `<a href="${environment.sousDomaine}/recherche?mot=${g1}" style="color: #707070 !important"> ${m} </a>`;
    });
  }

  // V2
  getProductByIdV2(id) {
    this.httpClient
      .get(environment.ws_url + `produits/HADEEN/${id}`)
      .subscribe((response: any) => {
        this.codeOUmodel = response.data.code
          ? `${response.data.code}`
          : `${response.data.model}`;
        response.data["descriptionTag"] = this.HASHTAG_FORMATTER(
          response.data.description
        );
        this.product = response.data;

        this.updateImage(this.product.image);
        const newTitre = this.product.code
          ? this.product.code
          : this.product.model;
        this.updateMeta(newTitre, this.product.model);

        if (this.commonService.user) {
          const testLike = this.product.likes.filter(
            (elt) => elt.id === this.commonService.user.id.userId
          );
          if (testLike && testLike.length) {
          } else {
          }
        } else {
          this.product.is_liked = false;
        }
        this.sousProduits = this.product.sous;

        if (
          this.commonService.user &&
          this.product.likes.filter(
            (elt) => elt.id === this.commonService.user.id.userId
          ).length
        ) {
          this.product.isLike = true;
          this.likeState = "assets/images/red--heart.svg";
        } else {
          this.product.isLike = false;
          this.likeState = "assets/images/heart.svg";
        }
        this.setColorV2(0, true);
        // this.colorSelectedIndex = this.sousProduits[0];

        this.getVendeur(this.product.vendeur.id);
        this.avis = response.data.feedback_produits;
        this.moyenne = response.data.star;
        this.moyenneNon = response.data.star ? response.data.star : 5;

        this.lastIndex = this.product.descriptionTag
          .substring(0, 100)
          .lastIndexOf(" ");
        if (this.lastIndex > 100) {
          this.lastIndex = 100;
        }
        this.counter = this.lastIndex;
        // setTimeout(() => {
        //   this.viewportScroller.scrollToAnchor('detail_produit');
        // }, 300);
        this.calcPrice(1);
        this.prixOneProduct =
          this.product.prix_promotion && this.product.prix_promotion > 0
            ? this.product.prix_promotion
            : this.product.prix;
        setTimeout(() => {
          $(".carousel").carousel();
        }, 100);
      });
  }

  setColorV2(ind, def?) {
    // console.log("SOUS PRODUIT: ", this.sousProduits);
    if (this.sousProduits[ind].images.length>0) {
      this.setImgPle(this.sousProduits[ind].images[0]);
    }    
    // if (def) {
    //   // this.colorSelectedIndex = this.sousProduits[0];
    //   this.colorSelectedIndex = 0;
    // } else {
    //   this.colorSelectedIndex = ind;
    // }
    this.colorSelectedIndex = ind;
    this.imgListeV2 = this.sousProduits[ind].images;
    this.imgListeV3 = this.createNewArray(3, this.imgListeV2);
    this.imgListeV2.forEach((elt) => {
      elt["image"] = elt.nom;
    });
    if (this.sousProduits[ind].sous_produits) {
      this.tailleProdByColor = this.sousProduits[ind].sous_produits;
      if (this.tailleProdByColor && this.tailleProdByColor[0]?.taille) {
        this.tailleByColor = this.sousProduits[ind].sous_produits;
        if (def) {
          this.setTailleV2(this.tailleByColor[0]);
        } else {
          this.sousProdSelected = "";
        }
      } else {
        this.setTailleV2(this.sousProduits[ind].sous_produits[0]);
      }
    }
  }

  setTailleV2(sousProd) {
    this.sousProdSelected = sousProd;
    this.statutSousProd = sousProd.disponibilite;
    if (this.statutSousProd > 0) {
      this.quantiteV2 = 1;
    } else {
      this.quantiteV2 = 0;
    }
  }

  setImgPle(item, i?) {
    this.imgPlle = item.nom;
    this.selectedImagePle = i ? i : 0;
    this.initializeActiveUkSlider();
  }

  initializeActiveUkSlider() {
    const index = this.selectedImagePle;
    const e = document.getElementsByClassName("carousel");
    setTimeout(() => {
      const tab = Array.from(e);
      for (const el of tab) {
        if (el.classList.contains("uk-active")) {
          el.classList.remove("uk-active");
          if (el.classList.contains("uk-transition-active")) {
            el.classList.remove("uk-transition-active");
          }
        }
      }
      tab[index].classList.add("uk-active");
      tab[index].classList.remove("uk-transition-active");
    }, 1000);
  }

  ajoutPanierV2() {
    const userLoc = this.storageService.getItem("User");
    
    // tslint:disable-next-line:max-line-length
    const priceProduct =
    this.sousProdSelected.produit.prix_promotion &&
    this.sousProdSelected.produit.prix_promotion > 0
    ? this.sousProdSelected.produit.prix_promotion
    : this.sousProdSelected.produit.prix;
    
    let _value = (priceProduct * this.quantiteV2).toFixed(2)

    let _data = {
      value: _value,
      currency: 'EUR'
    }
    
    this.trackEventService.fbqTrack('addtocart', _data);

    this.trackEventService.gtagTrack('addtocart', _data);

    // let _dataGtagAw = {
    //   send_to: 'AW-479791588/FT4DCMyC1v0YEOST5OQB',
    //   value: _value,
    //   currency: 'EUR'
    // }
    // this.trackEventService.gtagTrack('addtocart', _dataGtagAw);
    
    this.trackEventService.pintrkTrack('addtocart', _data);
    
    // this.googleAnalyticsEventsService.trackAddToCart(this.productId);

    // // this.trackMe(); // Google analytics
    // this.googleAnalyticsEventsService.trackAddToCart(this.productId);
    
    // pintrk("track", "addtocart", {
    //   value: priceProduct * this.quantiteV2,
    //   order_quantity: this.quantiteV2,
    //   currency: "EUR",
    // });

    // fbq('track', 'AddToCart', {
    //   contents: [{id: this.sousProdSelected.id, quantity: this.quantiteV2}],
    //   currency: "EUR", value: priceProduct * this.quantiteV2
    // });
    
    let itemIsExist = this.commonService.paniers.filter(elt => elt.sous_produit.id === this.sousProdSelected.id);
    if (itemIsExist.length) {
      if (userLoc) {
        this.updatePanier(itemIsExist[0])
      } else {
        let panierNew = this.commonService.paniers;
        let panierItemSelected = this.commonService.paniers.filter(elt => elt.sous_produit.id === this.sousProdSelected.id)[0];
        if (panierItemSelected.quantite < this.sousProdSelected.disponibilite) {
          if ((panierItemSelected.quantite + this.quantiteV2) <= this.sousProdSelected.disponibilite) {
            panierItemSelected.quantite += this.quantiteV2;
            this.storageService.setItem("paniers", JSON.stringify(panierNew));
            this.commonService.getPanier();
            this.toastrService.success('Produit rajouté au panier', '', {
              timeOut: 3000
            });
          } else {
            this.toastrService.warning(`${this.sousProdSelected.disponibilite - panierItemSelected.quantite} article(s) disponible(s) a ajouter au panier`, "", {
              timeOut: 3000,
            });
          }
        } else {
          this.toastrService.warning(`${this.sousProdSelected.disponibilite} article(s) disponible(s)`, "", {
            timeOut: 3000,
          });
        }
      }
    } else {
      if (userLoc) {
        const dataPanier = new FormData();
        dataPanier.set("quantite", String(this.quantiteV2));
        dataPanier.set("sous_produit_id", this.sousProdSelected.id);
        this.httpClient
          .post(environment.ws_url + "paniers", dataPanier)
          .subscribe((response: any) => {
            this.commonService.getPanier();
            this.toastrService.success("Produit ajouté au panier", "", {
              timeOut: 3000,
            });
          });
      } else {
        const aaa = this.product.sous[this.colorSelectedIndex].images;
        const ee = [];
        aaa.forEach((elt) => {
          ee.push(elt.nom);
        });
        const data = {
          id: this.sousProdSelected.id,
          images: ee,
          liste_achat: null,
          quantite: this.quantiteV2,
          sous_produit: this.product.sous[
            this.colorSelectedIndex
          ].sous_produits.filter((elt) => elt.id === this.sousProdSelected.id)[0],
        };
        const isPanier = this.storageService.getItem("paniers");
        let allPaniers = [];
        if (isPanier) {
          allPaniers = JSON.parse(isPanier);
          allPaniers.push(data);
          this.storageService.setItem("paniers", JSON.stringify(allPaniers));
          this.commonService.getPanier();
          this.toastrService.success("Produit ajouté au panier", "", {
            timeOut: 3000,
          });
        } else {
          this.storageService.setItem("paniers", JSON.stringify([data]));
          this.commonService.getPanier();
          this.toastrService.success("Produit ajouté au panier", "", {
            timeOut: 3000,
          });
        }
      }
    }
  }

  updatePanier(idPanierItem) {
    if (idPanierItem.quantite < this.sousProdSelected.disponibilite) {
      if ((idPanierItem.quantite + this.quantiteV2) <= this.sousProdSelected.disponibilite) {
        const dataPanier = new FormData();
        const qte = idPanierItem.quantite + this.quantiteV2;
        console.log(qte);
        
        dataPanier.set('id', idPanierItem.id);
        dataPanier.set('quantite', '' + qte);
        dataPanier.set('sous_produit_id', idPanierItem.sous_produit.id);
        this.httpClient.put(environment.ws_url + `paniers/${idPanierItem.id}?id=${idPanierItem.id}&quantite=${qte}&sous_produit_id=${idPanierItem.sous_produit.id}`, dataPanier).subscribe((response: any) => {
          this.commonService.getPanier();
          this.toastrService.success('Produit rajouté au panier', '', {
            timeOut: 3000
          });
        });
      } else {
        this.toastrService.warning(`${this.sousProdSelected.disponibilite - idPanierItem.quantite} article(s) disponible(s) a ajouter au panier`, "", {
          timeOut: 3000,
        });
      }
    } else {
      this.toastrService.warning(`${this.sousProdSelected.disponibilite} article(s) disponible(s)`, "", {
        timeOut: 3000,
      });
    }
  }

  crementQuantite(crement) {
    if (crement > 0) {
      if (this.quantiteV2 < this.statutSousProd) {
        this.quantiteV2++;
      }
    } else {
      if (this.quantiteV2 - 1 > 0) {
        this.quantiteV2--;
      }
    }
    this.calcPrice(this.quantiteV2); //calcul du prix total final
  }

  getBrigade() {
    this.httpService
      .get(`produits/brigade/HADEEN/${this.productId}`)
      .subscribe((resp: any) => {
        this.productSuggestion = resp.data.produits;
      });
  }

  sendMessage() {
    const message = {
      receiver: this.product.vendeur.id,
      objet: `Produit: ${this.product.model} ${this.product.id.produitId}`,
      message: this.messageForSeller,
    };
    this.messageForSeller = "";
    this.chatService.sendMessage(message, this.loginTemplate2);
  }

  openLogin() {
    this.modalOpen.loginModal(this.loginTemplate2);
  }

  getListeAchat() {
    this.httpService.get("achats").subscribe((resp: any) => {
      resp.data.forEach((elt, ind) => {
        if (ind === 0) {
          elt.state = true;
          this.achatActive = elt.id;
        } else {
          elt.state = false;
        }
      });
      if (resp.data && resp.data.length) {
        this.achatsListe = resp.data.filter(
          (elt) => elt.status !== "SUPPRIMEE"
        );
      } else {
        this.achatsListe = [];
      }
    });
  }

  selectList(items, ind) {
    items.forEach((elt, index) => {
      elt.state = false;
      if (ind === index) {
        elt.state = true;
        this.achatActive = elt.id;
      }
    });
  }

  ajouterAuListe(id) {
    if (this.sousProdSelected) {
      const formData = new FormData();
      formData.set("liste_achat_id", id);
      formData.set("sous_produit_id", this.sousProdSelected.id);
      formData.set("quantite", String(this.quantiteV2));
      this.httpService.post("achats/add", formData).subscribe((resp: any) => {
        this.toastrService.success("Produit ajouté a la liste", "", {
          timeOut: 3000,
        });
      });
    } else {
      this.toastrService.error(
        "Merci de selectionner votre sous produits",
        "Attention",
        {
          timeOut: 3000,
        }
      );
    }
  }

  validerListe(id, template: TemplateRef<any>) {
    this.ajouterAuListe(id);
    const formData = new FormData();
    formData.set("id", id);
    const user = this.commonService.user;
    this.user = user;

    this.message2 = "Bonjour,\n";
    this.message2 += "«" + this.user.pseudo + "» t’invite à consulter :\n";
    this.achatsListe.forEach((elt) => {
      this.message2 += "- " + elt.title + ":" + "code " + elt.code + "\n";
    });
    // tslint:disable-next-line: max-line-length
    this.message2 += `\n N’hésitez pas à vous rendre sur www.hadeen-place.fr ou télécharger l’application mobile, vous connecter et vous rendre dans la rubrique Mon compte /Consulter une liste d’achat et insérer le code reçu.

    À très vite sur hadeen-place.fr`;

    this.message = encodeURIComponent(this.message2);
    this.modalRefListValid = this.modalService.show(template, {
      keyboard: false,
    });
  }

  toUnicode(word) {
    let array = word.split("");
    array = array.map((character) => {
      if (character.match(/[^a-zA-Z]/g)) {
        const conversion = "000" + character.charCodeAt(0).toString(16);
        return "\\u" + conversion;
      }
      return character;
    });
    return array.join("");
  }

  copierMessage() {
    this.message = encodeURIComponent(this.message2);
    $("#exampleFormControlTextarea1").select();
    $("#exampleFormControlTextarea1").setSelectionRange(0, 99999);
    document.execCommand("copy");
  }

  onchangeInput() {
    this.message = encodeURIComponent(this.message2);
  }

  likeProduct() {
    const user = JSON.parse(localStorage.getItem("User"));
    if (user) {
      let path = "";
      const isLiked = this.product.isLike;
      if (isLiked) {
        path = "/app/unlike";
        this.product.isLike = false;
        this.likeState = "assets/images/heart.svg";
      } else {
        path = "/app/like";
        this.product.isLike = true;
        this.likeState = "assets/images/red--heart.svg";
      }
      const data = {
        produit: this.product.id.produitId,
      };
      this.modalOpen.likeProd(path, data);
    } else {
      this.openLogin();
    }
  }

  removeLastComma(str) {
    const lastChar = str.slice(-1);
    if (lastChar === ",") {
      str = str.trim().slice(0, -1);
    }
    const tab = str.trim().split(",");
    return tab.join(", ");
  }

  getMoyenne() {
    let total = 0;
    this.product.feedback_produits.forEach((elt: any) => {
      total += elt.star;
    });

    return total / this.product.feedback_produits.length;
  }

  openModalContactDisponibilite() {
    $("#modalDisponibilite").modal("toggle");
  }
  openModalContact(event: Event) {
    event.preventDefault();
    if (this.commonService.user) {
      $("#modalContactModerateur2").modal("toggle");
    } else {
      this.openLogin();
    }
  }

  formatUrl(url) {
    url = url.trim().split("&").join("___");
    return url.trim().split(" ").join("_");
  }

  scrollToElement(totalAvis): void {
    if (totalAvis > 0) {
      this.selectedIndex = 2;
      this.step = 3;
      setTimeout(() => {
        const id = window.innerWidth < 500 ? "avismobile" : "avis";
        const elt = document.getElementById(id);
        if (elt) {
          elt.scrollIntoView({
            behavior: "smooth",
            block: "end",
            inline: "nearest",
          });
        }
      }, 100);
    }
  }

  setStep(indice) {
    this.step = indice;
    this.expanded = false;
  }

  createNewArray(nbChild: number, arrayInit) {
    const arrayRes = [];
    const nbPage = arrayInit.length / nbChild;
    for (let index = 0; index < nbPage; index++) {
      arrayRes.push({ fils: this.paginate(arrayInit, nbChild, index + 1) });
    }
    return arrayRes;
  }

  paginate(array, pageSize, pageNumber) {
    // human-readable page numbers usually start with 1, so we reduce 1 in the first argument
    return array.slice((pageNumber - 1) * pageSize, pageNumber * pageSize);
  }

  encodeLien(newString) {
    return newString.split(" ").join("_");
  }

  // @ViewChild('col8Element', { static: true }) col8Element: ElementRef;

  @HostListener("window:scroll", ["$event"])
  onWindowScroll(event: Event = null) {
    const scrollY = window.scrollY;
  }
  panelOpenState = false;

  activeBox: number = 1;
  selectedBox(qte) {
    this.calcPrice(qte);
  }

  getPictos(id) {
    this.httpClient
      .get(environment.ws_url + `pictos/HADEEN/${id}`)
      .subscribe((response: any) => {
        this.pictos = response.data;
      });
  }

  showDesceriptif(title: string) {
    let word = title.split(" ");
    let show = "";
    if (word.length == 1) {
      return title.substring(0, 100);
    } else {
      for (let i = 0; i < word.length; i++) {
        if ((show + word[i]).length <= 100) {
          show += word[i] + " ";
        } else {
          break;
        }
      }
      let lastChar = show.trim().slice(-1);
      return lastChar === "-" || lastChar === "," || lastChar === ";"
        ? show.trim().slice(0, -1)
        : show;
    }
  }

  calcPrice(nbrProduct) {
    this.prixFinal = this.commonService.calculPrix(this.product, nbrProduct);
    this.quantiteV2 = nbrProduct;
    this.activeBox = nbrProduct;
  }
  expanded: boolean = false;
  positionPanel = 0;
  readMore(event: Event) {
    event.preventDefault();
    this.expanded = !this.expanded;
    if (!this.expanded) {
      window.scrollTo({ top: this.positionPanel, left: 0, behavior: "auto" });
    }
    this.positionPanel = window.scrollY;
  }
}
