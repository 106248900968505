import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class HttpService {
  httpOptions: any;
  options: any;
  token: any;
  constructor(
    private http: HttpClient) {
  }

  // HttpClient API get() method => Fetch employee
  get(url: string, param?): Observable<any> {
    return this.http.get<any>(environment.ws_url + url, param)
      .pipe(
        retry(0),
        // catchError(this.handleError)
      );
  }

  // HttpClient API post() method
  post(url: string, parametre: any): Observable<any> {
    return this.http.post(environment.ws_url + url, parametre)
      .pipe(
        retry(0),
        catchError(this.handleError)
      );
  }

  // HttpClient API put() method
  put(url: string, parametre: any): Observable<any> {
    return this.http.put(environment.ws_url + url, JSON.stringify(parametre))
      .pipe(
        retry(0),
        catchError(this.handleError)
      );
  }

  // HttpClient API delete() method
  delete(url: string) {
    return this.http.delete(environment.ws_url + url)
      .pipe(
        retry(0),
        catchError(this.handleError)
      );
  }

  // Error handling
  handleError(error) {
    // let errorMessage = '';
    // let errorJson = {};
    // if (error.error instanceof ErrorEvent) {
    //   // Get client-side error
    //   errorMessage = error.error.message;
    //   errorJson = error.error;
    // } else {
    //   // Get server-side error
    //   errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    //   errorJson = {error: true, message: error.message, status: error.status};
    // }
    return throwError(error);
    // return throwError(errorMessage);
  }
}
