<div class="container example-button-container" *ngIf="scrollTopView">
  <button
    style="z-index: 2;position: fixed; bottom: 50px; right: 30px; background-color: #42594F; border-radius: 50% !important;"
    (click)="scrollTo()" mat-mini-fab color="accent" aria-label="Example icon button with a plus one icon">
    <mat-icon>arrow_upward</mat-icon>
  </button>
</div>

<div style="background-color: #F6FAF0;" class="text-center d-flex justify-content-center">
  <img class="img_gratuit" src="../../../assets/images/appli_gratuite.webp" alt="appli gratuite"
    style="position: relative;width: 30rem;top: 70px;height: auto;">
  <div style="position: relative;top: 135px;left: 0;" class="div_gratuit">
    <a href="https://play.google.com/store/apps/details?id=com.place.hadeen">
      <img class="img_store" width="auto" height="auto" loading="eager" src="../../../assets/images/btn_googlePlayV2.png" alt="google play"
        title="google play" style="width: 120px; margin-bottom: 15px;border-radius: 25px;border: solid 1px #dbd8d8;">
    </a><br>
    <a href="https://apps.apple.com/fr/app/id1531299991">
      <img class="img_store" width="auto" height="auto" loading="eager" src="../../../assets/images/btn_appStoreV2.png" alt="appstore"
        title="appstore" style="width: 120px;border-radius: 25px;border: solid 1px #dbd8d8;">
    </a>
  </div>
</div>
<div class="newsletter-containt">
  <div class="container" style="padding-top: 2%;">
    <div class="row justify-content-center">
      <div class="col-lg-4 col-md-4 col-sm-12 mb-4 mb-md-2 text-center">
        <p class="newsletter"
          style="margin-top: 4px; font-size: 24px; border-right: 1px solid #ffffff; font-family: 'Din Bold'; color: #000;">
          Je veux tout savoir</p>
      </div>
      <div class="col-lg-6 col-md-6 col-12 input-newsletter">
        <div class="row" style="background-color: white; ">
          <div class="newsletter-input col-10">
            <input class="text-center" type="email" name="email" id="email" placeholder="Entrez votre adresse mail"
              [(ngModel)]="email">
          </div>
          <a class="col-1" (click)="inscrireToNewsLetter()">OK</a>
        </div>
      </div>
    </div>
  </div>
</div>
<app-aboutus></app-aboutus>
<div style="height: 58px;background-color: white;" class="d-md-none d-block" *ngIf="!scrollTopView"></div>
