import { HttpService } from './../../services/http.service';
import { ChatService } from './../../services/chat.service';
import { HttpClient } from '@angular/common/http';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { CommonService } from 'src/app/services/common.service';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';
import { StorageService } from 'src/app/services/storage.service';
import { FormBuilder, Validators } from '@angular/forms';
import { MatStepper } from '@angular/material/stepper';

import { STEPPER_GLOBAL_OPTIONS } from '@angular/cdk/stepper';

@Component({
  selector: 'app-panier',
  templateUrl: './panier.component.html',
  styleUrls: ['./panier.component.scss'],
  providers: [
    {
      provide: STEPPER_GLOBAL_OPTIONS,
      useValue: {displayDefaultIndicatorType: false},
    },
  ],
})
export class PanierComponent implements OnInit {
  @ViewChild('loginTemplate') loginTemplate: ElementRef;
  @ViewChild('stepper') private myStepper: MatStepper;
  
  nbQuantity = 1;
  user;
  paniers;
  isInvalidShop = true;
  addCodePromo = false;
  totalPromo = 0;
  fraisLivraisonTotal = 0;

  panierFormGroup = this._formBuilder.group({
    panierValid: [false, Validators.requiredTrue],
  });

  adrsFormGroup = this._formBuilder.group({
    p_i: ['', Validators.required],
  });
  isUser = false;

  constructor(
    private httpClient: HttpClient, private toastrService: ToastrService, public commonService: CommonService,
    public chatService: ChatService, private router: Router, private storageService: StorageService, private httpService :HttpService,
    private _formBuilder: FormBuilder) { }

  moins(itemPanier) {
    if (itemPanier.quantite === 1) {
      itemPanier.quantite = 1;
      this.updatePanier(itemPanier);
    } else {
      itemPanier.quantite --;
      this.updatePanier(itemPanier);
    }
  }

  plus(itemPanier) {
    if (itemPanier.quantite < itemPanier.sous_produit.disponibilite) {
      itemPanier.quantite ++;
      this.updatePanier(itemPanier);
    } else {
      this.toastrService.warning(itemPanier.sous_produit.disponibilite + ' article(s) disponible(s)', '', {
        timeOut: 1000
      });
    }
  }

  ngOnInit(): void {
    const isConneted = this.storageService.getItem('User');
    if (isConneted) {
      this.isUser = true;
      this.user = JSON.parse(isConneted);
      this.getPanier()
    } else {
      const respLoc = JSON.parse(this.storageService.getItem('paniers'));
      if (respLoc) {
        respLoc.forEach(elt => {
          elt['slug'] = elt.sous_produit.produit.slug;
          if (elt.sous_produit.couleur) {
            elt.sous_produit.couleur.codeHexa = elt.sous_produit.couleur.code_hexa;
          }
        });
        this.paniers = respLoc;
        this.getFraisDeLivraison();
        this.isInvalidShop = this.paniers.every(a => a.sous_produit.disponibilite < 1);
      }
      if(this.paniers.length<1){
        this.commonService.reinitCodePromo();
      }
    }
  }

  getPanier() {
    this.commonService.getPanier();
    setTimeout(() => {
      const panierCom = this.commonService.paniers;
      panierCom.forEach(eltP => {
        eltP['slug'] = eltP.sous_produit.produit.slug;
        if (eltP.quantite > eltP.sous_produit.disponibilite) {
          eltP.quantite = eltP.sous_produit.disponibilite;
        }
      });
      this.paniers = panierCom;
      this.getFraisDeLivraison();
      this.isInvalidShop = this.paniers.every(a => a.sous_produit.disponibilite < 1);
      if (this.paniers.length > 0) {
        this.panierFormGroup.controls['panierValid'].setValue(true);
      }
    }, 500);
  }

  removePanier(ind, idPanierItem?) {
    if (this.user) {
      this.httpClient.delete(environment.ws_url + `paniers/${idPanierItem}`).subscribe((response: any) => {
        this.toastrService.warning('Article supprimé', '', {
          timeOut: 3000
        });
        this.commonService.getPanier();
        setTimeout(() => {
          this.paniers = this.commonService.paniers;
          if (this.paniers.length == 0) {
            this.isInvalidShop = true;
            this.panierFormGroup.controls['panierValid'].setValue(false);
            this.adrsFormGroup.controls['p_i'].setValue('');
          }
          this.getFraisDeLivraison();
        }, 600);
        this.checkCodePromo();
      });
    } else {
      this.paniers.splice(ind, 1);
      if (this.paniers.length) {
        this.storageService.setItem('paniers', JSON.stringify(this.paniers));
      } else {
        this.storageService.removeItem('paniers');
      }
      this.commonService.getPanier();
      this.paniers = this.commonService.paniers;
      this.checkCodePromo();
      this.getFraisDeLivraison();
    }
  }

  updatePanier(idPanierItem) {
    if(this.commonService.user){
      const dataPanier = new FormData();
      dataPanier.set('id', idPanierItem.id);
      dataPanier.set('quantite', ''+idPanierItem.quantite);
      dataPanier.set('sous_produit_id', idPanierItem.sous_produit.id);
      this.httpClient.put(environment.ws_url + `paniers/${idPanierItem.id}?id=${idPanierItem.id}&quantite=${idPanierItem.quantite}&sous_produit_id=${idPanierItem.sous_produit.id}`, dataPanier).subscribe((response: any) => {
        this.toastrService.success('Produit modifié', '', {
          timeOut: 3000
        });
        this.checkCodePromo();
        this.getFraisDeLivraison();
        this.adrsFormGroup.controls['p_i'].setValue('');
      });
    } else{
      this.storageService.setItem('paniers',JSON.stringify(this.paniers));
      this.checkCodePromo();
      this.getFraisDeLivraison();
    }
  }

  checkCodePromo(){
    if(this.commonService.codePromo.isValid){
      if(this.commonService.codePromo.data.type === 'VALEUR'){
        const total = this.getTotalArticle();
        this.commonService.codePromo.isValid = total > this.commonService.codePromo.data.min_valeur;
        if(!this.commonService.codePromo.isValid){
          this.toastrService.warning("Le code promo ne s'applique que si l'achat dépasse "+this.commonService.codePromo.data.min_valeur+" €");
          this.commonService.reinitCodePromo()
        }
      } else if(this.paniers.length<1){
        this.commonService.reinitCodePromo();
      }
    }
  }

  getTotalArticle() {
    let total = 0;
    if (this.paniers && this.paniers.length) {
      this.paniers.forEach(elt => {
        total += this.commonService.calculPrix(elt.sous_produit.produit, elt.quantite);
      });
    }
    return total;
  }

  getTotalPromo(){
    let total = 0;
    if(this.commonService.codePromo.isValid){
      const totalPanier = this.getTotalArticle();
      if(this.commonService.codePromo.data.type === 'POURCENTAGE'){
        total = totalPanier * (+this.commonService.codePromo.data.valeur) /100;
      } else if(totalPanier > this.commonService.codePromo.data.min_valeur){
        total = this.commonService.codePromo.data.valeur;
      }
    }
    return total;
  }

  validateCodePromo(){
    const code = encodeURIComponent(this.commonService.codePromo.value);
    const url = environment.ws_url + `code-promos/validate?code=${code}`;
    this.httpClient.get(url).subscribe(response => {
      if(response && response['data'].activated){

        if(response['data'].type === "POURCENTAGE"){
          this.commonService.codePromo.isValid = true;
        } else{
          const total = this.getTotalArticle();

          this.commonService.codePromo.isValid = total > response['data'].min_valeur;
          if(!this.commonService.codePromo.isValid){
            this.toastrService.warning("Ce code ne s'applique que si l'achat dépasse "+response['data'].min_valeur+" €");
          }
        }

        if(this.commonService.codePromo.isValid){
          this.commonService.codePromo['data'] = response['data'];
          localStorage.setItem('codepromo',JSON.stringify(this.commonService.codePromo));
          this.toastrService.success("Code promo validé avec succès");
        }else{
          this.commonService.reinitCodePromo();
        }

      } else{
        this.commonService.reinitCodePromo();
        this.toastrService.error("Votre code promo n'est pas valide");
      }
    },error => {
      this.commonService.reinitCodePromo();
      this.toastrService.error('Code promo invalide ou inexistant');
    })

    this.addCodePromo = false;

  }

  openlogin() {
    this.chatService.loginModal(this.loginTemplate);
  }

  getFraisDeLivraison() {
    const datadata = new FormData();
    if (this.paniers.length) {
      this.paniers.forEach((element, ind) => {
        datadata.set(`lignes[${ind}].quantite`, element.quantite);
        datadata.set(`lignes[${ind}].sous_produit `, element.sous_produit.id);
      });
      this.httpClient.post(`${environment.ws_url}frais-livraisons/paniers`, datadata).subscribe((resp:any) => {
        this.fraisLivraisonTotal = resp.data.total;
      });
    }
  }
  
  goToAdrs() {
    this.getPanier()
    if (this.paniers.length > 0) {
      this.myStepper.next();
      this.scroolToTop();
    }
  }

  adrsLivrEvent($event) {
    this.adrsFormGroup.controls['p_i'].setValue($event);
    this.myStepper.next();
    this.scroolToTop2();
  }

  scroolToTop() {
    document.querySelector('#topPanier0').scrollIntoView({ behavior: 'smooth', block: 'start' });
  }
  scroolToTop2() {
    document.querySelector('#topPanier1').scrollIntoView({ behavior: 'smooth', block: 'start' });
  }

  resetStepFormValidation($event) {
    if ($event.selectedIndex < $event.previouslySelectedIndex) {
      this.adrsFormGroup.controls['p_i'].setValue('');
    }
  }
}
