import { SmodinApiService } from './../../../../services/smodin-api.service';
import { Component, OnInit, Output, Input, EventEmitter, TemplateRef, ChangeDetectorRef, ViewChildren, QueryList, Inject, PLATFORM_ID } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { ActivatedRoute } from '@angular/router';
import { MatExpansionPanel } from '@angular/material/expansion';
import { isPlatformBrowser } from '@angular/common';

@Component({
  selector: "app-step1",
  templateUrl: "./step1.component.html",
  styleUrls: ["./step1.component.scss"],
})
export class Step1Component implements OnInit {
  formGroupe: FormGroup;
  modalRef: BsModalRef;
  menusShow = [];
  univerSelected: any = {};
  sousUniverSelected: any = {};
  categorieSelected: any = {};
  step = 1;
  showSpinner = false;
  showError = false;

  listAffiche = [];
  listImages = [];
  productEdit;

  @Input() formData;

  @Output() nextStep: EventEmitter<any> = new EventEmitter();
  @ViewChildren(MatExpansionPanel)
  matExpansionPanelQueryList: QueryList<MatExpansionPanel>;
  constructor(
    @Inject(PLATFORM_ID) private platformId,
    private formBuilder: FormBuilder,
    private modalService: BsModalService,
    private httpClient: HttpClient,
    private activatedRoute: ActivatedRoute,
    private cdRef: ChangeDetectorRef,
    private smodin: SmodinApiService
  ) {}

  ngOnInit(): void {
    if (isPlatformBrowser(this.platformId)) {
      window.scrollTo(0, 0);
    }
    this.formGroupe = this.formBuilder.group({
      model: [
        "",
        [
          Validators.required,
          Validators.minLength(50),
          Validators.maxLength(170),
        ],
      ],
      // nomProduit: ['', Validators.required],
      reference: [
        "",
        [
          Validators.required,
          Validators.minLength(35),
          Validators.maxLength(70),
        ],
      ],
      description: ["", Validators.required],
      univer: ["", Validators.required],
      sousUniver: ["", Validators.required],
      categorie: ["", Validators.required],
    });
    this.productEdit = this.activatedRoute.snapshot.paramMap.get("idProduit");
    if (this.productEdit) {
      this.httpClient
        .get(environment.ws_url + `produits/${"HADEEN"}/${this.productEdit}`)
        .subscribe((response: any) => {
          this.formGroupe.controls["model"].setValue(response.data.model);
          this.formGroupe.controls["reference"].setValue(response.data.code);
          this.formGroupe.controls["description"].setValue(
            response.data.description
          );
          this.formGroupe.controls["univer"].setValue(
            response.data.univers.nom
          );
          this.formGroupe.controls["sousUniver"].setValue(
            response.data.sous_univers.nom
          );
          this.formGroupe.controls["categorie"].setValue(
            response.data.categorie.nom
          );
          this.univerSelected = response.data.univers;
          this.sousUniverSelected = response.data.sous_univers;
          this.categorieSelected = response.data.categorie;
          // this.useSmodin = response.data.ilaysmodin;
        });
    }

    if (this.formData && this.formData[1]) {
      const groupe = this.formData[1];
      this.formGroupe.patchValue(groupe.formGroupe.value);
      this.categorieSelected = groupe.categorie;
    }
    this.getUnivers();

    // const dataMenu = {
    //   data: {
    //     categorie: {
    //       code: '30201',
    //       has_color: true,
    //       has_taille: true,
    //       nom: 'Top',
    //       photo: 'https://hadeen.nyhoaviko.com:9090/uploads/defaut.png',
    //       slug: '30201-top',
    //       translation: {
    //         traduction1: '',
    //         traduction2: '',
    //         traduction3: '',
    //         traduction4: '',
    //       }
    //     },
    //     code: 'gfd',
    //     createdAt: null,
    //     dateFin: null,
    //     description: 'fhgf',
    //     devise: null,
    //     id: {
    //       produitId: 901
    //     },
    //     plateforme: {
    //       commision: 12,
    //       email: 'fanabned@gmail.com',
    //       has_marque: 0,
    //       moderateur_id: 14,
    //       name: 'HADEEN PLACE',
    //       plateforme: 'HADEEN',
    //       tva: 20
    //     },
    //     image: null,
    //     model: 'aaa',
    //     priorite: null,
    //     prix: null,
    //     prix_promotion: null,
    //     promoted_at: null,
    //     slug: '901-aaa',
    //     staked_at: null,
    //     star: null,
    //     statut: null,
    //     symbol: null,
    //     updatedAt: null
    //   }
    // };
    // this.nextStep.emit(dataMenu.data);
  }

  afficherConseil() {}

  openModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template);
  }

  closeModal() {
    this.modalRef.hide();
  }

  txtRewrite: string = "";
  rewriteLoading: boolean = false;
  useSmodin = "0";
  rewriteText(template: TemplateRef<any>) {
    this.rewriteLoading = true;
    this.smodin
      .postRewrite(this.formGroupe.controls["description"].value)
      .subscribe((resp: any) => {
        this.txtRewrite = resp.rewrite;
        this.rewriteLoading = false;
        this.modalRef = this.modalService.show(template);
      });
  }
  validateRewrite() {
    if (this.txtRewrite.trim() != "") {
      this.formGroupe.controls["description"].setValue(this.txtRewrite);
      this.useSmodin = "1";
    }
    this.closeModal();
  }

  formSubmitted = false;
  async validerStep() {
    this.formSubmitted = true;
    if (!this.formGroupe.invalid && !this.showSpinner) {
      this.showSpinner = true;
      this.showError = false;
      const valuesForm = this.formGroupe.value;
      const formData = new FormData();
      formData.set("model", valuesForm.model);
      formData.set("description", valuesForm.description);
      formData.set("code", valuesForm.reference);
      // formData.set('code', this.univerSelected['code']);
      formData.set("categorie_code", this.categorieSelected["code"]);
      // formData.set('sous_categorie_code', this.sousUniverSelected['code']);
      formData.set("plateforme", "HADEEN");
      formData.set("is_smodin", this.useSmodin);

      const form1 = {
        formGroupe: this.formGroupe,
        univer: this.univerSelected,
        sousuniver: this.sousUniverSelected,
        categorie: this.categorieSelected,
      };
      if (!this.productEdit) {
        await this.httpClient
          .post(environment.ws_url + "produits/step-one", formData)
          .subscribe((dataMenu: any) => {
            if (dataMenu.data) {
              dataMenu.data.categorie = this.categorieSelected;
              dataMenu.data.formGroupe = this.formGroupe;
            }
            this.nextStep.emit(dataMenu.data);
          });
      } else {
        // const body = new URLSearchParams();
        // body.set('model', valuesForm.model);
        // body.set('description', valuesForm.description);
        // body.set('plateforme', 'HADEEN');
        // tslint:disable-next-line: max-line-length
        // await this.httpClient.put(environment.ws_url + 'produits/HADEEN/' + this.productEdit + '?' + body.toString(), body).subscribe((dataMenu: any) => {
        //   if (dataMenu.data) {
        //     dataMenu.data.categorie = this.categorieSelected;
        //     dataMenu.data.formGroupe = this.formGroupe;
        //     // dataMenu.data.univer = this.univerSelected;
        //     // dataMenu.data.sousuniver= this.sousUniverSelected;
        //   }
        //   this.nextStep.emit(dataMenu.data);
        // });

        const body = new FormData();
        body.set("model", valuesForm.model);
        body.set("description", valuesForm.description);
        body.set("code", valuesForm.reference);
        body.set("plateforme", "HADEEN");
        // tslint:disable-next-line: max-line-length
        this.httpClient
          .post(
            `${environment.ws_url}produits/HADEEN/${this.productEdit}`,
            body
          )
          .subscribe((dataMenu: any) => {
            if (dataMenu.data) {
              dataMenu.data.categorie = this.categorieSelected;
              dataMenu.data.formGroupe = this.formGroupe;
            }
            this.nextStep.emit(dataMenu.data);
          });
      }
    } else {
      this.showError = true;
    }
  }

  getUnivers() {
    this.httpClient
      .get(environment.ws_url + "arborescences")
      .subscribe((dataMenu: any) => {
        this.menusShow = dataMenu.data;

        this.menusShow.forEach((elt) => {
          elt.children = elt.sous_univers;

          elt.children.forEach((eltCategorie) => {
            eltCategorie.children = eltCategorie.categories;
          });
        });
      });
  }

  clickUniver(step?) {
    step = step || this.step;
    this.listAffiche = [];

    switch (step) {
      case 1:
        this.listAffiche = [...this.menusShow];
        this.step = 1;
        break;
      case 2:
        // tslint:disable-next-line: no-string-literal
        if (this.univerSelected["code"]) {
          // tslint:disable-next-line: no-string-literal
          this.listAffiche = [...this.univerSelected["children"]];
          this.step = 2;
        } else {
          this.listAffiche = [...this.menusShow];
          this.step = 1;
        }
        break;
      case 3:
        if (this.univerSelected["code"]) {
          // tslint:disable-next-line: no-string-literal
          this.listAffiche = [...this.univerSelected["children"]];
          this.step = 2;
        } else {
          this.listAffiche = [...this.menusShow];
          this.step = 1;
        }
        break;
    }

    if (!this.listAffiche.length) {
      this.closeModal();
    }
  }

  selectItem(item) {
    switch (this.step) {
      case 1:
        this.univerSelected = item;
        this.sousUniverSelected = {};
        this.categorieSelected = {};
        this.formGroupe.patchValue({
          univer: item.nom,
          sousUniver: "",
          categorie: "",
        });
        this.clickUniver(2);
        break;
      case 2:
        this.sousUniverSelected = item;
        this.categorieSelected = {};
        this.formGroupe.patchValue({
          sousUniver: item.nom,
          categorie: "",
        });
        this.clickUniver(3);
        break;
      case 3:
        this.categorieSelected = item;
        this.formGroupe.patchValue({
          categorie: item.nom,
        });
        this.closeModal();
        break;
    }
    this.listAffiche.forEach((elt) => {
      elt.step = false;
    });
  }

  clickCategorie(item, sous) {
    this.categorieSelected = sous;
    this.sousUniverSelected = item;
    this.formGroupe.patchValue({
      sousUniver: item.nom,
      categorie: sous.nom,
    });
    this.closeModal();
  }

  setStep(row) {
    // row.step = !row.step;
    this.cdRef.detectChanges();
  }
}
