import { HttpService } from 'src/app/services/http.service';
import { ChatService } from './../../services/chat.service';
import { Component, ElementRef, Inject, Input, OnInit, PLATFORM_ID, TemplateRef, ViewChild } from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { FormControl, FormGroup } from '@angular/forms';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import User from '../../interfaces/user';
import { AuthService } from 'angularx-social-login';
import { ProductService } from 'src/app/services/product.service';
import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { MatDrawer } from '@angular/material/sidenav';
import { MenuListService } from 'src/app/services/menu-list.service';
import { CommonService } from 'src/app/services/common.service';
import { WebSocketAPI } from 'src/app/services/WebSocketAPI';
import { isPlatformBrowser } from '@angular/common';
import { StorageService } from 'src/app/services/storage.service';

declare var $;

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  @Input() type: any;
  textElastic;
  searchFormGroup: FormGroup = new FormGroup({
    searchkeyword: new FormControl('')
  });
  searchSuggestions = [];
  @ViewChild('loginTemplate') loginTemplate: ElementRef;
  isCollapse = true;
  textSearch;
  showHeaders = true;
  menuList: any;
  menuWS = [];
  modalRef2: BsModalRef;
  modalMobilMenu: BsModalRef;
  modalCategorie: BsModalRef;
  name = '';
  signe = 'password';
  faLogin = 'fa-eye';
  example = '';
  accountClass = '';
  newClass = 'activModal';
  MselectSexe = 'selectedSexe';
  FselectSexe = 'unselectedSexe';
  newsCustomer: boolean;
  votreEmail;
  mdp;
  sexe = 'HOMME';
  formRegister: FormGroup;
  newsL = true;
  currentUserSubject: BehaviorSubject<any>;
  currentUser: any;
  sousMenuWS: any;
  sousMenuWS2: any;
  sousMenuBool = false;
  menuHover = false;
  menuMobil = true;
  titreMobile: string;
  codeCategorie: any;
  activeMenu = '';
  menusShow;
  activeBorder = '';
  selectedIdx: number;
  sousUniverMenuWS: any[];
  sousMenuSUniv: any;
  titreSousUniv: any;
  titreUniver: any;
  drawer: MatDrawer;
  messageText;

  public menuSubject: Observable<any> = of([]);
  public nameSubject: Observable<any> = of([]);
  showSpinner = false;

  webSocketAPI: WebSocketAPI;
  router2;
  constructor(
    @Inject(PLATFORM_ID) private platformId,
    public chatService: ChatService,
    private modalService: BsModalService,
    private httpService: HttpService,
    private toastr: ToastrService,
    private authService: AuthService,
    public commonService: CommonService,
    private storageService: StorageService,
    public productS: ProductService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    public breakpointObserver: BreakpointObserver,
    public MenuListServ: MenuListService
  ) {
    this.textElastic = this.activatedRoute.snapshot.queryParams['mot'];
    if (isPlatformBrowser(this.platformId)) {
      this.router2 = router;
      this.router2.events.subscribe((val) => {
        if (val instanceof NavigationEnd) {
          if (val.url.includes('livraison') || val.url.includes('payment')) {
            this.showHeaders = false;
          } else {
            this.showHeaders = true;
          }
        }
      });

      this.getMenuWS();
      this.commonService.getPanier();
      this.nameSubject = this.MenuListServ.getName();
      this.nameSubject.subscribe((data: any) => {
        this.name = data;
      });
      this.menuSubject = this.MenuListServ.getMenu();
      this.menuSubject.subscribe((data: any) => {
        this.menuList = data;
      });
      this.currentUserSubject = new BehaviorSubject<any>(storageService.getItem('Token'));
      if (this.currentUserSubject.getValue() !== null) {
        const a = JSON.parse(this.storageService.getItem('User'));
        this.name = !a.prenom ? a.prenom : a.pseudo;
        this.MenuListServ.setMenuList();
      } else {
        this.MenuListServ.setMenuList();
        this.name = ', Identifiez-vous';
      }
      this.currentUser = this.currentUserSubject.asObservable();
    }
  }

  public get currentUserValue(): User {
    return this.currentUserSubject.value;
  }

  logout() {
    this.storageService.removeItem('Token');
    this.storageService.removeItem('User');
    this.storageService.removeItem('Details');
    this.storageService.removeItem('Vendeur');
    this.storageService.removeItem('Boutique');
    this.storageService.removeItem('tokenCompany');
    this.storageService.removeItem('etape1');

    this.commonService.getPanier();
    this.commonService.reinitCodePromo();
    this.commonService.user = null;
    this.router.navigate(['']);
    this.currentUserSubject.next(null);
    this.MenuListServ.setMenuList();
    this.MenuListServ.setName([ ', Identifiez-vous']);
    this.authService.signOut().then((resp: any) => {
      this.toastr.warning('à Bientot', '', {
        timeOut: 1000
      });
    });
  }

  loginModal(template: TemplateRef<any>) {
    this.modalRef2 = this.modalService.show(
      template,
      Object.assign({}, { class: 'login' }),
      // Object.assign({ backdrop: 'static' }),
    );
  }

  newsCustomerBtn() {
    if (this.newsCustomer === false) {
      this.newsCustomer = true;
      this.newClass = 'activModal';
      this.accountClass = '';
    } else if (this.newsCustomer === true) {
      this.newsCustomer = false;
      this.newClass = '';
      this.accountClass = 'activModal';
    }
  }
  public goTo(a, loginTemplate?) {
    if (a.includes('Votre compte')) {
      this.router.navigate(['/compte']);
    } else if (a.includes('nouveau client')) {
      this.MenuListServ.isNewCustomer = true;
      this.loginModal(loginTemplate);
      this.newsCustomer = true;
      this.newsCustomerBtn();
    } else if (a.includes('déjà un compte')) {
      this.MenuListServ.isNewCustomer = false;
      this.loginModal(loginTemplate);
      this.newsCustomer = false;
      this.newsCustomerBtn();
    } else if (a.includes('déconnecter')) {
      this.logout();
    } else if (a.includes('contact')) {
      if (this.commonService.user) {
        $('#modalContactModerateur2').modal('toggle');
      } else {
        this.loginModal(loginTemplate);
      }
    } else if (a.includes('reset-mdp')) {
      this.modalRef2.hide();
      this.router.navigate(['/reset-mdp']);
    } else if (a.includes('Devenir Vendeur')) {
      const ab = this.storageService.getItem('Token');
      if (ab !== null) {
        this.router.navigate(['/devenir-vendeur']);
      } else {
        this.router.navigate(['login-devenir-vendeur']);
      }
    } else if (a.includes('Ajouter un produit')) {
      const aaa = JSON.parse(this.storageService.getItem('User'));
      if (aaa.etapeVendeur === 3 && aaa.role === 'VENDEUR_VALIDEE') {
        this.router.navigate(['/ajout-prod']);
      } else {
        this.httpService.get(`users/acheteur/byId`).subscribe(
          resp => {
            this.storageService.setItem('User', JSON.stringify(resp.data.user));
            setTimeout(() => {
              this.router.navigate(['/ajout-prod']);
            }, 200);
          },
          err => {
            this.openModal();
          }
        );
      }
    } else if (a.includes('Vos messages')) {
      this.router.navigate(['/compte/mes-messages']);
    } else if (a.includes('favoris')) {
      if (this.commonService.user) {
        this.router.navigate(['/compte/favoris']);
      } else {
        this.loginModal(loginTemplate);
        this.newsCustomer = false;
        this.newsCustomerBtn();
      }
    } else if (a.includes('commandes')) {
      this.router.navigate(['/compte/mes-commandes']);
    } else if (a.includes('Boutique')) {
      this.router.navigate(['/compte/ma-boutique']);
    }
  }

  getMenuWS() {
    this.productS.getUniv(true).subscribe((data: any) => {
      // this.menuWS = data.data;
      this.menuWS = this.moveArrayItemToNewIndex(data.data, 0, 4);
    });
  }

  moveArrayItemToNewIndex(arr, oldIndex, newIndex) {
    if (newIndex >= arr.length) {
        let k = newIndex - arr.length + 1;
        while (k--) {
            arr.push(undefined);
        }
    }
    arr.splice(newIndex, 0, arr.splice(oldIndex, 1)[0]);
    return arr;
  }

  seeSousUniv(a) {
    this.titreSousUniv = a.nom;
    this.productS.getcategorie(a.code, true).subscribe((data: any) => {
      this.sousMenuSUniv = data.data;
      this.sousMenuSUniv.forEach(element => element.categorie = a);
    });
  }

  seeMenu(a, i) {
    if (this.menuHover === true) {
      this.titreUniver = a.nom;
      this.productS.getSousUniv(a.code).subscribe((data: any) => {
        this.sousMenuWS = data.data;
        this.sousMenuBool = true;
        this.selectedIdx = i;
        switch (i) {
          case i = 0:
            return this.activeMenu = 'activDropG activDrop';
          case i = 1:
            return this.activeMenu = 'activDropG activDrop';
          case i = 2:
            return this.activeMenu = 'activDropG activDrop';
          case i = 3:
            return this.activeMenu = 'activDropG activDrop';
          case i = 4:
            return this.activeMenu = 'activDropC activDrop';
          case i = 5:
            return this.activeMenu = 'activDropC activDrop';
          case i = 6:
            return this.activeMenu = 'activDropD activDrop';
          case i = 7:
            return this.activeMenu = 'activDropD activDrop';
          case i = 8:
            return this.activeMenu = 'activDropD activDrop';
        }
      });
    }
  }

  menuM(a, template: TemplateRef<any>, c, x?) {
    if (c === 'enfants') {
      if (x) {
        this.titreUniver = a.slug;
      }
      this.titreMobile = a.nom;
      this.productS.getSousUniv(a.code, true).subscribe((data: any) => {
        this.sousMenuWS = data.data;
        this.modalMobilMenu = this.modalService.show(template,
          Object.assign({}, { class: 'burgermenu' }));
      });
    } else if (c === 'categorie') {
      if (!x) {
        this.titreSousUniv = a.slug;
      }
      this.titreMobile = a.nom;
      this.codeCategorie = a.univers_code;
      this.productS.getcategorie(a.code, true).subscribe((data: any) => {
        this.sousMenuWS2 = data.data;
      });

    }
  }

  leaveM() {
    this.sousMenuBool = false;
    this.activeMenu = '';
    this.selectedIdx = null;
    this.sousMenuSUniv = [];
    this.titreSousUniv = '';
    this.titreUniver = '';
  }

  openToggle(a) {
    if (a === 'Open') {
      this.example = 'example-container';
    } else {
      this.example = 'sidenavClose';
    }
  }

  ngOnInit(): void {
    // this.httpService.get('arborescences').subscribe((dataMenu: any) => {
    //   this.menusShow = dataMenu.data;

    //   this.menusShow.forEach(elt => {
    //     elt.children = elt.sous_univers;

    //     elt.children.forEach(eltCategorie => {
    //       eltCategorie.children = eltCategorie.categories;
    //     });
    //   });

    // });

    this.breakpointObserver
      .observe(['(min-width: 767px)'])
      .subscribe((state: BreakpointState) => {
        if (state.matches) {
          this.menuHover = true;
        }
      });
    const user = this.storageService.getItem('User');
    if (user) {

      this.chatService.testConnectionSOcket();
    }

  }

  changeQuery(item) {
    this.router.navigate([`/all-product/${item.categorie.nom}/${item.nom}`]);
  }
  retourMenu(drawer) {
    if (this.sousMenuWS2) {
      this.sousMenuWS2 = null;
    } else {
      // this.openToggle('Open');
      this.modalMobilMenu.hide();
      drawer.open();
      this.openToggle('Open');
    }
  }

  // NEW
  removePanier(idItem,ind?) {
    this.commonService.removePanier(ind, idItem);
    // this.commonService.removePanier('1', idItem);
  }

  openModal() {
    $('#staticBackdropInvalidAccount').modal('show');
  }

  closeModal(state) {
    $('#staticBackdropInvalidAccount').modal('hide');
    if (state) {
      this.router.navigate(['/' + state]);
    }
  }

  sendMessage() {
    const idModerateur = 14;
    const messageToModerateur = {
      receiver: idModerateur,
      objet: 'Hadeen',
      message: this.messageText
    };
    this.chatService.sendMessage(messageToModerateur);
  }

  searchElastic(e, mobil?) {
    const linkParams = {};
    let resultSearch = '';
    if (mobil) {
      resultSearch = this.textSearch || this.searchFormGroup.value;
    } else {
      const valueForm = this.searchFormGroup.value;
      resultSearch = valueForm.searchkeyword;
    }
    linkParams['mot'] = resultSearch;
    this.router.navigate(['/recherche'], { relativeTo: this.activatedRoute, queryParams: linkParams });
    setTimeout(() => {
      let activeElement = <HTMLElement>document.activeElement;
      activeElement && activeElement.blur && activeElement.blur();
    }, 1000);
  }

  getTotalArticle() {
    let totalPrice = 0;
    let totalQte = 0;
    if (this.commonService.paniers && this.commonService.paniers.length) {
      this.commonService.paniers.forEach(elt => {
        totalPrice += this.commonService.calculPrix(elt.sous_produit.produit, elt.quantite);
        totalQte += elt.quantite;
      });
    }
    return {totalPrice, totalQte};
  }

}
