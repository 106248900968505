<div class="row">
  <div class="col-12 mb-0">
    <div class="header">
      <ul class="breadcrumb mb-0">
        <li [routerLink]="['/compte']"><a>Compte</a></li>
        <li class="active"><a>Mes messages</a></li>
      </ul>
    </div>
  </div>
  <div class="col-12 mb-4">
    <h1 class="text-center">MES MESSAGES</h1>
  </div>

  <div class="col-12">
    <mat-paginator [pageSizeOptions]="[10]" showFirstLastButtons></mat-paginator>

    <div style="border: 1px solid #d9d9d9;">

      <table mat-table [dataSource]="chatService.dataSource" class="w-100">

        <!-- Position Column -->
        <ng-container matColumnDef="position">
          <th mat-header-cell *matHeaderCellDef style="width: 200px;">À</th>
          <td mat-cell *matCellDef="let element">
            <img width="auto" height="auto" *ngIf="(user.id.userId !== element.receiver.id) && !element.receiver.imageProfil"
              src="../../../assets/images/logo/profilRounded.png" alt="" style="border-radius: 50%; width: 30px;">
            <img width="auto" height="auto" *ngIf="(user.id.userId !== element.receiver.id) && element.receiver.imageProfil"
              [src]="element.receiver.imageProfil" alt="" style="border-radius: 50%; width: 30px;">
            <span *ngIf="user.id.userId !== element.receiver.id"> {{element.receiver.pseudo}}</span>

            <img width="auto" height="auto" *ngIf="(user.id.userId !== element.sender.id) && !element.sender.imageProfil"
              src="../../../assets/images/logo/profilRounded.png" alt="" style="border-radius: 50%; width: 30px;">
            <img width="auto" height="auto" *ngIf="(user.id.userId !== element.sender.id) && element.sender.imageProfil"
              [src]="element.sender.imageProfil" alt="" style="border-radius: 50%; width: 30px;">
            <span *ngIf="user.id.userId !== element.sender.id"> {{element.sender.pseudo}}</span>

            <span *ngIf="user.id.userId === element.receiver.id && user.id.userId === element.sender.id">
              {{element.receiver.pseudo}}</span>
          </td>
        </ng-container>

        <!-- Name Column -->
        <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef>Message</th>
          <td mat-cell *matCellDef="let element">
            <span *ngIf="element.messages"
              [ngClass]="{'text-bold': element.lu === 0 && element.messages[element.messages.length-1].is_sent === false}">
              {{element.objet}} : {{removeHTMLTags(element.messages[element.messages.length-1].message)}}
            </span>
          </td>
        </ng-container>

        <!-- Symbol Column -->
        <ng-container matColumnDef="symbol">
          <th mat-header-cell *matHeaderCellDef style="width: 120px;">Date</th>
          <td mat-cell *matCellDef="let element">
            <div class="row pr-3">
              <div class="ml-auto mr-3"><img width="auto" height="auto" src="../../../assets/images/logo/strombone.png" alt=""
                  *ngIf="element.messages && element.messages[element.messages.length-1].files && element.messages[element.messages.length-1].files.length>0">
              </div>
              <div style="min-width: 40px;"
                *ngIf="element.messages && !testDate(element.messages[element.messages.length-1].createdAt|date)">

                <small>
                  <!-- {{diffDate(element.messages[element.messages.length-1].createdAt)}} <br> -->
                  {{element.messages[element.messages.length-1].createdAt|date:'hh:mm'}}
                </small>
              </div>
              <div style="min-width: 40px;"
                *ngIf="element.messages && testDate(element.messages[element.messages.length-1].createdAt|date)">
                <small>{{element.messages[element.messages.length-1].createdAt|date:'dd/MM/yy'}}</small>
              </div>
            </div>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;" (click)="selectDiscussion(row)"
          style="cursor:pointer;"></tr>
      </table>

    </div>
  </div>
</div>
