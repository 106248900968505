import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SmodinApiService {

  constructor(private http: HttpClient) { }

  postRewrite(description: any, validation: string = '0') {
    // const headers = {
    //   'Content-Type': 'application/json',
    //   'X-RapidAPI-Key': environment.apiKeySmodin,
    //   'X-RapidAPI-Host': environment.apiHostSmodin
    // };
    let formData = new FormData();
    formData.set('lang', 'fr');
    formData.set('length', 'THREE');
    formData.set('text', description);    
    return this.http.post(environment.ws_url+'smodin/rewrite', formData);
  }
}
