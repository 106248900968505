import { Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { environment } from 'src/environments/environment';
import { ToastrService } from 'ngx-toastr';
import { AuthServices } from 'src/app/services/auth.service';
import { UserService } from 'src/app/services/user.service';
import { BehaviorSubject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { isPlatformBrowser } from '@angular/common';

import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { SearchCountryField, TooltipLabel, CountryISO } from 'ngx-intl-tel-input';
import { MenuListService } from 'src/app/services/menu-list.service';
import { CommonService } from 'src/app/services/common.service';
import { HttpService } from 'src/app/services/http.service';
import { Meta, Title } from '@angular/platform-browser';
import { StorageService } from 'src/app/services/storage.service';
import { DialogFraisLivraisonDialogComponent } from '../../common/dialog-frais-livraison-dialog/dialog-frais-livraison-dialog.component';
import { MatDialog } from '@angular/material/dialog';

declare var Stripe;
declare var $;

@Component({
  selector: 'app-devenirvendeur',
  templateUrl: './devenirvendeur.component.html',
  styleUrls: ['./devenirvendeur.component.scss']
})
export class DevenirvendeurComponent implements OnInit {
  logo: any;
  logoFile: any;
  logoPdf;
  file1;
  file2;
  file3;
  steDetailLoc;

  seeImage = false;
  imgLinkProfile: any;
  nexStep = false;
  kbis1 = false;
  kbis2 = false;
  kbis3 = false;
  // stripe = Stripe(environment.keys);
  stripe = Stripe(environment.keys, {apiVersion: '2020-03-02'});
  elements: any;
  siret: any;
  nom: any;
  tva: any;
  adresse: any;
  villeSte: any;
  postCodeSte: any;
  paysSte: any;
  mail: any;
  description: any;
  condition: any;
  IbanDir: any;
  prenomDir: any;
  nomDir: any;
  fonctionDir: any;
  partDir: any;
  adresseDir: any;
  codePostalDir: any;
  villeDir: any;
  paysDir: any;
  user: any;
  imgPath1: string | ArrayBuffer;
  imgSend1: File;
  formData: any;
  imgPath2: string | ArrayBuffer;
  imgSend2: File;
  imgSend3: File;
  imgPath3: string | ArrayBuffer;
  tokenFile1;
  tokenFile2;
  tokenFile3;
  tokenFileValid = false;
  imagetoken11: any;
  test1 = false;
  test2 = false;
  test3 = false;
  numTel: any;
  numTel1;
  dataStep2 = new FormData();
  dataStep3 = new FormData();
  imagetoken12: any;
  imagetoken21: any;
  datefinal: any;
  tokenPerson: any;
  token1: any;
  numsoc: any;
  tokenAccount: any;
  dataUp = new FormData();
  userSubject: BehaviorSubject<any>;
  etapeVendeur: any;
  documentPerson;
  documentSociete;


  // new
  imagePath;
  imgURL;
  showSpinner = false;

  separateDialCode = true;
  SearchCountryField = SearchCountryField;
  TooltipLabel = TooltipLabel;
  CountryISO = CountryISO;
  preferredCountries: CountryISO[] = [CountryISO.UnitedStates, CountryISO.UnitedKingdom];
  phoneForm = new FormGroup({
    phone: new FormControl(undefined, [Validators.required])
  });


  // v3
  userConnected;
  userData;

  societeLogoState = false;
  societeLogoFile;
  societeLogoPath;
  societeFormGroup: FormGroup = new FormGroup({
    societeCtrlNom: new FormControl('', Validators.required),
    societeCtrlSiret: new FormControl('', Validators.required),
    societeCtrlTva: new FormControl('', Validators.required),
    societeCtrlAdresse: new FormControl('', Validators.required),
    societeCtrlCode: new FormControl('', Validators.required),
    societeCtrlVille: new FormControl('', Validators.required),
    societeCtrlPays: new FormControl('', Validators.required),
    societeCtrlEmail: new FormControl('', Validators.required),
    societeCtrlNumero: new FormControl('', Validators.required),
    societeCtrlDescription: new FormControl('', Validators.required),
    societeCtrlConditions: new FormControl('', Validators.required),
    societeCtrlAccept: new FormControl(false, Validators.requiredTrue),
  });
  isSocieteValid = false;
  isSFGSubmitted = false;
  customBoxError = false;
  societeToken;

  dirNum;
  dirigeantFormGroup: FormGroup = new FormGroup({
    dirigeantCtrlNom: new FormControl('', Validators.required),
    dirigeantCtrlPrenom: new FormControl('', Validators.required),
    dirigeantCtrlNaissance: new FormControl('', Validators.required),
    dirigeantCtrlNaissanceDay: new FormControl('', Validators.required),
    dirigeantCtrlNaissanceMounth: new FormControl('', Validators.required),
    dirigeantCtrlNaissanceYears: new FormControl('', Validators.required),
    dirigeantCtrlAdresse: new FormControl('', Validators.required),
    dirigeantCtrlCode: new FormControl('', Validators.required),
    dirigeantCtrlVille: new FormControl('', Validators.required),
    dirigeantCtrlPays: new FormControl('', Validators.required),
    dirigeantCtrlFonction: new FormControl('', Validators.required),
    dirigeantCtrlPart: new FormControl(0, [Validators.required, Validators.min(1)]),
    dirigeantCtrlIban: new FormControl('', Validators.required),
    dirigeantCtrlNumero: new FormControl('', Validators.required),
    dirigeantCtrlKbis1: new FormControl({value: '', disabled: true}, Validators.required),
    dirigeantCtrlKbis2: new FormControl({value: '', disabled: true}, Validators.required),
    dirigeantCtrlKbis3: new FormControl({value: '', disabled: true}, Validators.required)
  });
  dirigeantFile1;
  dirigeantFile2;
  dirigeantFile3;
  isDirigeantValid = false;
  dirigeantToken;

  stripeAccountId;
  testAdress = '';
  options = {
    componentRestrictions : {
      country: ['FR']
    }
  };

  messageModal = '';
  messageTop = 'merci d\'avoir rempli le formulaire';
  stateStep = 0;

  jours = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31];
  mois = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
  annees = [];

  isWeb = true;

  tokenApp;
  typeDevice;
  isDisabled = false;

  constructor(
    private titleService: Title, private metaService: Meta,
    @Inject(PLATFORM_ID) private platformId: any,
    private toastr: ToastrService, public dialog: MatDialog,
    public userService: UserService,
    private auth: AuthServices,
    private httpClient: HttpClient,
    private storageService: StorageService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private commonService: CommonService,
    private MenuListServ: MenuListService
  ) {
    this.tokenApp =  this.activatedRoute.snapshot.queryParamMap.get('token');
    this.typeDevice =  this.activatedRoute.snapshot.queryParamMap.get('device');
    if (this.tokenApp && this.tokenApp) {
      this.isWeb = false;
    }
  }

  getExtension(filename) {
    const parts = filename.split('.');
    return parts[parts.length - 1];
  }

  OnDateChange(a) {
    this.convertData(a);
  }

  convertData(str) {
    const date = new Date(str);
    const mnth = ('0' + (date.getMonth() + 1)).slice(-2);
    const day = ('0' + date.getDate()).slice(-2);
    this.datefinal = [date.getFullYear(), mnth, day].join('-');
    return this.datefinal;
  }

  uploadData(event, num) {
    const fileList: FileList = event.target.files;
    const ext = this.getExtension(fileList[0].name).toLowerCase();
    // tslint:disable-next-line: max-line-length
    if (ext.includes('pdf') || ext.includes('jpg') || ext.includes('JPG') || ext.includes('jpeg') || ext.includes('JPEG') || ext.includes('png') || ext.includes('PNG')) {
      if (fileList.length > 0) {
        const file: File = fileList[0];
        const reader = new FileReader();
        reader.onload = (even) => {
          switch (num) {
            case 0:
              this.logoFile = file;
              this.logo = reader.result;
              break;
            case 1:
              this.imgPath1 = reader.result;
              this.imgSend1 = file;
              this.dataStep3.set('kbis[0]', file);
              this.kbis1 = true;
              this.file1 = file;
              break;
            case 2:
              this.dataStep3.set('kbis[1]', file);
              this.imgPath2 = reader.result;
              this.imgSend2 = file;
              this.kbis2 = true;
              this.file2 = file;
              break;
            case 3:
              this.dataStep3.set('kbis[2]', file);
              this.imgPath3 = reader.result;
              this.imgSend3 = file;
              this.kbis3 = true;
              this.file3 = file;
              break;
            default:
              break;
          }
        };
        reader.readAsDataURL(event.target.files[0]);
      }
    } else {
      this.toastr.warning('Un problème de format uploadé', '', {
        timeOut: 3000
      });
    }
  }

  ngOnInit() {
    this.httpClient.get(`${environment.ws_url}users/acheteur/byId`).subscribe((response: any) => {
      this.userConnected = response.data.user;
      this.etapeVendeur = this.userConnected.etapeVendeur; // this.auth.authStep2
      this.commonService.user = this.userConnected;
      this.commonService.getUser();
    });

    if (this.tokenApp && this.tokenApp) {
      this.isWeb = false;
    }
    if (this.tokenApp && this.tokenApp.length) {
      this.storageService.removeItem('Token');
      this.getVendeur();
      this.userService.typeDevice = this.typeDevice;
      this.storageService.setItem('Token', this.tokenApp);
      // tslint:disable-next-line:max-line-length
      this.httpClient.get(`${environment.ws_url}users/acheteur/byId`, {headers: {Authorization: 'Basic ' + btoa(`${this.typeDevice}:${this.tokenApp}`)}}).subscribe((resp: any) => {
        this.storageService.setItem('User', JSON.stringify(resp.data.user));
        this.commonService.user = resp.data.user;
        this.userConnected = resp.data.user;
        this.etapeVendeur = parseInt(this.commonService.user.etapeVendeur, 10);
        if (this.etapeVendeur) {
          if (this.etapeVendeur >= 3) {
            // this.router.navigate(['/compte/ma-boutique']);
            this.router.navigate(['/abonnement']);
          }
          this.getVendeur();
        }
      });
    } else {
      this.isWeb = true;
      // this.userConnected = JSON.parse(this.storageService.getItem('User'));
      // this.etapeVendeur = this.userConnected.etapeVendeur; // this.auth.authStep2
      this.getVendeur();


      this.commonService.getVendeur();
      if (this.etapeVendeur) {
        if (this.etapeVendeur >= 3) {
          // this.router.navigate(['/compte/ma-boutique']);
          this.router.navigate(['/abonnement']);
        }
        // this.getVendeur();
      }
      if (isPlatformBrowser(this.platformId)) {
        const user = this.storageService.getItem('User');
        if (user) {
          const aaa = JSON.parse(user);
          this.etapeVendeur = parseInt(aaa.etapeVendeur, 10);
          const details = this.storageService.getItem('Details');
          if (details) {
            this.steDetailLoc = JSON.parse(details);
          }
        }
      }

      this.titleService.setTitle('Hadéen-Place| Rejoins notre communauté | 100% Entreprises Françaises');
      this.metaService.updateTag({
        name: 'description',
        // tslint:disable-next-line:max-line-length
        content: 'Vous disposerez d\'un nombre d\'annonces illimitées et d\'une e-boutique rassemblant tous vos produits, service de paiement sécurisé, visibilité de vos articles sur notre site web et application, possibilité de mettre en avant votre vitrine ou tous vos articles en créant vous-même votre publicité défilante, gestion de vos commandes. Recevez votre rémunération directement sur votre compte bancaire.'
      });
    }

    $(document).ready(() => {
      $('societeFormGroup').attr('autocomplete', 'off');
      $('dirigeantFormGroup').attr('autocomplete', 'off');
    });
    const newDate = new Date().getFullYear() - 100;
    for (let index = new Date().getFullYear(); index > newDate; index--) {
      this.annees.push(index);
    }

  }

  preview(event, id) {
    const fileList: FileList = event.target.files;
    if (fileList.length) {
      const reader = new FileReader();
      switch (id) {
        case 1:
          this.societeLogoState = false;
          this.societeLogoFile = fileList[0];
          reader.readAsDataURL(fileList[0]);
          reader.onload = (_event) => {
            this.societeLogoPath = reader.result;
            this.societeLogoState = true;
          };
          break;
        case 2:
          this.dirigeantFile1 = fileList[0];
          this.dirigeantFormGroup.controls['dirigeantCtrlKbis1'].setValue('');
          this.dirigeantFormGroup.controls['dirigeantCtrlKbis1'].setValue(fileList[0].name);
          break;
        case 3:
          this.dirigeantFile2 = fileList[0];
          this.dirigeantFormGroup.controls['dirigeantCtrlKbis2'].setValue('');
          this.dirigeantFormGroup.controls['dirigeantCtrlKbis2'].setValue(fileList[0].name);
          break;
        case 4:
          this.dirigeantFile3 = fileList[0];
          this.dirigeantFormGroup.controls['dirigeantCtrlKbis3'].setValue('');
          this.dirigeantFormGroup.controls['dirigeantCtrlKbis3'].setValue(fileList[0].name);
          break;
        default:
          break;
      }
    }
  }

  getVendeur(rempl?) {
    this.httpClient.get(environment.ws_url + 'users/vendeur/byId').subscribe((respVendeur: any) => {
      this.userData = respVendeur.data;
      if (respVendeur.data.vendeur.id_account_stripe) {
        this.stripeAccountId = respVendeur.data.vendeur.id_account_stripe;
        this.httpClient.get(environment.ws_url + 'HADEEN/stripe-accounts/' + this.stripeAccountId).subscribe((resp: any) => {
          // console.log('response user', resp);
        });
      }
    });
  }

  public handleAddressChange(address: any, ind?) {
    const codePostal = address.address_components.filter(elt => elt.types[0] === 'postal_code');
    const ville = address.address_components.filter(elt => elt.types[0] === 'locality');
    const pays = address.address_components.filter(elt => elt.types[0] === 'country');
    if (!ind) {
      this.societeFormGroup.controls['societeCtrlAdresse'].setValue(address.name);
      this.societeFormGroup.controls['societeCtrlCode'].setValue(codePostal.length ? codePostal[0].long_name : '');
      this.societeFormGroup.controls['societeCtrlVille'].setValue(ville.length ? ville[0].long_name : '');
      this.societeFormGroup.controls['societeCtrlPays'].setValue(pays.length ? pays[0].long_name : '');
    } else {
      this.dirigeantFormGroup.controls['dirigeantCtrlAdresse'].setValue(address.name);
      this.dirigeantFormGroup.controls['dirigeantCtrlCode'].setValue(codePostal.length ? codePostal[0].long_name : '');
      this.dirigeantFormGroup.controls['dirigeantCtrlVille'].setValue(ville.length ? ville[0].long_name : '');
      this.dirigeantFormGroup.controls['dirigeantCtrlPays'].setValue(pays.length ? pays[0].long_name : '');
    }
  }

  ngModelChange(event, dir?) {
    if (!dir) {
      const adrsDataValue = this.societeFormGroup.value;
      const num = adrsDataValue.societeCtrlNumero.internationalNumber;
      if (num) {
        this.societeFormGroup.controls['societeCtrlNumero'].setValue(num);
        this.customBoxError = true
      }
    } else {
      const adrsDataValue = this.dirigeantFormGroup.value;
      const num = adrsDataValue.dirigeantCtrlNumero.internationalNumber;
      if (num) {
        this.dirigeantFormGroup.controls['dirigeantCtrlNumero'].setValue(num);
        this.customBoxError = true
      }
    }
  }

  async step1_stripeCompanyToken() {
    const societeDataValue = this.societeFormGroup.value;
    this.isSFGSubmitted = true;
    if (this.societeLogoState) {
      console.log('this.societeFormGroup: ', this.societeFormGroup);
      console.log('this.societeFormGroup: ', this.societeLogoFile.size);
      // if (this.societeLogoFile.size >= 50000) {
      //   this.toastr.error(`Votre logo ne doit pas dépasser les 5Mo`, '', {
      //     timeOut: 3000
      //   });
      // } else {
        if (!this.societeFormGroup.invalid) {
          if (societeDataValue['societeCtrlAccept'] === true) {
            this.showSpinner = true;
            this.isSocieteValid = false;
            const numComp = (societeDataValue.societeCtrlNumero.internationalNumber).split(' ').join('');
            const taxComp = String(societeDataValue.societeCtrlSiret) || '';
            const tvaComp = societeDataValue.societeCtrlTva || '';
            const accountResult = await this.stripe.createToken('account', {
              business_type: 'company',
              requested_capabilities: [
                'card_payments',
                'transfers',
              ],
              company: {
                name: societeDataValue.societeCtrlNom,
                phone: numComp,
                address: {
                  line1: societeDataValue.societeCtrlAdresse,
                  city: societeDataValue.societeCtrlVille,
                  state: societeDataValue.societeCtrlPays,
                  postal_code: societeDataValue.societeCtrlCode,
                },
                tax_id: taxComp,
                vat_id: tvaComp,
                owners_provided: true,
                directors_provided: true,
              },
              tos_shown_and_accepted: true,
            });
            if (accountResult.token) {
              this.societeToken = accountResult.token.id;
              const me = JSON.parse(this.storageService.getItem('User'));
  
              this.step1_sendData();
            } else if (accountResult.error) {
              this.showSpinner = false;
            }
          } else {
            this.toastr.error('Merci d\'accepter les Conditions Générales d\'utilisation', '', {
              timeOut: 3000
            });
          }
  
        } else {
          this.isSocieteValid = true;
          
          if (
            this.societeFormGroup.controls.societeCtrlNom.status == 'INVALID' ||
            this.societeFormGroup.controls.societeCtrlSiret.status == 'INVALID' ||
            this.societeFormGroup.controls.societeCtrlTva.status == 'INVALID' ||
            this.societeFormGroup.controls.societeCtrlAdresse.status == 'INVALID' ||
            this.societeFormGroup.controls.societeCtrlCode.status == 'INVALID' ||
            this.societeFormGroup.controls.societeCtrlVille.status == 'INVALID' ||
            this.societeFormGroup.controls.societeCtrlPays.status == 'INVALID' ||
            this.societeFormGroup.controls.societeCtrlEmail.status == 'INVALID' ||
            this.societeFormGroup.controls.societeCtrlDescription.status == 'INVALID' ||
            this.societeFormGroup.controls.societeCtrlConditions.status == 'INVALID'
          ) {
            this.toastr.error(`Merci de remplir le formulaire`, '', {
              timeOut: 3000
            });
          } else if (this.societeFormGroup.controls.societeCtrlNumero.status == 'INVALID') {
            this.toastr.error(`Merci de vérifier le numéro de téléphone`, '', {
              timeOut: 3000
            });
          } else if (this.societeFormGroup.controls.societeCtrlAccept.status == 'INVALID') {
            this.toastr.error(`Merci d'accepter notre CGU et la création du compte stripeConnect`, '', {
              timeOut: 3000
            });
          } else {
            this.toastr.error(`Merci de remplir le formulaire`, '', {
              timeOut: 3000
            });
          }
        }
      // }
    } else {
      this.showSpinner = false;
      this.toastr.error('Le logo est obligatoire', '', {
        timeOut: 3000
      });
    }
  }

  async step1_sendData() {
    const societeData = new FormData();
    const societeDataValue = this.societeFormGroup.value;
    const numnum = (societeDataValue.societeCtrlNumero.internationalNumber).split(' ').join('');
    // tslint:disable-next-line:max-line-length
    const allAdress = societeDataValue.societeCtrlAdresse + ',' + societeDataValue.societeCtrlCode + ',' + societeDataValue.societeCtrlVille + ',' + societeDataValue.societeCtrlPays;
    societeData.set('logo', this.societeLogoFile);
    societeData.set('nom_societe', societeDataValue.societeCtrlNom);
    societeData.set('siret', societeDataValue.societeCtrlSiret);
    societeData.set('tva', societeDataValue.societeCtrlTva);
    societeData.set('ville_societe', societeDataValue.societeCtrlVille);
    societeData.set('pays_societe', societeDataValue.societeCtrlPays);
    societeData.set('adresse_societe', allAdress);
    societeData.set('code_postale_societe', societeDataValue.societeCtrlCode);
    societeData.set('mail_societe', societeDataValue.societeCtrlEmail);
    societeData.set('telephone_societe', numnum);
    societeData.set('description_societe', societeDataValue.societeCtrlDescription);
    societeData.set('condition_retour', societeDataValue.societeCtrlConditions);
    this.auth.authStep2(societeData).subscribe(
      (resp: any) => {
        if (!resp.error) {
          const me = JSON.parse(this.storageService.getItem('User'));
          me.etapeVendeur = 0;
          this.storageService.setItem('User', JSON.stringify(me));
          this.commonService.user = me;
          this.etapeVendeur = 0;
          // this.storageService.setItem('etape1', JSON.stringify(this.societeFormGroup.value));
          // this.showSpinner = false;
        }
        this.step1_stripeAccountCreate();
      },
      (err: any) => {
        if (err.error.data === 'Vous avez déja fait étape2 ') {
          this.step1_stripeAccountCreate();
        } else {
          this.showSpinner = false;
        }
      }
    );
  }

  step1_stripeAccountCreate() {
    const account = new FormData();
    account.set('business_type', 'COMPANY');
    account.set('country', 'fr');
    account.set('token_account', this.societeToken);
    this.userService.stripeAccount(account).subscribe(
      response => {
        if (!response.data.error) {
          this.stripeAccountId = response.data.response.id;
          const me = JSON.parse(this.storageService.getItem('User'));
          me.etapeVendeur = 1;
          me.role = 'VENDEUR';
          this.storageService.setItem('User', JSON.stringify(me));
          this.etapeVendeur = 1;

          this.httpClient.get(environment.ws_url + 'users/vendeur/byId').subscribe((respVendeur: any) => {
            this.userData = respVendeur.data;
            if (respVendeur.data.vendeur.account_stripe) {
              this.stripeAccountId = respVendeur.data.vendeur.account_stripe;
            }
            this.showSpinner = false;
            if (isPlatformBrowser(this.platformId)) {
              window.scrollTo(0, 0);
            }
          });
        } else {
          this.showSpinner = false;
          this.toastr.error(response.data.message, '', {
            timeOut: 3000
          });
        }
      },
      err => {
        this.showSpinner = false;
        this.toastr.error(err.error.data.message, '', {
          timeOut: 3000
        });
      }
    );
  }

  async step2_file() {
    this.messageModal = '';
    this.userService.stateStep = 0;
    if (!this.userData) {
      this.getVendeur();
    }
    if (this.etapeVendeur === 1) {
      if (!this.dirigeantFormGroup.invalid && this.dirigeantFile1 && this.dirigeantFile2) {
        this.openModal();
        this.messageModal = '1- Téléchargement des fichiers';
        this.isDirigeantValid = false;
        this.documentPerson = {
          verification: {
            document: {
              front: ''
            },
            additional_document: {
              front: ''
            }
          }
        };
        this.documentSociete = {
          verification: {
            document: {
              front: ''
            }
          }
        };
        const data = new FormData();
        data.append('files[0].file', this.dirigeantFile1);
        data.set('files[0].purpose', 'ADDITIONAL_VERIFICATION');
        data.append('files[1].file', this.dirigeantFile2);
        data.set('files[1].purpose', 'IDENTITY_DOCUMENT');
        if (this.dirigeantFile3) {
          data.append('files[2].file', this.dirigeantFile3);
          data.set('files[2].purpose', 'IDENTITY_DOCUMENT');
        } else {
          data.append('files[2].file', this.dirigeantFile2);
          data.set('files[2].purpose', 'IDENTITY_DOCUMENT');
        }
        data.set('plateforme', 'HADEEN');
        await this.userService.stripeFileToken(data).subscribe(
            (response: any) => {
              this.documentSociete.verification.document.front = response.data[0].response.id;
              this.documentPerson.verification.document.front = response.data[1].response.id;
              if (response.data.length === 3) {
                this.documentPerson.verification.document.back = response.data[2].response.id;
              }

              const me = JSON.parse(this.storageService.getItem('User'));
              this.step1_stripeCompanyToken2();
            },
            (err: any) => {
              this.showSpinner = false;
              this.userService.stateStep = 1;
              this.toastr.error('erreur pendant le telechargement des fichiers', '', {
                timeOut: 5000
              });
            }
          );
      } else {
        this.isDirigeantValid = true;
        this.userService.stateStep = 1;
        this.toastr.error('Merci d\'ajouter les fichier de verifications', '', {
          timeOut: 3000
        });
      }
    } else {
      this.openModal();
      this.step2_createBank();
    }
  }

  async step1_stripeCompanyToken2() {
    const dirigeantDataValue = this.dirigeantFormGroup.value;

    // tslint:disable-next-line:max-line-length
    const phonephone = this.userData.vendeur.telephone ? this.userData.vendeur.telephone : (dirigeantDataValue.dirigeantCtrlNumero.internationalNumber).split(' ').join('');
    const allAdress = (this.userData.vendeur.adresse_societe).split(',');
    const lineAdrs1 = allAdress[0] ? allAdress[0] : dirigeantDataValue.dirigeantCtrlAdresse;
    const city1 = allAdress[2] ? allAdress[2] : dirigeantDataValue.dirigeantCtrlVille;
    const state1 = allAdress[3] ? allAdress[3] : dirigeantDataValue.dirigeantCtrlPays;
    const postalCode1 = allAdress[1] && isNaN(allAdress[1]) ? allAdress[1] : dirigeantDataValue.dirigeantCtrlCode;
    const mySiret = this.userData.vendeur.siret || '';
    const myTva = this.userData.vendeur.tva || '';

    console.log('phonephone: ', phonephone);
    
    const documentSociete = {
      verification: {
        document: {
          front: ''
        }
      }
    };
    const accountResult = await this.stripe.createToken('account', {
      business_type: 'company',
      requested_capabilities: [
        'card_payments',
        'transfers',
      ],
      company: {
        name: this.userData.boutique.nom,
        phone: phonephone,
        address: {
          line1: lineAdrs1,
          city: city1,
          state: state1,
          postal_code: postalCode1,
        },
        tax_id: mySiret,
        vat_id: myTva,
        owners_provided: true,
        directors_provided: true,
        verification: documentSociete.verification
      },
      tos_shown_and_accepted: true,
    });
    if (accountResult.token) {
      this.societeToken = accountResult.token.id;
      this.step2_stripePersonnelToken();
    } else if (accountResult.error) {
      this.showSpinner = false;
      this.userService.stateStep = 1;
    }
  }

  async step2_stripePersonnelToken() {
    this.messageModal = '2- Création de votre compte connecté';

    const dirigeantDataValue = this.dirigeantFormGroup.value;
    const numDir = (dirigeantDataValue.dirigeantCtrlNumero.internationalNumber).split(' ').join('');
    const mailmail = this.userConnected ? this.userConnected.email : '';
    console.log('numDir: ', numDir);
    const personResult = await this.stripe.createToken('person', {
      dob: {
        day: dirigeantDataValue.dirigeantCtrlNaissanceDay,
        month: dirigeantDataValue.dirigeantCtrlNaissanceMounth,
        year: dirigeantDataValue.dirigeantCtrlNaissanceYears,
      },
      first_name: dirigeantDataValue.dirigeantCtrlNom,
      last_name: dirigeantDataValue.dirigeantCtrlPrenom,
      email: mailmail,
      phone: numDir,
      address: {
        line1: dirigeantDataValue.dirigeantCtrlAdresse,
        city: dirigeantDataValue.dirigeantCtrlVille,
        state: dirigeantDataValue.dirigeantCtrlPays,
        postal_code: dirigeantDataValue.dirigeantCtrlCode,
      },
      relationship: {
        percent_ownership: parseInt(dirigeantDataValue.dirigeantCtrlPart, 10),
        title: dirigeantDataValue.dirigeantCtrlFonction,
        owner: true,
        representative: true,
        director: true,
        executive: true
      },
      verification: this.documentPerson.verification
    });
    if (personResult.token) {
      this.dirigeantToken = personResult.token.id;
      this.step2_stripeAccountUpdate();
    }
    if (personResult.error) {
      this.showSpinner = false;
      this.userService.stateStep = 1;
      if (personResult.error.param === 'person[phone]') {
        this.toastr.error('Votre numero est invalide', '', {
          timeOut: 3000
        });
      } else if (personResult.error.param === 'person[dob][year]') {
        this.toastr.error('Merci de verifier votre date de naissance', '', {
          timeOut: 3000
        });
      } else {
        this.toastr.error('Merci de verifier votre informations', '', {
          timeOut: 3000
        });
      }
    }
  }

  async step2_stripeAccountUpdate() {
    this.messageModal = '3- Mis à jour des informations de votre compte';
    const account = new FormData();
    account.append('business_type', 'COMPANY');
    account.append('country', 'fr');
    account.append('token_account', this.societeToken);
    account.append('token_person', this.dirigeantToken);
    const options = `business_type=COMPANY&country=fr&token_account=${this.societeToken}&token_person=${this.dirigeantToken}`;
    await this.userService.stripeAccountUpdate(account, options).subscribe(
      (response: any) => {
        this.stripeAccountId = response.data.response.id;
        const me = JSON.parse(this.storageService.getItem('User'));
        this.step2_sendData();
      },
      (err: any) => {
        // tslint:disable-next-line:max-line-length
        if (err.error && err.error.data.message.includes('You cannot change `verification[document][front]` via API if an account is verified.')) {
          this.step2_sendData();
        } else {
          this.userService.stateStep = 1;
           this.toastr.error(err.error.data.message, '', {
              timeOut: 3000
            }); 
        }
      }
    );
  }

  async step2_sendData() {
    this.messageModal = '4- processus de création de votre boutique';
    const dirigeantDataValue = this.dirigeantFormGroup.value;
    const dataStep3 = new FormData();
    dataStep3.set('plateforme', 'HADEEN');
    dataStep3.set('dirigeant.nom', dirigeantDataValue.dirigeantCtrlNom);
    dataStep3.set('dirigeant.prenom', dirigeantDataValue.dirigeantCtrlPrenom);
    dataStep3.set('dirigeant.adresse', dirigeantDataValue.dirigeantCtrlAdresse);
    dataStep3.set('dirigeant.fonction', dirigeantDataValue.dirigeantCtrlFonction);
    dataStep3.set('dirigeant.telephone', (dirigeantDataValue.dirigeantCtrlNumero.internationalNumber).split(' ').join(''));
    dataStep3.set('kbis[0]', this.dirigeantFile1);
    dataStep3.set('kbis[1]', this.dirigeantFile2);
    if (this.dirigeantFile3) {
      dataStep3.set('kbis[2]', this.dirigeantFile3);
    } else {
      dataStep3.set('kbis[2]', this.dirigeantFile2);
    }
    dataStep3.set('part_detention', dirigeantDataValue.dirigeantCtrlPart);

    this.auth.authStep3(dataStep3).subscribe(
      (response: any) => {
        const me = JSON.parse(this.storageService.getItem('User'));
        me.etapeVendeur = 2;
        this.etapeVendeur = 2;
        this.storageService.setItem('User', JSON.stringify(me));
        this.commonService.user = me;
        setTimeout(() => {
          this.step2_createBank();
        }, 200);
      },
      (err: any) => {
        this.messageTop = 'Merci de vérifier votre informations';
        this.showSpinner = false;
        this.userService.stateStep = 1;
        this.toastr.error(' error on upload data on ws', '', {
          timeOut: 3000
        });
      }
    );
  }

  async step2_createBank() {
    this.messageModal = '5- processus de création de votre banque';
    const dirigeantDataValue = this.dirigeantFormGroup.value;
    const testIbanBrut = (dirigeantDataValue.dirigeantCtrlIban).toUpperCase();
    const testIban = testIbanBrut.trim().split(' ').join('');
    const dataBanque = new FormData();
    dataBanque.set('country', 'FR');
    dataBanque.set('iban', testIban);
    dataBanque.set('plateforme', 'HADEEN');
    dataBanque.set('currency', 'EUR');
    // new Response(dataBanque).text().then(console.log);
    this.userService.backAccount(dataBanque).subscribe(
      (rsp: any) => {
        this.showSpinner = false;
        const me = JSON.parse(this.storageService.getItem('User'));
        me.etapeVendeur = 3;
        me['has_frais_livraison'] = null;
        this.commonService.getUser();
        this.commonService.getVendeur();
        // this.openDialog(false);
        this.storageService.setItem('User', JSON.stringify(me));
        this.commonService.user = me;
        this.toastr.success('Succès', '', {
          timeOut: 5000
        });
        this.MenuListServ.setMenuList();
        this.messageModal = 'Votre compte à bien été crée avec succès';
        this.userService.stateStep = 2;
        this.getVendeur2();
        $('#staticBackdrop').modal('hide');
        this.router.navigateByUrl('/abonnement');
        // setTimeout(() => {
        //   this.commonService.openDialog(false);
        // }, 200);
      },
      (err: any) => {
        this.messageTop = 'Merci de vérifier votre IBAN';
        this.showSpinner = false;
        this.userService.stateStep = 1;
      }
    );
  }

  openModal() {
    $('#staticBackdrop').modal('show');
  }

  closeModal(state?) {
    $('#staticBackdrop').modal('hide');
    this.userService.stateStep = 1;
    if (state) {
      this.router.navigate(['']);
    }
  }

  onChange($event, typeSelect) {
    switch (typeSelect) {
      case 'days':
        if ($event === '31') {
          this.mois = [1, 3, 5, 7, 8, 10, 12];
        } else if ($event === '30') {
          this.mois = [1, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
        }
        this.dirigeantFormGroup.controls.dirigeantCtrlNaissanceDay.setValue($event);
        break;
      case 'month':
        this.dirigeantFormGroup.controls.dirigeantCtrlNaissanceMounth.setValue($event);
        break;

      case 'year':
        this.dirigeantFormGroup.controls.dirigeantCtrlNaissanceYears.setValue($event);
        break;


      default:
        break;
    }
    const dirigeantDataValue = this.dirigeantFormGroup.value;
    // tslint:disable-next-line:max-line-length
    if (dirigeantDataValue.dirigeantCtrlNaissanceDay && dirigeantDataValue.dirigeantCtrlNaissanceMounth && dirigeantDataValue.dirigeantCtrlNaissanceYears) {
      this.dirigeantFormGroup.controls.dirigeantCtrlNaissance.setValue('11/11/2000');
    }
  }

  getVendeur2() {
    this.httpClient.get(`${environment.ws_url}users/vendeur/byId`).subscribe((respVendeur: any) => {
      const newUser = JSON.parse(this.storageService.getItem('User'));
      newUser['has_frais_livraison'] = respVendeur.data.vendeur.has_frais_livraison;
      this.storageService.setItem('User', JSON.stringify(newUser));
    });
  }

  change_part() {
    const dirigeantDataValue = this.dirigeantFormGroup.value;
    const aa = parseInt(dirigeantDataValue.dirigeantCtrlPart, 10);
    if (!aa || aa <= 0) {
      this.toastr.error('Vous devez détenir des parts de la société afin de créer un compte vendeur', '', {
        timeOut: 3000
      });
    }
  }
}
