<div class="optin-in-block ">
  <div class="optin-in-block__form">
    <p class="optin-in-block__form--title">{{ dataItem.SubTitle }}</p>
    <p class="optin-in-block__form--pitch">{{ dataItem.Title }}</p>
    <div class="optin-in-block__form--cta input-group">
      <input type="text" class="form-control" placeholder="{{ dataItem.PlaceholderText }}" aria-label="Email"
        aria-describedby="button-addon2" [(ngModel)]="mailSubscribe" style="border: 1px solid #E2011B; font-weight: 400; background-color: #ffe7e9; color: #E2011B;">
      <div class="input-group-append">
        <button class="btn" type="button" id="button-addon2" (click)="sendData()"
          style="background-color: #ffffff; color: #E2011B; border: 1px solid #E2011B; font-weight: 600;">
          {{ dataItem.ButtonText }}
        </button>
      </div>
    </div>
    <mat-checkbox class="optin-in-block__form-checkbox" [(ngModel)]="acceptNewsLetter">
      <small style="white-space: normal;">
        Je souhaite recevoir la newsletter et j’accepte
        <a href="#" class="text-primary"><u>la politique de confidentialité des données</u></a>
      </small>
    </mat-checkbox>
  </div>
  <div class="optin-in-block__banner">
    <p class="optin-in-block__banner--img" *ngIf="dataItem.Image">
      <img width="auto" height="auto" src="https://strapi.hadeen-place.fr{{ dataItem.Image.url }}" alt="">
    </p>
    <p class="optin-in-block__banner--text" *ngIf="dataItem.Text">{{ dataItem.Text }}</p>
  </div>
</div>
