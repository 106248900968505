<div class="container my-5" style="background-color: '#F6FAF0'">
  <h1 class="d-none">
    Conditions générales d’utilisation, de vente d'Hadéen-Place
  </h1>
  <div class="row">
    <div class="col-12 text-center">
      <div style="font-size: 2rem; line-height: 1.2">
        Conditions générales d’utilisation et de vente des services Hadéen-Place
      </div>
    </div>

    <div class="col-12 mb-4">
      <b>
        Merci de lire ces conditions attentivement avant d'utiliser nos
        Services. En utilisant nos services, vous acceptez d'être soumis aux
        présentes conditions.
      </b>
    </div>
  </div>

  <div class="row">
    <div class="col-12 mt-2">
      <h2>1. Présentation de la Société</h2>
      <p class="mt-1">
        Le site
        <a
          href="https://www.hadeen-place.fr"
          class="underline"
          target="_blank"
          rel="noopener noreferrer"
          >https://hadeen-place.fr</a
        >
        et l’application Hadéen-Place sont édités par la société par actions
        simplifiées SAS HADEEN dont le siège social se situe au : 82 allée du
        Petit Pont, 83240 Cavalaire sur Mer et est inscrite au registre du
        commerce et des sociétés de Fréjus RCS 884 015 983
      </p>
      <p>
        Vous pouvez nous contacter en écrivant au siège social ou par e-mail :
        <a href="mailto:service.client@hadeen-place.fr" class="underline"
          >service.client@hadeen-place.fr</a
        >.
      </p>
      <p>
        Le président de la société est Monsieur Kevin Corna. Toute personne
        ayant accès à internet est autorisée à utiliser nos services
        gratuitement. Notre market place est une plateforme multi vendeurs,
        donnant la possibilité à des TPE et PME françaises de vendre des
        Articles de la vie quotidienne.
      </p>
      <p>
        Hadéen-Place désigne ci-après dans les CGV et utilisation la société SAS
        HADEEN.
      </p>
    </div>

    <div class="col-12 mt-2">
      <h2>
        2. Conditions Générales d’Utilisation et de Vente des services
        Hadéen-Place
      </h2>
      <p class="mt-0">
        En cas de litige, seules les conditions générales d'utilisation de la
        date du conflit seront valides. Nous nous réservons le droit de modifier
        ces conditions générales sans préavis, les utilisateurs recevront chaque
        mise à jour par newsletter et par bannière pour les nouveaux
        utilisateurs. En cas de refus d’acceptation des conditions générales
        d’utilisation de la part de l’utilisateur, la navigation sur nos
        services sera limitée. Nous nous réservons le droit d'interrompre à tout
        moment tout ou partie de nos services sans avertissement préalable. La
        navigation sur nos serveurs est accessible 24h/24h et 7j/7j sauf en cas
        de maintenance. La société SAS HADEEN ne pourra être tenue responsable
        de l’indisponibilité de ses services en cas d’interruption volontaire ou
        non, sécurité ou accessibilité.
      </p>
    </div>

    <div class="col-12 mt-2 ml-md-3">
      <h3>2.1 Définitions</h3>
      <ul class="mt-0">
        <li>
          Acheteur : désigne un utilisateur qui effectue un achat sur nos
          services
        </li>
        <li>
          Vendeur : désigne un utilisateur qui liste et vend des Articles sur
          notre marketplace via nos services
        </li>
        <li>
          Utilisateurs : désigne toute personne utilisant nos services web et
          application.
        </li>
        &nbsp;&nbsp;&nbsp;&nbsp;- Compte : compte personnel regroupant les
        coordonnées de l’utilisateur.
        <li>Nous, notre : désigne les responsables de SAS HADEEN.</li>
        <li>
          Contenu : toutes les données fournies par l’utilisateur sur nos
          services
        </li>
        <li>
          Site : désigne l’ensemble de notre site situé à l’URL
          <a
            href="https://www.hadeen-place.fr"
            class="underline"
            target="_blank"
            rel="noopener noreferrer"
            >hadeen-place.fr</a
          >
        </li>
        <li>Market-place : désigne notre plate-forme de vente</li>
        <li>
          Conditions d’utilisation : désigne l’ensemble des conditions suivantes
          qui s’appliquent à toutes personnes utilisant nos services.
        </li>
        <li>
          Nos services : désigne l’ensemble de notre site : www.hadeen-place.fr
          et de notre application Hadeen
        </li>
      </ul>
    </div>

    <div class="col-12 mt-2 ml-md-3">
      <h3>2.2 Création et Suppression du Compte</h3>
      <p class="mt-0">
        La création d'un compte vous sera demandée pour la création d'une liste,
        valider un panier ou contacter un vendeur. Les données récoltées lors de
        la création du compte comme vos coordonnées, votre mot de passe, reste
        strictement confidentielles.
      </p>
      Pour s’inscrire sur Hadéen-Place,
      l’Utilisateur devra créer un compte avec son adresse email. Tous les
      champs demandés sont obligatoires afin de finaliser l’ouverture du compte
      :
      <ul>
        <li>Genre</li>
        <li>Identité</li>
        <li>Date de naissance</li>
        <li>Adresse mail</li>
        <li>Mot de passe</li>
      </ul>

      <p>
        Pour modifier vos données personnelles il vous suffit de nous écrire via
        la messagerie interne ou par courrier à l'adresse du siège social SAS
        HADEEN : 82 allée du petit pont, 83240 Cavalaire sur Mer. Un
        justificatif d’identification vous sera demandé.
      </p>
      <p>
        Un délai de plusieurs semaines sera nécessaire pour le traitement des
        modifications pour des raisons techniques. Toutes données recueillies
        sur nos services, doit être fournies par l’utilisateur lui-même
        (personne majeur et capable juridiquement) afin d’authentifier la
        véracité des données.
      </p>
      <p>
        En cas d'utilisation frauduleuse, de perte, ou tout autre détournement
        de vos identifiants et mots de passe, nous vous conseillons fortement de
        contacter Hadéen-Place et de les avertir de l’usurpation d’identité.
      </p>
      <p>
        Pour la suppression de votre compte est possible en cliquant sur le
        pictogramme « Mon Compte » puis en cliquant sur bouton « Supprimer mon
        Compte ». Avant de procéder à la suppression définitive de votre compte,
        une confirmation vous sera demandée. Toute suppression est définitive et
        entraîne la suppression de vos données personnelles définitivement.
      </p>
    </div>

    <div class="col-12 mt-2 ml-md-3">
      <h3>2.3 Devenir Vendeur</h3>
      Seuls les professionnels (auto-entreprise ou société) peuvent ouvrir un
      compte « Devenir Vendeur » et seulement sur la version web de notre site.
      Des informations et documents spécifiques seront demandés pour finaliser
      la création d’un compte vendeur :
      <ul>
        <li>Logo</li>
        <li>Nom de société</li>
        <li>Siret</li>
        <li>Numéro de TVA Intracommunautaire</li>
        <li>Code postal</li>
        <li>Ville</li>
        <li>Pays</li>
        <li>Email</li>
        <li>Numéro de téléphone</li>
        <li>Description de la boutique</li>
        <li>Conditions de retour et de remboursement</li>
        <li>Nom et adresse de l’utilisateur</li>
        <li>Part dans la société</li>
        <li>Fonction</li>
        <li>Envoi d’un extrait Kbis de moins de 3 mois</li>
        <li>Envoi d’une copie d’une pièce d’identité en cours de validit</li>
      </ul>
      <p>
        Une fois finalisée son inscription sur Hadéen-place, un Vendeur se verra
        attribuer une e-boutique.
      </p>
    </div>

    <div class="col-12 mt-2 ml-md-3">
      <h3 class="mb-0 pl-3 mt-0">2.3.1 Vendre un article</h3>

      <p class="mt-0">
        Le Vendeur s'engage à ce que tous les Articles mis en vente soient
        disponibles immédiatement et qu’il dispose la pleine et entière
        propriété et capacité de vente. Le Vendeur s'engage à ce que les
        Articles soient neufs, dans leur emballage d’origine et avec les
        étiquettes originales (sauf exception pour les Articles personnalisés).
        Le Vendeur atteste qu’il ne contrevient en aucune façon aux lois et
        réglementations en vigueur ou ne porte pas atteinte aux droits de tiers
        en proposant un article à la vente via nos services.
      </p>
      <p>
        Dans son Annonce, le Vendeur s’engage à décrire de façon complète,
        sincère et fidèle l'Article en vente selon le formulaire proposé par
        Hadéen-Place. La description de l'Article doit correspondre à ses
        caractéristiques effectives. Le Vendeur s’engage à ne mettre en ligne
        que des photographies fidèles, précises et non retouchées du Produit, et
        il garantit détenir l’ensemble des droits qui y sont attachés.
        Hadéen-Place se réserve le droit de supprimer toutes photographies
        qu’elle juge de qualité insuffisante ou inutiles à la bonne présentation
        de l’article. Le Vendeur atteste que l’origine, l’état et les
        caractéristiques du produit qu’il propose à la vente sur le Site sont
        conformes à la description qui en est faite dans la Fiche Produit et que
        l’Article proposé à la vente n’est pas un article contrefaisant.
        Hadéen-Place se réserve le droit de demander au Vendeur de fournir tous
        les documents de nature à justifier l’authenticité l’Article proposé à
        la vente et/ou l’origine de celui-ci.
      </p>
      <p>
        Les Utilisateurs s'interdisent de vendre ou d'acquérir directement ou
        indirectement par l'intermédiaire d’Hadéen-Place des Articles portant
        atteinte à des droits de propriété intellectuelle (Articles contrefaits)
        ou à des réseaux de distribution sélective. Hadéen-Place se réserve le
        droit de supprimer la Fiche Profil et/ou la Fiche Article d’un Vendeur
        dans l’hypothèse où il ne justifierait pas valablement de ses droits de
        propriété sur le ou les Articles référencés sur le Site et/ou de
        l’origine de celui-ci/ceux-ci.
      </p>
      <p>
        L’Utilisateur ne pourra revendiquer indemnisation en cas de suppression
        de ces informations par Hadéen-Place pour les raisons évoquées cidessus.
        L’Utilisateur est le seul responsable de la mise en vente d’un article
        qui ferait l'objet d'une interdiction de vente et/ou qui,
        contreviendrait aux règlementations en vigueur ou porterait atteinte aux
        droits de tiers.
      </p>
      <p>
        Hadéen-Place se réserve le droit de modifier le titre de l’article en
        respectant la description courte de l’article et la description complète
        de l’article afin d’optimiser les outils de SEO liés à sa plate-forme.
      </p>
      <p>
        Les Utilisateurs s'engagent à informer Hadéen-Place dès qu'ils
        constateront qu’une annonce n'est pas conforme aux dispositions du
        présent article. Le Vendeur s’engage à ne mettre qu’une annonce par
        article mis en vente. Hadéen-Place se réserve le droit de supprimer les
        annonces qui sembleraient multiples pour un seul et même article.
      </p>
      <p>
        Le Vendeur détermine le Prix de vente de l'Article sous sa seule
        responsabilité. Hadéen-Place attire notamment l'attention du Vendeur sur
        le fait qu'il est de sa responsabilité de ne pas fixer un Prix
        manifestement excessif. Le Vendeur doit décrire précisément l’ensemble
        des différentes caractéristiques de l'Article et mentionne son Prix, sur
        la Fiche Article. Il n’y a ni de prix minimum ni de prix maximum,
        cependant le prix de l’article doit inclure la totalité des frais
        d'envoi et d’assurance de l’Article.
      </p>
      <p>
        Une fois la Fiche Article mise en ligne, le Vendeur doit se connecter
        régulièrement à son compte afin de prendre connaissance de la liste des
        transactions en cours, les surveiller et maintenir son inventaire à
        jour. Hadéen-Place se réserve le droit de solliciter le Vendeur afin
        qu'il modifie et/ou supprime son annonce et/ou de le faire elle-même
        directement, notamment si le vendeur n'a pas publié son annonce dans la
        catégorie adéquate ou s'il a indiqué ses coordonnées dans son annonce.
        Le Vendeur s'engage à ne publier que des photos des Articles qu'il a
        lui-même prises. Hadéen-Place se réserve le droit de supprimer du site
        toute photo qui n'aurait pas été prise par le vendeur.
      </p>

      <h3 class="mb-0 pl-3 mt-0">2.3.2 Envoyer un article</h3>

      <p class="mt-0">
        Le Vendeur s'engage à envoyer l'Article commandé convenablement emballé
        dans les 72 heures suivant la date d’acceptation de la Commande et à
        communiquer à l’Acheteur le numéro de suivi du transporteur ainsi que la
        ou les photos de l’Article et du colis avant l’expédition. Le Vendeur
        peut contacter l’Acheteur via le système de messagerie proposé par
        Hadéen-Place pour avoir des renseignements supplémentaires pour la
        réalisation de livraison (par exemple le numéro de téléphone). Les
        coordonnées de l'Acheteur doivent être utilisées uniquement pour les
        besoins de l'exécution de la commande ainsi que des obligations légales
        qui s'y rattachent. Le Vendeur doit conserver la preuve d’envoi du Colis
        et une copie de la facture de la commande de l’Article pendant un délai
        de trente (30) jours à compter de ladite expédition.
      </p>
      <p>
        Le Vendeur est le seul responsable vis-à-vis de l’Acheteur de la perte,
        du vol ou de la dégradation de l’Article ou des Articles expédiés.
      </p>
      <p>
        L'Acheteur s'engage à posséder une boîte aux lettres conforme aux
        recommandations des services postaux (dimension de la boîte aux lettres,
        indication des coordonnées des résidents...). Afin de réduire les
        risques de perte ou de mauvaise distribution lors de la livraison, et de
        permettre le suivi de l'expédition de l’Article (l’envoi doit se faire
        obligatoirement par un transporteur qui propose le suivi du Colis).
        Hadéen-Place préconise au Vendeur la souscription d’une assurance auprès
        du transporteur choisi équivalente au montant correspondant au prix de
        son Article. Hadéen-Place décline toute responsabilité lors de la
        livraison et ce, peu importe le transporteur sélectionné.
      </p>
      <p>
        Hadéen-Place ne peut être tenu responsable des délais, des prestataires
        choisis ou de la qualité de livraison choisi par le vendeur.
      </p>
      <p>
        Ni le vendeur ni Hadéen-Place ne pourront être tenus responsables en cas
        de non-livraison de la commande de la part du prestataire de transport
        due à une mauvaise ou incomplète information concernant l’adresse de
        livraison renseignée par l’utilisateur ou en cas de force majeur.
      </p>
      <p>
        A défaut d’expédition de la commande et de communication au Service
        Client du numéro de suivi dans un délai de sept (7) jours après la
        confirmation de disponibilité de l’article, la vente sera annulée et
        l’Acheteur sera remboursé du montant de la commande de l’Article
        concerné
      </p>
      <p>
        Si l’Acheteur ne reçoit pas ses Articles dans les trente (30) jours
        suivant la date de la Commande il peut annuler son contrat par lettre
        recommandée avec accusé de réception au siège social de Hadéen-Place, à
        condition qu’il ait préalablement sollicité Hadéen-Place de remédier à
        la situation dans un délai raisonnable et que la livraison n’ait pas été
        effectuée dans ledit délai supplémentaire.
      </p>

      <h3 class="mb-0 pl-3 mt-0">2.3.3 E-boutique et fonctionnalités supplémentaires</h3>

      <p class="mt-0">
        La e-boutique rassemble les Articles mis en vente par un seul et même
        vendeur. Elle est en quelque sorte la vitrine d’un Vendeur. Il a la
        possibilité de mettre en avant certains de ses Articles ou directement
        sa e-boutique grâce aux fonctionnalités supplémentaires payantes. Le
        Boost est une fonctionnalité supplémentaire payante automatisée qui
        permet de donner plus de visibilité à des Articles sélectionnés afin de
        remonter en tête de catégorie, ou d4 univers ou de sous-univers. Il est
        également possible de mettre en avant des Articles ou e-boutique sur la
        Page d’accueil grâce aux différents espaces publicitaires (il existe
        plusieurs tarifs et plusieurs durées). Ces Services supplémentaires
        donnent lieu à un paiement des commandes de ces « Mises en avant ». Le
        vendeur devra fournir ses coordonnées bancaires afin de les payer avant
        la « Mise en avant » effective.
      </p>
      <p>
        Si un compte ou une carte bancaire appartenant à une tierce personne
        sont enregistrés sur le Site, Hadéen-Place peut demander des pièces
        justificatives d’identité. La facture de cette prestation sera
        disponible dès la mise en place de la mise en avant. Hadéen-Place pourra
        modifier la liste des différents tarifs à tout moment. Hadéen-Place ne
        peut être tenu responsable en cas de non-commande malgré la mise en
        avant. Ces services supplémentaires ne sont que des mises à disposition
        sans aucune obligation de résultat de commande.
      </p>

      <h3 class="mb-0 pl-3 mt-0">2.3.4 Paiement et rémunération</h3>
      <p class="mt-0">
        Le Vendeur recevra le montant de la commande dans un délai de 15 jours
        calendaire à partir de la date de réception du produit, signature ou
        numéro de traçage colis faisant foi (délai de rétractation légal). Le
        montant que percevra le vendeur correspondra au montant du ou des
        Articles vendus déduction faire de la commission de Hadéen-Place qui est
        de 12% par défaut. Cette commission correspond aux frais de
        fonctionnement du site, frais annexes et rémunération ainsi que de la
        commission du prestataire de paiement sécurisé STRIPE.
      </p>
      <p>
        Par exemple un acheteur passe une commande de 100€ à un vendeur, 15
        jours après réception de la commande, le vendeur percevra 88€
        directement sur son compte bancaire. La facture de commission sera alors
        disponible.
      </p>
    </div>

    <div class="col-12 mt-2 ml-md-3">
      <h3>2.4 Le programme d’Abonnement Vendeur</h3>
      <p class="mt-0">
        Les membres du programme d’abonnement de Hadéen-Place peuvent bénéficier
        de nombreux avantages et services tels que des « mises en avant » sur la
        page d’accueil ou à travers différents niveaux d’arborescence,
        référencement à travers notre blog, publication sur les réseaux sociaux
        (Facebook et Instagram), campagnes Google Adds mettant en avant
        plusieurs Articles du Vendeur, réduction de commission sur vente,
        collaboration avec des influenceurs, mention de la boutique du vendeur
        ou d’un et/ou de ses article(s) dans la newsletter d’Hadéen-Place …
      </p>
      <p style="font-weight: 600">
        En vous inscrivant au programme d’abonnement vous acceptez expressément
        que nous commencions à vous fournir le Service de l’abonnement choisi
        pendant la période du délai de rétractation de 14 jours qui n’est
        valable que lors de la première souscription à un des abonnements
        proposés.
      </p>
    </div>

    <div class="col-12 mt-2 ml-md-3">
              <h3 class="mb-0 pl-3 mt-0">
                2.4.1 Avantages du programme d’abonnement
              </h3>
          <p class="mt-0">
            Les membres du programme d’abonnement peuvent bénéficier de nombreux
            avantages cités expressément dans chaque box des offres consultables
            en ligne :
            <a
              class="underline"
              href="https://hadeen-place.fr/compte/mon-abonnement"
              target="_blank"
              rel="noopener noreferrer"
              >https://hadeen-place.fr/compte/mon-abonnement</a
            >.
          </p>
          <h4 style="font-weight: bold">1. L’abonnement Terre :</h4>
          <ul class="mt-0">
            <li>
              Une publicité dédiée à la boutique du Vendeur sur notre Page
              d’accueil dans les "A la Une" (publicité fixe durant 7 jours).
            </li>
            <li>
              Un article sur notre blog incluant un lien externe vers le site
              web du Vendeur
            </li>
            <li>Un deuxième article vers vos réseaux sociaux durant 1 mois.</li>
          </ul>

          <h4 style="font-weight: bold">
            2. L’abonnement Eau :<span style="font-weight: normal"
              >cet abonnement inclut les mêmes avantages que l’abonnement Terre
              avec en complément :</span
            >
          </h4>
          <ul class="mt-0">
            <li>
              La boutique du Vendeur exposée sur la page d’accueil du Site via
              un slider (publicité défilante)
            </li>
            <li>
              Une publication Instagram dans notre fil d’actualité ainsi qu’en
              Story, et toujours en y intégrant un lien vers la boutique «
              Hadéen-Place » du Vendeur et un autre lien vers le profil
              Instagram du Vendeur.
            </li>
          </ul>

          <h4 style="font-weight: bold">
            3. L’abonnement Feu :<span style="font-weight: normal"
              >cet abonnement inclut les mêmes avantages que l’abonnement Eau
              avec en complément :</span
            >
          </h4>
          <ul class="mt-0">
            <li>Une publication réalisée en partenariat avec un influenceur</li>
            <li>
              Réduction de commission à un taux de 10% au lieu de 12% pour le
              Vendeur
            </li>
            <li>La présence du Vendeur dans notre Newsletter</li>
          </ul>
          <p>
            => Pour cet abonnement le Vendeur s’engage à fournir par ses propres
            moyens, à Hadéen-Place ou à l’influenceur directement, un ou
            plusieurs de ses Articles.
          </p>
              <h3 class="mb-0 pl-3 mt-0">
                2.4.2 Conditions particulières pour le remboursement des
                abonnements
              </h3>
          <p class="mt-0">
            L’autre avantage lié au programme d’abonnement d’Hadéen Place est le
            remboursement des frais d’abonnement d’un vendeur, seulement pour la
            première année d’inscription, à partir du jour de souscription du
            premier abonnement. Le remboursement de ces frais ne peut se faire
            seulement si le vendeur a respecté les recommandations prescrites
            par l’équipe d’Hadéen-Place énumérée sous les box d’abonnement et
            visibles avant même la souscription.
          </p>
          <p>
            Si, malgré le suivi de nos 10 recommandations, le vendeur n’a pas
            vendu pour un montant supérieur ou égal à son abonnement annuel,
            l'intégralité de ses forfaits annuels lui sera remboursé. Par
            exemple, si le Vendeur a souscrit à l'abonnement EAU à 32.90€ par
            mois (soit 394,80€ /an) et que le montant de ses ventes via
            Hadéen-Place n'atteint pas ce montant sous réserve que le Vendeur
            ait respecté les conseils préconisés par la brigade d'Hadéen, le
            Vendeur sera intégralement remboursé de son abonnement annuel.
          </p>
          Voici les 10 recommandations :
          <ul class="mt-0" style="list-style-type: auto">
            <li>
              <strong>Mettre à jour les photos des Articles</strong>, les mettre
              en valeur sous leur meilleur angle. (cela permettra aux
              Utilisateurs de mieux se rendre compte de vos Articles en
              situation. )
            </li>
            <li>
              <strong>Présenter les annonces avec des mots clefs</strong>
              pertinents dans les titres (pour aider les Acheteurs à trouver vos
              Articles par le biais de l'outil de recherche).
            </li>
            <li>
              <strong>Ajouter au moins 10#</strong> à la description de
              l’article (le référencement en sera augmenté de manière
              considérable)
            </li>
            <li>
              <strong>Aligner les prix de vente</strong> sur toutes les
              marketplaces et le site web en propre (la cohérence des prix est
              toute aussi importante que les photos).
            </li>
            <li>
              <strong>Mettre plusieurs photos par Article</strong> (des photos
              dans plusieurs situations ou différents fonds rendent l'Article
              plus réel et réduisent les probabilités de retours).
            </li>
            <li>
              <strong>Mettre le lien URL de la boutique Hadéen-Place</strong>
              sur les réseaux sociaux du Vendeur (permettre à vos acheteurs
              d'acheter rapidement les Articles).
            </li>
            <li>
              <strong>Proposer un franco de livraison</strong> (afin
              d'encourager les utilisateurs à acheter plusieurs Articles dans la
              boutique Hadéen du Vendeur)
            </li>
            <li>
              <strong>Décrire la boutique et mettre vos valeurs</strong> bien en
              évidence (cette présentation augmentera la crédibilité du Vendeur
              auprès des acheteurs)
            </li>
            <li>
              <strong>Proposer des conditions de retour</strong> assez larges ou
              gratuites (la confiance des acheteurs sera accrue s'ils savent
              qu'ils peuvent retourner les Articles aisément et gratuitement)
            </li>
            <li>
              <strong>Communiquer</strong> régulièrement sur la boutique Hadéen
              via les réseaux sociaux du Vendeur (publication et story)
            </li>
          </ul>

          <p>
            La demande de remboursements doit être faite au plus tard 1 mois
            avant la date d’anniversaire dudit abonnement. A toutes fin de
            clarifications, si le Vendeur a souscrit le 1er janvier 2022 à un
            abonnement, le Vendeur devra faire la demande de remboursement au
            plus tard le 1er décembre 2022. La demande de remboursement de son
            abonnement n'est valable seulement que pour la première année et à
            partir de sa première souscription.
          </p>
          <p>
            L’offre de remboursement concerne les vendeurs dont le prix des
            Articles sont inférieurs ou égal à 80€ TTC. Le plafond de
            remboursement ne peut excéder le montant payé par le Vendeur.
            Hadéen-Place se réserve le droit d’informer un Vendeur qu’il n’est
            pas éligible à l’offre de remboursement annuel s’il estime que
            l’abonnement ne permettra pas au vendeur d’accroitre ses ventes au
            regard du prix des Articles ou de frais de livraison appliqués, et
            pourra ainsi demander le remboursement de l’abonnement en cours s’il
            le souhaite. Un Vendeur ne peut pas souscrire à deux abonnements
            simultanément.
          </p>
              <h3 class="mb-0 pl-3 mt-0">
                2.4.3 Frais d'inscription et durée des abonnements
              </h3>
          <p class="mt-0">
            Les frais d'inscription correspondent au montant des abonnements :
          </p>
          <ul>
            <li>Air : gratuit</li>
            <li>Terre : 14.90€ pour 1 mois</li>
            <li>Eau : 32.90€ pour 1 mois</li>
            <li>Feu : 99.90€ pour 1 mois</li>
          </ul>
          <p>
            Tous les prix affichés sont hors taxes, le vendeur sera débité et
            facturé au montant TTC (toutes taxes comprises).
          </p>
          <p>
            Les abonnements se reconduisent tacitement tous les mois tant que le
            Vendeur n’a pas résilié son abonnement ou changer de formule, à
            partir de son compte ou sur simple demande auprès du service client.
            Un mail sera envoyé automatiquement 48h avant le renouvèlement afin
            d’informer le vendeur que son abonnement va être reconduit si aucune
            action n’est faite de sa part pour résilier ou changer d’abonnement.
          </p>
          <p>
            La possibilité de souscrire aux abonnements pour une année permet
            une réduction de 15% du montant total. Cette souscription annuelle
            oblige le Vendeur durant une année.
          </p>
              <h3 class="mb-0 pl-3 mt-0">
                2.4.4 Paiement des abonnements
              </h3>
          <p class="mt-0">
            Si un Vendeur s’inscrit au programme d’abonnement d’Hadéen-place, il
            sera facturé dès le 1er du mois suivant, et recevra la facture par
            mail.
          </p>
          <p>
            Si un Vendeur souhaite se rétracter, mettre fin à son inscription ou
            annuler son inscription, les frais d'inscription alors en vigueur
            seront automatiquement facturés en utilisant le moyen de paiement
            par défaut si la période des 14 jours de délai légal de rétractation
            a été dépassée et si les « mises en avant » sont déjà été
            effectives.
          </p>
          <p>
            Si les 14 jours sont respectés, l’abonnement prendre fin, et le
            Vendeur sera remboursé. Si le Vendeur souhaite souscrire à un autre
            abonnement, il devra attendre la fin de l’abonnement encours.
          </p>
          <p>
            Si le moyen de paiement par défaut associé au paiement de
            l’abonnement cesse d'être valable pendant la durée de l’adhésion au
            programme d’abonnement, l’abonnement ne sera pas reconduit. Si le
            paiement est décliné pour toute raison autre qu'une raison qui nous
            serait imputable, votre inscription au programme d’abonnement sera
            annulée.
          </p>
              <h3 class="mb-0 pl-3 mt-0">
                2.4.5 Résiliation de l'inscription par le Vendeur et
                remboursements
              </h3>
          <p class="mt-0">
            Un Vendeur est inscrit au programme d’abonnement d’Hadéen-place peut
            le résilier à tout moment en se rendant dans la rubrique « Votre
            Compte » et en ajustant les paramètres de son inscription. Le
            Vendeur peut également contacter le Service Client. Cependant aucun
            remboursement du mois en cours ne pourra être effectué si les
            avantages de cette offre ont déjà été mis en ligne et le délai de
            rétractation passé.
          </p>
          <p>
            Les inscriptions au programme d’abonnement d’Hadéen-place souscrites
            en utilisant un code promotionnel ou un code cadeau ne sont pas
            remboursables.
          </p>
              <h3 class="mb-0 pl-3 mt-0">
                2.4.6 Droit de rétractation de 14 jours
              </h3>
          <p class="mt-0">
            Si un Vendeur souscrit au programme d’abonnement d’Hadéen-place, il
            peut exercer son droit de rétractation dans un délai de 14 jours
            après sa date d'inscription au programme lors de son premier
            abonnement. Le droit de rétractation ne sera pas valable en cas de
            changement d’Abonnement après 14 jours.
          </p>
          <p>
            Si un Vendeur décide d'exercer son droit de rétractation et qu’il
            n’a ni bénéficié des avantages d’abonnement Hadéen-Place ni d’offres
            promotionnelles, nous lui rembourserons intégralement les frais
            d'inscription du programme d’abonnement pour la période concernée.
            Dans le cas contraire, nous procéderons à un remboursement partiel
            en fonction de l'utilisation des avantages de l’abonnement dont le
            Vendeur a bénéficié jusqu'alors.
          </p>
          <p>
            Pour exercer son droit de rétractation dans le délai de 14 jours, le
            Vendeur peut modifier les paramètres de son adhésion d’abonnement
            dans la rubrique « Votre Compte », ou contacter le Service Client.
            La date prise en compte pour déterminer si un Vendeur peut exercer
            son droit de rétractation dans le délai imparti de 14 jours est la
            date d'envoi de son paiement.
          </p>
              <h3 class="mb-0 pl-3 mt-0">
                2.4.7 Offres promotionnelles d'essai ou d'inscription
              </h3>
          <p class="mt-0">
            Nous proposons parfois à certains vendeurs des offres
            promotionnelles d'essai ou autres offres promotionnelles
            d'inscription aux abonnements qui sont soumises aux présentes
            Conditions sauf mention contraire dans les conditions spécifiques de
            l'offre.
          </p>
          <p>
            Les Vendeurs ayant souscrits à une offre promotionnelle d'essai ou à
            toute autre offre promotionnelle peuvent décider, à tout moment,
            conformément aux présentes Conditions et aux conditions de l'offre,
            de ne pas convertir leur essai en inscription payante ou de ne pas
            continuer leur offre promotionnelle en inscription
            non-promotionnelle en modifiant les paramètres du programme
            d’abonnement de Hadéen-Place dans la rubrique « Votre Compte ».
          </p>
              <h3 class="mb-0 pl-3 mt-0">
                2.4.8 Acceptation de l’ inscription aux abonnements
              </h3>
          <p class="mt-0">
            Nous nous réservons le droit d'accepter ou de refuser votre
            inscription, dans les limites autorisées par la loi applicable.
          </p>
    </div>

    <div class="col-12 mt-2 ml-md-3">
      <h3>
        2.5 Interdiction de transfert ou de cession de votre inscription ou de
        vos avantages
      </h3>
      <p class="mt-0">
        Il n'est pas possible de transférer à des tiers une inscription au
        programme d’abonnement Hadéen-Place ni aucun avantage découlant de
        l’inscription.
      </p>
    </div>

    <div class="col-12 mt-2 ml-md-3">
      <h3>2.6 Résiliation de l’inscription au programme d’abonnements</h3>
      <p class="mt-0">
        Nous pouvons résilier une inscription au programme d’abonnement de
        Hadéen-Place sans préavis. Dans ce cas le Vendeur recevra un
        remboursement partiel des frais d'inscription déjà payés. Le montant de
        ce remboursement sera déterminé en proportion de la durée de
        l'inscription restant à courir.
      </p>
      <p>
        Nous pouvons notifier de la résiliation immédiate et de plein droit de
        l’inscription au programme d’abonnement d’Hadéen-place si nous estimons
        que (a) votre utilisation du Service d’abonnement de Hadéen-Place
        constitue une violation matérielle des présentes Conditions ou de toute
        loi applicable, ou que (b) le Vendeur fait un usage abusif ou frauduleux
        du service d’abonnement de Hadéen-Place. Le Vendeur ne recevra pas de
        remboursement dans ce cas. Nous informerons de la résiliation de
        l’inscription au programme d’abonnements d’Hadéen-place et du droit d'en
        faire appel.
      </p>
      <p>
        Conformément à l'article 10.2 des présentes Conditions, le Vendeur peut
        résilier son inscription au programme d’abonnement de Hadéen-Place à
        tout moment.
      </p>
    </div>
    <div class="col-12 mt-2 ml-md-3">
              <h3 class="mb-0 mt-0">
                2.6.1 Modification des Conditions du programme d’abonnement
              </h3>
          <p class="mt-0">
            Nous pouvons à tout moment apporter des modifications aux présentes
            Conditions ou au Service d’abonnement d’Hadéen-place, ou à l'un
            quelconque de ses éléments : pour des raisons légales ou
            réglementaires ; pour des raisons de sécurité ; pour améliorer des
            éléments existants ou pour ajouter des éléments supplémentaires au
            programme d’abonnement d’Hadéen-place; pour intégrer des avancées
            technologiques; pour effectuer des ajustements techniques appropriés
            du programme d’abonnement d’Hadéen-place; et pour s'assurer du
            fonctionnement régulier du programme d’abonnement d’Hadéen-place.
          </p>
          <p>
            Si nous apportons des modifications, nous en informerons les
            Vendeurs dans le délai et la forme prescrits et rappellerons les
            droits dont ils disposeront. Les Vendeurs seront en droit de ne pas
            accepter ces modifications et conserverez la possibilité de mettre
            fin à leur inscription au programme d’abonnement d’Hadéen-place.
            Toute augmentation du montant des frais d'inscription ne sera pas
            appliquée avant la fin de la période en cours et vous en serez
            notifié au préalable au moins 14 jours avant par email. Si une
            modification est considérée comme non valable, nulle ou inapplicable
            pour quelque raison que ce soit, celle-ci n'affecte pas la validité
            ni l'applicabilité des autres conditions et modifications.
          </p>
    </div>

    <div class="col-12 mt-2 ml-md-3">
      <h3>2.7 Transaction</h3>
      <p class="mt-0">
        Tout utilisateur doit être majeur et capable juridiquement pour conclure
        une vente ou un achat.
      </p>
      <p>
        Toutes les transactions se feront via nos services. L’utilisateur aura
        plusieurs choix concernant le paiement et les livraisons en fonction des
        options proposées par les vendeurs concernés par la transaction. La
        transaction sera définitivement validée qu’après acceptation des CGU,
        paiement total du montant de la commande et après la validation de la
        part du vendeur. Les prix affichés sont édités en euros, toutes taxes
        comprises et frais de livraison inclus.
      </p>
      <p>
        Nous ne pouvons être tenus responsables en cas de mauvaises informations
        concernant un article vendu par les vendeurs présents sur notre site.
        Les informations disponibles sur nos services ne sont fournies qu'à
        titre indicatif.
      </p>
      <p>
        Le Vendeur s'engage à confirmer ou infirmer la commande dans un délai de
        24 heures suivant la réception du courrier électronique de Hadéen-Place
        l'informant que l'article qu'il a mis en vente sur le Site a trouvé un
        acheteur. Le Vendeur peut accepter la commande en consultant son Compte
        sur le Site ou l’application. Son acceptation est ferme et irrévocable.
        A compter de l'acceptation du vendeur, la vente est conclue et réputée
        parfaite. A défaut de réponse de la part du vendeur, passé le délai de
        24 heures vaudra infirmation de la commande, et le contrat de vente ne
        sera pas conclu.
      </p>
      <p>
        Dès lors que le Vendeur accepte la commande, l'Acheteur accepte que ses
        coordonnées (nom, prénom et adresse de livraison) soient révélées au
        vendeur. Le Vendeur s'engage à garder confidentielles ces informations
        et à ne pas les divulguer. Il s’engage également à ne pas porter
        atteinte au respect de la vie privée des autres Utilisateurs et à ne pas
        leur envoyer de message, d'objet, de documentation notamment
        publicitaire, par quelque moyen que ce soit (notamment par voie postale,
        téléphonique ou électronique).
      </p>
      <p>
        Dès lors que le vendeur refuse la commande, la transaction est alors
        annulée, et aucun débit de la carte bancaire de l'Acheteur n'aura lieu.
      </p>
      <p>
        En cas de commande personnalisée, nous invitons les utilisateurs à
        contacter la messagerie interne du Vendeur afin d’être d’accord sur la
        personnalisation.
      </p>
      <p>
        Pour toute question relative à une commande, l’Acheteur et le Vendeur
        peuvent nous contacter à l’adresse
        <a class="underline" href="mailto:service.client@hadeen-place.fr"
          >service.client@hadeen-place.fr</a
        >
        ou via notre Application dans la rubrique Mon Compte > Nous Contacter.
      </p>
    </div>

    <div class="col-12 mt-2 ml-md-3">
      <h3>2.8 Modalités de Paiement</h3>
      <p class="mt-0">
        Afin de finaliser une transaction plusieurs modes de paiement, vous sont
        proposés grâce à notre prestataire de service STRIPE. Toute transaction
        est donc soumise à l’Accord sur les comptes Stripe Connected (Stripe
        Connected Account Agreement), incluant les Modalités de services de
        Stripe (“Conditions Générales d’Utilisation Stripe” - “Stripe Services
        Agreement”.).
      </p>
      <p>
        En agréant aux présentes Conditions Générales d’Utilisation ou en
        continuant à opérer en tant que Vendeur sur nos services, vous acceptez
        d’être lié aux Conditions Générales d’Utilisation Stripe, celles-ci
        pouvant être occasionnellement modifiées.
      </p>
      <p>
        Les données bancaires récoltées lors du paiement sont protégées et
        cryptées par ce prestataire, ni Hadéen-Place ni les vendeurs n’ont accès
        à ces données. En cas de refus de paiement de la part du prestataire
        Stripe, la transaction sera annulée. Jusqu'au paiement complet de la
        commande, les Articles restent la propriété des vendeurs. L'utilisateur
        atteste sur l'honneur d'être en capacité d'utiliser le moyen de paiement
        ainsi que la véracité de ses données personnelles. L'Acheteur peut payer
        par : Carte Bancaire (Visa, Mastercard ou American Express) via notre
        prestataire sécurisé Stripe qui utilise le protocole SSL (Secure Socket
        Layer), cryptant par logiciel les informations transmises et assurant
        qu'aucun tiers (ni Hadéen-Place) ne peut en prendre connaissance au
        cours du transport sur le réseau.
      </p>
      <p>
        Hadéen-Place peut vérifier auprès de l’Acheteur, par téléphone, par
        courrier électronique ou courrier postal, pour valider la commande et
        lui demander des justificatifs d'identité (copie de la carte d'identité
        et/ou justificatif de domicile). Dans l'attente de la réception de ces
        documents, Hadéen-Place se réserve le droit de suspendre ou de supprimer
        une commande. Hadéen-Place ne peut être tenue responsable en cas d’usage
        frauduleux des moyens de paiement utilisés lors d’une ou plusieurs
        commandes.
      </p>
      <p>
        La sauvegarde du moyen de paiement, comme la sauvegarde de la carte de
        bancaire proposée par notre prestataire de service Stripe, permet de
        passer une commande plus rapidement et plus simplement, tout en offrant
        un niveau de sécurité maximale. Pour cela, l’Acheteur devra cocher la
        case « J'autorise Stripe à mémoriser mes coordonnées financières pour
        éviter de les saisir lors de mes prochaines commandes chez ce marchand
        ». Ces coordonnées bancaires ne seront pas conservées par Hadéen-Place
        mais par Stripe.
      </p>
    </div>

    <div class="col-12 mt-2 ml-md-3">
      <h3>2.9 Réception Commande et Avis</h3>
      <p class="mt-0">
        L'Acheteur doit confirmer la réception de l'Article et sa conformité
        dans un délai de 48h suivant la réception de l'Article (y compris
        week-ends et jours fériés, sans compter le jour de la livraison). Si
        au-delà de 48h après réception de l'Article, l'Acheteur n’a pas notifié
        la réception ainsi que la conformité, Hadéen-Place procèdera à la
        finalisation de la vente et au paiement du Vendeur des sommes
        correspondant à la transaction. L'Acheteur et le Vendeur acceptent que
        Hadéen-Place calcule ce délai de 48h à partir des informations fournies
        par le transporteur. Hadéen-Place décline toute responsabilité en cas de
        mauvaise information fournie par le transporteur ou vendeur.
      </p>
      <p>
        En présence d'une anomalie apparente (avarie, Articles manquants, colis
        endommagé, Articles cassés, etc.), l’Acheteur doit impérativement porter
        sur le bon de livraison des réserves précises : indication du nombre de
        colis et/ou Articles manquants et/ou endommagés et description détaillée
        du dommage éventuel (emballage ouvert ou déchiré, Articles détériorés ou
        manquants, etc.). Il est indispensable que l’Acheteur conserve les
        éléments de l’expédition dans l'état dans lequel ils lui ont été livrés
        (accessoires, pièces reçues avec ces Articles dans leur état d’origine).
        Nous invitons l’acheteur à prendre en photo le colis dès sa réception
        pour justifier l’ouverture du litige.
      </p>
      <p>
        Dans l'hypothèse ou faute d’être présent lors de la livraison ou d'être
        allé chercher l'Article, et que le colis est réexpédié au Vendeur par le
        transporteur, l'Acheteur accepte que les frais d'expédition réglés par
        le Vendeur ainsi que toute autre taxe, frais ou commission, soient
        intégralement reversés au Vendeur. Le Vendeur s’engage à communiquer
        dans les plus brefs délais à Hadéen-Place et à l’Acheteur le retour de
        l’Article. Le Vendeur s’engage à communiquer à l’Acheteur si le
        remboursement de l’Article retourné, net de toute taxe, frais ou
        commission, peut être effectué. L’Article (hors frais de port et taxe et
        frais) sera remboursé.
      </p>
      <p>
        Si le Colis était indiqué comme “livré” alors que l’Acheteur déclarerait
        ne pas l’avoir reçu, ce dernier s’engage à ouvrir une réclamation auprès
        du transporteur et/ou de fournir toute information demandée par le
        transporteur afin de mettre en application l’assurance sur l’expédition
        contracté par le Vendeur.
      </p>
      <p>
        Dans l’hypothèse où le colis serait perdu par les services postaux, le
        Vendeur s’engage à rembourser le Prix de la Transaction à l’Acheteur. Il
        reviendra au Vendeur de se retourner contre son transporteur.
      </p>
      <p>
        La durée moyenne d’une enquête est de trente (30) jours. Si la perte ou
        l’avarie est constatée, Hadéen-Place remboursera l’Acheteur. Ce
        remboursement s’effectuera dans un délai de cinq (5) jours ouvrés à
        compter de la confirmation de la perte ou de l’avarie du Colis par le
        transporteur. Dans l’hypothèse où le Colis est déclaré à tort
        “réceptionné par l’Acheteur”, il appartient à l’Acheteur d’engager une
        procédure en contestation. Pour se faire, l’Acheteur doit contacter le
        Service Client du transporteur dans un délai de deux (2) jours maxima
        suivant la dernière mise à jour du statut du Colis.
      </p>
      <p>
        Le Vendeur s’engage à communiquer à l’Acheteur toute information
        nécessaire pour l’ouverture d’une contestation ou litige auprès du
        transporteur. Hadéen-Place met à disposition de l’Acheteur et du Vendeur
        un service de messagerie afin de faciliter les échanges pour tous les
        litiges, réclamation, personnalisation.
      </p>
      <p>
        Hadéen-Place ne peut être tenue responsable de la perte, du vol, du
        retard, dégradation ou endommagement dans l'acheminement des Articles.
      </p>
      <p>
        Dans l'hypothèse où un litige surviendrait entre le Vendeur et
        l'Acheteur, Hadéen-Place pourra bloquer les fonds versés par l’Acheteur
        jusqu'à la résolution du litige.
      </p>
      <p>
        En cas de transaction vous êtes invités à noter et laisser des
        commentaires concernant les vendeurs ainsi que les Articles ou services
        achetés via nos services. Ces commentaires ne doivent en aucun cas
        entraver la loi ou être de mauvaise foi. Tous commentaires à caractère
        raciste, illégal, injurieux, diffamatoire, harcelant, hors de propos
        sera supprimé. Hadéen-Place se réserve le droit de supprimer tout
        commentaire, sans notification préalable ni motif.
      </p>
      <p>
        En cas de non-respect des présentes conditions générales d'utilisation,
        nous nous réservons le droit d'interrompre de manière temporaire ou
        définitive l'accès aux services à l'utilisateur concerné. Tout
        utilisateur du site certifie avoir 18 ans révolus et être capable
        légalement (sans tutelle ou curatelle par exemple).
      </p>
    </div>

    <div class="col-12 mt-2 ml-md-3">
      <h3>2.10 Retour et Remboursement</h3>
      <p class="mt-0">
        Conformément à la loi l'utilisateur peut exercer son droit de
        rétractation sans se justifier (un motif sera demandé à titre
        indicatif), dans un délai de 14 jours calendaires à partir de la date de
        réception du produit, signature ou numéro de traçage colis faisant foi.
        Le droit de rétractation s'applique également aux Articles soldés,
        d'occasions ou déstockés. Le paiement des frais de port retour sont à la
        charge de l’acheteur. Nous n'intervenons en aucun cas sur la gestion des
        retours. L'utilisateur contacte directement le Vendeur via la messagerie
        interne dans « Mon compte » puis « Commande » et enfin contacter le
        Vendeur pour un retour. Un formulaire de retour vous sera fourni. Le
        retours des Articles devront être effectués dans leur emballage
        d'origine est complet, afin de permettre leur recommercialisation
        Cependant les retours des Articles suivants sont exclus :
      </p>
      <ul>
        <li>Les denrées alimentaires</li>
        <li>Les Articles personnalisés</li>
        <li>Les Articles alcoolisés</li>
        <li>
          Les Articles santé, cosmétiques, hygiène, sous-vêtements dont
          l’emballage d’origine a été ouvert
        </li>
      </ul>

      Chaque Vendeur définit sa propre politique de retours ou d’échange :
      <ul>
        <li>Prise en charge du montant du retour</li>
        <li>Délais de rétractation plus long</li>
        <li>Lieu de prise en charge du retour</li>
        <li>Échange contre un autre produit</li>
        <li>
          Une décote en guise de dédommagement en cas d’article utilisé et
          endommagé
        </li>
        <li>Autres</li>
      </ul>
      <p>
        Nous vous préconisons de lire attentivement les conditions de retour de
        chacun des vendeurs correspondant aux Articles que vous souhaitez
        achetés. Le remboursement du montant de la commande ainsi que des frais
        de port standards s’effectueront dans les 14 jours à compter de l’envoi
        du retour (numéro de tracking faisant foi), par le même moyen de
        paiement que celui utilisé lors de la commande sauf accord préalable du
        client
      </p>
      <p>
        En cas d’Articles très encombrants, le vendeur devra fournir un
        estimation des frais de retour. Si un défaut apparaît sur le produit
        après l’achat, la loi sur la garantie prévue par le code de consommation
        vous permet de retourner l’Article même si vous n’avez pas souscrit à un
        contrat de garantie. Cette garantie couvre la livraison du mauvais
        article (non conforme à la description ou impropre à son usage attendu)
        dans un délai de deux ans à compter de la possession de ce produit.
      </p>
      <p>
        En cas de litige, c’est au vendeur de prouver que le défaut n’existait
        pas au moment de la vente. Une solution d’échange, de réparation ou de
        remboursement peut être proposée par le vendeur. Les litiges sont
        directement réglés entre l'acheteur et le vendeur via l'outil de
        messagerie mis à leur disposition sur le site et l’application. Dans
        tous les cas, l’acheteur est invité à nous faire part de ce désagrément
        afin d’en avertir les autres utilisateurs de ce même produit. Cependant
        la société SAS HADEEN ne peut être tenue responsable de ces garanties.
      </p>
    </div>

    <div class="col-12 mt-2 ml-md-3">
      <h3>2.11 Contenu du Site et Falsification</h3>
      <p class="mt-0">
        Tout téléchargement, reproduction, utilisation, affichage, modification
        du contenu de notre site doit faire l'objet d'une demande écrite à
        Hadéen-Place. Les photos, images, textes, liens, vidéos, marques, logo
        ou tout autre contenu figurant sur nos services sont la propriété de
        Hadéen-Place et/ou de ses vendeurs.
      </p>
      L’Utilisateur autorise Hadéen-Place en mettant en ligne son Profil et/ou
      un Produit :
      <ul>
        <li>
          À retoucher les photos des Articles qu'il aura mis en ligne afin
          d'optimiser leur visibilité.
        </li>
        <li>À communiquer sur certains Articles qu'il aura mis en vente.</li>
        <li>
          À modifier, sauvegarder, reproduire, diffuser et exploiter l’ensemble
          des éléments, dont le titre de l’article, son image et/ou ses photos
          des Articles, et/ou mettre en ligne des commentaires, en tous formats,
          par tous moyens et sur tous supports, notamment sur le Site et sur les
          documents commerciaux tels que les « Notifications » et « Newsletters
          », ainsi que sur les sites partenaires de Hadéen-Place.
        </li>
      </ul>
      <p>
        L’Utilisateur s’engage à ne pas employer sur le Site, notamment par le
        biais des commentaires, des propos contraires aux bonnes mœurs,
        injurieux, diffamatoires ou pouvant porter atteinte à la réputation des
        autres Utilisateurs. En cas de non-respect de cette obligation,
        Hadéen-Place se réserve le droit de clôturer définitivement le compte de
        l’Utilisateur. L’Utilisateur s’interdit de publier des photographies
        dont le contenu serait inapproprié (nudité, violence, haine…).
        Hadéen-Place se réserve le droit de supprimer tout contenu qu’il
        jugerait inapproprié ou diffamatoire, et également de supprimer les
        comptes des Utilisateurs.
      </p>
    </div>

    <div class="col-12 mt-2 ml-md-3">
      <h3>2.12 Dommages</h3>
      <p class="mt-0">
        Nous assumons la responsabilité des dommages causés par une négligence
        grave ou une faute intentionnelle de notre part, y-compris nos
        directeurs et représentants légaux. Nous assumons également la
        responsabilité des dommages causés par notre violation d'une obligation
        essentielle des présentes Conditions et sur laquelle vous vous êtes
        fondé au moment de votre inscription sur le Site et/ou au programme
        d’abonnement d’Hadéen-place. Dans ce cas, notre responsabilité ne peut
        être engagée que pour les pertes et dommages qui étaient prévisibles
        pour vous et Hadéen-Place. Ce paragraphe n'a pas d'incidence sur les
        droits qui vous sont obligatoirement accordés par la loi en tant que
        consommateurs. Il ne limite pas la responsabilité de Hadéen-Place
        résultant d'une atteinte à la vie, de tout dommage corporel ou atteinte
        à la santé, d'une violation de garantie ou défaut frauduleusement
        dissimulé. Les Conditions d'Utilisation et Générales de vente
        s'appliquent également.
      </p>
    </div>

    <div class="col-12 mt-2 ml-md-3">
      <h3>2.13 Force Majeure</h3>
      <p class="mt-0">
        Nous ne pourrons pas être tenus responsables d'un retard ou manquement à
        nos obligations en vertu des présentes Conditions si ce retard ou
        manquement est causé par un cas de force majeure.
      </p>
    </div>

    <div class="col-12 mt-2 ml-md-3">
      <h3>2.14 Litiges</h3>
      <p class="mt-0">
        Le Site joue seulement un rôle d’intermédiaire, Hadéen-Place n’est en
        aucun cas revendeur des Articles mis en vente et ne devient, à aucun
        moment, propriétaire des Articles.
      </p>
      <p>
        Le contrôle qualité effectué par, Hadéen-Place porte uniquement sur
        l’adéquation de l’Article et à son descriptif inséré par le Vendeur dans
        la fiche Article. Hadéen-Place ne peut être tenue responsable des
        litiges déclarés entre l'Acheteur et le Vendeur concernant une
        transaction et ne peut pas garantir que l’Article satisfera l’Acheteur.
      </p>
      <p>
        Lorsqu'un litige survient entre les parties, Hadéen-Place pourra,
        pendant un délai de 30 jours, mettre tout en oeuvre pour aider les
        parties à trouver un accord à l’amiable et tenir le rôle de médiateur
        mais ne pourra être tenu pour responsable de l'issue du litige. Pendant
        ce délai, le fonds de la transaction du ou des Utilisateurs concernés
        peuvent-être temporairement bloqués et les demandes de virement
        suspendues.
      </p>
      <p>
        Tout comportement révélant une volonté manifeste d'induire en erreur
        et/ou de mauvaise foi, pourra faire l'objet d'une mise en demeure, de la
        suspension ou de l’interruption définitive d’accès de l’Utilisateur aux
        services de Hadéen-Place. Dans ce cas, le Vendeur devra rembourser
        Hadéen-Place des sommes qu’il aura éventuellement perçues au titre de la
        vente dudit Article et devra dédommager Hadéen-Place pour tous les frais
        éventuels encourus par Hadéen-Place et/ou l’Acheteur du fait de
        l’activité du Vendeur et devra garantir Hadéen-Place contre
        d’éventuelles poursuites judiciaires.
      </p>
      <p>
        Hadéen-Place est en partenariat avec des prestataires dont les sites
        internet sont en lien sur nos services. Ne pouvant être tenu responsable
        du contenu de ses sites ni vous garantir la satisfaction de leur
        services ou Articles en cas de transaction.
      </p>
    </div>

    <div class="col-12 mt-2 ml-md-3">
      <h3>2.15 Loi Applicable</h3>
      <p class="mt-0">
        Nous nous réservons le droit de restreindre, de suspendre, de résilier
        l’accès de nos services à tout utilisateur ayant enfreint les présentes
        conditions générales d’utilisations ou ayant un comportement frauduleux
        à l’encontre de la société SAS HADEEN ou à tiers vendeur de la Market
        Place.
      </p>
      <p>
        Nous nous réservons le droit également d’intenter une action en justice,
        plainte ou toute autre sanction que la direction de SAS HADEEN jugera
        utile et nécessaire, à l’encontre de tout utilisateur frauduleux ou
        ayant commis une violation des conditions générales d’utilisation ou de
        vente, pour un remboursement ou indemnisation du préjudice subi.
      </p>
      <p>
        Présentes conditions générales d'utilisation sont régies par le droit
        français. Tout litige relatif à ces conditions générales relève des
        juridictions françaises Si une partie des conditions générales
        d’utilisation et de vente devaient s'avérer illégales, invalides ou
        inapplicables, pour quelque raison que ce soit, les dispositions en
        question seraient réputées non écrites, sans remettre en cause la
        validité des autres dispositions qui continueront de s'appliquer à tous
        les utilisateurs de nos services et à la société SAS HADEEN.
      </p>
      <p>
        Pour toute réclamation merci de contacter le service client à :
        <a class="underline" href="mailto:service.client@hadeen-place.fr"
          >service.client@hadeen-place.fr</a
        >.
      </p>
      <b>Date d'entrée en vigueur : le 01 décembre 2023</b>
    </div>
  </div>
</div>

<!--<app-ads></app-ads> -->

<!--<app-footer></app-footer> -->
