// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  strapi_url: "https://strapi.hadeen-place.fr/",
  // sousDomaine: 'http://localhost:4200',
  sousDomaine: "https://site.hadeen-place.fr",
  googleLog:
    "285698818452-lom8vnn7p5sjlf9hr86ci9fbhqat3sms.apps.googleusercontent.com",
  facebookLog: "3560711240655506",
  ws_url: "https://apipreprod.hadeen-place.fr/api/v1/",
  socket_url: "https://apipreprod.hadeen-place.fr/ws-hadeen",
  keys: "pk_test_51HTl4ZD1SZuRZVEhOuyy4QxEqVApakNf5rg4wCUdXCsV9L506xQcziPCyEiiL9yUCZwSyg0M1RCzDHjlL8zZSBFj00YCXIHlKj",
  gtag_key: "UA-180350306-2",
  // apiUrlSmodin: 'https://rewriter-paraphraser-text-changer-multi-language.p.rapidapi.com/rewrite',
  // apiKeySmodin: 'e43a5f997cmsh9496dc57be2348bp18e0a7jsn7fea13ea2d0f',
  // apiHostSmodin: 'rewriter-paraphraser-text-changer-multi-language.p.rapidapi.com',
  service_format: (width, height, url) =>
    `https://service-pic.vatilab.com/unsafe/fit-in/${width}x${height}/filters:format(webp)/${url}`,
};

// export const environment = {
//   production: true,
//   strapi_url: 'https://strapi.hadeen-place.fr/',
//   sousDomaine: 'https://hadeen-place.fr',
//   googleLog: '285698818452-5r3q2rou3gl36gecnr4e4ger792hlroq.apps.googleusercontent.com',
//   facebookLog: '380730929776448',
//   ws_url: 'https://api.hadeen-place.fr/api/v1/',
//   socket_url: 'https://api.hadeen-place.fr/ws-hadeen',
//   keys: 'pk_live_51HTl4ZD1SZuRZVEhZVhbYQ2zgHyJrTMl5eouCMcoVj3tEnWmyoVHiB21Y9fMb2vdvHdl2BIn37HJ4cykayf4NT8j00jV3b7VzC',
//   gtag_key: 'UA-180350306-5',
//   // apiUrlSmodin: 'https://rewriter-paraphraser-text-changer-multi-language.p.rapidapi.com/rewrite',
//   // apiKeySmodin: 'e43a5f997cmsh9496dc57be2348bp18e0a7jsn7fea13ea2d0f',
//   // apiHostSmodin: 'rewriter-paraphraser-text-changer-multi-language.p.rapidapi.com',
//   service_format: (width, height, url) => `https://service-pic.vatilab.com/unsafe/fit-in/${width}x${height}/filters:format(webp)/${url}`
// };

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
