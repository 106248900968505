<!-- <div class="top-header" *ngIf="from == 'page' && (etape === 1 || etape === 2)">
  <hr>
  <div class="container">
    <div class="header">
      <ul class="breadcrumb">
        <li><a>Hadéen-place</a></li>
        <li>panier</li>
        <li>validation commande</li>
      </ul>
    </div>
    <div class="main-header" style="margin-top: -10px;">
    </div>
  </div>
</div> -->

<div style="cursor: pointer;" *ngIf="etape === 1 || etape === 2">
  <div class="row p-2">

    <!-- <div class="col-12" *ngIf="from == 'page'">
      <div class="uk-grid-small col-md-7 mb-3 pt-2" uk-grid style="height: 50px;">
        <div class="uk-width-expand pt-1" uk-leader>
          <span style="border: 1px solid #d9d9d9; border-radius: 50%; padding: 5px 11px;">1</span> Livraison
        </div>
        <div class="pt-1">
          <span style="border: 1px solid #d9d9d9; border-radius: 50%; padding: 5px 9px;">2</span> paiement
        </div>
      </div>
    </div> -->

    <div class="col-12">
      <!-- <div class="col-12 col-md-7 col-lg-7 p-0 p-md-3"> -->

      <!-- Etape 1 -->
      <ng-template #step1 [ngIf]="etape === 1">
        <div class="pt-4" style="border: 1px solid #D6D6D6; background-color: #fff;">
          <form [formGroup]="firstFormGroup">
            <div class="row">
              <div class="col-12 col-md-10 offset-md-1">
                <h4 class="text-center">Vos coordonnées pour la livraison</h4>
                <p class="text-center">Les champs marqués d’une * sont obligatoires</p>

                <mat-form-field class="example-full-width col-12 CInput" appearance="fill" [floatLabel]="'auto'">
                  <mat-label>Prénom</mat-label>
                  <input matInput placeholder="Prénom" formControlName="adresseCtrlPrenom" required>
                </mat-form-field>
                <mat-form-field class="example-full-width col-12 CInput" appearance="fill" [floatLabel]="'auto'">
                  <mat-label>Nom</mat-label>
                  <input matInput placeholder="Nom" formControlName="adresseCtrlNom" required>
                </mat-form-field>
                <mat-form-field class="example-full-width col-12 CInput" appearance="fill" [floatLabel]="'auto'">
                  <mat-label>Adresse</mat-label>
                  <input matInput ngx-google-places-autocomplete [options]='options'
                    (onAddressChange)="handleAddressChange($event)" placeholder="Adresse"
                    formControlName="adresseCtrlAdress" required>
                </mat-form-field>
                <mat-form-field class="example-full-width col-12 CInput" appearance="fill" [floatLabel]="'auto'">
                  <mat-label>Complement d'adresse</mat-label>
                  <input matInput placeholder="Complement d'adresse" formControlName="adresseCtrlComplement">
                </mat-form-field>
                <mat-form-field class="example-full-width col-12 CInput" appearance="fill" [floatLabel]="'auto'">
                  <mat-label>Code postal</mat-label>
                  <input matInput placeholder="Code postal" formControlName="adresseCtrlCode" required>
                </mat-form-field>
                <mat-form-field class="example-full-width col-12 CInput" appearance="fill" [floatLabel]="'auto'">
                  <mat-label>Ville</mat-label>
                  <input matInput placeholder="Ville" formControlName="adresseCtrlVille" required>
                </mat-form-field>
                <div class="col-12">
                  <div class="field text-left col-12 CInput pb-2" style="border: 1px solid #d9d9d9;">
                    <ngx-intl-tel-input inputId="numero" [cssClass]="'custom control-form CInput country-tel-code'"
                      [preferredCountries]="[CountryISO.France, CountryISO.UnitedKingdom]"
                      [enableAutoCountrySelect]="true" [enablePlaceholder]="true" [searchCountryFlag]="true"
                      [searchCountryField]="[SearchCountryField.Iso2, SearchCountryField.Name]"
                      [selectFirstCountry]="true" [selectedCountryISO]="CountryISO.France" [maxLength]="15"
                      [tooltipField]="TooltipLabel.Name" [phoneValidation]="false" [separateDialCode]="false"
                      name="phone" formControlName="adresseCtrlNumero" (input)="ngModelChange($event)">
                    </ngx-intl-tel-input>
                    <label class="m-0 mt-2" for="numero" style="font-size: 12px;">Numéro de téléphone*</label>
                  </div>
                </div>

                <div class="col-12 mb-md-4">
                  <button class="h-btn-dore rounded-0 w-100 pt-2 pb-2" (click)="setAdresseLivraison()">VALIDER L'ADRESSE</button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </ng-template>



      <!-- Etape 2 -->
      <ng-template #step2 [ngIf]="etape === 2">
        <!-- <div class="pt-4" style="border: 1px solid #D6D6D6; background-color: #fff;">
        </div> -->
      </ng-template>

    </div>

    <!-- <div class="col-md-5 col-lg-5 col-12 p-0 pl-md-3 pr-md-3 pt-md-3">
      <div style="background-color: #F9F9F9; border: 1px solid #D6D6D6;">
        <ul uk-accordion class="m-0">
          <li>
            <a class="uk-accordion-title p-3" href="#">
              <span>Ma commande</span>
              <span style="float: right; font-size: 14px; margin-top: 6px;">Afficher le détail</span>
            </a>
            <div class="uk-accordion-content m-0" style="background-color: #ffffff;">
              <div class="p-3" style="border-bottom: 1px solid #D6D6D6;"
                *ngFor="let itemPanier of paniers; let ind = index">
                <div class="row">
                  <div class="col-3 col-md-2 pr-0 pr-md-2">
                    <img width="auto" height="auto" [src]="itemPanier.sous_produit.produit.image" class="m-md-auto"
                      style="display: block; height: 68px;" alt="">
                  </div>
                  <div class="col-9 col-md-10">
                    <div class="row">
                      <div class="col-12 col-md-4 p-0">
                        <h4 style="font-size: 15px; overflow: hidden;">{{ itemPanier.sous_produit.produit.model }}</h4>
                      </div>
                      <div class="col-7 col-md-5 mt-sm-2 mt-lg-0">
                        <h5 style="font-size: 15px;">Quantité:
                          <span style="font-size: 15px;">
                            <span style="padding: 10px; background-color: #fbfafa;"> {{ itemPanier.quantite }} </span>
                          </span>
                        </h5>
                        <h5 style="font-size: 15px;" *ngIf="itemPanier.sous_produit.taille">Taille: {{
                          itemPanier.sous_produit.taille.valeur }}</h5>
                        <p *ngIf="itemPanier.sous_produit.couleur">
                          <span style="font-size: 15px;">Coleur: </span>
                          <span>
                            <div [ngStyle]="{'background-color': itemPanier.sous_produit.couleur.codeHexa}"
                              style="width: 18px; height: 18px; border: 1px solid #d9d9d9;display: inline-block; margin-top: 2px; margin-right: 5%;">
                            </div>
                          </span>
                        </p>
                      </div>

                      <div class="col-5 col-md-3 pl-0 text-right">
                        <h3 style="font-size: 16px;">
                          {{ this.commonService.calculPrix(itemPanier.sous_produit.produit, itemPanier.quantite) | number : '1.2-2' }} €
                        </h3>
                        <a style="font-size: 16px;" (click)="removePanier(ind, itemPanier.id)">Supprimer</a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </li>
        </ul>

        <div class="p-3" style="border-top: 1px solid #D6D6D6">
          <span>Total articles ({{ paniers.length }})</span>
          <span style="float: right;">{{ getTotalArticle() | number : '1.2-2' }} €</span>
        </div>
        <div *ngIf="commonService.codePromo.isValid" class="p-3" style="border-top: 1px solid #D6D6D6">
          <span>Total promotion </span>
          <span style="float: right;">-{{ getTotalPromo() | number : '1.2-2' }} €</span>
        </div>
        <div class="p-3" style="border-top: 1px solid #D6D6D6">
          <span>{{fraisLivraisonTotal === 0 ? 'Livraison incluse' : 'Frais de livraison'}}</span>
          <span style="float: right;" *ngIf="fraisLivraisonTotal > 0">{{ fraisLivraisonTotal }} €</span>
        </div>
        <div class="p-3" style="border-top: 1px solid #D6D6D6">
          <span>Total TTC</span>
          <span style="float: right;">{{ getTotalArticle() - getTotalPromo() + fraisLivraisonTotal | number : '1.2-2' }}
            €</span>
        </div>
      </div>

    </div> -->
  </div>
</div>

<!-- <hr> -->
<!-- <app-ads></app-ads> -->
