<div class="container my-5" style=" background-color: '#F6FAF0'">
    <app-cgu></app-cgu>
    <!--     
    <div class="row">
        <div class="col-12 text-center">
            <h2>Conditions générales d’utilisation et de vente</h2>
        </div>

        <div class="col-12">
            <b>
                Merci de lire ces conditions attentivement avant d'utiliser nos Services.
                En utilisant nos services, vous acceptez d'être soumis aux présentes conditions.
            </b>
            <p>
                En cas de litige, seules les conditions générales d'utilisation de la date du conflit seront valides.
                Nous nous réservons le droit de modifier ces conditions générales sans préavis, les
                utilisateurs recevront chaque mise à jour par newsletter et par bannière pour les
                nouveaux utilisateurs. En cas de refus d’acceptation des conditions générales d’utilisation de la part de
                l’utilisateur , la navigation sur nos services sera limitée.
                Nous nous réservons le droit d'interrompre à tout moment tout ou partie de nos services sans avertissement
                préalable. La navigation sur nos serveurs est accessible
                24h/24h et 7j/7j sauf en cas de maintenance. La société SAS HADEEN ne pourra
                être tenue responsable de l’indisponibilité de ses services en cas d’interruption volontaire ou non, sécurité ou
                accessibilité.
            </p>
        </div>

    </div>

    <div class="row">
        <div class="col-12">
            <h4>1° Définitions</h4>

            <h6>- Acheteur : désigne un utilisateur qui effectue un achat sur nos services</h6>
            <h6>- Vendeur : désigne un utilisateur qui liste et vend des articles sur notre marketplace via nos services</h6>
            <h6>- Utilisateurs : désigne toute personne utilisant nos services web et application.</h6>
            <h6>- Compte : compte personnel regroupant les coordonnées de l’utilisateur.</h6>
            <h6>- Nous et notre : désigne les responsables de SAS HADEEN.</h6>
            <h6>- Contenu : toutes les données fournies par l’utilisateur sur nos services</h6>
            <h6>- Site : désigne l’ensemble de notre site situé à l’URL "www.hadeen-place.fr"</h6>
            <h6>- Market-place : désigne notre plate-forme de vente</h6>
            <h6>- Conditions d’utilisation : désigne l’ensemble des conditions suivantes qui s’appliquent à toutes personnes utilisant nos services.</h6>
            <h6>- Nos services : désigne l’ensemble de notre site : <a>www.hadeen-place.fr</a> et de notre application hadeen</h6>
            <h6>- Nous contacter : par email : et téléphone :</h6>

        </div>

        <div class="col-12 mt-2">
            <h4>2° Présentation de la société</h4>
            <p>
                Le site <a href="https://www.hadeen-place.fr" target="blank">https://www.hadeen-place.fr</a> et l’application Hadeen sont édités par la société par actions simplifiées SAS
                HADEEN. Son siège social se situe au :
                82 ALLEE DU PETIT PONT, 83240 CAVALAIRE SUR MER et inscrite au registre
                du commerce et des sociétés de Frejus 884015983 RCS
                Vous pouvez nous contacter en écrivant au siège social ou par Mail : service.client@hadeen-plce.fr
                Le président de la société est Monsieur Kevin Corna.
                Toute personne ayant accès à internet est autorisée à utiliser nos services gratuitement.
                Notre market place est une plate forme multi vendeurs, donnant la possibilité à des
                TPE et PME françaises de vendre des produits de la vie quotidienne.
            </p>
        </div>

        <div class="col-12 mt-2">
            <h4>3°Création de compte</h4>
            <p>
                La création d'un compte vous sera demandée pour la création d'une liste, valider
                un panier ou contacter un vendeur. Les données récoltées lors de la création du
                compte comme vos coordonnées, votre mot de passe, reste strictement confidentiel. Vous pouvez à tout moment modifier
                ou supprimer ces données dans le pictogramme « MON COMPTE ».
                Pour s’inscrire sur Hadeen, l’Utilisateur devra créer un compte avec son adresse
                email ou en se connectant avec Facebook ou Google.
                Tous les champs demandés sont obligatoires afin de finaliser l’ouverture du compte : </p>
                <ul class="px-0">
                    <li>- Genre</li>
                    <li>- Identité</li>
                    <li>- Date de naissance</li>
                    <li>- Adresse mail</li>
                    <li>- Mot de passe</li>
                </ul>

            <p>     Pour modifier vos données personnelles il vous suffit de nous écrire via la messagerie interne ou par courrier à
                l'adresse du siège social SAS HADDEN : 82 allée du
                petit pont, 83240 CAVALAIRE. Un justificatif d’identification vous sera demandé.
                Un délai de plusieurs semaines sera nécessaire pour le traitement des modifications pour des raisons techniques.
                Toutes données recueillies sur nos services, doit être fournies par l’utilisateur luimême (personne majeur et
                capable juridiquement) afin d’authentifier la véracité des
                données.
                En cas d'utilisation frauduleuse, de perte, ou tout autre détournement de vos identifiants et mots de passe, nous
                vous conseillons fortement de contacter SAS HADEEN et de les avertir de l’usurpation d’identité.

            </p>
        </div>

        <div class="col-12 mt-2">
            <h4>4°1 Devenir Vendeur</h4>
            <p>
                Seuls les professionnels (auto-entreprise ou société) peuvent ouvrir un compte
                « devenir vendeur » et seulement sur la version web de notre site.
                Des informations et documents spécifiques seront demandés pour finaliser la création d’un compte vendeur :
            </p>
                <ul class="px-0">
                    <li>- Logo</li>
                    <li>- Nom de société</li>
                    <li>- Siret</li>
                    <li>- Tva</li>
                    <li>- Code postal</li>
                    <li>- Ville</li>
                    <li>- Pays</li>
                    <li>- Email</li>
                    <li>- Numéro de téléphone</li>
                    <li>- Description de la boutique</li>
                    <li>- Conditions de retour et remboursement</li>
                    <li>- Nom et adresse de l’utilisateur</li>
                    <li>- Part dans la société</li>
                    <li>- Fonction</li>
                    <li>- Kbis</li>
                    <li>- Pièce d’identité</li>
                </ul>
            <p>
                Une fois finalisée son inscription sur le site web Hadeen-place, un Vendeur se voit
                attribuer une e-boutique.

            </p>
        </div>

        <div class="col-12 mt-2">
            <h4>4°2 Vendre un article</h4>
            <p>
                Le Vendeur s'engage à ce que tous les articles mis en vente soient disponible immédiatement et qu’il dispose la
                pleine et entière propriété et capacité de vente. Le
                Vendeur s'engage à ce que les Articles soient neufs, dans leur emballage d’origine
                et avec les étiquettes originales (sauf exception pour les articles personnalisés).
                Le Vendeur atteste qu’il ne contrevient en aucune façon aux lois et réglementations
                en vigueur ou ne porte pas atteinte aux droits de tiers en proposant un article à la
                vente via nos services.
            </p>
            <p>
                Dans son Annonce, le Vendeur s’engage à décrire de façon complète, sincère et
                fidèle l'Article en vente selon le formulaire proposé par le Site. La description de
                l'Article doit correspondre à ses caractéristiques effectives.
                Le Vendeur s’engage à ne mettre en ligne que des photographies fidèles, précises
                et non retouchées du Produit, et il garantit détenir l’ensemble des droits qui y sont
                attachés. SAS HADEEN se réserve le droit de supprimer toutes photographies
                qu’elle juge de qualité insuffisante ou inutiles à la bonne présentation de l’article.
                Le Vendeur atteste que l’origine, l’état et les caractéristiques du produit qu’il propose à la vente sur le Site
                sont conformes à la description qui en est faite dans la
                Fiche Produit et que le produit proposé à la vente n’est pas un article contrefaisant.
                SAS HADEEN se réserve le droit de demander au Vendeur de fournir tous les documents de nature à justifier
                l’authenticité l’Article proposé à la vente et/ou l’origine
                de celui-ci.
            </p>
            <p>
                Les Utilisateurs s'interdisent de vendre ou d'acquérir directement ou indirectement
                par l'intermédiaire du Site des produits portant atteinte à des droits de propriété intellectuelle (articles
                contrefaits) ou à des réseaux de distribution sélective.
                SAS HADEEN se réserve le droit de supprimer la Fiche Profil et/ou la Fiche Produit
                d’un Vendeur dans l’hypothèse où il ne justifierait pas valablement de ses droits de
                propriété sur le ou les Produits référencés sur le Site et/ou de l’origine de celuici/ceux-ci. L’Utilisateur ne
                pourra revendiquer indemnisation en cas de suppression
                de ces informations par SAS HADDEN pour les raisons évoquées ci- dessus.
                L’Utilisateur est le seul responsable de la mise en vente d’un produit qui ferait l'objet d'une interdiction de
                vente et/ou qui, contreviendrait aux règlementations en vigueur ou porterait atteinte aux droits de tiers.
            </p>
            <p>
                Les Utilisateurs s'engagent à informer SAS HADEEN dès qu'ils constateront qu'une
                Annonce n'est pas conforme aux dispositions du présent article.
                Le Vendeur s’engage à ne mettre qu’une annonce par article mis en vente. SAS
                HADEEN se réserve le droit de supprimer les annonces qui sembleraient multiples
                pour un seul et même article.
            </p>
            <p>
                Le Vendeur détermine le Prix de vente de l'Article sous sa seule responsabilité.
                SAS HADEEN attire notamment l'attention du Vendeur sur le fait qu'il est de sa responsabilité de ne pas fixer un Prix
                manifestement excessif.
                Le Vendeur doit décrire précisément l’ensemble des différentes caractéristiques de
                l'Article et mentionne son Prix, sur la Fiche Produit. Il n’y a ni de prix minimum ni de
                prix maximum, cependant le prix de l’article doit inclure la totalité des frais d'envoi
                et d’assurance de l’Article.
            </p>
            <p>
                Une fois la Fiche Produit mise en ligne, le Vendeur doit se connecter régulièrement
                à son compte afin de prendre connaissance de la liste des transactions en cours,
                les surveiller et maintenir son inventaire à jour
                SAS HADEEN se réserve le droit de solliciter le Vendeur afin qu'il modifie et/ou
                supprime son annonce et/ou de le faire elle-même directement, notamment si le
                vendeur n'a pas publié son annonce dans la catégorie adéquate ou s'il a indiqué
                ses coordonnées dans son annonce. Le Vendeur s'engage à ne publier que des
                photos des produits qu'il a lui-même prises. SAS HADEEN se réserve le droit de
                supprimer du site toute photo qui n'aurait pas été prise par le vendeur.
            </p>

        </div>

        <div class="col-12 mt-2">
            <h4>4°3 Envoyer un article</h4>
            <p>
                Le Vendeur s'engage à envoyer l'Article commandé convenablement emballé dans
                les 72 heures suivant la date d’acceptation de la Commande et à communiquer à
                l’acheteur le numéro de suivi du transporteur ainsi que la ou les photos de l’Article
                et du colis avant l’expédition. Le Vendeur peut contacter l’Acheteur via le système
                de messagerie proposé par SAS HADEEN pour avoir des renseignements supplémentaires pour la réalisation de livraison
                (par exemple le numéro de téléphone).
                Les coordonnées de l'Acheteur doivent être utilisées uniquement pour les besoins
                de l'exécution de la commande ainsi que des obligations légales qui s'y rattachent.
                Le Vendeur doit conserver la preuve d’envoi du Colis et une copie de la facture de
                la commande de l’Article pendant un délai de trente (30) jours à compter de ladite
                expédition.
            </p>
            <p>
                Le Vendeur est le seul responsable vis-à-vis de l’Acheteur de la perte, du vol ou de
                la dégradation de l’Article ou des Articles expédiés.
            </p>
            <p>
                L'Acheteur s'engage à posséder une boîte aux lettres conforme aux recommandations des services postaux (dimension de la boîte aux lettres, indication des coordonnées des résidents...) Afin de réduire les risques de perte ou de mauvaise distribution lors de la livraison, et de permettre le suivi de l'expédition de l’Article (l’envoi doit se faire obligatoirement par un transporteur qui propose le suivi du Colis).
                SAS HADEEN préconise au Vendeur la souscription d’une assurance auprès du
                transporteur choisi équivalente au montant correspondant au prix de son Article.
                SAS HADEEN décline e toute responsabilité lors de la livraison et ce, peu importe le
                transporteur sélectionné.
            </p>
            <p>
                SAS HADEEN ne peut être tenu responsable des délais, des prestataires choisis
                ou de la qualité de livraison choisi par le vendeur.
            </p>
            <p>
                Ni le vendeur ni SAS HADEEN ne pourront être tenus responsables en cas de non
                livraison de la commande de la part du prestataire de transport due à une mauvaise ou incomplète information
                concernant l’adresse de livraison renseignée par
                l’utilisateur ou en cas de force majeur.
            </p>
            <p>
                A défaut d’expédition de la commande et de communication au Service Client du
                numéro de suivi dans un délai de sept (7) jours après la confirmation de disponibilité de l’article, la vente sera
                annulée et l’Acheteur sera remboursé du montant de la
                commande de l’article concerné
            </p>
            <p>
                Si l’Acheteur ne reçoit pas ses Produits dans les trente (30) jours suivant la date de
                la Commande il peut annuler son contrat par lettre recommandée avec accusé de
                réception au siège social de SAS HADDEN , à condition qu’il ait préalablement sollicité SAS HADEEN de remédier à la
                situation dans un délai raisonnable et que la
                livraison n’ait pas été effectuée dans ledit délai supplémentaire.
            </p>
        </div>

        <div class="col-12 mt-2">
            <h4>4°4 Eboutique et fonctionnalité supplémentaires</h4>
            <p>
                La eboutique rassemble les articles mis en vente par un seul et même vendeur.
                Elle est en quelque sorte la vitrine d’un vendeur. Il a la possibilité de mettre en
                avant certain de ses articles ou directement sa eboutique grâce aux fonctionnalités
                supplémentaires payantes.
                Le Boost est une fonctionnalité supplémentaire payante automatisée qui permet de
                donner plus de visibilité à des articles sélectionnés afin de remonter en tête de catégorie, ou des sous univers ou
                d’univers. Il est également possible de mettre en
                avant des articles ou eboutique sur la Page d’acceuil grâce aux différents espaces
                publicitaires (il existe plusieurs tarifs et plusieurs durées).
                Ces Services supplémentaires donnent lieu à un paiement des commandes de ces
                mises en avant. Le vendeur devra fournir ses coordonnées bancaires afin de les
                payer avant la mise en avant effective. Si un compte ou une carte bancaire appartenant à une tierce personne sont
                enregistrés sur le Site, SAS HADEEN peut demander des pièces justificatives d’identité. La facture de cette
                prestation sera disponible dès la mise en place de la mise en avant.
                SAS HADEEN pourra modifier la Liste des Tarifs à tout moment. La société SAS
                HADEEN ne peut-être tenu responsable en cas de non commande malgrè la mise
                en avant. Ces services supplémentaires ne sont que des mises à disposition sans
                aucune obligation de résultat de commande.
            </p>
        </div>

        <div class="col-12 mt-2">
            <h4>4°5 Paiement et rémunération</h4>
            <p>
                Le vendeur recevra le montant de la commande dans un délai de 15 jours calendaire à partir de la date de réception
                du produit, signature ou numéro de traçage
                colis faisant foi (délai de rétractation légal). Le montant que percevra le vendeur
                correspondra au montant de ou des articles vendus déduis de la commission de
                SAS HADEEN qui est de 12% par défaut. Cette commission correspond aux frais
                de fonctionnement du site, des frais annexes et rémunération ainsi que de la commission du prestataire de paiement
                sécurisé STRIPE.
            </p>

            <p>Par exemple un acheteur passe une commande de 100€ à un vendeur, 15 jours
                après réception de la commande, le vendeur percevra 88€ directement sur son
                compte bancaire.</p>
            <p>La facture de commission sera alors disponible.</p>
        </div>

        <div class="col-12 mt-2">
            <h4>5°1 Transaction</h4>
            <p>
                Tout utilisateur doit être majeur et capable juridiquement pour conclure une vente
                ou un achat.
            </p>
            <p>
                Toutes les transactions se feront via nos services. L’utilisateur aura plusieurs choix
                concernant le paiement et les livraisons en fonction des options proposées par les
                vendeurs concernés par la transaction. La transaction sera définitivement validée
                qu’après acceptation des CGU, paiement total du montant de la commande et
                après la validation de la part du vendeur. Les prix affichés sont édités en euros,
                Toutes Taxes Comprises et frais de port inclus.
            </p>
            <p>
                Nous ne pouvons être tenus responsables en cas de mauvaises informations concernant un produit vendu par les
                vendeurs présents sur notre site. Les informations disponibles sur nos services ne sont fournies qu'à titre indicatif.
            </p>
            <p>

            </p>
            <p>Le Vendeur s'engage à confirmer ou infirmer la commande dans un délai de 24
                heures suivant la réception du courrier électronique de SAS HADEEN l'informant
                que l'article qu'il a mis en vente sur le Site a trouvé un acheteur. Le Vendeur peut
                accepter la commande en consultant son Compte sur le Site ou l’application. Son
                acceptation est ferme et irrévocable. A compter de l'acceptation du vendeur, la
                vente est conclue et réputée parfaite.A défaut de réponse de la part du vendeur,
                passé le délai de 24 heures vaudra infirmation de la commande, et le contrat de
                vente ne sera pas conclu.
            </p>
            <p>
                Dès lors que le Vendeur accepte la commande, l'acheteur accepte que ses coordonnées (nom, prénom et adresse de
                livraison) soient révélées au vendeur. Les
                vendeurs s'engagent à garder confidentielles ces informations et à ne pas les divulguer. Ils s’engagent
                également à ne pas porter atteinte au respect de la vie privée
                des autres Utilisateurs et à ne pas leur envoyer de message, d'objet, de documentation notamment publicitaire,
                par quelque moyen que ce soit (notamment par voie
                postale, téléphonique ou électronique).
            </p>
            <p>
                Dès lors que le vendeur refuse la commande, la transaction est alors annulée, et
                aucun débit de la carte bancaire de l'Acheteur n'aura lieu. En cas de paiement par
                PayPal le remboursement sera crédité du montant débité. Le montant crédité apparaîtra sur le compte PayPal dans
                un délai de 48h à compter de l'opération de recrédit. Il en est de même pour les paiements par CB.
            </p>
            <p>
                En cas de commande personnalisée, nous invitons les utilisateurs à contacter la
                messagerie interne du vendeur afin d’être d’accord sur la personnalisation.
            </p>
            <p>
                Pour toute question relative à une commande, l’Acheteur et le Vendeur peuvent
                nous contacter à l’adresse service.client@hadeen-place.fr ou via notre Application
                dans la rubrique Mon Compte > Nous Contacter.
            </p>

        </div>

        <div class="col-12 mt-2">
            <h4>5°2 Mode de paiement</h4>
            <p>
                Afin de finaliser une transaction plusieurs modes de paiement vous sont proposés
                grâce a notre prestataire de service Stripe <br>
                Toutes transaction est donc soumise à l’Accord sur les comptes Stripe Connected
                (Stripe Connected Account Agreement), incluant les Modalités de service de Stripe
                (“Conditions Générales d’Utilisation Stripe” - “Stripe Services Agreement”.) <br>
                En agréant aux présentes Conditions Générales d’Utilisation ou en continuant à
                opérer en tant que Vendeur sur nos services, vous acceptez d’être lié aux Conditions Générales d’Utilisation Stripe,
                celles-ci pouvant être occasionnellement modifiées. Afin de finaliser une transaction plusieurs modes de paiement
                vous sont proposés grâce a notre prestataire de service Stripe. <br>
                Les données bancaires récoltées lors du paiement sont protégées et cryptées par
                ce prestataire, ni SAS HADEEN ni les vendeurs n’ont accès à ces données.
                En cas de refus de paiement de la part du prestataire Stripe, la transaction sera annulée. Jusqu'au paiement complet de
                la commande, les produits restent la propriété des vendeurs. <br>
                L'utilisateur atteste sur l'honneur d'être en capacité d'utiliser le moyen de paiement
                ainsi que la véracité de ses données personnelles. <br>
                L'Acheteur peut payer par : <br>
                - Carte Bancaire (Visa, Mastercard ou American Express) via notre prestataire sécurisé Stripe qui utilise le protocole
                SSL (Secure Socket Layer), cryptant par logiciel les informations transmises et assurant qu'aucun tiers (ni SAS HADEEN)
                ne peut en prendre connaissance au cours du transport sur le réseau. SAS HADEEN peut vérifier auprès de l’Acheteur, par
                téléphone, par courrier électronique ou courrier postal, pour valider la commande et lui demander des justificatifs
                d'identité (copie de la carte d'identité et/ou justificatif de domicile). Dans l'attente de la réception de ces
                documents, SAS HADEEN se réserve le droit de
                suspendre ou de supprimer une commande. <br>

                SAS HADEEN ne peut être tenue responsable en cas d’usage frauduleux des
                moyens de paiement utilisés lors d’une ou plusieurs commandes.
                La sauvegarde du moyen de paiement, comme la sauvegarde de la carte de bancaire proposée par notre prestataire de
                service Stripe, permet de passer une commande plus rapidement et plus simplement, tout en offrant un niveau de sécurité
                maximale. Pour cela, l’Acheteur devra cocher la case « J'autorise Stripe à mémoriser mes coordonnées financières pour
                éviter de les saisir lors de mes prochaines
                commandes chez ce marchand ». Ces coordonnées bancaires ne seront pas conservées par SAS HADEEN mais par Stripe.

            </p>
        </div>

        <div class="col-12 mt-12">
            <h4>5°3 Réception commande et avis</h4>
            <p>
                L'Acheteur doit confirmer la réception de l'Article et sa conformité dans un délai de
                48h suivant la réception de l'Article (y compris week-ends et jours fériés, sans
                compter le jour de la livraison). <br>
                Si au-delà de 48h après réception de l'Article, l'Acheteur n;’a pas notifié la réception
                ainsi que la conformité, SAS HADEEN procèdera à la finalisation de la vente et au
                paiement du Vendeur des sommes correspondant à la transaction. <br>
                L'Acheteur et le Vendeur acceptent que SAS HADEEN calcule ce délai de 48h à
                partir des informations fournies par le transporteur. <br>
                SAS HADDEN décline toute responsabilité en cas de mauvaise information fournie
                par le transporteur ou vendeur. <br>
                En présence d'une anomalie apparente (avarie, Produits manquants, colis endommagé, Produits cassés, etc.),
                l’Acheteur doit impérativement porter sur le bon de livraison des réserves précises : indication du nombre de colis
                et/ou Produits manquants et/ou endommagés et description détaillée du dommage éventuel (emballage ouvert ou déchiré,
                Article détérioré ou manquant, etc.). Il est indispensable que l’Acheteur conserve les éléments de l’expédition dans l'état dans lequel ils lui
                ont été livrés (accessoires, pièces reçues avec ces Produits dans leur état d’origine). Nous invitons l’acheteur à
                prendre en photo le colis dès sa réception pour justifier l’ouverture du litige.
                Dans l'hypothèse ou faute d’être présent lors de la livraison ou d'être allé chercher
                l'Article, et que le colis est réexpédié au Vendeur par le transporteur, l'Acheteur accepte que les frais d'expédition
                réglés par le Vendeur ainsi que toute autre taxe,
                frais ou commission, soient intégralement reversés au Vendeur. Le Vendeur s’engage à communiquer dans les plus brefs
                délais à SAS HADEEN et à l’Acheteur le
                retour de l’Article. Le Vendeur s’engage à communiquer à l’acheteur si le remboursement de l’Article retourné, net de
                toute taxe, frais ou commission, peut être effectué. L’article (hors frais de port et taxe et frais) sera remboursé.
                <br>
                Si le Colis serait indiqué comme “livré” alors que l’Acheteur déclarerait ne pas
                l’avoir reçu, ce dernier s’engage à ouvrir une réclamation auprès du transporteur
                et/ou de fournir toute information demandée par le transporteur afin de mettre en
                application l’assurance sur l’expédition contracté par le Vendeur. <br>
                Dans l’hypothèse où le colis serait perdu par les services postaux, le Vendeur s’engage à rembourser le Prix de la
                Transaction à l’Acheteur. Il reviendra au Vendeur
                de se retourner contre son transporteur. <br>
                La durée moyenne d’une enquête est de trente (30) jours. Si la perte ou l’avarie est
                constatée, SAS HADEEN remboursera l’Acheteur. Ce remboursement s’effectuera
                dans un délai de cinq (5) jours ouvrés à compter de la confirmation de la perte ou
                de l’avarie du Colis par le transporteur. Dans l’hypothèse où le Colis est déclaré à
                tort “réceptionné par l’Acheteur”, il appartient à l’Acheteur d’engager une procédure
                en contestation. Pour se faire, l’Acheteur doit contacter le Service Client du transporteur dans un délai de deux (2)
                jours maximum suivant la dernière mise à jour du
                statut du Colis. <br>
                Le Vendeur s’engage à communiquer à l’Acheteur toute information nécessaire
                pour l’ouverture d’une contestation ou litige auprès du transporteur.
                SAS HADEEN met à disposition de l’Acheteur et du Vendeur un service de messagerie afin de faciliter les échanges pour
                tous les litiges, réclamation, personnalisation. <br>
                SAS HADEEN ne peut être tenue responsable de la perte, du vol, du retard , dégradation ou endommagement dans
                l'acheminement des Produits. <br>
                Dans l'hypothèse où un litige surviendrait entre le Vendeur et l'Acheteur, SAS HADEEN pourra bloquer les fonds versés
                par l’Acheteur jusqu'à la résolution du litige.
            </p>
            <p>
                En cas de transaction vous êtes invités à noter et laisser des commentaires concernant les vendeurs ainsi que les
                produits ou services achetés via nos services. Ces
                commentaires ne doivent en aucun cas entraver la loi ou être de mauvaise foi.
                Tous commentaires à caractère raciste, illégal, injurieux, diffamatoires, harcelant,
                hors de propos sera supprimé. <br>
                La société HADDEN se réserve le droit de supprimer tout commentaire, sans notification préalable ni motif. <br>
                En cas de non respect des présentes conditions générales d'utilisation, nous nous
                réservons le droit d'interrompre de manière temporaire ou définitive l'accès aux services à l'utilisateur concerné. Tout
                utilisateur du site certifie avoir 18 ans révolus et
                être capable légalement (sans tutelle ou curatelle par exemple).
            </p>
        </div>

        <div class="col-12 mt-12">
            <h4>6° Retour et Remboursement</h4>
            <p>
                Conformément à la loi l'utilisateur peut exercer son droit de rétractation sans se justifier (un motif sera demandé à titre indicatif), dans un délai de 14 jours calendaires
                à partir de la date de réception du produit, signature ou numéro de traçage colis faisant foi. Le droit de rétractation s'applique également aux articles soldés, d'occasions ou déstockés. Le paiement des frais de port retour sont à la charge de l’acheteur. Nous n'intervenons en aucun cas sur la gestion des retours. L'utilisateur contacte directement le vendeur via la messagerie interne dans « mon compte » puis
                « commande » et enfin contacter le vendeur pour un retour. Un formulaire de retour
                vous sera fourni. <br>
                Le retours des articles devront être effectués dans leur emballage d'origine est
                complet, afin de permettre leur recommercialisation
                Cependant les retours des produits suivants sont exclus :
            </p>
            <ul class="px-0">
                <li>- denrées alimentaires</li>
                <li>- Les produits personnalisés</li>
                <li>- Les produits alcoolisés</li>
                <li>- Les produits santé, cosmétiques, hygiène, sous vêtements dont l’emballage
                    d’origine a été ouvert</li>
            </ul>

            Chaque vendeur définit sa propre politique de retours ou d’échange :
            <ul class="px-0">
                <li>- prise en charge du montant du retour</li>
                <li>- délais de rétractation plus long</li>
                <li>- lieu de prise en charge du retour</li>
                <li>- Échange contre un autre produit</li>
                <li>- Une décote en guise de dédommagement en cas d’article utilisé et endommagé</li>
                <li>- Autre</li>
            </ul>

            <p>
                Nous vous préconisons de lire attentivement les conditions de retour de chacun
                des vendeurs correspondant aux produits que vous souhaitez achetés.
                Le remboursement du montant de la commande ainsi que des frais de port standards s’effectueront dans les 14 jours à compter de l’envoie du retour (numéro de
                tracking faisant foi), par le même moyen de paiement que celui utilisé lors de la
                commande sauf accord préalable du client
                En cas d’articles très encombrant, le vendeur devra fournir un estimation des frais
                de retour. <br>
                Si un défaut apparaît sur le produit après l’achat, la loi sur la garantie prévue par le
                code de consommation vous permet de retourner l’article même si vous n’avez pas
                souscrit à un contrat de garantie. Cette garantie couvre la livraison du mauvais article (non conforme a la description ou impropre à son usage attendu) dans un délai
                de deux ans à compter de la possession de ce produit. En cas de litige, c’est au
                vendeur de prouver que le défaut n’existait pas au moment de la vente. <br>
                Une solution d’échange, de réparation ou de remboursement peut être proposée par le vendeur. <br>
                Les litiges sont directement réglés entre l'acheteur et le vendeur via l'outil de messagerie mis à leur disposition sur le site et l’application.
                Dans tous les cas, l’acheteur est invité à nous faire part de ce désagrément afin
                d’en avertir les autres utilisateurs de ce même produit. <br>
                Cependant la société SAS HADEEN ne peut être tenue responsable de ces garanties.
            </p>

        </div>

        <div class="col-12 mt-12">
            <h4>7° Contenu du site et falsification</h4>
            <p>
                Tout téléchargement , reproduction, utilisation, affichage, modification du contenu
                de notre site doit faire l'objet d'une demande écrite à la SAS HADDEN . Les photos,
                images, textes, liens, videos, marques, logo ou tout autre contenu figurant sur nos
                services sont la propriété de SAS HADEEN et/ou de ses vendeurs. <br>
                L’Utilisateur autorise SAS HADEEN en mettant en ligne son Profil et/ou un Produit :
            </p>
            <ul class="px-0">
                <li>- A retoucher les photos des articles qu'il aura mis en ligne afin d'optimiser leur visibilité.</li>
                <li>- A communiquer sur certains articles qu'il aura mis en vente.</li>
                <li>- A modifier, sauvegarder, reproduire, diffuser et exploiter l’ensemble des éléments, dont son image et/ou ses photos des produits, et/ou mettre en ligne des
                commentaires, en tous formats, par tous moyens et sur tous supports, notamment
                sur le Site et sur les documents commerciaux tels que les « Notifications » et «
                Newsletters », ainsi que sur les sites partenaires de SAS HADDEN. </li>
            </ul>
            <p>
                L’Utilisateur s’engage à ne pas employer sur le Site, notamment par le biais des
                commentaires, des propos contraires aux bonnes mœurs, injurieux, diffamatoires
                ou pouvant porter atteinte à la réputation des autres Utilisateurs. En cas de nonrespect de cette obligation, SAS HADEEN se réserve le droit de clôturer définitivement le compte de l’Utilisateur.
                L’Utilisateur s’interdit de publier des photographies dont le contenu serait inapproprié (nudité, violence, haine…). SAS HADEEN se réserve le droit de supprimer tout
                contenu qu’il jugerait inapproprié ou diffamatoire, et également de supprimer les
                comptes des Utilisateurs.
            </p>
        </div>

        <div class="col-12 mt-12">
            <h4>8° Données personnelles et Cookies</h4>
            <p>
                Les cookies sont de petits fichiers stockés dans le navigateur lors de l’utilisation
                d’un site afin de suivre vos mouvements lors de votre navigation sur ce site pour
                comprendre vos préférences, se souvenir de vos identifiants de connexion, reprendre là où vous avez quitté la navigation ou tout autre personnalisation.
                Les cookies sur notre site ne sont pas utilisés pour révéler votre identité ou tout
                autre information permettant votre identification personnelle. <br>
                En gérant vos cookies vous permettez l'arrêt du ciblage publicitaire si vous le souhaitez. Cependant en supprimant les cookies l’accès vous sera limité aux fonctions
                de bases du site. <br>
                Les cookies publicitaires permettent de vous présenter des offres en adéquation
                avec vos interêts. <br>
                La protection de vos données est essentielle pour nous. Les demandes marquées
                d’un astérisque sont obligatoires afin de vous permettre de continuer une navigation sur notre site sans restriction. Elles nous permettent également de vous inscrire à notre Newsletter, de créer votre compte, finaliser une commande, vous proposer des offres commerciales ciblées , lutte contre la fraude, élaborer des statistiques, des études et des sondages, enquête de satisfaction, afin d’améliorer notre
                site et notre base de donnée. Ces données nous sont également essentielles lors
                de la gestion de votre compte, livraison, retour, remboursement, facturation
                Les données collectées concerne :
            </p>
            <ul class="px-0">
                <li>- Votre identité (nom, prénom, âge)</li>
                <li>- Vos coordonnées (adresse de livraison, facturation, téléphone, mail)</li>
                <li>- Vos données de paiement (protégées par notre prestataire STRIPE)</li>
                <li>- Vos coordonnées web (adresse IP) et géolocalisation</li>
                <li>- Prospection commerciale (acceptation ou refus)</li>
                <li>- Votre navigation sur le site (préférences, ouverture, clic,</li>
                <li>- Consentement à l’utilisation des données</li>
            </ul>
            Certaines données peuvent être fournies via les réseaux sociaux que vous avez
            connecté sur notre site.
            Par votre activité sur nos services, nous pouvons également recueillir les données
            suivantes :
            <ul class="px-0">
                <li>- Un historique des échanges intervenus entre SAS Haden et vous</li>
                <li>- un historique de vos commandes et ventes via nos services</li>
                <li>- un historique des opérations financières ou comptables effectuées via nos services</li>
                <li>- un historique de votre activité sur l’application ou le site (en outre visites et contenus générés en rapport avec votre compte)</li>
                <li>- si vous êtes Vendeur, le détail de vos évaluations données par vos clients</li>
                <li>- si vous êtes Acheteur, le détail des évaluations données aux Vendeurs</li>
                <li>- si vous avez répondu à nos questionnaires, le détail de vos réponses.</li>
            </ul>
        </div>

        <div class="col-12 mt-12">
            <h4>9° Données Personnelles</h4>
            <p>
                Conformément à la loi « Informatique et Libertés » n°78-17 du 6 janvier 1978, l'Utilisateur peut à tout moment
                accéder aux informations personnelles le concernant
                qu'il a communiquées au Site, et/ou en demander la rectification, le complément, la
                clarification, la mise à jour ou la suppression en s'adressant au Service Client via la
                rubrique Mon Compte > Nous contacter ou par simple lettre (SAS HADEEN, 82 allée du petit pont, 83240 CAVALAIRE), en
                indiquant ses nom, prénom, date et lieu
                de naissance, adresse postale et électronique ainsi que son identifiant
                Le site www.hadeen-place.fr est conçu pour être très attentif aux besoins de ses
                Utilisateurs. Si vous refusez l'enregistrement de Cookies, ou si vous supprimez
                ceux qui y sont enregistrés, vous ne pourrez plus bénéficier de certaines fonctionnalités nécessaires pour naviguer
                dans certains espaces du Site ou Applications. <br>
                En s’inscrivant sur le Site, l’Utilisateur reconnaît et accepte le traitement de ses
                données personnelles par SAS HADEEN conformément à la loi applicable et aux
                stipulations des Conditions Générales d’Utilisation
                Toutes ces données que vous saisissez sont sécurisées et cryptées pour préserver
                votre sécurité. <br>
                Pour des raisons légales les données sont conservées trois ans à compter du dernier échange commercial puis archivées ou rendues anonymes afin de procéder à
                des études statistiques. <br>
                Pour ne plus recevoir de publicité vous devez adresser un courrier au siège social
                de SAS HADEEN. <br>
                Seuls les services habilités par SAS HADEEN ont accès à ces données selon leur
                degré d’habilitation :
            </p>
            <ul class="px-0">
                <li>- la direction de SAS HADEEN (gestion des litiges, modification ou suppression des données …)</li>
                <li>- La section commerciale (concours, prospection, recommandations …)</li>
                <li>- Le prestataire digital et technique ( hébergement, technicité informatique …)</li>
                <li>- Prestataire de paiement (finalisation de commande, remboursement …)</li>
                <li>- Section relation client (Sondage, statistique, avis …)</li>
            </ul>
            Toutes les personnes internes ou externes à SAS HADEEN qui utiliseront, recueilleront ou traiteront ces données seront
            soumis à une obligation contractuelle.
            <p>
                Le Site joue seulement un rôle d’intermédiaire, SAS HEDDEN n’est en aucun cas
                revendeur des articles mis en vente et ne devient, à aucun moment, propriétaire
                des produits. <br>
                Le contrôle qualité effectué parSAS HADEEN porte uniquement sur l’adéquation de
                l’Article et à son descriptif inséré par le vendeur dans la Fiche Produit.
                SAS HADEEN ne peut être tenue responsable des litiges déclarés entre l'acheteur
                et le vendeur concernant une transaction et ne peut pas garantir que l’Article satisfera l’acheteur. <br>
                Lorsqu'un litige survient entre les parties, SAS HADEEN pourra, pendant un délai
                de 30 jours, mettre tout en oeuvre pour aider les parties à trouver un accord à
                l’amiable et tenir le rôle de médiateur mais ne pourra être tenu pour responsable de
                l'issue du litige. Pendant ce délai, le fonds de la transaction du ou des Utilisateurs
                concernés peuvent-être temporairement bloqués et les demandes de virement suspendues. <br>
            </p>
            <p>
                Tout comportement révélant une volonté manifeste d'induire en erreur et/ou de
                mauvaise foi, pourra faire l'objet d'une mise en demeure, de la suspension ou de
                l’interruption définitive d’accès de l’Utilisateur aux services de SAS HADEEN. Dans
                ce cas, le Vendeur devra rembourser SAS HADEEN des sommes qu’il aura éventuellement perçues au titre de la vente dudit Article et devra dédommager SAS HADEEN pour tous les frais éventuels encourus par SAS HADEEN et/ou l’Acheteur du
                fait de l’activité du Vendeur et devra garantir SAS HADEEN contre d’éventuelles
                poursuites judiciaires. <br>
                Le vendeur s'engage à ne pas insérer ses coordonnées (téléphone, adresse,
                email...) dans les fiches produits, ni dans les questions et réponses y afférent, ni
                dans le nom ou la description de l’Article. SAS HADEEN pourra le cas échant supprimer ces informations sans demande préalable auprès de l'Utilisateur. L’Utilisateur s’engage à ne pas entrer directement en contact avec les autres Utilisateurs
                en dehors de l’espace de commentaires présent sur le Site. <br>
                5°Partenariat
                SAS HADEEN est en partenariat avec des prestataires dont leur site internet sont
                en lien sur nos services. Ne pouvant être tenu responsable du contenu de ses sites
                ni vous garantir la satisfaction de leur services ou produits en cas de transaction.
                La protection de vos données personnelles est importante pour nous.
            </p>
        </div>

        <div class="col-12 mt-12">
            <h4>10° LOI APPLICABLE</h4>
            <p>
                Nous nous réservons le droit de restreindre , de suspendre, de résilier l’accès de
                nos services à tout utilisateur ayant enfreint les présentes conditions générales
                d’utilisations ou ayant un comportement frauduleux à l’encontre de la SAS HADEEN ou à tiers vendeur de la Market Place.<br>
                Nous nous réservons le droit également d’intenter une action en justice, plainte ou
                toute autre sanction que la direction de SAS HADEEN jugera utile et nécessaire, à
                l’encontre de tout utilisateur frauduleux ou ayant commis une violation des conditions générales d’utilisation ou de vente , pour un remboursement ou indemnisation
                du préjudice subit.<br>
                Présentes conditions générales d'utilisation sont régi par le droit français. Tout litige
                relatif à ces conditions générales relève des juridictions françaises
                Si une partie des conditions générales d’utilisation et de vente devaient s'avérer illégales, invalides ou inapplicables, pour quelque raison que ce soit, les dispositions
                en question seraient réputées non écrites, sans remettre en cause la validité des
                autres dispositions qui continueront de s'appliquer a tous les utilisateurs de nos services et SAS HADEEN. <br>
                Pour toute réclamation merci de contacter le service client à : service.client@hadeen-place.fr
            </p>
        </div>


    </div>
    -->
</div>