<div class="row">
  <div class="col-12 col-md-12">
    <div class="row">
      <div class="col-12 mb-0">
        <div class="header">
          <ul class="breadcrumb mb-0">
            <li [routerLink]="['/compte']"><a>Compte</a></li>
            <li class="active"><a>Favoris</a></li>
          </ul>
        </div>
      </div>

      <div class="col-12 mb-4"><h1 class="text-center">Favoris</h1></div>

      <div class="col-12 col-sm-12 mb-5">
        <div class="row mt-3">
          <div class="col-6 col-sm-6 col-md-4 col-lg-4 product p-2" *ngFor="let product of productService.mesEnvies.produits | paginate: { itemsPerPage: 12, currentPage: page ,totalItems: productService.mesEnvies.nb_total }">
            <app-produit-input [data]="product"></app-produit-input>
          </div>
        </div>
        <div class="row">
          <pagination-controls class="modal-pagination text-center m-auto" maxSize="5" (pageChange)="changePage($event)" directionLinks="true" responsive="true" autoHide="true" previousLabel="PRÉCÉDENT" nextLabel="SUIVANT">
          </pagination-controls>
        </div>
      </div>
    </div>
  </div>
</div>
