import { HttpService } from './services/http.service';
import { environment } from './../environments/environment';
import { HttpClient } from '@angular/common/http';
import { GoogleAnalyticsEventsService } from './services/google-analytics-events.service';
import { ProductService } from 'src/app/services/product.service';
import { AfterViewInit, Component, ElementRef, HostListener, Inject, OnDestroy, OnInit, PLATFORM_ID, ViewChild } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Router, NavigationEnd } from '@angular/router';
import { CommonService } from './services/common.service';
import { WebSocketAPI } from './services/WebSocketAPI';
import { isPlatformBrowser, DOCUMENT, isPlatformServer } from '@angular/common';
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';
import { GoogleTagManagerService } from 'angular-google-tag-manager';
import { StorageService } from './services/storage.service';
import { MatDialog } from '@angular/material/dialog';

import { NgcCookieConsentService, NgcInitializeEvent, NgcNoCookieLawEvent, NgcStatusChangeEvent } from 'ngx-cookieconsent';
import { Subscription }   from 'rxjs/Subscription';
import { TrackEventService } from './services/track-event.service';

declare var ga;
declare var gtag;
declare var pintrk;

export interface DialogData {
  animal: string;
  name: string;
}
@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent implements OnInit, AfterViewInit {
  pi = 3.14159265359;
  animal: string;
  name: string;

  @ViewChild("loginTemplate") loginTemplate: ElementRef;
  title = "Hadeen-Place";
  fixe = "";

  webSocketAPI: WebSocketAPI;
  user;
  modalRef2: BsModalRef;

  azerty;
  link: HTMLLinkElement;
  alternateLink: HTMLLinkElement;
  menusShow: any;
  showHeaders = true;

  cguapp;
  showBandeau;
  
  //keep refs to subscriptions to be able to unsubscribe later
  private popupOpenSubscription: Subscription;
  private popupCloseSubscription: Subscription;
  private initializeSubscription: Subscription;
  private statusChangeSubscription: Subscription;
  private revokeChoiceSubscription: Subscription;
  private noCookieLawSubscription: Subscription;

  constructor(
    private ccService: NgcCookieConsentService,
    @Inject(DOCUMENT) private document: Document,
    @Inject(PLATFORM_ID) private platformId,
    private toast?: ToastrService,
    private commonService?: CommonService,
    private storageService?: StorageService,
    public router?: Router,
    public dialog?: MatDialog,
    private productService?: ProductService,
    private gtmService?: GoogleTagManagerService,
    public googleAnalyticsEventsService?: GoogleAnalyticsEventsService,
    private httpClient?: HttpClient,
    public httpService?: HttpService,
    private trackEventService?: TrackEventService
  ) {
    this.statusOffline();
    this.statusOnline();
    this.router.events.subscribe((val) => {
      if (val instanceof NavigationEnd) {
        this.setAnalitics(null);
        // this.googleAnalyticsEventsService.emitPageview(event.urlAfterRedirects);
        const tab = val.url.trim().split("/");
        this.showBandeau = !(
          tab &&
          (tab[1] === "compte" || tab[1] === "ajout-prod")
        );
        this.createCanonicalURL();
        this.createAlternateURL();

        if (val.url.includes("livraison") || val.url.includes("payment")) {
          this.showHeaders = false;
        } else {
          this.showHeaders = true;
        }
      }
    });
    this.router.events.forEach((event) => {
      if (event instanceof NavigationEnd) {
        this.setAnalitics(event);
        this.createCanonicalURL();
        this.createAlternateURL();
      }
    });

    // this.router.events.subscribe((event) => {
    //   if (event instanceof NavigationEnd) {
    //     // gtag('config', 'G-REXPXS6TTM');
    //     this.googleAnalyticsEventsService.emitPageview(event.urlAfterRedirects);
    //     const tab = event.url.trim().split("/");
    //     this.showBandeau = !(
    //       tab &&
    //       (tab[1] === "compte" || tab[1] === "ajout-prod")
    //     );
    //     this.createCanonicalURL();
    //     this.createAlternateURL();
    //     pintrk("track", "pagevisit");
    //   }
    // });
  }
  ngAfterViewInit(): void {
    if (isPlatformServer(this.platformId)) {
      // subscribe to cookieconsent observables to react to main events
      this.popupOpenSubscription = this.ccService.popupOpen$.subscribe(() => {
        // you can use this.ccService.getConfig() to do stuff...
      });
   
      this.popupCloseSubscription = this.ccService.popupClose$.subscribe(() => {
        // you can use this.ccService.getConfig() to do stuff...
      });
   
      this.initializeSubscription = this.ccService.initialize$.subscribe((event: NgcInitializeEvent) => {
        // you can use this.ccService.getConfig() to do stuff...
      });
   
      this.statusChangeSubscription = this.ccService.statusChange$.subscribe((event: NgcStatusChangeEvent) => {
        // you can use this.ccService.getConfig() to do stuff...
        if (event.status == 'allow') {
          this.storageService.setItem('_consent', 'true')
        } else if (event.status == 'deny') {
          this.storageService.setItem('_consent', 'false')
        } else {
          this.storageService.removeItem('_consent')
        }
      });
   
      this.revokeChoiceSubscription = this.ccService.revokeChoice$.subscribe(() => {
        // you can use this.ccService.getConfig() to do stuff...
      });
   
      this.noCookieLawSubscription = this.ccService.noCookieLaw$.subscribe((event: NgcNoCookieLawEvent) => {
        // you can use this.ccService.getConfig() to do stuff...
      });
      
      this.ccService.getConfig().content.dismiss = 'hahaha'
      this.ccService.getConfig().content.deny = 'Refuser'
      this.ccService.getConfig().content.allow = 'Accepter'
      this.ccService.getConfig().autoOpen = false;
      this.ccService.init(this.ccService.getConfig()); 
      // this.ccService.destroy();//remove previous cookie bar (with default messages)
      
      setTimeout(() => {
        const _consent = this.storageService.getItem('_consent')
        if (!_consent) {
          this.ccService.fadeIn();
          this.ccService.open();
        }
      }, 3000);
    }
  }

  headerFixed: boolean = false;
  @HostListener("window:scroll", ["$event"])
  onScroll(event) {
    const scrollTop =
      window.pageYOffset ||
      document.documentElement.scrollTop ||
      document.body.scrollTop ||
      0;
    if (scrollTop > 70) {
      this.fixe = "fixe fade-in";
    } else {
      this.fixe = "fade-out";
    }
  }

  statusOnline() {
    if (isPlatformBrowser(this.platformId)) {
      window.addEventListener("online", (resp: any) => {
        this.toast.success("Connected");
        this.user = this.storageService.getItem("User");
        if (this.user) {
          this.productService.getEnvies();
          if (this.user.role !== "ACHETEUR") {
            this.getVendeur();
          }
        }
      });
    }
  }

  statusOffline() {
    if (isPlatformBrowser(this.platformId)) {
      window.addEventListener("offline", () => {
        this.toast.error("Offline");
      });
    }
  }

  moveArrayItemToNewIndex(arr, oldIndex, newIndex) {
    if (newIndex >= arr.length) {
      let k = newIndex - arr.length + 1;
      while (k--) {
        arr.push(undefined);
      }
    }
    arr.splice(newIndex, 0, arr.splice(oldIndex, 1)[0]);
    return arr;
  }

  onActivate(e) {
    if (isPlatformBrowser(this.platformId)) {
      window.scroll(0, 0);
    }
  }

  ngOnInit() {
    this.httpService.get("arborescences").subscribe((dataMenu: any) => {
      this.menusShow = this.moveArrayItemToNewIndex(dataMenu.data, 0, 4);
      this.menusShow.forEach((elt) => {
        elt.children = elt.sous_univers;
        elt.children.forEach((eltCategorie) => {
          eltCategorie.children = eltCategorie.categories;
        });
      });
    });

    if (isPlatformBrowser(this.platformId)) {
      window.scroll(0, 0);
      this.commonService.getPanier();
      this.commonService.getUser();
      this.user = this.storageService.getItem("User");
      if (this.user && this.user.role !== "ACHETEUR") {
        this.getVendeur();
      }
      this.router.events.subscribe((evt: any) => {
        if (!(evt instanceof NavigationEnd)) {
          if (isPlatformBrowser(this.platformId)) {
            return window.scroll(0, 0);
          }
        }
        window.scroll(0, 0);
        // tslint:disable-next-line:max-line-length
        if (
          evt.url.includes("conditions-generales-de-ventes-app") ||
          evt.url.includes("centre-d-aide-app") ||
          evt.url.includes("token") ||
          evt.url.includes("frais-livraison")
        ) {
          this.cguapp = true;
        }
      });
      setTimeout(() => {
        if (this.user) {
          this.productService.getEnvies();
          this.postModalFrais();
        }
      }, 300);
    }
  }

  initCookies() {
    return this.ccService.hasAnswered() ? null : this.ccService.open();
  }

  ngOnDestroy() {
    // unsubscribe to cookieconsent observables to prevent memory leaks
    this.popupOpenSubscription.unsubscribe();
    this.popupCloseSubscription.unsubscribe();
    this.initializeSubscription.unsubscribe();
    this.statusChangeSubscription.unsubscribe();
    this.revokeChoiceSubscription.unsubscribe();
    this.noCookieLawSubscription.unsubscribe();
  }
  
  createCanonicalURL() {
    this.link = <HTMLLinkElement>(
      this.document.querySelector("link[rel='canonical']")
    );
    let newLink = this.document.URL.replace("http://", "https://");
    if (this.link === undefined || this.link === null) {
      this.link = this.document.createElement("link");
      this.link.setAttribute("rel", "canonical");
      this.link.setAttribute("href", newLink);
      this.document.head.appendChild(this.link);
    } else {
      this.link.setAttribute("href", newLink);
    }
  }

  createAlternateURL() {
    if (isPlatformBrowser(this.platformId)) {
      const parsedUrl = new URL(window.location.href);
      const baseUrl = parsedUrl.origin;

      if (this.alternateLink === undefined) {
        this.alternateLink = this.document.createElement("link");
        this.alternateLink.setAttribute("rel", "alternate");
        this.document.head.appendChild(this.alternateLink);
      }
      this.alternateLink.setAttribute("href", baseUrl);
      this.alternateLink.setAttribute("hreflang", "fr");
    }
  }

  getVendeur() {
    this.httpClient
      .get(`${environment.ws_url}users/vendeur/byId`)
      .subscribe((respVendeur: any) => {
        const newUser = JSON.parse(this.storageService.getItem("User"));
        newUser["has_frais_livraison"] =
          respVendeur.data.vendeur.has_frais_livraison;
        this.storageService.setItem("User", JSON.stringify(newUser));
        this.postModalFrais();
      });
  }

  postModalFrais() {
    const userStorage = localStorage.getItem("User");
    if (userStorage) {
      const user = JSON.parse(userStorage);
      setTimeout(() => {
        if (
          user.role === "VENDEUR_COMPLET" &&
          (!user.has_frais_livraison || user.has_frais_livraison === 0)
        ) {
          this.commonService.openDialog(false);
        }
        if (
          user.role === "VENDEUR_VALIDEE" &&
          (!user.has_frais_livraison || user.has_frais_livraison === 0)
        ) {
          this.commonService.openDialog(true);
        }
      }, 200);
    }
  }

  setAnalitics(event: any) {
    this.trackEventService.fbqTrack('page_view');
    this.trackEventService.gtagTrack('page_view');
    this.trackEventService.pintrkTrack('page_view');

    // ga("create", environment.gtag_key, "auto");
    // ga("set", "page", event.url);
    // ga("send", "pageview");

    // const gtmTag = {
    //   event: "page",
    //   pageName: event.url,
    // };
    // this.gtmService.pushTag(gtmTag);

    // // tslint:disable-next-line:no-unused-expression
    // removeEventListener;
    // return;
  }

  goTo(univ) {
    // console.log("MENU: ", univ);
    this.router.navigate([`/all-product/${univ.slug}`]);

    // const nomUniver = (univ.nom).split(' ').join('_');
    // this.router.navigate([`/all-product/${nomUniver}`]);
  }
}
