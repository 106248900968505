import { Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-ajout-prod-mob',
  templateUrl: './ajout-prod-mob.component.html',
  styleUrls: ['./ajout-prod-mob.component.scss']
})
export class AjoutProdMobComponent implements OnInit {
  myUser;
  step1 = true;
  step2 = false;
  attenteValidation = false;

  step = 0;
  listCaracteristique = [];
  listCouleur = [];
  showSpinner = false;

  dataForm: any = {};
  listTailles: any = [];
  constructor(private httpClient: HttpClient, private router: Router) { }

  ngOnInit(): void {
    this.getInfoVendeur();
  }

  async getInfoVendeur() {
    this.showSpinner = true;
    return await this.httpClient.get(environment.ws_url + `users/acheteur/byId`).subscribe((dataMenu: any) => {
      this.showSpinner = false;
      const detail = dataMenu.data;
      const user = detail.user;
      if (user.etapeVendeur === 3 && user.role === 'VENDEUR_VALIDEE') {
        this.step = 1;
      } else {
        this.attenteValidation = true;
      }
    });
  }

  goto(step) { this.step = step; }

  nextStep() { this.step += 1; }

  saveDataForm(event) {
    this.dataForm[this.step] = event;
    if (this.step === 1) {
      this.showSpinner = true;
      this.getCaracteristique().then(() => {
        this.showSpinner = false;
        this.nextStep();
      });
    } else {
      this.nextStep();
    }
  }

  async getCaracteristique() {
    const categorie = this.dataForm[this.step].categorie || {};
    return await this.httpClient.get(`${environment.ws_url}categories/${categorie ? categorie.code || 0 : 0}/details`).subscribe(
      (dataMenu: any) => {
        const detail = dataMenu.data;
        this.listCaracteristique = detail.caracteristiques || [];
        this.listCouleur = detail.couleurs || [];
        detail.tailles.forEach(element => {
          this.listTailles = element.taille_values || [];
        });
        this.listTailles.forEach(elt => {
          elt.id = elt.taille_id;
          elt.text = elt.valeur;
        });
      }
    );
  }
}
