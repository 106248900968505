<div class="row">

  <!--debut Row Commande Vendeur-->
  <div class="col-lg-12 col-sm-12">
    <div class="col-lg-12 col-sm-12 text-center">
      <h1 style="font-size: 1.5rem;">MES COMMANDES</h1>
    </div>
    <div class="row">
      <div class="col-lg-8 col-12 col-sm-7" *ngFor="let vend of commadSellerById">
        <h2 style="font-size: 20px;">Detail de la nouvelle commande</h2>
        <p class="m-0 pr-0" style="font-size: 14px; color: #6A6B6A;">Commande N° : <span
            style="font-size: 14px; margin-left: 25px; color: #000;"> {{ vend.id }} </span></p>
        <p class="m-0 pr-0" style="font-size: 14px; color: #6A6B6A;">Date de commande : <span
            style="font-size: 14px;  margin-left: 12px; color: #000;"> {{ vend.created_at | date }} </span><span
            class="ml-1" style="font-size: 14px; color: #e4a92b;"> ( {{ commadSellerById[0].statut_acheteur }})</span>
        </p>
        <p class="m-0 pr-0" style="font-size: 14px; color: #6A6B6A;">
          Status: <span
            style="font-size: 14px; color: #fff; margin-left: 95px; border-radius: 5px; background-color: #044704;"
            class="p-1">
            {{ commadSellerById[0].statut_vendeur }} </span>
        </p>
        <p class="m-0 pr-0" *ngIf="vend.code_expedition" style="font-size: 14px; color: #6A6B6A;">Numéro de suivi :
          <span style="font-size: 14px; color: #000;"> {{ vend.code_expedition }} </span>
        </p>
        <p class="m-0 pr-0" *ngIf="vend.lien_expedition" style="font-size: 14px; color: #6A6B6A;">
          Url d'expédition :
          <a [href]="'http://' + vend.lien_expedition" target="blank">
            {{ vend.lien_expedition }}
            <!-- <span style="font-size: 14px; color: #000;"> {{ commandes.lien_expedition }} </span> -->
          </a>
        </p>
      </div>
      <div class="pt-3 col-lg-4 col-12 col-sm-5">
        <div class="row">
          <div class="col-lg-9 col-12 mb-0">
            <button class="btn-gren-borderGreen mt-2" *ngIf="commadSellerById.length != 0
              && commadSellerById[0].statut_vendeur == 'NOUVELLE'
              || commadSellerById[0].statut_vendeur == 'EN_COURS'" (click)="onValideCommande(commadSellerById[0].id)">
              Valider cette commande
            </button>
          </div>
          <div class="col-lg-3 col-12 mb-0">
            <button class="refuseCom mt-2" *ngIf="commadSellerById.length != 0
                                && commadSellerById[0].statut_vendeur == 'NOUVELLE'
                                || commadSellerById[0].statut_vendeur == 'EN_COURS'"
              (click)="onRefusedCommande(commadSellerById[0].id)">
              Refuser
            </button>
          </div>
          <div class="col-lg-9 col-12"
            *ngIf="commadSellerById.length != 0 && commadSellerById[0].statut_vendeur == 'EXPEDIEE' || commadSellerById[0].statut_vendeur == 'LIVREE'">
            <a [href]="'http://' + commadSellerById[0].lien_expedition" target="blank" class="linked">
              <button class="h-btn btn-gren-borderGreen cmd mt-3">
                Suivre la commande
              </button>
            </a>
          </div>
        </div>
      </div>
    </div>
    <div class="row mt-3" *ngIf="isValidate">
      <div class="col-12">
        <form [formGroup]="expeditionForm" (ngSubmit)="onExpedition()">
          <div class="form-group row">
            <label style="font-size: 14px; color: #6A6B6A;" for="inputlink" class="col-lg-12 col-form-label">Suivi
              d'expedition :</label>
            <div class="col-lg-3">
              <input type="text" class="form-control seleCt mb-1" id="inputlink" placeholder="numéro de suivi"
                formControlName="code">
            </div>
            <div class="col-lg-8">
              <input type="text" class="form-control seleCt mb-1" id="inputlink" placeholder="url d'expedition "
                formControlName="url">
            </div>
            <div class="col-lg-1">
              <button type="submit" class="expedierBtn" [disabled]="expeditionForm.invalid">Expedier</button>
            </div>
          </div>
        </form>
      </div>
    </div>
    <hr />

    <!--début Adress de Livraison-->
    <div class="row mb-3">
      <div class="col-lg-12 col-sm-12 col-12"
        *ngIf="isValidate || commadSellerById[0].statut_vendeur === 'EXPEDIEE' || commadSellerById[0].statut_vendeur === 'LIVREE'">
        <h2 style="font-size: 20px;">Coordonnées pour la livraison</h2>
        <div class="card" style="background-color: rgba(0,0,0,.03);">
          <div class="card-body">
            <p class="m-0 pr-0">{{ commadSellerById[0].adresse.prenom }} {{ commadSellerById[0].adresse.nom }} </p>
            <p class="m-0 pr-0">{{ commadSellerById[0].adresse.adresse }}</p>
            <p class="m-0 pr-0" *ngIf="commadSellerById[0].adresse.complement_adresse">
              {{ commadSellerById[0].adresse.complement_adresse }}
            </p>
            <p class="m-0 pr-0">{{ commadSellerById[0].adresse.ville }}</p>
            <p class="m-0 pr-0">{{ commadSellerById[0].adresse.code_postal }}</p>
            <p class="m-0 pr-0">{{ commadSellerById[0].adresse.telephone }}</p>
          </div>
        </div>
      </div>
    </div>
    <!--fin Adress de Livraiso-->

    <div class="row">
      <!--Début carte-->
      <div class="col-lg-12 col-12">
        <div class="card mb-3" *ngFor="let itemProduit of commadSellerById">
          <div class="card-body">
            <div class="row">
              <div class="col-lg-3 col-sm-3 col-5">
                <img width="auto" height="auto" [src]="itemProduit.sous_produit.produit.image" alt=""
                  style="height: 100px;" class="img-fluid">
              </div>
              <div class="text-right col-lg-3 col-sm-3 col-7 mb-2">
                <p class="m-0 pr-0" style="font-size: 14px; color: #457e63;">{{ itemProduit.sous_produit.produit.model}}
                </p>
                <p class="mt-2 pr-0 mb-0" style="font-size: 14px; color: #6A6B6A;">Stock disponible : <span
                    style="color: #000;">{{ itemProduit.sous_produit.disponibilite }}</span></p>
              </div>
              <div class="col-lg-3 col-sm-3 col-5 mb-3">
                <p class="m-0 pr-0" style="font-size: 14px; color: #6A6B6A;">Quantité : <span style="color: #000;">{{
                    itemProduit.quantite }}</span></p>
                <p *ngIf="itemProduit.sous_produit.taille && itemProduit.sous_produit.taille !== 'null'"
                  class="m-0 pr-0" style="font-size: 14px; color: #6A6B6A;">Taille : <span
                    style="color: #000; font-size: initial;">{{ itemProduit.sous_produit.taille.valeur }}</span></p>

                <div class="" *ngIf="itemProduit.sous_produit.couleur">
                  <p class="m-0 pr-0" style="font-size: 14px; color: #6A6B6A;"
                    *ngIf="itemProduit.sous_produit.couleur && itemProduit.sous_produit.couleur !== null">Couleur : </p>
                  <div *ngIf="itemProduit.sous_produit.couleur && itemProduit.sous_produit.couleur !== null"
                    [style.background-color]="itemProduit.sous_produit.couleur.code_hexa"
                    [style.border-color]="itemProduit.sous_produit.couleur.code_hexa == '#ffffff' ? 'lightgray' : itemProduit.sous_produit.couleur.code_hexa"
                    style="height: 20px; width:20px; border: 2px solid;">
                  </div>
                  <span style="font-size: 14px; color: #6A6B6A;">{{itemProduit.sous_produit.couleur.nom}}</span>
                </div>
              </div>
              <div class="col-lg-3 col-sm-3 col-7 text-right">
                <p style="font-size: 14px; color: #6A6B6A;">Prix d'achat reservé au vendeur</p>

                <p class="mt-3 mb-0" style="color: #2b9746; font-size: 20px; text-align: right;">
                  {{ itemProduit.prix_vendeur | number : '1.2-2' }} €</p>
              </div>
            </div>
            <div class="row text-center">
              <div style="margin: auto;">
                <button class="w-100 h-btn-grey-green" data-toggle="modal" mat-flat-button
                  data-target="#modalContactModerateur">Besoin d’aide? Nous contacter</button>
              </div>
              <!-- modal pour contacter le moderateur -->
              <div class="modal fade" id="modalContactModerateur" tabindex="-1" role="dialog"
                aria-labelledby="modalLabe" aria-hidden="true">
                <div class="modal-dialog modal-dialog-centered" role="document">
                  <div class="modal-content" style="border-radius: 8px;">
                    <div class="modal-header" style="margin-left: auto;">
                      <button type="button" class="h-btn btnCloseModal" #closeModalContact data-dismiss="modal"
                        aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                      </button>
                    </div>
                    <div class="modal-body">
                      <div class="col-sm-12">
                        <form class="form" [formGroup]="contactModeratorForm" (ngSubmit)="onContactModerator()">
                          <div class="form-group">
                            <div class="text-right">
                              <textarea class="form-control col-sm-12 area" name="msg" id="msg" rows="10"
                                placeholder="Your message" formControlName="messages"></textarea>

                              <button type="submit" [disabled]="contactModeratorForm.invalid"
                                class="h-btn contactModeratorBtn mt-2"
                                style="height: 40px; width: 20%;">Envoyer</button>
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- end modal pour contacter le moderateur -->
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--fin carte-->

    <div class="row">
      <!-- debut carte article-->
      <div class="col-lg-12  col-12 mb-2">
        <div class="card" *ngFor="let item of commadSellerById">
          <div class="card-header" style="display: flex;">
            <p class="text-left">Total articles : ({{ commadSellerById.length }})</p>
            <p class="text-right" style="margin-left: auto; font-size: 20px; color: #2b9746;">
              {{ prix(item) | number : '1.2-2' }} €</p>
          </div>
          <!-- <div class="card-body" style="background-color: rgba(0,0,0,.03);">
                        <p class="card-text">Livraison incluse</p>
                    </div> -->
          <div class="card-header" style="display: flex;">
            <p class="text-left">{{ totalFrais > 0 ? 'Frais livraison :' : 'Frais incluse' }}</p>
            <p class="text-right" *ngIf="totalFrais > 0" style="margin-left: auto; font-size: 20px; color: #2b9746;">
              {{ totalFrais | number : '1.2-2' }} €</p>
          </div>
          <div class="card-footer" style="display: flex;">
            <p class="text-left">Total TTC : </p>
            <p class="text-right" style="margin-left: auto; font-size: 25px; color: #2b9746;">
              {{ prix(item) | number : '1.2-2' }} €</p>
          </div>
        </div>
      </div>
    </div>
    <!--Fin carte article-->

    <div class="row">
      <!-- début Frmulaire contact-->
      <div class="col-lg-12 col-xs-12 mb-2">
        <h2 style="font-size: 20px;">Contactez l'acheteur au sujet de cette commande</h2>
      </div>
      <div class="col-sm-12 mb-3">
        <form class="form" [formGroup]="contactShopperByForm" (ngSubmit)="onContactShopperByCommande(commadSellerById)">
          <select name="commandes" id="commandes" formControlName="article"
            class="form-control col-sm-12 mb-2 seleCt input">
            <option value="" disabled>Selectionnez un article</option>
            <option [ngValue]="item.sous_produit.produit.model" *ngFor="let item of commadSellerById">
              Commande N° {{ item.id }} : {{ item.sous_produit.produit.model }}</option>
          </select>
          <div class="text-right">
            <textarea formControlName="message" class="form-control col-sm-12 area" name="command" id="commanda"
              rows="10" placeholder="Message"></textarea>
            <button type="submit" class="h-btn contactAcheteur" [disabled]="contactShopperByForm.invalid"
              style="color: #fff; background-color: #044704; height: 40px; margin-top: 10px;">ENVOYER</button>
          </div>
        </form>
      </div>
    </div>
    <!--Fin formulaire contact-->
  </div>
</div>
<!--fin Row Commande Vendeur-->
