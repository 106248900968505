import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { CommonService } from './common.service';
import { HttpService } from './http.service';
import { MenuListService } from './menu-list.service';
import { StorageService } from './storage.service';
@Injectable({
  providedIn: 'root'
})
export class AuthServices {
  token: any;
  authToken: any;
    constructor(
      private http: HttpService,
      private menuListService: MenuListService, private storageService: StorageService
    ) { }
  register(user): Observable<any> {
    return this.http.post('inscriptions/step-one', user);
  }
  signIn(user): Observable<any> {
    return this.http.post('users/login', user);
  }
  storeUserData(user, token?) {
    return new Promise((resolve, reject) => {
      // localStorage.setItem('User', user);
      // localStorage.setItem('Token', token);
      // const a = localStorage.getItem('Token');
      // const b = localStorage.getItem('User');
      this.storageService.setItem('User', user);
      const b = this.storageService.getItem('User');
      this.menuListService.setUser(user);
      this.menuListService.setUser(b);
      if (token) {
        this.storageService.setItem('Token', token);
        const a = this.storageService.getItem('Token');
        this.menuListService.setToken(token);
        this.menuListService.setToken(a);
        resolve(token);
      }
    });
  }
  signUpfb(token) {
    return this.http.post('inscriptions/signup-fb', token);
  }
  loginFb(token) {
    return this.http.post('users/login-fb', token);
  }
  resetstep2(data) {
    return this.http.post('users/recuperation', data);
  }
  resetstep1(data) {
    return this.http.post( 'users/send-recuperation', data);
  }
  authStep1(data) {
    return this.http.post( 'inscriptions/step-one', data);
  }
  authStep2(data) {
    return this.http.post( 'inscriptions/step-two', data);
  }
  authStep3(data) {
    return this.http.post( 'inscriptions/step-three', data);
  }

  getUserVendeur(data) {
    return this.http.post( 'inscriptions/step-three', data);
  }




  googleLogin(data) {
    return this.http.post('users/login-google', data);
  }
  googleRegister(data) {
    return this.http.post('google/user-profile', data);
  }


  facebookLogin(data) {
    return this.http.post('users/login-fb', data);
  }
  facebookRegister(data) {
    return this.http.post('users/signup-fb', data);
  }
}
