<div class="row">
  <div class="col-lg-12 col-12 col-md-12">
    <!-- Début Row Suivi Commande-->
    <div class="row">
      <div class="col-sm-12 col-12 mb-3">
        <h1 class="text-center" style="font-size: 1.5rem;">MES COMMANDES</h1>
        <h2 style="font-size: 20px;">Suivi de Commande</h2>
        <div class="row">
          <div class="col-12 col-md-6" *ngFor="let cmd of commadShopperById; let i = index" [ngClass]="{'hideComd' : cmd.statut_acheteur == 'EXPEDIEE'}">
            <p class="m-0 pr-0" style="font-size: 14px; color: #6A6B6A;">Commande N° :
              <span style="font-size: 14px; color: #000;"> {{ cmd.id }}</span>
            </p>
            <p class="m-0 pr-0" style="font-size: 14px; color: #6A6B6A;">Date de commande:
              <span style="font-size: 14px; color: #000;"> {{ cmd.created_at | date }}</span>
              <span class="ml-1" style="font-size: 14px;" [ngStyle]="{'color' : getColor(i)}">(
                {{ cmd.statut_acheteur }})</span>
            </p>
          </div>
        </div>
        <div *ngFor="let commandes of commadShopperById; let i = index" [ngClass]="{'hideComd' : commandes.statut_acheteur != 'EXPEDIEE'}">
          <div class="row mt-3">
            <div class="col-lg-7 col-sm-12">
              <p class="m-0 pr-0" style="font-size: 14px; color: #6A6B6A;">Commande N° :
                <span style="font-size: 14px; color: #000;"> {{ commandes.id }} </span>
              </p>
              <p class="m-0 pr-0" style="font-size: 14px; color: #6A6B6A;">Date de commande :
                <span style="font-size: 14px; color: #000;"> {{ commandes.created_at | date }} </span>
                <span class="ml-1" style="font-size: 14px;" [ngStyle]="{'color' : getColor(i)}">(
                  {{ commandes.statut_acheteur }})</span>
              </p>
              <p class="m-0 pr-0" *ngIf="commandes.code_expedition" style="font-size: 14px; color: #6A6B6A;">Numéro de suivi :
                <span style="font-size: 14px; color: #000;"> {{ commandes.code_expedition }} </span>
              </p>
              <p class="m-0 pr-0" *ngIf="commandes.lien_expedition" style="font-size: 14px; color: #6A6B6A;">
                Url d'expédition :
                <a [href]="'http://' + commandes.lien_expedition" target="blank">{{ commandes.lien_expedition }}</a>
              </p>
            </div>
            <div class="col-lg-5 col-sm-12">
              <div class="row">
                <div class="col-lg-6 col-sm-12 text-center pl-sm-3 pl-lg-0">
                  <a [href]="'http://' + commandes.lien_expedition" target="blank" class="linked">
                    <button class="h-btn-green-green mt-3" style="height: 70%; width: 100%;">
                      Suivre la commande
                    </button>
                  </a>
                </div>
                <div class="col-lg-6 col-sm-12 text-center pl-sm-3 pl-lg-0">
                  <button class="h-btn-green-green mt-3" (click)="onConfirmation(commandes)" style="height: 70%; width: 100%;">
                    Confirmer la reception
                  </button>
                </div>
              </div>
            </div>
            <hr />
          </div>
        </div>
      </div>
    </div>
    <!-- Fin Row Suivi Commande-->

    <!-- Début row ma commande-->
    <div class="row">
      <div class="col-sm-12">
        <h2 style="font-size: 20px;">Ma Commande</h2>
        <div class="card mb-3" *ngFor="let itemShoppers of commadShopperById">
          <div class="card-body">
            <p *ngIf="itemShoppers.lste_achat && itemShoppers.lste_achat.user.id === commonService.user.id.userId">Ce produit a été acheté par {{ itemShoppers.acheteur.pseudo }} sur votre liste d'achats {{ itemShoppers.lste_achat.event }} {{ itemShoppers.lste_achat.title }}</p>
            <p *ngIf="itemShoppers.lste_achat && itemShoppers.lste_achat.user.id !== commonService.user.id.userId">Vous avez acheté ce produit sur la liste d'achats de {{ itemShoppers.lste_achat.user.pseudo }} {{ itemShoppers.lste_achat.event }} {{ itemShoppers.lste_achat.title }}</p>
            <div class="row">
              <div class="col-lg-3 col-sm-3 col-4">
                <img width="auto" height="auto" [src]="itemShoppers.sous_produit.produit.image" alt="" style="height: 100px;" class="img-fluid">
              </div>
              <div class="col-lg-3 col-sm-4 col-8">
                <p class="m-0 pr-0" style="font-size: 14px; color: #2b9746;">
                  {{ itemShoppers.sous_produit.produit.model }}</p>
                <p class="m-0 pr-0" style="font-size: 14px; color: #6A6B6A;">Vendu par :
                  <span style="text-decoration: underline; color: #000;">{{ itemShoppers.vendeur.pseudo }}</span>
                </p>
                <button fragment="commanddd" *ngIf="itemShoppers.statut_acheteur !== 'EN_COURS' && itemShoppers.statut_acheteur !== 'INITIALISEE'" class="h-btn-green-green" style="height: 34px; font-size: 12px;" data-toggle="modal" data-target="#contacterVendeur" (click)="onItemShoppers(itemShoppers)">CONTACTER LE VENDEUR</button>
              </div>
              <div class="col-lg-3 col-sm-2 col-7 pr-0">
                <p class="m-0 pr-0" style="font-size: 14px; color: #6A6B6A;">Quantité : {{ itemShoppers.quantite }}</p>
                <p *ngIf="itemShoppers.sous_produit.taille && itemShoppers.sous_produit.taille !== 'null'" class="m-0 pr-0" style="font-size: 14px; color: #6A6B6A;">Taille : {{ itemShoppers.sous_produit.taille.valeur }}</p>
                <div style="display: flex;">
                  <span *ngIf="itemShoppers.sous_produit.couleur && itemShoppers.sous_produit.couleur !== null" class="m-0 pr-0" style="font-size: 14px; color: #6A6B6A;">Couleur &nbsp; </span>
                  <div *ngIf="itemShoppers.sous_produit.couleur && itemShoppers.sous_produit.couleur !== null" style="height: 20px; width:20px; border: 2px solid;"
                    [style.background-color]="itemShoppers.sous_produit.couleur.code_hexa"
                    [style.border-color]="itemShoppers.sous_produit.couleur.code_hexa == '#ffffff' ? 'lightgray' : itemShoppers.sous_produit.couleur.code_hexa"
                  ></div>
                  <span class="ml-2" style="font-size: 14px; color: #6A6B6A;" *ngIf="itemShoppers.sous_produit.couleur">{{itemShoppers.sous_produit.couleur.nom}}</span>
                </div>
              </div>
              <div class="col-lg-3 col-sm-3 col-5">
                <!-- TrustBox widget - Review Collector -->
                <div class="trustpilot-widget" data-locale="fr-FR" data-template-id="56278e9abfbbba0bdcd568bc" data-businessunit-id="6152d7a2916a86001d1a9a41" data-style-height="52px" style="width: 75px; margin-left: auto;" data-style-width="74px">
                  <a href="https://fr.trustpilot.com/review/hadeen-place.fr" target="_blank" rel="noopener">Trustpilot</a>
                </div>
                <!-- End TrustBox widget -->
                <p class="mt-4" style="color: #2b9746; font-size: 26px; text-align: right;">
                  {{itemShoppers.prix_acheteur }} €
                </p>
              </div>
            </div>
            <div class="row mt-2" *ngIf="itemShoppers.statut_acheteur !== 'EN_COURS'">
              <div class="col-lg-3 col-md-12 mb-0">
                <button class="h-btn-black-bordered w-100 text-center" style="height: 35px; font-size: 12px;" data-toggle="modal" data-target="#noteVendeur" [disabled]="itemShoppers.is_feed_vendeur" (click)=onItemNoteSeller(itemShoppers)>
                  <div class="d-flex flex-row">
                    <i *ngIf="!itemShoppers.is_feed_vendeur" class="fas fa-star ml-auto" style="color: orange; padding-top: 2px;"></i>
                    <img width="auto" height="auto" class="ml-auto" *ngIf="itemShoppers.is_feed_vendeur" style="width: 1rem;" src="../../../../../../assets/images/icons/check.svg" alt="">
                    <span class="ml-2 mr-auto">
                      {{ !itemShoppers.is_feed_vendeur ? 'Notez le vendeur' : 'Vendeur noté'}}
                    </span>
                  </div>
                </button>
              </div>

              <div class="col-lg-8 col-md-12 col-12 mb-0">
                <div class="row mt-1">
                  <div class="col-lg-5 offset-lg-2 col-md-6 col-12 mb-0">
                    <p *ngIf="!itemShoppers.is_feed_produit" class="mt-1 mb-1" style="font-size: 15px;">Vous aimez ce produit ?</p>
                  </div>
                  <div class="col-lg-5 col-md-6 col-12 text-right">
                    <button class="h-btn-black-bordered w-100" style="height: 35px; font-size: 12px;" [disabled]="itemShoppers.is_feed_produit" (click)="openModalProduit(itemShoppers)">
                      <div class="d-flex flex-row">
                        <i *ngIf="!itemShoppers.is_feed_produit" class="fas fa-star ml-auto" style="color: orange; padding-top: 2px;"></i>
                        <img width="auto" height="auto" class="ml-auto" *ngIf="itemShoppers && itemShoppers.is_feed_produit" style="width: 1rem;" src="../../../../../../assets/images/icons/check.svg" alt="">
                        <span class="ml-2 mr-auto" style="color: white;">
                          {{ !itemShoppers.is_feed_produit ? 'Notez le produit' : 'Produit noté'}}
                        </span>
                      </div>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Fin row ma commande-->

    <div class="row">
        <!--début row Total Articles-->
        <div class="col-lg-12 col-sm-12 col-12">
            <div class="card">
                <div class="card-header" style="display: flex;">
                    <p class="text-left">Total articles : ({{ commadShopperById.length }})</p>
                    <p class="text-right" style="margin-left: auto; font-size: 20px; color: #2b9746;">
                        {{ totalPriceCommande(commadShopperById) | number : '1.2-2' }} €</p>
                </div>
                <div class="card-header" style="display: flex;" *ngIf="totalPromo(commadShopperById)>0">
                    <p class="text-left">Total promotion : </p>
                    <p class="text-right" style="margin-left: auto; font-size: 20px; color: #2b9746;">
                        -{{ totalPromo(commadShopperById) | number : '1.2-2' }} €</p>
                </div>
                <div class="card-header" style="display: flex;">
                    <p class="text-left">{{fraisLivraison === 0 ? 'Livraison incluse' : 'Frais de livraison'}}</p>
                    <p class="text-right" style="margin-left: auto; font-size: 20px; color: #2b9746;">
                        {{ fraisLivraison | number : '1.2-2' }} €</p>
                </div>
                <div class="card-footer" style="display: flex;">
                    <p class="text-left">Total TTC : </p>
                    <p class="text-right" style="margin-left: auto; font-size: 25px; color: #2b9746;">
                        {{ totalPriceCommande(commadShopperById) - totalPromo(commadShopperById) + fraisLivraison | number : '1.2-2' }} €</p>
                </div>
            </div>
        </div>
    </div>
    <!--Fin row Total Articles-->

    <div class="row mb-3" *ngIf="commadShopperById.length != 0">
        <div class="col-lg-12 col-sm-12 col-12">
            <h2 style="font-size: 20px;">Récapitulatif de livraison</h2>
            <div class="card" style="background-color: rgba(0,0,0,.03);">
                <div class="card-body">
                    <p>Vos coordonnées pour la livraison</p>
                    <p class="m-0 pr-0">{{ commadShopperById[0].adresse.prenom }} {{ commadShopperById[0].adresse.nom }} </p>
                    <p class="m-0 pr-0">{{ commadShopperById[0].adresse.adresse }}</p>
                    <p class="m-0 pr-0" *ngIf="commadShopperById[0].adresse.complement_adresse">
                        {{ commadShopperById[0].adresse.complement_adresse }}
                    </p>
                    <p class="m-0 pr-0">{{ commadShopperById[0].adresse.ville }}</p>
                    <p class="m-0 pr-0">{{ commadShopperById[0].adresse.code_postal }}</p>
                    <p class="m-0 pr-0">{{ commadShopperById[0].adresse.telephone }}</p>
                </div>
            </div>
        </div>
    </div>

    <div class="row">
        <!--Début Row récapitulatif-->
        <div class="col-lg-6 col-sm-12 mb-2" *ngFor="let command of commadShopperById">
            <div class="card" style="background-color: rgba(0,0,0,.03);">
                <div class="card-body">
                    <p>Commande (n°: {{ command.id }}) passée le : {{ command.created_at | date }}</p>
                </div>
            </div>
        </div>
        <div class="col-lg-6 col-sm-12">
            <div class="card" style="background-color: rgba(0,0,0,.03);">
                <div class="card-body">
                    <p>Mode de paiement : Carte de crédit</p>
                </div>
            </div>
        </div>
    </div>
    <!--Fin Row récapitulatif-->

    <div class="row">
        <!-- début formulaire contact-->
        <div class="col-sm-12">
            <h2 style="font-size: 20px;">Contactez-nous au sujet de cette commande</h2>
        </div>
        <div class="col-sm-12 mb-3">
            <form class="form" [formGroup]="contactSellerByForm" (ngSubmit)="onContactSellerByCommande(commadShopperById)">
                <select name="commandes" id="commandes" formControlName="article" class="form-control col-sm-12 mb-2 seleCt input">
        <option value="" disabled>Selectionnez un article</option>
        <option [ngValue]="item.sous_produit.produit.model" *ngFor="let item of commadShopperById">
          Commande N° {{ item.id }} : {{ item.sous_produit.produit.model }}</option>
      </select>
                <div class="text-right">
                    <textarea formControlName="Message" class="form-control col-sm-12 area" name="command" id="commanda" rows="5" placeholder="Message"></textarea>
                    <button type="submit" class="h-btn-green-green" [disabled]="contactSellerByForm.invalid" style="margin-top: 10px;">ENVOYER</button>
                </div>
            </form>
        </div>
    </div>
    <!-- Fin formulaire contact-->

  </div>
  <!-- Fin Colone Mes Commandes acheteur-->
</div>
<!-- Fin row compte-nav + Mes Comandes acheteur-->




<!-- modal pour noter un produit -->
<div class="modal fade" id="modalNoteProduit" tabindex="-1" role="dialog" aria-labelledby="modalLabel" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content" style="border-radius: 8px;">
      <ng-container *ngIf="commandSelected">
        <div class="modal-header">
          <h5>{{ commandSelected.sous_produit.produit.model | titlecase }}</h5>

          <ngb-rating [(rate)]="commandSelected.sous_produit.produit.star" [(max)]="maxRate" class="noBroder">
            <ng-template let-fill="fill" let-index="index">
              <span class="star" [class.filled]="fill === 100">&#9733;</span>
            </ng-template>
          </ngb-rating>
          <span style="font-size: 20px;">{{commandSelected.sous_produit.produit.star}}/5</span>
          <button type="button" class="h-btn btnCloseModal mt-1" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div class="col-sm-12">
            <textarea class="form-control col-sm-12 area" rows="5" placeholder="Merci d’insérer votre commentaire" [(ngModel)]="commandSelectedCommentaire"></textarea>
            <button [disabled]="commandSelected.sous_produit.produit.star<1" class="h-btn-black-bordered float-right mt-2" style="height: 40px;" (click)="addNoteProduit()" data-dismiss="modal">Noter le produit</button>
          </div>
        </div>
      </ng-container>
    </div>
  </div>
</div>
<!-- end modal pour noter un produit -->

<!-- debut modal Notez le vendeur -->
<div class="modal fade" id="noteVendeur" tabindex="-1" role="dialog" aria-labelledby="modalLabel" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content" style="border-radius: 8px;">
      <div class="modal-header">
        <h5 *ngIf="elementSelectedNoteSeller" class="modal-title" id="modalLabel">
          {{ elementSelectedNoteSeller.vendeur.pseudo}}
        </h5>

        <ngb-rating [(rate)]="noteActuelle" [(max)]="maxRate" class="noBroder" style="z-index: 99999;">
          <ng-template let-fill="fill" let-index="index">
            <span class="star" [class.filled]="fill === 100">&#9733;</span>
          </ng-template>
        </ngb-rating>
        <span style="font-size: 20px;">{{ noteActuelle }}/5</span>
        <button type="button" class="h-btn btnCloseModal mt-1" #closeModalNote data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="col-sm-12">
          <form class="form" [formGroup]="noteSellerForm" (ngSubmit)="onSendNoteVendeur()">
            <div class="text-right">
              <textarea class="form-control col-sm-12 area" name="com" id="com" rows="5" placeholder="Merci d’insérer votre commentaire" formControlName="comment"></textarea>
              <button type="submit" [disabled]="noteSellerForm.invalid" class="h-btn-black-bordered mt-2">Noter</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- fin modal Notez le vendeur -->

<!-- debut modal Contacter le vendeur -->
<div class="modal fade" id="contacterVendeur" tabindex="-1" role="dialog" aria-labelledby="modalLabe" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content" style="border-radius: 8px;">
      <div class="modal-header" style="margin-left: auto;">
        <button type="button" class="h-btn btnCloseModal" #closeModalContact data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body pt-0">
        <div class="col-sm-12">
          <form class="form" [formGroup]="contactSellerForm" (ngSubmit)="onContactVendeur()">
            <div class="form-group">
              <div class="text-right">
                <textarea class="form-control col-sm-12 area" name="msg" id="msg" rows="5" placeholder="votre message" formControlName="message"></textarea>
                <button type="submit" (click)="onContactVendeur()" [disabled]="contactSellerForm.invalid" class="h-btn-black-bordered contactAcheteur mt-2 pl-3 pr-3">Envoyer</button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- fin modal contacter le vendeur -->
