<div class="modal-header sign-header">
    <div class="container">
        <div class="row" style="cursor: pointer;">
            <div class="col-6 pl-0 pl-md-3 pr-0 pr-md-3 {{accountClass}}" (click)="newsCustomerBtn()">
                <h2>Je suis nouveau client</h2>
            </div>
            <div class="col-6 pl-0 pl-md-3 pr-0 pr-md-3 {{newClass}}" (click)="newsCustomerBtn()">
                <h2>J'ai déjà un compte</h2>
            </div>
        </div>
    </div>
    <button type="button" class="close pull-right login" aria-label="Close" (click)="modal.hide()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
    <div class="signin" *ngIf="newsCustomer">
        <h5>Bonjour</h5>
        <h6>{{ title }}</h6>
        <div class="row sociallogin">
            <div class="col-12 col-md-6 col-sm-6 col-lg-6 text-center"><button (click)="connecterViaGoogle()"><img
            src="assets/static/g.PNG" style="height:16px" alt=""><span>avec
            Google</span></button> </div>
            <div class="col-12 col-md-6 col-sm-6 col-lg-6 text-center"><button (click)="connecterViaFB()"><img
            src="assets/static/f.PNG" alt=""><span>avec
            Facebook</span></button></div>
            <div class="col-12">
                <h6>- ou -</h6>
            </div>
        </div>
        <form>
            <div class="col-12" style="background-color: white;">
                <div class="field">
                    <input type="text" name="votreemail" id="votreemail" [(ngModel)]="votreEmail" placeholder="   ">
                    <label for="votreemail" id="votreemail">Votre email</label>
                </div>
                <div class="field">
                    <span class="fa" (click)="showPassLogin()"><img width="auto" height="auto" src="assets\static\{{faLogin}}.png" alt=""></span>
                    <input type="{{signe}}" name="mdp" id="mdp" [(ngModel)]="mdp" placeholder="   ">
                    <label for="mdp" id="mdp">Votre mot de passe</label>
                </div>
                <p style="cursor: pointer;" (click)="goTo('reset-mdp', 'params')">Mot de passe oublié ?</p>
                <br>
                <div class="checking">
                    <label class="control control-checkbox ">Rester connecté
            <input type="checkbox" id="beSellerID" name="filter" />
            <div class="control_indicator"></div>
          </label>
                </div>
                <button class="signin" (click)="signIn(votreEmail, mdp)">Se connecter avec votre compte</button>
            </div>
        </form>
    </div>
    <div class="signin" *ngIf="!newsCustomer">
        <h5>Bonjour</h5>
        <h6>Inscrivez-vous et connectez avec votre compte</h6>
        <div class="row sociallogin">
            <div class="col-12 col-md-6 col-sm-6 col-lg-6 text-center"><button (click)="creationCompteGoogle()"><img
            src="assets/static/g.PNG" style="height:16px" alt=""><span>avec
            Google</span></button> </div>
            <div class="col-12 col-md-6 col-sm-6 col-lg-6 text-center"><button (click)="creationCompteFb()"><img
            src="assets/static/f.PNG" alt=""><span>avec
            Facebook</span></button></div>
            <div class="col-12">
                <h6>- ou -</h6>
            </div>
        </div>
        <form class="row" [formGroup]="formRegister">
            <div class="col-6 col-md-6 col-sm-6 col-lg-6 text-center " (click)="selectSexe()"><a class="btn {{MselectSexe}}">M</a> </div>
            <div class="col-6 col-md-6 col-sm-6 col-lg-6 text-center " (click)="selectSexe()"><a class="btn {{FselectSexe}}">Mme</a></div>
            <div class="col-12" style="background-color: white;">
                <div class="form-group" [ngClass]="{'has-error': formRegister.controls.prenom.errors && formRegister.controls.prenom.dirty, 'has-success': formRegister.controls.prenom.valid && formRegister.controls.prenom.dirty }">
                    <div class="field">
                        <input type="text" #prenom name="prenom" id="prenom" placeholder="   " formControlName="prenom" autocomplete=off>
                        <label for="prenom" id="prenom">Prénom*</label>
                    </div>
                    <ul class="help-block">
                        <li *ngIf="formRegister.controls.prenom.errors?.required && (formRegister.controls.prenom.dirty || errorForm)">
                            Les champs marqués d’une * sont obligatoires</li>
                    </ul>
                </div>
                <div class="form-group" [ngClass]="{'has-error': formRegister.controls.nom.errors &&  (formRegister.controls.nom.dirty || errorForm), 'has-success': formRegister.controls.nom.valid && formRegister.controls.nom.dirty }">
                    <div class="field">
                        <input type="text" name="npm" id="nom" placeholder="   " formControlName="nom" autocomplete=off>
                        <label for="nom" id="nom">Nom*</label>
                    </div>
                    <ul class="help-block">
                        <li *ngIf="formRegister.controls.nom.errors?.required && (formRegister.controls.nom.dirty || errorForm)">
                            Les champs marqués d’une * sont obligatoires</li>
                    </ul>
                </div>
                <div class="form-group" [ngClass]="{'has-error': (formRegister.controls.email.errors &&
formRegister.controls.email.dirty) || (!checkMail && formRegister.controls.email.dirty), 'has-success':
formRegister.controls.email.valid && formRegister.controls.email.dirty && checkMail }">
                    <div class="field">
                        <!-- <input type="text" name="uremail" id="uremail" placeholder="   " formControlName="email" autocomplete=off pattern="[\-'\/&a-zÀ-ÿ0-9 ]+"> -->
                        <input type="text" name="uremail" id="uremail" placeholder="   " formControlName="email" autocomplete=off>
                        <label for="uremail" id="uremail">Votre email</label>
                    </div>
                    <ul class="help-block">
                        <li *ngIf="formRegister.controls.email.errors?.required && (formRegister.controls.email.dirty || errorForm)">
                            Les champs marqués d’une * sont obligatoires</li>
                        <li *ngIf="formRegister.controls.email.errors?.checkMail && (formRegister.controls.email.dirty || errorForm)">
                            Veuillez insérer un mail valide</li>
                    </ul>
                </div>
                <div class="form-group" [ngClass]="{'has-error': (formRegister.controls.password.errors &&
formRegister.controls.password.dirty)  || (!checkpw && formRegister.controls.password.dirty),
'has-success': formRegister.controls.password.valid &&
formRegister.controls.password.dirty && checkpw }">
                    <div class="field">
                        <span class="fa register" (click)="showPassLogin()"><img width="auto" height="auto" src="assets\static\{{faLogin}}.png"
                alt=""></span>
                        <input type="{{signe}}" name="mdpregister" id="mdpregister" placeholder="   " formControlName="password" autocomplete=off>
                        <label for="mdpregister" id="mdpregister">Votre mot de passe</label>
                    </div>
                    <ul class="help-block">
                        <li *ngIf="formRegister.controls.password.errors?.required && (formRegister.controls.password.dirty || errorForm)">
                            Les champs marqués d’une * sont obligatoires</li>
                        <li *ngIf="formRegister.get('password').hasError('checkPseudo') && (formRegister.controls.password.dirty || errorForm)">
                            Le mot de passe doit contenir au moins une Majuscule, une minuscule et une chiffre</li>
                    </ul>
                </div>

                <div>
                    <p style="text-align: justify;font-size: 12px;float: inherit;">Le mot de passe doit contenir au moins une Majuscule, une minuscule et une chiffre</p>
                    <h6 style="    text-align: justify;">Les champs marqués d’une * sont obligatoires</h6>
                    <div class="checking">
                        <label class="control control-checkbox ">Je souhaite recevoir par e-mail les remises et
              astuces
              de la Brigade Hadéen-place
              <input type="checkbox" id="emailnewsletter" name="filter" [checked]="newsL"
                (change)="checknewl($event)" />
              <div class="control_indicator"></div>
            </label>
                    </div>
                    <div class="checking">
                        <label class="control control-checkbox ">J'accepte les conditions générales et
              <span class="px-2" style="text-decoration: underline;cursor: pointer;" [routerLink]="['/conditions-de-ventes']" routerLinkActive="router-link-active" >
                la politique de confidentialité</span>
              <input type="checkbox" id="cgu" name="cgu" [checked]=true readonly />
              <div class="control_indicator"></div>
            </label>
                    </div>
                </div>
                <button class="signin btn-singup" (click)="createAccount()" [disabled]="!checkMail || requestProcess">Créer mon compte</button>
            </div>
        </form>
    </div>
</div>
