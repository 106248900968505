import { Injectable } from '@angular/core';

declare var ga;
declare var gtag;

@Injectable({
  providedIn: 'root'
})
export class GoogleAnalyticsEventsService {

  constructor() { }

  public eventEmitter(
    eventName: string,
    eventCategory: string,
    eventAction: string,
    eventLabel: string = null,
    eventValue: number = null
  ) {
    // gtag('event', eventName, {
    //   eventCategory: eventCategory,
    //   eventLabel: eventLabel,
    //   eventAction: eventAction,
    //   eventValue: eventValue
    // });
  }

  trackAddToCart(productId) {
    this.eventEmitter('add_to_cart', 'shop', 'cart', 'click', productId);
  }

  trackValidateCommand() {
    this.eventEmitter('validate_command', 'shop', 'cart', 'click', 10);
  }

  trackPaymentSuccess() {
    this.eventEmitter('payment_success', 'payement', 'result', 'click', 10);
  }

  analyticTrack(type, name, asadasds, value) {
    this.eventEmitter('payment_success', 'payement', 'result', 'click', 10);
  }

  public emitPageview(url: string) {
    // gtag('config', 'G-REXPXS6TTM', {
    //   page_view: url
    // });
  }

  trackPageView() {}
}
