<div class="bodyListing" style="background-color: #F6FAF0;">
  <div class="top-header">
    <div class="container">
      <div class="header d-none d-md-block">
        <ul class="breadcrumb">
          <li [routerLink]="['/']"><a>Hadéen-place</a></li>
          <li *ngIf="univer" [routerLink]="['/all-product/' + univerBrute]"> {{ univer | univerPipe}} </li>
          <li *ngIf="sousUniver" [routerLink]="['/all-product/' + univerBrute + '/' + sousUniverBrute]"> {{ sousUniver | univerPipe
            }} </li>
          <li *ngIf="categorie">{{ categorie | univerPipe }}</li>
        </ul>
      </div>
      <div class="main-header" style="margin-top: -10px;">
        <h1 style="font-size: 24px !important; font-family: 'SFCompactDisplay-Regular'; font-weight: 600;"> {{ (categorie ? categorie : sousUniver ? sousUniver : univer ? univer :
          '') | univerPipe }} </h1>
        <div class="col-12 mb-3" *ngIf="mea_detail">
          <p [ngClass]="{'limitTextHeight': isReadMore}">
            {{mea_detail.description}}
          </p>
          <div *ngIf="mea_detail.description.length > 50" class="text-right mt-2 d-md-none" style="font-size: 13px;"><a (click)="showText()">{{ isReadMore ? '... Voir plus': 'Voir moins ...' }}</a></div>
        </div>
      </div>
    </div>
    <hr>
  </div>

  <div class="container">
    <div class="col-12 my-3 mis_en_avant" *ngIf="isEnAvant && mea_detail">
      <div style="position: absolute; top: 50%; left: 50%; transform: translate(-50%, -50%); text-shadow: #fff 1px 1px, #fff -1px 1px, #fff -1px -1px, #fff 1px -1px;">
        <h1 class="mea--title" *ngIf="mea_detail.name">{{mea_detail.name}}</h1>
      </div>
      <div class="ratio image_head">
        <img [src]="mea_detail.image" alt="" class="w-100 h-100" style="object-fit: cover;">
      </div>
    </div>
  </div>

  <div class="d-flex justify-content-around align-items-start">
    <ng-container *ngIf="!isVenteFlash">
      <!-- <div style="width: 200px;" class="d-none d-lg-block" id="sticky">
        <div class="filtre-row pl-0 p-lg-1">
          <div>
            <div class="mb-3" *ngIf="!univer">
              <h4 style="text-decoration: underline; font-weight: 600;"> UNIVERS </h4>
              <ul class="p-0">
                <li *ngFor="let item of listUnivers" [routerLink]="['/all-product/' + formatUrl(item.nom)]">
                  {{item.nom}}
                  <span *ngIf="item.nb">({{item.nb}} )</span>
                </li>
              </ul>
            </div>

            <div class="mb-3" *ngIf="univer && !sousUniver">
              <h4 style="text-decoration: underline; font-weight: 600;" type="button" data-toggle="collapse"
                data-target="#collapseSousUnivers">SOUS UNIVERS</h4>
              <ul class="p-0 collapse" id="collapseSousUnivers">
                <li *ngFor="let item of listSousUnivers"
                  [routerLink]="['/all-product/' + formatUrl(univer) + '/' + formatUrl(item.nom)]">
                  {{item.nom}} <span *ngIf="item.nb">({{item.nb}} )</span></li>
              </ul>
            </div>

            <div class="mb-3" *ngIf="univer && sousUniver && !categorie">
              <h4 style="text-decoration: underline; font-weight: 600;" type="button" data-toggle="collapse"
                data-target="#collapseCategorie">CATÉGORIE</h4>
              <ul class="p-0 collapse" id="collapseCategorie">
                <li *ngFor="let item of sousUniv"
                  [routerLink]="['/all-product/' + formatUrl(univer) + '/' + formatUrl(sousUniver) + '/' + formatUrl(item.nom)]">
                  {{item.nom}} <span *ngIf="item.nb">({{item.nb}} )</span></li>
              </ul>
            </div>

            <div *ngIf="caracs && caracs.caracteristiques && caracs.caracteristiques.length">
              <div *ngFor="let item of caracs.caracteristiques; index as i" class="mb-4">
                <h4 *ngIf="item.caracteristique.type === 'SELECT' && item.caracteristique.nom"
                  style="text-decoration: underline; font-weight: 600;" type="button" data-toggle="collapse"
                  [attr.data-target]="'#collapse' + i">{{ item.caracteristique.nom | uppercase }}</h4>

                <section class="row example-section collapse" *ngIf="item.caracteristique.type === 'SELECT'"
                  id="collapse{{i}}">
                  <mat-checkbox class="col-12 example-margin" *ngFor="let value of item.valeurs; let ind = index"
                    [(ngModel)]="value.checked" (click)="addNewFilter(item.caracteristique.code,value)"> {{
                    value.item.valeur }}
                  </mat-checkbox>
                </section>
              </div>
            </div>


            <div class="mb-4" *ngIf="caracs && caracs.couleurs && caracs.couleurs.length">
              <h4 style="text-decoration: underline; font-weight: 600;" type="button" data-toggle="collapse"
                data-target="#collapsecouleur">COULEUR</h4>
              <section class="row example-section collapse" id="collapsecouleur">
                <mat-checkbox class="col-12 example-margin"
                  *ngFor="let couleur of caracs.couleurs; let indCouleur = index" [(ngModel)]="couleur.checked"
                  (change)="setSet(caracs.couleurs, indCouleur)"> {{couleur.nom}} </mat-checkbox>
              </section>
            </div>

            <div class="mb-4" *ngIf="caracs && caracs.tailles && caracs.tailles.length">
              <h4 style="text-decoration: underline; font-weight: 600;" type="button" data-toggle="collapse"
                data-target="#collapsetaille">TAILLE</h4>
              <section class="row example-section collapse" id="collapsetaille">
                <mat-checkbox class="col-12 example-margin"
                  *ngFor="let taille of caracs.tailles[0].tailles; let indTaille = index" [(ngModel)]="taille.checked"
                  (change)="setSet(caracs.tailles[0].tailles, indTaille)"> {{taille.valeur}} </mat-checkbox>
              </section>
            </div>
          </div>
        </div>

      </div> -->
    </ng-container>
    <div class="container mx-2 w-100">
      <ng-container *ngIf="isVenteFlash; else elseTemplate">
        <h1 style="font-size: 24px !important; text-align: center;">NOTRE SÉLECTION</h1>
      </ng-container>
      <ng-template #elseTemplate>
        <div class="row" *ngIf="!isVenteFlash && !isEnAvant">
          <div class="mb-3 mr-3">
            <button [ngClass]="this.filter.croissant ? 'newbutton-active' : 'newbutton'" type="button" (click)="this.filter.croissant = true; search()">Prix croissant</button>
          </div>

          <div class="mb-3 mr-3">
            <button [ngClass]="this.filter.croissant === false ? 'newbutton-active' : 'newbutton'" type="button" (click)="this.filter.croissant = false; search()">Prix décroissant</button>
          </div>

          <div class="mb-3 mr-3" *ngIf="!univer">
            <div class="uk-inline">
              <button class="newbutton" type="button">UNIVERS</button>
              <div uk-dropdown="mode: click">
                <ul class="p-0">
                  <li *ngFor="let item of listUnivers" [routerLink]="['/all-product/' + formatUrl(item.nom)]">
                    {{item.nom}}
                    <span *ngIf="item.nb">({{item.nb}} )</span>
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <div class="mb-3 mr-3" *ngIf="univer && !sousUniver">
            <div class="uk-inline">
              <button class="newbutton" type="button">SOUS UNIVERS</button>
              <div uk-dropdown="mode: click">
                <ul class="p-0" id="collapseSousUnivers">
                  <li *ngFor="let item of listSousUnivers"
                    [routerLink]="['/all-product/' + formatUrl(univer) + '/' + formatUrl(item.nom)]">
                    {{item.nom}} <span *ngIf="item.nb">({{item.nb}} )</span></li>
                </ul>
              </div>
            </div>
          </div>

          <div class="mb-3 mr-3" *ngIf="univer && sousUniver && !categorie">
            <div class="uk-inline">
              <button class="newbutton" type="button">CATÉGORIE</button>
              <div uk-dropdown="mode: click">
                <ul class="p-0" id="collapseCategorie">
                  <li *ngFor="let item of sousUniv"
                    [routerLink]="['/all-product/' + formatUrl(univer) + '/' + formatUrl(sousUniver) + '/' + formatUrl(item.nom)]">
                    {{item.nom}} <span *ngIf="item.nb">({{item.nb}} )</span></li>
                </ul>
              </div>
            </div>
          </div>

          <div *ngIf="caracs && caracs.caracteristiques && caracs.caracteristiques.length">
            <div class="uk-inline" style="display: inline-flex;">
              <div *ngFor="let item of caracs.caracteristiques; index as i" class="mb-3 mr-3">
                <button class="newbutton" type="button">{{ item.caracteristique.nom | uppercase }}</button>
                <div uk-dropdown="mode: click">
                  <section class="row example-section" *ngIf="item.caracteristique.type === 'SELECT'"
                    id="collapse{{i}}">
                    <mat-checkbox class="col-12 example-margin" *ngFor="let value of item.valeurs; let ind = index"
                      [(ngModel)]="value.checked" (click)="addNewFilter(item.caracteristique.code,value)"> {{
                      value.item.valeur }}
                    </mat-checkbox>
                  </section>
                </div>
              </div>
            </div>
          </div>

          <div class="mb-3 mr-3" *ngIf="caracs && caracs.couleurs && caracs.couleurs.length">
            <div class="uk-inline">
              <button class="newbutton" type="button">COULEUR</button>
              <div uk-dropdown="mode: click">
                <section class="row example-section" id="collapsecouleur">
                  <mat-checkbox class="col-12 example-margin"
                    *ngFor="let couleur of caracs.couleurs; let indCouleur = index" [(ngModel)]="couleur.checked"
                    (change)="setSet(caracs.couleurs, indCouleur)"> {{couleur.nom}} </mat-checkbox>
                </section>
              </div>
            </div>
          </div>

          <div class="mb-3 mr-3" *ngIf="caracs && caracs.tailles && caracs.tailles.length">
            <div class="uk-inline">
              <button class="newbutton" type="button">TAILLE</button>
              <div uk-dropdown="mode: click">
                <section class="row example-section" id="collapsetaille">
                  <mat-checkbox class="col-12 example-margin"
                    *ngFor="let taille of caracs.tailles[0].tailles; let indTaille = index" [(ngModel)]="taille.checked"
                    (change)="setSet(caracs.tailles[0].tailles, indTaille)"> {{taille.valeur}} </mat-checkbox>
                </section>
              </div>
            </div>
          </div>
        </div>

        <div class="row mb-2">
          <div class="col-12 col-md-12 col-lg-3 mb-0 mt-2 leftTOcenter" style="font-weight: 600;">{{ nbrProd || 0 }}
            Article(s)
          </div>
          <!-- <span *ngIf="!isEnAvant" class="col-6 col-md-12 col-lg-9 pl-0 order-lg-2 text-right">
            <span class="onlyWeb">Trier par:</span>
            <select class="selectpicker" name="selectedTrad" [(ngModel)]="this.filter.croissant"
              (ngModelChange)="search()" #selectedTrad="ngModel">
              <option *ngIf="this.filter.croissant === null" value="null" disabled selected style="display: none;">le
                moins cher</option>
              <option value="true">le moins cher</option>
              <option value="false">le plus cher</option>
            </select>
          </span>
          <div class="col-6 d-block d-lg-none pl-2 pr-2">
            <div class="pt-1 pl-2 pb-1" (click)="openModalFiltre(filtreModal)"
              style="border: 1px solid rgb(59, 59, 59);">
              Filtrer par</div>
          </div> -->
        </div>

      </ng-template>
    </div>
  </div>

  <div class="d-flex justify-content-around align-items-start">
    <div class="container-xl mx-2">
      <div class="row mt-3">
        <div class="col-6 col-sm-4 col-md-3 col-lg-2 px-xs-0 mb-4"
          *ngFor="let product of productsToShow | paginate: { itemsPerPage: ITEM_PER_PAGE, currentPage: p ,totalItems: nbrProd};let i=index">
          <div [ngClass]="i%2 === 0 ? 'pr-15' : 'pl-15'">
            <app-produit-input [data]="product" [loginTemplate]="loginTemplate"></app-produit-input>
          </div>
        </div>

        <div class="col-12 text-center mt-4" *ngIf="!productsToShow.length">
          <img width="auto" height="auto" src="assets/images/logo/refus@2x.png" alt="" style="width: 50px;">
          <p>Aucun produit n'a été trouvé</p>
        </div>
      </div>
      
      <pagination-controls class="modal-pagination text-center" (pageChange)="changePage($event)" directionLinks="true"
        responsive="true" autoHide="true" previousLabel="PRÉCÉDENT" nextLabel="SUIVANT">
      </pagination-controls>
    </div>
  </div>

</div>

<ng-template #filtreModal>
  <div class="modal-header" style="max-width: 96vw;">
    <div class="container pl-0">
      <button type="button" style="margin-right: 10px;" class="close pull-right" aria-label="Close"
        (click)="modalRef.hide()">
        <span aria-hidden="true">&times;</span>
      </button>
      <h4 class="hello-burger pull-left">Filtrer par</h4>
    </div>
  </div>
  <div class="modal-body" style="font-family: SFCompactDisplay-Medium;">
    <div>
      <!-- univers -->
      <div *ngIf="!univer">
        <mat-accordion>
          <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false"
            style="background:white !important;">
            <mat-expansion-panel-header style="padding-left:0px !important;">
              <mat-panel-title>
                <h4 style="text-decoration: underline; font-weight: 600;"> UNIVERS </h4>
              </mat-panel-title>
            </mat-expansion-panel-header>
            <ul class="p-0 font-weight-bold">
              <li *ngFor="let item of listUnivers" [routerLink]="['/all-product/' + item.nom]"> {{item.nom}} <span
                  *ngIf="item.nb">({{item.nb}} )</span></li>
            </ul>
          </mat-expansion-panel>
        </mat-accordion>
      </div>

      <!-- sous univers -->
      <div *ngIf="univer && !sousUniver">
        <mat-accordion>
          <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false"
            style="background:white !important;">
            <mat-expansion-panel-header style="padding-left:0px !important;">
              <mat-panel-title>
                <h4 style="text-decoration: underline; font-weight: 600;"> SOUS UNIVERS </h4>
              </mat-panel-title>
            </mat-expansion-panel-header>
            <ul class="p-0 font-weight-bold">
              <li *ngFor="let item of listSousUnivers" [routerLink]="['/all-product/' + univer + '/' + item.nom]">
                {{item.nom}} <span *ngIf="item.nb">({{item.nb}} )</span></li>
            </ul>
          </mat-expansion-panel>
        </mat-accordion>
      </div>

      <!-- catégorie -->
      <div *ngIf="univer && sousUniver && !categorie">
        <mat-accordion>
          <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false"
            style="background:white !important;">
            <mat-expansion-panel-header style="padding-left:0px !important;">
              <mat-panel-title>
                <h4 style="text-decoration: underline; font-weight: 600;"> CATEGORIES </h4>
              </mat-panel-title>
            </mat-expansion-panel-header>
            <ul class="p-0 font-weight-bold">
              <li *ngFor="let item of sousUniv"
                [routerLink]="['/all-product/' + univer + '/' + sousUniver + '/' + item.nom]">
                {{item.nom}} <span *ngIf="item.nb">({{item.nb}} )</span></li>
            </ul>
          </mat-expansion-panel>
        </mat-accordion>
      </div>

      <!-- caracteristique -->
      <div *ngIf="caracs && caracs.caracteristiques && caracs.caracteristiques.length">
        <mat-accordion>
          <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false"
            style="background:white !important;">
            <mat-expansion-panel-header style="padding-left:0px !important;">
              <mat-panel-title>
                <h4 style="text-decoration: underline; font-weight: 600;"> CARACTÉRISTIQUES </h4>
              </mat-panel-title>
            </mat-expansion-panel-header>
            <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false"
              style="background:white !important;" *ngFor="let item of caracs.caracteristiques; index as i">
              <mat-expansion-panel-header>
                <mat-panel-title>
                  <h4>{{ item.caracteristique.nom }}</h4>
                </mat-panel-title>
              </mat-expansion-panel-header>
              <section class="row example-section" *ngIf="item.caracteristique.type === 'SELECT'">
                <mat-checkbox class="col-12 example-margin" *ngFor="let value of item.valeurs; let ind = index"
                  [(ngModel)]="value.checked" (click)="addNewFilter(item.caracteristique.code,value)"> {{
                  value.item.valeur }} </mat-checkbox>
              </section>
            </mat-expansion-panel>

          </mat-expansion-panel>
        </mat-accordion>
      </div>

      <!-- couleur -->
      <div *ngIf="caracs && caracs.couleurs && caracs.couleurs.length">
        <mat-accordion>
          <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false"
            style="background:white !important;">
            <mat-expansion-panel-header style="padding-left:0px !important;">
              <mat-panel-title>
                <h4 style="text-decoration: underline; font-weight: 600;"> COULEURS </h4>
              </mat-panel-title>
            </mat-expansion-panel-header>
            <section class="row example-section">
              <mat-checkbox class="col-12 example-margin"
                *ngFor="let couleur of caracs.couleurs; let indCouleur = index" [(ngModel)]="couleur.checked"
                (change)="setSet(caracs.couleurs, indCouleur)"> {{couleur.nom}} </mat-checkbox>
            </section>
          </mat-expansion-panel>
        </mat-accordion>
      </div>

      <!-- tailles -->
      <div *ngIf="caracs && caracs.tailles && caracs.tailles.length">
        <mat-accordion>
          <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false"
            style="background:white !important;">
            <mat-expansion-panel-header style="padding-left:0px !important;">
              <mat-panel-title>
                <h4 style="text-decoration: underline; font-weight: 600;"> TAILLES </h4>
              </mat-panel-title>
            </mat-expansion-panel-header>

            <section class="row example-section">
              <mat-checkbox class="col-12 example-margin"
                *ngFor="let taille of caracs.tailles[0].tailles; let indTaille = index" [(ngModel)]="taille.checked"
                (change)="setSet(caracs.tailles[0].tailles, indTaille)"> {{taille.valeur}} </mat-checkbox>
            </section>

          </mat-expansion-panel>
        </mat-accordion>
      </div>
    </div>

    <button class="validate" (click)="modalRef.hide()">Valider</button>
  </div>
</ng-template>

<ng-template #loginTemplate>
  <app-login [modal]="chatService.modalRef2" [newsCustomer]="true"></app-login>
</ng-template>
