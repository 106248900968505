import { Component, OnInit } from '@angular/core';
import { AuthServices } from '../../services/auth.service';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { FormControl, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-mdpoublier',
  templateUrl: './mdpoublier.component.html',
  styleUrls: ['./mdpoublier.component.scss']
})
export class MdpoublierComponent implements OnInit {
  step = true;
  signe = 'password';
  faLogin = 'fa-eye';
  pwStep1: string;
  code: string;
  pw1: string;
  pw2: string;
  hide = true;

  emailFormControl = new FormControl('', [
    Validators.required,
    Validators.email,
  ]);
  confirmForm = new FormGroup({
    cff_code: new FormControl('', [Validators.required]),
    cff_newMdp: new FormControl('', [Validators.required]),
    cff_newMdpConfirm: new FormControl('', [Validators.required]),
  });

  constructor(private auth: AuthServices, private toastr: ToastrService, private router: Router) { }

  Soumettre() {
    if (this.emailFormControl.valid) {
      const data = new FormData();
      // data.set('email', this.pwStep1);
      data.set('email', this.emailFormControl.value);
      data.set('plateforme', 'HADEEN');
      this.auth.resetstep1(data).subscribe((rsp: any) => {
        this.step = false;
      }, err => {
        this.step = true;
        this.toastr.error('Une erreur est survenu', '', { timeOut: 1000 });
      });
    } else { this.toastr.error('Email non valide', '', { timeOut: 1000 }); }
  }

  Envoyer() {
    const formValue = this.confirmForm.value;
    if (this.confirmForm.valid) {
      // if (this.pw1 !== this.pw2) {
      if (formValue.cff_newMdp !== formValue.cff_newMdpConfirm) {
        this.toastr.error('Les 2 mots de passe sont différents', '', { timeOut: 3000 });
      } else {
        const data = new FormData();
        // data.set('code', this.code);
        // data.set('email', this.pwStep1);
        // data.set('password', this.pw1);
        data.set('code', formValue.cff_code);
        data.set('email', this.emailFormControl.value);
        data.set('password', formValue.cff_newMdp);
        data.set('plateforme', 'HADEEN');
        this.auth.resetstep2(data).subscribe((rsp: any) => {
          this.toastr.success('Votre mot de passe est changé', '', { timeOut: 1000 });
          this.router.navigate(['/']);
        }, err => {
          this.step = true;
          this.toastr.error('Une erreur est survenu', '', { timeOut: 1000 });
        });
      }
    } else { this.toastr.error('Merci de vérifier votre formulaire', '', { timeOut: 1000 }); }
  }
  showPassLogin() {
    if (this.signe === 'password' && this.faLogin === 'fa-eye') {
      this.signe = 'text';
      this.faLogin = 'fa-eye-slash';
    } else {
      this.signe = 'password';
      this.faLogin = 'fa-eye';
    }
  }

  ngOnInit(): void {
  }

}
